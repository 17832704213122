import { put, takeLatest, call, select, all } from "redux-saga/effects";
import {
  setArticles,
  setCalendar,
  setProperty,
  setPmfees,
  updateComparables,
  updateMarketingOptions,
  updateMeta,
  updateVendor,
  updateRecipient,
  setAppraisalId,
  updateMethodOfSaleField,
  updateNextSteps,
  updateStateInfo,
  updateMarketing,
  updateTeam,
  setCommercialArticles,
  setAvailableCalendars,
  updateCommercialField, updateOwners,
} from "../reducers";
import {
  fetchArticles,
  initializeComparable,
  prepareAppraisal,
  initVendorDetails,
  updateVendorWithCoverLetter,
  updateOwnersDetails,
  updateTeamSection,
  prepareServiceFee,
  prepareCommercialType,
  updateAttachmentsSection,
} from "../actions";
import {
  convertPropertyData,
  populateCalendar,
  populateVendorDetails,
  populateStateInfo,
  populateMarketingOptions,
  getCommercialArticles, populateOwnersDetails,
} from "../../app/modules/Appraisals/CreateAppraisal/utils";
import PropertiesService from "../../app/api/PropertiesService";
import ArticlesService from "../../app/api/ArticlesService";
import * as constants from "../../app/assets/constants";
import {
  setLoadProposalStart,
  setLoadProposalFinish,
} from "redux/reducers/commonReducer";
import { ProposalTypes } from "app/interfaces";
import { defaultResidentialItems as residentialAttachments } from "app/modules/Appraisals/Commercial/Attachments/utils";

export default function* watchAppraisals() {
  yield takeLatest(prepareAppraisal.type, function* prepareAppraisalSaga({
    payload,
  }) {
    yield put(setLoadProposalStart("prepareAppraisal"));
    const { selectedCalendar } = yield select(
      state => state.appraisal.calendar,
    );
    const {
      office: appraisalOffice,
      property: appraisalProperty,
    } = yield select(state => state.createProposal);
    const property = payload?.property || appraisalProperty;
    const office = payload?.office || appraisalOffice;

    const isSale = !!property?.saleLifeId;
    const category = isSale ? "sales" : "lease";
    const classification = property?.class?.internalName || "residential";
    const meta = {
      category,
      classification: classification,
      property: property.id,
      office: office._id || office.id,
      // serviceFeesHidden: false
    };
    const propertyData = convertPropertyData(
      property,
      classification,
      category,
    );
    const marketingDescription = `Our marketing activities will ensure you stand out from the competition and attract the right type of ${
      isSale ? "buyer" : "tenant"
    }. The following are a selection of marketing strategies which you may choose to implement.`;

    const marketing = office.marketing[isSale ? "sale" : "lease"];
    const marketingRegular = marketing.regular.map((item, index) => ({
      ...item,
      active: index < 3,
    }));
    yield all([
      put(setProperty(propertyData)),
      put(updateMeta(meta)),
      put(
        updateMarketing(
          populateMarketingOptions(office, category, marketingDescription),
        ),
      ),
      put(
        updateMarketingOptions({
          tabs: marketingRegular,
          extra: marketing.extra,
        }),
      ),
      put(setCalendar(populateCalendar(office, isSale, selectedCalendar))),
      put(setAvailableCalendars(office?.timeline_settings)),
      put(initializeComparable({ meta })),
      put(
        updateStateInfo(
          populateStateInfo(propertyData.address.state.abbreviation),
        ),
      ),
      put(initVendorDetails({ property, meta })),
      put(
        updateRecipient({
          email: { message: "", subject: "" },
          phone: { message: "" },
        }),
      ),
      put(setAppraisalId(null)),
      put(
        updateMethodOfSaleField({
          value: property.saleMethod,
          filed: "selectedMethod",
        }),
      ),
      put(updateTeamSection()),
      put(prepareServiceFee({ office, category })),
      put(updateAttachmentsSection()),
    ]);
    yield put(setLoadProposalFinish("prepareAppraisal"));
  });

  yield takeLatest(initVendorDetails.type, function* initVendorDetailsSaga({
    payload,
  }) {
    try {
      yield put(setLoadProposalStart("initVendorDetails"));
      const { property, meta } = payload;
      const res = yield call(
        PropertiesService.getPropertyVendor,
        ...[
          property.id,
          property.saleLifeId || property.leaseLifeId || property.lifeId,
          meta.office,
          meta.category,
          "",
        ],
      );
      console.log(populateOwnersDetails(res.data))
      yield put(updateVendorWithCoverLetter(populateVendorDetails(res.data)));
      yield put(updateOwnersDetails(populateOwnersDetails(res.data)));
      yield put(setLoadProposalFinish("initVendorDetails"));
    } catch (e) {
      yield put(setLoadProposalFinish("initVendorDetails"));
      console.error(e.message);
    }
  });
  yield takeLatest(
    updateVendorWithCoverLetter.type,
    function* updateVendorWithCoverLetterSaga({ payload }) {
      yield put(setLoadProposalStart("updateVendorWithCoverLetter"));
      const { coverLetter } = yield select(state => state.appraisal.vendor);
      if (!coverLetter) return;
      const updatedCoverLetter = coverLetter.replace(
        /<p>.*Dear .*,<\/p>/,
        `<p>Dear ${payload.name},</p>`,
      );
      yield put(updateVendor({ ...payload, coverLetter: updatedCoverLetter }));
      yield put(setLoadProposalFinish("updateVendorWithCoverLetter"));
    },
  );

  yield takeLatest(
    updateOwnersDetails.type,
      function* updateOwnersDetailsSaga({ payload }) {
        yield put(setLoadProposalStart("updateOwnersDetails"));
        const { coverLetter } = yield select(state => state.appraisal.vendor);
        const ownersNames = payload.map(owner => `${owner.firstName}`).join(' & ');
        const updatedCoverLetter = coverLetter?.replace(/<p>.*Dear .*,<\/p>/, `<p>Dear ${ ownersNames },</p>`);
        yield put(updateOwners(payload));
        yield put(updateVendor({ ...payload, coverLetter: updatedCoverLetter }));
        yield put(setLoadProposalFinish("updateOwnersDetails"));
      },
  );

  // COMPARABLE INITIALIZATION
  yield takeLatest(
    initializeComparable.type,
    function* initializeComparableSaga({ payload }) {
      yield put(setLoadProposalStart("initializeComparable"));
      const { meta } = payload;
      const { appraisalType } = yield select(state => state.appraisal.meta);
      const { opinionTitle } = yield select(
        state => state.appraisal.comparables.opinionTitle,
      );
      if (appraisalType === "preAppraisal") {
        yield put(
          updateComparables({
            sectionTitle:
              meta.category === "sales"
                ? "Recent Sales & Listings"
                : "Current Rental Listings",
            sectionSubtitle:
              meta.category === "sales"
                ? "Keep up-to-date with your local market"
                : "Keep up-to-date with your local market",
          }),
        );
      }
      if (opinionTitle === undefined || opinionTitle === "") {
        yield put(
          updateComparables({
            opinionTitle:
              meta.category === "sales"
                ? `Taking into consideration all the factors aforementioned, we estimate the following sales range for your ${
                    appraisalType === ProposalTypes.businessListingProposal
                      ? "business"
                      : "property"
                  }:`
                : `Taking into consideration all the factors aforementioned, we estimate the following rental range for your ${
                    appraisalType === ProposalTypes.businessListingProposal
                      ? "business"
                      : "property"
                  }:`,
          }),
        );
      }
      yield put(setLoadProposalFinish("initializeComparable"));
    },
  );

  // ARTICLES INITIALIZATION
  yield takeLatest(fetchArticles.type, function* fetchArticlesSaga({
    payload,
  }) {
    yield put(setLoadProposalStart("fetchArticles"));
    const office = yield select(state => state.createProposal?.office);
    const officeId = payload || office?.id;
    const templateArticles = yield select(state => state.appraisal.articles);
    const disabledArticles = templateArticles
      .filter(el => el.active === false)
      .map(el => el._id);
    yield put(setArticles([]));
    const appraisalType = yield select(
      state => state.appraisal.meta.appraisalType,
    );
    const appraisalCategory = yield select(
      state => state.appraisal.meta.category,
    );

    const commonListOfArticles = constants.getAllArticles(appraisalCategory);
    const { data } = yield call(
      ArticlesService.getOfficeArticles,
      officeId,
      null,
      null,
      null,
      null,
      "unlimited",
    );

    const searchCategory =
      appraisalCategory === "sales"
        ? ["residential-sales", "residential-sales-nz"]
        : [
            "residential-property-management",
            "residential-property-management-nz",
          ];

    let validType = searchCategory + ["common"];

    if (appraisalType === ProposalTypes.prospecting) {
      validType = ["corporate-prospecting", "common"];
    }

    if (appraisalType === ProposalTypes.businessListingProposal) {
      validType = ["common", "business-sales"];
    }

    const articlesFromTemplate = yield select(
      state => state.appraisal.articlesFromTemplate,
    );
    const articlesFromTemplateMap = articlesFromTemplate.reduce((cur, item) => {
      return Object.assign(cur, { [item.slug]: item });
    }, {});

    if (
      appraisalType === ProposalTypes.informationMemorandum ||
      appraisalType === ProposalTypes.residentialInformationMemorandum
    ) {
      const commercialArticles = data.articles?.map(article => {
        if (
          articlesFromTemplate?.length &&
          articlesFromTemplateMap[article.slug]
        ) {
          const articleFromTemplate = articlesFromTemplateMap[article.slug];
          article = { ...articleFromTemplate };
        }
        return article;
      });
      yield put(
        prepareCommercialType({
          articles: commercialArticles,
          type: appraisalType,
        }),
      );
      return;
    }

    let filteredArticles = data.articles.filter(
      article =>
        article.active &&
        validType.includes(article.category) &&
        commonListOfArticles.includes(article.slug),
    );

    filteredArticles = filteredArticles.map(article => {
      if (
        articlesFromTemplate?.length &&
        articlesFromTemplateMap[article.slug]
      ) {
        const articleFromTemplate = articlesFromTemplateMap[article.slug];
        article.active = articleFromTemplate.active;
      }
      return article;
    });

    if (disabledArticles.length > 0) {
      filteredArticles.map(a => (a.active = !disabledArticles.includes(a._id)));
    }

    if (appraisalType === "preAppraisal") {
      const index = filteredArticles.findIndex(
        el => el.slug === "the-sales-process",
      );
      if (index !== -1)
        filteredArticles[index] = { ...filteredArticles[index], active: false };
    }

    if (appraisalType === ProposalTypes.commercialListingProposal) {
      const articlesList = constants.getAllArticlesByTypes(
        ProposalTypes.commercialListingProposal,
        appraisalCategory,
      );
      const validCategory = ["commercial-sales"];
      filteredArticles = data.articles.filter(
        article =>
          article.active &&
          validCategory.includes(article.category) &&
          articlesList.includes(article.slug),
      );
    }

    if (appraisalType === ProposalTypes.tenantHandbook) {
      const articlesList = constants.getAllArticlesByTypes(
        ProposalTypes.tenantHandbook,
        appraisalCategory,
      );
      const validCategory = ["residential-tenant-handbook", "residential-tenant-handbook-nz"];
      filteredArticles = data.articles.filter(
        article =>
          article.active &&
          validCategory.includes(article.category) &&
          articlesList.includes(article.slug),
      );
    }

    yield put(setArticles(filteredArticles));

    let nextStepArticle = filteredArticles.filter(
      a => a.slug === constants.articles.nextSteps[appraisalCategory][0],
    )[0];
    if (appraisalCategory === "sales" && appraisalType === "preAppraisal") {
      nextStepArticle = filteredArticles.filter(
        a => a.slug === constants.articles.nextSteps[appraisalCategory][1],
      )[0];
    }
    if (["marketUpdate", "agentProfiling"].includes(appraisalType)) {
      nextStepArticle = {
        ...nextStepArticle,
        body: "Enter next steps commentary here...",
      };
    }

    const nextStepDescription = yield select(
        state => state.appraisal.nextSteps?.description || nextStepArticle?.body || "",
    );

    yield put(
      updateNextSteps({
        description: nextStepDescription,
        image: nextStepArticle?.image,
      }),
    );
    yield put(setLoadProposalFinish("fetchArticles"));
  });

  yield takeLatest(updateTeamSection.type, function*() {
    const { image, about } = yield select(state => state.appraisal.team);
    return yield put(updateTeam({ aboutHidden: !image && !about }));
  });

  yield takeLatest(updateAttachmentsSection.type, function*() {
    const { appraisalType } = yield select(state => state.appraisal.meta);
    let attachment = yield select(
      state => state.appraisal.commercial.attachments,
    );
    if (appraisalType === ProposalTypes.residentialInformationMemorandum) {
      const data = { ...attachment, items: residentialAttachments };
      return yield put(updateCommercialField({ data, name: "attachments" }));
    }
  });

  yield takeLatest(prepareServiceFee.type, function*({ payload }) {
    yield put(setLoadProposalStart("prepareServiceFee"));
    let serviceFeesSubtitle = yield select(
      state => state.appraisal.meta.serviceFeesSubtitle,
    );

    if (serviceFeesSubtitle === "") {
      serviceFeesSubtitle =
        payload.category === "sales"
          ? "What's included in the fee?"
          : "Breakdown of service fees";
    }

    yield all([
      put(setPmfees(payload.office?.pmfees)),
      put(updateMeta({ serviceFeesSubtitle })),
    ]);
    yield put(setLoadProposalFinish("prepareServiceFee"));
  });

  yield takeLatest(prepareCommercialType.type, function*({ payload }) {
    yield put(setLoadProposalStart("prepareCommercialType"));
    const articlesFromTemplate = yield select(
      state => state.appraisal.commercialFromTemplate,
    );
    const { articles, type } = payload;
    const commercialArticles = getCommercialArticles(articles, type);
    yield put(
      setCommercialArticles({ ...commercialArticles, ...articlesFromTemplate }),
    );

    const { email } = yield select(state => state.appraisal.team);
    yield put(
      updateNextSteps({
        action: {
          title: "Contact Me",
          type: "email",
          value: email || "demo@mailinator.com",
        },
      }),
    );
    yield put(setLoadProposalFinish("prepareCommercialType"));
  });
}
