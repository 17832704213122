import axios from "axios";
import API_URL from "./api";

export default class StatisticsService {
  static getStatistics = params => {
    return axios.get(
      `${API_URL}/corelogic/get?locality=${params.locality}&category=${params.category}&country=${params.country}`,
    );
  };
}
