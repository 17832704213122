import React, { useContext, useEffect, useRef, useState } from "react";
import OfficesService from "app/api/OfficesService";
import UsersService from "app/api/UsersService";
import produce from "immer";
import { ToastContext } from "app/components/ToastProvider";
import Tabs from "../../../components/Tabs";
import UserList from "./UserList";
import { useCallback } from "react";

const tabsData = (users, loading, updateValue) => [
  {
    button: { title: "Active" },
    component: UserList,
    componentProps: {
      users: users.filter(user => user.isActive),
      loading,
      updateValue,
    },
  },
  {
    button: { title: "Deactive" },
    component: UserList,
    componentProps: {
      users: users.filter(user => !user.isActive),
      loading,
      updateValue,
    },
  },
];

const OfficeUsers = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const { toast } = useContext(ToastContext);
  const [users, setUsers] = useState([]);
  const totalPages = useRef(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    OfficesService.getOffice(id)
      .then(({ data }) => OfficesService.syncAgents(data.token, id))
      .then(() =>
        UsersService.getOfficeUsers(id, "", 1, "active", "descending", true),
      )
      .then(resp => {
        const {
          data: { users, pages },
        } = resp;
        const userList = (users || []).map(user => ({
          id: user._id,
          fullName:
            user.firstname + " " + user.lastname + " (" + user.agentid + ")",
          isActive: user.active,
          level: parseInt(user.permissions),
        }));
        totalPages.current = pages;
        setUsers(userList);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong. Please try again later",
          life: 4000,
        });
      })
      .finally(() => setLoading(false));
  }, [id, toast]);

  useEffect(() => {
    fetchData();
    // TODO: fix
  }, [fetchData, id]);

  const updateValue = (id, value, field, localField) => {
    setLoading(true);
    UsersService.updateUser(id, { [field]: value })
      .then(() => {
        let newUsers = produce(users, draftUsers => {
          let idx = draftUsers.findIndex(u => u.id === id);
          draftUsers[idx][localField] = value;
        });
        setUsers(newUsers);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Team Member has been successfully updated",
          life: 3000,
        });
      })
      .catch(e => {
        console.error(e.message);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong. Please try again later",
          life: 4000,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="card-header flex-nowrap px-5">
        <div className="card-title flex-column-auto">
          <h3 className="card-label font-weight-bolder text-dark">
            User Access
          </h3>
        </div>
      </div>
      <Tabs
        data={tabsData(users, loading, updateValue)}
        className="d-flex flex-grow-1 p-relative"
      />
    </>
  );
};

export default OfficeUsers;
