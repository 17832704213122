import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { fetchAppraisalsList } from "redux/actions";
import {
  getProposalsList,
  getIsLoadingProposalsList,
  getProposalsTotalPages,
} from "redux/selectors/proposalsSelector";
import GridLinksList from "app/components/GridLinksList";
import SvgIcon from "app/components/ui/SvgIcon";
import ProposalActionMenu from "app/components/ProposalActionMenu";
import PaginationSelective from "app/components/PaginationSelective";
import Loader from "app/components/ui/Loader";
import { OPENED, UNOPENED } from "app/constants/proposalsConstants";
import useDebounce from "../../../hooks/useDebounce";
import { useGetUser } from "redux/hooks";

import "./styles.scss";
import { getWeeks, getRange } from "../../../../redux/selectors";

const columnsTitles = isCorporateUser => [
  { title: "" },
  {
    title: "Office",
    className: `${isCorporateUser ? "width-160" : "hidden"}`,
    sortLabel: "office",
  },
  {
    title: "Status",
    className: `width-${isCorporateUser ? 100 : 140}`,
    sortLabel: "status",
  },
  { title: "Team Member", className: "width-120", sortLabel: "member" },
  {
    title: "Opens",
    className: `item__centered width-${isCorporateUser ? 50 : 100}`,
    sortLabel: "opens",
  },
  {
    title: "Prints",
    className: `item__centered width-${isCorporateUser ? 70 : 100}`,
    sortLabel: "prints",
  },
  { title: "Type", className: "width-120", sortLabel: "type" },
  {
    title: "Updated",
    className: `width-${isCorporateUser ? 60 : 100}`,
    sortLabel: "updated",
    isDate: true,
  },
  {
    title: "FLK",
    className: `item__centered width-100`,
    sortLabel: "flk",
  },
  {
    title: `${isCorporateUser ? "Report" : "View Report"}`,
    className: `item__centered ${isCorporateUser ? "width-60" : ""}`,
  },
  { title: "", style: { width: "33px" } },
];

const filterForDraftTab = tab => {
  if (
    tab?.sortLabel === "opens" ||
    tab?.title === "Report" ||
    tab?.title === "View Report"
  ) {
    return false;
  }
  return true;
};

const draftsTitles = isCorporateUser =>
  columnsTitles(isCorporateUser)?.filter(filterForDraftTab);

const getMainData = ({
  proposal,
  proposalStatus,
  isDraftsTab,
  isCorporateUser,
}) => {
  const data = [
    {
      className: "hover-highlighted",
      content: proposal.property,
    },
    {
      sortLabel: "office",
      className: `${isCorporateUser ? "width-160" : "hidden"}`,
      content: proposal.office?.replace("Raine & Horne ", ""),
    },
    {
      sortLabel: "status",
      className: `item__label width-${isCorporateUser ? 100 : 140} ${
        proposalStatus ? proposalStatus : proposal.opened ? OPENED : UNOPENED
      }`,
      content: proposalStatus
        ? proposalStatus
        : proposal.opened
        ? OPENED
        : UNOPENED,
    },
    {
      sortLabel: "member",
      className: "width-120",
      content: proposal.member,
    },
    {
      sortLabel: "opens",
      className: `item__centered width-${isCorporateUser ? 50 : 100}`,
      content: proposal.opens,
    },
    {
      sortLabel: "prints",
      className: `width-${isCorporateUser ? 70 : 100} item__centered`,
      content: proposal.prints,
    },
    {
      sortLabel: "type",
      className: "width-120",
      content: proposal.proposalType,
    },
    {
      sortLabel: "updated",
      className: `width-${isCorporateUser ? 60 : 100}`,
      content: proposal.updated,
      timeStamp: proposal.timeStamp,
    },
    {
      sortLabel: "flk",
      className: `width-100 item__centered`,
      content: proposal?.flk ? 'Yes' : 'No',
    },
  ];

  return isDraftsTab ? data.filter(filterForDraftTab) : data;
};

const List = ({
  search,
  draft,
  archived,
  name,
  proposalType,
  proposalOffice,
  proposalTeamMember,
  proposaldateRange,
}) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const weeks = useSelector(getWeeks);
  const range = useSelector(getRange);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery().get("type");

  const isLoading = useSelector(getIsLoadingProposalsList);
  const totalPages = useSelector(getProposalsTotalPages);
  const proposals = useSelector(getProposalsList);
  const { isCorporateUser } = useGetUser();

  const isDraftsTab = name === "drafts";

  const debouncedSearchTerm = useDebounce(search, 1500);

  useEffect(() => {
    dispatch(
      fetchAppraisalsList({
        search: debouncedSearchTerm,
        page,
        archived,
        draft,
        query,
        weeks,
        range,
        proposalType,
        proposalOffice,
        proposalTeamMember,
        proposaldateRange,
      }),
    );
  }, [
    debouncedSearchTerm,
    page,
    archived,
    draft,
    query,
    dispatch,
    weeks,
    proposalType,
    proposalOffice,
    proposalTeamMember,
    proposaldateRange,
    range,
  ]);

  const archiveHandler = () => {
    dispatch(
      fetchAppraisalsList({ search, page, archived, draft, query, weeks }),
    );
  };

  const actionReportHandler = e => {
    if (draft) {
      e.preventDefault();
    }
  };

  const proposalStatus = draft ? "draft" : archived ? "archived" : null;

  const listData = proposals.map(proposal => {
    const data = getMainData({
      proposalStatus,
      proposal,
      isDraftsTab,
      isCorporateUser,
    });

    const mainData = [...data];

    if (!isDraftsTab) {
      mainData.push({
        className: cn({
          [!draft && proposal.sent
            ? "hover-highlighted"
            : "cursor-default"]: true,
          "item__centered": true,
          "width-60": isCorporateUser,
        }),
        content: proposal.sent ? (
          <Link
            to={`/proposals/${proposal.id}/metrics`}
            onClick={actionReportHandler}
          >
            <SvgIcon iconName="actionReport" />
          </Link>
        ) : (
          ""
        ),
      });
    }

    mainData.push({ title: "", style: { width: "33px" } });

    return {
      id: proposal.id,
      link: `/proposals/${proposal.id}/edit`,
      mainData,
      rowTail: (
        <ProposalActionMenu
          status={archived ? 3 : 1}
          rowId={proposal.id}
          archiveHandler={archiveHandler}
        />
      ),
    };
  });

  if (isLoading)
    return (
      <div className="proposals-list-wrapper">
        <Loader />
      </div>
    );

  if (!isLoading && !listData?.length)
    return (
      <div className="proposals-list-wrapper">
        <div className="container-centered-items h2 my-20">
          No {name} proposals at this time.
        </div>
      </div>
    );

  return (
    <div className="proposals-list-wrapper">
      <GridLinksList
        content={listData}
        columnsTitles={
          isDraftsTab
            ? draftsTitles(isCorporateUser)
            : columnsTitles(isCorporateUser)
        }
        isLoading={isLoading}
        columnsTitlesDetached
      />
      <div className="proposals-list-footer">
        <PaginationSelective
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default List;
