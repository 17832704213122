export const UNOPENED = "unopened";
export const OPENED = "opened";
export const RECENT = "recent";
export const MAX_LIMIT = 5555;
export const PROPOSALS_TYPES = {
  preAppraisal: "Pre List",
  listingProposal: "Listing Proposal",
  marketUpdate: "Market Update",
  agentProfiling: "Agent Profiling",
  appraisal: "Appraisal",
  prospecting: "Prospecting",
  informationMemorandum: "Information Memorandum",
  commercialListingProposal: "Commercial Listing Submission",
  businessListingProposal: "Business Listing Proposal",
  residentialInformationMemorandum: "Residential Information Memorandum",
  tenantHandbook: 'Tenant Information Guide',
};

export const PROPOSALS_TYPES_OPTIONS = [
  { value: "preAppraisal", display: "Pre List", role: ["residential"] },
  {
    value: "listingProposal",
    display: "Listing Proposal",
    role: ["residential"],
  },
  { value: "marketUpdate", display: "Market Update", role: ["residential"] },
  {
    value: "agentProfiling",
    display: "Agent Profiling",
    role: ["residential"],
  },
  // { value: "appraisal", display: "Appraisal", role: [] },
  { value: "prospecting", display: "Prospecting", role: [] },
  {
    value: "informationMemorandum",
    display: "Information Memorandum",
    role: ["commercial"],
  },
  {
    value: "commercialListingProposal",
    display: "Commercial Listing Submission",
    role: ["commercial"],
  },
  {
    value: "businessListingProposal",
    display: "Business Listing Proposal",
    role: ["business"],
  },
  {
    value: "residentialInformationMemorandum",
    display: "Residential Information Memorandum",
    role: ["residential"],
  },
  {
    value: "tenantHandbook",
    display: "Tenant Information Guide",
    role: ["residential"],
  },
];

export const PROPOSALS_BY_TYPE = {
  preAppraisal: { color: "#a259ff", title: "Pre List" },
  listingProposal: { color: "#ffb300", title: "Listing Proposal" },
  marketUpdate: { color: "#09cf83", title: "Market Update" },
  agentProfiling: { color: "#1b70fe", title: "Agent Profiling" },
  otherTypes: { color: "#e49040", title: "Other Types" },
};

export const PROPOSALS_BY_TYPE_EXTENDED = [
  { title: "Pre List", alias: "total_pre" },
  { title: "Listing Proposal", alias: "total_listing" },
  { title: "Market Update", alias: "total_market" },
  { title: "Agent Profile", alias: "total_agent" },
  { title: "Residential IM", alias: "total_residential_im" },
  { title: "Commercial IM", alias: "total_commercial_im" },
  { title: "Commercial Submission", alias: "total_commercial_listing" },
  { title: "Business Sales", alias: "total_business_listing" },
  { title: "Total Sales", alias: "total_sales" },
  { title: "Total Lease", alias: "total_lease" },
  { title: "Total Sent", alias: "total" },
  { title: "Prints", alias: "prints" },
];

export const PROPOSAL_SESSION_METRICS_TABLE = [
  {
    key: "articleOpens",
    title: "Article Opens",
    columns: ["Article", "Duration (h:mm:ss)"],
  },
  {
    key: "methodOfSaleTabs",
    title: "Method of Sale Tabs Clicks",
    columns: ["Tab", "Clicks"],
  },
  {
    key: "marketingTabs",
    title: "Marketing Tabs Clicks",
    columns: ["Tab", "Clicks"],
  },
  {
    key: "comparablesTabs",
    title: "Comparables Engagement",
    columns: ["Tab", "Clicks"],
  },
  {
    key: "teamDetailsSection",
    title: "Team Details Section Clicks",
    columns: ["Item", "Clicks"],
  },
];
