import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Header from "app/modules/Dashboard/Header";
import ProposalsWidget from "app/modules/Dashboard/ProposalsWidget";
import ProposalChart from "app/modules/Dashboard/ProposalsChart";
import ProposalsTabs from "app/modules/Dashboard/ProposalsTabs";
import { LeaderBoard } from "app/modules/Dashboard/Leaderboard";
import Footer from "app/modules/Dashboard/Footer";
import { getStorage } from "../../../_metronic/_helpers/LocalStorageHelpers";
import { getIsLoadingHeaders } from "redux/selectors/dashboardSelector";
import Loader from "app/components/ui/Loader";
import DashboardModal from "../../modules/Dashboard/DashboardModal";
import MessagesSlider from "../../modules/Messages/MessagesSlider";
import { getUnreadedMessages } from "redux/selectors/messagesSelector";

import "./styles.scss";

export const Dashboard = () => {
  const isLoading = useSelector(getIsLoadingHeaders);
  const isOwnAccount = getStorage("ownAccountToken") ? false : true;
  const [open, setOpen] = useState(false);
  const unreadedMessages = useSelector(getUnreadedMessages);

  useEffect(() => {
    if (
      unreadedMessages &&
      unreadedMessages?.some(m => m.showcaseNotification)
    ) {
      setOpen(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isOwnAccount ? <DashboardModal /> : ""}
      {!isLoading ? (
        <Loader />
      ) : (
        <div className="dashboard-page">
          <section className="dashboard-main">
            <Header withCustomDates />
            <ProposalsWidget />
            <ProposalChart />
            <ProposalsTabs />
            <Footer />
          </section>
          <LeaderBoard />
          {open && (
            <MessagesSlider
              open={open}
              onClose={() => setOpen(false)}
              messages={unreadedMessages?.filter(m => m.showcaseNotification)}
            />
          )}
        </div>
      )}
    </>
  );
};
