import React from "react";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  separator: ({ space = 6}) => ({
    width: '100%',
    height: '1px',
    borderBottom: `2px solid ${theme.palette.border.main}`,
    paddingTop: theme.spacing(space),
  }),
})

const Separator = ({ classes }) => <div className={classes.separator}/>

export default withStyles(styles)(Separator);
