import { generateFormattedDate } from './formettedDate';

const WEEK_DAYS_INDEX = 6;
const ONE_WEEK_DAYS = 7;
const FOUR_WEEKS_DAYS = 28;

const convertProposalsArray = (proposalsArray) => {
  const proposalsData = {};
  proposalsArray.forEach(item => {
    proposalsData[item._id] = {
      recent: item.count,
      unopened: item.unopened,
    };
  });
  return proposalsData;
};

export const getOneWeek = (proposalsArray) => {
  const datesArray = generateFormattedDate(WEEK_DAYS_INDEX);
  const proposalsData = convertProposalsArray(proposalsArray);

  const result = {
    labels: [],
    recent: [],
    unopened: [],
  };
  datesArray.forEach(({ date, day }) => {
    result.labels.push(day);
    if (proposalsData[date]) {
      result.recent.push(proposalsData[date].recent);
      result.unopened.push(proposalsData[date].unopened);
    } else {
      result.recent.push(0);
      result.unopened.push(0);
    }
  });
  return result;
};

export const getByMonths = (proposalsArray, weeks) => {
  const DAYS = (weeks * ONE_WEEK_DAYS);
  const datesArray = generateFormattedDate(DAYS);
  const proposalsData = convertProposalsArray(proposalsArray);

  const result = {
    labels: [],
    recent: [],
    unopened: [],
  };

  let recent = 0;
  let unopened = 0;
  const breakdown = weeks > 4 ? FOUR_WEEKS_DAYS : ONE_WEEK_DAYS
  datesArray.forEach(({ date, day }, index) => {
    if (proposalsData[date]) {
      recent += proposalsData[date].recent;
      unopened += proposalsData[date].unopened;
    }
    if ((index && (index % breakdown === 0)) || index === datesArray.length - 1) {
      result.labels.push(day);
      result.recent.push(recent);
      result.unopened.push(unopened);
      recent = 0;
      unopened = 0;
    }
  });
  return result;
};
