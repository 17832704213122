import React from "react";
import { Container } from "@material-ui/core";

import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import YourProperty from "../YourProperty";
import Comparable from "../../Comparables/Comparable";
import Marketing from "../Marketing";
import OurBroker from "../OurBroker";
import OurServices from "../OurServices";
import TeamDetails from "../TeamDetails";
import NextSteps from "../NextSteps";
import Footer from "../Footer";
import MarketReport from "../MarketReport";
import SalesProcess from "../SalesProcess";
import CampaignCalendar from "../CampaignCalendar";
import ServicesFees from "../ServicesFees";
import { shallowEqual, useSelector } from "react-redux";

const PreAppraisal = () => {
  const category = useSelector(({ appraisal }) => appraisal.meta.category, shallowEqual);
  const isSale = category === 'sales';
  return (
    <>
      <CoverletterImage />
      <Container maxWidth="md" component="div" className="px-10" id="appraisal-details">
        <Coverletter />
      </Container>
      <YourProperty />
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <MarketReport />
        <Comparable />
        { isSale ? <SalesProcess /> : <OurServices />}
        <Marketing />
        <CampaignCalendar />
        <ServicesFees />
        <OurBroker />
        <TeamDetails awards difference profile community history/>
        <NextSteps salesProcess faq lookingFor preparingYourHome />
      </Container>
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <Footer />
      </Container>
    </>
  );
}

export default PreAppraisal;
