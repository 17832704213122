import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute } from '_metronic/layout';
import Login from './pages/Login';

const AuthRouter = () => (
  <Switch>
    <ContentRoute path="/auth/login" component={Login} />
    <Redirect to="/auth/login" />
  </Switch>
);

export default AuthRouter;
