import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextareaAutosize, Tooltip } from "@material-ui/core";
import cn from "classnames";
import { AppraisalContext } from "../AppraisalContext";

interface TitleContextProps {
  className?: string;
  value: string;
  onBlur(val?: string): any;
  maxLength?: number;
  rowsMax?: number;
  disabled?: boolean;
}

interface BlockContextProps {
  defaultTitle?: string;
  defaultSubtitle?: string;
  children?: React.ReactNode;
  className?: string;
  id: string | undefined;
  onTitleUpdate?(val?: any): any;
  onSubtitleUpdate?(val?: any): any;
  separator?: boolean;
  hide?: boolean;
  withoutSubTitle?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `20px 0 40px 0`,
    [theme.breakpoints.down("sm")]: {
      margin: `20px 0 32px 0`,
    },
  },
  title: {
    color: theme?.palette?.primary.main,
    fontSize: 40,
    lineHeight: "40px !important",
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    textAlign: "center",
  },
  subtitle: {
    color: "rgba(60, 60, 61, 0.5)",
    fontSize: 14,
    letterSpacing: "0.015em",
    textAlign: "center",
  },
  separator: {
    width: "100%",
    height: "1px",
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    paddingTop: "48px",
  },
  tooltip: {
    fontSize: 18,
  },
  textAreaBase: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    "&:disabled, &:focus": {
      opacity: 1 + " !important",
    },
    "&:hover": {
      opacity: 0.6,
    },
  },
}));

export const Title = (props: TitleContextProps) => {
  const {
    className,
    value,
    onBlur,
    maxLength = 22,
    rowsMax = 1,
    disabled,
  } = props;
  const { preview } = useContext(AppraisalContext);
  const [title, setTitle] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setTitle(value);
  }, [value]);

  const onTextBlur = () => {
    onBlur(title);
    setShowTooltip(false);
  };

  const tooltipMessage = `You can enter another ${maxLength -
    title?.length} characters`;

  if (preview)
    return <div className={cn(className, classes.textAreaBase)}>{title}</div>;

  return (
    <Tooltip
      placement="top"
      title={disabled ? "" : tooltipMessage}
      open={showTooltip}
      classes={{ tooltip: classes.tooltip }}
    >
      <TextareaAutosize
        maxLength={maxLength}
        placeholder={`Maximum ${maxLength} characters`}
        rowsMax={rowsMax}
        className={cn(className, classes.textAreaBase)}
        value={title}
        onChange={e => setTitle(e.target.value)}
        onBlur={onTextBlur}
        onFocus={() => setShowTooltip(true)}
        disabled={preview || disabled}
      />
    </Tooltip>
  );
};

const BlockContainer = (props: BlockContextProps) => {
  const {
    defaultTitle,
    defaultSubtitle,
    children,
    className,
    id,
    onTitleUpdate = () => null,
    onSubtitleUpdate = () => null,
    separator = true,
    hide,
    withoutSubTitle,
  } = props;
  const classes = useStyles();
  return (
    <div className={className} id={id}>
      <div className={classes.titleContainer}>
        <Title
          value={defaultTitle || ""}
          onBlur={onTitleUpdate}
          className={classes.title}
          maxLength={30}
        />
        {withoutSubTitle ? (
          ""
        ) : (
          <Title
            value={defaultSubtitle || ""}
            onBlur={onSubtitleUpdate}
            className={classes.subtitle}
            maxLength={50}
          />
        )}
      </div>
      {hide ? "" : children}
      {separator && <div className={classes.separator} />}
    </div>
  );
};

export default BlockContainer;
