import React, { useState } from "react";
import AuthLayout from "../../../Layouts/AuthLayout";
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  select: {
    backgroundColor: "#F3F6F9",
    width: "100%",
    padding: "1.25rem 1.5rem",
  },
  formControl: {
    marginBottom: 20,
    "& .MuiInput-underline:before": {
      content: "none"
    },
    "& .MuiInput-underline:after": {
      content: "none"
    }
  }
}))

const SelectOffice = ({ onSelect, officeList }) => {
  const [selectedOffice, setSelectedOffice] = useState(0);
  const classes = useStyles();
  const onChangeOffice = ({ target }) => setSelectedOffice(target.value);
  const submitOffice = () => onSelect(officeList[selectedOffice]);

  return (
    <AuthLayout
      title="Login Account"
      subTitle="Your user account appears to be linked to multiple office accounts. Please choose which office you would like to sign in as"
    >
      <FormControl className="w-100" classes={{ root: classes.formControl }}>
        <Select
          value={selectedOffice}
          onChange={onChangeOffice}
          classes={{ root: classes.select }}
        >
          {officeList.map((item, index) =>
            <MenuItem value={index} key={item.office._id}>
              {item.office.name}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <button
        onClick={submitOffice}
        className="btn btn-primary font-weight-bold px-9 py-4 w-100"
      >
        Sign In
      </button>
    </AuthLayout>
  );
}

export default SelectOffice;
