import React from "react";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import useTrackEvent from "app/hooks/useTrackEvent";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  corporateProfile: {
    display: "flex",
    // margin: 'auto',
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(60, 60, 61, 0.5) !important",
    textDecoration: "underline !important",
    fontSize: "16px",
    cursor: "Pointer",
    "&:hover": {
      textDecoration: "underline !important",
      backgroundColor: "white",
      color: "rgba(60, 60, 61, 0.5)",
    },
  },
  corporateProfileIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    paddingRight: "0.3em",
    fontSize: "3em",
  },
}))

const ProfileButton = ({ profileLink, title }) => {
  const trackEvent = useTrackEvent();
  const classes = useStyles();

  return (
    <a
      className={classes.corporateProfile}
      href={profileLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => trackEvent("Team Details Section", title)}
    >
      <AssignmentIndIcon
        fontSize={"large"}
        className={classes.corporateProfileIcon}
      />
      {title}
    </a>
  )
}

export default React.memo(ProfileButton);
