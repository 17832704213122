import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import LinkIcon from '@material-ui/icons/Link';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import produce from 'immer';

import {updateRecipient, updateVendor} from 'redux/reducers/appraisalReducer';
import EmailTemplate from './EmailTemplate';
import SmsTemplate from './SmsTemplate';
import {getSendBody} from '../utils';
import SendService from '../../../../api/SendService';
import SuccessModal from './SuccessModal';
import {email, sms} from '../../../../assets/constants';
import AppraisalsService from '../../../../api/AppraisalsService';
import cn from 'classnames';
import {APPRAISAL_TYPES} from "app/constants/appraisalsConstants";
import {
    useToast,
    getErrorToastMessage,
    getSuccessToastMessage,
} from "../../../../hooks";
import {useCommercial} from 'app/hooks';
import {ProposalTypes} from 'app/interfaces';

const getSubject = (address, street, appraisalType) => {
    if (appraisalType === APPRAISAL_TYPES.preAppraisal) return `Looking forward to meeting with you on ${address}`;
    if (appraisalType === APPRAISAL_TYPES.listingProposal) return `Property Proposal for ${address}`;
    if (appraisalType === APPRAISAL_TYPES.marketUpdate) return `Market Update`;
    if (appraisalType === APPRAISAL_TYPES.agentProfiling) return `Agent Profile`;
    if (appraisalType === APPRAISAL_TYPES.prospecting) return `Franchise opportunity with Raine & Horne`;
    if (appraisalType === APPRAISAL_TYPES.informationMemorandum) return `Information Memorandum: ${address}`;
    if (appraisalType === APPRAISAL_TYPES.commercialListingProposal) return `Commercial Listing Submission: ${address}`;
    if (appraisalType === APPRAISAL_TYPES.businessListingProposal) return `Business For Sale: ${street}`;
    if (appraisalType === APPRAISAL_TYPES.residentialInformationMemorandum) return `Residential Information Memorandum: ${address}`;
    if (appraisalType === APPRAISAL_TYPES.tenantHandbook) return `Tenant Information Guide`;
    return "";
};

const getHeader = (name, displayAddress, suburb, postcode, state, street, appraisalType) => {
    const _name = `<p style="text-align: center;">Dear ${name},</p>`;
    let _displayAddress = displayAddress ? `<p style="text-align: center;">RE: ${displayAddress}</p>` : '';
    if (appraisalType === APPRAISAL_TYPES.prospecting && (state || postcode)) {
        _displayAddress = `<p style="text-align: center;">RE: Prospect Area – ${suburb}, ${state} ${postcode}</p>`;
    }
    if (appraisalType === APPRAISAL_TYPES.businessListingProposal && street) {
        _displayAddress = `<p style="text-align: center;">RE: ${street}</p>`;
    }
    return _name + _displayAddress;
};

const getMessage = (type, vendor, property, team, category, appraisalType) => {
    return `
    ${getHeader(
        vendor.name || vendor.firstName,
        property.displayAddress,
        property?.address?.suburb?.name,
        property?.address?.suburb?.postcode,
        property?.address?.suburb?.state?.abbreviation,
        property?.address?.street,
        appraisalType
    )}
    
    ${email.messages[category][type]}
    
    <p style="text-align: center; margin: 0">Kind regards,</p>
    <p style="text-align: center; margin: 0">${team.leaderName}</p>
    <p style="text-align: center; margin: 0">${team.phone || ''}</p>
    <p style="text-align: center; margin: 0">${team.email || ''}</p>
    `;
}

const getButtonText = type => {
    const texts = {
        preAppraisal: 'Find out more',
        listingProposal: 'View Proposal',
        marketUpdate: 'View Market Report',
        agentProfiling: 'Find out more',
        prospecting: 'View Proposal',
        informationMemorandum: 'View IM',
        commercialListingProposal: 'View Submission',
        businessListingProposal: 'View Proposal',
        residentialInformationMemorandum: 'View IM',
    }
    return texts[type] || 'View Proposal'
}

const fillRecipient = (recipient, property, vendor, team, appraisalId, appraisalType, category, isCommercial) => {
    const leaderName = isCommercial ? team.leaderName : team.leaderName.split(' ')[0];
    return produce(recipient, _recipient => {
        const link = window.location.origin + `/proposal-view/` + appraisalId;
        console.log('Sms', _recipient.phone.message)
        if (_recipient.phone.message === null || _recipient.phone.message === '') {
            _recipient.phone.message = sms.getSms(appraisalType, vendor.name || vendor.firstName, leaderName, team.phone, link, team.officeName, property.displayAddress, property?.address?.suburb?.name)
        } else {
            _recipient.phone.message = _recipient.phone.message.replace(/Hi .*,/, `Hi ${vendor?.name || vendor?.firstName},`);
            _recipient.phone.message = _recipient.phone.message.replace(/proposal-view\/.*./, `proposal-view/${appraisalId}.`);
            _recipient.phone.message = _recipient.phone.message.replace(/null/, `${appraisalId}`);
        }
        if (_recipient.email.subject === null || _recipient.email.subject === '') {
            _recipient.email.message = getMessage(appraisalType, vendor, property, team, category, appraisalType);
            _recipient.email.subject = getSubject(property.displayAddress, property.address?.street, appraisalType);
        } else {
            _recipient.email.message = _recipient.email.message.replace(/<p style="text-align: center;">.*Dear .*,<\/p>/, `<p style="text-align: center;">Dear ${vendor?.name || vendor?.firstName},</p>`);
        }
    })
};

const Send = ({goBack, fixedPositionStyle}) => {
    const [tabValue, setTabValue] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [printInProgress, setPrintInProgress] = useState(false);
    const dispatch = useDispatch();
    const {showToastMessage} = useToast();

    const appraisalIdFromUrl = useParams().appraisalId;
    const appraisalId = useSelector(({appraisal}) => appraisal.id, shallowEqual) || appraisalIdFromUrl;
    const team = useSelector(({appraisal}) => appraisal.team, shallowEqual);
    const meta = useSelector(({appraisal}) => appraisal.meta, shallowEqual);
    const property = useSelector(({appraisal}) => appraisal.property, shallowEqual);
    let vendor = useSelector(({appraisal}) => appraisal.vendor, shallowEqual);
    const owners = useSelector(({appraisal}) => appraisal.owners, shallowEqual);
    const displayAddress = useSelector(({appraisal}) => appraisal.property.displayAddress, shallowEqual);
    const category = useSelector(({appraisal}) => appraisal.meta.category, shallowEqual);
    const recipient = useSelector(({appraisal}) => appraisal.recipient, shallowEqual);

    const appraisalType = meta.appraisalType;
    const {isCommercial} = useCommercial()

    if (appraisalType === APPRAISAL_TYPES.listingProposal && meta.flkEnabled && owners?.length !== 0) {
        vendor = owners[0];
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        dispatch(updateRecipient(
            fillRecipient(recipient, property, vendor, team, appraisalId, appraisalType, category, isCommercial),
        ));
        // TODO: FIX
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property, vendor, team, appraisalId, appraisalType]);

    const sendProposal = type => {

        const sendBodyOptions = {
            type, property, meta, team, recipient, vendor, owners,
            buttonText: getButtonText(appraisalType),
            isCommercial
        };

        SendService.sendAppraisal(appraisalId, getSendBody(sendBodyOptions))
            .then(() => setModalShow(true))
            .catch(() => {
                showToastMessage(
                    getErrorToastMessage("Something went wrong, please try again later"),
                );
            });
    }

    const printAppraisal = () => {
        setPrintInProgress(true)
        AppraisalsService.printAppraisal(appraisalId).then(resp => {
            const file = new Blob(
                [resp.data],
                {type: 'application/pdf'});
            const fileURL = window.URL.createObjectURL(file);
            let tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', displayAddress);
            tempLink.click();
        }).finally(() => {
            setPrintInProgress(false)
        })
    }

    const classes = useStyles();

    const copyLink = () => {
        const link = window.location.origin + `/proposal-view/` + appraisalId;
        navigator.clipboard.writeText(link);
        showToastMessage(getSuccessToastMessage("Link copied"));
    };

    return (
        <div>
            <Grid container spacing={3} className={cn('px-5', {[classes.fixedPosition]: fixedPositionStyle})}>
                <Grid item lg={4} xs={12} className="d-flex justify-content-between flex-column"
                      style={{background: '#fff'}}>
                    <div>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="Tabs"
                            className={classes.tabs}
                        >
                            <Tab icon={<DesktopMacIcon fontSize='large'/>} aria-label="email"/>
                            <Tab icon={<PhoneIphoneIcon fontSize='large'/>} aria-label="phone"/>
                        </Tabs>
                        <div
                            role="tabpanel"
                            hidden={tabValue !== 0}
                            id={`scrollable-prevent-tabpanel-${0}`}
                            aria-labelledby={`scrollable-prevent-tab-${0}`}
                        >
                            <div className="card-body px-0">
                                {appraisalType !== 'listingProposal' || !meta.flkEnabled || owners?.length === 0 ? (
                                    <div className={classes.inputDiv}>
                                        <Typography>Recipient(s):</Typography>
                                        <TextField
                                            className="mb-3 my-3 mx-4"
                                            id="recipient-name"
                                            fullWidth
                                            variant="outlined"
                                            label="Name(s)"
                                            size="small"
                                            value={vendor.name}
                                            onChange={e => dispatch(updateVendor({name: e.target.value}))}
                                        />
                                        <TextField
                                            className="mb-3 my-3 mx-4"
                                            id="recipient-email"
                                            fullWidth
                                            variant="outlined"
                                            label="Email(s)"
                                            size="small"
                                            value={vendor.email}
                                            onChange={e => dispatch(updateVendor({email: e.target.value}))}
                                        />
                                    </div>
                                ) : (
                                    <div className={`mb-3 my-3`}>
                                        <Typography>Recipient(s):</Typography>
                                        {owners.map((owner, index) => {
                                            return (
                                                <Typography>Recipient({index + 1}): {owner.firstName} {owner.lastName} {owner.email}</Typography>)
                                        })}
                                    </div>
                                )}

                                <TextField
                                    className="mb-3 my-3"
                                    id="recipient-subject"
                                    fullWidth
                                    label="Subject"
                                    variant="outlined"
                                    size="small"
                                    value={recipient.email.subject}
                                    onChange={e => dispatch(updateRecipient({
                                        email: {
                                            ...recipient.email,
                                            subject: e.target.value
                                        }
                                    }))}
                                />
                                <div className={classes.inputDiv}>
                                    <Typography>
                                        {`Characters: ${recipient.email.subject?.length || 0}`}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            hidden={tabValue !== 1}
                            id={`scrollable-prevent-tabpanel-${1}`}
                            aria-labelledby={`scrollable-prevent-tab-${1}`}
                        >
                            <div className="card-body px-0">
                                {appraisalType !== 'listingProposal' || !meta.flkEnabled || owners?.length === 0 ? (
                                    <div className={classes.inputDiv}>
                                        <Typography>Recipient(s):</Typography>
                                        <TextField
                                            className="mb-3 my-3 mx-4"
                                            id="sms-recipient-name"
                                            fullWidth
                                            variant="outlined"
                                            label="Name(s)"
                                            size="small"
                                            value={vendor.name}
                                            onChange={e => dispatch(updateVendor({name: e.target.value}))}
                                        />
                                        <TextField
                                            className="mb-3 my-3 mx-4"
                                            id="recipient-mobile"
                                            fullWidth
                                            label="Mobile(s)"
                                            variant="outlined"
                                            size="small"
                                            value={vendor.phone}
                                            onChange={e => dispatch(updateVendor({phone: e.target.value}))}
                                        />
                                    </div>
                                ) : (
                                    <div className={`mb-3 my-3 mx-4`}>
                                        <Typography>Recipient(s):</Typography>
                                        {owners.map((owner, index) => {
                                            return (
                                                <Typography>Recipient({index + 1}): {owner.firstName} {owner.lastName} {owner.phone}</Typography>)
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={classes.sendWrapper}>
                            <Button
                                onClick={() => sendProposal('email')}
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.sendButton}
                                disabled={ vendor?.email?.length === 0}
                            >
                                Send Email
                            </Button>
                            <Button
                                onClick={() => sendProposal('sms')}
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.sendButton}
                                disabled={vendor?.phone?.length === 0}
                            >
                                Send SMS
                            </Button>
                            <Button
                                onClick={() => sendProposal('both')}
                                variant="contained"
                                color="primary"
                                disableElevation
                                className={classes.sendButton}
                                disabled={vendor?.email?.length === 0 || vendor?.phone?.length === 0}
                            >
                                Send Both
                            </Button>
                        </div>
                    </div>
                    <div className={classes.footerContainer}>
                        <Button
                            onClick={printAppraisal}
                            variant="contained"
                            color="primary"
                            disableElevation
                            className={classes.sendButton}
                            disabled={printInProgress}
                        >
                            Print Proposal
                        </Button>
                        {[
                            ProposalTypes.marketUpdate,
                            ProposalTypes.agentProfiling,
                            ProposalTypes.informationMemorandum,
                            ProposalTypes.residentialInformationMemorandum,
                        ].includes(appraisalType) && (
                            <Button
                                onClick={copyLink}
                                variant="outlined"
                                disableElevation
                                className={classes.copyButton}
                                disabled={!appraisalId}
                                startIcon={<LinkIcon/>}
                            >
                                Copy link
                            </Button>
                        )}
                        {!goBack ? "" : (
                            <Button
                                onClick={goBack}
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.sendButton}
                                disabled={printInProgress}
                            >
                                {'< go back'}
                            </Button>
                        )}
                    </div>

                </Grid>
                <Grid item lg={8} xs={12}>
                    {tabValue === 0 &&
                        <EmailTemplate
                            appraisalId={appraisalId}
                            recipient={recipient}
                            buttonText={getButtonText(appraisalType)}
                            isCommercial={isCommercial}
                        />
                    }
                    {tabValue === 1 &&
                        <SmsTemplate
                            recipient={recipient}
                        />
                    }
                </Grid>
            </Grid>
            <SuccessModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                children={
                    <div>
                        Your proposal has been sent successful.
                        <br/>
                        Be sure to check in with the recipient within 24-48 hrs to ensure
                        they received it and begin your follow up.
                    </div>
                }
            />
            <Backdrop className={classes.backdrop} open={printInProgress}>
                <CircularProgress color="inherit"/>
                <Typography component="h4" variant="h4" className="mt-10">
                    Please wait a minute whilst we compose your printed proposal
                </Typography>
            </Backdrop>
        </div>
    )
}

Send.propTypes = {
    goBack: PropTypes.func,
    fixedPositionStyle: PropTypes.bool,
};

Send.defaultProps = {
    fixedPositionStyle: false,
};

export default Send;

const useStyles = makeStyles(theme => ({
    tabs: {
        paddingTop: '15px',
    },
    inputDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sendButton: {
        display: 'flex',
        color: '#fff',
        fontWeight: 600,
        padding: '10px 20px',
    },
    copyButton: {
        color: '#0172dc',
    },
    footerContainer: {
        position: 'sticky',
        bottom: 10,
        display: 'flex',
        justifyContent: 'space-between',
    },
    sendWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '& $sendButton:nth-child(even)': {
            margin: '0 10px',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(0, 0, 0, 0.5) !important',
    },
    fixedPosition: {
        position: 'fixed',
        top: 0,
        left: 65,
        background: '#fff',
        zIndex: 50,
    },
}));
