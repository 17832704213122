import React, { useContext, useEffect, useState } from "react";
import { AppraisalContext } from "../../modules/Appraisals/AppraisalContext";
import { Card, IconButton, makeStyles, Tooltip, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ReactCardFlip from "react-card-flip";
import EditorComponent from "../../modules/Common/EditorComponent";
import SaveButton from "app/components/ui/Buttons/Button";


const useStyles = makeStyles( () => ({
  description: ({ preview }) => ({
    padding: "10px",
    width: "100%",
    height: "100%",
    position: "relative",
    "& textarea": {
      border: "none",
      resize: "none",
      padding: "10px",
      width: "100%",
      height: preview ? "100%": "calc(100% - 60px)",
      lineHeight: '21px',
      letterSpacing: '0.3px',
      background: 'transparent',
    },
    "& button:not(.btn-brand-yellow)": {
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    "& .mce-content-body": {
      maxHeight: "300px",
      padding: "10px 0",
      width: "100%",
      height: "100%",
      lineHeight: "21px",
      letterSpacing: "0.3px",
      background: "transparent",
      overflow: "auto",
      textAlign: "initial",
    },
    "& .container": {
      height: "100%",
    }
  }),
  infoButton: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  tooltip: {
    fontSize: "12px",
    margin: 0,
  },
  editorBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  saveButton: {
    fontSize: "0.875rem",
    textTransform: "uppercase",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginTop: "10px",
  },
}))

const textLimit = 450;

const FlipIcon = ({ title, onClick, show }) => {
  const classes = useStyles();
  if (!show) return ''
  return (
    <Tooltip
      title={title}
      placement="top"
      classes={{tooltip: classes.tooltip}}
    >
      <IconButton
        classes={{root: classes.infoButton}}
        aria-label="info"
        color="primary"
        onClick={onClick}
      >
        <InfoIcon fontSize="large"/>
      </IconButton>
    </Tooltip>
  )
}

const FlipWrapper = ({ description, onDescriptionChange, children, isFlipped, setIsFlipped, showDescription }) => {
  const { preview } = useContext(AppraisalContext)
  const classes = useStyles({preview});
  const [value, setValue] = useState('');
  const [textCount, setTextCount] = useState(0);

  useEffect(() => {
    setValue(description);
    setTextCount(description?.replace(/<[^>]+>/g, '')?.replace(/&nbsp;/g, ' ')?.length || 0);
  }, [description])

  const saveChanges = () => {
    if (onDescriptionChange) onDescriptionChange(value);
    setIsFlipped(false);
  };

  const handleTextChange = (value) => setValue(value);

  const cancelChanges = () => {
    setIsFlipped(false);
    if (onDescriptionChange) onDescriptionChange(description);
  };

  const showFlipIcon = (preview && description) || (showDescription && description);
  const tooltipTitle = preview ? "View property commentary" : "Add property commentary"

  const onChange = (value) => {
    const textCount = value?.replace(/<[^>]+>/g, '')?.replace(/&nbsp;/g, ' ')?.length;
    setTextCount(textCount);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} containerStyle={{ height: "100%", minHeight: 250 }}>
      <div className="position-relative h-100">
        {children}
        <FlipIcon
          show={showFlipIcon}
          title={tooltipTitle}
          onClick={() => setIsFlipped(true)}
        />
      </div>
        <Card
          variant="outlined"
          classes={{ root : classes.description }}
        >
          {preview ? (
            <div
              onMouseLeave={() => setIsFlipped(false)}
              disabled={preview}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ) : (
            <div className={classes.editorBlock}>
              <EditorComponent
                inline
                value={value}
                onBlur={handleTextChange}
                disabled={preview}
                type={"comparables"}
                placeholder="Click to add property commentary here..."
                onChange={val => onChange(val)}
                notShowCharactersLimit={true}
                charactersLimit={450}
              />
              <div className={classes.buttonsContainer}>
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={cancelChanges}
                    color="primary"
                    style={{ position: "relative" }}
                  >
                    Cancel
                  </Button>
                  <SaveButton
                    onClick={saveChanges}
                    variant="btn-brand-yellow"
                    className={classes.saveButton}
                  >
                    Save
                  </SaveButton>
                </div>
                <div className="d-flex justify-content-between">
                  <div>Text size limit: {textLimit}.</div>
                  <div>Characters left: {textLimit - textCount}</div>
                </div>
              </div>
            </div>
          )}
        </Card>
    </ReactCardFlip>
  )
}

export default React.memo(FlipWrapper)
