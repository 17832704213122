import React from "react";
import { Box, Typography } from "@material-ui/core";

export const NoData = ({ children = "No data" }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="#696b6d"
  >
    <Typography variant="h5">{children}</Typography>
  </Box>
);
