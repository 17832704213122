import {
  Button,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { addionalFilterTooltip } from "shared/constants";

import IconButton from "app/components/ui/Buttons/IconButton";

const useStyles = makeStyles({
  button: {
    borderRadius: 0,
    borderLeft: "none",
    width: 90,
  },
  icon: {
    backgroundColor: "white !important",
    fill: "#fdb818",
  }
});

const SearchBox = ({
  onChangeSearch,
  value,
  placeholder = "Search...",
  className = '',
  textFieldClassName = "",
  additionalFilter = null,
  onCloseAdditionalFilter = () => null,
}) => {
  const classes = useStyles();

  const [isAdditionalFilter, setAdditionalFilter] = useState(false);
  const onAdditionalFilterClick = () => {
    if (isAdditionalFilter) {
      onCloseAdditionalFilter();
    }
    setAdditionalFilter(!isAdditionalFilter);
  };

  const onCLick = () => {
    onChangeSearch(value);
  };

  return (
    <>
      <div className={`d-flex d-flex-row flex-nowrap ${className || ""}`}>
        <TextField
          className={`w-100 ${textFieldClassName || ""}`}
          id="search-box"
          placeholder={placeholder}
          variant="outlined"
          type="search"
          value={value}
          onChange={e => onChangeSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="default" />
              </InputAdornment>
            ),
            endAdornment: additionalFilter ? (
              <IconButton
                icon={"tuneIcon"}
                className={classes.icon}
                onClick={onAdditionalFilterClick}
                tooltip={addionalFilterTooltip}
                tooltipOffsetX={20}
                tooltipOffsetY={-45}
              />
            ) : (
              <React.Fragment />
            ),
          }}
        />

        {/* THIS BUTTON IS NOT RELEVANT, WE DO SEARCH ON TextField VALUE CHANGE */}
        <Button
          variant="outlined"
          disableRipple
          onClick={onCLick}
          classes={{ root: classes.button }}
        >
          Search
        </Button>
      </div>
      {isAdditionalFilter ? additionalFilter : <React.Fragment />}
    </>
  );
};

export default SearchBox;
