import { createSelector } from "reselect";

import {
  PROPOSALS_TYPES,
  PROPOSALS_BY_TYPE,
} from "app/constants/proposalsConstants";
import { formatDateISO8601ToDdMmYy, getAppraisalTitle } from "shared/utils";

export const getIsLoadingProposalsList = ({ proposals }) =>
  proposals.isLoadingProposalsList;
export const getIsLoadingProposalsListWidgets = ({ proposals }) =>
  proposals.isLoadingProposalsListWidgets;
export const getIsLoadingSentProposalsByLimit = ({ proposals }) =>
  proposals.isLoadingSentProposalsByLimit;
export const getIsLoadingShortProposalList = ({ proposals }) =>
  proposals.isLoadingShortProposalList;
export const getSentProposalsByLimitTotal = ({ proposals }) =>
  proposals.sentProposalsByLimit.data?.total;
export const getSentProposalsByLimitPagesTotal = ({ proposals }) =>
  proposals.sentProposalsByLimit.data?.pages;
export const getRecentProposalsTotal = ({ proposals }) =>
  proposals.recentProposals.total;
export const getPrevRecentProposalsTotal = ({ proposals }) =>
  proposals.recentProposals.prevTotal;
export const getSentProposalsTotal = ({ proposals }) =>
  proposals.sentProposals.total;
export const getPrevSentProposalsTotal = ({ proposals }) =>
  proposals.sentProposals.prevTotal;
export const getUnopenedProposalsTotal = ({ proposals }) =>
  proposals.unopenedProposals.total;
export const getPrevUnopenedProposalsTotal = ({ proposals }) =>
  proposals.unopenedProposals.prevTotal;
export const getArticlesArray = ({ proposals }) => proposals.articles;
export const getProposalsTotalPages = ({ proposals }) =>
  proposals.proposalsList.pages;
export const getProposalsTotal = ({ proposals }) =>
  proposals.proposalsList.total;
const getSentProposalsByLimitArray = ({ proposals }) =>
  proposals.sentProposalsByLimit.data?.appraisals || [];
export const getProposalsByTypeArray = ({ proposals }) =>
  proposals.proposalsByType;
export const getProposalsListArray = ({ proposals }) =>
  proposals.proposalsList.appraisals || [];
export const getProposalsMetrics = ({ proposals }) =>
  proposals.proposalsMetrics;

export const getAverageReadingTime = ({ proposals }) =>
  proposals.readingTime.avg;
export const getPrevAverageReadingTime = ({ proposals }) =>
  proposals.readingTime.prevAvg;

export const getProposalsList = createSelector(
  getProposalsListArray,
  proposals => {
    return proposals.map(proposal => ({
      id: proposal.proposal_id,
      property: proposal.display_addres,
      office: proposal.office_name,
      opened: !!proposal.opens,
      member: proposal.team_name,
      opens: proposal.opens || 0,
      prints: proposal.prints || 0,
      proposalType: PROPOSALS_TYPES[proposal.type],
      updated: formatDateISO8601ToDdMmYy(proposal.updated_at),
      timeStamp: Date.parse(proposal.updated_at),
      sent: proposal.sent_at !== null,
      flk: proposal.flk || false,
    }));
  },
);

export const getClicksPercent = ({ proposals }) => {
  const clicks = proposals.clicksPercent.currClicks;
  return Math.round(Number(clicks));
};

export const getPervClicksPercent = ({ proposals }) => {
  const clicks = proposals.clicksPercent.prevClicks;
  return Math.round(Number(clicks));
};

export const getAverageOpens = ({ proposals }) => {
  const opens = proposals.averageOpens;
  return `${Math.round(opens)} Clicks`;
};

export const getSentProposalsByLimit = createSelector(
  getSentProposalsByLimitArray,
  appraisalsArray => {
    return appraisalsArray.map(appraisal => ({
      id: appraisal._id,
      title: getAppraisalTitle(appraisal),
      appraisalType: PROPOSALS_TYPES[appraisal.meta.appraisalType],
      sent_at: formatDateISO8601ToDdMmYy(appraisal.sent_at),
      officeName:
        appraisal.team.officeName?.replace("Raine & Horne ", "") || "",
      opened: appraisal.opened,
      flk: appraisal.flk,
    }));
  },
);

export const getProposalsByTypeTotalCount = createSelector(
  getProposalsByTypeArray,
  proposalsArray => {
    let totalProposals = 0;
    proposalsArray.forEach(item => (totalProposals += item.count));
    return totalProposals;
  },
);


export const getShortProposalsCount = ({ proposals }) =>
  proposals.shortProposalList.totalCount;

export const getShortProposals = ({ proposals }) =>
  proposals.shortProposalList.appraisals || []

export const getShortProposalsList = createSelector(
    getShortProposals,
    proposals => {
      return proposals.map(proposal => ({
        id: proposal.proposal_id,
        officeName: proposal.office_name?.replace("Raine & Horne ", "") || "",
        displayAddres: proposal.display_addres,
        status: proposal.status,
        appraisalType: PROPOSALS_TYPES[proposal.type],
        sentAt: formatDateISO8601ToDdMmYy(proposal.sent_at),
        flk: proposal.flk,
      }));
    },
  );

export const getProposalsByType = createSelector(
  getProposalsByTypeArray,
  proposalsArray => {
    const array = [];
    const otherTypes = {
      color: PROPOSALS_BY_TYPE.otherTypes.color,
      title: PROPOSALS_BY_TYPE.otherTypes.title,
      count: 0,
    };

    proposalsArray.forEach(proposal => {
      if (PROPOSALS_BY_TYPE[proposal._id]) {
        array.push({
          color: PROPOSALS_BY_TYPE[proposal._id].color,
          title: PROPOSALS_BY_TYPE[proposal._id].title,
          count: proposal.count,
        });
      } else {
        otherTypes.count += proposal.count;
      }
    });

    if (otherTypes.count) {
      array.push(otherTypes);
    }
    return array;
  },
);
