import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  fetchMessagesList,
  fetchReadMessage,
  fetchMessagesListByType,
  fetchDeleteMessage,
  fetchReadAllMessages,
} from "../actions";
import {
  setIsLoadingMessagesList,
  setMessagesList,
  updateMessage,
  updateUnreadedMessagesList,
  decreaseTotalUnreadedMessages,
  updateMessagesListByType,
} from "../reducers/messagesReducer";
import MessagesService from "app/api/MessagesService";

export function* fetchMessagesListSaga({ payload }) {
  try {
    yield put(setIsLoadingMessagesList(true));
    const { page, speciality } = payload;
    const { data } = yield call(
      MessagesService.getMessages,
      ...[speciality, page],
    );
    yield put(setMessagesList(data));
    yield put(setIsLoadingMessagesList(false));
  } catch (e) {
    console.error(e.message);
  }
}

export function* fetchMessagesListByTypeSaga({ payload }) {
  try {
    yield put(setIsLoadingMessagesList(true));
    const { page, activeTab, speciality } = payload;
    const { data } = yield call(
      MessagesService.getMessages,
      ...[speciality, page, activeTab],
    );
    yield put(updateMessagesListByType(data));
    yield put(setIsLoadingMessagesList(false));
  } catch (e) {
    console.error(e.message);
  }
}

export function* fetchReadMessageSaga({ payload }) {
  try {
    const { index, newMessage } = payload;
    const { data } = yield call(MessagesService.readMessage, newMessage);
    yield put(updateMessage({ index, message: data }));
    yield put(updateUnreadedMessagesList({ message: data }));
    yield put(decreaseTotalUnreadedMessages());
  } catch (e) {
    console.error(e.message);
  }
}

export function* fetchReadAllMessagesSaga({ payload }) {
  try {
    const { newMessages, speciality } = payload;
    yield all(
      newMessages.map(message => call(MessagesService.readMessage, message)),
    );
    yield call(fetchMessagesListSaga, { payload: { speciality } });
  } catch (e) {
    console.error(e.message);
  }
}

export function* fetchDeleteMessageSaga({ payload }) {
  try {
    const { newMessage, page, activeTab } = payload;
    yield call(MessagesService.deleteMessage, newMessage?._id, newMessage);
    yield call(fetchMessagesListByTypeSaga, { payload: { page, activeTab } });
  } catch (e) {
    console.error(e.message);
  }
}

export default function* watchMessages() {
  yield all([
    yield takeLatest(fetchMessagesList.type, fetchMessagesListSaga),
    yield takeLatest(fetchMessagesListByType.type, fetchMessagesListByTypeSaga),
    yield takeLatest(fetchReadMessage.type, fetchReadMessageSaga),
    yield takeLatest(fetchReadAllMessages.type, fetchReadAllMessagesSaga),
    yield takeLatest(fetchDeleteMessage.type, fetchDeleteMessageSaga),
  ]);
}
