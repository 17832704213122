import { APPRAISAL_TYPES } from "../../constants/appraisalsConstants";

const itemSent = (type, displayAddress, suburb) => {
  const strings = {
    preAppraisal: "a proposal for your property",
    listingProposal: "a proposal for your property",
    marketUpdate: "a bespoke market report for your area",
    agentProfiling: "an agency profile",
    prospecting: `Prospect Proposal for ${suburb}, ${displayAddress}`,
    commercialListingProposal: "a commercial listing submission",
    businessListingProposal: "a proposal for your property",
    tenantHandbook: "a Tenant Information Guide",
  };
  if (
    type === APPRAISAL_TYPES.informationMemorandum ||
    type === APPRAISAL_TYPES.residentialInformationMemorandum
  ) {
    return "a Information Memorandum for " + displayAddress;
  } else {
    return strings[type];
  }
};
export const getSms = (
  type,
  vendorName,
  agentName,
  agentPhone,
  link,
  office,
  displayAddress,
  suburb,
) => {
  let officeName =
    type === APPRAISAL_TYPES.prospecting ? "Raine & Horne" : office;

  return `Hi ${vendorName},
    
${agentName} from ${officeName} has sent you ${itemSent(
    type,
    displayAddress,
    suburb,
  )}. Click the link to view ${link}
    
If you have any questions, please contact ${agentName} on ${agentPhone}`;
};
