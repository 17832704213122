import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { updateTeam } from "redux/reducers";
import { getImageUrl } from "shared";
import HideContainer from "../../components/HideContainer";
import { Title } from "../BlockContainer";
import { makeStyles } from "@material-ui/core";
import defaultImage from "app/assets/images/article.jpeg"

const useStyles = makeStyles(theme => ({
  agentPhoto: {
    float: "right",
    maxWidth: "300px",
    marginLeft: "25px",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      float: "none",
      maxWidth: "100%",
      width: "100%",
    },
  },
  articleContent: {
    display: "inline",
    fontSize: "16px",
  },
}));


const AboutTeamSection = ({ isCommercial }) => {
  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);
  const dispatch = useDispatch();
  const classes = useStyles();

  const updateTeamDetailsTitle = val => {
    dispatch(updateTeam({ detailsTitle: val }));
  };

  if (!team?.about?.length) return "";

  return (
    <HideContainer
      title="Hide Sub Section"
      value={team.aboutHidden}
      onChange={val => dispatch(updateTeam({ aboutHidden: val }))}
      className="d-flex flex-column"
    >
      <Title
        value={team.detailsTitle}
        onBlur={updateTeamDetailsTitle}
        className={`common-subTitle mt-7 ${isCommercial ? "common-commercial-title": ""}`}
        maxLength={30}
      />
      {!team.aboutHidden && (
        <>
          <div className="mt-5">
            <img
              src={team.image ? getImageUrl(team.image) : defaultImage }
              className={classes.agentPhoto}
              alt="My team"
            />
            {team.about && (
              <div className={classes.articleContent}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: team.about
                      .replace(/<img[^>]*>/g, "")
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </HideContainer>
  )
}

export default AboutTeamSection;
