import { shallowEqual, useSelector } from "react-redux";

export const useNz = () => {
    const country = useSelector(
        ({ appraisal }) => appraisal.country,
        shallowEqual,
    );
    const isNz = country === 'New Zealand'

    return {
        isNz,
    };
};
