import axios from "axios";
import API_URL from "./api";

export default class MessagesService {
  static getMessages = (
    speciality = "residential",
    page = 1,
    activeTab = "all",
    filter = "created_at",
    sort = "descending",
    limit = 10,
  ) => {
    return axios.get(
      `${API_URL}/messages?speciality=${speciality}&page=${page}&filter=${filter}&sort=${sort}&limit=${limit}&activeTab=${activeTab}`,
    );
  };

  static readMessage = body => {
    const id = body._id;
    return axios.put(`${API_URL}/messages/${id}`, body);
  };

  static deleteMessage = (id, body) => {
    return axios.put(`${API_URL}/messages/${id}/delete`, body);
  };

  static sendMessage = body => {
    return axios.post(`${API_URL}/messages`, body);
  };
}
