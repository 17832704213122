import React from "react";
import SelectString from "./SelectString";

interface ContextProps {
  searchByAddress: boolean;
  classification: "residential" | "commercial" | undefined;
  paramsFields: {[key: string]: string};
  setParamsFields(val: React.SetStateAction<any> ): void;
}

const SubParamsFields = ({ searchByAddress, classification, paramsFields, setParamsFields }: ContextProps) => {
  const setState = (key: string, newValue: string) => {
    return setParamsFields({...paramsFields, [key]: newValue})
  } 

  if (searchByAddress) {
    return <React.Fragment />;
  }

  const residentialParamsFields = (
    <React.Fragment>
      <SelectString
        value={paramsFields.bedrooms}
        onChange={(value: string) => setState("bedrooms", value)}
        title={"Bedrooms"}
        options={[1, 2, 3, 4, 5, 6]}
        className="mr-3"
        width={87}
      />
      <SelectString
        value={paramsFields?.bathrooms}
        onChange={(value: string) => setState("bathrooms", value)}
        title={"Bathrooms"}
        options={[1, 2, 3, 4, 5, 6]}
        className="mr-3"
        width={93}
      />
      <SelectString
        value={paramsFields?.parking}
        onChange={(value: string) => setState("parking", value)}
        title={"Parking"}
        options={[1, 2, 3, 4, 5, 6]}
        className="mr-3"
        width={73}
      />
    </React.Fragment>
  );

  const commercialParamsFields = (
    <React.Fragment>
      <SelectString
        value={paramsFields?.parking}
        onChange={(value: string) => setState("parking", value)}
        title={"Parking"}
        options={[1, 2, 3, 4, 5, 6]}
        className="mr-3"
        width={73}
      />
    </React.Fragment>
  );

  return (
    <div className="d-flex">
      {classification === "residential"
        ? residentialParamsFields
        : commercialParamsFields}
    </div>
  );
};

export default SubParamsFields;
