import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { AppraisalContext } from "../AppraisalContext";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import cn from "classnames";
import { useCommercial } from "app/hooks";

const useArticleButtonStyles = makeStyles(theme => ({
  articleButtonContainer: {
    position: "relative",
    padding: '35px',
    paddingTop: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
    alignSelf: 'start'

  },
  articleStatusButton: {
    position: "absolute",
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  articleButton: ({ isCommercial }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'none',
    fontSize: '15px',
    lineHeight: '19px',
    textAlign: 'center',
    transition: 'color 0.2s ease',
    cursor: 'pointer',
    '& img': {
      width: 90,
      height: 90,
    },
    '& span': {
      width: 108,
      color: 'inherit',
      marginTop: 15,
    },
    '&:hover': {
      width: 108,
      color: isCommercial ? "#FFD200" : "#FFB300",
    },
    '&:active': {
      opacity: 0.6
    },
  }),
  disabled: {
    pointerEvents: 'none',
    opacity: 0.2,
  },
}))

const ArticleButton = ({ title, onClick, imgSrc, imgAlt, active, onChangeStatus, remove }) => {
  const { preview } = useContext(AppraisalContext)
  const { isCommercial } = useCommercial();
  const classes = useArticleButtonStyles({ preview, isCommercial });
  if ((preview && !active) || (active === undefined) || remove) return '';

  const StatusIcon = () => {
    if (!onChangeStatus || preview) return '';
    return (
      <Tooltip arrow title={
        <Typography color="inherit" component='h3'>{active ? 'Hide' : 'Show'}</Typography>
      }>
        <IconButton
          disableRipple
          aria-label="change status"
          className={classes.articleStatusButton}
          onClick={() => onChangeStatus(!active)}
        >
          { active ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon /> }
        </IconButton>
      </Tooltip>
    )
  }
  return (
    <div className={classes.articleButtonContainer}>
      <StatusIcon />
      <div
        className={cn(
          classes.articleButton,
          { [classes.disabled]: !active && !!onChangeStatus }
        )}
        onClick={onClick}
      >
        <img src={imgSrc} alt={imgAlt} />
        <span>
          {title}
        </span>
      </div>
    </div>
  )
}

export default React.memo(ArticleButton);
