import React, { useCallback, useState, useEffect } from "react";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { ProposalTypes } from "../../../interfaces";
import { setType } from "../redux/actions";
import getTypesList from "./TypesList";
import { RootState } from "redux/store";

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: "50px 0",
    display: "inline-grid",
    gridTemplateColumns: "repeat(3, 333px)",
    width: "100vw",
    gridGap: "20px",
    overflow: "scroll",
    overflowX: "auto",
    overflowY: "auto",
    margin: "auto",
  },
  mobileContainer: {
    padding: "50px 0",
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    gridGap: "20px",
    overflow: "scroll",
    overflowX: "auto",
    overflowY: "auto",
    margin: "auto",
  },
  card: {
    height: "220px",
    maxWidth: 345,
    width: "100%",
    cursor: "pointer",
    border: "1px solid transparent",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "24px",
    boxShadow: "0px 0px 7px rgb(0 0 0 / 16%)",
    borderRadius: "8px",
    "&:hover": {
      border: "1px solid #333333",
    },
  },
  mobileCard: {
    color: "#333333",
    padding: "0 15px",
    height: "60px",
    width: "100%",
    cursor: "pointer",
    border: "1px solid transparent",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 0px 7px rgb(0 0 0 / 16%)",
    borderRadius: "8px",
  },
  image: {
    marginRight: "16px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "28px",
      height: "28px",
    },
  },
  imgLink: {
    lineHeight: "150px",
    backgroundColor: "lightgrey",
    fontSize: "20px",
  },
  label: {
    marginTop: "15px",
  },
  mobileLabel: {
    display: "flex",
    alignItems: "center",
    margin: 0,
  },
  cardsDiv: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  selectedType: {
    backgroundColor: "gray",
  },
  arrow: {
    display: "flex",
    alignItems: "center",
    color: "#333333",
  },
}));

const SelectType = () => {
  const dispatch = useDispatch();
  const [isSmallWidth, setIsSmallWidth] = useState(window.innerWidth < 1200);
  const proposalTypes = useSelector(
    (state: RootState) => state.createProposal.office?.proposalTypes || [],
    shallowEqual,
  );

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const classes = useStyles();

  const handleResize = () => {
    setIsSmallWidth(window.innerWidth < 1200);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const getOfficeTypeList = useCallback(() => {
    return getTypesList(proposalTypes);
  }, [proposalTypes]);

  const typesList = getOfficeTypeList();

  const onCardClick = (type: ProposalTypes) => {
    dispatch(setType(type));
  };

  const MobileView = () => {
    return (
      <Grid container className={classes.mobileContainer}>
        {typesList.map(type => {
          return (
            <Grid
              item
              key={type.type}
              className="d-flex"
              style={{ padding: "0 15px" }}
            >
              <div
                className={classes.mobileCard}
                onClick={() => onCardClick(type.type)}
              >
                <div className="d-flex">
                  <span
                    className={`d-flex justify-content-end ${classes.image}`}
                  >
                    <img src={type.imgLink} alt={type.type} />
                  </span>
                  <Typography
                    className={classes.mobileLabel}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    {type.label}
                  </Typography>
                </div>
                <span className={classes.arrow}>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </span>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const DesktopView = () => {
    return (
      <Grid
        container
        className={`${!isSmallWidth ? "justify-content-center" : ""} ${
          classes.container
        }`}
      >
        {typesList.map(type => {
          return (
            <Grid item xs key={type.type} className="d-flex">
              <div
                className={classes.card}
                onClick={() => onCardClick(type.type)}
              >
                <Typography
                  className={classes.label}
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  {type.label}
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="p"
                  style={{
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  {type.sublabel}
                </Typography>
                <span className="d-flex justify-content-end">
                  <img src={type.imgLink} alt={type.type} />
                </span>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return isMobile ? <MobileView /> : <DesktopView />;
};

export default SelectType;
