import React, { useEffect, useState, useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import {
  updateTeam,
  updateProperty,
} from "../../../../../redux/reducers";
import EditorComponent from "../../../Common/EditorComponent";
import StatisticsService from "../../../../api/StatisticsService";
import cn from "classnames";
import BlockContainer from "../BlockContainer";
import HideContainer from "../../components/HideContainer";
import { AppraisalContext } from "../../AppraisalContext";
import SuburbProfile from "./SuburbProfile";
import { HideTab, Tabs } from "../../../Common/CustomizedTabs";
import {getType} from "./index";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  profileTitle: {
    textTransform: "capitalize",
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: 40,
    marginTop: 20,
    lineHeight: "54px !important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: "54px !important",
  },
  separator: {
    width: "100%",
    height: "1px",
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    paddingTop: "48px",
  },
}));

// TODO: REFACTORING
const MarketUpdateReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const { preview } = useContext(AppraisalContext);

  const [salesStatistics, setSalesStatistics] = useState(null);
  const [rentalStatistics, setRentalStatistics] = useState();

  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);
  const property = useSelector(
    ({ appraisal }) => appraisal.property,
    shallowEqual,
  );
  const country = useSelector(
    ({ appraisal }) => appraisal.country,
    shallowEqual,
  );
  const [activeTab, setActiveTab] = useState(
    property?.suburbSalesHidden ? "rentals" : "sales",
  );
  useEffect(() => {
    let isMounted = true;
    if (!preview || (preview && !property.suburbHidden)) {
      if (!!property?.address) {
        setType(getType(property.propertyType));
        const {
          name,
          postcode,
          state: { abbreviation: state },
        } = property.address?.suburb;
        ["sales", "lease"].forEach(category => {
          StatisticsService.getStatistics({
            locality: `${name}+${state}+${postcode}`,
            category: category,
            country: country,
          }).then(resp => {
            if (isMounted && resp.data) prepareStatistics(resp.data, category);
          });
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.address, property.propertyType]);

  const prepareStatistics = (statistics, category) => {
    if (
      !statistics.charts.Houses.data.length &&
      !statistics.charts.Units.data.length
    ) {
      dispatch(updateProperty({ suburbHidden: true }));
    } else {
      dispatch(updateProperty({ suburbHidden: false }));
      //Show/Hide one of tabs Houses ot Units
      if (!statistics.charts.Units.data.length) {
        setType("Houses");
      }
      if (!statistics.charts.Houses.data.length) {
        setType("Units");
      }
    }
    category === "sales"
      ? setSalesStatistics(statistics)
      : setRentalStatistics(statistics);
  };

  const hideSectionHandler = val => {
    dispatch(updateTeam({ reportHidden: val }));
  };

  const hideSuburbProfileHandler = val => {
    dispatch(updateProperty({ suburbHidden: val }));
  };

  const editSection = body => {
    dispatch(updateTeam({ report: body }));
  };

  const onTitleUpdate = title => {
    dispatch(updateTeam({ marketReportTitle: title }));
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateTeam({ marketReportSubtitle: subtitle }));
  };

  return (
    <>
      <HideContainer value={team.reportHidden} onChange={hideSectionHandler}>
        <BlockContainer
          defaultTitle={team.marketReportTitle}
          defaultSubtitle={team.marketReportSubtitle}
          id="market-report"
          onTitleUpdate={onTitleUpdate}
          onSubtitleUpdate={onSubtitleUpdate}
          hide={team.reportHidden}
        >
          <EditorComponent
            value={team.report}
            onBlur={editSection}
            type="coverletter"
            inline
            charactersLimit={1100}
          />
        </BlockContainer>
      </HideContainer>
      <div id="suburb-profile">
        <HideContainer
          title="Hide Sub Section"
          value={property.suburbHidden}
          onChange={hideSuburbProfileHandler}
        >
          {property.suburbHidden ||
          (preview &&
            property.suburbSalesHidden &&
            property.suburbRentalsHidden) ? (
            ""
          ) : (
            <>
              <Typography
                component="h6"
                variant="subtitle1"
                align="center"
                className={cn(classes.profileTitle, "mb-6", {
                  [classes.title]: !property.suburbHidden && team.reportHidden,
                })}
              >
                {`${property.address?.suburb?.name?.toLowerCase()} — Suburb Profile`}
              </Typography>
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                centered
                className="mb-5"
              >
                <HideTab
                  label="Sales"
                  hide={property.suburbSalesHidden}
                  onHideChange={val =>
                    dispatch(updateProperty({ suburbSalesHidden: val }))
                  }
                  value="sales"
                />
                <HideTab
                  label="Rentals"
                  hide={property.suburbRentalsHidden}
                  onHideChange={val =>
                    dispatch(updateProperty({ suburbRentalsHidden: val }))
                  }
                  value="rentals"
                />
              </Tabs>
              {activeTab !== "rentals" && (
                <SuburbProfile
                  statistics={salesStatistics}
                  type={type}
                  isRental={false}
                  category={"sales"}
                  isCommercial={property?.class?.internalName === "commercial"}
                />
              )}
              {activeTab !== "sales" && (
                <SuburbProfile
                  statistics={rentalStatistics}
                  type={type}
                  isRental={true}
                  isCommercial={property?.class?.internalName === "commercial"}
                />
              )}
              <div className={classes.separator} />
            </>
          )}
        </HideContainer>
      </div>
    </>
  );
};

export default MarketUpdateReport;
