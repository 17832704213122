import React, { useContext, useState } from "react";
import BlockContainer from "../BlockContainer";
import AgentBio from "./AgentBio";
import AddButton from "./AddButton";
import AddSecondAgentModal from "./AddSecondAgentModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppraisalContext } from "../../AppraisalContext";
import { APPRAISAL_TYPES as TYPES } from "app/constants/appraisalsConstants";
import {
  updateCommercialField,
  updateTeam,
} from "../../../../../redux/reducers";
import TeamsService from "app/api/TeamsService";
import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import HideContainer from "../../components/HideContainer";
import cn from "classnames";

const getLeaderName = leader => {
  let firstname = leader[0]?.firstname;
  let lastname = leader[0]?.lastname;
  return firstname ? firstname + " " + (lastname || "") : lastname || "";
};

const AgentsSection = ({ withoutCommercialStyle = false }) => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);
  const metaOffice = useSelector(
    ({ appraisal }) => appraisal.meta.office,
    shallowEqual,
  );
  const metaTeam = useSelector(
    ({ appraisal }) => appraisal.meta.team,
    shallowEqual,
  );
  const commercialAgentDetails = useSelector(
    ({ appraisal }) => appraisal.commercial.agentDetails,
    shallowEqual,
  );
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  const isCommercial = [
    TYPES.informationMemorandum,
    TYPES.commercialListingProposal,
  ].includes(appraisalType);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const [showSelectAgentModal, setShowSelectAgentModal] = useState(false);
  const [secondAgent, setSecondAgent] = useState({});
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [secondAgentsLoading, setSecondAgentsLoading] = useState(false);

  const addSecondAgent = async () => {
    setSecondAgentsLoading(true);
    const { data } = await TeamsService.getOfficeTeams(
      metaOffice,
      "",
      "",
      "updated_at",
      "descending",
      "true",
    );
    if (!data || !Array.isArray(data?.teams)) return [];
    const leaderList = data.teams
      .filter(team => team._id !== metaTeam)
      .map(teamData => ({
        id: teamData._id,
        leader_id: teamData.leader[0]?._id,
        email: teamData.email || teamData.leader[0]?.email,
        biography: teamData.biography,
        name: getLeaderName(teamData.leader),
        profilePortrait: teamData.profilePortrait,
        profileLink: teamData.profileLink,
        recentSales: teamData.recentSales,
        currentRentals: teamData.currentRentals,
        phone: teamData.phone,
        twitter: teamData.twitter,
        linkedIn: teamData.linkedIn,
        instagram: teamData.instagram,
        facebook: teamData.facebook,
      }));
    setTeamLeaders(leaderList);
    setShowSelectAgentModal(true);
    setSecondAgentsLoading(false);
  };

  const removeSecondAgent = () => {
    dispatch(
      updateTeam({
        secondLeaderName: null,
        secondBiography: null,
        secondProfilePortrait: null,
        secondProfileLink: null,
        secondRecentSales: null,
        secondCurrentRentals: null,
        secondLeaderPhone: null,
        secondEmail: null,
        secondTwitter: null,
        secondLinkedIn: null,
        secondInstagram: null,
        secondFacebook: null,
        secondOffice: null,
      }),
    );
  };

  const onSecondAgentSelect = e => {
    if (e.target.value) {
      setSecondAgent(e.target.value);
      let leader = teamLeaders.find(
        leader => leader.leader_id === e.target.value,
      );
      dispatch(
        updateTeam({
          secondLeaderName: leader.name,
          secondBiography: leader.biography,
          secondProfilePortrait: leader.profilePortrait,
          secondProfileLink: leader.profileLink,
          secondRecentSales: leader.recentSales,
          secondCurrentRentals: leader.currentRentals,
          secondLeaderPhone: leader.phone,
          secondEmail: leader.email,
          secondTwitter: leader.twitter,
          secondLinkedIn: leader.linkedIn,
          secondInstagram: leader.instagram,
          secondFacebook: leader.facebook,
        }),
      );
      setShowSelectAgentModal(false);
    }
  };

  const onTitleUpdate = title => {
    if (isCommercial && !withoutCommercialStyle) {
      const data = { ...commercialAgentDetails, title };
      dispatch(updateCommercialField({ data, name: "agentDetails" }));
    } else {
      dispatch(updateTeam({ leaderName: title }));
    }
  };

  const onSecondAgentTitleUpdate = title => {
    dispatch(updateTeam({ secondLeaderName: title }));
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateTeam({ leaderNameSubtitle: subtitle }));
  };

  const hideCommercialHandler = hidden => {
    const data = { ...commercialAgentDetails, hidden };
    dispatch(updateCommercialField({ data, name: "agentDetails" }));
  };

  const blockTitle =
    isCommercial && !withoutCommercialStyle
      ? commercialAgentDetails?.title
      : team.leaderName;

  const SecondAgentContainer = ({ children }) => {
    if (!team.secondLeaderName) return "";
    if (isCommercial && !withoutCommercialStyle) return <div>{children}</div>;
    return (
      <BlockContainer
        defaultTitle={team.secondLeaderName}
        defaultSubtitle={team.leaderNameSubtitle}
        id="second=agent-details"
        onTitleUpdate={onSecondAgentTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        separator={false}
        withoutSubTitle={isCommercial && !withoutCommercialStyle}
      >
        {children}
      </BlockContainer>
    );
  };

  const FirstAgentContainer = ({ children }) => {
    if (!isCommercial || withoutCommercialStyle) return <>{children}</>;
    return (
      <HideContainer
        value={commercialAgentDetails.hidden}
        onChange={hideCommercialHandler}
      >
        {children}
      </HideContainer>
    );
  };

  return (
    <FirstAgentContainer>
      <BlockContainer
        defaultTitle={blockTitle}
        defaultSubtitle={team.leaderNameSubtitle}
        id="agent-details"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        separator={isCommercial && !withoutCommercialStyle}
        hide={commercialAgentDetails.hidden}
        withoutSubTitle={isCommercial && !withoutCommercialStyle}
      >
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection={
            isCommercial && !withoutCommercialStyle && !isMobile
              ? "row"
              : "column"
          }
        >
          <div>
            <AgentBio
              currentRentals={team.currentRentals}
              biography={team.biography}
              profilePortrait={team.profilePortrait}
              leaderName={team.leaderName}
              profileLink={team.profileLink}
              recentSales={team.recentSales}
              phone={team.phone}
              email={team.email}
              officeName={team.officeName}
              withoutCommercialStyle={withoutCommercialStyle}
            />
            {!preview && (
              <div className="mt-10 text-center">
                <AddButton onClick={addSecondAgent}>
                  {team.secondLeaderName
                    ? "Edit Second Agent"
                    : "Add Second Agent"}
                  {secondAgentsLoading && (
                    <CircularProgress
                      color="#fff"
                      className="ml-5 h-20px w-20px"
                    />
                  )}
                </AddButton>
              </div>
            )}
            <AddSecondAgentModal
              secondAgent={secondAgent}
              show={showSelectAgentModal}
              onHide={() => setShowSelectAgentModal(false)}
              teamLeaders={teamLeaders}
              onSelect={onSecondAgentSelect}
            />
          </div>
          <SecondAgentContainer>
            <div>
              <AgentBio
                currentRentals={team.secondCurrentRentals}
                biography={team.secondBiography}
                profilePortrait={team.secondProfilePortrait}
                leaderName={team.secondLeaderName}
                profileLink={team.secondProfileLink}
                recentSales={team.secondRecentSales}
                phone={team.secondLeaderPhone}
                email={team.secondEmail}
                officeName={team?.secondOffice || team.officeName}
                className={cn({ "mt-8": isMobile })}
                withoutCommercialStyle={withoutCommercialStyle}
                secondAgent={true}
              />
            </div>

            {!preview && (
              <div className="mt-10 text-center">
                <AddButton onClick={removeSecondAgent}>
                  Remove Second Agent
                </AddButton>
              </div>
            )}
          </SecondAgentContainer>
        </Box>
      </BlockContainer>
    </FirstAgentContainer>
  );
};

export default AgentsSection;
