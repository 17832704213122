import axios from "axios";
import API_URL from "./api";

export default class SessionsService {
  static getUsersSessions = () => {
    return axios.get(`${API_URL}/sessions/users`);
  };

  static getOfficeSessions = () => {
    return axios.get(`${API_URL}/sessions/offices`);
  };
}
