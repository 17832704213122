import React, { useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";

import useArticles from "app/hooks/useArticles";
import { updateTeam, updateArticleStatus } from "redux/reducers";

import HideContainer from "../components/HideContainer";
import { AppraisalContext } from "../AppraisalContext";
import { Title } from "./BlockContainer";
import { AgentVideoSection } from "./AgentVideoSection";
import ArticleButton from "../CommonComponents/ArticleButton";

import awardIcon from "app/assets/images/award.svg";
import commercialAwardIcon from "app/assets/images/commercial/award.svg";
import ampersandIcon from "app/assets/images/logo-ampersand.svg";
import commercialAmpersandIcon from "app/assets/images/commercial/ampersand.svg";
import officeProfileIcon from "app/assets/images/office-profile.svg";
import commercialOfficeProfileIcon from "app/assets/images/commercial/office-profile.svg";
import communityIcon from "app/assets/images/community.svg";
import commercialCommunityIcon from "app/assets/images/commercial/community.svg";
import historyIcon from "app/assets/images/history.png";
import commercialHistoryIcon from "app/assets/images/commercial/history.png";

const getArticleIcon = (slug, isCommercial) => {
  const articleIcon = {
    awards: isCommercial ? commercialAwardIcon : awardIcon,
    "our-difference": isCommercial ? commercialAmpersandIcon : ampersandIcon,
    "office-profile": isCommercial
      ? commercialOfficeProfileIcon
      : officeProfileIcon,
    "corporate-profile": isCommercial
      ? commercialOfficeProfileIcon
      : officeProfileIcon,
    community: isCommercial ? commercialCommunityIcon : communityIcon,
    history: isCommercial ? commercialHistoryIcon : historyIcon,
  };
  return articleIcon[slug];
};

export const MoreInfoSubSection = ({
  isCommercial,
  openArticleModal,
  isRemoved,
}) => {
  const dispatch = useDispatch();

  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);
  const articleList = useArticles({ teamDetails: true }).teamDetails || [];

  const agentVideoLink = team.videos[1];
  const allArticlesDisabled = articleList?.every(a => a.active === false);

  const { preview } = useContext(AppraisalContext);

  const updateMoreDetailTitle = val => {
    dispatch(updateTeam({ moreDetailsTitle: val }));
  };

  const onUpdateArticleStatus = (id, status) => {
    dispatch(updateArticleStatus({ id, status }));
  };

  return (
    <HideContainer
      title="Hide Sub Section"
      value={
        team.moreInfoHidden ||
        (preview && allArticlesDisabled && !agentVideoLink)
      }
      onChange={val => dispatch(updateTeam({ moreInfoHidden: val }))}
    >
      <Title
        value={team.moreDetailsTitle}
        onBlur={updateMoreDetailTitle}
        className={`common-subTitle mt-7 ${
          isCommercial ? "common-commercial-title" : ""
        }`}
        maxLength={30}
      />
      {!team.moreInfoHidden && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
          >
            {articleList.map(articleElem => (
              <ArticleButton
                key={articleElem._id}
                title={articleElem.title}
                onClick={() => openArticleModal(articleElem)}
                active={articleElem.active}
                onChangeStatus={val =>
                  onUpdateArticleStatus(articleElem._id, val)
                }
                imgSrc={getArticleIcon(articleElem.slug, isCommercial)}
                imgAlt={articleElem.title}
                remove={isRemoved(articleElem.slug)}
              />
            ))}
          </Box>
          {agentVideoLink && (
            <AgentVideoSection agentVideoLink={agentVideoLink} />
          )}
        </>
      )}
    </HideContainer>
  );
};
