import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { updateTeamVideo } from "redux/reducers";

import { UpdateVideoLinkButton, YoutubeVideo } from "../CommonComponents";

export const AgentVideoSection = ({ agentVideoLink }) => {
  const dispatch = useDispatch();

  const onUpdateVideoUrl = newUrl => {
    dispatch(updateTeamVideo({ videoIndex: 1, newUrl }));
  };

  return (
    <Box marginTop={6}>
      <UpdateVideoLinkButton
        currentVideoLink={agentVideoLink}
        onUpdate={onUpdateVideoUrl}
      />
      <YoutubeVideo video={agentVideoLink} />
    </Box>
  );
};
