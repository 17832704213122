import React from "react";
import ReactHtmlParser from "react-html-parser";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const TemplatePreview = props => {
  return (
    <>
      <div className="justify-content-between d-flex">
        <ArrowBackIcon
          fontSize="large"
          className="cursor-pointer"
          onClick={() => props.closePreview()}
        />
        <div className="template-preview-header">
          {props.templateData.title}
        </div>
      </div>
      <div className="template-preview-image-container">
        <div className="preview-image image-text">Main Image</div>
      </div>
      <div>
        <div className="body-title">{props.templateData.title}</div>
        <div className="body-paragraph">
          {ReactHtmlParser(props.templateData.body)}
        </div>
        <div className="cursor-pointer" onClick={() => props.closePreview()}>
          <ArrowBackIcon />
          <div className="body-button commercial-border">Back To Template</div>
        </div>
      </div>
    </>
  );
};

export default TemplatePreview;
