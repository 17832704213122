import {
  Table as MiuiTable,
  TableBody as MiuiTableBody,
  TableCell as MiuiTableCell,
  TableHead as MiuiTableHead,
  TableRow as MiuiTableRow,
  TableFooter as MiuiTableFooter,
  withStyles,
} from "@material-ui/core";

export const Table = MiuiTable;
export const  TableCell = MiuiTableCell;
export const TableBody = MiuiTableBody;

export const TableHead = withStyles(() => ({
  root: {
    "& tr": {
      backgroundColor: "transparent !important",
    },
    "& th": {
      fontWeight: 600,
      fontSize: '15px',
    },
  },
}))(MiuiTableHead)

export const TableFooter = withStyles({
  root: {
    "& tr": {
      backgroundColor: "transparent !important",
    },
    "& td": {
      fontWeight: 600,
      color: 'inherit',
      fontSize: '15px',
    }
  }
})(MiuiTableFooter)

export const TableRow = withStyles(theme => ({
  root: {
    display: 'table !important',
    width: '100%',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    "& td": {
      fontSize: '15px',
      padding: '7px 16px'
    }
  },
}))(MiuiTableRow)
