import { useCallback, useContext } from "react";
import { ToastContext } from "../components/ToastProvider";

export const getSuccessToastMessage = message => ({
  severity: "success",
  summary: "Successful",
  detail: message,
  life: 3000,
});

export const getErrorToastMessage = message => ({
  severity: "error",
  summary: "Warning",
  detail: message || "Something went wrong, please try again later",
  life: 4000,
});

export const useToast = () => {
  const { toast } = useContext(ToastContext);

  const showToastMessage = useCallback(messageObj => toast.current.show(messageObj), [toast]);

  return {
    showToastMessage,
  };
};
