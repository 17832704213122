import React from "react";
import "./styles.scss";
import { useGetUser } from "redux/hooks";
import DateSelector from "../../../components/DateSelector";

const Header = ({ withCustomDates }) => {
  const { user } = useGetUser();

  return (
    <div className="dashboard-header layout-side-padding">
      <h1 className="title">Hey&nbsp;{user.firstname}!</h1>
      <DateSelector withCustomDates={withCustomDates} />
    </div>
  );
};

export default Header;
