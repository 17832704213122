import { shallowEqual, useSelector } from "react-redux";
import { ProposalTypes } from "app/interfaces";

export const useBusinessListing = () => {
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );
  const isBusinessListing = appraisalType === ProposalTypes.businessListingProposal ? true : false;

  return {
    isBusinessListing,
  };
};
