import { Button, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  addButton: {
    color: "#fff",
    fontWeight: 600,
    padding: "10px 20px",
  },
}));

const AddButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      disableElevation
      className={classes.addButton}
    >
      {children}
    </Button>
  )
}

export default AddButton;
