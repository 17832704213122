import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "app/components/Modal";

import { getImageUrl } from "shared";

import { Article, TabsContextProps } from "app/interfaces";
import { getBusinessSupportData } from "redux/selectors/builderSelectors";
import { updateBusinessSupport, updateArticleBody } from "redux/reducers";

import photoForArticles from "../../../assets/images/marketing/visuals.jpeg";

import useArticles from "app/hooks/useArticles";
import TabsBlock from "../components/TabsBlock";
import SEO from "app/assets/images/SEO.jpg";

const labelTabs: { [key: string]: string } = {
  training: "Training",
  propertyManagement: "PM",
  marketingAndCommunications: "Marketing & Comms",
  it: "Technology",
  ourBroker: "Our Broker",
};

const slugTabs: { [key: string]: string } = {
  training: "training",
  propertyManagement: "property-management",
  marketingAndCommunications: "marketing--comms",
  it: "it",
  ourBroker: "our-broker",
};

const mediaAssets: { [key: string]: any } = {
  marketingAndCommunications: (
    <img src={photoForArticles} alt="communications" />
  ),
  ourBroker: <img src={photoForArticles} alt="Our Broker" />,
  it: <img src={photoForArticles} alt="IT" />,
  propertyManagement: <img src={photoForArticles} alt="Property Management" />,
  training: <img src={photoForArticles} alt="Training" />,
};

const BusinessSupport = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const articles: { [key: string]: any } = useArticles({
    businessSupport: true,
  });

  const businessSupportData = useSelector(getBusinessSupportData);

  const updateProp = (fildName: string, val: any) => {
    dispatch(updateBusinessSupport({ [fildName]: val }));
  };

  const onTabsUpdate = (tabName: string, field: string, value: boolean) => {
    const preBusinessSupportType = businessSupportData.types;
    const updateBusinessSupportType = {
      ...preBusinessSupportType,
      [tabName]: {
        ...preBusinessSupportType[tabName],
        [field]: value,
      },
    };
    return updateProp("types", updateBusinessSupportType);
  };

  const getMedia = (props: {
    tabImage: string | undefined;
    title: string | undefined;
  }) => {
    if (!props || !props.tabImage) return null;
    const src = getImageUrl(props.tabImage);
    return <img src={src} alt={props.title} />;
  };

  const onUpdateArticleBody = (id: string, body: string) => {
    if (!id) return;
    dispatch(updateArticleBody({ id, body }));
  };
  const openAdditionalModal = () => setOpenModal(true);

  const tabCategories = Object.keys(businessSupportData.types || {});
  const tabsContent = tabCategories.reduce(
    (acc: Array<TabsContextProps>, item): any => {
      const articleSlug = slugTabs[item] || item;
      const article = articles?.businessSupport?.find(
        (art: Article) => art.slug === articleSlug,
      );
      return [
        ...acc,
        {
          key: item,
          label: labelTabs[item] || "",
          isHiddenTab: businessSupportData.types?.[item]?.hidden || false,
          onHideChange: (value: boolean) => onTabsUpdate(item, "hidden", value),
          media: getMedia(article) || mediaAssets[item] || null,
          body: article?.body || "",
          changeBody: (newBody: string) =>
            onUpdateArticleBody(article?._id, newBody),
          ...(articleSlug === slugTabs["it"] &&
            article?.body && { openAdditionalModal: openAdditionalModal }),
        },
      ];
    },
    [],
  );

  const ModalForTab = () => (
    <Modal open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg">
      <img src={SEO} alt={"SEO"} style={{ width: "100%" }} />
    </Modal>
  );

  return (
    <>
      <TabsBlock
        id="business-support"
        title={businessSupportData.title}
        subtitle={businessSupportData.subtitle}
        isHiddenSection={businessSupportData.hidden}
        onChangeHiddenSection={(value: boolean) => updateProp("hidden", value)}
        onTitleUpdate={(value: string) => updateProp("title", value)}
        onSubtitleUpdate={(value: string) => updateProp("subtitle", value)}
        tabsContent={tabsContent}
      />
      <ModalForTab />
    </>
  );
};

export default BusinessSupport;
