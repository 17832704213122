import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { FieldBuilder } from "../FieldBuilder";
import { ImageBlock } from "../ImageBlock";

const ManualBusinessListingTable = ({
  classes,
  isCommercial,
  property,
  setProperty,
  isAddressEmpty,
  isEditMode,
}) => {
  const onSetProperty = (name, value) =>
    setProperty({ ...property, [name]: value });

  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className={classes.imageBlock}>
          <Box width="100%">
            <ImageBlock
              classes={classes}
              image={property.image}
              setImage={value => onSetProperty("image", value)}
              id={property?.id}
              isEditMode={isEditMode}
            />
          </Box>
        </div>
        <div className={classes.addressBlockInWithImageBlock}>
          <div className={classes.addressBlock}>
            <FieldBuilder
              className={classes.inputContainer}
              label={"Business Name"}
              value={property.address}
              onChange={value => onSetProperty("address", value)}
              placeholder={"Tom's Chicken Shop"}
              isRequired={isAddressEmpty && !property.address}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          <FieldBuilder
            className={classes.inputContainer}
            label={"inc Stock"}
            value={property.price}
            onChange={value => onSetProperty("price", value)}
            placeholder={"eg. $300,000"}
            isPreClick
          />
        </div>
        <div style={{ width: "50%" }}>
          <FieldBuilder
            className={classes.inputContainer}
            label={`${category === "lease" ? "Lease" : "Sale"} Date`}
            value={property.saleDate}
            onChange={value => onSetProperty("saleDate", value)}
            placeholder={"eg. 31/01/2022"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManualBusinessListingTable;
