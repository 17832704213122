
export const convertPriceString = (string) => {
  let price;
  if (string) {
    price = string.toString().replace(/[,.]/g, '').match(/\d+/g)
    if (price) {
      price = price.map(Number).map((n) => getPrice(n)).join(' - ')
    }
  }
  return price === null ? string : (price || "$000,000 - $000,000")
}

export const getPrice = price => {
  if (!price) return null;
  if ( !(/^\d+(\.[0-9]+)*$/.test(price)) ) return price;
  `${price}`.replace(/w*\.0\b/, '')
  return new Intl
    .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    .format(price)
    .replace(/\D00(?=\D*$)/, '');
};

export const getLandSize = (land, units) => {
  if (!land) return "";
  if (`${land}`.length === `${land}`.replace(/[^0-9.,]/g, "").length) {
    if (units) {
      return `${land} ${units}`;
    } else return `${land} sqm`;
  } else {
    return land;
  }
};
