import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { fetchProposal, fetchMetrics, fetchSendHistory } from "redux/actions";
import {getCountry, getProposalTitle} from "redux/selectors/metricsSelector";
import SvgIcon from "app/components/ui/SvgIcon";
import MetricsWidget from "app/modules/ProposalMetrics/MetricsWidget";
import BigYellowButton from "app/components/ui/Buttons/BigYellowButton";
import ProposalDetails from "app/modules/ProposalMetrics/ProposalMetrics/ProposalDetails";
import SendHistory from "app/modules/ProposalMetrics/ProposalMetrics/SendHistory";
import SessionsMetrics from "app/modules/ProposalMetrics/SessionsMetrics";
import ComparablesEditDetails from "app/modules/ProposalMetrics/ProposalMetrics/ComparablesEditDetails";
import VisitedDetails from "../../modules/ProposalMetrics/ProposalMetrics/VisitedDetails";

import "./styles.scss";

const ProposalMetrics = () => {
  const dispatch = useDispatch();
  const { appraisalId } = useParams();

  useEffect(() => {
    dispatch(fetchProposal({ id: appraisalId }));
    dispatch(fetchMetrics({ id: appraisalId }));
    dispatch(fetchSendHistory({ id: appraisalId }));
  }, [appraisalId, dispatch]);

  const proposalTitle = useSelector(getProposalTitle);
  const isNz = useSelector(getCountry);

  return (
    <>
      <div className="page-header horizontal-separator">
        <Link to="/proposals" className="page-header-button">
          <SvgIcon iconName="chevronLargeBack" />
          <span>Back to Proposals</span>
        </Link>
      </div>
      <div className="page-body">
        <section className="main-section">
          <h1 className="page-title layout-side-padding">{proposalTitle}</h1>
          <MetricsWidget />
          <SessionsMetrics />
        </section>
        <section className="aside-section">
          <BigYellowButton to={`/proposals/${appraisalId}/edit`}>
            Edit proposal
          </BigYellowButton>
          <ProposalDetails />
          <SendHistory />
          <ComparablesEditDetails />
          {isNz && <VisitedDetails />}
        </section>
      </div>
    </>
  );
};

export default ProposalMetrics;
