import axios from "axios";

const serverUrl = process.env.REACT_APP_API_URL

export const UploadImage = (officeId, folderId, data) => {
  folderId = folderId === "rootFolderId" ? undefined : folderId
  const apiUrl = serverUrl + `/files?office=${officeId}&folder=${folderId}`;
  return axios.post(apiUrl, data);
}

export const GetOfficeImages = function(officeId) {
  const apiUrl = serverUrl + `/files/${officeId}?fullTree=true`;
  return axios.get(apiUrl);
}

export const UpdateFolder = data => {
  const apiUrl = serverUrl + `/folders/${data.id}`;
  return axios.put(apiUrl, data);
}

export const deleteFolder = id => {
  const apiUrl = serverUrl + `/folders/${id}`;
  return axios.delete(apiUrl);
}

export const deleteFile = id => {
  const apiUrl = serverUrl + `/files/${id}`;
  return axios.delete(apiUrl);
}

export const moveFiles = moveFiles => {
  const apiUrl = serverUrl + `/files/move`;
  return axios.put(apiUrl, { moveFiles });
}

export const moveFolder = moveFolders => {
  const apiUrl = serverUrl + `/folders/move`;
  return axios.put(apiUrl, { moveFolders });
}

export const createFolder = data => {
  const apiUrl = serverUrl + `/folders`;
  return axios.post(apiUrl, data);
}

export const uploadFile = (data, officeId, folderId) => {
  const apiUrl = serverUrl + `/files?office=${officeId}&folder=${folderId}`;
  return axios.post(apiUrl, data);
}

export const getFiles = officeId => {
  const apiUrl = serverUrl + `/files/${officeId}?fullTree=true`;
  return axios.get(apiUrl)
}
