import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Container, makeStyles, Select, MenuItem } from "@material-ui/core";
import BlockContainer from "../../AppraisalDetails/BlockContainer";
import HideContainer from "../../components/HideContainer";
import {
  updateNextSteps,
  updateCalendar,
} from "../../../../../redux/reducers/appraisalReducer";
import { AppraisalContext } from "../../AppraisalContext";
import NextStepAction from "./NextStepAction";

import { getNextStepsData } from "redux/selectors";
import Calendar from "../CampaignCalendar/Calendar";

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "20px",
    padding: 0,
    color: "#666",
  },
  button: {
    background: "linear-gradient(90deg, #F69400 0%, #FFC500 100%)",
    borderRadius: "10px",
    margin: "auto",
    display: "flex",
    height: "60px",
    width: "400px",
    color: "white",
    fontSize: "18px",
    fontWeight: "600",
    marginTop: "15px",
    justifyContent: "center",
    alignItems: "center",
  },
  articles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    flexWrap: "wrap",
  },
  expectText: {
    margin: 0,
    marginTop: "40px",
  },
}));

const AvailableCalendars = ({ availableCalendars, onSelect, selected }) => {
  if (!availableCalendars?.length) return "";
  return (
    <div className="d-flex justify-content-center mb-5">
      <Select value={selected} onChange={onSelect}>
        {availableCalendars.map((calendar, index) => (
          <MenuItem key={index} value={calendar}>
            {calendar.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const NextSteps = ({ withoutDescription = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const [selectedCalendar, setSelectedCalendar] = useState({});

  const nextStepsData = useSelector(getNextStepsData);
  const calendar = useSelector(
    ({ appraisal }) => appraisal.calendar,
    shallowEqual,
  );
  const calendarItems = useSelector(
    ({ appraisal }) => appraisal.calendar.items,
    shallowEqual,
  );
  const availableCalendars = useSelector(
    ({ availableCalendars }) => availableCalendars,
    shallowEqual,
  );

  useEffect(() => {
    if (availableCalendars.length) {
      setSelectedCalendar(
        availableCalendars.find(el => el._id === calendar.selectedCalendar),
      );
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCalendars]);

  const onChangeNextSteps = (field, val) => {
    dispatch(updateNextSteps({ [field]: val }));
  };

  const updateCalendarItems = items => {
    dispatch(updateCalendar({ items }));
  };

  const handleSelectCalendar = event => {
    dispatch(updateCalendar({ selectedCalendar: event.target.value._id }));
    setSelectedCalendar(event.target.value);
    const items = event.target.value.items || [];
    const newCalendarItems = items.map(el => ({
      date: new Date(),
      text: el.text,
    }));
    updateCalendarItems(newCalendarItems);
  };

  return (
    <HideContainer
      value={nextStepsData.hidden}
      onChange={val => onChangeNextSteps("hidden", val)}
    >
      <BlockContainer
        defaultTitle={nextStepsData.title}
        defaultSubtitle={nextStepsData.subtitle}
        id="next-steps"
        onTitleUpdate={val => onChangeNextSteps("title", val)}
        onSubtitleUpdate={val => onChangeNextSteps("subtitle", val)}
        hide={nextStepsData.hidden}
      >
        <div className={nextStepsData.hidden ? "d-none" : ""}>
          <Container
            maxWidth="md"
            component="div"
            className={classes.container}
          >
            {!withoutDescription ? (
              <div className={classes.boldText}>{calendar.text}</div>
            ) : (
              <React.Fragment />
            )}
            
            {availableCalendars?.length > 1 && (
              <AvailableCalendars
                availableCalendars={availableCalendars}
                onSelect={handleSelectCalendar}
                selected={selectedCalendar}
              />
            )}
            <Calendar
              calendarItems={calendarItems}
              onUpdate={updateCalendarItems}
            />
          </Container>
          {preview ? "" : <NextStepAction className="mt-10" />}
        </div>
      </BlockContainer>
    </HideContainer>
  );
};
export default NextSteps;
