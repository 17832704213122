import { createSelector } from "reselect";
import { APPRAISAL_TYPES } from "../../app/constants/appraisalsConstants";



const proposalState: any = (state: any) => state.appraisal;
export const getAppraisalData = createSelector(proposalState, state => state);
export const getTeamData = createSelector(proposalState, (state: any) => state?.team);
export const getMetricData = createSelector(proposalState, (state: any) => {
  return {
    id: state._id,
    officeId: state.meta?.office,
    draft: !state.sent_at,
  };
});

export const getRecipientData = createSelector(
  proposalState,
  (state: any) => state.nextSteps?.flkContacts || [],
)


export const getPreviewArchiveData = createSelector(
  proposalState,
  (state: any) => ({
    isArchived: state.meta?.archived,
    officeName: state.team?.officeName,
    officePhone: state.team?.officePhone,
    isCommercial: [
      APPRAISAL_TYPES.informationMemorandum,
      APPRAISAL_TYPES.commercialListingProposal,
    ].includes(state.meta?.appraisalType),
  }),
);

export const getBusinessSupportData = createSelector(
  proposalState,
  (state: any) => state.businessSupport || {},
);
export const getGrowBusinessData = createSelector(
  proposalState,
  (state: any) => state.growBusiness || {},
);
export const getArticlesData = createSelector(
  proposalState,
  (state: any) => state.articles || [],
);
export const getChooseUsData = createSelector(
  proposalState,
  (state: any) => state.chooseUs || {},
);
export const getRecruitmentAgentsData = createSelector(
  proposalState,
  (state: any) => state.recruitmentAgents || {},
);
export const getOurGrowingNetworkData = createSelector(
  proposalState,
  (state: any) => state.ourGrowingNetwork || {},
);

export const getNextStepsData = createSelector(
  proposalState,
  (state: any) => state.nextSteps || {},
);
export const getCategory = createSelector(
  proposalState,
  (state: any) => state.meta.category || null,
);

export const getTargetMarketData = createSelector(
  proposalState,
  (state: any) => state.targetMarket || {},
);
export const getExecutiveSummaryData = createSelector(
  proposalState,
  (state: any) => state.executiveSummary || {},
);
export const getDisclaimerData = createSelector(
  proposalState,
  (state: any) => state.disclaimer || {},
);
export const getMarketTrendsData = createSelector(
  proposalState,
  (state: any) => state.marketTrends || {},
);
export const getOurValuesData = createSelector(
  proposalState,
  (state: any) => state.ourValues || {},
);

export const getProposalType = createSelector(
  proposalState,
    (state: any) => state.meta?.appraisalType
)

export const getShowFlk = createSelector(
  proposalState,
  (state: any) => state.meta?.flkEnabled && state.meta?.appraisalType === "listingProposal",
)