import React from "react";
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import cn from 'classnames';

import "./styles.scss";

const Button = ({ children, className, variant, icon, onClick, disabled }) => {
  const linkButton = children.type?.displayName === 'Link';
  return (
    <button
      className={cn('button', variant, className, { disabled }, { 'link-button': linkButton })}
      onClick={() => !disabled && onClick()}
    >
      {icon &&
      <span className="icon">
        <SvgIcon iconName={icon} />
      </span>}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'btn-brand-black', 'btn-brand-yellow', 'outlined', 'outlined-blue']),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  className: '',
  variant: 'default',
  icon: '',
  onClick: () => null,
  disabled: false,
};

export default Button;
