import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from 'axios';
import { API_URL } from '../Auth/_redux/authCrud';
import GridLinksList from 'app/components/GridLinksList';
import PaginationSelective from 'app/components/PaginationSelective';
import Loader from 'app/components/ui/Loader';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import MenuAction from "./UserAccess/MenuAction";
import OfficesService from "../../api/OfficesService";
import {
  useToast,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "app/hooks/useToast";

const useStyles = makeStyles({
  listWrapper: {
    position: 'relative',
  },
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 82,
  },
});

const OfficeList = ({ search, activeStatus = "" }) => {
  const [offices, setOffices] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);
  const { showToastMessage } = useToast();

  const classes = useStyles();

  const changeOfficesActivity = (officeId, active) => {
    const changeActivity = active
      ? OfficesService.deactivateOffice
      : OfficesService.reactivateOffice;
    changeActivity(officeId)
      .then(resp => {
        const newOffices = offices.filter(office => office.id !== officeId);
        setOffices(newOffices);
        return showToastMessage(
          getSuccessToastMessage(resp.data.message),
        );
      })
      .catch(() => {
        showToastMessage(
          getErrorToastMessage("Something went wrong, please try again later"),
        );
      });
  };

  useEffect(() => {
    const apiUrl =
      API_URL +
      `offices?search=${search}&page=${page}&filter=updated_at&sort=descending&active=${activeStatus}`;
    setLoading(true);
    axios.get(apiUrl).then(resp => {
      const offices = get(resp.data, 'offices', []).map((office) => ({
        id: office._id,
        title: office.name,
        active: office.active,
      }));
      setOffices(offices);
      setTotalPages(parseInt(resp.data.pages));
      setLoading(false);
    });
  }, [search, page, activeStatus]);

  const listItems = offices.map(office => ({
    id: office.id,
    link: `/offices/${office.id}`,
    mainData: [
      { content: office.title },
    ],
    rowTail: (
      <MenuAction
        actions={
          currentUser.permissions === 3
            ? [
                {
                  title: office.active ? "Deactivate" : "Reactivate",
                  onClick: () => {
                    changeOfficesActivity(office.id, office.active);
                  },
                },
              ]
            : []
        }
      />
    ),
    rowTailClassName: "width-50 item__centered",
  }));

  return (
    <div className={classes.listWrapper}>
      {loading && <Loader />}
      <GridLinksList content={listItems} />
      <div className={classes.paginationWrapper}>
        <PaginationSelective
          page={page}
          setPage={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default OfficeList;
