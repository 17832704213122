import axios from 'axios';
import API_URL from './api';

export default class TeamsService {

  static getTeamMemberOptions = (officeId, searchParam, limit = null) => {
    const search = searchParam || "";
    const office = officeId || "";
    return axios.get(
      `${API_URL}/teamMemberOptions?office=${office}&search=${search}&limit=${limit}`
    );
  }

  static getOfficeTeams = (officeId, search = '', page = '', filter = 'updated_at', sort = 'descending', allOfficeTeams = 'false') => {
    return axios.get(
      `${API_URL}/teams?office=${officeId}&search=${search}&page=${page}&filter=${filter}&sort=${sort}&allOfficeTeams=${allOfficeTeams}`
    );
  }

  static getAgentTeams = (officeId, search = '', limit = null) => {
    return axios.get(
      `${API_URL}/team?office=${officeId}&search=${search}&limit=${limit}`
    );
  }

  static getTeam = teamId => {
    return axios.get(
      `${API_URL}/teams/${teamId}`
    )
  }

  static updateTeam = (teamId, teamData) => {
    return axios.put(
      `${API_URL}/teams/${teamId}`, teamData);
  }

  static deleteTeam = teamId => {
    return axios.delete(
      `${API_URL}/teams/${teamId}`);
  }

}
