import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Grid } from "@material-ui/core";

import useDebounce from "app/hooks/useDebounce";
import PropertyCard from "app/components/PropertyCard";
import { getLandSize } from "app/components/PropertyCard/utils";
import SearchBox from "app/modules/SearchBox";
import PropertiesService from "../../../api/PropertiesService";

import { Property, ProposalTypes } from "app/interfaces";
import { setProperty } from "../redux/actions";
import {
  teamSelector,
  officeSelector,
  teamLeaderIdSelector,
  propertyTypeSelector,
} from "../redux/selectors";
import { RootState } from "redux/store";

import { useToast, getErrorToastMessage } from "app/hooks/useToast";

import SwitchButton from "app/components/ui/Buttons/SwitchButton";

interface Props {
  index: number;
}

const useStyles = makeStyles(() => ({
  loading: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  content: {
    position: "relative",
    minHeight: "300px",
    overflow: "hidden",
    paddingBottom: 15,
  },
  propertySwitchButton: {
    width: "33.3%",
  },
  selectPropertySwitch: {
    margin: "10px 0",
    "& .switch-button": {
      display: "flex",
      margin: "0 auto",
      maxWidth: "600px",
      "& :nth-child(2)": {
        borderRight: "1px #dbdfe3 solid",
        borderLeft: "1px #dbdfe3 solid",
      },
    },
  },
}));

const optionsProperty = [
  { title: "All Listings", value: "appraisal,prospect,listing" },
  { title: "Appraisal Listings Only", value: "appraisal" },
  { title: "Active Listings Only", value: "listing" },
];

const SelectProperty = () => {
  const [search, setSearch] = useState("");
  const [properties, setProperties] = useState<Array<Property> | []>([]);
  const [loading, setLoading] = useState(false);
  const [propertyType, setPropertyType] = useState(
    "appraisal,prospect,listing",
  );
  const typeProposal = useSelector(
    (state: RootState) => state.createProposal.type,
    shallowEqual,
  );

  const { showToastMessage } = useToast();
  const dispatch = useDispatch();

  const teamLeaderId = useSelector(teamLeaderIdSelector);
  const teamData = useSelector(teamSelector);
  const office = useSelector(officeSelector);
  const type = useSelector(propertyTypeSelector);
  const officeId = office?.id;
  const officeSpeciality = office?.speciality;
  const isCommercialListingProposal =
    type === ProposalTypes.commercialListingProposal;
  const isBusinessListingProposal =
    type === ProposalTypes.businessListingProposal;
  const officeStatusList: { [key: string]: string } = {
    [ProposalTypes.commercialListingProposal]: propertyType,
    [ProposalTypes.residentialInformationMemorandum]: "listing",
    [ProposalTypes.businessListingProposal]: propertyType,
  };

  const officeStatus = type ? officeStatusList[type] : null;

  const debouncedSearchTerm = useDebounce(search, 500);

  const onPropertySelected = (property: Property) => {
    if (!property.geolocation.latitude || !property.geolocation.longitude) {
      return showToastMessage(
        getErrorToastMessage(
          "This property has not yet been Geocoded in CompassPlus. Correct this via the appraisal > other > map in CompassPlus.",
        ),
      );
    }
    const propertyDescription = property.saleLifeId
      ? teamData?.propertyForSale
      : teamData?.propertyForRent;

    property.description = propertyDescription || "";
    dispatch(setProperty(property));
  };

  const mixedClassificationType =
    typeProposal === ProposalTypes.residentialInformationMemorandum ||
    ([ProposalTypes.preAppraisal, ProposalTypes.listingProposal].includes(
      typeProposal!,
    ) &&
      (officeSpeciality === "residential" || !officeSpeciality))
      ? "mixedClassificationType"
      : null;

  const categorySearch = () => {
    if (officeSpeciality === 'business') {
      return "sales";
    } else if (typeProposal === "tenantHandbook") {
      return 'lease';
    } else {
      return null;
    }
  }

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    PropertiesService.getPropertiesVaultreAll(
      officeSpeciality,
      officeStatus,
      teamLeaderId,
      officeId,
      categorySearch(),
      debouncedSearchTerm,
      1,
      "updated_at",
      "descending",
      "all",
      mixedClassificationType,
    )
      .then(data => {
        if (isMounted) setProperties(data);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [
    officeSpeciality,
    officeStatus,
    officeId,
    teamLeaderId,
    debouncedSearchTerm,
    typeProposal,
    mixedClassificationType,
  ]);

  const classes = useStyles();

  const getDisplayAddress = (property: Property) => {
    switch (type) {
      case ProposalTypes.businessListingProposal:
        return property.address?.street;
      case ProposalTypes.residentialInformationMemorandum ||
        ProposalTypes.informationMemorandum:
        return `${property.displayAddress}${
          property.building?.name ? `, ${property.building?.name}` : ""
        }`;
      case ProposalTypes.commercialListingProposal:
        return `${property.displayAddress}${
          property.building?.name ? `, ${property.building?.name}` : ""
        }`;
      default:
        return property.displayAddress;
    }
  };

  const propertyDescription = (prop: Property) => [
    prop.type?.name === "Residential"
      ? prop.type?.propertyClass?.name
      : prop.type?.name,
    getDisplayAddress(prop),
  ];

  const onSwitchButtonClick = ({ index }: Props) => {
    setPropertyType(optionsProperty[index]?.value);
  };

  const initialIndex = optionsProperty.findIndex(
    item => item.value === propertyType,
  );

  return (
    <>
      <div className={classes.content}>
        {(isCommercialListingProposal || isBusinessListingProposal) && (
          <div className={classes.selectPropertySwitch}>
            <SwitchButton
              data={optionsProperty}
              handlerClick={onSwitchButtonClick}
              initialIndex={initialIndex}
              className={classes.propertySwitchButton}
            />
          </div>
        )}
        <SearchBox
          value={search}
          onChangeSearch={setSearch}
          className="my-3 mx-5"
        />
        {loading && (
          <div className={classes.loading}>
            <h3 className="mb-10">{`Fetching ${
              type === ProposalTypes.businessListingProposal
                ? "businesses"
                : "properties"
            } from CompassPlus...`}</h3>
            <CircularProgress />
          </div>
        )}
        {!loading && !properties.length && (
          <div className={classes.loading}>
            <h3 className="mb-10">No Properties found</h3>
          </div>
        )}
        {!loading && properties.length ? (
          <Grid container spacing={3} className="px-5">
            {properties.map((prop: Property) => (
              <Grid
                item
                xl={2}
                lg={3}
                xs={4}
                key={`${prop.id}_${prop.saleLifeId}`}
              >
                <PropertyCard
                  marketType={prop.saleLifeId ? "For Sale" : "For Lease"}
                  bed={prop.bed}
                  bath={prop.bath}
                  cars={prop.carports + prop.garages + prop.openSpaces}
                  landArea={getLandSize(
                    prop.landArea?.value,
                    prop.landArea?.units,
                  )}
                  image={
                    prop.photos
                      ? prop?.photos[0]?.thumbnails?.thumb_1024 ||
                        prop?.photos[0]?.url
                      : ""
                  }
                  onClick={() => onPropertySelected(prop)}
                  descriptionFields={propertyDescription(prop)}
                  underImageIcons
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SelectProperty;
