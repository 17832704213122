import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  messagesTabTitles: {
    padding: "0 0 0 36px",
    borderBottom: "1px solid #DFDFDF",
  },
  messagesTabButton: {
    height: "45px",
    padding: "0 17px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#7c7c7c",
    "&.active": {
      borderRadius: "3px 3px 0 0",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      color: "#fdb818",
      borderBottom: "1px solid #fdb818",
    },
  },
  counter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    marginLeft: "5px",
    width: "29px",
    height: "19px",
    background: "#EDEDED",
    borderRadius: "18px",
  },
}));

const Tabs = ({ data, className, onTabClick }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const Component = data[activeIndex].component;
  const componentProps = data[activeIndex].componentProps;
  const classes = useStyles();

  return (
    <div className={cn(className, "tabs-container")}>
      <div className={classes.messagesTabTitles}>
        {data.map((item, index) => (
          <button
            key={item.button.title}
            className={cn(classes.messagesTabButton, {
              active: index === activeIndex,
            })}
            onClick={() => {
              setActiveIndex(index);
              onTabClick(item.button.value);
            }}
          >
            <div className="d-flex">
              {item.button.title}
              {!!item.button?.counter && (
                <div className={classes.counter}>{item.button.counter}</div>
              )}
            </div>
          </button>
        ))}
      </div>
      <div className="tab-content-wrapper">
        <Component {...componentProps} />
      </div>
    </div>
  );
};

Tabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        title: PropTypes.string.isRequired,
        variant: PropTypes.string,
      }),
      component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.elementType,
      ]),
      componentProps: PropTypes.object,
    }),
  ),
};

Tabs.defaultProps = {
  data: [
    {
      button: {
        variant: "",
      },
      component: "",
      componentProps: null,
    },
  ],
};

export default Tabs;
