import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Box, Button, LinearProgress, withStyles } from "@material-ui/core";
import PackContent from "../Appraisals/AppraisalDetails/Marketing/Packs/PackContent";
import InlineEditor from "../Appraisals/CommonComponents/InlineEditor";
import { SelectButton } from "primereact/selectbutton";
import { confirmDialog } from "primereact/confirmdialog";
import OfficesService from "../../api/OfficesService";
import produce from "immer";
import IconButton from "../../components/ui/Buttons/IconButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../Appraisals/CreateAppraisal/utils";

const StickyHeader = withStyles({
  root: {
    position: "sticky",
    top: 0,
    padding: "10px 0",
    zIndex: 999,
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
  },
})(Box);

const AddMarketingButton = withStyles({
  root: {
    color: "#000",
    fontWeight: 600,
    padding: "10px 20px",
    marginTop: "20px",
  },
})(Button);

const MarketingSection = React.memo(({ item, onChange, onDelete }) => {
  const { items, title, total, costSavings } = item || {};
  const onChangeTitle = newVal => {
    onChange({ items, total, costSavings, title: newVal });
  };
  const onItemsUpdate = newVal => {
    onChange({ items: newVal, title, total, costSavings });
  };

  const onTotalUpdate = newVal => {
    onChange({ items, title, costSavings, total: newVal });
  };

  const onUpdateCostSavings = newVal => {
    onChange({ items, title, total, costSavings: newVal });
  };

  return (
    <>
      <div className="mt-10 d-flex align-items-baseline">
        {onDelete && (
          <IconButton
            icon="trash"
            variant="trash"
            className="mr-5"
            onClick={onDelete}
          />
        )}
        <InlineEditor
          value={title}
          onBlur={onChangeTitle}
          placeholder="title"
          className="h2"
        />
      </div>
      <PackContent
        items={items || []}
        total={total}
        onItemsUpdate={onItemsUpdate}
        onTotalUpdate={onTotalUpdate}
        onUpdateCostSavings={onUpdateCostSavings}
        costSavings={costSavings}
      />
    </>
  );
});

const MarketingSettings = ({ id }) => {
  const [marketing, setMarketing] = useState({});
  const [marketingType, setMarketingType] = useState("sale");
  const [showExtraOptions, setShowExtraOptions] = useState(false);
  const options = ["sale", "lease"];
  const dividerRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    OfficesService.getOffice(id)
      .then(({ data }) => {
        setMarketing(data.marketing);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const { extra, regular } = marketing?.[marketingType] || [];

  useEffect(() => {
    setShowExtraOptions(Boolean(extra?.items?.length));
  }, [extra]);

  const extraRef = useRef(null);

  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const saveChanges = () => {
    if (!regular?.length) {
      toast.current.show({
        severity: "error",
        summary: "Warning",
        detail: "You should add at least one Marketing element",
        life: 4000,
      });
      return;
    }
    setLoading(true);
    OfficesService.updateOffice(id, { marketing })
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Settings have been successfully updated",
          life: 3000,
        });
      })
      .finally(() => setLoading(false));
  };

  const onItemsUpdate = (newVal, index) => {
    const newList = produce(marketing, draftMarketing => {
      draftMarketing[marketingType].regular[index] = newVal;
    });
    setMarketing(newList);
  };

  const onExtraUpdate = newVal => {
    let newMarketing = produce(marketing, draftMarketing => {
      draftMarketing[marketingType].extra.items = newVal;
    });
    setMarketing(newMarketing);
  };

  const onDeleteItem = index => {
    const newMarketing = produce(marketing, draftMarketing => {
      draftMarketing[marketingType].regular.splice(index, 1);
    });
    setMarketing(newMarketing);
  };

  const onAddExtra = () => {
    setShowExtraOptions(true);
    setTimeout(() => {
      const scrollHeight = document.body.scrollHeight;
      window.scrollTo({ top: scrollHeight, behavior: "smooth" });
    }, 50);
  };

  const onAddItem = () => {
    const newMarketing = produce(marketing, draftMarketing => {
      const title =
        "Marketing Alternative Pack " +
          draftMarketing[marketingType].regular.length || "";
      draftMarketing[marketingType].regular.push({ items: [], title });
      return draftMarketing;
    });
    setMarketing(newMarketing);
    setTimeout(() => {
      dividerRef.current.scrollIntoView({ behavior: "smooth" });
    }, 50);
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const newItems = reorder(
      regular,
      result.source.index,
      result.destination.index,
    );
    const newMarketing = produce(marketing, draftMarketing => {
      draftMarketing[marketingType].regular = newItems;
    });
    setMarketing(newMarketing);
  };

  const confirmDelete = index => {
    confirmDialog({
      style: { width: 500 },
      header: "Delete confirmation",
      message: "Are you sure you want to delete this item?",
      accept: () => onDeleteItem(index),
    });
  };

  return (
    <>
      <LinearProgress color="primary" style={{ opacity: +loading }} />
      <div className="card-body pt-4" style={{ opacity: loading ? 0.6 : 1 }}>
        <Toast ref={toast} />
        <StickyHeader>
          <h2>Marketing Items</h2>
          <button onClick={saveChanges} className="btn btn-primary w-100px">
            Save
          </button>
        </StickyHeader>

        <SelectButton
          value={marketingType}
          options={options}
          onChange={e => e.value && setMarketingType(e.value)}
          className="select-buttons-black"
        />
        {regular?.length < 8 && (
          <AddMarketingButton
            onClick={onAddItem}
            variant="outlined"
            disableElevation
            className="mr-5"
          >
            Add Marketing Pack
          </AddMarketingButton>
        )}
        <AddMarketingButton
          onClick={onAddExtra}
          variant="outlined"
          disableElevation
          className="mr-5"
        >
          Add Optional Extras
        </AddMarketingButton>

        {loading ? <h3 className="mt-10">Loading...</h3> : ""}
        {!loading && !regular?.length ? (
          <h3 className="mt-10">No items found</h3>
        ) : (
          ""
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {regular?.map((item, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <MarketingSection
                          item={item}
                          onChange={newVal => onItemsUpdate(newVal, index)}
                          onDelete={() => confirmDelete(index)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div ref={dividerRef} />

        {showExtraOptions && (
          <div ref={extraRef}>
            <div className="h2 mt-10">Optional Extras</div>
            <PackContent
              items={extra?.items || []}
              onItemsUpdate={onExtraUpdate}
              showAddExtraItemButton
              isExtra
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MarketingSettings;
