import React, {useEffect, useState} from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_URL } from "../Auth/_redux/authCrud";
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import {get} from "lodash";
import InfoIcon from '@material-ui/icons/Info';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import DataGrid from "../Common/CustomizedDataGrid"
import UsersService from "../../api/UsersService";
import {shallowEqual, useSelector} from "react-redux";



const CreateTeam = ({id}) => {

  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const history = useHistory();
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '14px'
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    let leaders = []
    setLoading(true);
    UsersService.getOfficeUsers(id, '', 1, 'active', 'descending', true).then((resp) => {
      const users = get(
        resp.data,
        "users",
        [],
      ).map((user) => ({
        id: user._id,
        firstname: user.firstname,
        lastname: user.lastname,
        agentid: user.agentid,
        status: user.active ? 'Yes' : 'No'
      }));
      if (user.permissions < 2) {
        leaders.push({id: user._id, fullname: user.firstname + " " + user.lastname + ' (' + user.agentid + ')'})
      } else {
        leaders = get(
            resp.data,
            "users",
            [],
        ).map((user) => ({
          id: user._id,
          fullname: user.firstname + " " + user.lastname + ' (' + user.agentid + ')'
        }));
      }
      setUsers(users);
      setLeaders(leaders)
      setLoading(false);
    });
  }, [id]);

  const columns = [
    { field: "firstname", headerName: "First Name", flex: 1 },
    { field: "lastname", headerName: "Last Name", flex: 1 },
    { field: "agentid", headerName: "Agent ID", flex: 1 },
    { field: "status", headerName: "Active", flex: 0.5 }
  ];

  const departmentCategories = [
    {label: 'Residential Sales', value: 'Residential Sales'},
    {label: 'Residential PM', value: 'Residential PM'},
    {label: 'Commercial Sales', value: 'Commercial Sales'},
    {label: 'Commercial Leasing', value: 'Commercial Leasing'},
  ]

  const addTeam = (values, setStatus) => {
    const apiUrl = API_URL + `teams`;
    axios
      .post(apiUrl, { ...values, office: id, members: members })
      .then(resp => {
        history.push(`/offices/${id}/teams/${resp.data._id}`);
      })
      .catch(() => {
        setStatus("something went wrong");
      });
  };

  const Schema = Yup.object().shape({
    name: Yup.string().required("Profile Name is required"),
    leader: Yup.string().required("Agent is required"),
    departmentCategory: Yup.string().required("Department Category is required"),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      leader: user.permissions < 2 ? user._id : '',
      departmentCategory: '',
      members: [],
    },
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      addTeam(values, setStatus, setSubmitting)
    },
    enableReinitialize: true,
  });

  return (
    <div className="card-body p-0">
      <form
        className="card card-custom card-stretch"
        onSubmit={formik.handleSubmit}
      >
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Profile - New
            </h3>
          </div>
          <div className="card-toolbar">
            <button
              type="submit"
              className="btn btn-success mr-2"
              disabled={formik.isSubmitting}
            >
              Save
            </button>
            <div>
              <Link to={`/offices/${id}/teams`} className="btn btn-secondary">
                Cancel
              </Link>
            </div>
          </div>
        </div>
        <div className="form">
          {/* begin::Body */}
          <fieldset>
            <div className="card-body">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Agent / Property Manager
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className="form-control form-control-solid"
                    name="leader"
                    id="leader"
                    onChange={() => {
                      formik.setFieldTouched("leader", true, true);
                    }}
                    {...formik.getFieldProps("leader")}
                  >
                    {user.permissions >= 2 && <option value="">Select Agent / Property Manager</option>}
                    {leaders.map(item => (
                      <option key={item.id} value={item.id}>{item.fullname}</option>
                    ))}
                  </select>
                  {formik.touched.leader && formik.errors.leader ? (
                    <div className="" style={{ color: "red" }}>
                      {formik.errors.leader}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Profile Name
                  <Tooltip classes={{tooltip: classes.tooltip}} title="Profile Name">
                    <IconButton aria-label="profile tooltop">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    placeholder="Profile"
                    className={`form-control form-control-solid ${getInputClasses(
                      "name",
                    )}`}
                    name="name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="" style={{ color: "red" }}>
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Department Category
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className="form-control form-control-solid"
                    name="departmentCategory"
                    id="departmentCategory"
                    onChange={() => {
                      formik.setFieldTouched("departmentCategory", true, true);
                    }}
                    {...formik.getFieldProps("departmentCategory")}
                  >
                    <option value="">Select Department Category</option>
                    {departmentCategories.map(item => (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {formik.touched.departmentCategory && formik.errors.departmentCategory ? (
                    <div className="" style={{ color: "red" }}>
                      {formik.errors.departmentCategory}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Personal Assistants
                  <Tooltip classes={{tooltip: classes.tooltip}} title="Personal Assistants">
                    <IconButton aria-label="personal assistants tooltop">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </label>
                <div className="col-lg-12 col-xl-12">
                  <div style={{ height: "100%", width: "100%" }}>
                    <DataGrid
                      autoHeight
                      className="bg-white"
                      columns={columns}
                      rows={users}
                      checkboxSelection={true}
                      loading={loading}
                      onSelectionChange={(newSelection) => {
                        setMembers(newSelection.rowIds);
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
          </fieldset>
        </div>
      </form>
    </div>

  )
}

export default CreateTeam
