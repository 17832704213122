import React, { useContext, useState } from "react";
import produce from "immer";
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import DeleteConformationModal from "./DeleteConformationModal";
import Table from "./Table";
import { ToastContext } from "app/components/ToastProvider";

const PackContent = ({
  items = [],
  total,
  onItemsUpdate,
  onTotalUpdate,
  onAddExtraClick,
  isExtra = false,
  onUpdateCostSavings,
  costSavings,
}) => {
  const { toast } = useContext(ToastContext);
  // TODO: Remove after February 2022 if functionality not returned
  // const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState();

  const onDeleteItem = () => {
    let updatedItems = produce(items, draft => {
      const deleteIndex = draft.findIndex(i => i.text === deleteItem.text);
      draft.splice(deleteIndex, 1);
    });
    setDeleteItem(undefined);
    onItemsUpdate(updatedItems);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Item Deleted",
      life: 3000,
    });
  };

  const confirmDeleteItem = item => {
    setDeleteItem(item);
  };

  const hideDeleteItemDialog = () => {
    setDeleteItem(undefined);
  };

  return (
    <div>
      <Table
        items={items}
        total={total}
        onItemsUpdate={onItemsUpdate}
        onDelete={confirmDeleteItem}
        onAddExtraClick={onAddExtraClick}
        onTotalUpdate={onTotalUpdate}
        isExtra={isExtra}
        onUpdateCostSavings={onUpdateCostSavings}
        costSavings={costSavings}
      />

      <DeleteConformationModal
        show={!!deleteItem}
        onClose={hideDeleteItemDialog}
        onSubmit={onDeleteItem}
        name={deleteItem?.text}
      />
      {/* TODO: Remove after February 2022 if functionality not returned */}
      {/*<SelectItemsModal*/}
      {/*  show={showAddItemModal}*/}
      {/*  onHide={() => setShowAddItemModal(false)}*/}
      {/*  items={items}*/}
      {/*  onItemsUpdate={onItemsUpdate}*/}
      {/*/>*/}
    </div>
  );
};

export default React.memo(PackContent);
