import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PackContent from "./Packs/PackContent";

const useStyles = makeStyles(() => ({
  accordion: {
    boxShadow: "none !important",

    borderRadius: "5px",
    border: "none",
    margin: "0 !important",
    marginBottom: "5px !important",
    "&:before": {
      content: "none",
    },
    "&>.MuiButtonBase-root": {
      minHeight: 48,
      height: 48,
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: "#EEF1F5",
    marginLeft: "-2.5rem",
    marginRight: "-2.5rem",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  },
}));

const MobileTabs = ({
  marketingPacks,
  active,
  onChangeTab,
  onItemsUpdate,
  onAddExtraClick,
  onUpdateCostSavings,
  total,
}) => {
  const classes = useStyles();
  return (
    <div>
      {marketingPacks?.map((tab, index) => {
        return tab?.hidden || !tab?.active ? (
          ""
        ) : (
          <Accordion
            key={index}
            expanded={tab === active}
            onChange={() => onChangeTab(tab)}
            classes={{ root: classes.accordion }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${tab}-content`}
              id={`panel${tab}-content`}
              classes={{ root: classes.header }}
            >
              <Typography>{tab?.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0" />
            <AccordionDetails className="p-0 d-block">
              <PackContent
                items={tab?.items}
                onItemsUpdate={onItemsUpdate}
                onAddExtraClick={onAddExtraClick}
                onUpdateCostSavings={onUpdateCostSavings}
                costSavings={tab?.costSavings}
                total={total}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
export default MobileTabs;
