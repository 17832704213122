import { ChonkyActions, FileHelper } from "chonky";
import {
  cropImageAction,
  renameFolderAction,
  uploadLocalFile,
} from "./actions";
import * as utils from "./utils";

export const useFileActionHandler = ({
  fileList,
  onOpen,
  onDelete,
  onCreateFolder,
  onMove,
  onUploadFiles,
  onCropImage,
  onRename,
  onChangeSelection = () => {},
}) => {
  return async data => {
    switch (data.id) {
      case ChonkyActions.OpenFiles.id:
        const result = {};
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];

        result["isDir"] = fileToOpen && FileHelper.isDirectory(fileToOpen);
        if (result["isDir"]) {
          result["folderId"] = fileToOpen.id;
        } else {
          result["image"] = targetFile;
        }
        onOpen(result);
        break;

      case ChonkyActions.DeleteFiles.id:
        onDelete(
          await utils.deleteFiles(data.state.selectedFilesForAction, fileList),
        );
        break;

      case ChonkyActions.MoveFiles.id:
        onMove(
          await utils.moveFiles(
            data.payload.files,
            data.payload.source,
            data.payload.destination,
            fileList,
          ),
        );
        break;

      case ChonkyActions.CreateFolder.id:
        onCreateFolder();
        break;

      case ChonkyActions.UploadFiles.id:
      case uploadLocalFile.id:
        onUploadFiles();
        break;

      case ChonkyActions.ChangeSelection.id:
        const selectedFile = data.state.selectedFiles[0]?.isDir
          ? undefined
          : data.state.selectedFiles[0];
        onChangeSelection(selectedFile);
        break;

      case cropImageAction.id:
        const croppedFile = data.state.selectedFiles[0]?.isDir
          ? undefined
          : data.state.selectedFiles[0];
        onCropImage(croppedFile);
        break;

      case renameFolderAction.id:
        if (data.state.selectedFiles.length > 1) break;
        onRename(data.state.selectedFiles[0]);
        break;
      default:
        break;
    }
  };
};
