import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RaineAndHorneImg from "app/assets/images/RaineAndHorne.png";
import ProjectImg from "app/assets/images/Projects-Logo.png";
import RaineAndHorneCommercial from "app/assets/images/rhcommercial-logo.png";
import SvgIcon from "app/components/ui/SvgIcon";

import LeaderInfo from "./LeaderInfo";
import ListElement from "./ListElement";
import TeamsService from "app/api/TeamsService";
import getMenuList from "./propertyTypeConfig";
import { useCommercial } from 'app/hooks';

const useStyles = makeStyles(() => ({
  drawerWrapper: {
    minWidth: 250,
    margin: "0 auto",
  },
  drawer: ({ isCommercial }) => ({
    position: "fixed",
    width: 250,
    height: "100%",
    backgroundColor: isCommercial ? "#101820" : "rgb(235, 235, 236)",
    zIndex: 1,
    color: isCommercial ? "#fff" : "inherit",
  }),
  svgIcon: ({ isCommercial }) => ({
    opacity: isCommercial ? 0 : 1,
    position: "absolute",
    top: "4%",
    left: "-14%",
    width: "80%",
    height: "100%",
    zIndex: 1,
    "& svg": {
      width: "100%",
      height: "auto",
      "& path": {
        fill: "#fff",
        fillOpacity: 1,
      },
    },
  }),
  logoContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    height: "calc(100% - 16px)",
    marginTop: 16,
    textAlign: "center",
    zIndex: 2,
  },
  listItems: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    margin: "20px 0",
  },
  logoImg: {
    width: "200px",
  },
  bottomLink: ({ isCommercial }) => ({
    display: "block",
    fontSize: 12,
    textDecoration: "none",
    color: isCommercial ? "white" : "#2C2C2C",
    marginTop: "10px",
    "&:hover": {
      color: isCommercial && "#FAD201",
    },
  }),
}));

const LeaderContainer = ({ children, autoPlay = false }) => {
  if (!autoPlay) return <>{children}</>;
  return (
    <Carousel
      autoPlay={autoPlay}
      infiniteLoop
      interval={5000}
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      dynamicHeight={false}
      centerMode={true}
      centerSlidePercentage={100}
    >
      {children}
    </Carousel>
  );
};

const PreviewMenu = ({ drawerWidth, onSelect }) => {
  const { appraisal } = useSelector(state => state, shallowEqual);
  const teamId = useSelector(
    ({ appraisal }) => appraisal.meta.team,
    shallowEqual,
  );

  const { isCommercial } = useCommercial()

  const isFullList = [
    appraisal.vendor.coverLetterHidden,
    appraisal.property.detailsHidden,
    appraisal.team.reportHidden,
    appraisal.property.suburbHidden && appraisal.team.reportHidden,
    appraisal.comparables.sectionHidden,
    appraisal.methodOfSale.hidden,
    appraisal.marketing.sectionHidden,
    appraisal.calendar.sectionHidden,
    appraisal.meta.serviceFeesHidden,
    appraisal.meta.ourBrokerHidden,
    appraisal.team.sectionHidden,
  ].every(elem => !elem);

  const classes = useStyles({ drawerWidth, isFullList, isCommercial });
  const [isSmallHeight, setIsSmallHeight] = useState(false);
  const [team, setTeam] = useState({});
  const [loading, setLoading] = useState(true);
  const squeeze = isFullList && isSmallHeight;

  const handleResize = () => {
    setIsSmallHeight(window.innerHeight < 700);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (appraisal.id) {
      setLoading(false);
    }
  }, [appraisal]);

  useEffect(() => {
    TeamsService.getTeam(teamId).then(resp => setTeam(resp.data));
  }, [teamId]);

  const menuList = getMenuList(appraisal);
  let menuLogo = isCommercial ? RaineAndHorneCommercial : RaineAndHorneImg;
  if (appraisal.meta.officeVaultreId === 3634) {
    menuLogo = ProjectImg;
  }

  return (
    !loading && (
      <div className={classes.drawerWrapper}>
        <div className={classes.drawer}>
          <div className={classes.svgIcon}>
            <SvgIcon iconName="ampersand" />
          </div>
          <div className={classes.content}>
            <div className={classes.logoContainer}>
              <img src={menuLogo} alt="logo" className={classes.logoImg} />
            </div>
            <div className={classes.listItems}>
              {menuList.map(elem => (
                <ListElement
                  key={elem.id}
                  id={elem.id}
                  title={elem.title}
                  hide={elem.hide}
                  onSelect={onSelect}
                  squeeze={squeeze || isCommercial}
                />
              ))}
            </div>
            <div className="w-100 px-5 mb-10">
              <LeaderContainer
                autoPlay={
                  !!(team.secondLeaderName || appraisal.team.secondLeaderName)
                }
              >
                <LeaderInfo
                  profilePortrait={
                    team.profilePortrait || appraisal.team.profilePortrait
                  }
                  leaderName={team.leaderName || appraisal.team.leaderName}
                  phone={team.phone || appraisal.team.phone}
                  email={team.email || appraisal.team.email}
                  facebook={team.facebook || appraisal.team.facebook}
                  linkedIn={team.linkedIn || appraisal.team.linkedIn}
                  instagram={team.instagram || appraisal.team.instagram}
                  twitter={team.twitter || appraisal.team.twitter}
                  squeeze={squeeze}
                />
                {(team.secondLeaderName || appraisal.team.secondLeaderName) && (
                  <LeaderInfo
                    profilePortrait={
                      team.secondProfilePortrait ||
                      appraisal.team.secondProfilePortrait
                    }
                    leaderName={
                      team.secondLeaderName || appraisal.team.secondLeaderName
                    }
                    phone={
                      team.secondLeaderPhone || appraisal.team.secondLeaderPhone
                    }
                    email={team.secondEmail || appraisal.team.secondEmail}
                    facebook={
                      team.secondFacebook || appraisal.team.secondFacebook
                    }
                    linkedIn={
                      team.secondLinkedIn || appraisal.team.secondLinkedIn
                    }
                    instagram={
                      team.secondInstagram || appraisal.team.secondInstagram
                    }
                    twitter={team.secondTwitter || appraisal.team.secondTwitter}
                    squeeze={squeeze}
                  />
                )}
              </LeaderContainer>
              <a
                href={appraisal.meta.website}
                className={classes.bottomLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {appraisal.meta.website?.replace(/(^\w+:|^)\/\//, "")}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PreviewMenu;
