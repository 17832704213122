import React, { useContext, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Toast } from "primereact/toast";
import BlockContainer from "../AppraisalDetails/BlockContainer";
import HideContainer from "../components/HideContainer";
import {
  updateMeta,
  updateArticleBody,
  setPmfees,
} from "../../../../redux/reducers/appraisalReducer";
import EditorComponent from "../../Common/EditorComponent";
import { AppraisalContext } from "../AppraisalContext";
import useArticles from "../../../hooks/useArticles";
import PmFees from "../../Common/PMFees";

const useStyles = makeStyles(() => ({
  disabled: {
    pointerEvents: "none",
    opacity: 0.2,
  },
  imageContainer: {
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    marginBottom: "50px",
    display: "flex",
    "& img": {
      width: "100%",
    },
    "&:after": {
      content: '""',
      top: "100%",
      position: "absolute",
      height: "4px",
      width: "100%",
      backgroundColor: "#F7F7F7",
      background: "linear-gradient(to right, #EB9F47, #F6CB50)",
    },
  },
}));

const ServicesFees = ({ article = null, withImage = false, withoutSubTitle }) => {

  const toast = useRef(null);

  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );
  const isRental = category !== "sales";

  const classes = useStyles();
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);

  const serviceFeesHidden = useSelector(
    ({ appraisal }) => appraisal.meta.serviceFeesHidden,
    shallowEqual,
  );
  const serviceFeesTitle = useSelector(
    ({ appraisal }) => appraisal.meta.serviceFeesTitle,
    shallowEqual,
  );
  const serviceFeesSubtitle = useSelector(
    ({ appraisal }) => appraisal.meta.serviceFeesSubtitle,
    shallowEqual,
  );
  const fees = useSelector(({ appraisal }) => appraisal.pmfees, shallowEqual);

  const feeArticle = useArticles({ fee: true }).fee?.[0];

  const mainActicle = article || feeArticle;

  const hideDetailsHandler = val => {
    dispatch(updateMeta({ serviceFeesHidden: val }));
  };

  const onTitleUpdate = title => {
    dispatch(updateMeta({ serviceFeesTitle: title }));
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateMeta({ serviceFeesSubtitle: subtitle }));
  };

  const onUpdateArticleBody = (id, body) => {
    dispatch(updateArticleBody({ id, body }));
  };

  const onPMFeesUpdated = newList => {
    dispatch(setPmfees(newList));
  };

  return (
    <HideContainer value={serviceFeesHidden} onChange={hideDetailsHandler}>
      <Toast ref={toast} />
      <div className={serviceFeesHidden ? classes.disabled : ""}>
        <BlockContainer
          defaultTitle={serviceFeesTitle}
          defaultSubtitle={serviceFeesSubtitle}
          withoutSubTitle={withoutSubTitle}
          id="service-fees"
          onTitleUpdate={onTitleUpdate}
          onSubtitleUpdate={onSubtitleUpdate}
          hide={serviceFeesHidden}
        >
          {withImage ? (
            <div className={classes.imageContainer}>
              <img
                src={`${process.env.REACT_APP_API_URL}/img/${mainActicle?.image}`}
                alt={mainActicle?.title || ""}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="mt-5">
            <EditorComponent
              inline
              value={mainActicle?.body}
              onBlur={value => onUpdateArticleBody(mainActicle?._id, value)}
              type={"coverletter"}
              disabled={preview}
              charactersLimit={1100}
            />
            {isRental && fees?.length > 0 && (
              <PmFees pmFeesList={fees} onChange={onPMFeesUpdated} />
            )}
          </div>
        </BlockContainer>
      </div>
    </HideContainer>
  );
};

export default ServicesFees;
