import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import OfficeList from "../modules/Offices/OfficeList";
import SearchBox from "../modules/SearchBox";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CreateOffice from "../modules/Offices/CreateOfiice";
import { shallowEqual, useSelector } from "react-redux";
import Tabs from "../components/Tabs";

const tabsData = search => [
  {
    button: { title: "Active" },
    component: OfficeList,
    componentProps: {
      search,
      activeStatus: true,
    },
  },
  {
    button: { title: "Deactive" },
    component: OfficeList,
    componentProps: {
      search,
      activeStatus: false,
    },
  },
];

export const Offices = () => {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const suhbeader = useSubheader();
  suhbeader.setTitle("Offices");

  const history = useHistory();

  const [search, setSearch] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");

  const debounceSearchTrigger = useRef(
    debounce(searchTerm => {
      setSearch(searchTerm);
    }, 700),
  );

  useEffect(() => {
    if (user.permissions < 2) {
      history.push("/offices/" + user.office._id + "/teams");
    }
    if (user.permissions === 2) {
      history.push(`/offices/${user.office._id}`);
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    debounceSearchTrigger.current(debounceSearch);
  }, [debounceSearch]);

  const onChangeSearch = value => {
    setDebounceSearch(value);
  };

  return (
    <Switch id="kt_aside_tab_2">
      <Route exact path="/offices">
        <div className="card card-custom card-stretch">
          <div className="card-header flex-nowrap px-5">
            <div className="card-title flex-column-auto">
              <h3 className="card-label font-weight-bolder text-dark">
                Offices
              </h3>
            </div>
            <div className="ml-5 my-3">
              <button
                onClick={() => {
                  history.push(`/offices/new`);
                }}
                className="pull-right btn btn-primary w-120px h-100"
              >
                Activate Office
              </button>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="m-5">
              <SearchBox
                onChangeSearch={onChangeSearch}
                placeholder={"Search for Office"}
              />
            </div>
            <Tabs
              data={tabsData(search)}
              className="d-flex flex-grow-1 p-relative"
            />
          </div>
        </div>
      </Route>
      <Route exact path="/offices/new">
        <CreateOffice />
      </Route>
    </Switch>
  );
};
