import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container } from "@material-ui/core";

import EditorComponent from "../../Common/EditorComponent";
import MapWithProperty from "../../Common/GoogleMaps";
import GoogleMapContainer from "../../Common/GoogleMapContainer";
import BlockContainer from "./BlockContainer";
import { updateProperty } from "../../../../redux/reducers";
import HideContainer from "../components/HideContainer";
import Separator from "../CommonComponents/Separator";
import { GenericVideoModule } from "./GenericVideo";

const YourProperty = ({ withoutSubTitle, withoutVideo = false }) => {
  const dispatch = useDispatch();
  const property = useSelector(
    ({ appraisal }) => appraisal.property,
    shallowEqual,
  );

  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  const hideDetailsHandler = val => {
    dispatch(updateProperty({ detailsHidden: val }));
  };

  const onDescriptionChange = val => {
    const newAbout = {
      about: {
        image: property.about.image,
        description: val,
      },
    };
    dispatch(updateProperty(newAbout));
  };

  const onTitleUpdate = title => {
    dispatch(updateProperty({ sectionTitle: title }));
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateProperty({ sectionSubtitle: subtitle }));
  };

  const offices =
    property.address?.suburb?.offices
      ?.filter(({ active }) => active)
      .filter(({ location }) => location) || [];

  // console.log(property);
  // console.log(offices);

  return (
    <>
      <HideContainer
        value={property.detailsHidden}
        onChange={hideDetailsHandler}
      >
        <BlockContainer
          defaultTitle={property.sectionTitle}
          defaultSubtitle={property.sectionSubtitle}
          id="your-property"
          onTitleUpdate={onTitleUpdate}
          onSubtitleUpdate={onSubtitleUpdate}
          hide={property.detailsHidden}
          separator={false}
          withoutSubTitle={withoutSubTitle}
        >
          {appraisalType === "prospecting" ? (
            <GoogleMapContainer
              center={{
                lat: property.coordinates.lat,
                lng: property.coordinates.lon,
                zoom: property.coordinates.zoom,
              }}
              isMultiMarkers
              isOnlyMarkers
              backgroundTheme={"dim"}
              markers={offices.map(({ name, location }) => ({
                title: name?.replace(/Raine & Horne /, ""),
                lat: location?.coordinates[1],
                lng: location?.coordinates[0],
              }))}
            />
          ) : (
            <MapWithProperty
              lat={property.coordinates.lat}
              lng={property.coordinates.lon}
              zoom={property.coordinates.zoom}
            />
          )}
          <div className="min-h-15px" />
          <Container maxWidth="md" component="div" className="px-10 pb-1">
            <EditorComponent
              inline
              type="coverletter"
              value={property.about.description}
              onBlur={onDescriptionChange}
              placeholder="Enter any property commentary here..."
              charactersLimit={1200}
            />
            <Separator />
          </Container>
        </BlockContainer>
      </HideContainer>
      {!withoutVideo ? (
        <Container maxWidth="md" component="div" className="px-10 pb-1">
          <GenericVideoModule />
        </Container>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};

export default YourProperty;
