import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { format } from "date-fns";
import TeamsService from "app/api/TeamsService";
import SearchBox from "app/modules/SearchBox";
import useDebounce from "app/hooks/useDebounce";
import { Team } from "app/interfaces";
import DataGrid from "../../Common/CustomizedDataGrid";
import { setProfile } from "../redux/actions";
import { officeSelector } from "../redux/selectors";

const SelectTeam = () => {
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(search, 500);

  const office = useSelector(officeSelector);
  const officeId = office?._id;

  useEffect(() => {
    const fetchOfficeTeams = async () => {
      const { data } = await TeamsService.getOfficeTeams(
        officeId,
        debouncedSearchTerm,
      );
      const { teams }: { teams: Array<Team> } = data;
      teams.forEach(team => {
        team.id = team._id;
        team.updated_at = format(new Date(team.updated_at), "yyyy-MM-dd");
      }); // preparing for dataGrid)
      setTeams(teams);
    };

    setLoading(true);
    fetchOfficeTeams().finally(() => setLoading(false));
  }, [officeId, debouncedSearchTerm]);

  const columns = [
    { field: "name", headerName: "Profile Name", width: 300 },
    { field: "updated_at", headerName: "Updated", flex: 1 },
  ];

  const dispatch = useDispatch();

  const onRowSelected = (team: Team) => {
    dispatch(setProfile(team));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      height="100%"
    >
      <SearchBox
        value={search}
        onChangeSearch={(val: string) => setSearch(val)}
        className="my-3 mx-5"
      />
      <DataGrid
        columns={columns}
        rows={teams}
        loading={loading}
        onRowSelected={(val: { data: Team }) => onRowSelected(val.data)}
      />
    </Box>
  );
};

export default SelectTeam;
