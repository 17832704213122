import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Paper } from "@material-ui/core";
import {
  setAppraisal,
  setAppraisalId,
  setAvailableCalendars,
} from "redux/reducers";
import { RootState } from "redux/store";
import { getAppraisalData, getTeamData } from "redux/selectors";
import Send from "../../modules/Appraisals/CreateAppraisal/Send";
import PreviewDialog from "../../modules/Appraisals/AppraisalDetails/PreviewModal";
import AppraisalDetails from "../../modules/Appraisals/AppraisalDetails";
import AppraisalContextProvider from "../../modules/Appraisals/AppraisalContext";
import AppraisalsService from "../../api/AppraisalsService";
import OfficesService from "../../api/OfficesService";
import TeamsService from "../../api/TeamsService";
import { setStep } from "../../modules/CreateProposal/redux/actions";
import { CreatePropertySteps } from "../../interfaces";
import { activeStepSelector } from "../../modules/CreateProposal/redux/selectors";
import { generateBuilderData } from "app/modules/CreateProposal/redux/actions";
import {
  useToast,
  getErrorToastMessage,
  getSuccessToastMessage,
} from "app/hooks/useToast";


interface BuilderParams {
  appraisalId?: string;
}

const ProposalBuilder = ({ preview = false }) => {
  const dispatch = useDispatch();
  const { appraisalId } = useParams<BuilderParams>();
  const { showToastMessage } = useToast();

  const [showPreview, setPreview] = useState(false);
  const [showSend, setSend] = useState(false);
  const [proposalData, setProposalData] = useState<any>({});
  const [isLoadRequest, setLoadRequest] = useState(true);

  const activeStep = useSelector(activeStepSelector);
  const appraisalData: any = useSelector(getAppraisalData);
  const { isAutoSaveProposal } = useSelector(getTeamData);
  const isLoadProposalSaga = useSelector((state: RootState)  => state.common?.isLoadProposal, shallowEqual);

  useEffect(() => {
    const fetProposalData = async () => {
      const { data } = await AppraisalsService.getAppraisal(appraisalId);
      const { data: teamData } = await TeamsService.getTeam(data.meta.team);
      dispatch(setAppraisal({ 
        ...data, 
        id: appraisalId, 
        team: {
          ...data.team, 
          isAutoSaveProposal: teamData?.isAutoSaveProposal 
        } 
      }));
      const resp = await OfficesService.getOffice(data.meta.office);
      dispatch(setAvailableCalendars(resp.data?.timeline_settings));
      
      dispatch(setAvailableCalendars(resp.data?.timeline_settings));
    };
    dispatch(setAppraisal({})); // reset appraisal data
    if (appraisalId) {
      fetProposalData().then(() => setLoadRequest(false));
    } else {
      dispatch(generateBuilderData());
      setLoadRequest(false)
    }
  }, [appraisalId, dispatch]);

  useEffect(() => {
    if (activeStep === CreatePropertySteps.Builder) setSend(false);
    if (activeStep === CreatePropertySteps.Sender) setSend(true);
  }, [activeStep]);

  const goToBuilder = () => {
    setSend(false);
    dispatch(setStep(CreatePropertySteps.Builder));
  };

  const goToSender = () => {
    setSend(true);
    dispatch(setStep(CreatePropertySteps.Sender));
  };

  const onShowPreview = () => setPreview(true);

  const onClosePreview = () => {
    setPreview(false);
    localStorage.removeItem("proposal_preview");
    localStorage.removeItem("preview");
  };

  const pushAppraisal = appraisalData?.id
    ? AppraisalsService.putAppraisal
    : AppraisalsService.postAppraisal;

  const saveAppraisal = useCallback(() =>
    pushAppraisal(appraisalData, appraisalData?.id)
      .then(res => {
        showToastMessage(
          getSuccessToastMessage("The Proposal has been saved successfully"),
        );
        return appraisalData?.id ? res : dispatch(setAppraisalId(res.data._id));
      })
      .catch(() =>
        showToastMessage(
          getErrorToastMessage("Something went wrong, please try again later"),
        ),
      ),[appraisalData, dispatch, pushAppraisal, showToastMessage]);

  const sendAppraisal = () => {
    saveAppraisal().then(() => {
      localStorage.removeItem("proposal_preview");
      localStorage.removeItem("preview");
      setSend(true);
    });
  };

  const isLoad = isLoadProposalSaga < 1  || isLoadRequest;
  useEffect(() => {
    if (isLoad) return;
    const getStr = (obj: any) =>
      JSON.stringify({
        ...obj,
        id: null,
        team: { ...obj?.team, isAutoSaveProposal: "" },
      });
    const preState = getStr(proposalData);
    const newState = getStr(appraisalData);
    if (preState === newState || showSend) return;
    if (!isEmpty(proposalData) && isAutoSaveProposal) {
      saveAppraisal()
    }
    setProposalData(appraisalData);
  }, [
    isLoad,
    isAutoSaveProposal,
    activeStep,
    appraisalData,
    proposalData,
    setProposalData,
    saveAppraisal,
    showSend,
  ]);


  if (showSend) return <Send goBack={goToBuilder} />;
  return (
    <Paper>
      <AppraisalContextProvider preview={preview}>
        <AppraisalDetails
          showPreview={onShowPreview}
          showSend={goToSender}
          saveAppraisal={saveAppraisal}
        />
      </AppraisalContextProvider>
      <PreviewDialog
        show={showPreview}
        send={sendAppraisal}
        onClose={onClosePreview}
        appraisalId={appraisalData?.id}
      />
    </Paper>
  );
};

export default ProposalBuilder;
