import React from "react";
import { Button as PrButton } from "primereact/components/button/Button";
import { Dialog } from "primereact/dialog";

const DeleteModal = ({ show, onHide, onConfirm }) => {
  const deleteItemDialogFooter = (
    <React.Fragment>
      <PrButton label="No" icon="pi pi-times" className="p-button-text" onClick={onHide} />
      <PrButton label="Yes" icon="pi pi-check" className="p-button-text" onClick={onConfirm} />
    </React.Fragment>
  );

  return (
    <Dialog visible={show} style={{ width: '450px' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={onHide}>
      <div className="d-flex align-items-center">
        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
        <span>Are you sure you want to delete this Template?</span>
      </div>
    </Dialog>
  )
}

export default DeleteModal;
