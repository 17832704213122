import React, { useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";

import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CommonArticleSection from "../CommonArticleSection";
import { updateCommercialField } from "redux/reducers/appraisalReducer";
import Attachments from "../../Commercial/Attachments";
import AgentsSection from "../TeamDetails/AgentsSection";
import LocationOverView from "../../Commercial/LocationOverview";
import { GenericVideoModule } from "../GenericVideo";
import NextStepAction from "../NextSteps/NextStepAction";
import { AppraisalContext } from "../../AppraisalContext";
import Separator from "../../CommonComponents/Separator";
import PhotoGallery from "../../Commercial/PhotoGallery";
import PropertyOverview from "../../Commercial/PropertyOverview";
import SaleLeaseProcess from "../../Commercial/SaleLeaseProcess";

const useStyles = makeStyles(() => ({
  smallFontSize: {
    "& .mce-content-body p": {
      fontSize: 12,
      lineHeight: "18px",
      textAlign: "justify"
    },
  },
  customCommercialTable: {
    "& .mce-content-body table": {
      border: "none",
      "& td": {
        border: "none",
      },
      "& tr": {
        border: "none",
        borderBottom: "1px solid #d9c672",
      }
    }
  }
}))

const InformationMemorandum = () => {
  const { preview } = useContext(AppraisalContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    executiveSummary,
    opportunity,
    investmentHighlights,
    marketOverview,
    tenantOverview,
    financialOverview,
    priceList,
    disclaimer,
  } = useSelector(({ appraisal }) => appraisal.commercial, shallowEqual);

  const onArticleChange = (data, name) => {
    dispatch(updateCommercialField({ data, name }));
  };

  return (
    <div className={classes.customCommercialTable}>
      <div id="commercial-coverletter">
        <CoverletterImage
          isCommercial
          imageAspectRatio={ 1200 / 550 }
        />
      </div>
      <Container
        maxWidth="md"
        component="div"
        className="px-10"
        id="appraisal-details"
      >
        <Coverletter  withoutSeparator/>
        <GenericVideoModule />
        <Separator />
        <CommonArticleSection
          id="commercial-executiveSummary"
          data={executiveSummary}
          onChange={newData => onArticleChange(newData, "executiveSummary")}
        />
        <CommonArticleSection
          id="commercial-opportunity"
          data={opportunity}
          onChange={newData => onArticleChange(newData, "opportunity")}
          withInfographics
        />
        <PropertyOverview />
        <CommonArticleSection
          id="commercial-investmentHighlights"
          data={investmentHighlights}
          onChange={newData => onArticleChange(newData, "investmentHighlights")}
          withInfographics
        />
      </Container>
      <LocationOverView />
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <CommonArticleSection
          id="commercial-marketOverview"
          data={marketOverview}
          onChange={newData => onArticleChange(newData, "marketOverview")}
          withInfographics
        />
        <CommonArticleSection
          id="commercial-tenantOverview"
          data={tenantOverview}
          onChange={newData => onArticleChange(newData, "tenantOverview")}
          withInfographics
        />
        <CommonArticleSection
          id="commercial-financialOverview"
          data={financialOverview}
          onChange={newData => onArticleChange(newData, "financialOverview")}
        />
        <PhotoGallery />
        <SaleLeaseProcess />
        <CommonArticleSection
          id="commercial-priceList"
          data={priceList}
          onChange={newData => onArticleChange(newData, "priceList")}
        />
        <Attachments />
        <AgentsSection />
        <CommonArticleSection
          id="commercial-disclaimer"
          className={classes.smallFontSize}
          data={disclaimer}
          onChange={newData => onArticleChange(newData, "disclaimer")}
          withoutContainer
        />
        { preview ? "" :
          <NextStepAction className="my-10" />
        }
      </Container>
    </div>
  );
};

export default InformationMemorandum;
