import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import produce from "immer";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Button as PrButton } from "primereact/components/button/Button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import cn from "classnames";
import ImageEdit from "../../Common/ImageEdit";
import TeamMembers from "./TeamMembers";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import EditorComponent from "./../../Common/EditorComponent";
import TeamsService from "../../../api/TeamsService";
import UsersService from "../../../api/UsersService";
import { Checkbox } from "primereact/checkbox";
import {
  Box,
  withStyles,
  makeStyles,
  Switch,
  LinearProgress,
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import IconButton from "../../../components/ui/Buttons/IconButton";
import "primeicons/primeicons.css";
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
} from "../../Common/DraggableTable";
import { reorder } from "../../Appraisals/CreateAppraisal/utils";
import { API_URL } from "../../Auth/_redux/authCrud";
import axios from "axios";

const useStyles = makeStyles(() => ({
  tableRow: {
    "& td:not(:first-child)": {
      width: "30%",
      fontSize: "1rem",
      fontWeight: "400",
      color: "#495057",
      padding: "1rem",
    },
  },
  headTableRow: {
    "& th": {
      width: "30%",
      fontSize: "1rem",
      fontWeight: "600",
      color: "#495057",
      padding: "1rem",
    },
  },
  dragIndicatorIcon: {
    width: "5%",
    "&:hover": {
      color: "#ffb300",
    },
  },
}));

const StickyHeader = withStyles({
  root: {
    position: "sticky",
    top: 0,
    background: "#fff !important",
    zIndex: 999,
  },
})(Box);

const DescriptionText = withStyles({
  root: {
    backgroundColor: "#fcf8e3",
    display: "flex",
    textAlign: "center",
    padding: 20,
    fontSize: "15px",
    lineHeight: "21px",
    color: "#333",
    marginBottom: 15,
  },
})(Box);

const departmentCategories = [
  { label: "Residential Sales", value: "Residential Sales" },
  { label: "Residential PM", value: "Residential PM" },
  { label: "Commercial Sales", value: "Commercial Sales" },
  { label: "Commercial Leasing", value: "Commercial Leasing" },
  { label: "Business Sales", value: "Business Sales" },
];

const coverLetterTypes = [
  { label: "Pre-List", value: 1, type: "preAppraisal" },
  { label: "Listing Proposal", value: 2, type: "listingProposal" },
  { label: "Market Update", value: 3, type: "marketUpdate" },
  { label: "Agent Profile", value: 4, type: "agentProfiling" },
  { label: "Recruitment", value: 5, type: "recruitment" },
  { label: "Commercial IM", value: 6, type: "informationMemorandum" },
  {
    label: "Commercial Listing Submission",
    value: 7,
    type: "commercialListingProposal",
  },
  {
    label: "Residential IM",
    value: 8,
    type: "residentialInformationMemorandum",
  },
  {
    label: "Business Listing Proposal",
    value: 9,
    type: "businessListingProposal",
  },
  {
    label: "Prospecting",
    value: 13,
    type: "prospecting",
  },
  {
    label: "Tenant Information Guide",
    value: 14,
    type: "tenantHandbook",
  },
];

const allSmsTypes = {
  preAppraisal: "Pre List",
  listingProposal: "Listing Proposal",
  marketUpdate: "Market Update",
  agentProfiling: "Agent Profile",
  prospecting: "Prospecting",
  tenantHandbook: 'Tenant Information Guide',
  residentialInformationMemorandum: "Residential IM",
  informationMemorandum: "Commercial IM",
  commercialListingProposal: "Commercial Listing Submission",
  businessListingProposal: "Business Listing Proposal",
};

const SubmitDeleteTeam = ({ visible, onHide, onSubmit }) => {
  const deleteFooter = (
    <>
      <PrButton
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
      />
      <PrButton
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={onSubmit}
      />
    </>
  );
  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header="Confirm"
      modal
      footer={deleteFooter}
      onHide={onHide}
    >
      <div className="confirmation-content d-flex align-items-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        <span>Are you sure you want to delete this team?</span>
      </div>
    </Dialog>
  );
};

const EditOfficeTeam = () => {
  let emptyItem = {
    name: "",
    quote: "",
  };

  const [teamData, setTeamData] = useState({});
  const [officeMembers, setOfficeMembers] = useState([]);
  const [smsItems, setSmsItems] = useState([]);
  const { teamId, officeID } = useParams();
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(emptyItem);
  const [actionItemIndex, setActionItemIndex] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [itemDialog, setItemDialog] = useState(false);
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [letterType, setLetterType] = useState(1);
  const [propertyType, setPropertyType] = useState(1);
  const [showSubmitDelete, setSubmitDelete] = useState(false);
  const [proposalTypes, setProposalTypes] = useState([]);
  const [smsTypes, setSmsTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [officeGroupId, setOfficeGroupId] = useState(null);
  const classes = useStyles();

  const isCommercialType =
    teamData.departmentCategory === departmentCategories[2].value ||
    teamData.departmentCategory === departmentCategories[3].value;

  // const isBusinessType =
  //   teamData.departmentCategory === departmentCategories[4].value;

  const toast = useRef(null);
  const history = useHistory();

  const openNew = () => {
    setItem(emptyItem);
    setActionItemIndex(null);
    setSubmitted(false);
    setItemDialog(true);
  };

  useEffect(() => {
    const apiUrl = API_URL + `offices/${officeID}`;
    setLoading(true);
    axios
      .get(apiUrl)
      .then(resp => {
        setProposalTypes(resp.data?.proposalTypes || []);
        setOfficeGroupId(resp.data?.groupid);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong. Please try again later",
          life: 4000,
        });
      })
      .finally(() => setLoading(false));
  }, [officeID]);

  useEffect(() => {
    TeamsService.getTeam(teamId).then(resp => {
      setTeamData(resp.data);
      setItems(resp.data.testimonials?.items || []);
    });

    UsersService.getOfficeUsers(
      officeID,
      "",
      1,
      "active",
      "descending",
      true,
    ).then(resp => {
      setOfficeMembers(resp.data.users);
    });
    // eslint-disable-next-line
  }, [officeID, teamId]);

  useEffect(() => {
    if (officeGroupId === 966) {
      setLetterType(9);
    }
  }, [officeGroupId]);

  useEffect(() => {
    setSmsTypes(Object.keys(allSmsTypes));
  }, []);

  useEffect(() => {
    setSmsItems(teamData.smsOptions || smsTypes);
  }, [teamData.smsOptions, smsTypes]);

  const saveChanges = () => {
    TeamsService.updateTeam(teamId, teamData)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Team have been successfully updated",
          life: 3000,
        });
      })
      .catch(err => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Team hasn't been updated ${err.response.data.error.message}`,
          life: 5000,
        });
      });
  };

  const deleteOfficeTeam = () => {
    TeamsService.deleteTeam(teamId)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Team have been successfully deleted",
          life: 3000,
        });
        history.push(`/offices/${officeID}/teams`);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Team hasn't been deleted",
          life: 3000,
        });
      });
  };

  const editItem = (item, index) => {
    setItem({ ...item });
    setActionItemIndex(index);
    setItemDialog(true);
    setEdit(true);
  };

  const updateVideoField = (data, index) => {
    const newVideos = [...teamData.videos];
    newVideos[index] = data;
    setTeamData({ ...teamData, ...{ videos: newVideos } });
  };

  const confirmDeleteItem = (item, index) => {
    setItem(item);
    setActionItemIndex(index);
    setDeleteItemDialog(true);
  };

  const actionBodyTemplate = (rowData, index) => {
    return (
      <div className="d-flex justify-content-end testimonialActions">
        <PrButton
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-3"
          onClick={() => editItem(rowData, index)}
        />
        <IconButton
          icon="trash"
          variant="trash"
          onClick={() => confirmDeleteItem(rowData, index)}
        />
      </div>
    );
  };

  const testimonialBodyRow = (item, index) => {
    return (
      <Draggable
        draggableId={`${index} ${item.name}`}
        index={index}
        key={index}
      >
        {provided => (
          <TableRow
            className={classes.tableRow}
            ref={provided?.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TableCell className={classes.dragIndicatorIcon}>
              <DragIndicatorIcon className="m-auto" />
            </TableCell>
            <TableCell>
              <div>{item.name}</div>
            </TableCell>
            <TableCell>
              <div>{item.quote}</div>
            </TableCell>
            <TableCell>{actionBodyTemplate(item, index)}</TableCell>
          </TableRow>
        )}
      </Draggable>
    );
  };

  const hideDeleteItemDialog = () => {
    setDeleteItemDialog(false);
  };

  const hideDialog = () => {
    setItemDialog(false);
  };

  const saveItem = () => {
    setSubmitted(true);
    if (item.name.trim()) {
      let _items = [...items];
      let _item = { ...item };

      if (edit === true) {
        _items[actionItemIndex] = _item;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Item Updated",
          life: 3000,
        });
        setEdit(false);
      } else {
        _items.push(_item);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Item Created",
          life: 3000,
        });
      }

      setItems(_items);
      let newTeamData = produce(teamData, draftTeamData => {
        draftTeamData.testimonials.items = _items;
      });
      setTeamData(newTeamData);
      setItemDialog(false);
      setItem(emptyItem);
      setActionItemIndex(null);
    }
  };

  const deleteItem = () => {
    let _items = items.filter((val, index) => index !== actionItemIndex);
    setItems(_items);
    let newTeamData = produce(teamData, draftTeamData => {
      draftTeamData.testimonials.items = _items;
    });
    setTeamData(newTeamData);
    setDeleteItemDialog(false);
    setItem(emptyItem);
    setActionItemIndex(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Item Deleted",
      life: 3000,
    });
  };

  const deleteItemDialogFooter = (
    <>
      <PrButton
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteItemDialog}
      />
      <PrButton
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteItem}
      />
    </>
  );

  const itemDialogFooter = (
    <>
      <PrButton
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <PrButton
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveItem}
      />
    </>
  );

  const TableFooter = () => {
    const itemsLength = teamData.testimonials?.items.length;
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <PrButton
                label="Add Item"
                icon="pi pi-plus"
                className="btn btn-primary mr-4"
                onClick={() => openNew()}
                disabled={itemsLength >= 12}
              />
              {itemsLength >= 12 && (
                <div className="d-inline">
                  The maximum amount of testimonials has been reached. (12)
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _item = { ...item };
    _item[`${name}`] = val;
    setItem(_item);
  };

  const yourPropertyTypes = isCommercialType
    ? [
        { label: "For Sale", value: 1 },
        { label: "For Lease", value: 2 },
      ]
    : [
        { label: "For Sale", value: 1 },
        { label: "For Lease", value: 2 },
        { label: "Commercial IM", value: 3 },
        { label: "Residential IM", value: 4 },
      ];

  const agentBioImageUpdate = id => {
    setTeamData({ ...teamData, profilePortrait: id });
  };

  const updateTeamData = (field, val) => {
    setTeamData({ ...teamData, [field]: val });
  };

  const updateTestimonialsImage = val => {
    let newTeamData = produce(teamData, draftTeamData => {
      draftTeamData.testimonials.image = val;
    });
    setTeamData(newTeamData);
  };

  const updateTestimonialItems = val => {
    let newTeamData = produce(teamData, draftTeamData => {
      draftTeamData.testimonials.items = val;
    });
    setTeamData(newTeamData);
    setItems(val);
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    updateTestimonialItems(newItems);
  };

  const coverLetterType =
    letterType === 1 ? "coverLetter" : "coverLetter" + letterType;

  let propertyField;
  switch (propertyType) {
    case 1:
      propertyField = "propertyForSale";
      break;
    case 2:
      propertyField = "propertyForRent";
      break;
    case 3:
      propertyField = "propertyComIM";
      break;
    case 4:
      propertyField = "propertyResIM";
      break;
    default:
      propertyField = "propertyForSale";
  }

  const updateCoverLetter = val => {
    console.log(val)
    console.log(coverLetterType)
    setTeamData({ ...teamData, ...{ [coverLetterType]: val } });
  };

  const updateYourProperty = val => {
    setTeamData({ ...teamData, ...{ [propertyField]: val } });
  };

  const updateSmsOptions = ({ value, checked }) => {
    let selectedSmsOptions = [...smsItems];

    if (checked) {
      selectedSmsOptions.push(value);
    } else {
      selectedSmsOptions.splice(selectedSmsOptions.indexOf(value), 1);
    }

    setSmsItems(selectedSmsOptions);
    setTeamData({ ...teamData, ...{ smsOptions: selectedSmsOptions } });
  };

  return (
    <>
      <SubmitDeleteTeam
        onSubmit={deleteOfficeTeam}
        onHide={() => setSubmitDelete(false)}
        visible={showSubmitDelete}
      />
      <Toast ref={toast} />
      {loading && <LinearProgress color="primary" className="mb-5 mt-5" />}
      <StickyHeader className="card-header py-3">
        <div className="card-title">
          <h3 className="card-label font-weight-bolder text-dark">
            Profile Details - {teamData.name}
          </h3>
        </div>
        <div className="card-toolbar">
          <div className="text-right">
            <button
              onClick={() => setSubmitDelete(true)}
              className="btn btn-danger w-100px mr-5"
            >
              Delete
            </button>
            <button onClick={saveChanges} className="btn btn-primary w-100px">
              Save
            </button>
          </div>
        </div>
      </StickyHeader>
      <div className="card-body pt-4">
        <FormInput
          title="Agent/Property Manager"
          placeholder="Agent/Property Manager"
          value={(
            teamData.leader?.firstname || " " + teamData.leader?.lastname
          ).trim()}
          disabled
        />
        <FormInput
          title="Profile name"
          placeholder="Profile name"
          value={teamData.name || ""}
          onChange={val => setTeamData({ ...teamData, ...{ name: val } })}
        />
        <FormSelect
          options={departmentCategories}
          title="Department Category"
          value={teamData.departmentCategory || ""}
          placeholder="Please Select"
          onChange={val =>
            setTeamData({ ...teamData, ...{ departmentCategory: val } })
          }
        />
        <FormInput
          type="phone"
          title="Contact Phone"
          value={teamData.phone}
          placeholder="Contact Phone"
          onChange={val => setTeamData({ ...teamData, ...{ phone: val } })}
        />
        <div className="form-group row">
          <label className="col-lg-2 col-form-label">SMS Notifications</label>
          <div className="col-lg-10">
            {smsTypes.map((key, index) => {
              if (proposalTypes.includes(key)) {
                return (
                  <div className="field-checkbox">
                    <Checkbox
                      inputId={`sms-${index}`}
                      value={key}
                      onChange={val => updateSmsOptions(val)}
                      checked={smsItems.includes(key)}
                    ></Checkbox>
                    <label
                      htmlFor={`sms-${index}`}
                      className="p-checkbox-label"
                    >
                      {allSmsTypes[key]}
                    </label>
                    <br />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>

        <FormInput
          type="email"
          title="Contact Email"
          value={teamData.email}
          placeholder="Contact Email"
          onChange={val => setTeamData({ ...teamData, ...{ email: val } })}
        />
        <FormInput
          title="Generic Video Slot"
          value={teamData.videos?.[0] || ""}
          placeholder="url"
          onChange={val => updateVideoField(val, 0)}
        />
        <FormInput
          title="Agent Video Slot"
          value={teamData.videos?.[1] || ""}
          placeholder="url"
          onChange={val => updateVideoField(val, 1)}
        />
        <FormInput
          title="Website Profile Link"
          value={teamData.profileLink || ""}
          placeholder="url"
          onChange={val =>
            setTeamData({ ...teamData, ...{ profileLink: val } })
          }
        />
        <FormInput
          title={isCommercialType ? "Recent Transactions" : "Recent Sales"}
          value={teamData.recentSales || ""}
          placeholder="url"
          onChange={val =>
            setTeamData({ ...teamData, ...{ recentSales: val } })
          }
        />
        <FormInput
          title={isCommercialType ? "Current Listings" : "Current Rentals"}
          value={teamData.currentRentals || ""}
          placeholder="url"
          onChange={val =>
            setTeamData({ ...teamData, ...{ currentRentals: val } })
          }
        />
        <FormInput
          title="Marketing Brochure Link"
          value={teamData.marketingBrochureLink || ""}
          placeholder="url"
          onChange={val =>
            setTeamData({ ...teamData, ...{ marketingBrochureLink: val } })
          }
        />
        <FormInput
          title="Twitter"
          value={teamData.twitter || ""}
          placeholder="Twitter"
          onChange={val => setTeamData({ ...teamData, ...{ twitter: val } })}
        />
        <FormInput
          title="LinkedIn"
          value={teamData.linkedIn || ""}
          placeholder="LinkedIn"
          onChange={val => setTeamData({ ...teamData, ...{ linkedIn: val } })}
        />
        <FormInput
          title="Instagram"
          value={teamData.instagram || ""}
          placeholder="Instagram"
          onChange={val => setTeamData({ ...teamData, ...{ instagram: val } })}
        />
        <FormInput
          title="Facebook"
          value={teamData.facebook || ""}
          placeholder="Facebook"
          onChange={val => setTeamData({ ...teamData, ...{ facebook: val } })}
        />

        <div className="form-group row">
          <label className="col-lg-2 col-form-label">Auto Save Proposal</label>
          <div className="col-lg-10">
            <Switch
              checked={teamData.isAutoSaveProposal || false}
              onChange={e =>
                setTeamData({
                  ...teamData,
                  ...{ isAutoSaveProposal: e.target.checked },
                })
              }
              color="primary"
            />
          </div>
        </div>

        <Divider align="center">
          <span className="p-button">Coverletters</span>
        </Divider>

        <h4 className="card-label text-dark">
          Coverletter &nbsp;&nbsp;&nbsp;&nbsp;
          {officeGroupId !== 966 && (
            <Dropdown
              value={letterType}
              options={coverLetterTypes.filter(item =>
                proposalTypes.includes(item.type),
              )}
              onChange={e => setLetterType(e.value)}
              placeholder="Select Coverletter Type"
            />
          )}
        </h4>

        <EditorComponent
          value={teamData[coverLetterType]}
          onBlur={updateCoverLetter}
          type="settings"
        />

        <Divider align="center">
          <span className="p-button">Your Property</span>
        </Divider>

        <h4 className="card-label text-dark">
          Your Property &nbsp;&nbsp;&nbsp;&nbsp;
          <Dropdown
            value={propertyType}
            options={yourPropertyTypes}
            onChange={e => setPropertyType(e.value)}
            placeholder="Select Property Type"
          />
        </h4>

        <EditorComponent
          value={teamData[propertyField]}
          onBlur={updateYourProperty}
          type="settings"
        />

        <Divider align="center">
          <span className="p-button">Agent biography</span>
        </Divider>
        <ImageEdit
          src={teamData.profilePortrait}
          officeId={officeID}
          onImageSelected={agentBioImageUpdate}
          aspectRatio={1}
          defaultFolder="Staff Images"
          withDelete={Boolean(teamData.profilePortrait)}
        />
        <h4 className="card-label text-dark">Content</h4>
        <EditorComponent
          type="settings"
          value={teamData.biography}
          onBlur={val => setTeamData({ ...teamData, ...{ biography: val } })}
        />

        <Divider align="center">
          <span className="p-button">About the team</span>
        </Divider>
        <DescriptionText>
          Only use this section if you have your own team (e.g. you have your
          own sales associate, EA etc). An article already exists called Office
          Profile which covers information about your office and team as a
          whole.
        </DescriptionText>
        <ImageEdit
          src={teamData.image}
          officeId={officeID}
          onImageSelected={id => updateTeamData("image", id)}
          aspectRatio={1}
          defaultFolder="Staff Images"
          withDelete={Boolean(teamData.image)}
        />
        <h4 className="card-label text-dark">Content</h4>
        <EditorComponent
          type="settings"
          value={teamData.about}
          onBlur={val => setTeamData({ ...teamData, ...{ about: val } })}
        />

        <Divider align="center">
          <span className="p-button">Market Trends</span>
        </Divider>
        <h4 className="card-label text-dark">Content</h4>
        <EditorComponent
          type="settings"
          value={teamData.report}
          onBlur={val => setTeamData({ ...teamData, ...{ report: val } })}
        />

        <Divider align="center">
          <span className="p-button">Testimonials</span>
        </Divider>
        <ImageEdit
          src={teamData.testimonials?.image}
          officeId={officeID}
          onImageSelected={updateTestimonialsImage}
          aspectRatio={4.08}
        />

        <h4 className="card-label text-dark">Testimonials Items</h4>
        <Table>
          <TableHead>
            <TableRow className={classes.headTableRow}>
              <TableCell style={{ width: "5%" }} />
              <TableCell>Name</TableCell>
              <TableCell>Quote</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="testimonials">
              {provided => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => testimonialBodyRow(item, index))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          <div style={{ padding: "1rem" }}>
            <TableFooter />
          </div>
        </Table>

        <Dialog
          visible={deleteItemDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteItemDialogFooter}
          onHide={hideDeleteItemDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            {item && (
              <span>
                Are you sure you want to delete <b>{item.name}</b>?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={itemDialog}
          style={{ width: "450px" }}
          header="Testimonial"
          modal
          className="p-fluid"
          footer={itemDialogFooter}
          onHide={hideDialog}
        >
          <div className="p-field mb-5">
            <label htmlFor="text">Contact Name</label>
            <InputText
              id="text"
              value={item.name}
              onChange={e => onInputChange(e, "name")}
              required
              autoFocus
            />
            {submitted && !item.name && (
              <small className="p-error">Name is required.</small>
            )}
          </div>
          <div className="p-field">
            <label htmlFor="description">Quote</label>
            <InputTextarea
              id="description"
              maxLength={500}
              value={item.quote}
              onChange={e => onInputChange(e, "quote")}
              required
              rows={3}
              cols={20}
            />
            <div className={cn({ "text-danger": item.quote.length === 500 })}>
              Characters Limit: 500 characters, current number of characters:{" "}
              {item.quote.length}
            </div>
          </div>
        </Dialog>

        <Divider align="center">
          <span className="p-button">Personal Assistants</span>
        </Divider>
        <TeamMembers
          allMembers={officeMembers}
          setTeamData={setTeamData}
          teamData={teamData}
        />
      </div>
    </>
  );
};

export default EditOfficeTeam;
