import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./styles.scss";

const Tabs = ({ data, className, onTabClick }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const Component = data[activeIndex].component;
  const componentProps = data[activeIndex].componentProps;

  return (
    <div className={cn(className, "tabs-container")}>
      <div className="tab-titles layout-side-padding">
        {data.map((item, index) => (
          <button
            key={item.button.title}
            className={cn(
              "tab-button",
              item.button.variant && `${item.button.variant}`,
              {
                active: index === activeIndex,
              },
            )}
            onClick={() => {
              setActiveIndex(index);
              // onTabClick(index);
            }}
          >
            {item.button.title}
          </button>
        ))}
      </div>
      <div className="tab-content-wrapper">
        <Component {...componentProps} />
      </div>
    </div>
  );
};

Tabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        title: PropTypes.string.isRequired,
        variant: PropTypes.string,
      }),
      component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.elementType,
      ]),
      componentProps: PropTypes.object,
    }),
  ),
};

Tabs.defaultProps = {
  data: [
    {
      button: {
        variant: "",
      },
      component: "",
      componentProps: null,
    },
  ],
};

export default Tabs;
