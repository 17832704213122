import React, { useState } from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import MobileView from "../CreateAppraisal/MobileView";
import Button from "app/components/ui/Buttons/Button";
import IconButton from "app/components/ui/Buttons/IconButton";

const iframeStyle = {
  width: "100%",
  height: "100%",
  border: "none",
};

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    zIndex: "100 !important",
  },
  topBar: {
    position: "sticky",
    display: "flex",
    justifyContent: "space-between",
    height: 45,
    fontSize: 14,
    backgroundColor: "#3c3c3d",
  },
  topBarLeft: {
    display: "flex",
    paddingLeft: 12,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    "& .header": {
      margin: "auto 12px",
      color: "#fdb818",
    },
    "& .button-back": {
      color: "#fff",
      "& .icon": {
        transform: "rotateY(180deg)",
      },
      "& svg": {
        transform: "rotate(90deg)",
      },
    },
  },
  topBarCenter: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "#fff",
    "& g": {
      stroke: "#fff",
    },
    "& path": {
      stroke: "#fff",
    },
    "& button:first-child": {
      marginRight: 8,
    },
  },
}));

const PreviewDialog = ({ show, send, onClose, appraisalId }) => {
  const realUrl = window.location.origin + "/proposal-view/" + appraisalId;
  const previewUrl = realUrl + "?disableMetrics=true";

  const classes = useStyles();
  const [isMobile, setMobile] = useState(false);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullScreen
      classes={{ root: classes.dialogRoot }}
    >
      <div className={classes.topBar}>
        <div className={classes.topBarLeft}>
          <h5 className="header">Preview</h5>
          <Button icon="jumpUp" className="button-back" onClick={onClose}>
            Continue Editing
          </Button>
        </div>
        <div className={classes.topBarCenter}>
          <IconButton
            icon="laptop"
            tooltip="View on Desktop"
            onClick={() => setMobile(false)}
          />
          <IconButton
            icon="smartphone"
            tooltip="View on Tablet/Mobile"
            onClick={() => setMobile(true)}
          />
        </div>
        <Button variant="btn-brand-yellow" onClick={send}>
          Send Proposal
        </Button>
      </div>
      <MobileView mobile={isMobile}>
        <iframe src={previewUrl} style={iframeStyle} title={"Preview"} />
      </MobileView>
    </Dialog>
  );
};

export default PreviewDialog;
