import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import cn from 'classnames';
import withTooltip from 'app/hocs/withTooltip';

const IconButton = ({ icon, variant, className, onClick, disabled }) => (
  <button
    className={cn('icon-button', variant, className, { disabled })}
    onClick={!disabled ? onClick : null}
  >
    <SvgIcon iconName={icon} />
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'trash', 'round-btn-arrow-left', 'round-btn-arrow-right']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  icon: '',
  variant: 'default',
  className: '',
  onClick: null,
  disabled: false,
};

export default withTooltip(IconButton);
