import React from "react";
import { Dropdown } from 'primereact/dropdown';
import { makeStyles } from "@material-ui/styles";


const FormSelect = ({ title, value, placeholder, type, onChange, disabled, options }) => {
  const InputIcon = ({ type }) => {
    if (type !== "phone" && type !== "email") return "";
    return (
      <div className="input-group-prepend">
        <span className="input-group-text">
          {type === "phone" && <i className="fa fa-phone" />}
          {type === "email" && <i className="fa fa-at" />}
        </span>
      </div>
    );
  };

  const useStyles = makeStyles(theme => ({
    root: {
      "& span.p-placeholder": {
        padding: 0,
        fontFamily: "RaineHorneLight",
        fontSize: '1.08rem',
        lineHeight: '1.5',
      },
      "& span.p-inputtext": {
        padding: 0,
        fontFamily: "RaineHorneLight",
        fontSize: '1.08rem',
        lineHeight: '1.5',
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className="form-group row">
      <label className="col-lg-2 col-form-label">{title}</label>
      <div className="col-lg-10">
        <div className="input-group input-group-lg input-group-solid">
          <InputIcon type={type} />
          {
            // <select
            //   value={value}
            //   onChange={e => onChange(e.target.value)}
            //   placeholder={placeholder}
            //   disabled={disabled}
            //   className={`form-control form-control-lg form-control-solid`}
            // >
            //   {
            //     options.map(option => (
            //       <option key={option.value} value={option.value}>{`${option.label}`}</option>
            //     ))
            //   }
            // </select>
          }
            {

              <Dropdown
              value={value}
              options={options}
              className={`form-control form-control-lg form-control-solid d-inline-flex ${classes.root}`}
              onChange={e => onChange(e.target.value)}
              placeholder={placeholder}
              disabled={disabled}
              />
            }
        </div>
      </div>
    </div>
  );
};

export default FormSelect;
