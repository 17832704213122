import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    color: '#333',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',
    '& span': {
      padding: '0 10px',
    },
  },
  separator: {
    height: 1,
    width: '100%',
    background: "#333",
  }
})

const ButtonsSeparator = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.separator}/>
      <span>OR</span>
      <div className={classes.separator}/>
    </div>
  )
}

export default ButtonsSeparator;
