import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function Brand() {
  return (
    <>
      {/* begin::Brand */}
      <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-lg-6`}
      >
        {/* begin::Logo */}
        <SVG
          alt="logo"
          src={toAbsoluteUrl("/media/logos/logo-ampersand-yellow.svg")}
          className="max-h-30px"
          width="100%" height="100%"
        />
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
