import { shallowEqual, useSelector } from "react-redux";
import { articles as articlesConst } from "../../app/assets/constants";
import _ from "lodash";

const defaultSelectedArticles = {
  teamDetails: true,
  nextStepsSection: true,
  marketing: true,
  opinion: true,
  nextSteps: true,
  fee: true,
  methodsOfSale: true,
  ourServices: true,
  ourBroker: true,
  businessSupport: true,
  growBusiness: true,
  chooseUs: true,
  common: true,
};

const emptySelectedArticles = {
  teamDetails: false,
  nextStepsSection: false,
  marketing: false,
  opinion: false,
  nextSteps: false,
  fee: false,
  methodsOfSale: false,
  ourServices: false,
  ourBroker: false,
  businessSupport: false,
  growBusiness: false,
  chooseUs: false,
  common: false,
};

const useArticles = props => {
  const selectedArticles = props
    ? { ...emptySelectedArticles, ...props }
    : defaultSelectedArticles;
  const articles = useSelector(
    ({ appraisal }) => _.uniqBy(appraisal.articles, "slug"),
    shallowEqual,
  );
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );

  let result = {};
  articles.forEach(article => {
    if (
      selectedArticles.teamDetails &&
      articlesConst.teamDetails[category].includes(article.slug)
    ) {
      result.teamDetails = [...(result.teamDetails || []), article];
    } else if (
      selectedArticles.nextStepsSection &&
      articlesConst.nextStepsSection[category].includes(article.slug)
    ) {
      result.nextStepsSection = [...(result.nextStepsSection || []), article];
    } else if (
      selectedArticles.marketing &&
      articlesConst.marketing[category].includes(article.slug)
    ) {
      result.marketing = [...(result.marketing || []), article];
    } else if (
      selectedArticles.opinion &&
      articlesConst.opinion[category].includes(article.slug)
    ) {
      result.opinion = [...(result.opinion || []), article];
    } else if (
      selectedArticles.nextSteps &&
      articlesConst.nextSteps[category].includes(article.slug)
    ) {
      result.nextSteps = [...(result.nextSteps || []), article];
    } else if (
      selectedArticles.fee &&
      articlesConst.fee[category].includes(article.slug)
    ) {
      result.fee = [...(result.fee || []), article];
    } else if (
      selectedArticles.methodsOfSale &&
      articlesConst.methodsOfSale[category].includes(article.slug)
    ) {
      result.methodsOfSale = [...(result.methodsOfSale || []), article];
    } else if (
      selectedArticles.ourServices &&
      articlesConst.ourServices[category].includes(article.slug)
    ) {
      result.ourServices = [...(result.ourServices || []), article];
    } else if (
      selectedArticles.ourBroker &&
      articlesConst.ourBroker[category].includes(article.slug)
    ) {
      result.ourBroker = [...(result.ourBroker || []), article];
    } else if (
      selectedArticles.businessSupport &&
      articlesConst.businessSupport[category].includes(article.slug)
    ) {
      result.businessSupport = [...(result.businessSupport || []), article];
    } else if (
      selectedArticles.growBusiness &&
      articlesConst.growBusiness[category].includes(article.slug)
    ) {
      result.growBusiness = [...(result.growBusiness || []), article];
    } else if (
      selectedArticles.chooseUs &&
      articlesConst.chooseUs[category].includes(article.slug)
    ) {
      result.chooseUs = [...(result.chooseUs || []), article];
    } else if (
      selectedArticles.common &&
      articlesConst.common[category].includes(article.slug)
    ) {
      result.common = [...(result.common || []), article];
    }
  });
  return result;
};
export default useArticles;
