import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/`
// export const API_URL = 'http://127.0.0.1:3334/'
export const LOGIN_URL = API_URL + "users/login";
export const ME_URL = API_URL + "users/me";

export async function login(username, password) {
  return await axios.post(LOGIN_URL, { username, password });
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return await axios.get(ME_URL);
}
