import React from 'react';
import { makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

const useStyles = makeStyles({
  container: {
    "&>div":{
      height: "100% !important",
    },
  },
  dataGrid: {
    border: "none",
    "&	.MuiDataGrid-row": {
      cursor: "pointer",
      color: "#666",
      fontSize: "1rem",
    },
    "& .MuiDataGrid-viewport": {
      maxWidth: "100% !important",
    },
    "& .MuiDataGrid-columnsContainer": {
      fontSize: "15px",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    }
  }
})

const CustomizedDataGrid = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <DataGrid
        autoHeight
        disableColumnMenu
        className={`${classes.dataGrid} ${props.className || ''}`}
        {...props}
      />
    </div>
  )
}

export const StatusRow = ({ value }) => {
  return (
    <div className={`${value.toLowerCase() === "yes" ? "text-success" : "text-danger"}`}>
      {value}
    </div>
  )
}

export default CustomizedDataGrid;
