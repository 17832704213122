import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { WidgetMetric } from "shared";

import "./styles.scss";

const Widget = ({
  label,
  title,
  subtitle,
  variant,
  className,
  icon,
  metrics,
}) => (
  <div className={`widget ${variant} ${className}`}>
    <div className="widget_label">{label}</div>
    <div className={cn("widget_title-wrapper", { "with-subtitle": subtitle })}>
      <span className="widget_title">{title}</span>
      {icon}
    </div>
    {metrics && <WidgetMetric {...metrics} />}
    {subtitle && <span className="widget_subtitle">{subtitle}</span>}
  </div>
);

Widget.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.any]),
};

Widget.defaultProps = {
  subtitle: "",
  variant: "grey",
  className: "",
  icon: null,
};

export default Widget;
