import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { TableCell, TableRow } from "../../../../../../Common/DraggableTable";
import InlineEditor from "../../../../../CommonComponents/InlineEditor";
import IconButton from "../../../../../../../components/ui/Buttons/IconButton";
import { AppraisalContext } from "../../../../../AppraisalContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  priceCol: {
    width: 170,
    textAlign: "right",
    lineHeight: "30px",
    [theme.breakpoints.down("sm")]: {
      width: 135,
    },
    "&:last-child": {
      width: 170,
      [theme.breakpoints.down("sm")]: {
        width: 135,
      },
    },
  },
}));

const SortableRaw = ({
  data,
  provided,
  onUpdate,
  onDelete,
  showAmountColumns,
  ...other
}) => {
  const classes = useStyles();
  const { preview } = useContext(AppraisalContext);

  const onRawValueChange = (field, value) => {
    onUpdate({ ...data, [field]: value });
  };

  return (
    <TableRow
      ref={provided?.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...other}
    >
      <TableCell>
        {preview ? (
          <span style={{ wordBreak: "break-all" }}>{data.text}</span>
        ) : (
          <InlineEditor
            value={data.text}
            placeholder="Name"
            onBlur={val => onRawValueChange("text", val)}
            charLimit={200}
            multiline
          />
        )}
      </TableCell>
      <TableCell
        className={classes.priceCol}
        hidden={preview && !showAmountColumns}
      >
        <InlineEditor
          inputProps={{ style: { textAlign: "right" } }}
          value={data.amount}
          placeholder={preview ? "" : "Amount"}
          onBlur={val => onRawValueChange("amount", val)}
          charLimit={15}
        />
      </TableCell>

      {!preview && (
        <TableCell style={{ width: "60px" }}>
          <IconButton icon="trash" variant="trash" onClick={onDelete} />
        </TableCell>
      )}
    </TableRow>
  );
};

const SortableTableItem = ({
  item,
  index,
  onUpdate,
  onDelete,
  showAmountColumns,
}) => {
  const { preview } = useContext(AppraisalContext);
  return (
    <Draggable isDragDisabled={preview} draggableId={`${index}`} index={index}>
      {provided => (
        <SortableRaw
          index={index}
          data={{ ...item, index }}
          provided={provided}
          onDelete={onDelete}
          onUpdate={onUpdate}
          showAmountColumns={showAmountColumns}
        />
      )}
    </Draggable>
  );
};

export default SortableTableItem;
