import React, { useContext } from "react";
import SVG from "react-inlinesvg";
import AddIcon from '../../assets/images/plus.svg'
import TrashIcon from '../../assets/images/trash.svg'
import { makeStyles } from "@material-ui/core";
import { AppraisalContext } from "../Appraisals/AppraisalContext";

const HoverProposalIcon = ({ type = 'add', onClick, children }) => {
  const { preview } = useContext(AppraisalContext);

  const useStyles = makeStyles(theme => ({
    cover: {
      position: 'relative',
      width: 'inherit',
      height: '100%',
      "&:hover $overlay": {
        opacity: preview ? 0 : 1,
      },
    },
    addIcon: {
      fill: theme.palette.primary.main,
    },
    overlay: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      opacity: 0,
      transition: '.3s ease',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 22,
      boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
      "& svg": {
        width: 50,
        height: 50,
        marginBottom: 15,
      }
    }
  }))
  const classes = useStyles({ preview, type });
  return (
    <div className={classes.cover} onClick={onClick}>
      { children }
      <div className={classes.overlay}>
        { type === 'remove' && <SVG src={TrashIcon}/> }
        { type === 'add' && <SVG src={AddIcon} className={classes.addIcon}/> }
        <div>
          { type === 'remove' && 'Remove' }
          { type === 'add' && 'Select' }
        </div>
      </div>
    </div>
  )
};

export default React.memo(HoverProposalIcon)
