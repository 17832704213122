import React from "react";
import { ProposalTypes } from "app/interfaces";
import AgentProfiling from "./AgentProfiling";
import MarketUpdate from "./MarketUpdate";
import PreAppraisal from "./PreAppraisal";
import ListingProposal from "./ListingProposal";
import InformationMemorandum from "./InformationMemorandum";
import Prospecting from "./Prospecting";
import CommercialListingProposal from "./CommercialListingProposal"
import BusinessListingProposal from "./BusinessListingProposal"
import ResInformationMemorandum from "./ResInformationMemorandum";
import TenantHandbook from "./TenantHandbook";

const AppraisalTypeContent = ({ type }: { type: ProposalTypes }): JSX.Element  => {
  if (type === ProposalTypes.listingProposal) return <ListingProposal />;
  if (type === ProposalTypes.agentProfiling) return <AgentProfiling />;
  if (type === ProposalTypes.marketUpdate) return <MarketUpdate />;
  if (type === ProposalTypes.preAppraisal) return <PreAppraisal />;
  if (type === ProposalTypes.informationMemorandum) return <InformationMemorandum />;
  if (type === ProposalTypes.prospecting) return <Prospecting />;
  if (type === ProposalTypes.commercialListingProposal) return <CommercialListingProposal />;
  if (type === ProposalTypes.businessListingProposal) return <BusinessListingProposal />;
  if (type === ProposalTypes.residentialInformationMemorandum) return <ResInformationMemorandum />;
  if (type === ProposalTypes.tenantHandbook) return <TenantHandbook />;
  return <React.Fragment></React.Fragment>;
}

export default React.memo(AppraisalTypeContent);

