import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  bodyParagraph: {
    color: '#666363',
    fontWeight: '300',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '32px !important',
    marginBottom: '40px'
  }
}));

const TestimonialList = ({items}) => {
  const classes = useStyles();
  return (
    <>
      { items.map(item => (
        <div className={classes.bodyParagraph} key={item.name}>
          <img src={`${process.env.REACT_APP_API_URL}/img/5dbf9a0f4f55f40014d5d130`} alt={''}/> <br/>
          {item.quote} <br/>
          {item.name}
        </div>
      ))}
    </>

  )

}

export default TestimonialList;