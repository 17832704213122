import React, { useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";

import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CommonArticleSection from "../CommonArticleSection";
import { updateCommercialField } from "redux/reducers/appraisalReducer";
import Attachments from "../../Commercial/Attachments";
import LocationOverView from "../../Commercial/LocationOverview";
import { GenericVideoModule } from "../GenericVideo";
import NextStepAction from "../NextSteps/NextStepAction";
import { AppraisalContext } from "../../AppraisalContext";
import Separator from "../../CommonComponents/Separator";
import PhotoGallery from "../../Commercial/PhotoGallery";
import PropertyOverview from "../../Commercial/PropertyOverview";
import SaleLeaseProcess from "../../Commercial/SaleLeaseProcess";
import TeamDetails from "../TeamDetails";
import {
  RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
  RURAL_INFOGRAPHIC_FOLDER_NAME,
} from "app/constants/commonConstants";

const useStyles = makeStyles(() => ({
  smallFontSize: {
    "& .mce-content-body p": {
      fontSize: 12,
      lineHeight: "18px",
      textAlign: "justify",
    },
  },
  customCommercialTable: {
    "& .mce-content-body table": {
      border: "none",
      "& td": {
        border: "none",
      },
      "& tr": {
        border: "none",
        borderBottom: "1px solid #d9c672",
      },
    },
  },
}));

const InformationMemorandum = () => {
  const { preview } = useContext(AppraisalContext);
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    executiveSummary,
    opportunity,
    investmentHighlights,
    marketOverview,
    tenantOverview,
    financialOverview,
    priceList,
    disclaimer,
  } = useSelector(({ appraisal }) => appraisal.commercial, shallowEqual);

  const onArticleChange = (data, name) => {
    dispatch(updateCommercialField({ data, name }));
  };

  const genericVideoHidden = useSelector(
    state => state.appraisal.meta.genericVideoHidden,
  );

  const coverLetterHidden = useSelector(
    state => state.appraisal.vendor?.coverLetterHidden,
    shallowEqual,
  );

  return (
    <div className={classes.customCommercialTable}>
      <div id="residential-coverletter">
        <CoverletterImage imageAspectRatio={1200 / 550} />
      </div>
      <Container
        maxWidth="md"
        component="div"
        className="px-10"
        id="appraisal-details"
      >
        <Coverletter withoutSeparator />
        <GenericVideoModule />
        {genericVideoHidden && coverLetterHidden ? (
          <React.Fragment />
        ) : (
          <Separator />
        )}
        <CommonArticleSection
          id="residential-executiveSummary"
          data={executiveSummary}
          onChange={newData => onArticleChange(newData, "executiveSummary")}
        />
        <CommonArticleSection
          id="residential-opportunity"
          data={opportunity}
          onChange={newData => onArticleChange(newData, "opportunity")}
          withInfographics
          infographicsFolders={[
            RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
            RURAL_INFOGRAPHIC_FOLDER_NAME,
          ]}
          withInfographicsSelection
        />
        <PropertyOverview
          id="residential-propertyOverview"
          infographicsFolders={[
            RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
            RURAL_INFOGRAPHIC_FOLDER_NAME,
          ]}
          withInfographicsSelection
        />
        <CommonArticleSection
          id="residential-investmentHighlights"
          data={investmentHighlights}
          onChange={newData => onArticleChange(newData, "investmentHighlights")}
          withInfographics
          infographicsFolders={[
            RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
            RURAL_INFOGRAPHIC_FOLDER_NAME,
          ]}
          withInfographicsSelection
        />
      </Container>
      <LocationOverView
        id="residential-locationOverview"
        infographicsFolders={[
          RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
          RURAL_INFOGRAPHIC_FOLDER_NAME,
        ]}
        withInfographicsSelection
      />
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <CommonArticleSection
          id="residential-marketOverview"
          data={marketOverview}
          onChange={newData => onArticleChange(newData, "marketOverview")}
          withInfographics
          infographicsFolders={[
            RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
            RURAL_INFOGRAPHIC_FOLDER_NAME,
          ]}
          withInfographicsSelection
        />
        <CommonArticleSection
          id="residential-tenantOverview"
          data={tenantOverview}
          onChange={newData => onArticleChange(newData, "tenantOverview")}
          withInfographics
          infographicsFolders={[
            RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
            RURAL_INFOGRAPHIC_FOLDER_NAME,
          ]}
          withInfographicsSelection
        />
        <CommonArticleSection
          id="residential-financialOverview"
          data={financialOverview}
          onChange={newData => onArticleChange(newData, "financialOverview")}
        />
        <PhotoGallery id="residential-photo-gallery" />
        <SaleLeaseProcess
          id="residential-saleLeaseProcess"
          infographicsFolders={[
            RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME,
            RURAL_INFOGRAPHIC_FOLDER_NAME,
          ]}
          withInfographicsSelection
        />
        <CommonArticleSection
          id="residential-priceList"
          data={priceList}
          onChange={newData => onArticleChange(newData, "priceList")}
        />
        <Attachments />
        <TeamDetails
          awards
          difference
          profile
          community
          history
          withoutTestimonial
        />
        <CommonArticleSection
          id="residential-disclaimer"
          className={classes.smallFontSize}
          data={disclaimer}
          onChange={newData => onArticleChange(newData, "disclaimer")}
          withoutContainer
        />
        {preview ? "" : <NextStepAction className="my-10" />}
      </Container>
    </div>
  );
};

export default InformationMemorandum;
