import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 10,
    '& svg': {
      color: '#fdb818',
    }
  },
}));


const Loader = ({ styleParams = {} }) => {
  const classes = useStyles();
  return (
    <div
      className={`container-centered-items ${classes.loader}`}
      style={styleParams}
    >
      <CircularProgress />
    </div>
  );
};
export default Loader;
