import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";

import { getImageUrl } from "shared";
import { Article, TabsContextProps } from "app/interfaces";
import { updateGrowBusiness, updateArticleBody } from "redux/reducers";
import { getGrowBusinessData } from "redux/selectors/builderSelectors";

import useArticles from "app/hooks/useArticles";
import photoForArticles from "../../../assets/images/marketing/visuals.jpeg";
import TabsBlock from "../components/TabsBlock";
import { YoutubeVideo } from "app/modules/Appraisals/CommonComponents/YoutubeVideo";
import ecosystem from "app/assets/images/ecosystem.jpg";

const labelTabs: { [key: string]: string } = {
  compassPlus: "CompassPlus",
  designPlus: "DesignPlus",
  canva: "Canva",
  amplify: "Amplify",
  digiKitPlus: "DigiKitPlus",
  processPlus: "ProcessPlus",
};

const slugTabs: { [key: string]: string } = {
  compassPlus: "compassplus",
  designPlus: "designplus",
  canva: "canva",
  amplify: "amplify",
  digiKitPlus: "digikitplus",
  processPlus: "processplus",
};

const mediaAssets: { [key: string]: any } = {
  compassPlus: <img src={photoForArticles} alt="Compass Plus" />,
  designPlus: <img src={photoForArticles} alt="Design Plus" />,
  canva: <img src={photoForArticles} alt="Canva" />,
  amplify: (
    <YoutubeVideo video={"https://www.youtube.com/watch?v=wMRvOK5PerQ"} />
  ),
  digiKitPlus: <img src={photoForArticles} alt="DigiKit Plus" />,
  processPlus: <img src={photoForArticles} alt="Process Plus" />,
};

const growBusinessStyles = makeStyles({
  ecosystemContainer: {
    textAlign: "center",
    padding: "20px 0",
    "& img": {
      maxWidth: "600px",
      padding: "30px 0 0",
      width: "100%",
    },
  },
});

const GrowBusiness = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = growBusinessStyles();
  const growBusinessData = useSelector(getGrowBusinessData);
  const articles: { [key: string]: any } = useArticles({ growBusiness: true });

  const updateProp = (fildName: string, val: any) => {
    dispatch(updateGrowBusiness({ [fildName]: val }));
  };

  const getMedia = (props: {
    tabImage: string | undefined;
    title: string | undefined;
  }) => {
    if (!props || !props.tabImage) return null;
    const src = getImageUrl(props.tabImage);
    return <img src={src} alt={props.title} />;
  };

  const onTabsUpdate = (tabName: string, field: string, value: boolean) => {
    const preGrowBusinessType = growBusinessData.types;
    const updateGrowBusinesType = {
      ...preGrowBusinessType,
      [tabName]: {
        ...preGrowBusinessType[tabName],
        [field]: value,
      },
    };
    return updateProp("types", updateGrowBusinesType);
  };

  const onUpdateArticleBody = (id: string, body: string) => {
    if (!id) return;
    dispatch(updateArticleBody({ id, body }));
  };

  const tabCategories = Object.keys(growBusinessData.types || {});
  const tabsContent = tabCategories.reduce(
    (acc: Array<TabsContextProps>, item): any => {
      const articleSlug = slugTabs[item] || item;
      const article = articles?.growBusiness?.find(
        (art: Article) => art.slug === articleSlug,
      );
      return [
        ...acc,
        {
          key: item,
          label: labelTabs[item] || "",
          isHiddenTab: growBusinessData.types?.[item]?.hidden || false,
          onHideChange: (value: boolean) => onTabsUpdate(item, "hidden", value),
          media: getMedia(article) || mediaAssets[item] || null,
          body: article?.body || "",
          changeBody: (newBody: string) =>
            onUpdateArticleBody(article?._id, newBody),
        },
      ];
    },
    [],
  );

  const EcosystemBlock = () => (
    <div className={classes.ecosystemContainer}>
      <Typography
        component="p"
        variant="subtitle1"
        align="center"
        className="common-subTitle"
      >
        Your Product Ecosystem
      </Typography>
      <img src={ecosystem} alt="ecosystem" />
    </div>
  );

  return (
    <>
      <TabsBlock
        id="grow-business"
        title={growBusinessData.title}
        subtitle={growBusinessData.subtitle}
        isHiddenSection={growBusinessData.hidden}
        onChangeHiddenSection={(value: boolean) => updateProp("hidden", value)}
        onTitleUpdate={(value: string) => updateProp("title", value)}
        onSubtitleUpdate={(value: string) => updateProp("subtitle", value)}
        tabsContent={tabsContent}
      >
        <EcosystemBlock />
      </TabsBlock>
    </>
  );
};

export default GrowBusiness;
