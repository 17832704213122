import React, { useContext } from "react";

import { Box } from "@material-ui/core";
import BlockContainer from "../../AppraisalDetails/BlockContainer";
import HideContainer from "../../components/HideContainer";
import { AppraisalContext } from "../../AppraisalContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateCommercialField } from "../../../../../redux/reducers";
import EditorComponent from "../../../Common/EditorComponent";
import Infographics from "../Infographics";

const PropertyOverview = ({ id = null, infographicsFolders, withInfographicsSelection }) => {
  const { preview } = useContext(AppraisalContext);
  const dispatch = useDispatch();

  const propertyOverview = useSelector(({ appraisal }) => appraisal.commercial.propertyOverview, shallowEqual);
  const updatePropertyOverview = newData => {
    const data = { ...propertyOverview, ...newData };
    dispatch(updateCommercialField({ data, name: 'propertyOverview' }));
  };
  const onInfographicsUpdate = newInfographics => {
    const data = { ...propertyOverview, infographics: newInfographics };
    dispatch(updateCommercialField({ data, name: 'propertyOverview' }));
  }

  const onHideChange = val => updatePropertyOverview({ hidden: val });
  const onTitleUpdate = val => updatePropertyOverview({ title: val });
  const onArticleUpdate = val => updatePropertyOverview({ body: val });

  return (
    <HideContainer value={propertyOverview.hidden} onChange={onHideChange}>
      <BlockContainer
        id={id || "commercial-propertyOverview"}
        defaultTitle={propertyOverview.title}
        onTitleUpdate={onTitleUpdate}
        hide={propertyOverview.hidden}
        withoutSubTitle
      >
        <Box mt={5}>
          <EditorComponent
            inline
            value={propertyOverview.body}
            onBlur={onArticleUpdate}
            disabled={preview}
            charactersLimit={1100}
          />
        </Box>
        <Infographics
          infographics={propertyOverview.infographics}
          onChange={onInfographicsUpdate}
          infographicsFolders={infographicsFolders}
          withInfographicsSelection={withInfographicsSelection}
        />
      </BlockContainer>
    </HideContainer>
  );
};


export default PropertyOverview;
