import React, { useContext, useState } from "react";
import {
  Drawer,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { AppraisalContext } from "../AppraisalContext";
import LeftMenuContent from "./LeftMenu";
import ActionMenuCover from "./ActionMenuCover";
import CloseOutlined from "@material-ui/icons/CloseOutlined";

const useStyles = makeStyles({
  drawer: {
    backgroundColor: "#EBEBEC",
    width: "100%",
  },
  drawerHeader: {
    position: "absolute",
    right: "10px",
    top: "5px",
    zIndex: 3,
  },
  previewContainer: {
    display: "flex",
    position: "relative",
    overflowY: "scroll",
    backgroundColor: "#fff",
  },
});

const AppraisalMenus = ({
  onSync,
  saveLoading,
  onPreview,
  onPrint,
  onSave,
  turnAutoSave,
  onSend,
  onSaveTemplate,
  onFlk,
  children,
}) => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const { preview } = useContext(AppraisalContext);

  const LeftMenu = () =>
    isMobile ? (
      <Drawer
        anchor="left"
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setShowDrawer(false)}>
            <CloseOutlined />
          </IconButton>
        </div>
        <LeftMenuContent
          drawerWidth={"100%"}
          onSelect={() => setShowDrawer(false)}
        />
      </Drawer>
    ) : (
      <LeftMenuContent drawerWidth={250} />
    );

  return (
    <>
      {preview && <LeftMenu />}
      <div className="w-100">
        <ActionMenuCover
          saveLoading={saveLoading}
          onPreview={onPreview}
          onPrint={onPrint}
          onSave={onSave}
          turnAutoSave={turnAutoSave}
          onSaveTemplate={onSaveTemplate}
          onFlk={onFlk}
          onSend={onSend}
          onMenu={() => setShowDrawer(true)}
          onSync={onSync}
        >
          {children}
        </ActionMenuCover>
      </div>
    </>
  );
};

export default AppraisalMenus;
