import { shallowEqual, useSelector } from "react-redux";
import { ProposalTypes } from "app/interfaces";

export const useCommercial = () => {
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );
  const isCommercial = [
    ProposalTypes.informationMemorandum,
    ProposalTypes.commercialListingProposal,
  ].includes(appraisalType);

  return {
    isCommercial,
  };
};
