import { all, put, takeLatest, call, select } from "redux-saga/effects";
import {
  setIsLoadingLeaders,
  setLeaders,
  setRecentProposalsChartData,
  setProposalsWidgetData,
  setIsLoadingProposalsWidget,
  setIsLoadingFooterWidgets,
  setFooterWidgetsData,
  setUsersSessions,
  setOfficesSessions,
  setIsLoadingLeaderboardChart,
} from "../reducers/dashboardReducer";
import {
  setProposalsByType,
  setProposalsMetrics,
} from "../reducers/proposalsReducer";
import {
  fetchLeaders,
  fetchRecentProposalsChartData,
  fetchProposalsWidgetData,
  fetchFooterWidgetsData,
  fetchProposalsByTypeData,
} from "../actions";
import {
  getOfficesSessions,
  getRange,
  getUsersSessions,
  getWeeks,
} from "../selectors/dashboardSelector";
import {
  getUnopenedProposalsTotal,
  getSentProposalsTotal,
  getPrevSentProposalsTotal,
  getClicksPercent,
  getAverageReadingTime,
  getAverageOpens,
  getArticlesArray,
  getPrevUnopenedProposalsTotal,
} from "../selectors/proposalsSelector";
import {
  fetchProposalStatistic,
  fetchClicksPercent,
  fetchArticlesSaga,
  fetchAverageOpensSaga,
  fetchReadingTimeSaga,
} from "./proposalsSaga";
import AppraisalsService from "app/api/AppraisalsService";
import { AGENTS } from "app/constants/commonConstants";
import {
  getOneWeek,
  getByMonths,
} from "app/modules/Dashboard/ProposalsChart/utils/recentProposalsChartData";
import SessionsService from "../../app/api/SessionsService";

function* fetchUsersSessionsSaga() {
  const {
    data: { usersCount },
  } = yield call(SessionsService.getUsersSessions);
  yield put(setUsersSessions(usersCount));
}

function* fetchOfficesSessionsSaga() {
  const {
    data: { officesCount },
  } = yield call(SessionsService.getOfficeSessions);
  yield put(setOfficesSessions(officesCount));
}

function* fetchLeadersSaga({ payload }) {
  yield put(setIsLoadingLeaders(true));

  const {
    weeks,
    limit,
    page,
    listType,
    range,
    search,
    orderField,
    orderType,
    withMetric,
  } = payload;
  const service =
    listType === AGENTS
      ? AppraisalsService.leaderboard
      : AppraisalsService.officeLeaderboard;

  try {
    const { data } = yield call(
      service,
      ...[weeks, limit, page, range, search, orderField, orderType, withMetric],
    );
    yield put(setLeaders(data));
  } catch (e) {
    yield put(setLeaders({ error: e.message }));
  }
}

function* fetchRecentProposalsChartDataSaga() {
  try {
    const weeks = yield select(getWeeks);
    const range = yield select(getRange);
    const { data } = yield call(
      AppraisalsService.getDataForSentProposalGraph,
      ...[weeks, range],
    );
    if (data?.appraisals?.length && weeks) {
      let result = null;
      if (weeks === 1) {
        result = yield call(getOneWeek, data?.appraisals);
      }
      if (weeks > 1) {
        result = yield call(getByMonths, data?.appraisals, weeks);
      }
      yield put(setRecentProposalsChartData(result));
    } else {
      yield put(setRecentProposalsChartData(null));
    }
  } catch (e) {
    yield put(setRecentProposalsChartData(null));
  }
}

function* fetchProposalsWidgetDataSaga() {
  yield put(setIsLoadingProposalsWidget(true));
  try {
    yield all([
      call(fetchProposalStatistic, { filter: "sent_at", key: "sentProposals" }),
      call(fetchProposalStatistic, {
        status: "unopened",
        filter: "sent_at",
        key: "unopenedProposals",
      }),
      call(fetchClicksPercent),
    ]);
    const sent = yield select(getSentProposalsTotal);
    const prevSent = yield select(getPrevSentProposalsTotal);
    const unopened = yield select(getUnopenedProposalsTotal);
    const prevUnopened = yield select(getPrevUnopenedProposalsTotal);
    const clicks = yield select(getClicksPercent);
    const prevClicks = yield select(getClicksPercent);
    yield put(
      setProposalsWidgetData({
        sent,
        prevSent,
        unopened,
        prevUnopened,
        clicks,
        prevClicks,
      }),
    );
  } catch (e) {
    yield put(setIsLoadingProposalsWidget(false));
  }
}

function* fetchFooterWidgetsDataSaga() {
  yield put(setIsLoadingFooterWidgets(true));
  yield all([
    call(fetchArticlesSaga),
    call(fetchReadingTimeSaga),
    call(fetchAverageOpensSaga),
    call(fetchUsersSessionsSaga),
    call(fetchOfficesSessionsSaga),
  ]);
  const articles = yield select(getArticlesArray);
  const readingTime = yield select(getAverageReadingTime);
  const averageOpens = yield select(getAverageOpens);
  const usersSessions = yield select(getUsersSessions);
  const officesSessions = yield select(getOfficesSessions);
  yield put(
    setFooterWidgetsData({
      articles,
      readingTime,
      averageOpens,
      usersSessions,
      officesSessions,
    }),
  );
}

function* fetchProposalsByTypeDataSaga({ payload }) {
  yield put(setIsLoadingLeaderboardChart(true));
  const { weeks, range } = payload;
  try {
    const response = yield call(
      AppraisalsService.getSentProposalByType,
      ...[weeks, range],
    );
    const { currCount, prevCount, appraisals } = response.data || {};

    yield put(setProposalsByType(appraisals));
    yield put(setProposalsMetrics({ currCount, prevCount }));

    yield put(setProposalsByType(appraisals));
  } catch (e) {
    yield put(setProposalsByType({ error: e.message }));
  }
  yield put(setIsLoadingLeaderboardChart(false));
}

export default function* watchDashboard() {
  yield all([
    takeLatest(fetchLeaders.type, fetchLeadersSaga),
    takeLatest(
      fetchRecentProposalsChartData.type,
      fetchRecentProposalsChartDataSaga,
    ),
    takeLatest(fetchProposalsWidgetData.type, fetchProposalsWidgetDataSaga),
    takeLatest(fetchFooterWidgetsData.type, fetchFooterWidgetsDataSaga),
    takeLatest(fetchProposalsByTypeData.type, fetchProposalsByTypeDataSaga),
  ]);
}
