import React, { useContext } from "react";
import { AppraisalContext } from "app/modules/Appraisals/AppraisalContext";
import {
  TableCell,
  TableHead,
  TableRow,
} from "app/modules/Common/DraggableTable";

const TableHeaderComponent = ({ showAmountColumns, tabCellStyle }) => {
  const { preview } = useContext(AppraisalContext);
  return (
    <TableHead>
      <TableRow>
        <TableCell className="pl-0" />
        <TableCell
          className={tabCellStyle}
          hidden={preview && !showAmountColumns}
        >
          Price
        </TableCell>
        <TableCell hidden={preview} style={{ width: "60px" }} />
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderComponent;
