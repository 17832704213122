import React from "react";

import { getImageUrl } from "shared";
import SvgIcon from "app/components/ui/SvgIcon";

export const Avatar = ({ id, alt = "Avatar", width = 50 }) => {
  if (id) {
    return <img src={getImageUrl(id, width)} alt={alt} className="image" />;
  }

  return <SvgIcon iconName="avatar" />;
};
