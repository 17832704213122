import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { Toast } from "primereact/toast";

import GridLinksList from "app/components/GridLinksList";
import PaginationSelective from "app/components/PaginationSelective";
import Loader from "app/components/ui/Loader";
import { formatDateISO8601ToDdMmYy } from "shared/utils";

import { API_URL } from "../Auth/_redux/authCrud";
import SearchBox from "../SearchBox";
import useDebounce from "../../hooks/useDebounce";

const OfficeArticles = ({ id }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const debouncedSearchTerm = useDebounce(search, 500);

  const toast = useRef(null);
  const totalPages = useRef(null);

  const [office, setOffice] = useState(null);

  const getArticles = () => {
    const apiUrl =
      API_URL +
      `articles?office=${id}&search=${search}&page=${page}&filter=updated_at&sort=descending&limit=true`;
    setLoading(true);
    axios.get(apiUrl).then(resp => {
      const articles = get(resp.data, "articles", []).map(article => ({
        id: article._id,
        title: article.title,
        category: article.category,
        active: article.active,
        editable: article.editable,
        updated: formatDateISO8601ToDdMmYy(article.updated_at),
        updates: article.updates,
        timeStamp: Date.parse(article.updated_at),
      }));
      totalPages.current = resp.data.pages;
      setArticles(articles);
      setLoading(false);
    });
  };

  const getOffice = () => {
    const apiUrl = API_URL + `offices/${id}`;
    axios.get(apiUrl).then(resp => {
      const office = resp.data;
      setOffice(office);
    });
  };

  useEffect(() => {
    getOffice();
    getArticles();
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, debouncedSearchTerm, page]);

  const syncArticles = () => {
    const apiUrl = API_URL + `articles/sync?officeId=${id}`;
    setLoading(true);
    axios.get(apiUrl).then(resp => {
      let _office = Object.assign({}, office);
      _office.new_articles = false;
      setOffice(_office);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: resp.data.message,
        life: 3000,
      });
      setLoading(false);
    });
  };

  const columnsTitles = [
    { title: "Title", sortLabel: "title" },
    { title: "Category", className: "width-200 mr-20", sortLabel: "category" },
    { title: "Active", sortLabel: "active", className: "width-120" },
    { title: "Editable", sortLabel: "editable", className: "width-120" },
    {
      title: "Updated",
      sortLabel: "updated",
      className: "width-120",
      isDate: true,
    },
    { title: "Actions", sortLabel: "updates", className: "width-120" },
  ];

  const listData = articles.map(article => ({
    id: article.id,
    link: `/offices/${id}/articles/${article.id}`,
    mainData: [
      { content: article.title, sortLabel: "title" },
      {
        content: article.category,
        sortLabel: "category",
        className: "width-200 mr-20",
      },
      {
        content: article.active ? "Yes" : "No",
        className: article.active
          ? "green-color width-120"
          : "alert-color width-120",
        sortLabel: "active",
      },
      {
        content: article.editable ? "Yes" : "No",
        className: article.editable
          ? "green-color width-120"
          : "alert-color width-120",
        sortLabel: "editable",
      },
      {
        content: article.updated,
        sortLabel: "updated",
        timeStamp: article.timeStamp,
        className: "width-120",
      },
      {
        content: article.updates ? "Update available" : "",
        className: "width-120",
        sortLabel: "updates",
      },
    ],
  }));

  return (
    <>
      <Toast ref={toast} />
      <div className="card-header flex-nowrap px-5">
        <div className="card-title flex-column-auto">
          <h3 className="card-label font-weight-bolder text-dark">Articles</h3>
        </div>

        <div className="ml-5 my-3">
          {office && (
            <button
              onClick={() => syncArticles()}
              className="pull-right btn btn-primary w-120px h-100"
            >
              Get New Articles
            </button>
          )}
        </div>
      </div>
      <div className="m-5">
        <SearchBox onChangeSearch={val => setSearch(val)} />
      </div>
      <div className="card-body p-0 p-relative">
        {loading && <Loader />}
        <GridLinksList content={listData} columnsTitles={columnsTitles} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "82px",
          }}
        >
          <PaginationSelective
            page={page}
            setPage={setPage}
            totalPages={totalPages.current}
          />
        </div>
      </div>
    </>
  );
};

export default OfficeArticles;
