import React, { useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import ImageLibrary from "../Common/ImageLibrary"
import { Dialog } from "@material-ui/core";

const OfficeImages = ({ officeId }) => {
  const subheader = useSubheader();
  subheader.setTitle("Images");
  const [imagePath, setImagePath] = useState("")

  const onImageSelected = ({ thumbnailUrl }) => {
    setImagePath(thumbnailUrl)
  }

  const PreviewModal = ({ show, src, onClose }) => (
    <Dialog open={show} onClose={onClose} maxWidth="lg">
      <div style={{ height: '600px' }}>
        <img src={src} alt='Preview' width='100%' height='100%' style={{ objectFit: 'contain' }}/>
      </div>
    </Dialog>
  )

  return (
    <div className="card-body p-0">
      <ImageLibrary
        officeId={officeId}
        onImageSelected={onImageSelected}
        withMultipleUpload
      />
      <PreviewModal
        show={!!imagePath}
        src={imagePath}
        onClose={() => setImagePath('')}
      />
    </div>
  );
};

export default React.memo(OfficeImages)
