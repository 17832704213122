import React from "react";
import { useSelector } from "react-redux";
import { getComparablesEditDetails } from "redux/selectors/metricsSelector";

const ComparablesEditDetails = () => {
  const editData = useSelector(getComparablesEditDetails);
  const isRHChanged = editData.some(el => el.source === "RH");
  const isRPChanged = editData.some(el => el.source === "RP");

  return (
    <div className="unit">
      <div className="d-flex space-between">
        <h3 className="unit_header">Comparables</h3>
      </div>
      <div className="unit-table">
        <div className="unit-table_row unit-table_row__header horizontal-separator unit-table_row_comparables__header">
          <div className="unit_item-content">
            <span className="title">{"R&H Data Information Changed"}</span>
          </div>
          <div className="unit_item-content">
            <span className="title">{isRHChanged ? "Yes" : "N/A"}</span>
          </div>
        </div>
        {isRHChanged && (
          <div
            className="unit-table_row unit-table_row__header horizontal-separator comparable_item-row"
            style={{ paddingTop: "14px" }}
          >
            <span>User</span>
            <span>Date</span>
            <span>Address</span>
          </div>
        )}
        {isRHChanged &&
          editData.map(
            (item, index) =>
              item.source === "RH" && (
                <div
                  key={index}
                  className="unit-table_row horizontal-separator comparable_item-row"
                >
                  <div className="unit_item-content">
                    <span className="subtitle">{item.user}</span>
                  </div>
                  <div className="unit_item-content">
                    <span className="subtitle">{item.date}</span>
                  </div>
                  <div className="unit_item-content">
                    <span className="subtitle">{item.address}</span>
                  </div>
                </div>
              ),
          )}
        <div className="unit-table_row unit-table_row__header horizontal-separator unit-table_row_comparables__header">
          <div className="unit_item-content">
            <span className="title">{"RP Data Information Changed"}</span>
          </div>
          <div className="unit_item-content">
            <span className="title">{isRPChanged ? "Yes" : "N/A"}</span>
          </div>
        </div>
        {isRPChanged && (
          <div
            className="unit-table_row unit-table_row__header horizontal-separator comparable_item-row"
            style={{ paddingTop: "14px" }}
          >
            <span>User</span>
            <span>Date</span>
            <span>Address</span>
          </div>
        )}
        {isRPChanged &&
          editData.map(
            (item, index) =>
              item.source === "RP" && (
                <div
                  key={index}
                  className="unit-table_row horizontal-separator comparable_item-row"
                >
                  <div className="unit_item-content">
                    <span className="subtitle">{item.user}</span>
                  </div>
                  <div className="unit_item-content">
                    <span className="subtitle">{item.date}</span>
                  </div>
                  <div className="unit_item-content">
                    <span className="subtitle">{item.address}</span>
                  </div>
                </div>
              ),
          )}
      </div>
    </div>
  );
};

export default ComparablesEditDetails;
