import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  icon: {
    marginBottom: "17px",
  },
  title: {
    marginRight: "20px",
    fontSize: "32px",
    lineHeight: "36px",
    padding: "17px 0",
  },
  button: {
    padding: "12px 16px",
    height: "40px",
    fontWeight: "bold",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "16px",
  },
  messageText: {
    width: "100%",
    fontSize: "14px",
    textAlign: "left",
    paddingBottom: "14px",
    whiteSpace: "initial",
    fontWeight: "300" as "normal",
    lineHeight: "20px",
    color: "#707070",
  },
  senderBlock: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "11px",
  },
  senderTitle: {
    fontSize: "14px",
    lineHeight: "16px",
    color: " #000000",
  },
  unreadIcon: {
    background: "#FDB818",
    borderRadius: "50%",
    width: "8px",
    height: "8px",
    marginRight: "8px",
  },
});

interface ContentProps {
  data: any;
  onHandleMessage(value: any): void;
  isUnread: boolean;
  bodyClass?: string;
}

const MessageContent = ({
  data,
  onHandleMessage,
  isUnread,
  bodyClass,
}: ContentProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.senderBlock}>
        <div className="d-flex align-items-center">
          {isUnread && <div className={classes.unreadIcon}></div>}
          <div className={classes.senderTitle}>
            {data?.sender?.office || data?.sender?.name}
          </div>
        </div>
      </div>
      <div>
        <div
          className={`${classes.messageText} ${bodyClass}`}
          onClick={() => onHandleMessage(data)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: data?.content,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MessageContent;
