import React, { useEffect, useState } from "react";
import { Modal, ModalActions } from "app/components/Modal";
import { makeStyles } from "@material-ui/core";
import PhotoCard from "./PhotoCard";
import produce from "immer";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "600px",
    minHeight: "150px",
    overflow: "auto",
  },
}));

const PhotosModal = ({ show, onHide, onApply, photos }) => {
  const [photosList, setPhotosList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setPhotosList(photos);
    const alreadySelectedPhotos = photos.filter(photo => !photo.hidden);
    setSelectedList(alreadySelectedPhotos);
  }, [photos]);

  const isActive = id => {
    return selectedList.find(item => item.id === id);
  };

  const onSelectImage = image => {
    setSelectedList(currList => {
      const index = currList.findIndex(item => item.id === image?.id);
      if (index === -1) {
        return [...currList, image];
      } else {
        return produce(currList, draft => {
          draft.splice(index, 1);
        });
      }
    });
  };

  return (
    <Modal open={show} onClose={onHide} title="Select photos">
      <div className={classes.imageContainer}>
        {photosList.length === 0 && (
          <div className="m-auto">No photos exist</div>
        )}
        {photosList.map(image => (
          <PhotoCard
            key={image.id}
            url={image?.thumbnails?.thumb_1024}
            name={image.userFilename}
            active={isActive(image.id)}
            onClick={() => onSelectImage(image)}
          />
        ))}
      </div>
      <ModalActions
        onCancel={onHide}
        onSubmit={() => onApply(selectedList)}
        submitTitle="Apply"
      />
    </Modal>
  );
};

export default PhotosModal;
