import { ProposalTypes } from "app/interfaces";

export const getAppraisalTitle = appraisal => {
  switch (appraisal.meta.appraisalType) {
    case ProposalTypes.listingProposal:
      return appraisal.property.displayAddress;
    case ProposalTypes.marketUpdate:
      return `${appraisal.property.address?.suburb.name} ${appraisal.property.address?.suburb.postcode}, ${appraisal.property.address?.suburb.state.abbreviation}`;
    case ProposalTypes.agentProfiling:
      return appraisal.team.leaderName;
    case ProposalTypes.businessListingProposal:
      return appraisal.property.address?.street;
    case ProposalTypes.residentialInformationMemorandum:
      return appraisal.property.displayAddress;
    default:
      return appraisal.property.displayAddress;
  }
};

export const getAppraisalCountry = appraisal => {
  return appraisal.country === 'New Zealand'
}

export const getProposalListWidgetLabel = activeTab => {
  switch (activeTab) {
    case 0:
      return "Proposals sent";
    case 1:
      return "Drafts Created";
    case 2:
      return "Archived Proposals";
    default:
      return "Proposals sent";
  }
};
