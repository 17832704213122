import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { updateNextSteps } from "redux/reducers/appraisalReducer";
import { AppraisalContext } from "../../AppraisalContext";
import CloseIcon from "@material-ui/icons/Close";
import useTrackEvent from "../../../../hooks/useTrackEvent";
import { useCommercial } from "app/hooks";

const useStyles = makeStyles(theme => ({
  action: ({ isCommercial }) => ({
    background: isCommercial
      ? theme.palette.primary.main
      : "linear-gradient(90deg, #F69400 0%, #FFC500 100%)",
    borderRadius: "10px",
    margin: "auto",
    display: "flex",
    height: "60px",
    width: "400px",
    color: isCommercial ? "#000" : "#fff",
    fontSize: "18px",
    fontWeight: "600",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  overflow: {
    overflow: "initial",
    minWidth: "500px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const getHref = (value, type="link", subject = "", body = "") => {
  if (type === "email")
    return `mailto:${value}?subject=${subject}&body=${body}`;
  if (type === "link") return value;
  if (type === "phone") return `tel:${value}`;
};

const ActionDialog = ({ show, onClose, onSubmit, action }) => {
  const [type, setType] = useState("email");
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const { email, phone } = useSelector(
    ({ appraisal }) => appraisal.team,
    shallowEqual,
  );

  const flkHidden = useSelector(
    ({ appraisal }) => appraisal.nextSteps.flkHidden, shallowEqual,
  )

  const classes = useStyles();

  useEffect(() => {
    setType(action.type);
    setTitle(action.title);
    setValue(action.value);
    setSubject(action?.subject);
    setBody(action?.body);
  }, [action]);

  useEffect(() => {
    setValue(defaultValues[type]);
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const typeSelectItems = [
    { label: "Email address", value: "email" },
    { label: "Website URL", value: "link" },
    { label: "Phone number", value: "phone" },
  ];

  const defaultValues = {
    email,
    phone,
    link: "",
    subject: "",
    body: "",
  };

  const getTitle = () => {
    const index = typeSelectItems.findIndex(v => v.value === type);
    return typeSelectItems[index]?.label;
  };

  const onSubmitClick = () => {
    onSubmit({ title, type, value, subject, body });
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby={"next-step-action-dialog-title"}
      aria-describedby={"next-step-action-dialog-description"}
      classes={{ paper: classes.overflow }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.overflow }}>
        <Grid container spacing={4}>
          <Grid item sm={3} className="d-flex align-items-center">
            Button Label
          </Grid>
          <Grid item sm={9}>
            <InputText
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
          {flkHidden && (
           <>
            <Grid item sm={3} className="d-flex align-items-center">
              Select Type
            </Grid>
            <Grid item sm={9}>
              <Dropdown
                value={type}
                options={typeSelectItems}
                onChange={e => setType(e.value)}
                placeholder="Select a Type"
              />
            </Grid>
            <Grid item sm={3} className="d-flex align-items-center">
              {getTitle()}
            </Grid>
            <Grid item sm={9}>
              <InputText
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
              />
            </Grid>
            {type === "email" && (
              <>
                <Grid item sm={3} className="d-flex align-items-center">
                  Email Subject
                </Grid>
                <Grid item sm={9}>
                  <InputText
                    type="text"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                  />
                </Grid>
              </>
            )}
            {type === "email" && (
              <>
                <Grid item sm={3} className="d-flex align-items-center">
                  Email Body
                </Grid>
                <Grid item sm={9}>
                  <InputTextarea
                    rows={3}
                    cols={30}
                    value={body}
                    onChange={e => setBody(e.target.value)}
                  />
                </Grid>
              </>
            )}
          </> )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmitClick} color="primary" autoFocus>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NextStepAction = ({ className = "" }) => {
  const { preview } = useContext(AppraisalContext);
  const [showDialog, setShowDialog] = useState(false);
  const trackEvent = useTrackEvent();
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { isCommercial } = useCommercial();
  const action = useSelector(
    ({ appraisal }) => appraisal.nextSteps.action,
    shallowEqual,
  );

  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  const classes = useStyles({ isCommercial });

  const onActionClick = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onSubmitDialog = newAction => {
    dispatch(updateNextSteps({ action: newAction }));
    setShowDialog(false);
  };

  useEffect(() => {
    if (appraisalType) {
      setLoading(false);
    }
  }, [appraisalType, action]);

  const Action = () =>
    preview ? (
      <a
        href={getHref(action.value, action.type, action?.subject, action?.body)}
        className={classes.action}
        onClick={() => trackEvent("Request Agency Agreement", "")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {action.title}
      </a>
    ) : (
      <div onClick={onActionClick} className={classes.action}>
        {action.title}
      </div>
    );

  return (
    !loading && (
      <div className={className}>
        <Action />
        <ActionDialog
          show={showDialog}
          onClose={onCloseDialog}
          onSubmit={onSubmitDialog}
          action={action}
        />
      </div>
    )
  );
};

export default NextStepAction;
