import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'app/components/ui/Buttons/IconButton';

import './style.scss';

const PaginationConsecutive = ({
                      totalPages,
                      totalItems,
                      page,
                      setPage,
                      paginationStep,
                    }) => {
  const setNewPage = (plus) => {
    if (plus && (page < totalPages)) {
      setPage(prev => prev + 1);
    }
    if (!plus && page !== 1) {
      setPage(prev => prev - 1);
    }
  };

  const paginationTill = page * paginationStep;
  const from = paginationTill - (paginationStep - 1);
  const to = (totalItems > paginationTill) ? paginationTill : totalItems;

  return (
    <div className="pagination">
      <div className="pagination-info">
        {`${from} - ${to}`}&nbsp;of&nbsp;{totalItems}
      </div>
      <div className="pagination-buttons">
        <IconButton
          icon="chevronLeft"
          variant="round-btn-arrow-left"
          onClick={() => setNewPage(false)}
          disabled={page === 1}
        />
        <IconButton
          icon="chevronRight"
          variant="round-btn-arrow-right"
          onClick={() => setNewPage(true)}
          disabled={page === totalPages}
        />
      </div>
    </div>
  );
};

PaginationConsecutive.propTypes = {
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  paginationStep: PropTypes.number.isRequired,
};

export default PaginationConsecutive;
