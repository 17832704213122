import React from 'react';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { Link } from 'react-router-dom';

import '_metronic/_assets/sass/pages/login/classic/login-1.scss';

const Header = ({ title, subTitle }) => (
  <div className="text-center mb-10 mb-lg-10">
    <h3 className="font-size-h1">
      {title}
    </h3>
    <p className="text-muted font-weight-bold font-size-lg my-3">
      {subTitle}
    </p>
  </div>
)
const Footer = () => (
  <div
    className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
    <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
      &copy; 2021 Digikit
    </div>
    <div className="d-flex order-1 order-sm-2 my-2">
      <Link to="/terms" className="text-dark-75 text-hover-primary">
        Privacy
      </Link>
      <Link
        to="/terms"
        className="text-dark-75 text-hover-primary ml-4"
      >
        Legal
      </Link>
      <Link
        to="/terms"
        className="text-dark-75 text-hover-primary ml-4"
      >
        Contact
      </Link>
    </div>
  </div>
)

// const backgroundImage = toAbsoluteUrl('/media/bg/digikitplus-login-bg.jpeg')

const AuthLayout = ({ children, title, subTitle }) => (
  <div className="d-flex flex-column flex-root">
    {/*begin::Login*/}
    <div
      className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
    >
      <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/bg/digikitplus-login-bg.jpeg')})`,
        }}
      />
      {/*begin::Content*/}
      <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
          <div className="login-form login-signin" >
            <Header
              title={title}
              subTitle={subTitle}
            />
            {children}
          </div>
        </div>

        <Footer/>
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Login*/}
  </div>
);

export default AuthLayout;
