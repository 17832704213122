import { TableCell, TableFooter, TableRow } from "../DraggableTable";
import React, { useState } from "react";
import { Button as PrButton } from "primereact/components/button/Button";
import AddDialog from "./AddDialog";

const TableFooterCustom = ({ onAddNew }) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const onAdd = val => {
    setShowAddModal(false)
    onAddNew(val)
  }

  return (
    <>
      <TableFooter>
        <TableRow>
          <TableCell>
            <PrButton
              label="Add Item"
              icon="pi pi-plus"
              className="btn btn-primary mr-4"
              onClick={() => setShowAddModal(true)}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
      <AddDialog
        show={showAddModal}
        onClose={() => setShowAddModal(false)}
        onAdd={onAdd}
      />
    </>
  );
}

export default TableFooterCustom;
