export const AGENTS = 'agents';
export const OFFICES = 'offices';
export const DESCENDING = 'descending';
export const ASCENDING = 'ascending';

export const OFFICE_SPECIALITY = ["residential" , "commercial" , "business" ];

export const INFOGRAPHIC_FOLDER_NAME = "IM Infographics";
export const COMMERCIAL_INFOGRAPHIC_FOLDER_NAME = "Commercial IM Infographics";
export const RESIDENTIAL_INFOGRAPHIC_FOLDER_NAME = "Residential IM Infographics";
export const RURAL_INFOGRAPHIC_FOLDER_NAME = "Rural IM Infographics";
