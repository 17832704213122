import React from "react";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import cn from "classnames";

const useSelectStringStyles = makeStyles(() => ({
  button: {
    lineHeight: '17px',
    fontWeight: 600,
    fontSize: 14
  },
  select: {
    "& .MuiSelect-root ": {
      background: 'transparent',
      border: 'none',
      padding: '0 6px',
      lineHeight: '17px',
      fontWeight: 600,
      fontSize: 14
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 10px)",
    }
  },
}));

const SelectString = ({ title, value, onChange, options, className, width }) => {
  const classes = useSelectStringStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const menuItemStyle = {
    width,
    display: 'flex',
    justifyContent: 'center'
  }

  return (
    <div className={cn('d-flex', className)}>
      <div className={classes.button} onClick={handleOpen}>
        {title}
      </div>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value || ""}
        onChange={event => onChange(event.target.value)}
        disableUnderline
        className={classes.select}
        MenuProps={{
          anchorOrigin:{ vertical: 'bottom', horizontal: 'right' },
          transformOrigin:{ vertical: 'top', horizontal: 'right'  },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value=""/>
        {options.map( option => (
          <MenuItem value={option} key={option} style={menuItemStyle}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default SelectString;
