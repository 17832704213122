import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCurrentSessionData } from "redux/actions";
import { getIsCurrentSessionActionButtonClicked } from "redux/selectors/metricsSelector";
import SessionDataTable from "./SessionDataTable";
import IconButton from "app/components/ui/Buttons/IconButton";
import SvgIcon from "app/components/ui/SvgIcon";
import BigYellowButton from "app/components/ui/Buttons/BigYellowButton";
import cn from "classnames";

import "./styles.scss";

const SessionModal = ({ onClose, session }) => {
  const dispatch = useDispatch();
  const isActionButtonClicked = useSelector(
    getIsCurrentSessionActionButtonClicked,
  );
  const { appraisalId } = useParams();

  const sessionId = session.id;

  useEffect(() => {
    dispatch(fetchCurrentSessionData(sessionId));
  }, [dispatch, sessionId]);

  return (
    <div className="session-modal">
      <div className="session-modal_inner">
        <IconButton
          icon="closeRedBtn"
          onClick={onClose}
          className="close-button"
        />
        <div className="left-part">
          <div>
            <div className="block horizontal-separator">
              <h2 className="session_date">{session.date}</h2>
              <span className="session_id">{session.id}</span>
            </div>
            <div className="block horizontal-separator">
              <div className="session_duration">{session.duration}</div>
              <span className="block_item-title">Reading time</span>
            </div>
            <div className="block horizontal-separator">
              <div className="session_printed">
                <div
                  className={cn("session_printed-icon", {
                    not: !session.printed,
                  })}
                >
                  <SvgIcon
                    iconName={session.printed ? "checkMarkGreen" : "crossWhite"}
                  />
                </div>
                <span className="block_item-title">Printed this session</span>
              </div>
              <div className="session_clicks">
                <div className="session_clicks-icon">
                  {isActionButtonClicked ? (
                    <div className="green-tick-label">
                      <SvgIcon iconName="checkMarkGreen" />
                    </div>
                  ) : (
                    <div className="alert-label">
                      <SvgIcon iconName="crossWhite" />
                    </div>
                  )}
                </div>
                <div>
                  <span className="block_item-title">
                    Call to action button clicked?
                  </span>
                  <span className="block_item-subtitle">
                    {isActionButtonClicked ? "Yes" : "Not this session"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <BigYellowButton to={`/proposals/${appraisalId}/edit`}>
            Edit proposal
          </BigYellowButton>
        </div>
        <div className="right-part">
          <SessionDataTable />
        </div>
      </div>
    </div>
  );
};

export default SessionModal;
