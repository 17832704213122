import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "app/components/ui/Buttons/Button";
import SuccessIcon from "../../assets/images/success.svg";
import { Modal } from "../../components/Modal/index";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "32px",
    width: "488px",
  },
  closeButton: {
    padding: "12px 16px",
    width: "65px",
    height: "40px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FBFBFB",
    background: "#FDB818",
    borderRadius: "4px",
  },
  text: {
    fontSize: "32px",
    lineHeight: "36px",
    padding: "17px 72px 32px 0",
  },
  successIcon: {
    marginBottom: "17px",
  },
  closeModalButton: {
    top: "32px",
    right: "32px",
    border: "none",
  },
});

interface SuccessModalProps {
  show: boolean;
  onClose(): void;
}

const SuccessModal = ({ show, onClose }: SuccessModalProps) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={show}
        onClose={onClose}
        buttonClassname={classes.closeModalButton}
      >
        <div className={classes.container}>
          <div className={classes.successIcon}>
            <img src={SuccessIcon} alt="success"></img>
          </div>
          <div className={classes.text}>
            Your update has been published successfully!
          </div>
          <div>
            <Button className={classes.closeButton} onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessModal;
