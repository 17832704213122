import { ChonkyActions, defineFileAction } from "chonky";

export const cropImageAction = defineFileAction({
  id: 'crop_image',
  requiresSelection: true,
  fileFilter: file => file && !file.isDir,
  button: {
    name: 'Crop image',
    toolbar: true,
    group: 'Actions',
    contextMenu: true,
  },
})

export const renameFolderAction = defineFileAction({
  id: 'rename_folder',
  requiresSelection: true,
  fileFilter: file => file && file.isDir && file.name !== 'Available Shared Folders',
  button: {
    name: 'Rename Folder',
    toolbar: true,
    group: 'Actions',
    contextMenu: true,
  },
})

export const uploadLocalFile = defineFileAction({
  id: 'upload_local_file',
  requiresSelection: false,
  button: {
    name: 'Upload File',
    toolbar: true,
    contextMenu: false,
    icon: 'upload'
  },
})

export const actionsList = [
  ChonkyActions.CreateFolder,
  ChonkyActions.DeleteFiles,
  cropImageAction,
]
