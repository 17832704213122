/* eslint-disable no-mixed-operators */
import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  useMediaQuery,
  TextareaAutosize,
} from "@material-ui/core";
import { Box, withStyles } from "@material-ui/core";
import produce from "immer";
import nanoid from "nanoid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { ComparableModal } from "./ComparableModal";
import { ComparableManuallyModal } from "./ComparableManuallyModal";
import EditorComponent from "../../Common/EditorComponent";
import PropertyCard from "app/components/PropertyCard";
import BlockContainer from "../AppraisalDetails/BlockContainer";
import HideContainer from "../components/HideContainer";
import {
  updateComparables,
  updateProperty,
  updateArticleBody,
} from "../../../../redux/reducers";
import { AppraisalContext } from "../AppraisalContext";
import { getPrice, getLandSize } from "../CreateAppraisal/utils";
import { Tab, Tabs } from "../../Common/CustomizedTabs";
import { Title } from "../AppraisalDetails/BlockContainer";
import useTrackEvent from "../../../hooks/useTrackEvent";
import useArticles from "../../../hooks/useArticles";
import NoItems from "./PropertieList/NoItems";
import comparablesIcon from "app/assets/images/comparable-empty-icon.svg";
import { reorder } from "../CreateAppraisal/utils";

import DarkButton from "app/components/ui/DarkButton";
// import EditModal from "./EditModal";
import EditModal from "./EditPropertyModal";
import { useCommercial } from "app/hooks";
import { useBusinessListing } from "app/hooks";
import { ProposalTypes } from "app/interfaces";
import moment from "moment";
import {RadioButton} from "primereact/radiobutton";
import {useNz} from "../../../hooks/useNz";

const DescriptionText = withStyles({
  root: {
    backgroundColor: "#fcf8e3",
    display: "flex",
    textAlign: "center",
    padding: 10,
    fontSize: "15px",
    lineHeight: "21px",
    color: "#333",
    marginBottom: 15,
    justifyContent: "center",
  },
})(Box);

const useStyles = makeStyles(theme => ({
  estimationText: {
    fontWeight: 800,
    marginTop: "50px",
    fontSize: "15px",
    textAlign: "center",
  },
  estimation: ({ estimation, isRental, isCommercial, appraisalType }) => ({
    fontSize: "2em",
    fontWeight: "500",
    lineHeight: "2.5em",
    border: "none",
    color: isCommercial ? "#ffd200" : "#FFC13D",
    background: "white",
    letterSpacing: "2px",
    textAlign: "center",
    padding: "0 20px",
    paddingRight:
      isRental && appraisalType !== ProposalTypes.commercialListingProposal
        ? 0
        : 20,
    width: estimation?.length * 18 + "px",
    minWidth: "200px",
    outline: "none",
    "&:disabled": {
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  estimationContainer: ({ isCommercial }) => ({
    border: `2px solid ${isCommercial ? "#ffd200" : "#FFC13D"}`,
    display: "flex",
    alignItems: "center",
    color: isCommercial ? "#ffd200" : "#FFB300",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& input": {
        fontSize: "1.5em",
      },
    },
  }),
  estimationPostfix: ({ isCommercial }) => ({
    fontSize: "2em",
    fontWeight: "500",
    lineHeight: "2.5em",
    color: isCommercial ? "#ffd200" : "#FFC13D",
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
      lineHeight: "1.5em",
      paddingRight: 20,
      width: 140,
    },
  }),
  addEditSection: {
    backgroundColor: "#fcf8e3",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    color: "rgba(1, 1, 1, 0.7)",
    marginBottom: "30px",
    "& button": {
      width: "200px",
      backgroundColor: "#fff",
      fontWeight: "800",
    },
  },
  addEditSectionText: {
    flex: 1,
    fontSize: "15px",
    lineHeight: "21px",
    color: "#333",
  },
  textAreaBase: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    "&:disabled, &:focus": {
      opacity: 1 + " !important",
    },
    "&:hover": {
      opacity: 0.6,
    },
    color: "#333",
    fontSize: "15px",
    lineHeight: "21px",
    textAlign: "center",
  },
  noItems: {
    border: "2px dashed",
    borderColor: theme.palette.border.gray,
    height: 350,
    position: "relative",
    "&>div:first-child": {
      top: "40%",
    },
  },
  tabTitle: {
    fontWeight: 600,
    fontSize: 15,
  },
  dialogButtonAbsolute: {
    position: "absolute",
    bottom: 62,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "space-between",
    width: "300px",
  },
}));

const Comparable = ({ withoutSubTitle, noPriceOpinion }) => {
  const { isCommercial } = useCommercial();
  const { isBusinessListing } = useBusinessListing();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [openManualBlock, setOpenManualBlock] = useState(false);
  const [openEditInformation, setOpenEditInformation] = useState(undefined);
  const { isNz } = useNz();
  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);
  const userName = currentUser.firstname + ' ' + currentUser.lastname;

  const comparables = useSelector(
    ({ appraisal }) => appraisal.comparables,
    shallowEqual,
  );

  const property = useSelector(
    ({ appraisal }) => appraisal.property,
    shallowEqual,
  );

  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  let visited;
  if (!(isNz && (appraisalType === 'listingProposal' || appraisalType === 'preAppraisal'))) {
    visited = true;
  } else {
    visited = property.visited || false;
  }

  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );
  const classification = useSelector(
    ({ appraisal }) => appraisal.meta.classification,
    shallowEqual,
  );
  const appraisalDate = useSelector(
    ({ appraisal }) => appraisal.created_at,
    shallowEqual,
  );

  const today = new Date();

  const opinionArticle = useArticles({ opinion: true }).opinion?.[0];

  const { preview } = useContext(AppraisalContext);

  const isRental = category !== "sales";
  const trackEvent = useTrackEvent();
  const id = nanoid();

  const {
    raineandhorne: selectedRhProperties,
    on: selectedOnMarketProperties,
    off: selectedMarketProperties,
    rhleased: selectedRhleasedProperties,
    opinionTitle,
  } = comparables;

  const [estimation, setEstimation] = useState("$000,000");

  const openDialog = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const openManualDialog = () => setOpenManualBlock(true);
  const closeManualModal = () => setOpenManualBlock(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (preview) {
      if (!selectedRhProperties?.length) {
        selectedRhleasedProperties?.length
          ? setValue(1)
          : setValue(selectedMarketProperties?.length ? 2 : 3);
      } else {
        setValue(0);
      }
    }
  }, [
    selectedRhProperties,
    selectedOnMarketProperties,
    selectedMarketProperties,
    selectedRhleasedProperties,
    preview,
  ]);

  useEffect(() => {
    if (property.agentPriceOpinion === "$000,000 - $000,000" && isRental) {
      setEstimation("$000 - $000");
    } else {
      setEstimation(property.agentPriceOpinion);
    }
  }, [property.agentPriceOpinion, isRental]);

  useEffect(() => {
    if (!visited) {
      dispatch(updateComparables({ sectionHidden: true }));
    }
  }, [visited])

  const onPriceOpinionChange = e => {
    setEstimation(e.target.value);
  };

  const onVisitedUpdate = e => {
    dispatch(updateProperty({visited: e.target.value, visitedChanged: new Date(), visitedBy: userName}))
    if (!e.target.value) {
      dispatch(updateComparables({ sectionHidden: true }));
    } else {
      dispatch(updateComparables({ sectionHidden: false }));
    }
  }

  const updateOpinionPrice = () => {
    dispatch(
      updateProperty({
        agentPriceOpinion: estimation,
        // appraisalType === ProposalTypes.commercialListingProposal &&
        // category === "lease"
        //   ? estimation
        //   : convertPriceString(estimation),
      }),
    );
  };

  const updatePropertyField = (
    filed,
    val,
    index,
    propertyList,
    updateFunction,
  ) => {
    let newPropertyList = produce(propertyList, draft => {
      draft[index][filed] = val;
    });
    updateFunction(newPropertyList);
  };

  const dispatch = useDispatch();

  const onDragEnd = result => {
    if (!result.destination) return;

    const newList = reorder(
      activeListItem.items,
      result.source.index,
      result.destination.index,
    );

    activeListItem.updateFunction(newList);
  };

  const setRHProperties = items => {
    dispatch(updateComparables({ raineandhorne: items }));
  };

  const setRHLeasedProperties = items => {
    dispatch(updateComparables({ rhleased: items }));
  };

  const setMProperties = items => {
    dispatch(updateComparables({ off: items }));
  };

  const setOMProperties = items => {
    dispatch(updateComparables({ on: items }));
  };

  const hideSectionHandler = val => {
    dispatch(updateComparables({ sectionHidden: val }));
  };

  const onTitleUpdate = title => {
    dispatch(updateComparables({ sectionTitle: title }));
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateComparables({ sectionSubtitle: subtitle }));
  };

  const onComparableTitleUpdate = comparableTitle => {
    dispatch(updateComparables({ comparableTitle: comparableTitle }));
  };

  const onUpdateArticleBody = (id, body) => {
    if (!id) return;
    dispatch(updateArticleBody({ id, body }));
  };

  const updateOpinionTitle = ({ target }) => {
    dispatch(updateComparables({ opinionTitle: target.value }));
  };

  const deleteProperty = (index, items) => {
    const newItems = produce(items, draft => {
      draft.splice(index, 1);
    });
    activeListItem.updateFunction(newItems);
  };

  const openEditModal = (index, items) => {
    setOpenEditInformation({
      data: items[index],
      index,
    });
  };

  const classes = useStyles({
    estimation,
    isRental,
    isCommercial,
    appraisalType,
  });

  const setNewComparableData = (newData, index) => {
    let newPropertyList = produce(activeListItem.items, draft => {
      draft[index] = newData;
    });
    setOpenEditInformation(undefined);
    activeListItem.updateFunction(newPropertyList);
  };

  const ComparableItems = ({ items, updateFunction }) => {
    if (!items?.length && preview) return "";
    if (!items?.length)
      return (
        <div className={classes.addEditSection}>
          No properties have been selected. Please apply some from the add
          button above. If you choose not to show any within a tab, don’t select
          any and those tabs will hide on your finished Proposal
        </div>
      );

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={`${id} droppableComparable`}
          direction="horizontal"
        >
          {provided => (
            <Grid
              container
              spacing={3}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((prop, index) => {
                const renderPrice = /^\d+(\.[0-9]+)*$/.test(prop.price)
                  ? getPrice(prop.price)
                  : prop.price;
                const renderRatePerSqm = /^\d+(\.[0-9]+)*$/.test(
                  prop.ratePerSqm,
                )
                  ? getPrice(prop.ratePerSqm)
                  : prop.ratePerSqm;
                const daysOnMarket =
                  prop.daysOnMarket && prop.saleDate
                    ? prop.daysOnMarket
                    : prop.daysOnMarket +
                      moment(today).diff(appraisalDate, "days");

                return (
                  <Draggable
                    key={`${index} ${id}`}
                    draggableId={`${index} ${id}`}
                    index={index}
                  >
                    {provided => (
                      <Grid
                        key={prop.id || index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <PropertyCard
                          bed={prop.bedrooms}
                          bath={prop.bathrooms}
                          cars={prop.cars}
                          landArea={getLandSize(prop.landArea)}
                          image={prop.image}
                          underImageIcons
                          showEditButton={!!prop.id}
                          descriptionFields={[
                            prop.address,
                            prop.buildingArea &&
                              "Building Area: " + prop.buildingArea + " sqm",
                            prop.manually && prop.isCommercial
                              ? `${
                                  category === "lease" ? "" : "Sale "
                                }Price: ${renderPrice}`
                              : renderPrice,
                            prop.ratePerSqm &&
                              "Rate per sqm : " + renderRatePerSqm,
                            prop.saleDate &&
                              `${
                                category === "lease" ? "Lease" : "Sale"
                              } date: ` + prop.saleDate,
                            prop.daysOnMarket &&
                              "Days on Market (DOM): " + daysOnMarket,
                            getLandSize(prop.floorArea) &&
                              "Floor Area: " + getLandSize(prop.floorArea),
                            prop.yearBuilt && "Year Built: " + prop.yearBuilt,
                          ]}
                          description={prop.description}
                          onDescriptionChange={val =>
                            updatePropertyField(
                              "description",
                              val,
                              index,
                              items,
                              updateFunction,
                            )
                          }
                          onDelete={() => deleteProperty(index, items)}
                          onEdit={() => openEditModal(index, items)}
                        />
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const rhLabel = `${isMobile ? "R&H" : "Raine & Horne"} ${
    category === "sales" ? "Sales" : "For Lease"
  }`;
  const offLabel = "Market Sales";
  const onLabel = "On the Market";
  const rhleasedLabel = isMobile ? "R&H Leased" : "Raine & Horne Leased";

  const listOfItems = [
    {
      items: selectedRhProperties,
      updateFunction: setRHProperties,
      label: rhLabel,
    },
    {
      items: selectedRhleasedProperties,
      updateFunction: setRHLeasedProperties,
      label: rhleasedLabel,
    },
    {
      items: selectedMarketProperties,
      updateFunction: setMProperties,
      label: onLabel,
    },
    {
      items: selectedOnMarketProperties,
      updateFunction: setOMProperties,
      label: offLabel,
    },
  ];
  const activeListItem = listOfItems[value];
  const activeTab = activeListItem?.label;

  return (
    <>
      {!preview ||
      (preview &&
        ((!comparables.comparablesHidden &&
          (selectedRhProperties.length !== 0 ||
            selectedRhleasedProperties?.length !== 0 ||
            selectedMarketProperties.length !== 0 ||
            selectedOnMarketProperties.length !== 0)) ||
          !property.agentPriceOpinionHidden)) ? (
        <>
          {!preview && isNz && (appraisalType === 'listingProposal' || appraisalType === 'preAppraisal') && (
          <div className="d-flex justify-content-center flex-column">
            <div className="d-flex align-items-center p-3 mt-5 visited-text gap-3">
              <div className="mr-5">Have you visited and inspected this property?</div>
              <div className="d-flex align-items-center">
                <RadioButton inputId="visited1" name="visited" value={true} onChange={(e) => onVisitedUpdate(e)} checked={visited} />
                <label htmlFor="visited1" className="ml-2 mb-0">Yes</label>
              </div>
              <div className="d-flex align-items-center">
                <RadioButton inputId="visited2" name="visited" value={false} onChange={(e) => onVisitedUpdate(e)} checked={visited === false} />
                <label htmlFor="visited2" className="ml-2 mb-0">No</label>
              </div>
            </div>
            <p className='w-100'>
              * Prior to including a CMA and or price estimate in this proposal you must have inspected the property to be compliant with NZ regulations.
            </p>
          </div> )}
        <HideContainer
          value={comparables.sectionHidden}
          onChange={hideSectionHandler}
          disabled={!visited}
        >
          <BlockContainer
            defaultTitle={comparables.sectionTitle}
            defaultSubtitle={comparables.sectionSubtitle}
            withoutSubTitle={withoutSubTitle}
            id="price-estimation"
            onTitleUpdate={onTitleUpdate}
            onSubtitleUpdate={onSubtitleUpdate}
            hide={comparables.sectionHidden}
          >
            <ComparableModal
              show={open}
              onHide={closeModal}
              property={property}
              classification={classification}
              activeTabIndex={value}
            />
            <ComparableManuallyModal
              show={openManualBlock}
              onHide={closeManualModal}
              activeTabIndex={value}
              isCommercial={isCommercial}
            />
            <EditModal
              show={!!openEditInformation}
              onClose={() => setOpenEditInformation(undefined)}
              onSubmit={setNewComparableData}
              info={openEditInformation}
              type={activeTab}
              isCommercial={isCommercial}
            />
            {/* TODO: FIX */}
            {(!preview ||
              (preview && selectedRhProperties.length !== 0) ||
              selectedRhleasedProperties?.length !== 0 ||
              selectedMarketProperties.length !== 0 ||
              selectedOnMarketProperties.length !== 0) && (
              <HideContainer
                title="Hide Sub Section"
                value={comparables.comparablesHidden}
                onChange={val =>
                  dispatch(updateComparables({ comparablesHidden: val }))
                }
              >
                {appraisalType === "listingProposal" ? (
                  <Title
                    value={comparables.comparableTitle}
                    onBlur={onComparableTitleUpdate}
                    className="common-subTitle"
                    maxLength={30}
                  />
                ) : (
                  ""
                )}
                {comparables.comparablesHidden ? (
                  ""
                ) : (
                  <>
                    <div>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className="mb-5"
                        variant="fullWidth"
                        scrollButtons={isMobile ? "on" : "auto"}
                        centered
                      >
                        {!selectedRhProperties.length && preview ? (
                          ""
                        ) : (
                          <Tab
                            value={0}
                            label={rhLabel}
                            onClick={() =>
                              trackEvent(
                                "Comparables tab click",
                                "Raine & Horne Sales",
                              )
                            }
                          />
                        )}
                        {(!selectedRhleasedProperties?.length && preview) ||
                        category !== "lease" ||
                        isBusinessListing ? (
                          ""
                        ) : (
                          <Tab
                            value={1}
                            label={rhleasedLabel}
                            onClick={() =>
                              trackEvent(
                                "Comparables tab click",
                                "Raine & Horne Leased",
                              )
                            }
                          />
                        )}
                        {(!selectedMarketProperties.length && preview) ||
                        category !== "sales" ||
                        isBusinessListing ? (
                          ""
                        ) : (
                          <Tab
                            value={2}
                            label={offLabel}
                            onClick={() =>
                              trackEvent(
                                "Comparables tab click",
                                "Market Sales",
                              )
                            }
                          />
                        )}
                        {(!selectedOnMarketProperties.length && preview) ||
                        isBusinessListing || (category === "lease" && isNz) ? (
                          ""
                        ) : (
                          <Tab
                            value={3}
                            label={onLabel}
                            onClick={() =>
                              trackEvent(
                                "Comparables tab click",
                                "On the Market",
                              )
                            }
                          />
                        )}
                        )
                      </Tabs>
                      {!activeListItem.items.length && !preview ? (
                        <div className={classes.noItems}>
                          <NoItems
                            src={comparablesIcon}
                            text={
                              <>
                                <p>
                                  You currently have no comparable{" "}
                                  {isRental ? "leases" : "sales"} selected.
                                </p>
                                <p>
                                  {" "}
                                  Click Add below to launch our comparable{" "}
                                  {isRental ? "leases" : "sales"} finder tool.
                                </p>
                              </>
                            }
                          />
                          <div className={classes.dialogButtonAbsolute}>
                            <DarkButton onClick={openDialog}>
                              {"Search & Add"}
                            </DarkButton>
                            <DarkButton onClick={openManualDialog}>
                              Manually Add
                            </DarkButton>
                          </div>
                        </div>
                      ) : (
                        <>
                          {!preview && (
                            <div>
                              <div className="d-flex justify-content-center mb-5">
                                <DarkButton
                                  onClick={openDialog}
                                  style={{ marginRight: "10px" }}
                                >
                                  {"Search & Add"}
                                </DarkButton>
                                <DarkButton onClick={openManualDialog}>
                                  Manually Add
                                </DarkButton>
                              </div>
                              <DescriptionText>
                                Click, hold and drag and drop the property cards
                                to reorder
                              </DescriptionText>
                            </div>
                          )}
                          <ComparableItems
                            items={activeListItem.items}
                            updateFunction={activeListItem.updateFunction}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </HideContainer>
            )}
            <HideContainer
              title="Hide Sub Section"
              value={property.agentPriceOpinionHidden}
              onChange={val =>
                dispatch(updateProperty({ agentPriceOpinionHidden: val }))
              }
              removed={noPriceOpinion}
            >
              <TextareaAutosize
                defaultValue={opinionTitle}
                disabled={preview}
                onBlur={updateOpinionTitle}
                className={classes.textAreaBase + " " + classes.estimationText}
              />
              <div className="d-flex justify-content-center my-10 position-relative">
                <div className={classes.estimationContainer}>
                  <input
                    disabled={preview}
                    className={classes.estimation}
                    value={estimation}
                    onChange={onPriceOpinionChange}
                    onBlur={updateOpinionPrice}
                  />
                  {category !== "sales" &&
                    appraisalType !==
                      ProposalTypes.commercialListingProposal && (
                      <div className={classes.estimationPostfix}>per week</div>
                    )}
                </div>
              </div>
              <EditorComponent
                inline
                value={opinionArticle?.body}
                onBlur={value =>
                  onUpdateArticleBody(opinionArticle?._id, value)
                }
                type={"coverletter"}
                disabled={preview}
                charactersLimit={1100}
              />
            </HideContainer>
          </BlockContainer>
        </HideContainer>
      </>
      ) : (
        ""
      )}
    </>
  );
};

export default Comparable;
