import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getWeeks,
  getRecentProposalsChartData,
  getRange,
} from "redux/selectors/dashboardSelector";
import { fetchRecentProposalsChartData } from "redux/actions";
import { setRecentProposalsChartData } from "redux/reducers/dashboardReducer";
import { Chart } from "chart.js";

import "./styles.scss";

const ProposalChart = () => {
  const dispatch = useDispatch();
  const canvas = useRef();
  const tooltip = useRef();

  const weeks = useSelector(getWeeks);
  const range = useSelector(getRange);
  const data = useSelector(getRecentProposalsChartData);

  useEffect(() => {
    dispatch(fetchRecentProposalsChartData());
    return () => dispatch(setRecentProposalsChartData(null));
  }, [weeks, dispatch, range]);

  const hideTooltip = () => {
    tooltip.current.style.opacity = "";
  };

  useEffect(() => {
    let chart;
    if (data) {
      const ctx = canvas.current.getContext("2d");
      chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: data.labels,
          datasets: [
            {
              data: data.recent,
              borderColor: "rgb(253, 184, 24)",
              fill: {
                target: "origin",
                above: "rgba(253, 184, 24, 0.03)",
              },
            },
            {
              data: data.unopened,
              borderColor: "rgb(224, 75, 75)",
              fill: {
                target: "origin",
                above: "rgba(224, 75, 75, 0.03)",
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          pointRadius: 0,
          tension: 0.3,
          scales: {
            y: {
              suggestedMin: 1,
              suggestedMax: 5,
              grid: {
                display: false,
              },
            },
          },
          hover: {
            mode: "nearest",
            intersect: false,
            axis: "x",
          },
          elements: {
            point: {
              hoverRadius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "nearest",
              intersect: false,
              axis: "x",
              enabled: false,
              external: ({ tooltip: context }) => {
                const tooltipHeight = tooltip.current.offsetHeight;
                const tooltipWidth = tooltip.current.offsetWidth;
                const sentElement = tooltip.current.firstChild;
                const unopenedElement = tooltip.current.lastChild;
                const sentTitle = sentElement.lastChild;
                const unopenedTitle = unopenedElement.lastChild;
                if (context.opacity) {
                  sentTitle.textContent = `${context.dataPoints[0].formattedValue} Sent`;
                  sentElement.firstChild.style.backgroundColor =
                    context.dataPoints[0].dataset.borderColor;
                  unopenedTitle.textContent = `${context.dataPoints[1].formattedValue} Unopened`;
                  unopenedElement.firstChild.style.backgroundColor =
                    context.dataPoints[1].dataset.borderColor;
                  unopenedElement.lastChild.style.color =
                    context.dataPoints[1].dataset.borderColor;
                  tooltip.current.style.cssText = `
                    top: ${context.caretY - tooltipHeight - 16}px;
                    left: ${context.caretX - tooltipWidth / 2}px;
                    opacity: 1;
                  `;
                }
              },
            },
          },
        },
      });
    }
    return () => chart && chart.destroy();
  }, [data]);

  return (
    <div className="layout-side-padding">
      <div className="proposals-chart-wrapper">
        <div className="proposals-chart" onMouseLeave={hideTooltip}>
          <canvas ref={canvas} />
          <div ref={tooltip} className="proposals-tooltip">
            <div className="tooltip-label">
              <div className="mark" />
              <div />
            </div>
            <div className="tooltip-label">
              <div className="mark" />
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalChart;
