import { createSelector } from "reselect";
import { CreateProposalState } from "./reduxSlice";

const selectSelf = (state: any): CreateProposalState => state.createProposal;
export const activeStepSelector = createSelector(selectSelf, state => state.step);
export const propertyTypeSelector = createSelector(selectSelf, state => state.type);
export const officeSpecialitySelector = createSelector(selectSelf, state => state.office?.speciality || "residential" );
export const officeSelector = createSelector(selectSelf, state => state.office);
export const teamSelector = createSelector(selectSelf, state => state.profile);
export const teamLeaderIdSelector = createSelector(selectSelf, state => state.profile?.leader[0]?._id);


