import { put, takeLatest, call } from 'redux-saga/effects';
import { persistReducer } from 'redux-persist';
import UsersService from 'app/api/UsersService';
import storage from 'redux-persist/lib/storage';
import { persistor } from 'redux/store';
import moment from 'moment';
import { setupAxiosWithToken } from "../../../../redux";
import { setIsLoadingHeader } from "redux/reducers/dashboardReducer";

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  ResetStore: '[Reset Store] Action',
  FetchUser: '[Fetch User] Action',
  SetUser: '[Set User] Action',
  SetToken: '[Set Token] Action',
  UserInitialization: '[User Initialization] Action',
  SetExpirationTime: '[Set Expiration Time] Action',
  setAuthList: '[Set Auth List] Action',
  switchOffice: '[Switch office] Action',
  ChangeUser: '[Change User] Action',
};

const initialAuthState = {
  authList: [],
  user: {},
  authToken: undefined,
  expirationTime: undefined
};

export const reducer = persistReducer(
  { storage, key: "digikitplus-auth" },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken, user: undefined, expirationTime: undefined };
      }

      case actionTypes.ResetStore: {
        return initialAuthState;
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetToken: {
        const { token } = action.payload;
        return { ...state, authToken: token, expirationTime: moment().endOf('day') };
      }

      case actionTypes.SetExpirationTime: {
        return { ...state, expirationTime: moment().endOf('day') };
      }

      case actionTypes.setAuthList: {
        return { ...state, authList: action.payload};
      }

      default:
        return state;
    }
  }
);

export const actions = {
  logout: () => ({ type: actionTypes.Logout }),
  resetStore: () => ({ type: actionTypes.ResetStore }),
  fetchUser: () => ({ type: actionTypes.FetchUser }),
  setUser: user => ({ type: actionTypes.SetUser, payload: { user } }),
  setToken: token => ({ type: actionTypes.SetToken, payload: { token } }),
  setExpirationTime: expirationTime => ({ type: actionTypes.SetExpirationTime, payload: { expirationTime } }),
  initUser: data => ({ type: actionTypes.UserInitialization, payload: data }),
  setAuthList: data => ({ type: actionTypes.setAuthList, payload: data }),
  switchOffice: data => ({ type: actionTypes.switchOffice, payload: data }),
  changeUser: data => ({ type: actionTypes.ChangeUser, payload: data }),
};

export function* saga() {
  yield takeLatest(actionTypes.FetchUser, function* fetchUserSaga() {
    const { data } = yield call(UsersService.getMe);
    yield put(actions.setUser(data));
  });

  yield takeLatest(actionTypes.ChangeUser, function* changeUserSaga() {
    yield put(setIsLoadingHeader(false));
    const { data } = yield call(UsersService.getMe);
    yield put(actions.setUser(data));
    yield put(setIsLoadingHeader(true));
  });

  yield takeLatest(actionTypes.Logout, function* sagaLogOut() {
    yield put(actions.resetStore());
    yield persistor.purge();
  });

  yield takeLatest(actionTypes.UserInitialization, function* userInit({ payload }) {
    yield put(actions.setAuthList(payload.authList))
    yield put(actions.setUser(payload.user));
    yield put(actions.setToken(payload.token));
    yield put(actions.setExpirationTime({ expirationTime: moment().endOf('hour') }));
    yield persistor.flush();
  })

  yield takeLatest(actionTypes.SetToken, function* ({ payload }) {
    setupAxiosWithToken(payload.token)
    yield persistor.flush();
  });

  yield takeLatest(actionTypes.switchOffice, function* ({ payload }) {
    yield put(actions.setUser(payload.user));
    yield put(actions.setToken(payload.token));
  })
}
