import React from 'react'
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import cn from "classnames";
import bedImage from "../../assets/images/bed.svg";
import bathImage from "../../assets/images/bath.svg";
import sedanImage from "../../assets/images/sedan.svg";
import { makeStyles } from "@material-ui/core";
import measuring from "../../assets/images/measuring.svg";

const ValueWithIcon = ({ value, imgSrc, land }) => {
  if (!value) return "";
  return (
    <div className="d-flex">
      <SVG src={imgSrc} />
      <span className={land && "mr-0"}>{value}</span>
    </div>
  );
};


const MapWithProperty = ({ lat, lng, zoom = 16 }) => {
  const useStyles = makeStyles( theme => ({
    mapContainer: {
      "& .gm-style-iw-t::after": {
        top: "-50px"
      },
      "& .gm-style-iw-d": {
        display: "inline"
      },
      "& .gm-style-iw": {
        top: "-50px",
        padding: "0 !important"
      },
      "& .gm-style-iw button": {
        display: "none !important",
      },
      "& button.gm-fullscreen-control": {
        display: "none !important", // removing full screen
      },
      "& .gm-style-mtc": {
        display: "none !important", // removing select type of map
      },
      "& .gm-svpc": {
        display: "none !important", // removing street view
      },

    },
    container: {
      padding: "10px 3px",
      textAlign: "center",
      "& img": {
        height: "160px",
      }
    },
    iconsLayer: {
      color: theme.palette.text.secondary,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: "10px",
      "& svg": {
        height: "20px",
        width: "20px",
        fill: theme.palette.text.secondary,
      },
      "& span": {
        lineHeight: "20px",
        fontSize: "12px",
        marginLeft: "7px",
        marginRight: "15px",
      },
    }
  }))
  const property = useSelector(state => state.appraisal.property, shallowEqual);
  const classes = useStyles();
  const offices = property.address?.suburb.offices || [];
  
  if (!lat || !lng) return (' ')
  return (
    <div className={classes.mapContainer}>
      <GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        center={{ lat: + lat + 0.002, lng }}
        zoom={zoom}
        options={{gestureHandling: 'cooperative'}}
      >
        {offices?.length ? (
          offices.map(office => {
            const latOffice = office.location.coordinates[1]
            const lngOffice = office.location.coordinates[0]
            return (
              <React.Fragment>
                <Marker key={`${latOffice}_${lngOffice}`}position={{ lat: latOffice, lng: lngOffice }} />)
                <InfoWindow position={{ lat:latOffice , lng: lngOffice }}>
                  <div className={classes.container}>
                    <div className={cn(classes.iconsLayer, {'mt-0': !property.about.image})}>
                      {office.address}
                    </div>
                  </div>
                </InfoWindow>
              </React.Fragment>
            );
          })
        ) : <Marker position={{ lat, lng }} />}
        {(property.about?.image ||
          property.bedrooms ||
          property.bathrooms ||
          property.carSpaces ||
          property.landSize) && (
          <InfoWindow position={{ lat, lng }}>
            <div className={classes.container}>
              {property.about.image && <img src={property.about.image} alt="Property" />}
              <div className={cn(classes.iconsLayer, {'mt-0': !property.about.image})}>
                <ValueWithIcon value={property.bedrooms} imgSrc={bedImage} />
                <ValueWithIcon value={property.bathrooms} imgSrc={bathImage} />
                <ValueWithIcon value={property.carSpaces} imgSrc={sedanImage} />
                <ValueWithIcon value={property.landSize} imgSrc={measuring} land />
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
}

export default MapWithProperty;
