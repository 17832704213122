import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import Button from "app/components/ui/Buttons/Button";
import PaginationConsecutive from "app/components/PaginationConsecutive";
import Loader from "app/components/ui/Loader";
import { NoData } from "app/components/NoData";
import { PROPOSALS_BY_TYPE_EXTENDED } from "app/constants/proposalsConstants";
import MetricUp from "app/assets/images/leaderboards/MetricUp.svg";
import MetricDown from "app/assets/images/leaderboards/MetricDown.svg";

import { List } from "../List";
import { useGetLeaders } from "../hooks/useGetLeaders";

const LeaderPageRow = ({ leader }) => {
  const sentProposals = PROPOSALS_BY_TYPE_EXTENDED.map(
    type => leader[type.alias],
  );

  return sentProposals.map((proposalCount, index) => (
    <li>
      <Box key={index} textAlign="center">
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          {proposalCount}
        </Typography>
      </Box>
    </li>
  ));
};

const DashboardPageRow = ({ leader }) => {
  const hasMetric = !!leader.metric;
  const isUp = leader.metric === "increase";

  return (
    <Box display="flex" alignItems="center">
      {hasMetric && (
        <Box
          borderRadius="50%"
          bgcolor="#fff"
          width={20}
          height={20}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={1.5}
          border={1}
          borderColor="#d7d7d7"
        >
          <img src={isUp ? MetricUp : MetricDown} alt="Metric" />
        </Box>
      )}
      <Box width={20}>
        <Typography variant="h6" style={{ fontWeight: 600 }}>
          {leader.total}
        </Typography>
      </Box>
    </Box>
  );
};

export const LeadersList = ({ leadersPage = false, search }) => {
  const [page, setPage] = useState(1);
  const [orderField, setOrderField] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const { push } = useHistory();

  const setupOrder = clickOrderField => {
    let field, type;
    if (clickOrderField === orderField) {
      if (orderType === null) {
        field = clickOrderField;
        type = "ASC";
      } else if (orderType === "ASC") {
        field = clickOrderField;
        type = "DESC";
      } else {
        field = null;
        type = null;
      }
    } else {
      field = clickOrderField;
      type = "ASC";
    }
    setOrderField(field);
    setOrderType(type);
  };

  const {
    leaders,
    limit,
    isLoadingLeaders,
    leadersMeta,
    leadersListType,
  } = useGetLeaders({
    isLeadersPage: leadersPage,
    page,
    search,
    orderField,
    orderType,
  });

  const { total, pages } = leadersMeta || {};

  const onViewAll = () => push(`/leaderboard?type=${leadersListType}`);

  if (isLoadingLeaders) {
    return <Loader styleParams={{ top: "auto" }} />;
  }

  if (!leaders.length) {
    return (
      <Box mt={2}>
        <NoData />
      </Box>
    );
  }

  return (
    <>
      <List
        listType={leadersListType}
        leaders={leaders}
        leadersPage={leadersPage}
        renderItem={leadersPage ? LeaderPageRow : DashboardPageRow}
        onSetupOrder={setupOrder}
        orderField={orderField}
        orderType={orderType}
      />
      <Box
        display="flex"
        justifyContent={leadersPage ? "right" : "space-between"}
        paddingTop={3}
        paddingBottom={7}
        paddingX={5}
      >
        {!leadersPage && (
          <Button variant="outlined-blue" onClick={onViewAll}>
            View all
          </Button>
        )}
        <PaginationConsecutive
          totalPages={pages}
          totalItems={total}
          page={page}
          setPage={setPage}
          paginationStep={limit}
        />
      </Box>
    </>
  );
};

export default LeadersList;
