import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { OFFICE_SPECIALITY } from "app/constants/commonConstants";

import { API_URL } from "../Auth/_redux/authCrud";
import { useSubheader } from "../../../_metronic/layout";
import {
  Grid,
  Tabs,
  Tab,
  makeStyles,
  InputLabel,
  Input, Checkbox, FormControlLabel,
} from "@material-ui/core";
import OfficesService from "../../api/OfficesService";
import { Toast } from "primereact/toast";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { shallowEqual, useSelector } from "react-redux";
import { SelectButton } from "primereact/selectbutton";

const tabLinks = [
  { value: "teams", label: "User Profiles", permission_level: 0 },
  { value: "users", label: "User Access", permission_level: 2 },
  { value: "articles", label: "Office Articles", permission_level: 2 },
  { value: "images", label: "Office Images", permission_level: 0 },
  { value: "marketing-settings", label: "Marketing", permission_level: 2 },
  {
    value: "timeline-settings",
    label: "Campaign Calendar",
    permission_level: 2,
  },
  { value: "pm-fees", label: "PM Fees", permission_level: 2 },
  { value: "proposal-types", label: "Proposal Types", permission_level: 3 },
];

const useStyles = makeStyles(theme => ({
  activeTab: {
    color: "rgba(0, 0, 0, 0.54) !important",
    fontWeight: 700,
  },
  inlineEdit: {
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
    "&:focus": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
}));

export const InlineEdit = ({
  withoutLabel = false,
  value,
  onChange,
  onBlur,
  title,
}) => {
  const classes = useStyles();
  const classList = withoutLabel ? "h2" : "h4 text-muted";
  return (
    <>
      {!withoutLabel && (
        <InputLabel
          htmlFor={`website-link-${title}`}
          className="font-weight-bold mr-2 h4"
          style={{ color: "#3F4254" }}
        >
          {title}:
        </InputLabel>
      )}
      <Input
        id="website-link"
        placeholder="Click to Edit"
        classes={{ input: classes.inlineEdit }}
        className={classList}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        disableUnderline
      />
    </>
  );
};

const OfficeDetails = ({ id }) => {
  const history = useHistory();
  const subheader = useSubheader();
  const classes = useStyles();
  const toast = useRef(null);

  const [office, setOffice] = useState([]);
  const [tabValue, setTab] = useState(tabLinks[0].value);
  const [website, setWebsite] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [speciality, setSpeciality] = useState("residential");
  const [flkEnabled, setFLKEnabled] = useState(false);

  const [tabs, setTabs] = useState([]);

  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    const apiUrl = API_URL + `offices/${id}`;

    axios.get(apiUrl).then(resp => {
      const office = resp.data;
      setOffice(office);
      setWebsite(office.website);
      setOfficeName(office.name);
      setSpeciality(office.speciality || "residential");
      setFLKEnabled(office.flkEnabled);
    });
  }, [id]);

  useEffect(() => {
    let tbs = tabLinks.filter(
      item => currentUser.permissions >= item.permission_level,
    );
    setTabs(tbs);
    const tab = tbs.find(item => item.value === tabValue);
    subheader.setTitle(tab.label);
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, subheader]);

  const OfficeParam = ({ title, value, xs }) => {
    return (
      <Grid item xs={xs} className="d-flex align-items-center mb-2">
        <h4 className="font-weight-bold mr-2">{title}</h4>
        <h4 className="text-muted text-hover-primary">{value}</h4>
      </Grid>
    );
  };

  useEffect(() => {
    setTab(history.location.pathname.split("/")[3]);
  }, [history.location.pathname]);

  const updateWebsite = () => {
    OfficesService.updateOffice(id, { website: website })
      .then(resp => {
        const office = resp.data;
        setOffice(office);
        setWebsite(office.website);
        setOfficeName(office.name);
        setFLKEnabled(office.flkEnabled);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: `Website link has been updated`,
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Website link hasn't been updated`,
          life: 3000,
        });
      });
  };

  const updateOfficeName = () => {
    OfficesService.updateOffice(id, { name: officeName })
      .then(resp => {
        const office = resp.data;
        setOffice(office);
        setWebsite(office.website);
        setOfficeName(office.name);
        setFLKEnabled(office.flkEnabled);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: `Office name has been updated`,
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Office name hasn't been updated`,
          life: 3000,
        });
      });
  };

  const updateSpeciality = val => {
    setSpeciality(val);
    OfficesService.updateOffice(id, { speciality: val })
      .then(resp => {
        const office = resp.data;
        setOffice(office);
        setWebsite(office.website);
        setSpeciality(office.speciality);
        setFLKEnabled(office.flkEnabled);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: `Office has been successfully updated`,
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Office speciality hasn't been updated`,
          life: 3000,
        });
      });
  };
  const updateFlkEnabled = (event)=> {
    setFLKEnabled(event.target?.checked);
    OfficesService.updateOffice(id, { flkEnabled: event.target?.checked })
      .then(resp => {
        const office = resp.data;
        setOffice(office);
        setWebsite(office.website);
        setSpeciality(office.speciality);
        setFLKEnabled(office.flkEnabled);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: `Office has been successfully updated`,
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `Office speciality hasn't been updated`,
          life: 3000,
        });
      });
  };

  return (
    <div className="card-header pb-0 pt-7 px-5">
      <Toast ref={toast} />
      <Grid container spacing={2} className="pb-4">
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="officeTitle">
                <InlineEdit
                  value={officeName}
                  onBlur={updateOfficeName}
                  onChange={e => setOfficeName(e.target.value)}
                  withoutLabel
                />
              </div>
            </Grid>

            {currentUser.permissions === 3 && (
              <Grid item xs={3}>
                <FormControlLabel control={<Checkbox checked={flkEnabled}
                                                     onChange={updateFlkEnabled}/>} label={"FLK E-Sign"} />

              </Grid>
            )}

            {currentUser.permissions === 3 && (
              <Grid item xs={3}>
                <SelectButton
                  value={speciality}
                  options={OFFICE_SPECIALITY}
                  onChange={e => updateSpeciality(e.value)}
                  className="select-buttons-black"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <h4>{office.address?.replace(", Australia", "")}</h4>
            </Grid>
            <OfficeParam title="Office ID:" value={office.groupid} xs={2} />
            <OfficeParam title="Phone:" value={office.phone} xs={2} />
            <Grid item xs={4} className="d-flex align-items-center mb-2">
              <InlineEdit
                value={website}
                onChange={e => setWebsite(e.target.value)}
                title="Website"
                onBlur={updateWebsite}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Tabs
        value={tabValue}
        onChange={(_, val) => setTab(val)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className="w-100"
      >
        {tabs.map(tabItem => (
          <Tab
            classes={{ selected: classes.activeTab }}
            disableRipple
            key={tabItem.value}
            value={tabItem.value}
            label={tabItem.label}
            onClick={() => history.push(`/offices/${id}/${tabItem.value}`)}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default OfficeDetails;
