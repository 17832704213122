import React from "react";
import { Container } from "@material-ui/core";

import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import Comparable from "../../Comparables/Comparable";
import TeamDetails from "../TeamDetails";
import NextSteps from "../NextSteps";
import MarketUpdateReport from "../MarketReport/MarketUpdateReport";
import Footer from "../Footer";


const MarketUpdate = () => (
  <>
    <CoverletterImage />
    <Container maxWidth="md" component="div" className="px-10" id="appraisal-details">
      <Coverletter />
      <MarketUpdateReport />
      <Comparable noPriceOpinion/>
      <TeamDetails awards difference profile community history/>
      <NextSteps salesProcess faq lookingFor preparingYourHome/>
    </Container>
    <Container maxWidth="md" component="div" className="px-10 pb-1">
      <Footer />
    </Container>
  </>
)

export default MarketUpdate;
