import { createAction } from '@reduxjs/toolkit';

export const prepareAppraisal = createAction('appraisal/prepare');
export const initializeComparable = createAction('appraisal/initializeComparable');
export const initRhProperties = createAction('appraisal/initRhProperties');
export const initSoldProperties = createAction('appraisal/initSoldProperties');
export const initOnMarketProperties = createAction('appraisal/initOnMarketProperties');
export const fetchArticles = createAction('appraisal/fetchArticles');
export const updateVendorWithCoverLetter = createAction('appraisal/updateVendorWithCoverLetter');
export const updateOwnersDetails = createAction('appraisal/updateOwnersDetails');
export const initVendorDetails = createAction('appraisal/initVendorDetails');
export const updateTeamSection = createAction('appraisal/updateTeamSection');
export const prepareServiceFee = createAction('appraisal/prepareServiceFee');
export const prepareCommercialType = createAction('appraisal/prepareCommercialType');
export const updateAttachmentsSection = createAction('appraisal/updateAttachmentsSection');
