import React, { useState } from "react";
import { PROPOSALS_TYPES_OPTIONS } from "app/constants/proposalsConstants";

import SelectWithSearch from "app/components/SelectWithSearch";

import TeamsService from "app/api/TeamsService";
import OfficesService from "app/api/OfficesService";
import { DatePicker } from "@material-ui/pickers";
import SVG from "react-inlinesvg";
import calendarSvg from "app/assets/images/calendar.svg";
import { useGetUser } from "redux/hooks";

const Filters = ({ filter = {}, setFilter }) => {
  const { user, isCorporateUser } = useGetUser();

  let proposalTypes = isCorporateUser
    ? PROPOSALS_TYPES_OPTIONS
    : PROPOSALS_TYPES_OPTIONS.filter(item =>
        item.role.includes(user?.office?.speciality || "residential"),
      );

  const [officeOptions, setOfficeOptions] = useState([]);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);

  const userOfficeId = user?.office?._id;

  const onSearchByOffice = search => {
    return OfficesService.getOfficeOptions(search, 10).then(res =>
      setOfficeOptions(res?.data?.offices),
    );
  };

  const onSearchByTeamMember = (officeId, search) => {
    return TeamsService.getTeamMemberOptions(officeId, search, 10).then(res =>
      setTeamMemberOptions(res?.data?.teamMembers),
    );
  };

  const setProposalType = value => setFilter({ ...filter, type: value });
  const setProposalOffice = value => setFilter({ ...filter, office: value });
  const setProposalTeamMember = value =>
    setFilter({ ...filter, teamMember: value });

  const setProposalDateMin = value =>
    setFilter({
      ...filter,
      dateMin: value ? value.format("YYYY/MM/DD") : undefined,
    });
  const setProposalDateMax = value =>
    setFilter({
      ...filter,
      dateMax: value ? value.format("YYYY/MM/DD") : undefined,
    });

  return (
    <div className="filter-container">
      {isCorporateUser ? (
        <div className="filter-type-container">
          <SelectWithSearch
            label={"Office"}
            placeholder="Select Office"
            options={officeOptions}
            selectedValue={filter.office}
            onSearch={onSearchByOffice}
            onSelect={setProposalOffice}
            withSearch
            withAllSelect
          />
        </div>
      ) : (
        <React.Fragment />
      )}
      <div className="filter-type-container">
        <SelectWithSearch
          label={"Team Member"}
          placeholder="Select Team Member"
          options={teamMemberOptions}
          selectedValue={filter.teamMember}
          onSearch={search =>
            onSearchByTeamMember(
              !isCorporateUser ? userOfficeId : undefined,
              search,
            )
          }
          onSelect={setProposalTeamMember}
          withSearch
          withAllSelect
        />
      </div>
      <div className="filter-type-container">
        <SelectWithSearch
          label={"Proposal Type"}
          placeholder="Select Type"
          options={proposalTypes}
          selectedValue={filter.type}
          onSelect={setProposalType}
          withAllSelect
        />
      </div>
      <div className="filter-type-container">
        <div className="filter-type-label">Start date</div>
        <DatePicker
          className={`filter-type-date ${
            filter.dateMin ? "" : "filter-type-date-empty"
          }`}
          clearable
          value={filter.dateMin || null}
          onChange={setProposalDateMin}
          emptyLabel="Select Date"
          format="DD/MM/YY"
        />
        <SVG className="filter-type-date-svg" src={calendarSvg} />
      </div>
      <div className="filter-type-container">
        <div className="filter-type-label">End date</div>
        <DatePicker
          className={`filter-type-date ${
            filter.dateMax ? "" : "filter-type-date-empty"
          }`}
          clearable
          value={filter.dateMax || null}
          onChange={setProposalDateMax}
          emptyLabel="Select Date"
          format="DD/MM/YY"
        />
        <SVG className="filter-type-date-svg" src={calendarSvg} />
      </div>
    </div>
  );
};

export default Filters;
