import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  fetchProposal,
  fetchMetrics,
  fetchSendHistory,
  fetchCurrentSessionData,
} from "../actions";
import {
  setIsLoadSessions,
  setPropertyInfo,
  setReportInfo,
  setSendHistory,
  setCurrentSessionData,
  setIsLoadSessionData,
} from "../reducers/metricsReducer";
import { updateAppraisal, setAppraisalId } from "../reducers/appraisalReducer";
import MetricsService from "app/api/MetricsService";
import AppraisalsService from "app/api/AppraisalsService";
import SendService from "app/api/SendService";

function* fetchProposalSaga({ payload }) {
  const { data } = yield call(AppraisalsService.getAppraisal, payload.id);
  yield put(
    setPropertyInfo({
      proposal: data,
      agentName: data.team.leaderName || data.team.name,
      imageId: data.team.profilePortrait,
      officeName: data.team.officeName,
      updated: data.updated_at,
    }),
  );
  yield put(setAppraisalId(data._id));
  yield put(updateAppraisal(data));
}

function* fetchReportsSaga({ payload }) {
  yield put(setIsLoadSessions(true));
  const { data } = yield call(MetricsService.getMetrics, payload.id); 
  yield put(
    setReportInfo({
      all: data.all,
      prints: data.prints,
      sessions: data.sessions,
    }),
  );
  yield put(setIsLoadSessions(false));
}

function* fetchSendHistorySaga({ payload }) {
  const { data } = yield call(SendService.getHistory, payload.id);
  yield put(setSendHistory(data));
}

function* fetchCurrentSessionDataSaga({ payload }) {
  yield put(setIsLoadSessionData(true));
  const { data } = yield call(MetricsService.getSession, payload);
  yield put(setCurrentSessionData(data));
  yield put(setIsLoadSessionData(false));
}

export default function* watchMetrics() {
  yield all([
    yield takeLatest(fetchProposal, fetchProposalSaga),
    yield takeLatest(fetchMetrics, fetchReportsSaga),
    yield takeLatest(fetchSendHistory, fetchSendHistorySaga),
    yield takeLatest(fetchCurrentSessionData, fetchCurrentSessionDataSaga),
  ]);
}
