import axios from 'axios';
import API_URL from './api';

export default class VideosService {

  static postVideo = (body) => {
    return axios.post(
      `${API_URL}/videos`, body
    );
  }

  static getVideos = () => {
    return axios.get(
      `${API_URL}/videos`
    );
  }

  static deleteVideo = (id) => {
    return axios.delete(
      `${API_URL}/videos/${id}`
    );
  }
}
