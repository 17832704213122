import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getSessions,
  getIsLoadSessions,
} from "redux/selectors/metricsSelector";
import SvgIcon from "app/components/ui/SvgIcon";
import Loader from "app/components/ui/Loader";
import SessionModal from "../SessionModal";

import "./styles.scss";

// TODO: remove reRenders
const SessionsMetrics = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentSession, setCurrentSession] = useState(false);

  const isLoad = useSelector(getIsLoadSessions);
  const sessions = useSelector(getSessions);

  const onSessionOpen = session => {
    setCurrentSession(session);
    setShowModal(true);
  };

  const listColumnTitles = [
    { className: "w-150", content: "Date" },
    { className: "w-150", content: "Sessions" },
    { className: "w-110", content: "Duration (h:mm:ss)" },
    { className: "w-110", content: "Click Engagements" },
    { className: "w-55", content: "Printed" },
    { className: "w-110", content: "Recipient" },
    { className: "w-35", content: "Open" },
  ];

  return (
    <div className="sessions-list">
      <div className="list_header layout-side-padding">
        <h3 className="list_title">Sessions</h3>
        <div className="list_column-titles">
          {listColumnTitles.map(item => (
            <span key={item.content} className={item.className}>
              {item.content}
            </span>
          ))}
        </div>
      </div>
      <div className="list_body">
        {sessions.length ? (
          sessions.map((session, index) => (
            <div
              key={index}
              className="list_row layout-side-padding horizontal-separator"
            >
              <span className="w-150">{session.date}</span>
              <span className="w-150">{session.id}</span>
              <span className="w-110">{session.duration}</span>
              <span className="w-110">{session.clicks}</span>
              <span className="w-55">{session.printed}</span>
              <span className="w-110">{session?.recipient !== "undefined undefined" ? session.recipient : "N/A"}</span>
              <span
                className="w-35 hover-item"
                onClick={() => onSessionOpen(session)}
              >
                <SvgIcon iconName="actionReport" />
              </span>
            </div>
          ))
        ) : !sessions.length && !isLoad ? (
          <div className="no-data">
            <h4>No session recorded</h4>
            <p>
              This proposal hasn’t been opened and read yet. Don’t forget to
              follow up and send a reminder.
            </p>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      {showModal && (
        <SessionModal
          onClose={() => setShowModal(false)}
          session={currentSession}
        />
      )}
    </div>
  );
};

export default SessionsMetrics;
