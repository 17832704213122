import { APPRAISAL_TYPES } from "../../constants/appraisalsConstants";

export const messages = {
  lease: {
    [APPRAISAL_TYPES.preAppraisal]: `
    <p style="text-align: center;">We are thrilled at the prospect to discuss your property's investment potential and turn your curiosity into confidence.</p>
    <p style="text-align: center;">Most of all, we will be there to support you and ensure you are comfortable every step of the way. Our agency has been built on instilling confidence and now is our time to shine.</p>
    <p style="text-align: center;">Before we meet, we welcome you to click on the link below to discover how our strategies can help you secure the best possible outcome in the leasing and management of your investment property.</p>
    `,
    [APPRAISAL_TYPES.listingProposal]: `
    <p style="text-align: center;">It was an absolute pleasure meeting to discuss the opportunity to lease and manage your property. For me, it’s simple: I’m here to maximise your investment return and get your property leased as swiftly as possible.</p>
    <p style="text-align: center;">Click to view your personal proposal below and let’s secure you the right tenant, maximum rental return and make this experience as enjoyable as possible.</p>
    `,
    [APPRAISAL_TYPES.marketUpdate]: `
    <p style="text-align: center;">Are you curious to know how the current market conditions are affecting the value of your investment? We don’t guess, we know.</p>
    <p style="text-align: center;">Our bespoke market report is designed to inform you and support your property journey. With the property market constantly changing, and with many qualified tenants eager to secure a property in your area, it makes sense to keep you up-to-date.</p>
    <p style="text-align: center;">If you would like to know more about your local market trends and how it relates to your property, please click on the button below.</p>
    `,
    [APPRAISAL_TYPES.agentProfiling]: `
    <p style="text-align: center;">Thank you for taking the time to read my profile.</p>
    <p style="text-align: center;">As an experienced leasing agent in your local area, I offer exceptional and professional services when it comes to leasing and managing property. I strive to not only meet your expectations but to exceed them and ensure that your experience is both profitable and totally satisfying.</p>
    <p style="text-align: center;">I am dedicated and committed to making "A Wow Experience" for your next real estate venture. Please click on the button below to find out more.</p>
    `,
    [APPRAISAL_TYPES.prospecting]: `
      <p style="text-align: center;">It was an absolute pleasure meeting to discuss the opportunity to open a Franchise office with Raine & Horne.</p>
      <p style="text-align: center;">Click to view your personal proposal below and let’s secure this opportunity for you.</p>
    `,
    [APPRAISAL_TYPES.informationMemorandum]: `
    <p style="text-align: center;">Thank you for your interest in the above listing. To view more information and property details, please click on the link below to access the Information Memorandum.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
    [APPRAISAL_TYPES.tenantHandbook]: `
    <p style="text-align: center;">Thank you for your interest in the above listing. To view more information and property details, please click on the link below to access the Tenant Information Guide.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
    [APPRAISAL_TYPES.commercialListingProposal]: `
    <p style="text-align: center;">Thank you for your interest in the above commercial listing submission. To view more information, please click on the link below to access the Commercial Listing Submission.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
    [APPRAISAL_TYPES.businessListingProposal]: `
    <p style="text-align: center;">It was an absolute pleasure meeting to discuss the opportunity to lease and manage your property. For me, it’s simple: I’m here to maximise your investment return and get your property leased as swiftly as possible.</p>
    <p style="text-align: center;">Click to view your personal proposal below and let’s secure you the right tenant, maximum rental return and make this experience as enjoyable as possible.</p>
    `,
    [APPRAISAL_TYPES.residentialInformationMemorandum]: `
    <p style="text-align: center;">Thank you for your interest in the above listing. To view more information and property details, please click on the link below to access the Information Memorandum.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
  },
  sales: {
    [APPRAISAL_TYPES.preAppraisal]: `
    <p style="text-align: center;">We are thrilled at the prospect to discuss your property's enticing potential and turn your curiosity into confidence.</p>
    <p style="text-align: center;">Most of all, we will be there to support you and ensure you are comfortable every step of the way. Our agency has been built on instilling confidence and now is our time to shine.</p>
    <p style="text-align: center;">Before we meet, we welcome you to click on the link below to discover how our strategies can help you secure the best possible outcome.</p>
    `,
    [APPRAISAL_TYPES.listingProposal]: `
    <p style="text-align: center;">It was an absolute pleasure meeting to discuss the opportunity to list your property. For me, it’s simple: I’m here to maximise your sale price and get your property sold as swiftly as possible.</p>
    <p style="text-align: center;">Click to view your personal proposal below and let’s secure you the sale price you deserve and make this experience as enjoyable as possible.</p>
    `,
    [APPRAISAL_TYPES.marketUpdate]: `
    <p style="text-align: center;">Are you curious to know how the current market conditions are affecting the value of your property? We don’t guess, we know.</p>
    <p style="text-align: center;">Our bespoke market report is designed to inform you and support your property journey. With the property market constantly changing, and, with many qualified buyers eager to secure a property in your area, it makes sense to keep you up-to-date.</p>
    <p style="text-align: center;">If you would like to know more about your local market trends and how it relates to your property, please click on the button below.</p>
    `,
    [APPRAISAL_TYPES.agentProfiling]: `
    <p style="text-align: center;">Thank you for taking the time to read my profile.</p>
    <p style="text-align: center;">As an experienced agent in your local area, I offer exceptional and professional services when it comes to buying and selling. I strive to not only meet your expectations but to exceed them and ensure that your experience is both profitable and totally satisfying.</p>
    <p style="text-align: center;">I am dedicated and committed to making "A Wow Experience" for your next real estate venture. Please click on the button below to find out more.</p>
    `,
    [APPRAISAL_TYPES.prospecting]: `
      <p style="text-align: center;">It was an absolute pleasure meeting to discuss the opportunity to open a Franchise office with Raine & Horne.</p>
      <p style="text-align: center;">Click to view your personal proposal below and let’s secure this opportunity for you.</p>
    `,
    [APPRAISAL_TYPES.informationMemorandum]: `
    <p style="text-align: center;">Thank you for your interest in the above listing. To view more information and property details, please click on the link below to access the Information Memorandum.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
    [APPRAISAL_TYPES.tenantHandbook]: `
    <p style="text-align: center;">Thank you for your interest in the above listing. To view more information and property details, please click on the link below to access the Tenant Information Guide.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
    [APPRAISAL_TYPES.commercialListingProposal]: `
    <p style="text-align: center;">Thank you for your interest in the above commercial listing submission. To view more information, please click on the link below to access the Commercial Listing Submission.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
    [APPRAISAL_TYPES.businessListingProposal]: `
    <p style="text-align: center;">It was an absolute pleasure meeting to discuss the opportunity to list your property. For me, it’s simple: I’m here to maximise your sale price and get your property sold as swiftly as possible.</p>
    <p style="text-align: center;">Click to view your personal proposal below and let’s secure you the sale price you deserve and make this experience as enjoyable as possible.</p>
    `,
    [APPRAISAL_TYPES.residentialInformationMemorandum]: `
    <p style="text-align: center;">Thank you for your interest in the above listing. To view more information and property details, please click on the link below to access the Information Memorandum.</p>
    <p style="text-align: center;">If you have any further questions, please do not hesitate to contact me.</p>
    `,
  },
}
