import { DESCENDING, ASCENDING } from 'app/constants/commonConstants';

export const formatTimeFromSeconds = (duration) => {
  const hrs = ~~(duration / 3600),
    mins = ~~((duration % 3600) / 60),
    secs = duration % 60;

  let ret = "";

  ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

export const replaceKeysOfObject = (obj, mapObj) => {
  const str = JSON.stringify(obj);
  const re = new RegExp(Object.keys(mapObj).join("|"), "gi");

  const newSrt = str.replace(re, function(matched) {
    return mapObj[matched];
  });

  return JSON.parse(newSrt);
};

export const sortingData = (
  column,
  setSortColumn,
  sortColumn,
  sortDirection,
  setSortDirection,
) => {
  setSortColumn(column);

  if (column === sortColumn) {
    let sortBy;
    if (sortDirection) {
      sortBy = sortDirection === ASCENDING ? DESCENDING : null;
      if (!sortBy) {
        setSortColumn(null);
      }
    } else {
      sortBy = ASCENDING;
    }
    setSortDirection(sortBy);
  } else {
    setSortDirection(ASCENDING);
  }
};
