export const getPropertyTypes = (type, classification) => {
  const MarketResidentialOptions = [
    { display: "All", value: "All" },
    { display: "Flat", value: "FLATS" },
    { display: "House", value: "HOUSE" },
    { display: "Land", value: "LAND" },
    { display: "Unit", value: "UNIT" },
    { display: "Commercial", value: "Commercial" },
    { display: "Business", value: "Business" },
    { display: "Community", value: "Community" },
    { display: "Farm", value: "Farm" },
    { display: "Storage Unit", value: "Storage" },
  ];
  const MarketCommercialOptions = [
    { display: "All", value: "All" },
    { display: "Land", value: "LAND" },
    { display: "Commercial", value: "Commercial" },
    { display: "Business", value: "Business" },
    { display: "Community", value: "Community" },
    { display: "Farm", value: "Farm" },
    { display: "Storage Unit", value: "Storage" },
  ];
  const RHResidentialOptions = [
    { display: "All", value: "All" },
    { display: "Apartment", value: "Apartment" },
    { display: "BlockOfUnits", value: "BlockOfUnits" },
    { display: "DuplexSemi-detached", value: "DuplexSemi-detached" },
    { display: "House", value: "House" },
    { display: "Land", value: "Land" },
    { display: "Lifestyle", value: "Lifestyle" },
    { display: "Studio", value: "Studio" },
    { display: "Terrace", value: "Terrace" },
    { display: "Townhouse", value: "Townhouse" },
    { display: "Unit", value: "Unit" },
    { display: "Villa", value: "Villa" },
  ];
  const RHCommercialOptions = [
    { display: "All", value: "All" },
    { display: "Hotel/Leisure", value: "Hotel/Leisure" },
    { display: "Industrial/Warehouse", value: "Industrial/Warehouse" },
    { display: "Land/Development", value: "Land/Development" },
    { display: "Medical/Consulting", value: "Medical/Consulting" },
    { display: "Offices", value: "Offices" },
    { display: "Retail", value: "Retail" },
    { display: "Showrooms/Bulky", value: "Showrooms/Bulky Goods" },
    { display: "Other", value: "Other" },
  ];

  if (type !== "rh") {
    return classification === "residential"
      ? MarketResidentialOptions
      : MarketCommercialOptions;
  }
  return classification === "residential"
    ? RHResidentialOptions
    : RHCommercialOptions;
};
