import axios from "axios";
import API_URL from "./api";
import { stringify } from "querystring";

export default class AppraisalsService {
  static getAppraisals = (
    search = "",
    page = 1,
    filter = "updated_at",
    sort = "descending",
    archived = false,
    draft,
    limit = 50,
    weeks = 4,
    proposalType = "",
    proposalOffice = "",
    proposalTeamMember = "",
    proposaldateRange = "-",
  ) => {
    return axios.get(
      `${API_URL}/appraisals?search=${search}&page=${page}&filter=${filter}&sort=${sort}&archived=${archived}&draft=${draft}&limit=${limit}&weeks=${weeks}&proposalType=${proposalType}&proposalOffice=${proposalOffice}&proposalTeamMember=${proposalTeamMember}&proposaldateRange=${proposaldateRange}`,
    );
  };

  static getRecentAppraisals = (
    limit = 5,
    page = 1,
    weeks = 1,
    isQuantity = false,
  ) => {
    return axios.get(
      `${API_URL}/appraisals/recent?limit=${limit}&page=${page}&weeks=${weeks}&isQuantity=${isQuantity}`,
    );
  };

  static getUnopenedAppraisals = (limit = 5, page = 1, weeks = 1) => {
    return axios.get(
      `${API_URL}/appraisals/unopened?limit=${limit}&page=${page}&weeks=${weeks}`,
    );
  };

  static leaderboard = (
    weeks = 1,
    limit = 0,
    page = 1,
    range = { start: null, end: null },
    search = "",
    orderField = null,
    orderType = null,
    withMetric = false,
  ) => {
    return axios.get(
      `${API_URL}/appraisals/leaderboard?weeks=${weeks}&limit=${limit}&page=${page}&start=${range.start}&end=${range.end}&search=${search}&orderField=${orderField}&orderType=${orderType}&metric=${withMetric}`,
    );
  };

  static leaderboardExport = (
    weeks = 1,
    start_date = null,
    end_date = null,
  ) => {
    return axios.get(
      `${API_URL}/appraisals/leaderboard/export/csv?weeks=${weeks}&start=${start_date}&end=${end_date}`,
      { responseType: "blob" },
    );
  };

  static leaderboardOfficeExport = (
    weeks = 1,
    start_date = null,
    end_date = null,
  ) => {
    return axios.get(
      `${API_URL}/appraisals/leaderboard/offices_export/csv?weeks=${weeks}&start=${start_date}&end=${end_date}`,
      { responseType: "blob" },
    );
  };

  static officeLeaderboard = (
    weeks = 1,
    limit = 0,
    page = 1,
    range,
    search = "",
    orderField = null,
    orderType = null,
    withMetric = false,
  ) => {
    return axios.get(
      `${API_URL}/appraisals/office_leaderboard?weeks=${weeks}&limit=${limit}&page=${page}&start=${range.start}&end=${range.end}&search=${search}&orderField=${orderField}&orderType=${orderType}&metric=${withMetric}`,
    );
  };

  static senttypes = (weeks = 1) => {
    return axios.get(`${API_URL}/appraisals/senttypes?weeks=${weeks}`);
  };

  static sentGraph = (weeks = 1) => {
    return axios.get(`${API_URL}/appraisals/sent_graph?weeks=${weeks}`);
  };

  static postAppraisal = body => {
    return axios.post(`${API_URL}/appraisals`, body);
  };

  static putAppraisal = (body, id) => {
    return axios.put(`${API_URL}/appraisals/${id}`, body);
  };

  static getAppraisal = id => {
    return axios.get(`${API_URL}/appraisals/${id}`);
  };

  static archiveAppraisal = id => {
    return axios.put(`${API_URL}/appraisals/${id}/archive`);
  };

  static unarchiveAppraisal = id => {
    return axios.put(`${API_URL}/appraisals/${id}/unarchive`);
  };

  static printAppraisal = id => {
    delete axios.defaults.headers.common["Authorization"];
    const printLink = `${API_URL}/appraisals/print/main/${id}`;
    const footerUrl = `${API_URL}/appraisals/print/footer/${id}`;
    const pdfName = "proposal.pdf";
    const urlProps = {
      key: "251a7657-ae07-4ae0-b69b-54f181aefad2",
      url: printLink,
      max_load_time: 180,
      page_size: "A4",
      page_orientation: "Portrait",
      page_numbers: false,
      margin_top: 0,
      margin_right: 0,
      margin_bottom: 0,
      margin_left: 0,
      show_header: true,
      header_height: 0,
      header_display_on_first_page: false,
      show_footer: true,
      footer_url: footerUrl,
      footer_display_on_first_page: false,
      footer_height: 50,
      keep_images_together: true,
      pdf_name: pdfName,
    };

    const url = `https://selectpdf.com/api2/convert/?${stringify(urlProps)}`;

    let instance = axios.create();
    delete instance.defaults.headers.common["Authorization"];
    return instance.get(url, {
      method: "GET",
      responseType: "blob",
    });
  };

  static smsToAgent = id => {
    return axios.get(`${API_URL}/appraisals/${id}/sendSmsToAgent`);
  };

  static createProposalData = id => {
    return axios.get(`${API_URL}/appraisals/create/${id}`);
  };

  static getProposalStatistic = params => {
    return axios.get(`${API_URL}/proposal/statistic`, { params });
  };

  static getShortProposals = params => {
    return axios.get(`${API_URL}/proposal/getShortProposals`, { params });
  };

  static getDataForSentProposalGraph = (weeks = 1, range) => {
    return axios.get(
      `${API_URL}/proposal/sentGraph?weeks=${weeks}&start=${range?.start}&end=${range?.end}`,
    );
  };

  static getSentProposalByType = (weeks = 4, range) => {
    return axios.get(
      `${API_URL}/proposal/getSentProposalByType?weeks=${weeks}&start=${range?.start}&end=${range?.end}`,
    );
  };

  static getProposalList = params => {
    return axios.get(`${API_URL}/proposal/getProposalList`, { params });
  };
}
