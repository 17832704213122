import React, { useContext, useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ProposalTypes } from "app/interfaces";
import { updateProperty } from "redux/reducers/appraisalReducer"
import { AppraisalContext } from "../AppraisalContext";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import ModalImageLibrary from "../../Common/ModalImageLibrary/ModalImageLibrary"

import agentProfileImage from "../../../assets/images/cover/agent-profile-default.png"
import marketReportImage from "../../../assets/images/cover/market-report-default.png"
import preListImage from "../../../assets/images/cover/prelist-default.png"
import proposalImage from "../../../assets/images/cover/proposal-default.png"
import commercialIM from "../../../assets/images/cover/commercial-IM-default.jpeg"
import prospectingImage from "../../../assets/images/cover/market-report-default.png"
import commercialImage from "../../../assets/images/cover/commercial-default.jpg"
import businessImage from "../../../assets/images/cover/proposal-default.png"
import { getImageUrl } from "../../../../shared";

const defaultImage = {
  [ProposalTypes.preAppraisal]: preListImage,
  [ProposalTypes.listingProposal]: proposalImage,
  [ProposalTypes.marketUpdate]: marketReportImage,
  [ProposalTypes.agentProfiling]: agentProfileImage,
  [ProposalTypes.informationMemorandum]: commercialIM,
  [ProposalTypes.prospecting]: prospectingImage,
  [ProposalTypes.commercialListingProposal]: commercialImage,
  [ProposalTypes.businessListingProposal]: businessImage,
  [ProposalTypes.residentialInformationMemorandum]: proposalImage,
  [ProposalTypes.tenantHandbook]: proposalImage,
}

const defaultAspectRation = 100 / 3;

const useStyles = makeStyles(() => ({
  imageContainer : ({ aspectRation = defaultAspectRation }) => ({
    display: "block",
    width: "100%",
    height: "auto",
    position: "relative",
    overflow: "hidden",
    padding: `${aspectRation}% 0 0 0`,
    "&>img": {
      objectFit: "cover",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }
  }),
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    transition: '.3s ease',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    cursor: "pointer",
    "&:hover": {
      opacity: 1
    },
    "&>img": {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    }
  },
}));

const CoverLetterImage = ({ isCommercial, imageAspectRatio = 3 }) => {
  const dispatch = useDispatch();

  const coverImage = useSelector(state => state.appraisal.property.coverImage, shallowEqual);
  const officeId = useSelector(state => state.appraisal.team.office, shallowEqual);
  const appraisalType = useSelector(({ appraisal }) => appraisal.meta.appraisalType, shallowEqual);

  const [imageSrc, setImageSrc] = useState("");
  const [imageLibraryModalShow, setImageLibraryModalShow] = useState(false);

  const selectImage = id => {
    dispatch(updateProperty({ coverImage: id }));
    setImageLibraryModalShow(false)
  }

  useEffect(() => {
    setImageSrc(getImageUrl(coverImage));
  }, [coverImage, setImageSrc])

  const onImageError = () => {
    setImageSrc(defaultImage[appraisalType])
  }

  const { preview } = useContext(AppraisalContext)

  const classes = useStyles({ aspectRation: (100 / imageAspectRatio)  });

  const onClickHandler = () => {
    if (!preview) setImageLibraryModalShow(true);
  }

  const sharedFolder = isCommercial 
    ? { 
        title: 'Shared Commercial Cover Images', 
        value: 'Commercial Cover Images', 
        shared: true 
      }
    : { title: 'Shared Cover Images', value: 'Cover Images', shared: true }
    
  const imageFolders = [
    sharedFolder,
    { title: 'My Cover Images', value: 'Cover Images', shared: false }
  ]

  return(
    <>
      <Box width="100%">
        <div className={classes.imageContainer}>
          <img
            src={imageSrc}
            onError={onImageError}
            alt="cover"
          />
          { preview ? "" : (
            <div className={classes.overlay} onClick={onClickHandler}>
              <img src={toAbsoluteUrl("/media/upload.png")} alt="upload" />
            </div>
          )}
        </div>
      </Box>
      <ModalImageLibrary
        title="Cover Letter Images"
        onSelect={selectImage}
        officeId={officeId}
        imageFolders={imageFolders}
        aspectRatio={imageAspectRatio}
        onHide={() => setImageLibraryModalShow(false)}
        show={imageLibraryModalShow}
      />
    </>
  )
}

export default CoverLetterImage;
