export const convertAddress = str => {
  const australiaStates = {
    'Queensland': 'QLD',
    'New South Wales': 'NSW',
    'South Australia': 'SA',
    'Tasmania': 'TAS',
    'Western Australia': 'WA',
    'Northern Territory': 'NT',
    'Victoria': 'VIC'
  }

  try {
    let capitalizedText = str.split(' ').map( item => item.charAt(0).toUpperCase() + item.toLowerCase().slice(1)).join(' ');
    const stateIndex = Object.keys(australiaStates).findIndex( item => capitalizedText.includes(item) );
    if (stateIndex === -1) return capitalizedText
    return capitalizedText.replace(Object.keys(australiaStates)[stateIndex], Object.values(australiaStates)[stateIndex])
  } catch {
    return str;
  }
}
