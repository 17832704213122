// availableCalendars Reducer
import { createSlice } from '@reduxjs/toolkit'


export const availableCalendarsSlice = createSlice({
  name: 'availableCalendars',
  initialState: [],
  reducers: {
    setAvailableCalendars: (state, action) => {
      state = [ ...[], ...action.payload ]
      return state;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  setAvailableCalendars,
} = availableCalendarsSlice.actions

export default availableCalendarsSlice.reducer
