import { format } from 'date-fns';

const TIME_CONSTANT = 60;

const checkUTC = (date) => {
  if (date.includes('Z')) {
    return date.slice(0, -1);
  }
  return date;
};

export const formatDateISO8601ToDdMmYy = date => {
  const checkedUTC = checkUTC(date);
  return format(new Date(checkedUTC), 'dd/MM/yy');
};
export const formatDateISO8601ToHHmm = date => {
  const checkedUTC = checkUTC(date);
  return format(new Date(checkedUTC), 'hh:mm aaa');
};

export const formatDateISO8601ToFullDate = date => {
  const checkedUTC = checkUTC(date);
  const pattern = 'MMM do yyyy';
  return format(new Date(checkedUTC), pattern);
};

export const formatDateStampToISO8601 = stamp => {
  const pattern = 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'';
  return format(stamp, pattern);
};

const formatTime = value => value < 10 ? `0${value}` : value;

export const convertSecToHhMmSs = time => {
  let hours = 0;
  let minutes = Math.trunc(time / TIME_CONSTANT);
  let seconds = Math.round(time % TIME_CONSTANT);
  if (minutes > 59) {
    hours = Math.trunc(minutes / TIME_CONSTANT);
    minutes = minutes % TIME_CONSTANT;
  }
  minutes = formatTime(minutes);
  seconds = formatTime(seconds);
  return `${hours}:${minutes}:${seconds}`;
};

export const convertSecToFormattedStr = time => {
  if (time) {
    let hours;
    let minutes = Math.trunc(time / TIME_CONSTANT);
    let seconds = Math.round(time % TIME_CONSTANT);
    if (minutes > 59) {
      hours = Math.trunc(minutes / TIME_CONSTANT);
      minutes = minutes % TIME_CONSTANT;
    }
    hours = hours && formatTime(hours);
    minutes = formatTime(minutes);
    seconds = formatTime(seconds);
    return hours ? `${hours}:${minutes}:${seconds}s` : `${minutes}:${seconds}s`;
  }
  return `00:00s`;
};
