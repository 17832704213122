import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useHistory } from "react-router-dom";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import EditorComponent from "../Common/EditorComponent";
import Button from "app/components/ui/Buttons/Button";
import SuccessModal from "./SuccessModal";
import { fetchMessagesList } from "redux/actions";
import MessagesService from "../../api/MessagesService";
import { useToast, getErrorToastMessage } from "../../hooks";
import icon from "../../assets/images/bell.svg";
import ExpandMoreIcon from "../../assets/images/expandMore.svg";
import ReviewMessage from "./ReviewMessage";

const useStyles = makeStyles({
  mainContainer: {
    height: "100%",
    padding: "50px 50px 50px",
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#333",
    "& .tox-tinymce": {
      minHeight: "300px",
    },
  },
  newMessageDetailBlock: {
    display: "flex",
    padding: "30px 0 0 0",
  },
  texareaBlock: {
    padding: "30px 0 0 0",
  },
  headerOfDetail: {
    marginRight: "20px",
    fontSize: "32px",
    lineHeight: "36px",
  },
  container: {
    padding: 0,
    marginBottom: "30px",
  },
  checkbox: {
    "& div": {
      marginLeft: "10px",
    },
  },
  createMessageBlock: {
    display: "flex",
    flexDirection: "column",
  },
  selectInput: {
    height: "36px",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#FDB818",
  },
  select: {
    paddingRight: "14px !important",
    "&:focus": {
      background: "none",
    },
  },
  showcaseLabel: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "bold",
  },
  showcaseText: {
    fontSize: "14px",
    lineHeight: "20px",
    paddingLeft: "26px",
    marginBottom: "20px",
  },
  cancelButton: {
    padding: "12px 16px",
    width: "131px",
    height: "40px",
    background: "#F4F4F4",
    borderRadius: "4px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    marginRight: "8px",
  },
  submitButton: {
    padding: "12px 16px",
    width: "135px",
    height: "40px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FBFBFB",
    background: "#FDB818",
    borderRadius: "4px",
  },
});

interface Message {
  seen: [];
  content: string;
  showcaseNotification: boolean;
  sender: {
    email: string;
    name: string;
    phone: string;
    office: string;
  };
  to: string;
  message_unvisible_to: [];
}

const CreateMessage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showToastMessage } = useToast();
  const [audienceValue, setAudienceValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);
  const [reviewData, setReviewData] = useState<any>(null);
  const [showcaseNotificationValue, setShowcaseNotificationValue] = useState(
    false,
  );
  const classes = useStyles();
  const currentUser = useSelector(
    ({ auth }: RootState) => auth.user,
    shallowEqual,
  );

  const possibleAudienceValues = ["all", "residential", "commercial"];

  const onSend = async (data: Message) => {
    MessagesService.sendMessage(data)
      .then(() => {
        dispatch(
          fetchMessagesList({ speciality: currentUser?.office?.speciality }),
        );
        setAudienceValue("");
        setMessageValue("");
        setReviewData(null);
        setIsSuccessModalShow(true);
      })
      .catch(() => {
        showToastMessage(
          getErrorToastMessage("Something went wrong, please try again later"),
        );
      });
  };

  const onReview = () => {
    const newData = {
      to: audienceValue,
      content: messageValue,
      showcaseNotification: showcaseNotificationValue,
      seen: [],
      sender: {
        phone: currentUser.phone,
        email: currentUser.email,
        name: `${currentUser.firstname} ${currentUser.lastname}`,
        user: currentUser._id,
        office: currentUser?.office?.name,
      },
      message_unvisible_to: [],
    };
    setReviewData(newData);
  };

  const onCancel = () => {
    history.push("/dashboard");
  };

  const onCancelReview = () => {
    setReviewData(null);
  };

  const onHandleSelect = (e: any) => {
    setAudienceValue(e.target.value);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.createMessageBlock}>
        <span>
          <img src={icon} alt="icon"></img>
        </span>
        <div className={classes.newMessageDetailBlock}>
          <div className={classes.headerOfDetail}>Create a new update for</div>
          <Select
            onChange={(e: any) => onHandleSelect(e)}
            value={audienceValue}
            displayEmpty
            renderValue={
              audienceValue !== ""
                ? undefined
                : () => <div>Select audience</div>
            }
            className={classes.selectInput}
            IconComponent={() => (
              <img src={ExpandMoreIcon} alt="expandMoreIcon" />
            )}
            disableUnderline
            classes={{ select: classes.select }}
          >
            {possibleAudienceValues.map(tag => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.texareaBlock}>
          <div className={classes.container}>
            {/* @ts-ignore */}
            <EditorComponent
              value={messageValue}
              onBlur={(value: any) => setMessageValue(value)}
              placeholder="Click to add update here..."
              type="messages"
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-direction-column">
        <div>
          <label className={`checkbox checkbox-primary ${classes.checkbox}`}>
            <input
              id="showcaseNotification"
              type="checkbox"
              checked={showcaseNotificationValue}
              onChange={event =>
                setShowcaseNotificationValue(event.target.checked)
              }
            />
            <span />
            <div className={classes.showcaseLabel}>Showcase update</div>
          </label>
        </div>
        <div className={classes.showcaseText}>
          Create a notification that automatically appears as a pop up window
          when a user signs in.
        </div>
        <div>
          <Button className={classes.cancelButton} onClick={onCancel}>
            Discard & cancel
          </Button>
          <Button className={classes.submitButton} onClick={onReview}>
            Review & publish
          </Button>
        </div>
      </div>
      {isSuccessModalShow && (
        <SuccessModal
          show={isSuccessModalShow}
          onClose={() => setIsSuccessModalShow(false)}
        />
      )}
      {reviewData && (
        <ReviewMessage
          data={reviewData}
          onCancel={onCancelReview}
          onConfirm={onSend}
        />
      )}
    </div>
  );
};

export default CreateMessage;
