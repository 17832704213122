import React, { useEffect } from "react";
import ProposalBuilder from "../ProposalBuilder";
import { useHistory, useParams } from "react-router-dom";
import AppraisalsService from "../../api/AppraisalsService";
import { useDispatch } from "react-redux";
import { setAppraisal } from "../../../redux/reducers";
import { useToast, getErrorToastMessage } from "../../hooks";

interface CreteProposalProps {
  compassId: string;
}

const CreateProposalByCompassId = () => {
  const { compassId } = useParams<CreteProposalProps>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showToastMessage } = useToast();

  useEffect(() => {
    AppraisalsService.createProposalData(compassId)
      .then(resp => dispatch(setAppraisal(resp.data)))
      .catch(err => {
        showToastMessage(getErrorToastMessage(err.message));
        history.push(`/dashboard`);
      });
  }, [compassId, dispatch, history, showToastMessage]);

  return <ProposalBuilder />;
};

export default CreateProposalByCompassId;
