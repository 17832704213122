import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";

import infoIcon from "../../../../assets/images/fi-info.svg";

const useMetricsTableRowStyles = makeStyles({
  metricRow: {
    borderTop: "1px solid #e5e5e5",
  },
  metric: {
    color: "#333",
    fontWeight: 300,
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "32px !important",
    textTransform: "capitalize",
    padding: "10px 12px",
  },
  tooltipWrapper: {
    position: "relative",
    cursor: "help",
    textTransform: "capitalize",
    lineHeight: "1.5em !important",
    display: "inline-flex",
    top: "2px",
  },
  tooltip: {
    fontSize: "16px",
  },
  info: {
    height: "15px",
    opacity: "0.5",
  },
});

// TODO: refactoring
export const MetricsTableRow = ({ metric, isRental }) => {
  const classes = useMetricsTableRowStyles();

  const showSuffix =
    isRental && metric.metricType !== "Median Sale Price (12 months)";

  return (
    <tr className={classes.metricRow}>
      <td className={classes.metric}>
        <span>{metric.metricType}</span>
        <span className={classes.tooltipWrapper}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={`${metric.metricTypeDescription}. Data supplied by RP Data current as of ${metric.seriesDataList[0].dateTime}.`}
            aria-label="tooltip"
          >
            <img className={classes.info} src={infoIcon} alt="" />
          </Tooltip>
        </span>
      </td>
      {metric.metricDisplayType === "Percent" && (
        <td className={classes.metric}>
          <NumberFormat
            displayType={"text"}
            value={Math.round(metric.seriesDataList[0].value * 100)}
            suffix={"%"}
          />
        </td>
      )}
      {metric.metricDisplayType === "Dollar" && (
        <td className={classes.metric}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator={true}
            value={metric.seriesDataList[0].value}
            prefix={"$"}
            suffix={showSuffix ? " p/w" : ""}
          />
        </td>
      )}
      {metric.metricDisplayType === "Number" && (
        <td className={classes.metric}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator={true}
            value={metric.seriesDataList[0].value}
          />
        </td>
      )}
    </tr>
  );
};
