export const getWidgetMetrics = ({ weeks, curr, prev, showDiff = true }) => {
  const messageEnum = {
    1: "from last 7 days",
    4: "from last month",
    12: "from last 3 months",
    24: "from last 6 months",
    48: "from last 12 months",
  };

  const period = messageEnum[weeks] || "last time";
  const isChanged = curr !== prev;
  const isUp = curr > prev;
  const diff = isUp ? curr - prev : prev - curr;

  const message = isChanged
    ? `${isUp ? "Up" : "Down"} ${showDiff ? diff : ""} ${period}`
    : "No changes";

  return { isUp, message, isChanged };
};
