import React, { useState } from "react";
import { ImageCardSelector } from "../../Common/ImageEdit";
import CropImageModal from "../../Common/ImageLibrary/components/CropImageModal";
import * as utils from "../../Common/ImageLibrary/utils";
import imageCompression from "browser-image-compression";
import { dataURLtoFile } from "../../Images/utils";

const ImageInput = ({ url, onChange, aspectRatio }) => {
  const uploadImageRef = React.useRef(null);
  const [cropModalData, setCropModalData] = useState();

  const onImageSelected =  async e => {
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) return;
    const compressedImage = await imageCompression(file, { maxSizeMB: 5 });
    if (!aspectRatio) return onChange(compressedImage);
    const imageData = await utils.getImageData(compressedImage);
    const isSizeCorrect = await utils.checkImageSize(imageData, aspectRatio);
    if (isSizeCorrect) return await onChange(compressedImage);
    setCropModalData({ img: imageData, name: file.name });
  }

  const onCropImageDone = async (file, name) => {
    setCropModalData(undefined);
    const imageData = dataURLtoFile(file, name)
    onChange(imageData)
  }

  return (
    <>
      <input
        type="file"
        accept="image/*"
        id="image-input"
        ref={uploadImageRef}
        style={{ display: "none" }}
        onChange={onImageSelected}
      />
      <ImageCardSelector
        src={url}
        onClick={() => uploadImageRef.current.click()}
      />
      <CropImageModal
        show={!!cropModalData}
        data={cropModalData}
        onClose={() => setCropModalData(undefined)}
        onCrop={onCropImageDone}
        aspectRatio={aspectRatio}
      />
    </>
  )
}

export default ImageInput;
