import React, { useEffect, useState } from "react";
import produce from "immer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";

import { HideTab, Tabs } from "../../../Common/CustomizedTabs";
import {
  updateMarketing,
  updateArticleBody,
} from "../../../../../redux/reducers/appraisalReducer";
import EditorComponent from "../../../Common/EditorComponent";
import MobileTabs from "../../../Common/MobileTabs";
import { replaceKeysOfObject } from "../../../Common/Helpers";
import { articles } from "../../../../assets/constants/articles";
import useTrackEvent from "../../../../hooks/useTrackEvent";
import useArticles from "../../../../hooks/useArticles";
import { YoutubeVideo } from "../../CommonComponents/YoutubeVideo";
import ArticlesService from "../../../../api/ArticlesService";
import localMarketing from "../../../../assets/images/marketing/local.jpeg";
import digitalMarketing from "../../../../assets/images/marketing/digital.jpeg";
import databaseMarketing from "../../../../assets/images/marketing/database.jpeg";
import printMarketing from "../../../../assets/images/marketing/print.jpeg";
import visualsMarketing from "../../../../assets/images/marketing/visuals.jpeg";

import { getImageUrl } from "shared";
import {useNz} from "../../../../hooks/useNz";

const mediaAssets = {
  "social-media": (
    <YoutubeVideo video={"https://www.youtube.com/watch?v=wMRvOK5PerQ"} />
  ),
  digital: <img src={digitalMarketing} alt="Digital" />,
  local: <img src={localMarketing} alt="Local" />,
  visuals: <img src={visualsMarketing} alt="Visuals" />,
  database: <img src={databaseMarketing} alt="Database" />,
  print: <img src={printMarketing} alt="Print" />,
};

const getMedia = ({ tabImage, title }) => {
  if (!tabImage) return null;
  const src = getImageUrl(tabImage);
  return <img src={src} alt={title} />;
};

const useStyles = makeStyles(theme => ({
  tabContent: {
    marginTop: 10,
    marginBottom: 10,
    "& .mce-edit-focus": {
      maxHeight: "100% !important",
    },
    "& img": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const TabContent = ({ description, onChange, media, className }) => (
  <Grid container spacing={3} className={className}>
    <Grid item xs={12} sm={7}>
      <EditorComponent
        type="methodOfSale"
        inline
        value={description}
        onBlur={onChange}
        charactersLimit={1000}
        hardLimit
      />
    </Grid>
    <Grid item xs={12} sm={5}>
      {media}
    </Grid>
  </Grid>
);

const MarketingTypes = ({ isCommercial }) => {
  const [activeTab, setActiveTab] = useState("social-media");
  const [articlesTitles, setArticlesTitles] = useState({});
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const classes = useStyles();
  const { isNz } = useNz();
  const marketingTypes = useSelector(
    ({ appraisal }) => appraisal.marketing.types,
    shallowEqual,
  );
  const office = useSelector(({ appraisal }) => appraisal.team, shallowEqual);

  useEffect(() => {
    ArticlesService.getMarketingOfficeArticles(office.office).then(res => {
      const response = res.data || {};
      const articles = {};
      response.forEach(article => (articles[article.slug] = article.title));
      setArticlesTitles(articles);
    });
  }, [office.office]);

  const marketingArticles = useArticles({ marketing: true }).marketing;

  let marketingMediaAssets = mediaAssets;
  if (office.officeName === "Raine & Horne Bathurst") {
    marketingMediaAssets = {
      ...mediaAssets,
      "social-media": (
        <YoutubeVideo video={"https://www.youtube.com/watch?v=5oAaGxGxpfg"} />
      ),
    };
  }
  if (isNz) {
    marketingMediaAssets = {
      ...mediaAssets,
      "social-media": (
          <YoutubeVideo video={"https://www.youtube.com/watch?v=Q46IXFFq89g"} />
      ),
    };
  }
  if (isCommercial) {
    marketingMediaAssets = {
      ...mediaAssets,
      "social-media": (
        <img src={visualsMarketing} alt="Visuals" />
      ),
    };
  }

  useEffect(() => {
    if (!marketingTypes?.socialMedia.hidden) {
      setActiveTab("social-media");
      return;
    }
    if (marketingTypes?.socialMedia.hidden && !marketingTypes?.digital.hidden) {
      setActiveTab("digital");
      return;
    }
    if (marketingTypes?.digital.hidden && !marketingTypes?.local.hidden) {
      setActiveTab("local");
      return;
    }
    if (marketingTypes?.local.hidden && !marketingTypes?.visuals?.hidden) {
      setActiveTab("visuals");
      return;
    }
    if (marketingTypes?.visuals?.hidden && !marketingTypes?.database.hidden) {
      setActiveTab("database");
      return;
    }
    if (marketingTypes?.database.hidden) {
      setActiveTab("print");
    }
  }, [marketingTypes]);

  const onMarketingUpdate = (tabName, field, val) => {
    const newTypesList = produce(marketingTypes, draft => {
      draft[tabName][field] = val;
    });
    dispatch(updateMarketing({ types: newTypesList }));
  };

  const onUpdateArticleBody = (id, body) => {
    if (!id) return;
    dispatch(updateArticleBody({ id, body }));
  };
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const activeArticle =
    marketingArticles?.find(a => a.slug === activeTab) || {};

  const changeMobileTab = article => {
    setActiveTab(currentValue =>
      currentValue === article.slug ? null : article.slug,
    );
    trackEvent("Method of Sale tab click", article.title);
  };

  const ActiveTab = ({ article }) => (
    <TabContent
      description={article.body}
      media={getMedia(article) || mediaAssets[activeTab]}
      onChange={val => onUpdateArticleBody(article._id, val)}
      className={classes.tabContent}
    />
  );

  const reorderArticlesForMobile = items => {
    const newItems = [];
    articles.marketing.sales.forEach(newItem => {
      items.forEach(item => {
        if (item.slug === newItem) {
          newItems.push(item);
        }
      });
    });
    return newItems;
  };

  const hiddenMobileTabs = replaceKeysOfObject(marketingTypes, {
    socialMedia: "social-media",
  });

  if (isMobile)
    return (
      <MobileTabs
        active={activeTab}
        onChange={changeMobileTab}
        articles={reorderArticlesForMobile(marketingArticles || []) || []}
        body={data => <ActiveTab article={data} />}
        hiddens={hiddenMobileTabs}
      />
    );

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "on" : "auto"}
        centered={!isMobile}
      >
        <HideTab
          label={
            <div>
              {
                (articlesTitles["social-media"] || "Social Media")?.split(
                  " ",
                )?.[0]
              }
              <span className={classes.hideOnMobile}>
                {` ${(articlesTitles["social-media"] || "Social Media")?.split(
                  " ",
                )?.[1] || ""}`}
              </span>
            </div>
          }
          hide={marketingTypes?.socialMedia.hidden}
          onHideChange={val => onMarketingUpdate("socialMedia", "hidden", val)}
          value="social-media"
          onClick={() => trackEvent("Marketing tab click", "Social Media")}
        />
        <HideTab
          label={articlesTitles?.digital || "Digital"}
          hide={marketingTypes?.digital.hidden}
          onHideChange={val => onMarketingUpdate("digital", "hidden", val)}
          value="digital"
          onClick={() => trackEvent("Marketing tab click", "digital")}
        />
        <HideTab
          label={articlesTitles?.local || "Local"}
          hide={marketingTypes?.local.hidden}
          onHideChange={val => onMarketingUpdate("local", "hidden", val)}
          value="local"
          onClick={() => trackEvent("Marketing tab click", "Local")}
        />
        <HideTab
          label={articlesTitles?.visuals || "Visuals"}
          hide={marketingTypes?.visuals?.hidden}
          onHideChange={val => onMarketingUpdate("visuals", "hidden", val)}
          value="visuals"
          onClick={() => trackEvent("Marketing tab click", "Visuals")}
        />
        <HideTab
          label={articlesTitles?.database || "Database"}
          hide={marketingTypes?.database.hidden}
          onHideChange={val => onMarketingUpdate("database", "hidden", val)}
          value="database"
          onClick={() => trackEvent("Marketing tab click", "Database")}
        />
        <HideTab
          label={articlesTitles?.print || "Print"}
          hide={marketingTypes?.print.hidden}
          onHideChange={val => onMarketingUpdate("print", "hidden", val)}
          value="print"
          onClick={() => trackEvent("Marketing tab click", "Print")}
        />
      </Tabs>
      <TabContent
        description={activeArticle.body}
        media={getMedia(activeArticle) || marketingMediaAssets[activeTab]}
        onChange={val => onUpdateArticleBody(activeArticle._id, val)}
        className={classes.tabContent}
      />
    </div>
  );
};

export default MarketingTypes;
