import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getShortProposalsList,
  getIsLoadingShortProposalList,
  getShortProposalsCount,
} from "redux/selectors/proposalsSelector";
import { getWeeks } from "redux/selectors/dashboardSelector";
import ProposalActionMenu from "app/components/ProposalActionMenu";
import Button from "app/components/ui/Buttons/Button";
import PaginationConsecutive from "app/components/PaginationConsecutive";
import Loader from "app/components/ui/Loader";
import GridLinksList from "app/components/GridLinksList";
import { NoData } from "app/components/NoData";
import { OPENED, UNOPENED } from "app/constants/proposalsConstants";

import { useGetUser } from "redux/hooks";

const ITEMS_ON_DASHBOARD = 5;

const ListItems = ({ appraisalStatus, viewAllLink, serviceAction }) => {
  const { isCorporateUser } = useGetUser();
  const weeks = useSelector(getWeeks);

  const appraisals = useSelector(getShortProposalsList);
  const totalItems = useSelector(getShortProposalsCount) || ITEMS_ON_DASHBOARD;
  const totalPages = Math.ceil(totalItems / ITEMS_ON_DASHBOARD);


  const isLoading = useSelector(getIsLoadingShortProposalList);

  const [page, setPage] = useState(1);


  useEffect(() => {
    setPage(1);
  }, [appraisalStatus]);

  useEffect(() => {
    serviceAction(ITEMS_ON_DASHBOARD, page, weeks);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalStatus, page, weeks]);

  const archiveHandler = () => {
    serviceAction(ITEMS_ON_DASHBOARD, page, weeks);
  };

  const noDataText =
    appraisalStatus === UNOPENED
      ? "Great job! You are up to date. All proposals sent, have been opened."
      : "No available items";

  const itemsData = appraisals.map(appraisal => ({
    id: appraisal.id,
    link: `/proposals/${appraisal.id}/edit`,
    mainData: [
      {
        content: (
          <div className="d-flex space-between">
            <span className="hover-highlighted">{appraisal.displayAddres}</span>
            {isCorporateUser && <span>{appraisal.officeName}</span>}
          </div>
        ),
      },
      {
        // TODO
        className: `item__label ${appraisal.status === UNOPENED ?  UNOPENED : OPENED}`,
        content: appraisal.status === UNOPENED ?  UNOPENED : OPENED,
      },
      {
        content: appraisal.appraisalType,
      },
      {
        className: "item__centered",
        content: appraisal.sentAt,
      },
    ],
    rowTail: (
      <ProposalActionMenu
        rowId={appraisal.id}
        archiveHandler={archiveHandler}
      />
    ),
  }));

  return (
    <div className="appraisals-list-wrapper">
      {isLoading && <Loader />}
      {!itemsData.length && !isLoading ? (
        <NoData>{noDataText}</NoData>
      ) : (
        <>
          <GridLinksList content={itemsData} isLoading={isLoading} />
          <div className="list-footer layout-side-padding">
            {appraisals.length > 0 && (
              <>
                <Button variant="outlined-blue">
                  <Link to={viewAllLink}>View all</Link>
                </Button>
                <PaginationConsecutive
                  totalPages={totalPages}
                  totalItems={totalItems}
                  page={page}
                  setPage={setPage}
                  paginationStep={ITEMS_ON_DASHBOARD}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ListItems;
