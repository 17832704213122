import React, { useState, useEffect } from "react";
import { Modal, ModalActions } from "app/components/Modal";
import { makeStyles } from "@material-ui/core";
import ImageCard from "./ImageCard";
import { getIcons } from "./utils";

const useStyles = makeStyles(() => ({
  imageContainer: {
    maxWidth: "400px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "600px",
    minHeight: "150px",
    overflow: "auto",
  },
}));

const IconModal = ({
  show,
  onHide,
  onApply,
  type,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const classes = useStyles();
  const iconsOptions = getIcons();

  useEffect(() => {
    setSelectedImage(type)
  }, [type])

  const handleApply = (selectedImage) => {
    onApply(selectedImage);
    setSelectedImage(null);
  }

  const handleCancel = () => {
    setSelectedImage(null);
    onHide();
  }

  return (
    <Modal open={show} onClose={onHide} title="Select infographic icons">
      <div
        className={classes.imageContainer}
      >
        {Object.keys(iconsOptions).map(image => (
          <ImageCard
            key={image}
            url={iconsOptions[image]}
            name={image}
            active={selectedImage === image}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
      <ModalActions
        onCancel={handleCancel}
        onSubmit={() => handleApply(selectedImage)}
        submitTitle="Apply"
      />
    </Modal>
  );
};

export default IconModal;
