import React, { useContext } from "react";

import { Box } from "@material-ui/core";
import BlockContainer from "../../AppraisalDetails/BlockContainer";
import HideContainer from "../../components/HideContainer";
import { AppraisalContext } from "../../AppraisalContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateCommercialField } from "../../../../../redux/reducers";
import EditorComponent from "../../../Common/EditorComponent";
import Infographics from "../Infographics";
import DownloadLink from "./DownloadLink";

const SaleLeaseProcess = ({ id = null, infographicsFolders, withInfographicsSelection }) => {
  const { preview } = useContext(AppraisalContext);
  const dispatch = useDispatch();

  const saleLeaseProcess = useSelector(
    ({ appraisal }) => appraisal.commercial.saleLeaseProcess, shallowEqual
  ) || {};
  const updateSaleLeaseProcess = newData => {
    const data = { ...saleLeaseProcess, ...newData };
    dispatch(updateCommercialField({ data, name: "saleLeaseProcess" }));
  };
  const onInfographicsUpdate = newInfographics => {
    const data = { ...saleLeaseProcess, infographics: newInfographics };
    dispatch(updateCommercialField({ data, name: "saleLeaseProcess" }));
  };

  const onHideChange = val => updateSaleLeaseProcess({ hidden: val });
  const onTitleUpdate = val => updateSaleLeaseProcess({ title: val });
  const onArticleUpdate = val => updateSaleLeaseProcess({ body: val });
  const onDownloadChange = (url, title) => updateSaleLeaseProcess({ downloadLink: url,  buttonTitle: title });
  const onButtonHideChange = val =>
    updateSaleLeaseProcess({ isButtonHidden: val });

  return (
    <HideContainer value={saleLeaseProcess.hidden} onChange={onHideChange}>
      <BlockContainer
        id={id || "commercial-saleLeaseProcess"}
        defaultTitle={saleLeaseProcess.title}
        onTitleUpdate={onTitleUpdate}
        hide={saleLeaseProcess.hidden}
        withoutSubTitle
      >
        <Box mt={5}>
          <EditorComponent
            inline
            value={saleLeaseProcess.body}
            onBlur={onArticleUpdate}
            disabled={preview}
            charactersLimit={1100}
          />
          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <HideContainer
              title="Hide button"
              value={saleLeaseProcess.isButtonHidden || false}
              onChange={onButtonHideChange}
            >
              <DownloadLink
                url={saleLeaseProcess.downloadLink}
                onChange={onDownloadChange}
                title={saleLeaseProcess.buttonTitle}
              />
            </HideContainer>
          </Box>
        </Box>
        <Infographics
          className="mt-4"
          infographics={saleLeaseProcess.infographics}
          onChange={onInfographicsUpdate}
          infographicsFolders={infographicsFolders}
          withInfographicsSelection={withInfographicsSelection}
        />
      </BlockContainer>
    </HideContainer>
  );
};


export default SaleLeaseProcess;
