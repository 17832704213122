import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getIsLoadingProposalsListWidgets,
  getProposalsTotal,
  getClicksPercent,
  getAverageReadingTime,
  getAverageOpens,
  getIsLoadingProposalsList,
} from "redux/selectors/proposalsSelector";
import { fetchProposalsListWidgets } from "redux/actions";
import Loader from "app/components/ui/Loader";
import Widget from "app/components/Widgets/Widget";
import SvgIcon from "app/components/ui/SvgIcon";

import { averageOpensTooltip } from "shared/constants";
import { clickRateTooltip } from "shared/constants";

import "./styles.scss";
import { convertSecToFormattedStr } from "shared";
import { getWeeks } from "../../../../redux/selectors";
import { getProposalListWidgetLabel } from "shared/utils";

const ProposalsWidget = ({ activeTab }) => {
  const dispatch = useDispatch();
  const weeks = useSelector(getWeeks);

  useEffect(() => {
    dispatch(fetchProposalsListWidgets());
  }, [dispatch, weeks]);

  const isLoadingWidgets = useSelector(getIsLoadingProposalsListWidgets);
  const isLoadingProposalsTotal = useSelector(getIsLoadingProposalsList);
  const proposals = useSelector(getProposalsTotal) || "";
  const proposalListWidgetLabel = getProposalListWidgetLabel(activeTab);
  const clicksPercent = useSelector(getClicksPercent);
  const readingTime = useSelector(getAverageReadingTime);
  const averageOpens = useSelector(getAverageOpens);

  const formattedTime = convertSecToFormattedStr(readingTime);

  return (
    <div className="proposals-list-widget layout-side-padding">
      {isLoadingWidgets || isLoadingProposalsTotal ? (
        <Loader />
      ) : (
        <>
          <Widget
            label={proposals}
            variant="yellow"
            title={proposalListWidgetLabel}
          />
          <Widget
            variant="grey spacing-25"
            label={`${clicksPercent}%`}
            title="Click through rate"
            icon={
              <SvgIcon iconName="questionCircle" tooltip={clickRateTooltip} />
            }
          />
          <Widget
            variant="size-55 grey spacing-25"
            label={formattedTime}
            title="Average reading time"
          />
          <Widget
            variant="size-55 grey"
            label={averageOpens}
            title="Average proposal clicks"
            icon={
              <SvgIcon
                iconName="questionCircle"
                tooltip={averageOpensTooltip}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default ProposalsWidget;
