import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { useCommercial } from "app/hooks";

const theme = isCommercial =>
  createMuiTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
      typography: {
        fontFamily: "RaineHorneLight",
      },

      palette: {
        primary: {
          main: isCommercial ? "#ffd200" : "#FFB300",
        },
        secondary: {
          main: "rgba(60, 60, 61, 0.5)",
        },
        error: {
          main: "#f018a6",
        },
        border: {
          main: "rgba(60, 60, 61, 0.1)",
          gray: "#E0E3E7",
        },
        background: {
          gray1: "#454748",
          gray2: "#EEF1F5",
          gray3: "#F6F8FB",
        },
      },

      /**
       * @see https://material-ui.com/customization/globals/#default-props
       */
      props: {
        MuiButtonBase: {
          disableRipple: false,
        },
        MuiPopover: {
          elevation: 1,
        },
      },
    },
  );

export default function MaterialThemeProvider(props) {
  const { children } = props;
  const { isCommercial } = useCommercial()

  return <ThemeProvider theme={theme(isCommercial)}>{children}</ThemeProvider>;
}
