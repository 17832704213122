import React from 'react';
import { useSelector } from 'react-redux';
import Widget from 'app/components/Widgets/Widget';
import {
  getIsLoadSessions,
  getProposalOpens,
  getProposalFirstOpen,
  getProposalLatestOpen,
  getSessionsDurationTotal,
  getSessionsAverageReadingTime,
  getProposalPrints,
  getIsActionButtonClicked,
} from 'redux/selectors/metricsSelector';
import SvgIcon from 'app/components/ui/SvgIcon';
import Loader from 'app/components/ui/Loader';

import './styles.scss';

const MetricsWidget = () => {
  const isLoad = useSelector(getIsLoadSessions);
  const opens = useSelector(getProposalOpens);
  const firstOpen = useSelector(getProposalFirstOpen);
  const latestOpen = useSelector(getProposalLatestOpen);
  const sessionsDurationTotal = useSelector(getSessionsDurationTotal);
  const averageReadingTime = useSelector(getSessionsAverageReadingTime);
  const proposalPrints = useSelector(getProposalPrints);
  const isActionButtonClicked = useSelector(getIsActionButtonClicked);

  const averageReadingSubTitle = sessionsDurationTotal.length > 6 ?
    `Total ${sessionsDurationTotal} (hh:mm:ss)` : `Total ${sessionsDurationTotal} (mm:ss)`;

  const checkOpened = () => {
    if (opens) {
      return (
        <div className="green-tick-label">
          <SvgIcon iconName="checkMarkGreen" />
        </div>
      );
    } else {
      return (
        <div className="alert-label">
          <span>!</span>
        </div>
      );
    }
  };

  const checkIsActionButtonClicked = () => {
    if (isActionButtonClicked) {
      return (
        <div className="green-tick-label">
          <SvgIcon iconName="checkMarkGreen" />
        </div>
      );
    } else {
      return (
        <div className="alert-label">
          <SvgIcon iconName="crossWhite" />
        </div>
      );
    }
  }

  return (
    <div className="layout-side-padding">
      <div className="widget-wrapper">
        {isLoad ? <Loader /> :
          <div className="widget-inner">
            <Widget
              label={opens}
              title="Opens"
              subtitle={opens ? `Latest ${latestOpen}` : ' '}
              variant={opens ? 'yellow' : 'red'}
            />
            <Widget
              label={averageReadingTime}
              title="Average read time"
              subtitle={averageReadingSubTitle}
              variant="size-55 grey spacing-25"
            />
            <Widget
              label={proposalPrints}
              title="Prints"
              subtitle=" "
              variant="size-60 grey"
            />
            <Widget
              label={checkOpened()}
              title={opens ? 'Opened' : 'Unopened'}
              subtitle={opens ? `${firstOpen.date} at ${firstOpen.time}` : 'Follow up with client'}
              variant="size-60"
            />
            <Widget
              label={checkIsActionButtonClicked()}
              title="Call to action button clicked?"
              subtitle={isActionButtonClicked ? 'Follow up, next steps' : 'Waiting'}
              variant="size-60"
            />
          </div>
        }
      </div>
    </div>
  );
};

export default MetricsWidget;
