import React from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

const SuccessModal = props => {
  const useStyles = makeStyles(() => ({
    modalBody: {
      display: "flex",
      flexDirection: "column",
    },
    bodyTitle: {
      textTransform: "uppercase",
      fontSize: "22px",
      marginBottom: "10px",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
    },
    bodyText: {
      display: "flex",
      justifyContent: "center",
      fontSize: "14px",
      textAlign: "center",
      marginBottom: "20px",
    },
    closeButton: {
      margin: "auto",
    },
    image: {
      width: "320px",
      margin: "auto",
    },
  }));

  const classes = useStyles();

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.modalBody}>
        <div className={classes.bodyTitle}>Success!</div>
        <div className={classes.bodyText}>{props.children}</div>
        <Button
          className={classes.closeButton}
          variant="primary"
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
