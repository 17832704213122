import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentSessionData, getIsLoadSessionData } from 'redux/selectors/metricsSelector';
import { PROPOSAL_SESSION_METRICS_TABLE } from 'app/constants/proposalsConstants';
import Loader from 'app/components/ui/Loader';

const SessionDataTable = () => {
  const isLoading = useSelector(getIsLoadSessionData);
  const sessionData = useSelector(getCurrentSessionData);
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && PROPOSAL_SESSION_METRICS_TABLE.map(property => (
        <div key={property.title} className="metrics-table">
          <h4 className="metrics-table_title">{property.title}</h4>
          <div className="metrics-table_inner">
            <div className="metrics-table_columns-title horizontal-separator">
              {property.columns.map(column => <div key={column}>{column}</div>)}
            </div>
            {sessionData[property.key].length ?
              sessionData[property.key].map((item, index) => (
                <div key={index} className="metrics-table_row horizontal-separator">
                  <div>{item[0]}</div>
                  <div>{item[1]}</div>
                </div>
              )) :
              <div className="metrics-table_row">
                No data
              </div>
            }
          </div>
        </div>
      ))}
    </>
  );
};

export default SessionDataTable;
