import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'app/components/ui/Buttons/Button';
import Send from 'app/modules/Appraisals/CreateAppraisal/Send';
import { getSendHistory } from 'redux/selectors/metricsSelector';

const SendHistory = () => {
  const [showSend, setShowSend] = useState(false);
  const sendHistory = useSelector(getSendHistory);
  return (
    <div className="unit">
      <div className="d-flex space-between">
        <h3 className="unit_header">Send History</h3>
        <Button variant="outlined-blue" onClick={() => setShowSend(true)}>
          Send again
        </Button>
        {showSend && <Send goBack={() => setShowSend(false)} fixedPositionStyle />}
      </div>
      <div className="unit-table">
        <div className="unit-table_row unit-table_row__header horizontal-separator">
          <span>Method</span>
          <span>Client</span>
        </div>
        {sendHistory && sendHistory.map(item => (
          <div key={item[0]} className="unit-table_row horizontal-separator">
            <div className="unit_item-content">
              <span className="title">{item[1].sendType}</span>
              <span className="subtitle">{item[1].date}</span>
            </div>
            <div className="unit_item-content">
              <span className="title">{item[1].name}</span>
              <span className="subtitle">{item[1].contactInfo}</span>
            </div>
          </div>
        ))
        }
      </div>
    </div>
  );
};

export default SendHistory;
