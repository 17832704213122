import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import NumberFormat from "react-number-format";

import infoIcon from "../../../../assets/images/fi-info.svg";

const useSuburbMedianPriceStyles = makeStyles(theme => ({
  tooltipWrapper: {
    position: "relative",
    cursor: "help",
    textTransform: "capitalize",
    lineHeight: "1.5em !important",
    display: "inline-flex",
    top: "2px",
  },
  tooltip: {
    fontSize: "16px",
  },
  info: {
    height: "15px",
    opacity: "0.5",
  },
  chartMetrics: {
    flex: "0 0 50px",
    minHeight: "65px",
    width: "100%",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    backgroundColor: "#F7F7F7",
    boxSizing: "border-box",
    alignItems: "center",
    marginTop: "10px",
    padding: "10px",
  },
  left: {
    color: "#222020",
    textTransform: "uppercase",
    letterSpacing: "2px",
    lineHeight: 1.4,
    fontWeight: 400,
    fontSize: "14px",
  },
  right: {
    color: "#222020",
    lineHeight: 1,
    fontWeight: 300,
    fontSize: 44,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  year: {
    fontSize: "0.8em",
  },
}));

export const SuburbMedianPrice = ({ disclaimer, year, price, isRental }) => {
  const classes = useSuburbMedianPriceStyles();

  return (
    <div className={classes.chartMetrics}>
      <div className={classes.left}>
        <div>
          <span>SUBURB MEDIAN PRICE</span>
          <span className={classes.tooltipWrapper}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={disclaimer || ''}
              aria-label="tooltip"
            >
              <img className={classes.info} src={infoIcon} alt="" />
            </Tooltip>
          </span>
        </div>
        <div className={classes.year}>{year}</div>
      </div>
      <div className={classes.right}>
        <NumberFormat
          displayType="text"
          thousandSeparator
          value={price}
          prefix="$"
          suffix={isRental ? " p/w" : ""}
        />
      </div>
    </div>
  );
};
