import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  getProposalsByTypeTotalCount,
  getProposalsMetrics,
} from "redux/selectors/proposalsSelector";
import { getWeeks } from "redux/selectors/dashboardSelector";

import LeaderboardChart from "../LeaderboardChart";

import { getWidgetMetrics } from "shared/utils";
import { WidgetMetric } from "shared/components";

const Proposals = () => {
  const totalProposals = useSelector(getProposalsByTypeTotalCount);
  const weeks = useSelector(getWeeks);
  const metrics = useSelector(getProposalsMetrics);

  const { currCount, prevCount } = metrics || {};

  const metricsMessage = getWidgetMetrics({
    weeks,
    curr: currCount,
    prev: prevCount,
  });

  return (
    <div className="proposals">
      <div className="header horizontal-separator">
        <div className="total-proposals">{totalProposals}</div>
        <Box>
          <h2 className="header-title">Proposals sent by Team</h2>
          {metricsMessage && <WidgetMetric {...metricsMessage} />}
        </Box>
      </div>
      <div className="content">
        <LeaderboardChart />
      </div>
    </div>
  );
};

export default Proposals;
