import moment from 'moment';

const MILLISECONDS_IN_DAY = 86400000;

export const generateFormattedDate = (numberDayIndexes) => {
  const result = [];
  while (numberDayIndexes > -1) {
    const dateTimeStamp = Date.now() - (MILLISECONDS_IN_DAY * numberDayIndexes);
    const date = new Date(dateTimeStamp);

    let day = String(date.getDate());
    day = day.length === 1 ? `0${day}` : day;
    let month = String(date.getMonth() + 1);
    month = month.length === 1 ? `0${month}` : month;

    const fullDate = `${date.getFullYear()}-${month}-${day}`;
    const monthShortName = moment()._locale._monthsShort[date.getMonth()];
    const formattedDay = `${date.getDate()} ${monthShortName}`;
    result.push({ date: fullDate, day: formattedDay });
    numberDayIndexes--;
  }
  return result;
};
