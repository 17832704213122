import DevPlans from "../../../../assets/images/commercial/DevPlans.svg";
import Annexures from "../../../../assets/images/commercial/Annexures.svg";
import Floorplan from "../../../../assets/images/commercial/Floorplan.svg";
import Strata from "../../../../assets/images/commercial/Strata.svg";
import Zoning from "../../../../assets/images/commercial/Zoning.svg";
import TownPlanning from "../../../../assets/images/commercial/TownPlanning.svg";

export const getIcon = type => {
  const icons = { DevPlans, Annexures, Floorplan, Strata, Zoning, TownPlanning };
  return icons[type] || Annexures;
}

export const getIcons = () => {
  const icons = { DevPlans, Annexures, Floorplan, Strata, Zoning, TownPlanning };
  return icons;
}

export const defaultItems = [
  {
    type: "Floorplan",
    title: "Planning",
    url: "",
    active: true,
  },
  {
    type: "Zoning",
    title: "Zoning",
    url: "",
    active: true,
  },
  {
    type: "Strata",
    title: "Strata",
    url: "",
    active: true,
  },
  {
    type: "DevPlans",
    title: "Dev Plans",
    url: "",
    active: true,
  },
  {
    type: "TownPlanning",
    title: "Town Planning",
    url: "",
    active: true,
  },
  {
    type: "Annexures",
    title: "Annexures",
    url: "",
    active: true,
  },
]

export const defaultResidentialItems = [
  {
    type: "Annexures",
    title: "Label Link Name",
    url: "",
    active: true,
  },
  {
    type: "Annexures",
    title: "Label Link Name",
    url: "",
    active: true,
  },
  {
    type: "Annexures",
    title: "Label Link Name",
    url: "",
    active: true,
  },
  {
    type: "Annexures",
    title: "Label Link Name",
    url: "",
    active: true,
  },
  {
    type: "Annexures",
    title: "Label Link Name",
    url: "",
    active: true,
  },
  {
    type: "Annexures",
    title: "Label Link Name",
    url: "",
    active: true,
  },
]
