import React from "react";
import { Modal } from 'react-bootstrap';
import CloseIcon from '@material-ui/icons/Close';
import ImageLibrary, { selectImage } from '../index';

class ImageLibraryModal extends React.PureComponent{
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: false
    }
  }

  onChangeSelection = file => {
    this.setState({ selectedFile: file });
  }

  render() {
    const { onImageSelect, defaultFolder, officeId, aspectRatio } = this.props;
    const { selectedFile } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        centered
        dialogClassName='image-modal'
      >
        <Modal.Header className='h-55px'>
          <CloseIcon fontSize='large' onClick={this.props.onHide} className='hover-opacity-40 cursor-pointer'/>
          { !!selectedFile ? (
            <button
              type="button"
              onClick={() => selectImage(selectedFile)}
              className={`btn btn-primary font-weight-bold btn-sm`}
            >
              Use this File
            </button>
          ) : '' }
        </Modal.Header>
        <Modal.Body style={{ height: 'calc(100vh - 120px)' }} className='pt-0 pb-3'>
          <ImageLibrary
            officeId={officeId}
            onImageSelected={onImageSelect}
            defaultFolder={defaultFolder}
            onChangeSelection={this.onChangeSelection}
            aspectRatio={aspectRatio}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

export default ImageLibraryModal;
