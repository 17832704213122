import React from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import {Link, useHistory} from "react-router-dom";
import OfficesService from "../../api/OfficesService";

const CreateOffice = () => {

  const history = useHistory();

  const activateOffice = (values, setStatus) => {
    OfficesService.activateOffice(values)
      .then(() => {
        history.push("/offices");
      })
      .catch(() => {
        setStatus("something went wrong");
      });
  };

  const Schema = Yup.object().shape({
    token: Yup.string().required("token is required").length(40)
  });

  const getInputClasses = fieldName => {
    if (formik.touched[fieldName]) {
      return formik.errors[fieldName] ? "is-invalid" : "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      activateOffice(values, setStatus, setSubmitting)
    },
    enableReinitialize: true,
  });

 return (
     <div style={{ height: "100%", width: "100%" }}>
        <form
          className="card card-custom card-stretch"
          onSubmit={formik.handleSubmit}
        >
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Activate Office
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                Please enter office token
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={formik.isSubmitting}
              >
                Activate
              </button>
              <div>
                <Link to="/offices" className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
          <div className="form">
            {/* begin::Body */}
            <fieldset>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Office token
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      placeholder="Office token"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "token",
                      )}`}
                      name="token"
                      {...formik.getFieldProps("token")}
                    />
                    {formik.touched.token && formik.errors.token ? (
                      <div className="" style={{ color: "red" }}>
                        {formik.errors.token}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </form>
     </div>

 )
}

export default CreateOffice
