import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, MenuItem,
    Select,
} from "@material-ui/core";
import React from "react";

const AddSecondAgentModal = ({ secondAgent, onHide, show, teamLeaders, onSelect }) => {
  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Select the second Agent</DialogTitle>
      <DialogContent>
        <FormControl fullWidth size="medium" style={{ width: 300 }}>
          <InputLabel htmlFor="second-agent">Second Agent</InputLabel>
          <Select
            value={secondAgent}
            onChange={onSelect}
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {teamLeaders.map(item => (
              <MenuItem value={item.leader_id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddSecondAgentModal;
