import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(() => ({
  accordion: {
    boxShadow: "none !important",
    borderRadius: "5px",
    border: "none",
    margin: "0 !important",
    marginBottom: "5px !important",
    "&:before": {
      content: "none",
    },
    "&>.MuiButtonBase-root": {
      minHeight: 48,
      height: 48,
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: "#EEF1F5",
    marginLeft: "-2.5rem",
    marginRight: "-2.5rem",
    paddingLeft: "2.5rem",
    paddingRight: "2.5rem",
  },
}));

const MobileTabs = ({ active, onChange, articles, body, hiddens }) => {
  const classes = useStyles();
  const changeTab = article => {
    onChange(article);
  };
  return (
    <div>
      {articles.map(article => {
        return hiddens[article.slug]?.hidden ? (
          ""
        ) : (
          <Accordion
            key={article._id}
            expanded={active === article.slug}
            onChange={() => changeTab(article)}
            classes={{ root: classes.accordion }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${article.slug}-content`}
              id={`panel${article.slug}-content`}
              classes={{ root: classes.header }}
            >
              <Typography>{article.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0">{body(article)}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
export default MobileTabs;
