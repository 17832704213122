import React from 'react';
import LeaderboardChart from '../LeaderboardChart';

import './styles.scss';

const Proposals = () => (
  <div className="proposals horizontal-separator">
    <h2 className="header-title" style={{ marginBottom: '27px' }}>
      Proposals sent by Type
    </h2>
    <LeaderboardChart />
  </div>
);

export default Proposals;
