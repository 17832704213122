export const getOfficeId = ({ auth }) => auth.user.office?._id;

export const getOfficeName = ({ common }) => {
  return common.officeData.name?.replace("Raine & Horne ", "");
};

export const getPopularArticles = ({ common }) =>
  common.popularArticles.articles || [];

export const getPopularArticlesPages = ({ common }) =>
  common.popularArticles.pages;

export const getIsLoadingPopularArticles = ({ common }) =>
  common.isLoadingPopularArticles;
