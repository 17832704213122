import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";

const MenuAction = ({actions}) => {
  return (
    <Dropdown className="dropdown-inline">
      <Dropdown.Toggle as={DropdownCustomToggler}>
        <i className="ki ki-bold-more-hor" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-left">
        {actions.map( action => (
          <ul className="navi navi-hover" key={action.title}>
            <li className="navi-item">
              <div className="navi-link cursor-pointer" onClick={action.onClick}>
                {action.title}
              </div>
            </li>
          </ul>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default React.memo(MenuAction);
