import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import React, { useContext } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import produce from 'immer';
import SVG from 'react-inlinesvg';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';

import { toAbsoluteUrl } from '_metronic/_helpers';
import { AppraisalContext } from '../../AppraisalContext';
import CalendarItem from './CalendarItem';
import Button from 'app/components/ui/Buttons/Button';

import { reorder } from '../../CreateAppraisal/utils';
import { ProposalTypes } from 'app/interfaces';

const Calendar = ({ calendarItems = [], onUpdate }) => {
  const removeItem = index => {
    const newItems = produce(calendarItems, draft => {
      draft.splice(index, 1);
    });
    onUpdate(newItems);
  };

  const updateItem = (val, index) => {
    const newItems = produce(calendarItems, draft => {
      draft[index] = val
    })
    onUpdate(newItems)
  }

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const items = reorder(
      calendarItems,
      result.source.index,
      result.destination.index
    );

    onUpdate(items);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {
              calendarItems.map( (item, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(provided, snapshot) => (
                      <CalendarItem
                        provided={provided}
                        item={item}
                        index={index}
                        onRemove={() => removeItem(index)}
                        onUpdate={val => updateItem(val, index)}
                        isDragging={snapshot.isDragging}
                      />
                  )}
                </Draggable>
              ))
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}



const useStyles = makeStyles(() => ({
  mobileTimeline: { // styles for mobile version
    "& li:before": {
      display: 'none',
    },
    "& .home-icon": {
      textAlign: 'start !important',
      marginLeft: '-4px !important',
    },
    "& .dollar-icon": {
      textAlign: 'start !important',
      marginLeft: '0 !important',
    },
    "& .MuiTimelineContent-root": {
      margin: '10px 0',
    },
  },
}));

const TimeLineContainer = ({ calendarItems, onUpdate }) => {
  const { preview } = useContext(AppraisalContext);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const classes = useStyles();
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  const onSortStart = () => {
    document.body.style.cursor = 'row-resize'
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    document.body.style.cursor = 'default'
    if (oldIndex !== newIndex) onUpdate(reorder(calendarItems, oldIndex, newIndex))
  }

  const addNewTimeline = () => {
    const newList = produce(calendarItems, draft => {
      draft.push({
        date: new Date(),
        text: "Next step description"
      })
    })
    onUpdate(newList)
  }

  return (
    <Timeline
      align={isMobile ? 'left' : 'alternate'}
      className={`p-0 ${preview ? 'pe-none' :''}`}
      classes={{ alignLeft: classes.mobileTimeline }}
    >
      <div className="m-2 text-center home-icon">
        {appraisalType === ProposalTypes.prospecting ? (
          <img src={toAbsoluteUrl("/media/User.jpg")} alt={"user"} />
        ) : (
          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home1.svg")} />
        )}
      </div>
      <Calendar
        calendarItems={calendarItems}
        onUpdate={onUpdate}
        distance={2}
        onSortEnd={onSortEnd}
        onSortStart={onSortStart}
      />
      <div className="m-2 text-center dollar-icon">
        {appraisalType === ProposalTypes.prospecting ? (
          <img src={toAbsoluteUrl("/media/sign.jpg")} alt={'sign'}/>
        ) : (
          <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Dollar.svg")} />
        )}
      </div>

      { !preview && (
        <div className="mt-10 text-center">
          <Button
            variant="outlined"
            onClick={addNewTimeline}
          >
            Add New Timeline Item
          </Button>
        </div>
      )}
    </Timeline>
  )
}

export default TimeLineContainer;
