import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  isLoadingMessagesList: true,
  messagesList: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState: defaultState,
  reducers: {
    setIsLoadingMessagesList: (state, action) => {
      state.isLoadingMessagesList = action.payload;
    },
    setMessagesList: (state, action) => {
      state.messagesList = action.payload;
    },
    updateMessagesListByType: (state, action) => {
      state.messagesList = {
        ...state.messagesList,
        messages: action.payload.messages,
        pages: action.payload.pages,
        total: action.payload.total,
      };
    },
    updateMessage: (state, action) => {
      const { index, message } = action.payload;
      state.messagesList.messages[index] = message;
    },
    decreaseTotalUnreadedMessages: (state, action) => {
      state.messagesList.totalUnreaded = state.messagesList.totalUnreaded - 1;
    },
    updateUnreadedMessagesList: (state, action) => {
      const newUnreadedMessagesList = state.messagesList.unreadedMessages?.filter(
        message => message._id !== action.payload.message?._id,
      );
      state.messagesList.unreadedMessages = newUnreadedMessagesList;
    },
  },
});

export const {
  setIsLoadingMessagesList,
  setMessagesList,
  updateMessage,
  decreaseTotalUnreadedMessages,
  updateUnreadedMessagesList,
  updateMessagesListByType,
} = messagesSlice.actions;

export default messagesSlice.reducer;
