import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Button, MobileStepper, makeStyles } from "@material-ui/core";
import { fetchReadAllMessages } from "redux/actions";
import moment from "moment-timezone";
import { Message } from "../../interfaces";
import { RootState } from "redux/store";
import { Modal } from "../../components/Modal/index";
import icon from "../../assets/images/bell.svg";
import MessageContent from "./MessageContent";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "488px",
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
  titleBlock: {
    padding: "32px 32px 0 32px",
  },
  icon: {
    marginBottom: "5px",
  },
  title: {
    fontSize: "32px",
    lineHeight: "36px",
  },
  closeButton: {
    top: "32px",
    right: "32px",
    border: "none",
  },
  messageInfo: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0 32px 5px 32px",
  },
  messageContent: {
    maxHeight: "300px",
    overflowY: "auto",
  },
  nextButton: {
    width: "56px",
    color: "#FBFBFB",
    background: "#FDB818",
    "&:hover": {
      background: "#E2A516",
    },
  },
  backButton: {
    width: "56px",
    color: "#5A5A5A",
    background: "#F4F4F4",
    marginRight: "8px",
  },
  submitButton: {
    width: "191px",
    color: "#5A5A5A",
    background: "#F4F4F4",
    textTransform: "none",
  },
  button: {
    padding: "12px 16px",
    height: "40px",
    fontWeight: "bold",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "16px",
  },
  stepper: {
    justifyContent: "flex-end",
    background: "inherit",
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 20px",
  },
  modal: {
    maxHeight: "100%",
  },
  timeBlock: {
    display: "flex",
    marginTop: "10px",
  },
});

interface MessagesSliderProps {
  messages: Array<Message>;
  open: boolean;
  onClose: () => void;
}

const MessagesSlider = ({ open, messages, onClose }: MessagesSliderProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const officeSpeciality = useSelector(
    (state: RootState) => state.auth?.user?.office?.speciality,
    shallowEqual,
  );
  const userId = useSelector(
    ({ auth }: RootState) => auth.user._id,
    shallowEqual,
  );

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const steps = messages?.map(message => {
    return (
      <div>
        <MessageContent
          data={message}
          onHandleMessage={() => null}
          bodyClass={classes.messageContent}
          isUnread={false}
        />
        <div className={classes.timeBlock}>
          <AccessTimeIcon style={{ margin: "0 10px 0 0", color: "#707070" }} />
          <span>{moment(message.created_at).format("DD MMM")}</span>
        </div>
      </div>
    );
  });
  const maxSteps = steps?.length;

  const onSubmit = () => {
    const newMessages = messages?.map(message => {
      return {
        ...message,
        seen: [...message?.seen, userId],
      };
    });
    dispatch(
      fetchReadAllMessages({
        newMessages,
        speciality: officeSpeciality,
      }),
    );
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        buttonClassname={classes.closeButton}
        classes={{ paper: classes.modal }}
      >
        <div className={classes.container}>
          <div>
            <div className={classes.titleBlock}>
              <div className={classes.icon}>
                <img src={icon} alt="icon"></img>
              </div>
              <div className={classes.title}>
                <div
                  style={{ color: "#FDB818" }}
                >{`${maxSteps} new updates`}</div>
                <div>while you were away</div>
              </div>
            </div>
            <hr />
          </div>
          <div className={classes.messageInfo}>{steps[activeStep]}</div>

          <div className={classes.stepperContainer}>
            <div className="d-flex align-items-center">
              <Button
                onClick={onSubmit}
                className={`${classes.submitButton} ${classes.button}`}
              >
                Acknowledge all and close
              </Button>
            </div>
            <MobileStepper
              variant="progress"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  className={`${classes.nextButton} ${classes.button}`}
                >
                  <ArrowForwardIcon />
                </Button>
              }
              backButton={
                <Button
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  className={`${classes.backButton} ${classes.button}`}
                >
                  <ArrowBackIcon />
                </Button>
              }
              classes={{ positionStatic: classes.stepper }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MessagesSlider;
