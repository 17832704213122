import React from "react";
import { makeStyles } from "@material-ui/core";
import SVG from "react-inlinesvg";

const useStyles = makeStyles( () => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: '#646A6D',
    maxWidth: 480,
    "& svg": {
      width: 60,
      maxHeight: 70,
      marginBottom: 25,
    },
    "& p": {
      fontSize: 18,
      lineHeight: '25px',
      margin: 0
    }
  }
}))

const NoItems = ({ text, src }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/*<img src={src} alt='comparables' className='mb-3'/>*/}
      <SVG src={src} className='mb-3'/>
      {text}
    </div>
  )
}

export default NoItems;

