import { createSlice } from "@reduxjs/toolkit";

import {getAppraisalTitle, formatDateStampToISO8601, getAppraisalCountry} from "shared/utils";

const defaultState = {
  isLoadSessions: true,
  isLoadSessionData: true,
  proposal: {},
  proposalTitle: "",
  agentName: "",
  leaderImageId: "",
  officeName: "",
  updated: "",
  prints: 0,
  allEvents: [],
  sessions: [],
  firstOpen: "",
  latestOpen: "",
  sessionsDurationTotalSec: 0,
  sendHistory: {},
  currentSessionData: {},
  visited: false,
  visitedChanged: null,
  visitedBy: '',
  isNZ: false,
};

const sortSessionsDate = sessions => {
  sessions.forEach(
    session => (session.created_at = Date.parse(session.created_at)),
  );
  sessions.sort((a, b) => a.created_at - b.created_at);

  let sessionsDurationTotalSec = 0;
  sessions.forEach(session => {
    session.created_at = formatDateStampToISO8601(session.created_at);
    sessionsDurationTotalSec += session.duration;
  });
  return { sessionsSortedByDate: sessions, sessionsDurationTotalSec };
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: defaultState,
  reducers: {
    setIsLoadSessions: (state, action) => {
      state.isLoadSessions = action.payload;
    },
    setIsLoadSessionData: (state, action) => {
      state.isLoadSessionData = action.payload;
    },
    setPropertyInfo: (state, action) => {
      const {
        proposal,
        agentName,
        imageId,
        officeName,
        updated,
      } = action.payload;
      state.proposal = proposal;
      state.leaderName = agentName;
      state.leaderImageId = imageId;
      state.officeName = officeName;
      state.updated = updated;
      state.visited = proposal.property.visited;
      state.visitedChanged = proposal.property.visitedChanged;
      state.visitedBy = proposal.property.visitedBy;
      state.proposalTitle = getAppraisalTitle(proposal);
      state.isNz = getAppraisalCountry(proposal);
    },
    setReportInfo: (state, action) => {
      const { all, prints, sessions } = action.payload;
      // TODO
      const mutateSessions = session => ({
        ...session,
        sessionId: session?.sessionId?.current || session?.sessionId,
      });
      if (sessions.length) {
        const {
          sessionsSortedByDate,
          sessionsDurationTotalSec,
        } = sortSessionsDate(sessions);
        state.firstOpen = sessionsSortedByDate[0].created_at;
        state.latestOpen =
          sessionsSortedByDate[sessionsSortedByDate.length - 1].created_at;
        state.prints = prints;
        state.allEvents = all;
        state.sessions = sessions.map(mutateSessions);
        state.sessionsDurationTotalSec = sessionsDurationTotalSec;
      }
    },
    setSendHistory: (state, action) => {
      state.sendHistory = action.payload;
    },
    setCurrentSessionData: (state, action) => {
      state.currentSessionData = action.payload;
    },
  },
});

export const {
  setIsLoadSessions,
  setPropertyInfo,
  setReportInfo,
  setSendHistory,
  setCurrentSessionData,
  setIsLoadSessionData,
} = reportsSlice.actions;

export default reportsSlice.reducer;
