import React, { useEffect, useState, useRef, useCallback } from "react";
 import { debounce } from "lodash";
 import {
   Box,
   FormControl,
   MenuItem,
   ListSubheader,
   TextField,
   InputAdornment,
 } from "@material-ui/core";
 import Select from "./Select";
 import SearchIcon from "@material-ui/icons/Search";
 import "./styles.scss";

 const SelectWithSearch = ({
   label,
   placeholder = "select",
   searchLabel,
   options = [],
   selectedValue = "any",
   onSearch = () => null,
   onSelect = () => null,
   withAllSelect = false,
   withSearch = false,
 }) => {
   const [searchText, setSearchText] = useState("");
   const [debounceSearch, setDebounceSearch] = useState("");

   const debounceSearchTrigger = useRef(
     debounce(searchTerm => {
       onSearch(searchTerm);
     }, 700),
   );

   useEffect(() => {
     debounceSearchTrigger.current(debounceSearch);
   }, [debounceSearch]);

   const onChangeSearch = searchValue => {
     setSearchText(searchValue);
     setDebounceSearch(searchValue);
   };

   const displayedOptions = useCallback(
     () =>
       selectedValue === "any" ? (
         <span style={{ opacity: 0.5 }}>{placeholder}</span>
       ) : (
         options.find(o => o.value === selectedValue)?.display
       ),
     [placeholder, options, selectedValue],
   );

   return (
     <Box className="select-container">
       <FormControl fullWidth>
         <div className="select-label">{label || "Options"}</div>
         <Select
           MenuProps={{ autoFocus: false }}
           value={selectedValue}
           onChange={e => {
            if(e.target.value === "all") {
              setDebounceSearch("")
            }
            return onSelect(e.target.value === "all" ? undefined : e.target.value)
           }}
           onClose={() => setSearchText("")}
           renderValue={displayedOptions}
         >
           {withSearch ? (
             <ListSubheader>
               <TextField
                 variant="outlined"
                 className="select-search"
                 value={searchText}
                 size="small"
                 autoFocus
                 placeholder={searchLabel || "Type to search..."}
                 fullWidth
                 InputProps={{
                   startAdornment: (
                     <InputAdornment position="start">
                       <SearchIcon />
                     </InputAdornment>
                   ),
                 }}
                 onChange={e => onChangeSearch(e.target.value)}
                 onKeyDown={e => {
                   if (e.key !== "Escape") {
                     e.stopPropagation();
                   }
                 }}
               />
             </ListSubheader>
           ) : (
             <div key={"withoutSearch"} />
           )}
           {withAllSelect && options?.length ? (
             <MenuItem disabled={selectedValue === "any"} value="all">
               <em>Select All</em>
             </MenuItem>
           ) : (
             <div key={"withoutAllSelect"} />
           )}
           {options.map((option, i) => (
             <MenuItem key={i} value={option.value}>
               {option.display}
             </MenuItem>
           ))}
           <MenuItem disabled value="any" />
         </Select>
       </FormControl>
     </Box>
   );
 };

 export default SelectWithSearch;