import React, { useState } from "react";
import SearchBox from "app/modules/SearchBox";
import Tabs from "app/components/Tabs";
import List from "./List";
import Filters from "./Filters";
import { useSelector } from "react-redux";
import { getWeeks, getRange } from "../../../../redux/selectors";

const TabsList = ({ onTabClick }) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({});
  const weeks = useSelector(getWeeks);
  const range = useSelector(getRange);

  const tabsData = [
    {
      button: { title: "Active/Sent" },
      component: List,
      componentProps: {
        search,
        proposalType: filter.type,
        proposalOffice: filter.office,
        proposalTeamMember: filter.teamMember,
        proposaldateRange: `${filter.dateMin || ""}-${filter.dateMax || ""}`,
        draft: false,
        archived: false,
        name: "Active/Sent",
        weeks: weeks,
        range: range,
      },
    },
    {
      button: { title: "Drafts" },
      component: List,
      componentProps: {
        search,
        proposalType: filter.type,
        proposalOffice: filter.office,
        proposalTeamMember: filter.teamMember,
        proposaldateRange: `${filter.dateMin || ""}-${filter.dateMax || ""}`,
        draft: true,
        archived: false,
        name: "drafts",
        weeks: weeks,
        range: range,
      },
    },
    {
      button: { title: "Archived" },
      component: List,
      componentProps: {
        search,
        proposalType: filter.type,
        proposalOffice: filter.office,
        proposalTeamMember: filter.teamMember,
        proposaldateRange: `${filter.dateMin || ""}-${filter.dateMax || ""}`,
        draft: false,
        archived: true,
        name: "archived",
        weeks: weeks,
        range: range,
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="proposal-list-header">
        <SearchBox
          className="layout-side-padding proposals-list-search-box"
          styles={{ margin: "40px 0px" }}
          value={search}
          onChangeSearch={value => setSearch(value)}
          additionalFilter={<Filters filter={filter} setFilter={setFilter} />}
          onCloseAdditionalFilter={() => setFilter({})}
        />
      </div>
      <Tabs data={tabsData} onTabClick={onTabClick} />
    </React.Fragment>
  );
};

export default TabsList;
