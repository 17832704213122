import React from 'react';
import Proposals from './Proposals';
import Leaders from './Leaders';

const Corporate = () => (
  <div className="leaderboard-corporate">
    <Proposals />
    <Leaders />
  </div>
);

export default Corporate;
