import React from "react";

import { useGetUser } from "redux/hooks";

import Corporate from "./Corporate";
import Office from "./Office";
import "./styles.scss";

export const LeaderBoard = () => {
  const { isCorporateUser } = useGetUser();

  return (
    <section className="leaderboard">
      {isCorporateUser ? <Corporate /> : <Office />}
    </section>
  );
};
