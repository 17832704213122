import React, { useContext } from "react";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { AppraisalContext } from "../../modules/Appraisals/AppraisalContext";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    height: '100%',
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: 20,
    transform: 'translate(0, -50%)',
    background: '#fff',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '50%',
    zIndex: 999,
    width: 30,
    height: 30,
    cursor: 'pointer',
    "&:hover": {
      borderColor: theme.palette.background.gray2,
    },
    "&>i": {
      position: 'absolute',
      top: 15,
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
}))



const ActionsWrapper = ({ children, actionList = [] }) => {
  const { preview } = useContext(AppraisalContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const onItemClick = action => {
    handleClose();
    action();
  }

  if (preview || !actionList?.length) return children;

  return (
    <div className={classes.wrapper}>
      <div onClick={handleClick} className={classes.menu}>
        <i className="ki ki-bold-more-hor" />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center'  }}
      >
        {actionList.map(item => (
          <MenuItem
            key={item.title}
            onClick={() => onItemClick(item.action)}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      {children}
    </div>
  )
};

export default ActionsWrapper;
