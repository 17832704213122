import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { PROPOSALS_BY_TYPE } from "app/constants/proposalsConstants";

const LeaderChart = ({ leader }) => {
  const canvas = useRef();
  const tooltip = useRef();

  const chartDataSet = leader.sentProposals?.map(proposal => ({
    data: [proposal.count],
    backgroundColor: PROPOSALS_BY_TYPE[proposal.type].color,
  }));

  const hideTooltip = () => {
    tooltip.current.style = "";
    tooltip.current.lastChild.style = "";
  };

  useEffect(() => {
    let chart;
    if (chartDataSet?.length) {
      const ctx = canvas.current.getContext("2d");
      chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [""],
          datasets: chartDataSet,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: "y",
          scales: {
            y: {
              stacked: true,
              display: false,
            },
            x: {
              stacked: true,
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: ({ tooltip: context }) => {
                if (context.opacity) {
                  tooltip.current.firstChild.textContent =
                    context.dataPoints[0].formattedValue;
                  tooltip.current.style.cssText = `
                    top: -25px;
                    left: ${context.caretX -
                      context.dataPoints[0].element.width / 2 -
                      10}px;
                    background: ${
                      context.dataPoints[0].dataset.backgroundColor
                    };
                    opacity: 1;`;
                  tooltip.current.lastChild.style.backgroundColor = `${context.dataPoints[0].dataset.backgroundColor}`;
                }
              },
            },
          },
        },
      });
    }
    return () => chart?.destroy();
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leader]);

  return (
    <div className="agent-proposals-chart-wrapper">
      <div className="agent-proposals-chart" onMouseLeave={hideTooltip}>
        <canvas ref={canvas} />
      </div>
      <div ref={tooltip} className="agent-proposals-chart_tooltip">
        <span className="agent-proposals-chart_tooltip-value" />
        <div className="agent-proposals-chart_tooltip-arrow" />
      </div>
    </div>
  );
};

export default LeaderChart;
