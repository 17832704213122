import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { activeStepSelector } from "./redux/selectors";
import { CreatePropertySteps } from "app/interfaces";

import SelectType from "./Steps/SelectType";
import SelectOffice from "./Steps/SelectOffice";
import SelectTeam from "./Steps/SelectTeam";
import SelectProperty from "./Steps/SelectProperty";
import SelectSuburb from "./Steps/SelectSuburb";
import StepperHeader from "./StepperHeader";
import ProposalBuilder from "../../pages/ProposalBuilder";

interface ActiveStepProps {
  activeStep: CreatePropertySteps | undefined
}
const ActiveStep = ({ activeStep }: ActiveStepProps) => {
  if (activeStep === CreatePropertySteps.SelectOffice) return <SelectOffice />
  if (activeStep === CreatePropertySteps.SelectType) return <SelectType />
  if (activeStep === CreatePropertySteps.SelectTeam) return <SelectTeam />
  if (activeStep === CreatePropertySteps.SelectProperty) return <SelectProperty />
  if (activeStep === CreatePropertySteps.SelectSuburb) return <SelectSuburb />
  if (
    activeStep === CreatePropertySteps.Builder ||
    activeStep === CreatePropertySteps.Sender
  ) {
    return <ProposalBuilder/>;
  }
  return <></>;
}

const CreateProposal = () => {
  const activeStep = useSelector(activeStepSelector);

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" height="100%">
      <StepperHeader/>
      <ActiveStep activeStep={activeStep} />
    </Box>
  )
}

export default CreateProposal;
