import axios from 'axios';
import API_URL from './api';

export default class ArticlesService {

  static getOfficeArticles = (officeId, searchParam, pageNumber, filterParam, sortParam, removeLimit) => {

    let search = searchParam || ''

    let page = pageNumber || 1

    let filter = filterParam || 'updated_at'

    let sort = sortParam || 'descending'

    let limit = removeLimit || ''


    return axios.get(
      `${API_URL}/articles?office=${officeId}&search=${search}&page=${page}&filter=${filter}&sort=${sort}&limit=${limit}`
    );
  }

  static get = id => {
    return axios.get(`${API_URL}/articles/${id}`)
  }

  static getMarketingOfficeArticles = id => {
    return axios.get(`${API_URL}/articles/office/${id}`)
  }

  static update = (id, body) => {
    return axios.put(
      `${API_URL}/articles/${id}`, body, {
      });
  }

  static delete = id => {
    return axios.delete(`${API_URL}/articles/${id}`)
  }

  static refresh = id => {
    return axios.get(`${API_URL}/articles/refresh/${id}`)
  }

}
