import React, { useContext } from "react";
import { AppraisalContext } from "../../AppraisalContext";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

const StatusIcon = ({ active, onChange }) => {
  const { preview } = useContext(AppraisalContext);
  if (preview || !onChange) return '';
  return (
    <Tooltip arrow title={
      <Typography color="inherit" component='h3'>
        {active ? 'Hide' : 'Show'}
      </Typography>
    }>
      <IconButton
        disableRipple
        aria-label="change status"
        onClick={() => onChange(!active)}
      >
        { active ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon /> }
      </IconButton>
    </Tooltip>
  )
}

export default StatusIcon;
