import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ModalContent = DialogContent;
export const ModalActions = DialogActions;

// it's a small abstraction component
// use DialogContent and DialogActions
export const Modal = ({ isOpen, onClose, title, children }) => {
  const { spacing, palette } = useTheme();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box minWidth={420}>
        <DialogTitle>
          <Box
            position="absolute"
            right={spacing(1)}
            top={spacing(1)}
            color={palette.grey[500]}
          >
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {title}
        </DialogTitle>
        {children}
      </Box>
    </Dialog>
  );
};
