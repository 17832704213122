import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useGetUser } from "redux/hooks";
import { setStep } from "./redux/actions";
import { activeStepSelector, propertyTypeSelector } from "./redux/selectors";
import { CreatePropertySteps } from "app/interfaces";
import { getSteps } from "./utils";

const useStyles = makeStyles(() => ({
  stepper: {
    "& .MuiStepLabel-active": {
      fontSize: "20px",
    },
    "& .MuiStep-completed:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
    "& .MuiStepLabel-label": {
      transition: "font-size 0.4s ease-in-out",
    },
    "& .MuiStepIcon-text": {
      fill: "#fff",
      fontSize: "1rem",
    },
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    height: "115px",
  },
}));

const StepperHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isCorporateUser } = useGetUser();
  const activeStep = useSelector(activeStepSelector);
  const propertyType = useSelector(propertyTypeSelector);

  const steps = getSteps(isCorporateUser, propertyType);
  const activeStepIndex = steps.findIndex(step => step.id === activeStep);

  const changeStep = (step: CreatePropertySteps) => {
    // possible to go only to previous steps
    if (activeStep && step < activeStep) {
      dispatch(setStep(step))
    }
  }

  return (
    <Stepper
      activeStep={activeStepIndex}
      alternativeLabel
      className={classes.stepper}
    >
      {steps.map(step => (
        <Step key={step.id}>
          <StepLabel
            onClick={() => changeStep(step.id)}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default StepperHeader;
