import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { Button } from "primereact/button";
import { shallowEqual, useSelector } from "react-redux";

const NzInfo = {
    title: 'Real Estate Authority',
    link: 'https://www.rea.govt.nz',
    phone: '0800 367 732',
    logo: 'rea.png'
}

const TopFooter = ({ stateInfo, isNz }) => (
  <Grid container spacing={2} style={{ marginTop: '40px' }} className="footer">
    <Grid item xs={12} sm={6} >
      { isNz ? (
        <p>
          Your relevant country authority provides a range of useful information to help you understand what property laws mean for you. For further information please contact:       </p>
      ) : (
        <p>
          Your relevant state authority provides a range of useful information to help you understand what property laws mean for you. For further information please contact:
        </p>
      )}

      <p>{stateInfo?.title}</p>
      <p>{stateInfo?.link}</p>
      <p>Phone: {stateInfo?.phone}</p>
    </Grid>
    <Grid item xs={12} sm={6} className='d-flex justify-content-end align-items-center'>
      {stateInfo?.logo ? (<img src={toAbsoluteUrl(`/media/state-logos/` + stateInfo?.logo)}  alt='logo'/>) : <></>} 
    </Grid>
  </Grid>
)

const Footer = ({ withoutTop = false, withoutBottom = false }) => {

  const [hidden, setHidden] = useState(true)
  let stateInfo = useSelector(({ appraisal }) => appraisal.stateInfo, shallowEqual);
  const isNz = useSelector(({ appraisal }) => appraisal.meta.website.includes('co.nz'), shallowEqual);

  // check for New Zealand
  if (isNz) {
      stateInfo = NzInfo;
  }

  return (
    <>
      { withoutTop ? '' : <TopFooter stateInfo={stateInfo} isNz={isNz} /> }
      { withoutBottom ? '' : (
        <>
        { isNz ? (
          <div className="footer" style={{marginTop: '40px', textAlign: 'justify'}}>
            <p>
              Copyright & Disclaimers*
            </p>
            <p>
              This publication reproduces materials and content owned or licenced by CoreLogic NZ Limited (CoreLogic) and may include data, statistics, estimates, indices, photographs, maps, tools, calculators (including their outputs), commentary, reports and other information (CoreLogic Data).
            </p>
            <p>
              © Copyright {new Date().getFullYear()}. CoreLogic and its licensors are the sole and exclusive owners of all rights, title and interest (including intellectual property rights) subsisting in this publication, including any data, analytics, statistics and other information contained in this publication. All rights reserved.
            </p>
            <p>
              The CoreLogic Data provided in this publication is of a general nature and should not be construed as specific advice or relied upon in lieu of appropriate professional advice.  While CoreLogic uses commercially reasonable efforts to ensure the CoreLogic Data is current, CoreLogic does not warrant the accuracy, currency or completeness of the CoreLogic Data and to the full extent permitted by law excludes all loss or damage howsoever arising (including through negligence) in connection with the CoreLogic Data.            </p>
            <p>
              This publication contains data and statistics provided by Land Information New Zealand (LINZ) and is made available from the subject property’s Record of Title provided by LINZ that has not been changed in any way by CoreLogic is not a substitute for a Record of Title.  LINZ, in its sole discretion, may require you to amend, delete the LINZ data at any time.
            </p>
          </div> ) : (
            <div className="footer" style={{marginTop: '40px', textAlign: 'justify'}}>
                <p>
                    Copyright & Disclaimers*
                </p>
                <p>
                    © Copyright {new Date().getFullYear()}. RP Data Pty Ltd trading as CoreLogic Asia Pacific (CoreLogic) and its licensors are the sole and exclusive owners of all rights, title and interest (including intellectual property rights) subsisting in this publication, including any data, analytics, statistics and other information contained in this publication. All rights reserved.
                </p>
                <p>
                    The data and information (including commentary) provided in this publication (together, Information) is of a general nature and should not be construed as specific advice or relied upon in lieu of appropriate professional advice. While CoreLogic uses commercially reasonable efforts to ensure the Information is current, CoreLogic does not warrant the accuracy, currency or completeness of the Information and to the full extent permitted by law excludes all loss or damage howsoever arising (including through negligence) in connection with the Information.
                </p>
                <p>
                    Contains property sales information provided under licence from the Land and Property Information LPI. RP Data Pty Ltd trading as CoreLogic is authorised as a Property Sales Information provider by the LPI.
                </p>
            </div>
          ) }
          { isNz ? (
            <div className={`footer copyright ${hidden ? 'hidden' : ''}`}  style={{textAlign: 'justify'}}>
              <p><strong>Website Privacy Policy NZ</strong></p>
              <p><span>This Privacy Policy applies to all personal information collected by Raine &amp; Horne Pty Ltd (ACN 003 625 634) (<strong>DigiKitPlus</strong>) via the website located at digikitplus.rh.com.au (<strong>Website</strong>).</span></p>
              <p>Raine & Horne New Zealand Pty Limited and its related bodies including (we, our, us) provides real estate services including but not limited to:</p>
              <ul className='square-li'>
                <li><span>The leasing and management of commercial, retail, industrial, short-term, holiday and residential properties; and </span></li>
                <li><span>The sale of commercial, retail, industrial, short-term, holiday and residential properties; and</span></li>
                <li><span>The acquisition of commercial, retail, industrial, short-term, holiday and residential properties; and</span></li>
                <li><span>Brokerage services.</span></li>
              </ul>
              <p><span>This policy applies to Raine & Horne New Zealand Pty Limited Raine & Horne franchised offices, and the URLS, www.raineandhorne.co.nz; rh.co.nz; rhc.co.nz; rh.group; rhcommercial.co.nz; digikitplus.rh.com.au; rhamplify.com.au and (collectively <strong>Raine & Horne</strong>). It sets out the policies of Raine & Horne relating to Personal Information provided to us, or collected by us, including through our websites. Unless you give your express consent otherwise, the following policy governs how we handle your Personal Information and safeguards your privacy. By providing your Personal Information to us you agree to this policy.</span></p>
              <p><span><strong>Note:</strong> Third-party internet sites accessible via the above-mentioned website, including websites that may be independently owned and operated by individual Raine & Horne Offices, may have separate privacy and data-collection practices not controlled by Raine & Horne. Raine & Horne takes no responsibility for those independent policies and will not be liable for any actions of third parties in relation to privacy. It is recommended that you refer to those sites’ own privacy policies to learn how they collect and use your Personal Information. </span></p>
              <p><span>The Privacy Act 2020 (the Act) allows for Personal Information as defined in the Act about you to be collected, used and disclosed provided we have provided you with notice and in certain circumstances also obtained your consent</span></p>
              <p><span>We reserve the right to review and if necessary, change this Privacy Policy. We may (from time to time) update, revise, amend or replace this Privacy Policy by posting any changes to it on our website or by giving you two weeks written notification of any change.</span></p>
              <p><span>This Privacy Policy is available on our website, at our head office and may also be made available to you when requested.</span></p>
              <p><span>We are bound by the Act and the Information Privacy Principles (IPP) which regulate the collection, use and disclosure of Personal Information.  This Privacy Policy explains:</span></p>
              <ul  className='dec-li'>
                <li><span>The scope of our Privacy Policy;</span></li>
                <li><span>Why we collect Personal Information;</span></li>
                <li><span>What Personal Information we collect;</span></li>
                <li><span>How we collect and use your Personal Information;</span></li>
                <li><span>How we disclose your Personal Information;</span></li>
                <li><span>Your right to access your Personal Information;</span></li>
                <li><span>Your right to correct your Personal Information;</span></li>
                <li><span>How we protect the integrity of your Personal Information; </span></li>
                <li><span>Your right to make a privacy complaint; and</span></li>
                <li><span>How you can contact us regarding privacy concerns.</span></li>
              </ul>
              <p><span><strong>What Personal Information we collect:</strong></span></p>
              <p><span>We collect Personal Information that is reasonably necessary for one or more of its functions or activities.  Generally, the type of Personal Information collected by us includes your name, address, telephone numbers, email addresses, payment details including credit card and debit card details.  We may ask for your date of birth although it will be optional for you to provide these details.</span></p>
              <p><span>From time to time, photos may be taken and used for marketing and promotional purposes which may include images of franchised businesses, franchisees, employees or employees of franchisees and/or customers, from which individuals may be able to be identified.  If you are an employee of Raine & Horne your photo may be used within Raine & Horne to identify you, including by being electronically attached to your email address.</span></p>
              <p><span>Via Social Media Sites, we may be able to obtain other information about you which may itself constitute Personal Information or which, when combined with other information, is capable of identifying you, including photos, videos and any other information that you include on our Social Media Sites or sites linked to our Social Media Sites (“Other Sites”).  The level of information to which we have access may depend on your privacy settings on such Other Sites.</span></p>
              <p><span>The type of information that we collect, and hold may depend on your relationship with us. For example: </span></p>
              <p><span><strong>Candidate:</strong> if you are a candidate seeking employment with us, we may collect and hold information including your name, address, email address, contact telephone number, gender, age, employment history, references, resume, medical history, emergency contact, taxation details, qualifications,  and payment details.</span></p>
              <p><span><strong>Customer:</strong> if you are a customer of ours, we may collect and hold information including your name, address, email address, contact telephone number, gender, age, bank details and next of kin.</span></p>
              <p><span><strong>Supplier:</strong> if you are a supplier of ours, we may collect and hold information including your name, address, email address, contact telephone number, business records, billing information, and information about goods and services supplied by you.</span></p>
              <p><span>From our franchisees, we also require company details including NZBN and/or New Zealand company number, financial information including bank account and credit card details, profit and loss statements, balance sheets, weekly takings information, sales data and other business related information which may contain Personal Information.</span></p>
              <p><span>If you feel that the Personal Information that we are requesting at any point is not information that you wish to provide, please feel free to raise this with us. </span></p>
              <p><span><strong>How we collect Personal Information from you</strong></span></p>
              <p><span>We will collect Personal Information from you in the following circumstances: </span></p>
              <p><span>Property Management</span></p>
              <ul className='square-li'>
                <li><span>if you are a prospective or current landlord and you want us to manage your property, then we will collect Personal Information from you in order to identify you and the property; </span></li>
                <li><span>if you are a prospective or current tenant and/or you inspect any rental property, then we will collect Personal Information from you when you attend the property inspection;  </span></li>
                <li><span>if you are a prospective or current tenant and you want to rent a property, then we will collect Personal Information from you, usually at the rental application stage from the rental application and supporting documentation that you supply. If you are the successful tenant then we may also collect Personal Information from you during the continuation of any tenancy or lease agreement with the landlord; and</span></li>
                <li><span>if you are a prospective tenant who has enquired about a property listed by us, then we will collect Personal Information from you, usually at the enquiry stage whether that be by telephone, email, in person or online enquiry. </span></li>
              </ul>
              <p><span>Sales</span></p>
              <ul className='square-li'>
                <li><span>if you are a prospective or current vendor and you want us to act as your sales agent, then we will collect Personal Information from you in order to identify you and the property; </span></li>
                <li><span>if you are a prospective or current purchaser and you inspect any property, then we will collect Personal Information from you when you attend the inspection; </span></li>
                <li><span>if you are a prospective purchaser who has enquired about a property listed by us, then we will collect Personal Information from you, usually at the enquiry stage whether that be by telephone, email, in person or online enquiry; and</span></li>
                <li><span>subject to the other provisions of this Privacy Policy, we may also collect Personal Information from you when you make any other enquiry with us about the property.</span></li>
              </ul>
              <p><span>Generally</span></p>
              <ul className='square-li'>
                <li><span>if you are a candidate seeking employment with us, we may collect and hold Personal Information from you to assess your suitability for employment with us.  </span></li>
                <li><span>if you are a supplier of Ours, we may collect and hold Personal Information from you to ensure we can adequately identify you and arrange payment as necessary.  </span></li>
              </ul>
              <p><span><strong>How we collect Personal Information from other sources</strong></span></p>
              <p><span>We can collect Personal Information about you from other sources in any manner permitted by the Act.  We will only collect Personal Information from other sources where it is reasonably necessary for us to do so in relation to, or in connection with, any services we are supplying in connection with our real estate and/or strata agency business.  Examples of such sources of Personal Information include: </span></p>
              <ul className='square-li'>
                <li><span>from your agents, other owners, insurers and underwriters, contractors, legal, financial and other advisors in relation to or in connection with any tenancy, lease or contract for sale of the property;</span></li>
                <li><span>from public registers;</span></li>
                <li><span>from publicly available information;</span></li>
                <li><span>from property valuers or related service providers; </span></li>
                <li><span>from our other service providers including any third party that has been authorised to supply us with Personal Information; </span></li>
                <li><span>by entering competitions, promotions, or requesting information or material from us; </span></li>
                <li><span>completing surveys, providing feedback or complaining to us; </span></li>
                <li><span>publicly available sources of information or any other organisations where you have given your consent; and</span></li>
                <li><span>social media. </span></li>
              </ul>
              <p><span><strong>When the law authorises or requires collection of Personal Information</strong></span></p>
              <p><span>There are laws that may require us to collect and disclose your Personal Information.  For example, we may be required to collect and disclose your personal information pursuant to the Real Estates Agent Act 2008, including any regulation under that law.  </span></p>
              <p><span><strong>How your Personal Information may be used</strong></span></p>
              <p><span>We may collect, use and disclose your Personal Information for the following purposes including: </span></p>
              <p><span>Property Management</span></p>
              <ul className='square-li'>
                <li><span>to identify and/or verify the identity of any prospective or actual landlord or tenant for any property we manage or propose to manage; </span></li>
                <li><span>to process and assess any application received in relation to a tenancy or lease of any property;</span></li>
                <li><span>to advertise, market and promote any tenancy or lease of any property;</span></li>
                <li><span>to negotiate and prepare any tenancy agreement, lease or any other document for any property; </span></li>
                <li><span>to liaise and exchange information with the landlord or the tenant as well as their agents, contractors, legal, financial and other advisors in relation to or in connection with any tenancy or lease of the property;</span></li>
                <li><span>to manage any tenancy or lease agreement including the collection of rent and other amounts on behalf of the landlord and the preparation of required statements of account; </span></li>
                <li><span>to ensure the safety and security of landlords and existing occupiers and of each of their property in connection with the inspection of any property by prospective tenants;</span></li>
                <li><span>to provide your insurer with information relating to or connected with any property;</span></li>
                <li><span>to comply with any applicable law in connection with the tenancy agreement or lease;</span></li>
                <li><span>to confirm whether the landlord or tenant is registered for GST purposes; </span></li>
                <li><span>to tell you about any other rental property that we are managing or any other service that we provide, unless you tell us not to (this is referred to as direct marketing);</span></li>
              </ul>
              <p><span>Sales</span></p>
              <ul className='square-li'>
                <li><span>to identify and/or verify the identity of any prospective or actual vendor for any property; </span></li>
                <li><span>to advertise, market and promote the sale of any property;</span></li>
                <li><span>to negotiate and manage the sale of any property (including to provide non-legal assistance with the exchange of the contracts for the sale of property as well as the preparation of any required statements of account); </span></li>
                <li><span>to liaise and exchange information with the vendor, purchaser (including any prospective purchaser) as well with their respective agents, contractors, legal, financial and other advisors in relation to or in connection with the sale of the property;</span></li>
                <li><span>to ensure the safety and security of vendors and existing occupiers and of each of their property in connection with auctions and the inspection of any property by prospective purchasers;</span></li>
                <li><span>to comply with any applicable law in connection with the sale of the property;</span></li>
                <li><span>to confirm whether the vendor or purchaser is registered for GST purposes; </span></li>
                <li><span>to tell you about any other property that we are selling or any other service that we provide, unless you tell us not to (this is referred to as direct marketing);</span></li>
              </ul>
              <p><span>Generally</span></p>
              <ul className='square-li'>
                <li><span>to provide statistical and other information to industry bodies and regulators; </span></li>
                <li><span>to allow us to run our business efficiently and to perform administrative and operational tasks; </span></li>
                <li><span>to comply with any dispute resolution or other legal process; </span></li>
                <li><span>to act on behalf of clients in accordance with any agency agreement and to comply with our obligations under that agreement;</span></li>
                <li><span>in order to update our records and your contact details;</span></li>
                <li><span>to operate controlled money accounts;</span></li>
                <li><span>if we enter into or propose to enter into any agreement or arrangement with any party for the purpose of, or in connection with, the acquisition of our business (including any part of our business that includes Personal Information), then we may provide that party (including its legal, financial and other professional advisers) with Personal Information in relation to or in connection with those arrangements;</span></li>
                <li><span>in some cases, the organisations and third parties that we may disclose your information to may be based outside New Zealand; </span></li>
                <li><span>to conduct marketing;</span></li>
                <li><span>to advertise our business via websites, social or print media; and</span></li>
                <li><span>any other purpose to which you have consented.</span></li>
              </ul>
              <p><span>You can tell us at any time if you no longer wish to receive direct marketing information or offers from us. We will process your request as soon as reasonably practicable after receipt of the request. </span></p>
              <p><span><strong>How we share your Personal Information</strong></span></p>
              <p><span>Sharing Personal Information with third parties <br/>
                  We may seek and use Personal Information about you from, and use or disclose Personal Information about you to, third parties in relation to or in connection with the potential or actual rental of a property, the sale and purchase of a property or other business or administrative functions for the real estate and/or strata agency. These third parties may include:
                  </span></p>
              <ul className='square-li'>
                <li><span>your representatives, attorneys, lawyers, settlement agents, accountants, brokers;</span></li>
                <li><span>any conjunction, co-listing, buyers’ or other property agents that acts on your behalf in connection with the rental, leasing, sale and purchase of property;</span></li>
                <li><span>auctioneers;</span></li>
                <li><span>your financier including any mortgage brokers that may be assisting you with any existing mortgage loan or application in relation to the sale or purchase of property;</span></li>
                <li><span>valuers, surveyors, insurers, re-insurers, claim assessors and investigators;</span></li>
                <li><span>financial institutions including deposit taking institutions;</span></li>
                <li><span>if you are a tenant, including any prospective tenant, your referees, including your employer or other individuals to confirm your identity and/or other details about you in any rental application that you submit;</span></li>
                <li><span>organisations in and associated with debt collecting;</span></li>
                <li><span>law enforcement agencies;</span></li>
                <li><span>government and/or regulatory bodies including in many cases, these organisations may share information with foreign authorities;</span></li>
                <li><span>mailing houses and telemarketing agencies that assist us to communicate with you; </span></li>
                <li><span>if we enter into, or propose to enter into, any agreement or arrangement with any party for the purpose of, or in connection with, the acquisition of our business (including any part of our business that includes Personal Information), that party (including its legal, financial and other professional advisers) to provide Personal Information in relation to or in connection with those arrangements; and</span></li>
                <li><span>service providers including IT and data consultants, agents, contractors and advisers that assist us to conduct our business.</span></li>
              </ul>
              <p><span>Sharing Personal Information with related companies <br/>
              We may share Personal Information about you with our related companies for any of the purposes described above.
              </span></p>
              <p><span>Sharing Personal Information outside of New Zealand</span></p>
              <p><span>Some of your Personal Information may be disclosed overseas the countries below We may store information about you in cloud or other types of networked or electronic storage.  As electronic or networked storage can be accessed from various countries through an internet connection it is not always practical to know in which country Personal Information about you may be held. In some cases, the organisations and third parties that we may disclose your information to may be based outside New Zealand. For example, we may share your information with our third parties in:</span></p>
              <ul className="std-li">
                <li><span>-	the Philippines; </span></li>
                <li><span>-	United States of America;</span></li>
                <li><span>-	Australia;</span></li>
                <li><span>-	India;</span></li>
                <li><span>-	China;</span></li>
                <li><span>-	Indonesia;</span></li>
                <li><span>-	Malaysia;</span></li>
                <li><span>-	Singapore;</span></li>
                <li><span>-	Papua New Guinea; and</span></li>
                <li><span>-	The Middle East.</span></li>
              </ul>
              <p><span>In addition, some programs, software, online tools or Social Media Sites used by us and/or our third-party service providers, are based in and/or housed overseas.  The use of such programs by us or by our third-party service providers may involve disclosure of your Personal Information to such organisations overseas.  Use and disclosure of your Personal Information by such organisations is in accordance with the terms and conditions and privacy policies of such organisations. </span></p>
              <p><span>We take reasonable steps to make sure the overseas recipients of your Personal Information do not breach the privacy obligations relating to it. </span></p>
              <p><span>We will always take reasonable steps to ensure that any overseas organisation that receives your Personal Information will comply with the Act. If you consent to disclosure of your Personal Information as described above, we are not required to take reasonable steps to ensure that such organisations do not breach the IPPs in relation to the disclosed information, we will not be liable under the Act and you will not be able to seek redress under the Act. The overseas organisation may not be subject to any privacy obligations in their own country that are similar to the IPPs, you may not be able to seek redress in the overseas jurisdiction and the overseas recipient could be subject to foreign laws that compel disclosure to third parties, such as overseas authorities.  Having been informed of the possibility that such disclosures may occur, you consent to that disclosure by undertaking the type of activities specified above which indicate your consent.</span></p>
              <p><span><strong>If you do not provide Personal Information</strong></span></p>
              <p><span>If you do not provide your Personal Information to us, it may not be possible to provide you with the service that you have requested or applied for, for example:  </span></p>
              <ul className="std-li">
                <li><span>-	if Personal Information is not disclosed in a rental application by a prospective tenant, then we may be unable to process the application and/or submit it to the landlord for their consideration or approval. If you enter into a rental or lease agreement, you will need to be identified and your Personal Information will be included in the rental or lease agreement;   </span></li>
                <li><span>-	if Personal Information is not disclosed by a vendor in connection with the sale of their property, we will not be able to verify that they are the owner of the property and/or to advertise and market the property;  </span></li>
                <li><span>-	if Personal Information is not disclosed by a prospective purchaser at an open inspection, then we may not be able to admit them into the inspection;</span></li>
                <li><span>-	if Personal Information is not disclosed by a prospective purchaser at an auction, then we will not be able to register them as a bidder;</span></li>
                <li><span>-	if Personal Information is not disclosed by an owner in connection with the management of their strata scheme or their property, then we will not be able to verify that they are the owner of the property and manage the strata scheme; and</span></li>
                <li><span>-	to let you know about other services we provide that might be suitable for your needs (unless you opt out of this by telling us that you do not wish to receive such information).</span></li>
              </ul>
              <p><span><strong>Anonymity and pseudonymity</strong></span></p>
              <p><span>We are not always able to deal with people who do not wish to identify themselves. This will be particularly the case when we are proposing to, or we do, act for a vendor or a landlord in relation to either the sale or rental/lease of any property.  This will also apply where we are also dealing with actual or prospective tenants for any rental property that we manage.</span></p>
              <p><span>Further, we are not able to allow people through any property inspections who do not wish to identify themselves as this can create security and other concerns for the property owner (among other considerations).</span></p>
              <p><span>However, where possible, we can provide information of a general nature such as, for example, we can respond to general telephone and related enquiries about a rental/lease or sale property to unidentified individuals. Of course, if an individual makes an enquiry by email or other electronic or written methods then there is every possibility that the mode of communication may identify you.</span></p>
              <p><span><strong>Security</strong></span></p>
              <p><span>We take reasonable steps to protect all information which we hold (including any personal or sensitive information) from misuse, loss, unauthorised access, modification or disclosure.
              As our website is linked to the interest, and the internet is inherently insecure, we cannot provide any assurance regarding the security of transmission of information you communicate to us online. We also cannot guarantee that the information you supply will not be intercepted while being transmitted over the internet. Accordingly, any Personal Information or other information which you transmit to us online is transmitted at your own risk.
              </span></p>
              <p><span><strong>Links</strong></span></p>
              <p><span>Our website may contain links to other websites operated by third parties. We make no representations or warranties in relation to the privacy practices of any third party website and we are not responsible for the privacy policies or the contents of any third party website. Third party websites are responsible for informing you about their own privacy practices. </span></p>
              <p><span><strong>Marketing and opt-out</strong></span></p>
              <p><span>We may also use the information, including Personal Information (excluding sensitive information), provided by you for marketing and research purposes, to analyse and improve products, services and benefits and to inform you of products, services and benefits provided by us, our related entities, suppliers or sponsors which we consider may be of value or interest to you, unless you tell us, or have previously told us not to. We will not use your sensitive information for these purposes without your consent.  </span></p>
              <p><span><strong>Cookies</strong></span></p>
              <p><span>When you access our website, we may send a “cookie” (which is a small file containing a unique ID number) to your computer or device. This enables us to recognise your computer or device. It also enables us to keep track of your activity on our website for a period of time. We also use cookies to measure traffic patterns, to determine which areas of our website have been visited and to measure transaction patterns in the aggregate. We use this to research our users’ habits so that we can improve our online products and services. Our cookies do not collect Personal Information. If you do not wish to receive cookies, you can set your browser so that your computer does not accept them.</span></p>
              <p><span>We may log IP addresses (that is, the electronic addresses of computers and devices connected to the internet) to analyse trends, administer the website and track users movements. </span></p>
              <p><span><strong>Accessing your Personal Information</strong></span></p>
              <p><span>You have the right to request access to any Personal Information held by us which relates to you. Any requests for access to your Personal Information should be made in writing to the Privacy Officer (details specified below).  You also have the right to request the correction of any Personal Information which relates to you that is inaccurate, incomplete, irrelevant, misleading or out-of-date. We will respond within 10 days to any request regarding Personal Information held by us. </span></p>
              <p><span>We may refuse you access to Personal Information if (among other things):</span></p>
              <ul className="std-li">
                <li><span>•	Disclosing the information would disclose Personal Information about another individual;</span></li>
                <li><span>•	The request is frivolous or vexatious or the information request is trivial;</span></li>
                <li><span>•	The information is not readily retrievable;</span></li>
                <li><span>•	The information does not exist or cannot be found.</span></li>
              </ul>
              <p><span>We will inform you of our decision, and if refused the reasons why, within 10 days of you making the request.</span></p>
              <p><span><strong>Complaints</strong></span></p>
              <p><span>If you believe that your privacy has been breached, please contact us using the contact information below and provide details of the incident so that we can investigate it. We will deal with the complaint in accordance with our then current complaints handling procedure. </span></p>
              <p><span>If you require any further information about our management of Personal Information or have any queries or complaints, you should contact.</span></p>
            </div>
             ) : (
             <div className={`footer copyright ${hidden ? 'hidden' : ''}`}  style={{textAlign: 'justify'}}>
               <p><strong>Website Privacy Policy</strong></p>
               <p><span>This Privacy Policy applies to all personal information collected by Raine &amp; Horne Pty Ltd (ACN 003 625 634) (<strong>DigiKitPlus</strong>) via the website located at digikitplus.rh.com.au (<strong>Website</strong>).</span></p>
               <p><span><strong>How to contact us about privacy</strong></span></p>
               <p><span>Your privacy is very important to us. For that reason, please read the following details carefully and get in contact with us if you have any questions please contact our Privacy Officer at enquiries@rh.com.au, or at Level 11, 447 Kent Street Sydney, Sydney NSW 2000 or on +61 (0)2 9258 5400 . If we become aware of any concerns or problems we will take those matters seriously and work to address them as quickly as possible.</span></p>
               <p><span>If you are not satisfied with our response to your complaint, you can refer the matter to the Australian Information Commissioner: Director of Complaints, Office of the Australian Information Commissioner, GPO Box 5218, Sydney NSW 2001, Telephone: 1300 363 992, Facsimile +61 2 9284 9666.</span></p>
               <p><span><strong>Collection and Purpose</strong></span></p>
               <p><span>We may collect personal information from you in the course of your use of the Website if you input any personal information into the Website (for example when you provide information by phone or in writing, such as in application forms, in leases, at open house inspections or over the internet) or from other sources (such as solicitors, credit reporting agencies, tenancy reference databases and council records). In addition, we also store cookies on your computer to provide certain features and enhance the experience of using our website and we employ other activity tracking functions, which enables us to tell when and how you use the Website, to help customise your website experience and improve our delivery of real estate services to you. </span></p>
               <p><span>Examples of the sorts of information that we collect include your name, current and previous addresses, telephone numbers, email address, pages viewed, and other relevant details depending on the particular dealings you have with us.</span></p>
               <p><span>The purpose for which we collect personal information is to provide you with the best service experience possible on the website and through our real estate services. Some provision of personal information is optional. However, if you do not provide us with certain types of personal information, you may be unable to enjoy the full functionality of the Website.</span></p>
               <p><span>Information collected about you may be disclosed by us to other persons and organisations. The persons and organisations to whom we disclose information include your representatives (such as your authorised representatives, legal advisers and accountants); credit reporting agencies; credit providers; our professional advisers, government and regulatory authorities and other organisations, as required or authorised by law.</span></p>
               <p><span><strong>Disclosure</strong></span></p>
               <p><span>We customarily disclose personal information only to our service providers who assist us in operating the Website or who are offering to provide real estate services to you. We will only disclose personal information to an unrelated third party with your consent.</span></p>
               <p><span>We will not disclose any personal information without your consent unless:</span></p>
               <ol className="ol1">
                 <ol className="ol2">
                   <li className="li2"><span>such information must be provided to answer your queries, to provide you with a service that you have requested from us or in order for us to fulfil our responsibilities and obligations to you and others with whom we deal as a result of or in connection with our dealings with you; or</span></li>
                   <li className="li2"><span>such information is required by law to be disclosed.</span></li>
                 </ol>
               </ol>
               <p><span>We may also use your personal information for direct marketing purposes. We do not use your personal information for the purposes of marketing, other than direct marketing in accordance with the following section.</span></p>
               <p><span><strong>Direct marketing </strong></span></p>
               <p><span>When you register with us for certain services, including on a Raine &amp; Horne website, you may receive material from us and our related entities including Brokerage House Pty Ltd Pty Ltd (ACN 607 041 421) trading as Raine &amp; Horne Assist (ABN 40 607 041 421), our franchised offices (<strong>Raine &amp; Horne Offices</strong>). If you are receiving marketing information and do not want to continue receiving this information, please email us at enquiries@rh.com.au and ask to be removed from the marketing list. We will notify you of our receipt of your request and use commercially reasonable efforts to remove your name from the marketing list within five (5) working days from the date of receipt of your request.</span></p>
               <p><span><strong>Access and correction</strong></span></p>
               <p><span>National Privacy Principle of the <em>Privacy Act 1998</em> (Cth) allows you to get access to, and correct, the personal information we hold about you in certain circumstances. If you would like to obtain such access, please email us at enquiries@rh.com.au if you want to obtain a record of any information we might have collected from or about you during your visits to our sites or otherwise. We will give you access to that information within a reasonable period except where the law permits otherwise.</span></p>
               <p><span><strong>Correction and updating of your personal information </strong></span></p>
               <p><span>We will use reasonable efforts to ensure that personal information that we hold about you is accurate, up to date and relevant. Please email us at enquiries@rh.com.au if you want to update or change any personal information that you believe we may be storing.</span></p>
               <p><span><strong>Deletion of your personal information </strong></span></p>
               <p><span>If you want to delete any or all personal information you submit to us, you may do so by contacting us at enquiries@rh.com.au. We will notify you on receipt of your request and will use commercially reasonable efforts to functionally delete all such information from our database within five (5) working days from the date of receipt of your request. Please note that it might be impossible to delete all of your information without some residual records remaining on our computer system because of automatically generated backups and records of deletions. Deletion of your personal information may also make it impossible for us to provide and for you to have the benefit of some or all of our services.</span></p>
               <p><span><strong>Security </strong></span></p>
               <p><span>We have implemented technology and security features and strict policy guidelines to safeguard the privacy of personally identifiable information from unauthorised access or improper use. We will continue to enhance our security procedures as new technology becomes available.</span></p>
               <p><span><strong>Transfer out of Australia</strong></span></p>
               <p><span>The Website is not hosted in Australia. For that reason, we transfer all data on the website (including all personal information) to our hosting service provider in the United States of America. You hereby consent to this transfer.</span></p>
               <p><span><strong>Changes to the Privacy Policy</strong></span></p>
               <p><span>We reserve the right to modify or amend this Privacy Policy at any time and for any reason without notice. The current version of our Privacy Policy from time to time on the Website and otherwise on request.</span></p>

             </div>
          ) }
          <div className='copyright' style={{marginBottom: '40px'}}>
            <div className="d-flex p-jc-center">
              <Button label={`${hidden ? 'View all Terms & Conditions' : 'Hide Terms & Conditions'}`} className="p-button-outlined p-button-secondary" onClick={() => setHidden(!hidden) }/>
            </div>
          </div>
        </>
      )}
    </>
  )

}

export default Footer;
