import React, { useEffect, useState } from "react";
import { GetOfficeImages, UploadImage } from "../../../api/ImageLibraryService";
import { LinearProgress, makeStyles } from "@material-ui/core";
import cn from "classnames";

import { getImageFiles, getImageData, checkImageSize} from "./utils";
import { CropImageModal } from "./CropImageModal"
import { dataURLtoFile } from "../../Images/utils";
import imageCompression from "browser-image-compression";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 20px)',
    margin: '0 -10px',
    maxHeight: '429px',
    overflow: 'auto'
  },
  imageComponent: {
    width: 200,
    textAlign: 'center',
    margin: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    "& img": {
      width: 'inherit',
    },
    "& span": {
      color: '#666'
    },
    "& :hover": {
      cursor: 'pointer',
      opacity: 0.9
    },
    "& :active": {
      opacity: 0.9
    }
  },
  active: {
    "& span": {
      backgroundColor: 'rgba(255, 193, 7, 0.6)',
    },
  }
}));

const ImageCard = ({ url, name, active, onClick, onDoubleClick }) => {
  const classes = useStyles();
  return (
    <div
      className={cn(classes.imageComponent, { [classes.active]: active })}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <img src={url} alt={name} />
      <span>{name}</span>
    </div>
  )
}

const ImageList = ({ officeId, onSelect, aspectRatio, parentFolderName = 'Coverletter Images', shared }) => {
  const [ loading, setLoading ] = useState(false);
  const [ folderId, setFolderId ] = useState();
  const [ imageList, setImageList ] = useState([]);
  const [ selectedImage, setSelectedImage ] = useState();
  const [ cropImageData, setCropImageData ] = useState();
  const classes = useStyles();
  const uploadImageRef = React.useRef(null);

  useEffect(() => {
    setLoading(true);
    GetOfficeImages(officeId)
      .then( ({ data }) => {
        const { imageList, folderId } = getImageFiles(parentFolderName, data.fileMap, shared);
        setImageList(imageList);
        setFolderId(folderId);
        setLoading(false);
      })
  }, [officeId, parentFolderName, shared]);

  const postImage = async e => {
    e.preventDefault();
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else if (e.target) {
      file = e.target.files[0];
    }
    setLoading(true);
    const blobFile = await imageCompression(file, { maxSizeMB: 5 });
    const compressedFile = new File([blobFile], file.name, { lastModified: new Date() })
    setLoading(false);

    const imageData = await getImageData(compressedFile);
    const isCorrectProportion = await checkImageSize(imageData, aspectRatio);
    if (aspectRatio && isCorrectProportion) {
      uploadImage(file)
    } else {
      setCropImageData({
        name: file.name,
        data: imageData,
      })
    }
  };

  const uploadImage = (file, afterCrop) => {
    setLoading(true);
    const postData = new FormData();
    postData.append('file', file);
    UploadImage(officeId, folderId, postData)
      .then( ({ data }) => {
        const newFile = {
          id: data._id,
          name: data.filename,
          modDate: data.updated_at,
          parentId: data.folder,
          thumbnailUrl: data.url,
        }
        setImageList([ newFile, ...imageList ])
        if (afterCrop) onSelect(data._id)
      })
      .finally(() => {
        setLoading(false);
        uploadImageRef.current.value = null;
      })
  }

  const onSelectImage = async image => {
    if (!image?.id) return;
    const isCorrectProportion = await checkImageSize(image.thumbnailUrl, aspectRatio);
    if (isCorrectProportion) {
      onSelect(image.id);
    } else {
      setCropImageData({
        name: image.name,
        data: image.thumbnailUrl
      })
    }
  }

  const containerClick = () => {
    setSelectedImage(undefined);
  }

  const onImageSelect = async (image, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedImage(image);
  }

  const onDoubleCLick = async (image, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedImage(image);
    await onSelectImage(image);
  }

  const onCrop = file => {
    setCropImageData(undefined);
    const fileName = cropImageData?.name?.split('.');
    uploadImage(dataURLtoFile(file, `${fileName[0]}_cropped.${fileName[1]}`), true);
  }

  const hideCropModal = () => {
    setCropImageData(undefined);
    uploadImageRef.current.value = null;
  }

  return (
    <div className="w-100" onClick={containerClick}>
      <LinearProgress color="primary" className={ cn({'d-none': !loading}) }/>
      <div className={classes.imageContainer} style={{ opacity: loading? 0.4 : 1 }}>
        { imageList.length === 0 && !loading && <div>
          No images exist
        </div>}
        { imageList.map(image => (
          <ImageCard
            url={image.thumbnailUrl}
            name={image.name}
            key={image.id}
            active={image.id === selectedImage?.id}
            onClick={e => onImageSelect(image, e)}
            onDoubleClick={e => onDoubleCLick(image, e)}
          />
        )) }
      </div>
      <input
        type="file"
        accept="image/*"
        id="image-input"
        ref={uploadImageRef}
        style={{ display: "none" }}
        onChange={postImage}
      />

      <div className='mt-10'>
        <button
          type="button"
          className={cn("mr-5 btn btn-primary", { "btn-secondary disabled": !selectedImage } )}
          onClick={() => onSelectImage(selectedImage)}
        >
          Apply
        </button>
        {!shared && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => uploadImageRef.current.click()}
          >
            Upload
          </button>
        )}
      </div>
      <CropImageModal
        src={cropImageData?.data}
        onCrop={onCrop}
        show={!!cropImageData}
        onHide={hideCropModal}
        aspectRatio={aspectRatio}
      />
    </div>
  )
}

export default React.memo(ImageList)
