import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getWeeks,
  getProposalsWidgetData,
  getIsLoadingProposalsWidget,
  getRange,
} from "redux/selectors/dashboardSelector";
import { fetchProposalsWidgetData } from "redux/actions";
import WidgetLink from "app/components/Widgets/WidgetLink";
import Widget from "app/components/Widgets/Widget";
import Loader from "app/components/ui/Loader";
import SvgIcon from "app/components/ui/SvgIcon";

import { getWidgetMetrics } from "shared/utils";
import { clickRateTooltip } from "shared/constants";

import "./styles.scss";

const ProposalsWidget = () => {
  const dispatch = useDispatch();
  const weeks = useSelector(getWeeks);
  const range = useSelector(getRange);

  const {
    sent,
    prevSent,
    unopened,
    prevUnopened,
    clicks,
    prevClicks,
  } = useSelector(getProposalsWidgetData);

  const isLoading = useSelector(getIsLoadingProposalsWidget);

  useEffect(() => {
    dispatch(fetchProposalsWidgetData());
  }, [dispatch, weeks, range]);

  const recentMetrics = getWidgetMetrics({
    weeks,
    curr: sent,
    prev: prevSent,
  });

  const unopenedMetrics = getWidgetMetrics({
    weeks,
    curr: unopened,
    prev: prevUnopened,
  });

  const clicksMetrics = getWidgetMetrics({
    weeks,
    curr: clicks,
    prev: prevClicks,
  });

  return (
    <div className="layout-side-padding">
      <div className="dashboard-proposals-widget">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <WidgetLink
              to="/proposals"
              label={sent}
              title="Proposals sent"
              variant="yellow"
              metrics={recentMetrics}
            />
            <WidgetLink
              to="/proposals?type=unopened"
              label={unopened || 0}
              title="Unopened proposals"
              variant="red"
              metrics={unopenedMetrics}
            />
            <Widget
              label={`${clicks}%`}
              title="Click through rate"
              variant="size-60 spacing-25 grey"
              icon={
                <SvgIcon iconName="questionCircle" tooltip={clickRateTooltip} />
              }
              metrics={clicksMetrics}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProposalsWidget;
