import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DialogTitle, DialogContent, Box } from "@material-ui/core";
import moment from "moment-timezone";
import { Modal, ModalActions } from "../../../../components/Modal/index";

import EditPropertyModalStyles from "./EditPropertyModal.style";

import DefaultTable from "../components/PropertyTables/DefaultTable";
import ManualCommercialTable from "../components/PropertyTables/ManualCommercialTable";
import ManualBusinessListingTable from "../components/PropertyTables/ManualBusinessListingTable";
import { useBusinessListing } from "app/hooks";

const EditPropertyModal = ({
  classes,
  show,
  onClose,
  onSubmit,
  info,
  type,
  isCommercial,
}) => {
  const { data, index } = info || {};
  const [infoData, setInfoData] = useState(data);
  const [confirm, setConfirm] = useState(false);
  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);
  const { isBusinessListing } = useBusinessListing();

  const currentDate = moment
    .tz(moment(), "Australia/Sydney")
    .format("DD/MM/YYYY HH:mm");

  const bodyText =
    type === "On the Market" || type === "Market Sales"
      ? "Warning - by editing the details of properties that come from RP Data, you have read and acknowledge that you are overriding information that is public to the market and take full responsibility for new information that may not be known as true, that you are sharing in this proposal."
      : "Warning - by editing the details of properties that come from the Raine & Horne property feed, you have read and acknowledge that you are overriding information that may be from another Raine & Horne office, that is public to the market and take full responsibility for new information that may not be known as true, that you are sharing in this proposal.";

  useEffect(() => {
    setInfoData(data);
  }, [data]);

  const onSave = () => {
    if (!infoData?.address) {
      setIsAddressEmpty(true);
    } else setIsAddressEmpty(false);

    const newData = {
      ...infoData,
      editData: {
        date: currentDate,
        user: `${currentUser.firstname} ${currentUser.lastname}`,
      },
    };

    if (infoData?.address) {
      onSubmit(newData, index);
      setConfirm(false);
    }
  };

  const onConfirm = () => {
    setConfirm(true);
  };

  const structureOfProperty = () => {
    return (
      infoData && (
        <div>
          {isCommercial && infoData.manually ? (
            <ManualCommercialTable
              classes={classes}
              property={infoData}
              setProperty={setInfoData}
              isAddressEmpty={isAddressEmpty}
              isEditMode
              type={type}
            />
          ) : isBusinessListing ? (
            <ManualBusinessListingTable
              classes={classes}
              property={infoData}
              setProperty={setInfoData}
              isAddressEmpty={isAddressEmpty}
              isEditMode
            />
          ) :(
            <DefaultTable
              classes={classes}
              isCommercial={isCommercial}
              property={infoData}
              setProperty={setInfoData}
              isAddressEmpty={isAddressEmpty}
              isEditMode
              type={type}
            />
          )}
        </div>
      )
    );
  };

  return (
    show && (
      <Modal
        open={show}
        style={{ zIndex: "1000" }}
        classes={
          confirm || infoData?.manually
            ? { paper: classes.container }
            : { paper: classes.confirmBlock }
        }
        onClose={onClose}
      >
        <DialogTitle>
          <div className={classes.title}>Edit property</div>
        </DialogTitle>
        <DialogContent>
          <Box
            style={
              confirm || infoData?.manually ? { padding: "0 20px 30px" } : {}
            }
          >
            {confirm || infoData?.manually ? (
              <Box>{structureOfProperty}</Box>
            ) : (
              bodyText
            )}
          </Box>
        </DialogContent>
        <ModalActions
          onSubmit={confirm || infoData?.manually ? onSave : onConfirm}
          submitTitle={
            confirm || infoData?.manually ? "Save and Close" : "Proceed"
          }
          onCancel={onClose}
        />
      </Modal>
    )
  );
};

EditPropertyModal.prototype = {
  classes: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  info: PropTypes.object,
  type: PropTypes.string,
};

export default compose(EditPropertyModalStyles)(EditPropertyModal);
