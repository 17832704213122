import React, { memo, useRef, useCallback, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import ImageIcon from "@material-ui/icons/Image";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "_metronic/_helpers";
import CropImageModal from "app/modules/Common/ImageLibrary/components/CropImageModal";
import { ImagesModal } from "../ImagesModal";

import { API_URL } from "app/modules/Auth/_redux/authCrud";
import { ProposalTypes } from "app/interfaces";
import { dataURLtoFile } from "../../../Images/utils";
import { UploadImage } from "../../../../api/ImageLibraryService";

const ImageCover = memo(({ image, className }) => {
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  return image ? (
    <div className="h-140px">
      <img src={image} alt="Cover" className={className} />
    </div>
  ) : (
    <div
      className="d-flex flex-center flex-column h-140px"
      style={{ background: "#f2f2f2" }}
    >
      <ImageIcon style={{ fontSize: 40 }} />
      {`Upload ${
        appraisalType === ProposalTypes.businessListingProposal
          ? "business"
          : "property"
      } photo`}
    </div>
  );
});

export const ImageBlock = memo(
  ({ classes, image, setImage, id, isEditMode = false }) => {
    const inputFile = useRef(null);
    const [cropData, setCropData] = useState(undefined);
    const [isShowImagesModal, setShowImageModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imagesModal, setImagesModal] = useState([]);
    const [isLoadingModalImages, setLoadingModalImages] = useState(false);
    const officeId = useSelector(
      ({ appraisal }) => appraisal.team.office,
      shallowEqual,
    );
    const country = useSelector(
      ({ appraisal }) => appraisal.country,
      shallowEqual,
    );

    useEffect(() => {
      if (!image) {
        setCropData(undefined);
      }
    }, [image]);

    const uploadImage = useCallback(
      (file, afterCrop) => {
        setLoading(true);
        const postData = new FormData();
        postData.append("file", file);
        UploadImage(officeId, undefined, postData)
          .then(({ data }) => {
            setImage(data.url);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      [officeId, setImage],
    );

    const onCrop = useCallback(
      (file, name) => {
        setImage(file);
        setCropData(undefined);

        const fileName = name?.split(".");
        uploadImage(dataURLtoFile(file, `${fileName[0]}.${fileName[1]}`), true);
      },
      [setImage, uploadImage],
    );

    const onSelectImage = useCallback(event => {
      const name = event?.target?.files?.[0]?.name;
      if (!name) return;
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          setCropData({
            img: fileReader.result,
            name,
          });
        }
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }, []);

    const onImageSelected = useCallback(
      (id, image) => {
        setShowImageModal(false);
        return setImage(image);
      },
      [setImage],
    );

    const setDialogImages = useCallback(() => {
      if (!isEditMode) return inputFile.current.click();
      setLoadingModalImages(true);
      setShowImageModal(true);
      const apiUrl =
        API_URL + `properties/corelogic/images/${id}?country=${country}`;
      return axios
        .get(apiUrl)
        .then(({ data }) => {
          if (data && data?.images?.length) {
            const images = (data?.images || []).map(img => ({
              id: id,
              src: img.mediumPhotoUrl,
            }));
            setLoadingModalImages(false);
            return setImagesModal(images);
          } else {
            setLoadingModalImages(false);
            setShowImageModal(false);
            return inputFile.current.click();
          }
        })
        .catch(() => {
          setLoadingModalImages(false);
          setShowImageModal(false);
          return inputFile.current.click();
        });
    }, [id, isEditMode, country]);

    return (
      <React.Fragment>
        {loading ? (
          <CircularProgress color="#fff" className="ml-5 h-20px w-20px" />
        ) : (
          <>
            {isEditMode ? (
              <ImagesModal
                show={isShowImagesModal}
                onHide={() => setShowImageModal(false)}
                images={imagesModal}
                loading={isLoadingModalImages}
                onSelect={onImageSelected}
              />
            ) : (
              <React.Fragment />
            )}
            <div className={classes.imageContainer}>
              <ImageCover className={classes.image} image={image} />
              <div className={classes.overlay} onClick={setDialogImages}>
                <img src={toAbsoluteUrl("/media/upload.png")} alt="upload" />
              </div>
              <input
                type="file"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={event => onSelectImage(event)}
              ></input>
            </div>
            <CropImageModal
              data={cropData}
              onCrop={onCrop}
              show={!!cropData}
              onClose={() => setCropData(undefined)}
              aspectRatio={1.33}
            />
          </>
        )}
      </React.Fragment>
    );
  },
);
