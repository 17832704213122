import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { updateProperty, updateTeam } from "../../../../../redux/reducers";
import EditorComponent from "../../../Common/EditorComponent";
import StatisticsService from "../../../../api/StatisticsService";
import cn from "classnames";
import BlockContainer from "../BlockContainer";
import HideContainer from "../../components/HideContainer";
import { AppraisalContext } from "../../AppraisalContext";
import SuburbProfile from "./SuburbProfile";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  marketReportBlock: {
    "& img": {
      maxWidth: "100%",
      height: "auto",
    }
  },
  profileTitle: {
    textTransform: "capitalize",
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: 40,
    marginTop: 20,
    lineHeight: "54px !important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
  title: {
    fontSize: 40,
    lineHeight: "54px !important",
  },
  separator: {
    width: "100%",
    height: "1px",
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    paddingTop: "48px",
  },
}));

export const getType = type => {
  const houseTypes = [
    "House",
    "Acreage",
    "Semi-Rural",
    "Farm",
    "Semi Detached",
    "Terrace",
    "Townhouse",
  ];
  const unitTypes = [
    "Unit",
    "Apartment",
    "Block Of Units",
    "Retirement",
    "Studio",
    "Villa",
  ];
  if (houseTypes.indexOf(type) !== -1) return "Houses";
  if (unitTypes.indexOf(type) !== -1) return "Units";
  return "Houses";
};

// TODO: REFACTORING
const MarketReport = ({ withoutSubTitle = false, withoutSuburb }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const { preview } = useContext(AppraisalContext);
  const [statistics, setStatistics] = useState(null);

  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);
  const property = useSelector(
    ({ appraisal }) => appraisal.property,
    shallowEqual,
  );
  const country = useSelector(
    ({ appraisal }) => appraisal.country,
    shallowEqual,
  );
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );

  useEffect(() => {
    let isMounted = true;
    if (!withoutSuburb && !property.suburbHidden) {
      if (!preview || (preview && !property.suburbHidden)) {
        if (!!property?.address) {
          setType(getType(property.propertyType));
          //get statistics and prepare on the be, return only newStatistics object

          const {
            name,
            postcode,
            state: { abbreviation: state },
          } = property.address?.suburb;
          StatisticsService.getStatistics({
            locality: `${name}+${state}+${postcode}`,
            category: category,
            country: country,
          })
            .then(resp => {
              if (isMounted && resp.data) prepareStatistics(resp.data);
            })
            .finally(() => (isMounted = false));
        }
      }
    }
    return () => {
      isMounted = false;
    };
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.address, property.propertyType, property.suburbHidden]);

  const prepareStatistics = statistics => {
    if (
        !statistics.charts.Houses.data.length &&
        !statistics.charts.Units.data.length &&
        !statistics.metrics.length
    ) {
      dispatch(updateProperty({ suburbHidden: true }));
    } else {
      dispatch(updateProperty({suburbHidden: false}));
      //Show/Hide one of tabs Houses ot Units
      setType(getType(property?.propertyType))
    }

    setStatistics(statistics);
  };

  const hideSectionHandler = val => {
    dispatch(updateTeam({ reportHidden: val }));
  };

  const hideSuburbProfileHandler = val => {
    dispatch(updateProperty({ suburbHidden: val }));
  };

  const editSection = body => {
    dispatch(updateTeam({ report: body }));
  };

  const onTitleUpdate = title => {
    dispatch(updateTeam({ marketReportTitle: title }));
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateTeam({ marketReportSubtitle: subtitle }));
  };

  return (
    <>
      <HideContainer value={team.reportHidden} onChange={hideSectionHandler}>
        <BlockContainer
          defaultTitle={team.marketReportTitle}
          defaultSubtitle={team.marketReportSubtitle}
          withoutSubTitle={withoutSubTitle}
          className={classes.marketReportBlock}
          id="market-report"
          onTitleUpdate={onTitleUpdate}
          onSubtitleUpdate={onSubtitleUpdate}
          hide={team.reportHidden}
        >
          <EditorComponent
            value={team.report}
            onBlur={editSection}
            type="coverletter"
            inline
            charactersLimit={1100}
          />
        </BlockContainer>
      </HideContainer>
      {withoutSuburb ? (
        ""
      ) : (
        <div id="suburb-profile">
          <HideContainer
            title="Hide Sub Section"
            value={property.suburbHidden}
            onChange={hideSuburbProfileHandler}
          >
            <Typography
              component="h6"
              variant="subtitle1"
              align="center"
              className={cn(classes.profileTitle, "mb-6", {
                [classes.title]: !property.suburbHidden && team.reportHidden,
              })}
            >
              {`${property.address?.suburb?.name?.toLowerCase()} — Suburb Profile`}
            </Typography>
            {property.suburbHidden ? (
              ""
            ) : (
              <SuburbProfile
                statistics={statistics}
                type={type}
                isRental={category !== "sales"}
                isCommercial={property?.class?.internalName === "commercial"}
              />
            )}
            <div className={classes.separator} />
          </HideContainer>
        </div>
      )}
    </>
  );
};

export default MarketReport;
