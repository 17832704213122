import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'app/components/ui/Buttons/IconButton';
import cn from 'classnames';

import './style.scss';

const SELECT_MENU_HEIGHT = 35;

const DropDownMenu = ({ data, initialIndex, itemHandler, className, customHeight }) => {
  const [index, setIndex] = useState(initialIndex);
  const [listIsOpen, setListIsOpen] = useState(false);
  const [listWrapperStyle, setListWrapperStyle] = useState(null);
  const listRef = useRef();

  useEffect(() => {
    const clickHandler = (e) => {
      if (!e.target.closest('.drop-down-menu')) {
        setListIsOpen(false);
      }
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, []);

  useEffect(() => {
    const listHeight = customHeight ? customHeight : Number(getComputedStyle(listRef.current).height.replace('px', ''));
    listIsOpen && setListWrapperStyle({ height: `${listHeight + SELECT_MENU_HEIGHT}px` });
    !listIsOpen && setListWrapperStyle({ height: '0' });
  }, [listIsOpen, customHeight]);

  const toggleList = () => {
    setListIsOpen(!listIsOpen);
  };

  const onClickItem = (event, index) => {
    setIndex(index);
    setListIsOpen(false);
    itemHandler({ event, index });
  };

  return (
    <div className={cn('drop-down-menu', { [className]: className })}>
      <div className="select-wrapper">
        <div className="select">{data[index].label}</div>
        <IconButton
          icon="chevronLeft"
          onClick={toggleList}
          className={cn('list-button', { active: listIsOpen })}
        />
      </div>
      <div className="list-wrapper" style={listWrapperStyle}>
        <ul ref={listRef} className="list" style={{ top: `${customHeight ? "30px" : "auto"}` }}>
          {data.map((item, index) => (
            <li
              key={item.label}
              className="item"
              onClick={(e) => onClickItem(e, index)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

DropDownMenu.propTypes = {
  data: PropTypes.array.isRequired,
  initialIndex: PropTypes.number,
  itemHandler: PropTypes.func,
  className: PropTypes.string,
  customHeight: PropTypes.number,
};

DropDownMenu.defaultProps = {
  initialIndex: 0,
  itemHandler: () => null,
  className: '',
  customHeight: null,
};

export default DropDownMenu;
