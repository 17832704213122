import React from "react";
import { Carousel } from "react-responsive-carousel";
import cn from "classnames";
import ArrowNextIcon from "@material-ui/icons/NavigateNext";
import ArrowBeforeIcon from "@material-ui/icons/NavigateBefore";
import { makeStyles } from "@material-ui/core";


interface CarouselContextProps {
  className?: string;
  children?: any;
  autoPlay?: boolean;
  infiniteLoop?: boolean;
  interval?: number;
  centerSlidePercentage?: number;
  showStatus?: boolean;
  showThumbs?: boolean;
  centerMode?: boolean;
}


const useStyles = makeStyles( theme => ({
  carouselRoot: {
    "& .carousel-slider": {
      width: 'calc(100% + 60px) !important',
      margin: '0 -30px',
      padding: '0 30px',
      paddingBottom: '30px',
      "& .slide": {
        display: 'grid',
        padding: 1,
        zIndex: "1 !important"
      },
      "& .control-dots": {
        bottom: "-15px",
        width: 'calc(100% - 60px)',
        "& li": {
          width: 12,
          height: 12,
          background: "#666",
          boxShadow: 'none',
        },
        "& .selected": {
          background: theme.palette.primary.main,
        }
      }
    },
  },
  navigationIcon: {
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#fff',
    position: 'absolute',
    top: 'calc(50% - 15px)',
    transform: 'translateY(-50%)',
    height: 30,
    width: 30,
    display: 'flex',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
    zIndex: 10,
    "& svg": {
      fill: theme.palette.primary.main,
      margin: 'auto',
      fontSize: '25px',
    },
  },
}))

const Arrow = ({ onClick, direction = "right" }: {onClick: any, direction: string }) => {
  const classes = useStyles();
  const ArrowIcon = (direction === "right") ? ArrowNextIcon : ArrowBeforeIcon;
  const style = (direction === "right") ? { right: '10px'} : { left: '10px'}
  return (
    <div className={classes.navigationIcon} onClick={onClick} style={style}>
      <ArrowIcon />
    </div>
  )
}

const CustomCarousel = ({ className, children, ...props }: CarouselContextProps ): JSX.Element => {
  const classes = useStyles();
  return (
    <Carousel
      {...props}
      className={cn(classes.carouselRoot, className)}
      renderArrowPrev={onClick => <Arrow onClick={onClick} direction="left" />}
      renderArrowNext={onClick => <Arrow onClick={onClick} direction="right" />}
    >
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
