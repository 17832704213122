import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import HideContainer from "../../components/HideContainer";
import FlkForm from "./FlkForm";
import {AppraisalContext} from "../../AppraisalContext";
import {updateNextSteps} from "../../../../../redux/reducers";

const FlkSection = ({onChangeNextSteps}) => {
    const dispatch = useDispatch();
    const nextStepsData = useSelector((state) => state.appraisal.nextSteps, shallowEqual);
    const { preview } = useContext(AppraisalContext)

    const onChange = (field, value) => {
        onChangeNextSteps(field, value)
        if (value === false) {
          dispatch(updateNextSteps({ action: {title: "Sign Me Up"} }))
        } else {
          dispatch(updateNextSteps({ action: {title: "I'd like to proceed with Raine & Horne", type: "email"} }))
        }
    }

    return  (
        <HideContainer
            title='Hide Sub Section - FLK E-sign'
            value={nextStepsData.flkHidden}
            onChange={(value) => onChange("flkHidden", value) }
        >
            {!nextStepsData.flkHidden && !preview && <FlkForm />}
        </HideContainer>)
}

export default FlkSection;