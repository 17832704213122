import axios from 'axios';
import API_URL from './api';

export default class UsersService {

  static getOfficeUsers = (selectedOffice, searchParam, pageNumber, filterParam, sortParam, limit) => {

    let search = searchParam || ''

    let page = pageNumber || 1

    let filter = filterParam || 'updated_at'

    let sort = sortParam || 'descending'

    let extendLimit = limit || false

    return axios.get(
      `${API_URL}/users?office=${selectedOffice}&search=${search}&page=${page}&filter=${filter}&sort=${sort}&limit=${extendLimit}`,
    );
  }

  static getMe = () => {
    return axios.get(
      `${API_URL}/users/me`,
    )
  }

  static getMeByAzure = token => {
    return axios.get(
      `${API_URL}/users/me/${token}`,
    );
  }

  static updateUser = (userId, data) => {
    return axios.put(`${API_URL}/users/${userId}`, data)
  }

  static getUserToken = id => {
    return axios.get(`${API_URL}/users/getUserToken/${id}`);
  };
}
