import React, { useContext, useEffect, useState } from "react";
import { AppraisalContext } from "../../AppraisalContext";
import DarkButton from "app/components/ui/DarkButton";
import { Modal, ModalActions } from "app/components/Modal";
import { InputText } from "primereact/inputtext";
import { Box, Typography } from "@material-ui/core";

const openInNewTab = url => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const DownloadLink = ({ url, onChange, title = "Download Forms" }) => {
  const { preview } = useContext(AppraisalContext);
  const [localUrl, setLocalUrl] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setLocalUrl(url);
    setButtonTitle(title);
  }, [url, title]);

  const closeModal = () => setShowModal(false);
  const onButtonClick = () => {
    if (preview) {
      openInNewTab(url);
    } else {
      setShowModal(true);
    }
  };
  const onLocalUrlChange = e => setLocalUrl(e.target.value || "");
  const onTitleChange = e => setButtonTitle(e.target.value || "");
  const onSubmitModal = () => {
    onChange(localUrl, buttonTitle);
    closeModal();
  };

  return (
    <>
      <DarkButton onClick={onButtonClick}>{buttonTitle}</DarkButton>
      <Modal open={showModal} onClose={closeModal} title="Set link">
        <Box mx={2} mt={4} mb={2}>
          <Typography component="h5" variant="h5">
            Apply Downloadable Forms link
          </Typography>
          <InputText
            type="text"
            value={localUrl}
            placeholder="https://www.websiteurl.com"
            className="w-400px mt-3"
            onChange={onLocalUrlChange}
          />
        </Box>
        <Box mx={2} mb={2}>
          <Typography component="h5" variant="h5">
            Button title
          </Typography>
          <InputText
            type="text"
            value={buttonTitle}
            className="w-400px mt-3"
            onChange={onTitleChange}
          />
        </Box>
        <ModalActions onCancel={closeModal} onSubmit={onSubmitModal} />
      </Modal>
    </>
  );
};

export default DownloadLink;
