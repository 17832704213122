import React from "react";

const initState = {
  children: <></>,
  preview: false,
}
export const AppraisalContext = React.createContext<ContextProps>(initState);

interface ContextProps {
  children?: React.ReactNode
  preview: boolean
}

const AppraisalContextProvider = ({ children, ...props }: ContextProps) => {
  return (
    <AppraisalContext.Provider value={props}>
      {children}
    </AppraisalContext.Provider>
  );
};

export default AppraisalContextProvider;
