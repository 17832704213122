/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useContext, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, shallowEqual } from "react-redux";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { uploadFile } from "../../api/ImageLibraryService";
import cn from "classnames";

import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";

import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autolink";
import "tinymce/plugins/media";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/code";

import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";

import { AppraisalContext } from "../Appraisals/AppraisalContext";

const allowedKeys = [8, 13, 16, 17, 18, 20, 33, 34, 35, 36, 37, 38, 39, 40, 46];

const editableStyle = {
  opacity: 0.5,
  cursor: "pointer",
};

// const isNightTime = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   // Check if the time is between 9 PM (21:00) and 7 AM (07:00)
//   if (hours >= 21 || hours < 7) {
//     return true;
//   } else {
//     return false;
//   }
// };

const useStyles = makeStyles({
  editableContainer: {
    "& .mce-content-body:hover:not(.mce-edit-focus)": editableStyle,
  },
  container: {
    "& table": {
      maxWidth: "100%",
    },
  },
});

const EditorComponent = (props: any): JSX.Element => {
  const {
    onChange,
    value,
    type,
    inline,
    placeholder = "Enter your text here...\n",
    disabled,
    onBlur,
    charactersLimit = undefined,
    hardLimit = false,
  } = props;
  const { preview } = useContext(AppraisalContext);
  const team = useSelector((state: any) => state.appraisal.team, shallowEqual);

  const getToolbarSet = (type: any) => {
    if (editorToolbar[type]) return editorToolbar[type];
    return editorToolbar.default;
  };

  const [editorValue, setEditorValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [textCount, setTextCount] = useState(0);

  const setupEditor = (editor: any) => {
    let totalCount = () => 0;

    // TODO remove after approve DK-9
    // editor.on('KeyDown', (event: any) => {
    //   if (allowedKeys.indexOf(event.keyCode) !== -1) return true;
    //   const newCount = totalCount();
    //   if (hardLimit && newCount + 1 > charactersLimit) return false;
    // })

    editor.on("keyUp", () => {
      const newCount = totalCount();
      setTextCount(prevCount => {
        if (newCount > charactersLimit && prevCount <= charactersLimit) {
          setShowWarning(true);
        }
        return newCount;
      });
    });

    // TODO remove after approve DK-9
    // Add an event listener for the 'paste' event
    // editor.on('pastePreprocess', (event: any) => {
    //   const oldCount = totalCount();
    //   const pastedHTML = event.content;
    //
    //   // Extract text content from the pasted HTML
    //   const tempElement = document.createElement('div');
    //   tempElement.innerHTML = pastedHTML;
    //   const pastedText = tempElement.innerText;
    //
    //   // delete temporary element
    //   tempElement.innerHTML = ''
    //
    //   const newCount = oldCount + pastedText.length;
    //
    //   // Truncate pasted text if it exceeds the character limit
    //   if (newCount > charactersLimit) {
    //     event.content = pastedText.substring(0, charactersLimit - oldCount);
    //   }
    //
    // });

    editor.on("init", () => {
      totalCount = editor.plugins.wordcount.body.getCharacterCount;
      const infoComponent = document.getElementById(
        `${editor.id}-charactersLimit-info`,
      );
      if (infoComponent) infoComponent.remove();
      setTextCount(totalCount());
    });
  };

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  useEffect(() => {
    const interval = setInterval(() => {
      const iframes = document.querySelectorAll("iframe");
      // Iterate through each iframe
      for (let i = 0; i < iframes.length; i++) {
        const iframe = iframes[i];
        const iframeStyle = window.getComputedStyle(iframe);

        // console.log("IFRAME", iframe);
        // Check if the iframe's z-index matches the target z-index
        if (iframeStyle.zIndex === "2147483647") {
          iframe.style.width = "0";
          iframe.style.height = "0";
          iframe.style.zIndex = "0";
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const onLoaded = () => {};

  // useLayoutEffect(() => {

  // }, [value]);

  const onblurHandler = () => {
    onBlur && onBlur(editorValue); // don't call function if user don't need this
  };

  const onChangeHandler = (val: any) => {
    setEditorValue(prevVal => {
      // if (hardLimit && (textCount > charactersLimit) && (prevVal.length < val.length)) return prevVal;
      return val;
    });
    onChange && onChange(val); // don't call function if user don't need this
  };

  const editable = inline && !disabled && !preview;
  const classes = useStyles();
  if (!value && preview) return <></>;

  return (
    <>
      <div
        className={cn({
          [classes.editableContainer]: editable,
          [classes.container]: true,
        })}
      >
        <Editor
          initialValue={value || ""}
          value={editorValue}
          disabled={disabled || preview}
          // apiKey={tynimceKey}
          init={{
            toolbar: false,
            branding: false,
            inline: inline || preview,
            height: 500,
            menubar: false,
            plugins:
              "lists autoresize autolink image link media table wordcount imagetools code paste media",
            // selector: 'textarea',
            max_height: 500,
            content_css: "/css/_tinymce.css",
            toolbar1: getToolbarSet(type),
            setup: setupEditor,
            placeholder,
            file_picker_types: "image",
            file_picker_callback: function(callback, value, meta) {
              let input: any = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.onchange = function() {
                let file = input.files[0];
                let reader = new FileReader();
                reader.onload = function(e: any) {
                  callback(e.target.result, {
                    alt: file.name,
                  });
                };
                reader.readAsDataURL(file);
                const imageFile = new FormData();
                imageFile.append("file", file);
                uploadFile(imageFile, team.office, undefined);
              };
              input.click();
            },
            image_description: false,
          }}
          onEditorChange={onChangeHandler}
          onBlur={onblurHandler}
          onInit={onLoaded}
        />
        {charactersLimit && !preview && (
          <p
            className={clsx("charactersLimit-info mce-wordcount", {
              "text-danger": textCount >= charactersLimit,
            })}
          >
            Characters limit: {charactersLimit} characters, current number of
            characters: {textCount}
          </p>
        )}
        <Modal
          show={showWarning}
          onHide={() => setShowWarning(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <CloseIcon
              fontSize={"large"}
              onClick={() => setShowWarning(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <Typography component="h5" variant="h5" align="center">
              You have exceeded the character count limit. Please note, this is
              only relevant for print purposes. If you are sending the proposal
              digitally, you can disregard.
            </Typography>
          </Modal.Body>
        </Modal>
      </div>
    </>
    /* Provide Redux store */
  );
};

export default EditorComponent;

export const editorToolbar: { [key: string]: string } = {
  comparables: "bold italic | numlist bullist | code link",
  coverLetter:
    "undo redo | bold italic | alignleft aligncenter alignright  | numlist bullist outdent indent  | code image link | removeformat",
  methodOfSale:
    "bold italic | alignleft aligncenter alignright  | numlist bullist | code image link | removeformat",
  article:
    "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | code modal table image link | removeformat",
  settings:
    "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | code table image link | removeformat",
  messages:
    "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | code table image link media | removeformat",
  default:
    "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | code modal table image link | removeformat",
};
