import axios from "axios";
import API_URL from "./api";

export default class MetricsService {
  static postMetric = body => {
    return axios.post(`${API_URL}/metrics`, body);
  };

  static getMetrics = (appraisalId, sessionId = "") => {
    return axios.get(
      `${API_URL}/metrics?appraisalId=${appraisalId}&sessionId=${sessionId}`,
    );
  };

  static getSession = sessionId => {
    return axios.get(`${API_URL}/metrics/${sessionId}`);
  };

  static getAverageTime = weeks => {
    return axios.get(`${API_URL}/metrics/average_time?weeks=${weeks}`);
  };

  static getActionsClicks = () => {
    return axios.get(`${API_URL}/metrics/clicks`);
  };

  static getAverageOpens = () => {
    return axios.get(`${API_URL}/metrics/average_articles`);
  };

  static getArticles = (limit = 3, page) => {
    return axios.get(`${API_URL}/metrics/articles?limit=${limit}&page=${page}`);
  };
}
