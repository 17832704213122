import { API_URL } from "../../Auth/_redux/authCrud";
import { APPRAISAL_TYPES } from "app/constants/appraisalsConstants";

const propertyDescription = {
  sales: `<p>Introduction to property...</p>
  <p>Key Selling Features:</p>
  <ul>
    <li>Example</li>
    <li>Example</li>
    <li>Example</li>
  </ul>
  <p>Target Buyers:</p>
  <ul>
    <li>Example</li>
  </ul>
`,
  lease: `<p>Introduction to property...</p>
  <p>Key Marketing Features:</p>
  <ul>
    <li>Example</li>
    <li>Example</li>
    <li>Example</li>
  </ul>
  <p>Target Tenants:</p>
  <ul>
    <li>Example</li>
  </ul>
`,
};

export const convertPropertyData = (data, classification, category) => {
  const coordinates = data.geolocation
    ? { lon: data.geolocation.longitude, lat: data.geolocation.latitude }
    : {};

  const getSpace = ({ value, units }) => {
    if (!!value) {
      if (units) return value + " " + units;
      return value;
    }
    return "";
  };

  const buildingSize =
    data.class.name === "Land" ? "" : getSpace(data?.floorArea);
  const landSize = getSpace(data?.landArea);
  const carSpaces =
    classification === "residential"
      ? data.carports + data.garages + data.openSpaces
      : data.carSpaces;

  return {
    address: data.address,
    displayAddress: data.displayAddress,
    agentPriceOpinion: convertPriceString(data.agentPriceOpinion),
    coordinates,
    bedrooms: data.bed,
    bathrooms: data.bath,
    carSpaces: carSpaces,
    buildingSize,
    landSize,
    commercialCarSpaces: 0, // ????
    propertyType: data.type.name,
    about: {
      image: data.photos[0]?.url,
      description: data?.description || propertyDescription[category],
    },
    suburbHidden: false,
    feedbackHidden: false,
    photos: data.photos,
    buildingName: data.building?.name,
  };
};

// this is a short version of convertPropertyData for sync functionality
export const mapPropertyData = (data, category, descr, price) => {
  const coordinates = data.geolocation
    ? { lon: data.geolocation.longitude, lat: data.geolocation.latitude }
    : {};

  const getSpace = ({ value, units }) => {
    if (!!value) {
      if (units) return value + " " + units;
      return value;
    }
    return "";
  };

  const buildingSize =
    data.class.name === "Land" ? "" : getSpace(data?.floorArea);
  const landSize = getSpace(data?.landArea);

  return {
    address: data.address,
    displayAddress: data.displayAddress,
    agentPriceOpinion: price || convertPriceString(data.agentPriceOpinion),
    coordinates,
    bedrooms: data.bed,
    bathrooms: data.bath,
    carSpaces: data.carports + data.garages,
    buildingSize,
    landSize,
    commercialCarSpaces: 0, // ????
    propertyType: data.type.name,
    about: {
      image: data.photos[0]?.url,
      description: descr || data?.description || propertyDescription[category],
    },
    photos: data.photos,
  };
};

export const convertPriceString = string => {
  let price;
  if (string) {
    price = string
      .toString()
      .replace(/[,.]/g, "")
      .match(/\d+/g);
    if (price) {
      price = price
        .map(Number)
        .map(n => getPrice(n))
        .join(" - ");
    }
  }
  return price === null ? string : price || "$000,000 - $000,000";
};

export const getPrice = price => {
  if (!price) return null;
  if (!/^\d+(\.[0-9]+)*$/.test(price)) return price;
  `${price}`.replace(/w*\.0\b/, "");
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
    .format(price)
    .replace(/\D00(?=\D*$)/, "");
};

export const getLandSize = land => {
  if (!land) return "";
  if (`${land}`.length === `${land}`.replace(/[^0-9.,]/g, "").length) {
    return `${land} sqm`;
  } else {
    return land;
  }
};

export const getTeamData = (
  teamData,
  officeName,
  officeAddress,
  officePhone,
) => {
  const getLeaderName = leader => {
    let firstname = leader[0]?.firstname;
    let lastname = leader[0]?.lastname;
    return firstname ? firstname + " " + (lastname || "") : lastname || "";
  };
  return {
    name: teamData.name,
    phone: teamData.phone,
    email: teamData?.email || teamData?.leader?.email,
    office: teamData.office,
    officeName,
    officeAddress,
    officePhone,
    leaderName: getLeaderName(teamData.leader),
    biography: teamData.biography,
    profilePortrait: teamData.profilePortrait,
    profileLink: teamData.profileLink,
    recentSales: teamData.recentSales,
    currentRentals: teamData.currentRentals,
    secondLeaderName: null,
    secondLeaderPhone: null,
    secondBiography: null,
    secondProfilePortrait: null,
    about: teamData.about,
    aboutHidden: false,
    image: teamData.image,
    report: teamData.report,
    reportHidden: false,
    reportImage: teamData.reportImage,
    videos: teamData.videos,
    twitter: teamData.twitter,
    linkedIn: teamData.linkedIn,
    instagram: teamData.instagram,
    facebook: teamData.facebook,
    id: teamData.id,
    marketingBrochureLink: teamData.marketingBrochureLink,
    propertyForRent: teamData.propertyForRent,
    propertyForSale: teamData.propertyForSale,
    departmentCategory: teamData.departmentCategory,
    isAutoSaveProposal: teamData.isAutoSaveProposal,
  };
};

export const populateVendorDetails = ({ items, contacts }) => {
  let vendor;
  if (items) {
    if (items[0]) {
      vendor = {
        name: `${items[0].firstName}`,
        phone: items[0]?.phoneNumbers[0]
          ? items[0]?.phoneNumbers[0].number
          : "",
        email: items[0]?.emails[0],
      };
      if (items[0].partnerFirstName && items[0].partnerFirstName !== "") {
        vendor.name += ` & ${items[0]?.partnerFirstName}`;
        if (items[0]?.phoneNumbers[1] !== undefined) {
          vendor.phone += `, ${items[0]?.phoneNumbers[1].number}`;
        }
        if (items[0].emails[1]) {
          vendor.email += `, ${items[0]?.emails[1]}`;
        }
      }
    } else {
      vendor = {
        name: "",
        phone: "",
        email: "",
      };
    }
  } else {
    vendor = {
      name: `${contacts[0].firstname}`,
      phone: contacts[0].mobile,
      email: contacts[0].email,
    };
  }

  return vendor;
};

export const populateOwnersDetails = ({ items, contacts }) => {
    let owners = [];
    if (items) {
      if (items[0]) {
       let vendor = {
          firstName: `${items[0].firstName}`,
          lastName: `${items[0].lastName}`,
          phone: items[0]?.phoneNumbers[0]
              ? items[0]?.phoneNumbers[0].number
              : "",
          email: items[0]?.emails[0],
        };
        owners.push(vendor);
        if (items[0].partnerFirstName && items[0].partnerFirstName !== "") {
          let partner = {
            firstName: `${items[0].partnerFirstName}`,
            lastName: `${items[0].partnerLastName}`,
            phone: items[0]?.phoneNumbers[1]
              ? items[0]?.phoneNumbers[1].number
              : "",
            email: items[0]?.emails[1],
          };
          owners.push(partner);
        }
      } else {
        owners =[ {
          name: "",
          phone: "",
          email: "",
        }];
      }
    } else {
      owners = [{
        name: `${contacts[0].firstname}`,
        phone: contacts[0].mobile,
        email: contacts[0].email,
      }];
    }

    return owners;
};

export const getSendBody = ({
  type,
  vendor,
  property,
  meta,
  team,
  recipient,
  buttonText,
  owners,
  isCommercial = false,
}) => {
  return {
    client: {
      name: vendor.name,
      email: vendor.email,
      phone: vendor.phone,
      address: property.displayAddress,
    },
    owners: owners,
    agent: {
      name: team.leaderName,
      officeName: team.officeName,
      team: meta.team,
      mobile: team.phone,
    },
    coverLetter:
      recipient.email.message !== null || recipient.email.message !== ""
        ? recipient.email.message
        : null,
    smsMessage: recipient.phone.message,
    subject: recipient.email.subject,
    type: type,
    propertyCategory: meta.category === "sales" ? "sale" : "lease",
    buttonText,
    isCommercial,
  };
};

export const populateMarketingOptions = (office, category, description) => {
  if (category === "sales") {
    return {
      option_title: office.marketing_settings?.option_title || "",
      extra_items: office.marketing_settings?.extra_options || [],
      description: description,
    };
  } else {
    return {
      option_title: office.marketing_lease_settings?.option_title || "",
      extra_items: office.marketing_lease_settings?.extra_options || [],
      description: description,
    };
  }
};

export const populateCalendar = (office, isSale, selectedCalendar) => {
  const baseItems = [
    { text: "Approve Marketing Material" },
    { text: "Local Paper Ad" },
    { text: "Open for Inspection TBA" },
    { text: "Open House buyer call backs" },
    { text: "Weekly Report Update" },
    { text: "Auction Invitations sent" },
    { text: "Auction buyers contacted" },
    { text: "Auction Date" },
  ];
  const calendarText =
    `Our goal is to make the ${
      isSale ? "sales" : "rental"
    } process as simple and clear` +
    ` as possible for you. The following is the proposed timeline of your property’s ${
      isSale ? "sales" : "rental"
    } campaign:`;
  let timeline_settings;
  if (selectedCalendar !== "") {
    const calendar = office?.timeline_settings?.find(
      el => el._id === selectedCalendar,
    );
    timeline_settings = calendar ? calendar.items : baseItems;
  } else {
    timeline_settings = office?.timeline_settings?.length
      ? office?.timeline_settings[0].items
      : baseItems;
  }

  const calendarItems = timeline_settings.map(el => ({
    date: new Date(),
    text: el.text,
  }));
  return {
    items: calendarItems,
    text: calendarText,
    selectedCalendar:
      selectedCalendar !== ""
        ? selectedCalendar
        : office?.timeline_settings?.[0]?.title || "",
  };
};

export const getTestimonials = data => {
  if (!data) return [];
  if (data.items) return data.items;
  if (!data.body) return [];
  const rawArrays = data.body.split("<p><strong>");
  rawArrays.splice(0, 1);
  return rawArrays.map(item => {
    const testimonial = item.split("<p>");
    testimonial.splice(3);
    const final = testimonial.map(i => i.replace(/<.*?>|"|\n|&nbsp;/gim, ""));
    return { quote: final[1], name: final[2].replace("- ", "") };
  });
};

export const getYoutubeParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const populateStateInfo = state => {
  const suburbsInfo = {
    NSW: {
      title: "New South Wales Fair Trading",
      link: "www.fairtrading.nsw.gov.au",
      phone: "13 32 20",
      logo: "nsw.png",
    },
    SA: {
      title: "South Australia Consumer and Business Services",
      link: "www.cbs.sa.gov.au",
      phone: "131 882",
      logo: "sa.png",
    },
    TAS: {
      title: "Tasmania Consumer Affairs and Fair Trading",
      link: "www.cbos.tas.gov.au",
      phone: "1300 65 44 99",
      logo: "tas.png",
    },
    VIC: {
      title: "Victoria Consumer Affairs",
      link: "www.consumer.vic.gov.au",
      phone: "1300 55 81 81",
      logo: "vic.png",
    },
    NT: {
      title: "Northern Territory",
      link: "www.nt.gov.au",
      phone: "0000 0000",
      logo: "nt.png",
    },
    WA: {
      title: "Western Australia",
      link: "www.commerce.wa.gov.au",
      phone: "1300 304 054",
      logo: "wa.png",
    },
    QLD: {
      title: "Queensland Fair Trading",
      link: "www.qld.gov.au/law/fair-trading",
      phone: "13 74 68",
      logo: "qld.png",
    },
  };

  return suburbsInfo[state];
};

export const getDefaultSuburbsCoordinates = state => {
  const suburbsInfo = {
    NSW: {
      lat: -33.064443,
      lon: 146.437681,
      zoom: 5,
    },
    SA: {
      lat: -29.244567,
      lon: 134.503018,
      zoom: 7,
    },
    TAS: {
      lat: -42.198896,
      lon: 146.664045,
      zoom: 7,
    },
    VIC: {
      lat: -36.813218,
      lon: 144.192274,
      zoom: 7,
    },
    NT: {
      lat: -20.19074,
      lon: 133.356765,
      zoom: 7,
    },
    WA: {
      lat: -27.037237,
      lon: 121.687683,
      zoom: 5,
    },
    QLD: {
      lat: -23.235156,
      lon: 144.480798,
      zoom: 5,
    },
  };

  return suburbsInfo[state];
};

export const getNextStepActionTitle = type => {
  const typesCollections = {
    agentProfiling: "Contact Me",
    marketUpdate: "What’s my property worth?",
    preAppraisal: "Contact Me",
    listingProposal: "I'd like to proceed with Raine & Horne",
    prospecting: "Contact Me",
    commercialListingProposal: "Contact Me",
    businessListingProposal: "I'd like to proceed with Raine & Horne",
    tenantHandbook: "Contact Me",
  };
  return typesCollections[type];
};

export const getImage = id => {
  if (!id) return null;
  return id.toString().includes("/") ? id : `${API_URL}img/${id}`;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getCommercialArticles = (articles, type) => {
  const result = {};
  const content = {
    [APPRAISAL_TYPES.informationMemorandum]: [
      {
        slug: "executive-summary",
        category: "commercial-information-memorandum",
        key: "executiveSummary",
      },
      {
        slug: "the-opportunity",
        category: "commercial-information-memorandum",
        key: "opportunity",
      },
      {
        slug: "property-overview",
        category: "commercial-information-memorandum",
        key: "propertyOverview",
      },
      {
        slug: "investment-highlights",
        category: "commercial-information-memorandum",
        key: "investmentHighlights",
      },
      {
        slug: "location-overview",
        category: "commercial-information-memorandum",
        key: "locationOverview",
      },
      {
        slug: "market-overview",
        category: "commercial-information-memorandum",
        key: "marketOverview",
      },
      {
        slug: "tenant-overview",
        category: "commercial-information-memorandum",
        key: "tenantOverview",
      },
      {
        slug: "financial-overview",
        category: "commercial-information-memorandum",
        key: "financialOverview",
      },
      {
        slug: "salelease-process",
        category: "commercial-information-memorandum",
        key: "saleLeaseProcess",
      },
      {
        slug: "price-list",
        category: "commercial-information-memorandum",
        key: "priceList",
      },
      {
        slug: "disclaimer",
        category: "commercial-information-memorandum",
        key: "disclaimer",
      },
    ],
    [APPRAISAL_TYPES.residentialInformationMemorandum]: [
      {
        slug: "executive-summary",
        category: "residential-information-memorandum",
        key: "executiveSummary",
      },
      {
        slug: "the-opportunity",
        category: "residential-information-memorandum",
        key: "opportunity",
      },
      {
        slug: "property-overview",
        category: "residential-information-memorandum",
        key: "propertyOverview",
      },
      {
        slug: "investment-highlights",
        category: "residential-information-memorandum",
        key: "investmentHighlights",
      },
      {
        slug: "location-overview",
        category: "residential-information-memorandum",
        key: "locationOverview",
      },
      {
        slug: "market-overview",
        category: "residential-information-memorandum",
        key: "marketOverview",
      },
      {
        slug: "tenant-overview",
        category: "residential-information-memorandum",
        key: "tenantOverview",
      },
      {
        slug: "financial-overview",
        category: "residential-information-memorandum",
        key: "financialOverview",
      },
      {
        slug: "salelease-process",
        category: "residential-information-memorandum",
        key: "saleLeaseProcess",
      },
      {
        slug: "price-list",
        category: "residential-information-memorandum",
        key: "priceList",
      },
      {
        slug: "disclaimer",
        category: "residential-information-memorandum",
        key: "disclaimer",
      },
    ],
  };
  const expectedArticles = content[type];
  expectedArticles.forEach(({ slug, category, key }) => {
    const foundArticle = articles.find(
      data => data.active && data.slug === slug && data.category === category,
    );
    if (foundArticle) {
      const { body, title, image, active } = foundArticle;
      result[key] = { body, title, image, active, hidden: false };
    }
  });
  return result;
};
