import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  withStyles,
  makeStyles,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { Toast } from "primereact/toast";
import produce from "immer";
import cn from "classnames";

import { Modal, ModalActions } from "../../../components/Modal";
import SearchToolbar from "./SearchToolbar";
import { updateComparables } from "../../../../redux/reducers";
import ComparableService from "../../../api/ComparableService";
import PropertiesList from "./PropertieList";
import { convertAddress } from "./utlis";
import OfficesService from "../../../api/OfficesService";
import { useBusinessListing } from "app/hooks";
import {useNz} from "../../../hooks/useNz";

const useStyles = makeStyles(theme => ({
  tabContainer: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.background.gray2,
      color: "#333",
      fontWeight: 600,
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& button": {
      textTransform: "none",
      fontSize: 12,
    },
  },
  body: {
    height: "calc(100vh - 300px)",
    minHeight: 170,
  },
  loadingBar: {
    position: "absolute",
    top: 198,
    width: "100%",
  },
  loading: {
    opacity: 0.5,
  },
}));

const CustomizedDialogTitle = withStyles({
  root: {
    padding: 0,
    position: "relative",
  },
})(DialogTitle);

const marketTypes = {
  rh: "rh",
  rhleased: "rhleased",
  off: "off",
  on: "on",
};

const initState = {
  search: [],
  selected: [],
  showLoadMore: false,
  totalCount: 0,
  notActualSearch: false,
};

export const ComparableModal = ({
  property,
  show,
  onHide,
  classification,
  activeTabIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const searchOptions = useRef({});
  const { isBusinessListing } = useBusinessListing();

  const [marketType, setMarketType] = useState(marketTypes.rh);

  const [rhList, setRhList] = useState(initState);
  const [onList, setOnList] = useState(initState);
  const [offList, setOffList] = useState(initState);
  const [rhleasedList, setRhleasedList] = useState(initState);

  const [isLoading, setLoading] = useState(false);

  const { isNz } = useNz();

  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );
  const comparables = useSelector(
    ({ appraisal }) => appraisal.comparables,
    shallowEqual,
  );
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );
  const officeId = useSelector(
    ({ appraisal }) => appraisal.meta.officeVaultreId,
    shallowEqual,
  );
  const office = useSelector(
    ({ appraisal }) => appraisal.meta.office,
    shallowEqual,
  );
  const country = useSelector(
    ({ appraisal }) => appraisal.country,
    shallowEqual,
  );

  const tabs = {
    [marketTypes.rh]: [rhList, setRhList],
    [marketTypes.on]: [onList, setOnList],
    [marketTypes.off]: [offList, setOffList],
    [marketTypes.rhleased]: [rhleasedList, setRhleasedList],
  };
  const [activeTabValues, setActiveTabValues] = tabs[marketType];

  const isRental = category !== "sales";

  useEffect(() => {
    setRhList({ ...initState, selected: comparables.raineandhorne || [] });
    setOnList({ ...initState, selected: comparables.on || [] });
    setOffList({ ...initState, selected: comparables.off || [] });
    setRhleasedList({ ...initState, selected: comparables.rhleased || [] });
    const openTab = Object.values(marketTypes)[activeTabIndex];
    setMarketType(openTab);
  }, [comparables, show, activeTabIndex]);

  const handleTabChange = (event, newValue) => setMarketType(newValue);

  const saveAllChanges = () => {
    dispatch(
      updateComparables({
        raineandhorne: rhList.selected,
        on: onList.selected,
        off: offList.selected,
        rhleased: rhleasedList.selected,
      }),
    );
    onHide();
  };

  const onSearch = async (searchByAddress, options, loadMore) => {
    options.page = options.page || 1;
    searchOptions.current = { searchByAddress, options };
    const {
      name,
      postcode,
      state: { abbreviation: state },
    } = property.address?.suburb;
    const { lat, lon } = property?.coordinates;
    options.country = country;
    options.classification = classification;
    options.locality = `${name} ${state} ${postcode}`;
    options.query = `${name}, ${state} ${postcode}`;
    options.officeId = officeId;
    options.type = marketType;
    options.category = isRental ? "lease" : "sales";
    options.radius_type = isRental
      ? marketType === marketTypes.on
        ? "for-lease"
        : "leased"
      : marketType === marketTypes.on
      ? "for-sale"
      : "sold";
    if (
      isRental &&
      (marketType === marketTypes.rhleased || marketType === marketTypes.rh)
    ) {
      options.status = marketType === marketTypes.rhleased ? "sold" : "active";
    }

    let searchRequest;
    if (searchByAddress) {
      searchRequest =
        marketType === marketTypes.rh
          ? ComparableService.getRhPropertiesByAddress
          : ComparableService.getPropertiesByAddress;
    } else {
      options.lat = lat;
      options.lon = lon;
      searchRequest = ComparableService.getProperties;
      if (appraisalType === "agentProfiling") {
        const { data } = await OfficesService.getOffice(office);
        if (
          marketType === marketTypes.rh ||
          marketType === marketTypes.rhleased
        ) {
          const {
            name,
            postcode,
            state: { abbreviation: state },
          } = data.suburb;
          options.query = `${name}, ${state} ${postcode}`;
          searchRequest = ComparableService.getRhProperties;
        } else {
          options.lon = data.location.coordinates[0];
          options.lat = data.location.coordinates[1];
          searchRequest = ComparableService.getProperties;
        }
      } else {
        if (!lat || !lon)
          searchRequest = ComparableService.getPropertiesByLocality;
        if (
          marketType === marketTypes.rh ||
          marketType === marketTypes.rhleased
        )
          searchRequest = ComparableService.getRhProperties;
      }
    }

    setLoading(true);
    searchRequest(options)
      .then(({ data }) => {
        const selectedIds = activeTabValues.selected.map(item => item.id);
        const selectedAddresses = activeTabValues.selected.map(
          item => item.address,
        );
        let filteredData = data?.properties?.filter(item =>
          item.id
            ? !selectedIds.includes(item.id)
            : !selectedAddresses.includes(item.address),
        );

        filteredData = filteredData.map(item => ({
          ...item,
          ...{ address: convertAddress(item.address) },
        }));
        setActiveTabValues(prevState => {
          const search = loadMore
            ? [...prevState.search, ...filteredData]
            : filteredData;
          const totalCount = data?.pageInfo?.totalElements || 0;
          const showLoadMore = search.length < totalCount;
          return { ...prevState, search, totalCount, showLoadMore };
        });
      })
      .catch(err => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 4000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMore = () => {
    const { searchByAddress, options } = searchOptions.current;
    options.page += 1;
    onSearch(searchByAddress, options, true);
  };

  const onAddItem = (newItem, index) => {
    if (activeTabValues.selected.length === 9) {
      return toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail:
          "The maximum number of 9 comparable properties has already been selected",
        life: 4000,
      });
    }
    setActiveTabValues(prevState => {
      const search = produce(prevState.search, draft => {
        draft.splice(index, 1);
      });
      const selected = [...prevState.selected, newItem];
      return { ...prevState, search, selected };
    });
  };

  const onDeleteItem = (item, index) => {
    setActiveTabValues(prevState => {
      const selected = produce(prevState.selected, draft => {
        draft.splice(index, 1);
      });
      const search = [item, ...prevState.search];
      return { ...prevState, search, selected };
    });
  };

  return (
    <Modal open={show} onClose={onHide} maxWidth="xl" fullWidth>
      <CustomizedDialogTitle>
        <Tabs
          centered
          value={marketType}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{ root: classes.tabContainer }}
        >
          <Tab
            disableRipple
            value={marketTypes.rh}
            label={category === "sales" ? "R&H Sales" : "R&H For Lease"}
          />
          {category === "sales" && !isBusinessListing && (
            <Tab
              disableRipple
              value={marketTypes.off}
              label="RP Data Market Sales"
            />
          )}
          {category === "lease" && !isBusinessListing && (
            <Tab
              disableRipple
              value={marketTypes.rhleased}
              label="R&H Leased"
            />
          )}
          {isBusinessListing || (category === "lease" && isNz) ? (
            ""
          ) : (
            <Tab
              disableRipple
              value={marketTypes.on}
              label="RP Data On The Market"
            />
          )}
        </Tabs>
      </CustomizedDialogTitle>

      <SearchToolbar
        onSearch={onSearch}
        marketType={marketType}
        classification={classification}
      />
      {isLoading && (
        <LinearProgress color="primary" className={classes.loadingBar} />
      )}
      <div className={cn(classes.body, { [classes.loading]: isLoading })}>
        <PropertiesList
          searchItems={activeTabValues.search}
          selectedItems={activeTabValues.selected}
          loadMore={loadMore}
          showLoadMore={activeTabValues.showLoadMore}
          totalItems={activeTabValues.totalCount}
          onAddItem={onAddItem}
          onDeleteItem={onDeleteItem}
          isRental={isRental}
        />
      </div>
      <ModalActions
        onCancel={onHide}
        onSubmit={saveAllChanges}
        submitTitle="Save and Close"
      />
      <Toast ref={toast} />
    </Modal>
  );
};
