import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import ImageList from "./index";
import { makeStyles, Tab, Tabs } from "@material-ui/core";

const useStyles = makeStyles({
  modalWidth: {
    width: '921px',
    maxWidth: 'none !important'
  }
})

const ModalImageLibrary = ({ show, onHide, officeId, aspectRatio, onSelect, title, imageFolders }) => {
  const [ activeTab, setActiveTab ] = useState(0);

  const classes = useStyles();
  return (
    <Modal
      dialogClassName={classes.modalWidth}
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title>
          {title}
        </Modal.Title>
        <CloseIcon fontSize={'large'} onClick={onHide} className='cursor-pointer'/>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="Tabs"
        >
          {imageFolders.map( folder =>
            <Tab label={folder.title} key={folder.title}/>
          )}
        </Tabs>
        <ImageList
          aspectRatio={aspectRatio}
          officeId={officeId}
          onSelect={onSelect}
          parentFolderName={imageFolders[activeTab].value}
          shared={imageFolders[activeTab].shared}
        />
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(ModalImageLibrary);
