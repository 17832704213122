import {
  formatDateISO8601ToDdMmYy,
  formatDateISO8601ToHHmm,
  convertSecToHhMmSs,
  formatDateISO8601ToFullDate,
  convertSecToFormattedStr,
} from 'shared/utils';
import { PROPOSAL_SESSION_METRICS_TABLE } from 'app/constants/proposalsConstants';

export const getProposalTitle = ({ metrics }) => metrics.proposalTitle;
export const getProposalOpens = ({ metrics }) => metrics.sessions.length;
export const getSessionsDurationTotal = ({ metrics }) => convertSecToFormattedStr(metrics.sessionsDurationTotalSec);
export const getProposalPrints = ({ metrics }) => metrics.prints;
export const getLeaderName = ({ metrics }) => metrics.leaderName;
export const getLeaderImageId = ({ metrics }) => metrics.leaderImageId;
export const getOfficeName = ({ metrics }) => metrics.officeName.replace('Raine & Horne ', '');
export const getIsLoadSessions = ({ metrics }) => metrics.isLoadSessions;
export const getIsLoadSessionData = ({ metrics }) => metrics.isLoadSessionData;
export const getIsCurrentSessionActionButtonClicked = ({ metrics }) => metrics.currentSessionData.requestAgencyAgreement;
export const getVisited = ({ metrics }) => metrics.visited;
export const getVisitedBy = ({ metrics }) => metrics.visitedBy;
export const getCountry = ({ metrics}) => metrics.isNz;

export const getCurrentSessionData = ({ metrics }) => {
  const changeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  const data = metrics.currentSessionData;
  const sessionData = {};
  if (data.articleOpens) {
    PROPOSAL_SESSION_METRICS_TABLE.forEach(property => {
      sessionData[property.key] = {};
      if (property.key === 'articleOpens') {
        data[property.key].forEach(item => {
          const key = changeFirstLetter(item.data);
          sessionData[property.key][key] = sessionData[property.key][key] ?
            sessionData[property.key][key] + item.duration : item.duration;
        });
      } else {
        data[property.key].forEach(item => {
          const key = changeFirstLetter(item.data);
          sessionData[property.key][key] = sessionData[property.key][key] ?
            sessionData[property.key][key] + 1 : 1;
        });
      }
    });
    for (let key in sessionData) {
      sessionData[key] = Object.entries(sessionData[key]);
    }
    sessionData['articleOpens'].forEach(article => article[1] = convertSecToHhMmSs(article[1]));
  }
  return sessionData;
};

export const getIsActionButtonClicked = ({ metrics }) => {
  let isClicked = false;
  metrics.allEvents.forEach(event => {
    if (event.type === 'Request Agency Agreement') {
      isClicked = true;
    }
  });
  return isClicked;
};

export const getSessions = ({ metrics }) => {
  if (metrics.sessions.length) {
    const sessions = metrics.sessions.map(session => ({
      date: `${formatDateISO8601ToFullDate(session.created_at)}, ${formatDateISO8601ToHHmm(session.created_at)}`,
      id: session.sessionId,
      duration: convertSecToHhMmSs(session.duration),
      clicks: 0,
      printed: 0,
      recipient: session.recipient,
    }));

    metrics.allEvents.forEach(event => {
      if (event.type === 'print') {
        const session = sessions.find(session => ((session?.id?.current === event.sessionId) || (session?.id === event.sessionId)));
        if(session) {
          session.printed += 1;
        }
      }
      if (event.type !== 'session' && event.type !== 'print') {
        const session = sessions.find(session => ((session?.id?.current === event.sessionId) || (session?.id === event.sessionId)));
        if(session) {
          session.clicks += 1;
        }
      }
    });
    return sessions.reverse();
  } else {
    return [];
  }
};

export const getSendHistory = ({ metrics }) => {
  const historyData = {};
  metrics.sendHistory.sendHistory && metrics.sendHistory.sendHistory.forEach(item => {
    const sendType = item.sendType === 'email' ? 'Email' : 'SMS';
    historyData[item.client.contactInfo] = {
      ...historyData[item.client.contactInfo],
      sendType: sendType,
      date: formatDateISO8601ToDdMmYy(item.created_at),
      contactInfo: item.client.contactInfo,
      name: item.client.name,
    };
  });
  return Object.entries(historyData);
};

export const getProposalLastUpdated = ({ metrics }) => {
  if (metrics.updated) {
    const date = formatDateISO8601ToDdMmYy(metrics.updated);
    const time = formatDateISO8601ToHHmm(metrics.updated);
    return { date, time };
  }
  return {};
};

export const getVisitedChanged = ({ metrics }) => {
  if (metrics.visitedChanged) {
    const date = formatDateISO8601ToDdMmYy(metrics.visitedChanged);
    const time = formatDateISO8601ToHHmm(metrics.visitedChanged);
    return { date, time };
  }
  return {};
};

export const getProposalFirstOpen = ({ metrics }) => {
  if (metrics.firstOpen) {
    const date = formatDateISO8601ToDdMmYy(metrics.firstOpen);
    const time = formatDateISO8601ToHHmm(metrics.firstOpen);
    return { date, time };
  }
  return {};
};
export const getProposalLatestOpen = ({ metrics }) => {
  return metrics.latestOpen.length ? formatDateISO8601ToDdMmYy(metrics.latestOpen) : '';
};

export const getSessionsAverageReadingTime = (state) => {
  const sessionsNumber = getProposalOpens(state);
  const sessionsDurationTotalSec = state.metrics.sessionsDurationTotalSec;
  return convertSecToFormattedStr(Math.trunc(sessionsDurationTotalSec / sessionsNumber));
};

export const getComparablesEditDetails = ({ metrics }) => {
  const editData = [];
  for (let item in metrics.proposal.comparables) {
    if (item === "on" || item === "off") {
      let onComparables = metrics.proposal.comparables[item];
      onComparables.forEach(comp => {
        if (!comp.manually && !!comp.editData) {
          editData.push({ ...comp.editData, source: "RP", address: comp.address });
        }
      });
    }
    if (item === "raineandhorne") {
      let rhComparables = metrics.proposal.comparables[item];
      rhComparables.forEach(comp => {
        if (!comp.manually && !!comp.editData) {
          editData.push({ ...comp.editData, source: "RH", address: comp.address });
        }
      });
    }
  }
  return editData;
};
