import axios from 'axios';
import API_URL from './api';

export default class SuburbsService {

  static getSuburbs = (office, search, page) => {
    return axios.get(
      `${API_URL}/suburbs?office=${office}&search=${search}&page=${page}`
    );
  }

  static getCoordinates = async address => {
    if (!address?.trim()) return {}
    const gMaps = window.google.maps;
    const geocoder = new gMaps.Geocoder();
    let lat, lng;
    try {
      await geocoder.geocode({ address }, (results, status) => {
        if (status === gMaps.GeocoderStatus.OK) {
          lng = results[0]?.geometry.location.lng();
          lat = results[0]?.geometry.location.lat();
        }
      }
    )
    } catch (e) {
      console.log(e)
    }
    return { lat, lng }
  }

}
