import { Button as PrButton } from "primereact/components/button/Button";
import { Dialog } from "primereact/dialog";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

const SubmitDeleteTeam = ({ show, onHide, onSubmit }) => {
  const deleteFooter = (
    <>
      <PrButton label="No" icon="pi pi-times" className="p-button-text" onClick={onHide} />
      <PrButton label="Yes" icon="pi pi-check" className="p-button-text" onClick={onSubmit} />
    </>
  )
  return (
    <Dialog visible={show} style={{ width: "450px" }} header="Confirm" modal footer={deleteFooter} onHide={onHide}>
      <div className="confirmation-content d-flex align-items-center">
        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
        <span>Are you sure you want to delete this image?</span>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    height: '100%',
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    background: '#fff',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '50%',
    width: 30,
    height: 30,
    cursor: 'pointer',
    "&:hover": {
      borderColor: theme.palette.background.gray2,
    },
    "&>i": {
      position: 'absolute',
      top: 15,
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
}))

const ActionsWrapper = ({ children, onEdit, onDelete, withDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const classes = useStyles();
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onSubmitDelete = () => {
    setShowDelete(false);
    onDelete();
  }

  const onShowEdit = () => {
    handleClose();
    onEdit();
  }

  const onShowDelete = () => {
    handleClose();
    setShowDelete(true);
  }

  if (!withDelete) {
    return (
      <>
        <label
          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="change"
          data-toggle="tooltip"
          title="Change image"
          data-original-title="Change image"
          onClick={onEdit}
        >
          <i className="fa fa-pen icon-sm text-muted"/>
        </label>
        {children}
      </>
    )
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div onClick={handleClick} className={classes.menu}>
          <i className="ki ki-bold-more-hor" />
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center'  }}
        >
          <MenuItem onClick={onShowEdit}>Edit</MenuItem>
          <MenuItem onClick={onShowDelete}>Delete</MenuItem>
        </Menu>
        {children}
      </div>
      <SubmitDeleteTeam
        show={showDelete}
        onHide={() => setShowDelete(false)}
        onSubmit={onSubmitDelete}
      />
    </>
  )
};

export default ActionsWrapper;
