import { useMemo } from "react";
import { useSelector } from "react-redux";

import { isCorporate } from "shared";

export const useGetUser = () => {
  const user = useSelector(({ auth }) => auth.user);

  const { permissions } = user || {};
  const isCorporateUser = useMemo(() => isCorporate(permissions), [
    permissions,
  ]);

  return {
    user,
    officeId: user.office?._id,
    isCorporateUser,
  };
};
