import React, {useContext} from "react";
import { Container, makeStyles } from "@material-ui/core";

import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  updateArticleBody,
  updateArticleStatus,
  updateArticleTitle,
} from "redux/reducers/appraisalReducer";
import Attachments from "../../Commercial/Attachments";
import TenantArticleSection from "../TenantArticleSection";
import NextStepAction from "../NextSteps/NextStepAction";
import {AppraisalContext} from "../../AppraisalContext";


const useStyles = makeStyles(() => ({
  smallFontSize: {
    "& .mce-content-body p": {
      fontSize: 12,
      lineHeight: "18px",
      textAlign: "justify",
    },
  },
  customCommercialTable: {
    "& .mce-content-body table": {
      border: "none",
      "& td": {
        border: "none",
      },
      "& tr": {
        border: "none",
        borderBottom: "1px solid #d9c672",
      },
    },
  },
}));

const TenantHandbook = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { preview } = useContext(AppraisalContext);

  const articles = useSelector(
    ({ appraisal }) => appraisal.articles,
    shallowEqual,
  );

  const officeData = articles?.find(item => item.slug === "office-information");
  const responsibilities = articles?.find(
    item => item.slug === "tenant-responsibilities",
  );
  const payments = articles?.find(item => item.slug === "rental-payments");
  const tenancy = articles?.find(item => item.slug === "during-your-tenancy");
  const inspections = articles?.find(
    item => item.slug === "routine-inspections",
  );
  const maintenance = articles?.find(
    item => item.slug === "property-maintenance",
  );
  const vacating = articles?.find(
    item => item.slug === "vacating-the-property",
  );
  const onArticleChange = (data) => {
    dispatch(updateArticleBody({ id: data._id, body: data.body }));
  };
  const onTitleChange = (data, article) => {
    dispatch(updateArticleTitle({ id: article._id, title: data }));
  };

  const onStatusChange = (data, article) => {
    dispatch(updateArticleStatus({ id: article._id, active: data }));
  };

  return (
    <div className={classes.customCommercialTable}>
      <div id="residential-coverletter">
        <CoverletterImage imageAspectRatio={1200 / 550} />
      </div>
      <Container
        maxWidth="md"
        component="div"
        className="px-10"
        id="appraisal-details"
      >
        <Coverletter />
        <TenantArticleSection
          id="residential-office-information"
          data={officeData}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, officeData)}
          onHide={newData => onStatusChange(!newData, officeData)}
        />
        <TenantArticleSection
          id="residential-tenant-responsibilities"
          data={responsibilities}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, responsibilities)}
          onHide={newData => onStatusChange(!newData, responsibilities)}
        />
        <TenantArticleSection
          id="residential-rental-payments"
          data={payments}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, payments)}
          onHide={newData => onStatusChange(!newData, payments)}
        />
        <TenantArticleSection
          id="residential-during-your-tenancy"
          data={tenancy}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, tenancy)}
          onHide={newData => onStatusChange(!newData, tenancy)}
        />
        <TenantArticleSection
          id="residential-routine-inspections"
          data={inspections}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, inspections)}
          onHide={newData => onStatusChange(!newData, inspections)}
        />
        <TenantArticleSection
          id="residential-property-maintenance"
          data={maintenance}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, maintenance)}
          onHide={newData => onStatusChange(!newData, maintenance)}
        />
        <TenantArticleSection
          id="residential-vacating-the-property"
          data={vacating}
          onChange={newData => onArticleChange(newData)}
          onTitleChange={newData => onTitleChange(newData, vacating)}
          onHide={newData => onStatusChange(!newData, vacating)}
        />

        <Attachments />
        {preview ? "" : <NextStepAction className="my-10" />}
      </Container>
    </div>
  );
};

export default TenantHandbook;
