import React, { useContext, useState } from "react";
import {useSelector, useDispatch} from "react-redux";
import { Container, makeStyles } from "@material-ui/core";
import moment from 'moment';
import ArticleModal from '../../CommonComponents/ArticleModal';
import BlockContainer, { Title } from "../../AppraisalDetails/BlockContainer";
import HideContainer from "../../components/HideContainer";
import { updateNextSteps, updateArticleStatus, updateArticleBody } from "../../../../../redux/reducers";
import ArticleButton from "../../CommonComponents/ArticleButton";
import EditorComponent from "../../../Common/EditorComponent";
import { AppraisalContext } from "../../AppraisalContext";
import NextStepAction from "./NextStepAction";
import useTrackEvent from "../../../../hooks/useTrackEvent";
import useArticles from "../../../../hooks/useArticles";
import next_property from '../../../../assets/images/Helpful-Tips/next_property.svg';
import question_mark from '../../../../assets/images/Helpful-Tips/question-mark.svg';
import SaleProcessIcon from "../../../../assets/images/Helpful-Tips/SaleProcessIcon.svg";
import preparing_home from '../../../../assets/images/Helpful-Tips/preparing_home.svg';
import landlord_faq from "../../../../assets/images/Helpful-Tips/landlord_faq.png";
import rentals_standout from "../../../../assets/images/Helpful-Tips/rentals_standout.png";
import rentals_consider from "../../../../assets/images/Helpful-Tips/rentals_consider.png";
import landlord_responsibilities from "../../../../assets/images/Helpful-Tips/landlord_responsibilities.png";

import {getNextStepsData, getCategory, getShowFlk} from "redux/selectors"
import FlkSection from "./FlkSection";

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '20px',
    padding: 0,
    color: '#666'
  },
  button: {
    background: 'linear-gradient(90deg, #F69400 0%, #FFC500 100%)',
    borderRadius: '10px',
    margin: 'auto',
    display: 'flex',
    height: '60px',
    width: '400px',
    color: 'white',
    fontSize: '18px',
    fontWeight: '600',
    marginTop: '15px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  articles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexWrap: "wrap",
  },
  expectText: {
    margin: 0,
    marginTop: '40px',
  },
}));

const NextSteps = ({ withoutSubTitle, salesProcess, faq, lookingFor, preparingYourHome, withoutSubSection = false, article = null}) => {
  const classes = useStyles();
  const { preview } = useContext(AppraisalContext);
  const trackEvent = useTrackEvent();
  const [articleModalShow, setArticleModalShow] = useState(false);
  const [activeArticle, setActiveArticle] = useState({});
  const [sessionStart, setSessionStart] = useState({});

  const dispatch = useDispatch();
  const nextStepsData = useSelector(getNextStepsData);
  const category = useSelector(getCategory);
  const showFlk = useSelector(getShowFlk);

  const articleList = useArticles({ nextStepsSection: true }).nextStepsSection || [];
  const allArticlesDisabled =  articleList?.every( a => a.active === false)

  const isCloseSection = nextStepsData.articlesHidden || allArticlesDisabled

  const mainContent = article ? {
    content: article?.body,
    onChangeContent: (value) => onUpdateArticleBody(article._id, value),
  } : {
    content: nextStepsData.description || '',
    onChangeContent: (value) => onChangeNextSteps('description', value) 
  }

  const onUpdateArticleBody = (id, body) => {
    dispatch(updateArticleBody({ id, body }))
  }

  const onChangeNextSteps = (field, val) => {
    dispatch(updateNextSteps({ [field]: val }))
  }

  const closeArticleModal = () => {
    setArticleModalShow(false)
    const now = moment();
    const duration = moment.duration(now.diff(sessionStart))
    trackEvent('Open article', activeArticle.title, parseInt(duration.as('seconds')));
  }

  const openArticleModal = (currentArticle) => {
    setActiveArticle(currentArticle);
    setArticleModalShow(true);
    setSessionStart(moment());
  }

  const onUpdateArticleStatus = (id, status) => {
    dispatch(updateArticleStatus({ id, status }))
  }

  const getIcon = slug => {
    const icons = {
      sales: {
        "preparing-your-home": preparing_home,
        "faqs": question_mark,
        "looking-for-your-next-property": next_property,
        "the-sales-process": SaleProcessIcon,
      },
      lease: {
        "making-your-property-stand-out" : rentals_standout,
        "considering-another-investment-property" : rentals_consider,
        "landlord-responsibilities" : landlord_responsibilities,
        "faqs": landlord_faq,
      }
    }
    return icons[category][slug]
  }

  const isRemoved = slug => {
    return ((slug === "the-sales-process" || slug === "considering-another-investment-property") && !salesProcess)
      || (slug === "faqs" && !faq)
      || ((slug === "looking-for-your-next-property" || slug === "landlord-responsibilities") && !lookingFor)
      || ((slug === 'preparing-your-home' || slug === "making-your-property-stand-out") && !preparingYourHome)
  }

  if (preview && isCloseSection && !nextStepsData.description) return <React.Fragment></React.Fragment>;

  return (
    <HideContainer value={nextStepsData.hidden} onChange={ val => onChangeNextSteps('hidden', val) }>
      <BlockContainer
        defaultTitle={nextStepsData.title}
        defaultSubtitle={nextStepsData.subtitle}
        withoutSubTitle={withoutSubTitle}
        id="next-steps"
        onTitleUpdate={ val => onChangeNextSteps('title', val) }
        onSubtitleUpdate={ val => onChangeNextSteps('subtitle', val) }
        hide={nextStepsData.hidden}
      >
        <div className={nextStepsData.hidden ? 'd-none' : ''}>
          <Container maxWidth="md" component="div" className={classes.container} >
            <EditorComponent
              inline
              value={mainContent.content }
              onBlur={mainContent.onChangeContent}
              type={'coverletter'}
              disabled={preview}
              charactersLimit={1100}
            />
          </Container>
          { showFlk && (<FlkSection onChangeNextSteps={onChangeNextSteps}/>) }
          { preview ? '' : <NextStepAction className="mt-10"  /> }
          { !withoutSubSection ? (
            <HideContainer
              title='Hide Sub Section'
              value={nextStepsData.articlesHidden || allArticlesDisabled}
              onChange={(value) => onChangeNextSteps("articlesHidden", value) }
              removed={!salesProcess && !faq && !lookingFor && !preparingYourHome}
            >
              <Container maxWidth="md" component="div" >
                <Title
                  value={nextStepsData.articlesTitle}
                  onBlur={(value) => onChangeNextSteps("articlesTitle", value) }
                  className={`${classes.expectText} common-subTitle`}
                  maxLength={30}
                />
                <div className={classes.articles}>
                  {articleList.map((article) => (
                    <ArticleButton
                      title={article.title}
                      active={article.active}
                      imgSrc={getIcon(article.slug)}
                      onClick={() => openArticleModal(article)}
                      onChangeStatus={val => onUpdateArticleStatus(article._id, val)}
                      imgAlt={article.title}
                      key={article._id}
                      remove={isRemoved(article.slug)}
                    />
                  ))}
                </div>
                <ArticleModal
                  article={activeArticle || {}}
                  show={articleModalShow}
                  onHide={closeArticleModal}
                  editable={activeArticle.editable}
                />
              </Container>
            </HideContainer>
          ) : <></>}
        </div>
      </BlockContainer>
    </HideContainer>
  );
};
export default NextSteps;
