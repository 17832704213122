import React, { useEffect, useState, useRef } from "react";
import { get } from "lodash";
import LaunchIcon from "@material-ui/icons/Launch";

import GridLinksList from "./GridLinksList";
import PaginationSelective from "app/components/PaginationSelective";
import Loader from "app/components/ui/Loader";
import TemplatesService from "app/api/TemplatesService";
import { formatDateISO8601ToDdMmYy } from "shared/utils";
import { sortingData } from "../Common/Helpers";

const TemplateList = ({ search, page, setPage }) => {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [sortDirection, setSortDirection] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);

  const totalPages = useRef(null);

  useEffect(() => {
    setLoading(true);
    TemplatesService.getTemplates({
      search,
      page,
      filter: sortColumn,
      sort: sortDirection,
    }).then(resp => {
      const templates = get(resp.data, "templates", []).map(template => ({
        id: template._id,
        title: template.title,
        category: template.category,
        editable: template.editable,
        updated: formatDateISO8601ToDdMmYy(template.updated_at),
        timeStamp: Date.parse(template.updated_at),
      }));
      totalPages.current = resp.data.pages;
      setTemplates(templates);
      setLoading(false);
    });
  }, [search, page, sortDirection, sortColumn]);

  const openTemplatePreview = id => {
    const win = window.open(`/admin/manager/templates/${id}`, "_blank");
    win.focus();
  };

  const columnTitles = [
    { title: "Title", sortLabel: "title" },
    { title: "Category", className: "width-200 mr-20", sortLabel: "category" },
    { title: "Editable", sortLabel: "editable", className: "width-120" },
    {
      title: "Updated",
      sortLabel: "updated_at",
      isDate: true,
      className: "width-100",
    },
    { title: "Preview", className: "item__right width-120" },
  ];

  const listData = templates.map(template => ({
    id: template.id,
    link: `/admin/manager/templates/${template.id}`,
    mainData: [
      { content: template.title, sortLabel: "title" },
      {
        content: template.category,
        className: "width-200 mr-20",
        sortLabel: "category",
      },
      {
        content: template.editable ? "Yes" : "No",
        className: template.editable
          ? "green-color width-120"
          : "alert-color width-120",
        sortLabel: "editable",
      },
      {
        content: template.updated,
        sortLabel: "updated_at",
        className: "width-100",
        timeStamp: template.timeStamp,
      },
    ],
    rowTail: (
      <LaunchIcon
        className="cursor-pointer"
        onClick={() => openTemplatePreview(template.id)}
      />
    ),
    rowTailClassName: "width-120",
  }));

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {loading && <Loader />}
      <GridLinksList
        content={listData}
        columnsTitles={columnTitles}
        sortDirection={sortDirection}
        sortColumn={sortColumn}
        sortingData={column =>
          sortingData(
            column,
            setSortColumn,
            sortColumn,
            sortDirection,
            setSortDirection,
          )
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "82px",
        }}
      >
        <PaginationSelective
          page={page}
          setPage={setPage}
          totalPages={totalPages.current}
        />
      </div>
    </div>
  );
};

export default TemplateList;
