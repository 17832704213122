export const UNOPENED = 'unopened';
export const RECENT = 'recent';
export const UNOPENED_TITLE = 'Unopened';
export const RECENT_TITLE = 'Recently Sent';
export const ITEMS_ON_DASHBOARD = 5;
export const APPRAISAL_TYPES = {
  preAppraisal: 'preAppraisal',
  listingProposal: 'listingProposal',
  marketUpdate: 'marketUpdate',
  agentProfiling: 'agentProfiling',
  informationMemorandum: 'informationMemorandum',
  prospecting: 'prospecting',
  commercialListingProposal: 'commercialListingProposal',
  businessListingProposal: 'businessListingProposal',
  residentialInformationMemorandum: 'residentialInformationMemorandum',
  tenantHandbook: 'tenantHandbook',
};
