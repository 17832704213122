// Appraisal Reducer
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { defaultItems as commercialAttachments } from "app/modules/Appraisals/Commercial/Attachments/utils";
import { ProposalTypes } from "app/interfaces";

const appraisalReducerDefaultState = {
  id: null,
  recipient: {
    email: {
      message: "",
      subject: "",
    },
    phone: {
      message: "",
    },
  },
  articles: [],
  articlesFromTemplate: [],
  property: {
    visited: false,
    visitedChanged: null,
    visitedBy: "",
    coverImage: undefined,
    address: {
      suburb: {
        state: {},
      },
      offices: [],
    },
    displayAddress: "",
    coordinates: {
      lat: null,
      lon: null,
    },
    bedrooms: 0,
    bathrooms: 0,
    carSpaces: 0,
    buildingSize: "",
    landSize: "",
    commercialCarSpaces: 0,
    agentPriceOpinion: "$000,000 - $000,000",
    agentPriceOpinionHidden: false,
    propertyType: "",
    about: {
      image: "",
      description: "",
    },
    detailsHidden: false,
    suburbHidden: false,
    suburbSalesHidden: false,
    suburbRentalsHidden: false,
    feedbackHidden: false,
    sectionTitle: "Your Property",
    sectionSubtitle: "What sets your property apart?",
    photos: [],
    buildingName: null,
  },
  team: {
    sectionHidden: false,
    name: "",
    phone: "",
    email: "",
    twitter: "",
    linkedIn: "",
    instagram: "",
    facebook: "",
    office: "",
    officeName: "",
    officeAddress: "",
    officePhone: "",
    leaderName: "",
    leaderNameSubtitle: "About me",
    profileLink: "",
    recentSales: "",
    currentRentals: "",
    biography: "",
    profilePortrait: "",
    secondLeaderName: "",
    secondLeaderPhone: "",
    secondBiography: "",
    secondProfilePortrait: "",
    secondProfileLink: "",
    secondRecentSales: "",
    secondCurrentRentals: "",
    secondEmail: "",
    secondTwitter: "",
    secondLinkedIn: "",
    secondInstagram: "",
    secondFacebook: "",
    about: "",
    aboutHidden: false, // my team section
    image: "",
    report: "",
    reportHidden: false, // market report text
    marketReportTitle: "Market Trends",
    marketReportSubtitle: "What are the current market conditions?",
    reportImage: "",
    videos: [],
    moreInfoHidden: false,
    marketingBrochureLink: "",
    propertyForRent: "",
    propertyForSale: "",
    departmentCategory: "Residential Sales",
    detailsTitle: "My Team",
    moreDetailsTitle: "More Information On My Team",
    isAutoSaveProposal: false,
  },
  vendor: {
    name: "",
    phone: "",
    email: "",
    coverLetter: "",
    coverLetterHidden: false,
  },
  owners: [],
  comparables: {
    raineandhorne: [],
    rhleased: [],
    off: [],
    on: [],
    pricefinderSoldLeased: [],
    pricefinderOTM: [],
    sectionTitle: "Price Estimate",
    comparableTitle: "Comparable Sales",
    sectionSubtitle: "Opinion for sale price for your property",
    sectionHidden: false,
    comparablesHidden: false,
    opinionTitle:
      "Taking into consideration all the factors aforementioned, we estimate the following sales range for your property:",
  },
  calendar: {
    items: [],
    text:
      "Our goal is to make the sales process as simple and clear as possible for you. The following is the proposed timeline of your property’s sales campaign:",
    sectionHidden: false,
    sectionTitle: "Campaign Calendar",
    sectionSubtitle: "The proposed calendar schedule",
    selectedCalendar: "",
  },
  meta: {
    category: "sales",
    classification: "",
    office: "",
    officeVaultreId: "",
    team: "",
    property: "",
    active: true,
    archived: false,
    appraisalType: "",
    draft: true,
    ourBrokerHidden: false,
    ourBrokerTitle: "Our Broker",
    ourBrokerSubtitle: "Financial services",
    serviceFeesHidden: false,
    serviceFeesTitle: "Service Fees",
    serviceFeesSubtitle: "",
    faqHidden: false,
    genericVideoHidden: false,
    agentVideoHidden: false,
  },
  methodOfSale: {
    title: "Method Of Sale",
    subtitle: "Our recommendations to sell",
    hidden: false,
    subSectionHidden: false,
    selectedMethod: "Private Treaty",
    methodRecommendationText:
      "Considering the unique characteristics of your property, we recommend the following method of sale:",
    methods: {
      privateTreaty: {
        hidden: false,
      },
      auction: {
        hidden: false,
      },
      eoi: {
        hidden: false,
      },
      offMarketSale: {
        hidden: false,
      },
      tender: {
        hidden: false,
      },
    },
  },
  ourServices: {
    title: "Our Services",
    subtitle: "Our Services",
    hidden: false,
    services: {
      maximisingYourReturn: {
        hidden: false,
      },
      tenancySelection: {
        hidden: false,
      },
      ongoingService: {
        hidden: false,
      },
      yourPropertyJourney: {
        hidden: false,
      },
    },
  },
  marketing: {
    title: "Marketing",
    subtitle: "Give yourself the competitive edge",
    description:
      "Our marketing activities will ensure you stand out from the competition and attract the right type of buyer. The following are a selection of marketing strategies which you may choose to implement.",
    hidden: false,
    packsHidden: false,
    packsTitle: "Marketing Options",
    options: [],
    packs: {
      tabs: [],
      extra: [],
    },
    types: {
      socialMedia: {
        hidden: false,
      },
      digital: {
        hidden: false,
      },
      local: {
        hidden: false,
      },
      database: {
        hidden: false,
      },
      print: {
        hidden: false,
      },
      visuals: {
        hidden: false,
      },
    },
    option_title: "",
    show_extra_options: false,
    extra_items: [],
  },
  testimonials: {
    image: "",
    title: "Testimonials",
    subTitle: "What our clients say about us",
    hidden: false,
    items: [],
  },
  stateInfo: {
    title: "",
    link: "",
    phone: "",
    logo: "",
  },
  nextSteps: {
    title: "Next Steps",
    subtitle: "What should you do now?",
    hidden: false,
    description: "",
    articlesHidden: false,
    articlesTitle: "Helpful Tips & Frequently Asked Questions",
    flkHidden: true,
    flkContacts: [],
    action: {
      title: "",
      type: "email",
      value: "demo@mailinator.com",
      subject: "",
      body: "",
    },
  },
  pmfees: [],
  commercial: {
    executiveSummary: {
      title: "Executive Summary",
      hidden: false,
    },
    opportunity: undefined,
    investmentHighlights: undefined,
    marketOverview: undefined,
    tenantOverview: undefined,
    financialOverview: undefined,
    saleLeaseProcess: undefined,
    priceList: undefined,
    disclaimer: undefined,
    attachments: {
      title: "Attachments",
      hidden: false,
      items: commercialAttachments,
    },
    agentDetails: {
      title: "Agent Details",
      hidden: false,
    },
    locationOverview: {
      title: "Location Overview",
      hidden: false,
      infographics: [],
    },
    photoGallery: {
      title: "Image Gallery",
      hidden: false,
    },
    propertyOverview: {
      title: "Property Overview",
      hidden: false,
      infographics: [],
    },
  },
  businessSupport: {
    title: "How we support you",
    subtitle: "We’ll help grow your business",
    hidden: false,
    packsHidden: false,
    types: {
      training: {
        hidden: false,
      },
      propertyManagement: {
        hidden: false,
      },
      marketingAndCommunications: {
        hidden: false,
      },
      it: {
        hidden: false,
      },
      ourBroker: {
        hidden: false,
      },
    },
  },
  growBusiness: {
    title: "Level Up With Tech",
    subtitle: "We’ve got the tools to help you succeed",
    hidden: false,
    packsHidden: false,
    types: {
      compassPlus: {
        hidden: false,
      },
      designPlus: {
        hidden: false,
      },
      canva: {
        hidden: false,
      },
      amplify: {
        hidden: false,
      },
      digiKitPlus: {
        hidden: false,
      },
      processPlus: {
        hidden: false,
      },
    },
  },
  chooseUs: {
    title: "Why Choose Us?",
    subtitle: "Partner with a global group who are with you",
    isHiddenSection: false,
  },
  recruitmentAgents: {
    title: "Recruitment",
    subtitle: "Attract the million-dollar agents to your business",
    isHiddenSection: false,
  },
  ourGrowingNetwork: {
    title: "Our Growing Network",
    subtitle: "Growing strength to strength",
    isHiddenSection: false,
  },
  targetMarket: {
    title: "Target Market",
    subtitle: "",
    isHiddenSection: false,
  },
  executiveSummary: {
    title: "Executive Summary",
    subtitle: "",
    isHiddenSection: false,
  },
  disclaimer: {
    title: "Disclaimer",
    subtitle: "",
    isHiddenSection: false,
  },
  marketTrends: {
    title: "Understanding Business Value",
    subtitle: "We’ll get you best price for your business",
    isHiddenSection: false,
  },
  ourValues: {
    title: "Our Values",
    subtitle: "Our values underpin everything we do",
    isHiddenSection: false,
  },
  country: "Australia",
};

export const appraisalSlice = createSlice({
  name: "appraisal",
  initialState: appraisalReducerDefaultState,
  reducers: {
    setAppraisal: (state, action) => {
      state = { ...appraisalReducerDefaultState, ...action.payload };
      // prevent articles duplication
      state.articles = _.uniqBy(action.payload.articles, "_id");
      return state;
    },
    setAppraisalId: (state, action) => {
      state.id = action.payload;
    },
    setAppraisalType: (state, action) => {
      state.meta.appraisalType = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    updateTeam: (state, action) => {
      state.team = { ...state.team, ...action.payload };
    },
    updateTeamVideo: (state, action) => {
      const { videoIndex, newUrl } = action.payload;
      state.team.videos[videoIndex] = newUrl;
    },
    setProperty: (state, action) => {
      // state.property = { ...appraisalReducerDefaultState.property, ...action.payload }
      state.property = { ...state.property, ...action.payload };
    },
    updateProperty: (state, action) => {
      state.property = { ...state.property, ...action.payload };
    },
    updateMeta: (state, action) => {
      state.meta = { ...state.meta, ...action.payload };
    },
    setCalendar: (state, action) => {
      // state.calendar = { ...appraisalReducerDefaultState.calendar ,...action.payload }
      state.calendar = { ...state.calendar, ...action.payload };
    },
    updateCalendar: (state, action) => {
      state.calendar = { ...state.calendar, ...action.payload };
    },
    updateComparables: (state, action) => {
      state.comparables = { ...state.comparables, ...action.payload };
    },
    updateVendor: (state, action) => {
      state.vendor = { ...state.vendor, ...action.payload };
    },
    updateOwners: (state, action) => {
      state.owners = action.payload;
    },
    updateRecipient: (state, action) => {
      state.recipient = { ...state.recipient, ...action.payload };
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    updateMarketing: (state, action) => {
      state.marketing = { ...state.marketing, ...action.payload };
    },
    updateMarketingPacks: (state, action) => {
      state.marketing = { ...state.marketing, packs: action.payload };
    },
    updateMarketingOptions: (state, action) => {
      state.marketing.options = action.payload;
    },
    updateArticleStatus: (state, action) => {
      const { id, active, status } = action.payload;
      const index = state.articles.findIndex(article => article._id === id);
      state.articles[index].active = !!active || !!status;
    },
    updateArticleBody: (state, action) => {
      const { id, body } = action.payload;
      const index = state.articles.findIndex(article => article._id === id);
      state.articles[index].body = body;
    },
    updateArticleTitle: (state, action) => {
      const { id, title } = action.payload;
      const index = state.articles.findIndex(article => article._id === id);
      state.articles[index].title = title;
    },
    updateMethodOfSale: (state, action) => {
      state.methodOfSale = { ...state.methodOfSale, ...action.payload };
    },
    updateMethodOfSaleMethodProp: (state, action) => {
      const { value, field, prop } = action.payload;
      state.methodOfSale.methods[field][prop] = value;
    },
    updateMethodOfSaleField: (state, action) => {
      const { value, field } = action.payload;
      state.methodOfSale[field] = value;
    },
    updateOurServicesServiceProp: (state, action) => {
      const { value, field, prop } = action.payload;
      state.ourServices.services[field][prop] = value;
    },
    updateOurServicesField: (state, action) => {
      const { value, field } = action.payload;
      state.ourServices[field] = value;
    },
    updateAppraisal: (state, action) => {
      const newState = {
        recipient: { ...state.recipient, ...action.payload.recipient },
        articles: [...state.articles, ...action.payload.articles],
        property: { ...state.property, ...action.payload.property },
        team: { ...state.team, ...action.payload.team },
        vendor: { ...state.vendor, ...action.payload.vendor },
        comparables: { ...state.comparables, ...action.payload.comparables },
        calendar: { ...state.calendar, ...action.payload.calendar },
        meta: { ...state.meta, ...action.payload.meta },
        methodOfSale: { ...state.methodOfSale, ...action.payload.methodOfSale },
        ourServices: { ...state.ourServices, ...action.payload.ourServices },
        marketing: { ...state.marketing, ...action.payload.marketing },
        testimonials: { ...state.testimonials, ...action.payload.testimonials },
        stateInfo: { ...state.stateInfo, ...action.payload.stateInfo },
        nextSteps: { ...state.nextSteps, ...action.payload.nextSteps },
      };
      state = { ...state, ...newState };
      return state;
    },

    updateAppraisalFromTemplate: (state, action) => {
      const proposalType = state.meta.appraisalType;
      const updateValue = (templateValue, stateValue) =>
        templateValue !== undefined ? templateValue : stateValue;

      // const uniqArrays = (firstArr, secondArr, attr) =>
      //   _.uniqBy([...firstArr, ...secondArr], attr);

      // let marketingOptions;
      // if (action.payload.marketing?.options?.tabs) {
      //   const newMarketingTabs = uniqArrays(
      //     action.payload.marketing?.options?.tabs,
      //     state.marketing?.options?.tabs,
      //     "title",
      //   );
      //   marketingOptions = JSON.parse(JSON.stringify(newMarketingTabs));
      //   marketingOptions?.forEach(templateTab => {
      //     state.marketing?.options?.tabs?.forEach(stateTab => {
      //       if (
      //         templateTab?.title &&
      //         stateTab?.title &&
      //         templateTab?.title === stateTab?.title
      //       ) {
      //         templateTab.items = uniqArrays(
      //           templateTab?.items,
      //           stateTab?.items,
      //           "text",
      //         );
      //         if (!templateTab?.costSavings && stateTab?.costSavings) {
      //           templateTab.costSavings = stateTab?.costSavings;
      //         }
      //       }
      //     });
      //   });
      // } else marketingOptions = state.marketing?.options?.tabs;

      const newState = {
        methodOfSale: { ...state.methodOfSale, ...action.payload.methodOfSale },
        ourServices: { ...state.ourServices, ...action.payload.ourServices },
        calendar: {
          ...state.calendar,
          sectionHidden: updateValue(
            action.payload.calendar?.sectionHidden,
            state.calendar?.sectionHidden,
          ),
          sectionSubtitle: updateValue(
            action.payload.calendar?.sectionSubtitle,
            state.calendar?.sectionSubtitle,
          ),
          sectionTitle: updateValue(
            action.payload.calendar?.sectionTitle,
            state.calendar?.sectionTitle,
          ),
          text: updateValue(
            action.payload.calendar?.text,
            state.calendar?.text,
          ),
          ...(proposalType === ProposalTypes.prospecting || proposalType === ProposalTypes.preAppraisal || proposalType === ProposalTypes.listingProposal || proposalType === ProposalTypes.businessListingProposal
            ? { items: action.payload.calendar?.items }
            : {}),
        },
        comparables: {
          ...state.comparables,
          comparableTitle: updateValue(
            action.payload.comparables?.comparableTitle,
            state.comparables?.comparableTitle,
          ),
          comparablesHidden: updateValue(
            action.payload.comparables?.comparablesHidden,
            state.comparables?.comparablesHidden,
          ),
          opinionTitle: updateValue(
            action.payload.comparables?.opinionTitle,
            state.comparables?.opinionTitle,
          ),
          sectionHidden: updateValue(
            action.payload.comparables?.sectionHidden,
            state.comparables?.sectionHidden,
          ),
          sectionSubtitle: updateValue(
            action.payload.comparables?.sectionSubtitle,
            state.comparables?.sectionSubtitle,
          ),
          sectionTitle: updateValue(
            action.payload.comparables?.sectionTitle,
            state.comparables?.sectionTitle,
          ),
        },
        marketing: {
          ...state.marketing,
          // ...action.payload.marketing,
          // options: { ...state.marketing.options, tabs: marketingOptions },
          hidden: updateValue(
            action.payload.marketing?.hidden,
            state.marketing?.hidden,
          ),
          packsHidden: updateValue(
            action.payload.marketing?.packsHidden,
            state.marketing?.packsHidden,
          ),
        },
        meta: {
          ...state.meta,
          faqHidden: updateValue(
            action.payload.meta?.faqHidden,
            state.meta?.faqHidden,
          ),
          genericVideoHidden: updateValue(
            action.payload.meta?.genericVideoHidden,
            state.meta?.genericVideoHidden,
          ),
          ourBrokerHidden: updateValue(
            action.payload.meta?.ourBrokerHidden,
            state.meta?.ourBrokerHidden,
          ),
          ourBrokerSubtitle: updateValue(
            action.payload.meta?.ourBrokerSubtitle,
            state.meta?.ourBrokerSubtitle,
          ),
          ourBrokerTitle: updateValue(
            action.payload.meta?.ourBrokerTitle,
            state.meta?.ourBrokerTitle,
          ),
          serviceFeesHidden: updateValue(
            action.payload.meta?.serviceFeesHidden,
            state.meta?.serviceFeesHidden,
          ),
          serviceFeesSubtitle: updateValue(
            action.payload.meta?.serviceFeesSubtitle,
            state.meta?.serviceFeesSubtitle,
          ),
          serviceFeesTitle: updateValue(
            action.payload.meta?.serviceFeesTitle,
            state.meta?.serviceFeesTitle,
          ),
        },
        property: {
          ...state.property,
          // about: {
          //   ...state.property?.about,
          //   description: updateValue(
          //     action.payload.property?.about?.description,
          //     state.property?.about?.description,
          //   ),
          // },
          // agentPriceOpinionHidden: updateValue(
          //   action.payload.property?.agentPriceOpinionHidden,
          //   state.property?.agentPriceOpinionHidden,
          // ),
          detailsHidden: updateValue(
            action.payload.property?.detailsHidden,
            state.property?.detailsHidden,
          ),
          feedbackHidden: updateValue(
            action.payload.property?.feedbackHidden,
            state.property?.feedbackHidden,
          ),
          sectionSubtitle: updateValue(
            action.payload.property?.sectionSubtitle,
            state.property?.sectionSubtitle,
          ),
          sectionTitle: updateValue(
            action.payload.property?.sectionTitle,
            state.property?.sectionTitle,
          ),
          suburbHidden: updateValue(
            action.payload.property?.suburbHidden,
            state.property?.suburbHidden,
          ),
          suburbRentalsHidden: updateValue(
            action.payload.property?.suburbRentalsHidden,
            state.property?.suburbRentalsHidden,
          ),
          suburbSalesHidden: updateValue(
            action.payload.property?.suburbSalesHidden,
            state.property?.suburbSalesHidden,
          ),
          coverImage: updateValue(
            action.payload.property?.coverImage,
            state.property?.coverImage,
          ),
          // agentPriceOpinion: updateValue(
          //   action.payload.property?.agentPriceOpinion,
          //   state.property?.agentPriceOpinion,
          // ),
        },
        team: {
          ...state.team,
          aboutHidden: updateValue(
            action.payload.team?.aboutHidden,
            state.team?.aboutHidden,
          ),
          detailsTitle: updateValue(
            action.payload.team?.detailsTitle,
            state.team?.detailsTitle,
          ),
          leaderNameSubtitle: updateValue(
            action.payload.team?.leaderNameSubtitle,
            state.team?.leaderNameSubtitle,
          ),
          marketReportSubtitle: updateValue(
            action.payload.team?.marketReportSubtitle,
            state.team?.marketReportSubtitle,
          ),
          marketReportTitle: updateValue(
            action.payload.team?.marketReportTitle,
            state.team?.marketReportTitle,
          ),
          moreInfoHidden: updateValue(
            action.payload.team?.moreInfoHidden,
            state.team?.moreInfoHidden,
          ),
          reportHidden: updateValue(
            action.payload.team?.reportHidden,
            state.team?.reportHidden,
          ),
          sectionHidden: updateValue(
            action.payload.team?.sectionHidden,
            state.team?.sectionHidden,
          ),
          moreDetailsTitle: updateValue(
            action.payload.team?.moreDetailsTitle,
            state.team?.moreDetailsTitle,
          ),
          secondLeaderName: updateValue(
            action.payload.team?.secondLeaderName,
            state.team?.secondLeaderName,
          ),
          secondLeaderPhone: updateValue(
            action.payload.team?.secondLeaderPhone,
            state.team?.secondLeaderPhone,
          ),
          secondBiography: updateValue(
            action.payload.team?.secondBiography,
            state.team?.secondBiography,
          ),
          secondProfileLink: updateValue(
            action.payload.team?.secondProfileLink,
            state.team?.secondProfileLink,
          ),
          secondRecentSales: updateValue(
            action.payload.team?.secondRecentSales,
            state.team?.secondRecentSales,
          ),
          secondEmail: updateValue(
            action.payload.team?.secondEmail,
            state.team?.secondEmail,
          ),
          secondTwitter: updateValue(
            action.payload.team?.secondTwitter,
            state.team?.secondTwitter,
          ),
          secondLinkedIn: updateValue(
            action.payload.team?.secondLinkedIn,
            state.team?.secondLinkedIn,
          ),
          secondInstagram: updateValue(
            action.payload.team?.secondInstagram,
            state.team?.secondInstagram,
          ),
          secondFacebook: updateValue(
            action.payload.team?.secondFacebook,
            state.team?.secondFacebook,
          ),
          secondProfilePortrait: updateValue(
            action.payload.team?.secondProfilePortrait,
            state.team?.secondProfilePortrait,
          ),
        },
        vendor: {
          ...state.vendor,
          coverLetterHidden: updateValue(
            action.payload.vendor?.coverLetterHidden,
            state.vendor?.coverLetterHidden,
          ),
        },
        nextSteps: {
          ...state.nextSteps,
          action: {
            ...state.nextSteps?.action,
            title: updateValue(
              action.payload.nextSteps?.action?.title,
              state.nextSteps?.action?.title,
            ),
          },
          title: updateValue(
            action.payload.nextSteps?.title,
            state.nextSteps?.title,
          ),
          subtitle: updateValue(
            action.payload.nextSteps?.subtitle,
            state.nextSteps?.subtitle,
          ),
          hidden: updateValue(
            action.payload.nextSteps?.hidden,
            state.nextSteps?.hidden,
          ),
          articlesTitle: updateValue(
            action.payload.nextSteps?.articlesTitle,
            state.nextSteps?.articlesTitle,
          ),
          articlesHidden: updateValue(
            action.payload.nextSteps?.articlesHidden,
            state.nextSteps?.articlesHidden,
          ),
          description: updateValue(
            action.payload.nextSteps?.description,
            state.nextSteps?.description,
          ),
        },
        stateInfo: { ...state.stateInfo, ...action.payload.stateInfo },
        articles: [...state.articles],
        // articlesFromTemplate: [...action.payload.articles],
        // testimonials: { ...state.testimonials, ...action.payload.testimonials },

        commercial: {
          ...state.commercial,
          ...action.payload.commercial,
          locationOverview: {
            ...state.commercial?.locationOverview,
            ...action.payload.commercial?.locationOverview,
            body: state.commercial?.locationOverview?.body, // keep the nested 'body' property from state.commercial
          },
        },
        commercialFromTemplate: { ...action.payload.commercial },


        businessSupport: {
          ...state.businessSupport,
          ...action.payload.businessSupport,
        },
        growBusiness: { ...state.growBusiness, ...action.payload.growBusiness },
        chooseUs: { ...state.chooseUs, ...action.payload.chooseUs },
        recruitmentAgents: {
          ...state.recruitmentAgents,
          ...action.payload.recruitmentAgents,
        },
        ourGrowingNetwork: {
          ...state.ourGrowingNetwork,
          ...action.payload.ourGrowingNetwork,
        },
        marketTrends: {
          ...state.marketTrends,
          ...action.payload.marketTrends,
        },
        executiveSummary: {
          ...state.executiveSummary,
          ...action.payload.executiveSummary,
        },
        disclaimer: { ...state.disclaimer, ...action.payload.disclaimer },
        targetMarket: { ...state.targetMarket, ...action.payload.targetMarket },
        ourValues: {
          ...state.ourValues,
          ...action.payload.ourValues,
        },
      };
      state = { ...state, ...newState };
      return state;
    },

    updateTestimonials: (state, action) => {
      state.testimonials = { ...state.testimonials, ...action.payload };
    },
    updateStateInfo: (state, action) => {
      state.stateInfo = { ...state.stateInfo, ...action.payload };
    },
    updateNextSteps: (state, action) => {
      state.nextSteps = { ...state.nextSteps, ...action.payload };
    },
    setPmfees: (state, action) => {
      state.pmfees = action.payload;
    },
    setCommercialArticles: (state, action) => {
      state.commercial = { ...state.commercial, ...action.payload };
    },
    updateCommercialField: (state, action) => {
      const { data, name } = action.payload;
      state.commercial[name] = data;
    },
    updateBusinessSupport: (state, action) => {
      state.businessSupport = { ...state.businessSupport, ...action.payload };
    },
    updateGrowBusiness: (state, action) => {
      state.growBusiness = { ...state.growBusiness, ...action.payload };
    },
    updateChooseUs: (state, action) => {
      state.chooseUs = { ...state.chooseUs, ...action.payload };
    },
    updateRecruitmentAgents: (state, action) => {
      state.recruitmentAgents = {
        ...state.recruitmentAgents,
        ...action.payload,
      };
    },
    updateOurGrowingNetwork: (state, action) => {
      state.ourGrowingNetwork = {
        ...state.ourGrowingNetwork,
        ...action.payload,
      };
    },
    updateTargetMarket: (state, action) => {
      state.targetMarket = { ...state.targetMarket, ...action.payload };
    },
    updateExecutiveSummary: (state, action) => {
      state.executiveSummary = { ...state.executiveSummary, ...action.payload };
    },
    updateDisclaimer: (state, action) => {
      state.disclaimer = { ...state.disclaimer, ...action.payload };
    },
    updateMarketTrends: (state, action) => {
      state.marketTrends = { ...state.marketTrends, ...action.payload };
    },
    updateOurValues: (state, action) => {
      state.ourValues = { ...state.ourValues, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAppraisal,
  setAppraisalId,
  setAppraisalType,
  setCountry,
  setTeam,
  setProperty,
  updateProperty,
  updateMeta,
  setCalendar,
  updateCalendar,
  updateComparables,
  updateVendor,
  updateOwners,
  updateRecipient,
  setArticles,
  updateTeam,
  updateMarketing,
  updateMarketingPacks,
  updateMarketingOptions,
  updateArticleStatus,
  updateArticleBody,
  updateArticleTitle,
  updateMethodOfSale,
  updateMethodOfSaleMethodProp,
  updateMethodOfSaleField,
  updateTestimonials,
  updateAppraisal,
  updateAppraisalFromTemplate,
  updateStateInfo,
  updateNextSteps,
  setPmfees,
  updateOurServicesServiceProp,
  updateOurServicesField,
  setCommercialArticles,
  updateCommercialField,
  updateTeamVideo,
  updateBusinessSupport,
  updateGrowBusiness,
  updateChooseUs,
  updateRecruitmentAgents,
  updateOurGrowingNetwork,
  updateTargetMarket,
  updateExecutiveSummary,
  updateDisclaimer,
  updateMarketTrends,
  updateOurValues,
} = appraisalSlice.actions;

export default appraisalSlice.reducer;
