import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SvgIcon from 'app/components/ui/SvgIcon';
import { DESCENDING } from 'app/constants/commonConstants';
import cn from 'classnames';

import '../../components/GridLinksList/styles.scss';

const GridLinksList = ({ content, columnsTitles, hasLink, sortDirection, sortColumn, sortingData }) => {
  const [data, setData] = useState(content);

  useEffect(() => {
    setData(content)
  },[content])

  const ItemWrapper = hasLink ? Link : 'div';

  return (
    <div className="grid-list">
      {columnsTitles &&
      <div className={
        cn('titles-row layout-side-padding')}>
        {columnsTitles.map((item, index) => (
          <div
            key={index}
            className={cn('item', { [item.className]: item.className }, { 'cursor-pointer': item.sortLabel })}
            data-label={item.sortLabel}
            data-isdate={item.isDate}
            onClick={() => item.sortLabel ? sortingData(item.sortLabel) : null}
            style={item.style && item.style}
          >
            <div className="title">
              {item.title}
              {sortDirection && item.sortLabel === sortColumn &&
              <SvgIcon
                iconName="thinArrow"
                className={cn('sort-icon', { [DESCENDING]: sortDirection === DESCENDING })}
              />
              }
            </div>
          </div>
        ))}
      </div>
      }
      {data.map(item => (
        <div
          key={item.id}
          className="item-row layout-side-padding"
        >
          <ItemWrapper
            to={item.link}
            className="item-wrapper"
          >
            {item.mainData.map((item, index) => (
              <div key={index} className={cn('item', { [item.className]: item.className })}>
                <div className="item-inner">
                  {item.content}
                </div>
              </div>
            ))}
          </ItemWrapper>
          {item.rowTail &&
            <div className={cn('item item__tail', { [item.rowTailClassName]: item.rowTailClassName })}>
              {item.rowTail}
            </div>
          }
        </div>
      ))}
    </div>
  );
};

GridLinksList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string,
      mainData: PropTypes.array.isRequired,
      rowTail: PropTypes.element,
      rowTailClassName: PropTypes.string,
    }),
  ),
  columnsTitles: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    className: PropTypes.string,
    sortLabel: PropTypes.string,
    style: PropTypes.object,
  })),
  hasLink: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortColumn: PropTypes.string,
  sortingData: PropTypes.func,
};

GridLinksList.defaultProps = {
  content: {
    link: '#',
    rowTail: null,
    rowTailClassName: '',
  },
  columnsTitles: null,
  hasLink: true,
  sortDirection: null,
  sortColumn: null,
  sortingData: () => null,
};

export default GridLinksList;
