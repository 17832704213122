import axios from "axios";
import API_URL from "./api";
import { stringify } from "query-string";
const moment = require("moment");

const getProps = props => {
  return {
    dateRange: props.dateRange || `19800101-${moment().format("YYYYMMDD")}`,
    p_type: props.p_type,
    page: props.page || 1,
    radius: props.radius || 0.5,
    lat: props.lat,
    lon: props.lon,
    min_baths: props.min_baths,
    max_baths: props.max_baths,
    min_beds: props.min_beds,
    max_beds: props.max_beds,
    min_car_spaces: props.min_car_spaces,
    max_car_spaces: props.max_car_spaces,
    min_land: props.min_land,
    max_land: props.max_land,
    min_land_area: props.min_land_area,
    max_land_area: props.max_land_area,
    min_price: props.min_price,
    max_price: props.max_price,
    country: props.country,
  };
};

export default class ComparableService {
  static getRhProperties = props => {
    const category =
      props.category === "lease"
        ? props.category
        : props.radius_type || props.category;

    let listingStatus = category;

    if (category === "lease") {
      listingStatus = props.status === "sold" ? "leased" : "lease";
    }

    let classification =
      props.classification && props.classification !== "land"
        ? props.classification
        : "residential";
    let queryParams = stringify({
      ...getProps(props),
      q: props.query,
    });
    if (props.type === "rhleased") {
      queryParams += `&status=${props.status}`;
    } else if (props.type === "rh") {
      queryParams += `&status=${props.status}`;
    }
    const URL = `${API_URL}/properties/raineandhorne/${classification}/${listingStatus}?${queryParams}`;
    return axios.get(URL);
  };

  static getProperties = props => {
    const radius_type = props.radius_type || "sold";
    const queryParams = stringify({
      ...getProps(props),
      classification: props.classification || "residential",
    });

    const URL = `${API_URL}/properties/corelogic/radius-${radius_type}?${queryParams}`;
    return axios.get(URL);
  };

  static getPropertiesByAddress = ({
    address,
    type = "off",
    category = "sales",
    country,
  }) => {
    const URL = `${API_URL}/properties/corelogic/address?address=${address}&type=${type}&category=${category}&country=${country}`;
    return axios.get(URL);
  };

  static getRhPropertiesByAddress = ({ address, officeId = "" }) => {
    const URL = `${API_URL}/properties/raineandhorne/address?address=${address}&officeId=${officeId}`;
    return axios.get(URL);
  };

  static getPropertiesByLocality = props => {
    const locality_type = props.radius_type || "sold";
    const queryParams = stringify({
      ...getProps(props),
      locality: props.locality,
      classification: props.classification || "",
    });

    const URL = `${API_URL}/properties/corelogic/locality-${locality_type}?${queryParams}`;
    return axios.get(URL);
  };
}
