import React from "react";
import { makeStyles } from "@material-ui/core";
import { TextareaAutosize } from '@material-ui/core';
import { useDispatch } from "react-redux"
import { updateRecipient } from "../../../../../redux/reducers/appraisalReducer";
import MobileView from "../MobileView";

const useStyles = makeStyles(() => ({
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  input: {
    height: '575px !important',
    width: '100%',
    fontSize: '16px',
  },
  charactersCount: {
    position: 'absolute',
    bottom: '8%',
    marginLeft: '200px',
    color: 'white'
  }
}));


const SmsTemplate = (props) => {

  const dispatch = useDispatch();
  const { recipient } = props;

  const classes = useStyles();

  return(
    <div>
      <div className={classes.imageWrapper}>
        <MobileView mobile>
          <TextareaAutosize
            rowsMax={17}
            rowsMin={17}
            value={recipient.phone.message}
            onChange={e => dispatch(updateRecipient({phone: {message: e.target.value}}))}
            className={classes.input}>
          </TextareaAutosize>
        </MobileView>
        <div className={classes.charactersCount}>
          {`Characters: ${recipient.phone.message?.length}`}
        </div>
      </div>
    </div>
  )
}

export default SmsTemplate;
