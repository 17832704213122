import React, { useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  CircularProgress,
  IconButton,
  useMediaQuery,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { AppraisalContext } from "../AppraisalContext";
import printSvg from "app/assets/images/print.svg";
import NextStepAction from "../AppraisalDetails/NextSteps/NextStepAction";
import cn from "classnames";
import { confirmDialog } from "primereact/confirmdialog";
import Button from "app/components/ui/Buttons/Button";
import { getShowFlk } from "../../../../redux/selectors";

const useStyles = makeStyles(theme => ({
  bottomBar: {
    position: "sticky",
    bottom: 0,
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    height: 45,
    transition: "bottom 0.2s ease-in-out",
    boxShadow: "0px -5px 10px -6px rgba(34, 60, 80, 0.2)",
    minHeight: 45,
    zIndex: 10,
  },
  hideBar: {
    bottom: -80,
  },
  mobileHeader: {
    zIndex: 101,
    position: "sticky",
    top: 0,
    height: 60,
    backgroundColor: "rgb(235, 235, 236)",
    boxShadow: "0px 10px 30px 0px rgb(82 63 105 / 8%)",
    display: "flex",
    justifyContent: "space-between",
  },
  mobileRoot: {
    "& #cover-letter, #appraisal-details, #your-property, #market-report, #price-estimation, #sales-process, #campaign-calendar, #marketing, #service-fees, #our-broker, #team-details, #next-steps": {
      scrollMarginTop: "65px", // fix for scrolling in preview
      scrollSnapMarginTop: "65px",
    },
  },
  actionButton: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    "& a": {
      height: 45,
      [theme.breakpoints.down("sm")]: {
        width: 300,
      },
    },
  },
  switchBox: {
    margin: "0px",
    fontSize: "14px",
    color: "#333333",
  },
}));

const ActionMenuCover = ({
  saveLoading,
  onPrint,
  onSend,
  onSave,
  turnAutoSave,
  onPreview,
  onSaveTemplate,
  children,
  onMenu,
  onSync,
  onFlk,
  isCommercial,
}) => {
  const { preview } = useContext(AppraisalContext);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = useStyles({ isCommercial });
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );
  const isAutoSaveProposal = useSelector(
    ({ appraisal }) => appraisal.team.isAutoSaveProposal,
    shallowEqual,
  );

  const showFlk = useSelector(getShowFlk);

  const [showFooter, setShowFooter] = useState(false);
  const [loadAutoSave, setLoadAutoSave] = useState(false);
  const root = useRef(null);

  const LoadingTitle = ({ title }) =>
    saveLoading ? (
      <div className="align-content-center d-flex h-20px justify-content-between w-100">
        Loading...{" "}
        <CircularProgress
          style={{ color: "#fff", height: "20px", width: "20px" }}
        />
      </div>
    ) : (
      <div>{title}</div>
    );

  const sync = () => {
    confirmDialog({
      message:
        "By syncing, you will be syncing across new data from CompassPlus only including property image, bed, bath, car, land and vendor names, mobiles and email addresses. Please make these changes in CompassPlus first and then sync.",
      header: "Sync information from CompassPlus",
      icon: "pi pi-exclamation-triangle",
      accept: onSync,
      acceptLabel: "Sync",
      rejectLabel: "Cancel",
      style: { width: "30vw" },
    });
  };

  const saveTemplate = () => {
    confirmDialog({
      message:
        "By saving the layout of this proposal, you are making this the default. This means that changes made to the cover image, headings and subheadings, hidden and unhidden modules, chosen campaign calendar and amendments to the call to action button, will remain for future proposals. Should you need to make changes to other parts of the proposal, please do so in settings.",
      header: "Save Layout",
      icon: "pi pi-exclamation-triangle",
      accept: onSaveTemplate,
      acceptLabel: "Save",
      rejectLabel: "Cancel",
      style: { width: "30vw" },
    });
  };

  const scrollHandler = e => {
    setShowFooter(e.target.scrollTop > 500);
  };

  const onTurnAutoSave = check => {
    setLoadAutoSave(true);
    return turnAutoSave(check).finally(() => setLoadAutoSave(false));
  };

  useEffect(() => {
    root.current = document.getElementById("preview-container");
    if (root?.current) root.current.addEventListener("scroll", scrollHandler);
    return () => root?.current?.removeEventListener("scroll", scrollHandler);
  }, []);

  if (preview) {
    if (isMobile) {
      return (
        <div>
          <div className={classes.mobileHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onMenu}
              disableRipple
            >
              <MenuIcon />
            </IconButton>
            {appraisalType !== "agentProfiling" ? (
              <IconButton
                color="inherit"
                aria-label="print appraisal"
                onClick={onPrint}
                disableRipple
              >
                {saveLoading ? (
                  <CircularProgress
                    style={{ color: "inherit", height: "20px", width: "20px" }}
                  />
                ) : (
                  <img src={printSvg} alt="print appraisal" />
                )}
              </IconButton>
            ) : (
              ""
            )}
          </div>
          <div className={classes.mobileRoot}>{children}</div>
          <div
            className={cn(classes.bottomBar, {
              [classes.hideBar]: !showFooter,
            })}
          >
            <NextStepAction className={classes.actionButton} />
          </div>
        </div>
      );
    } else {
      return (
        <>
          {children}
          <div
            className={cn("flex-row-reverse", classes.bottomBar, {
              [classes.hideBar]: !showFooter,
            })}
          >
            {appraisalType !== "agentProfiling" ? (
              <button onClick={onPrint} className="btn btn-secondary h-45px">
                <img src={printSvg} alt="print appraisal" />
              </button>
            ) : (
              ""
            )}
            <NextStepAction className={classes.actionButton} />
          </div>
        </>
      );
    }
  } else {
    return (
      <>
        {children}
        <div className={classes.bottomBar}>
          <div className="d-flex">
            <Button variant="btn-brand-black" onClick={onSave}>
              <LoadingTitle title="Save Proposal" />
            </Button>
            <Button className="right-separate" icon="sync" onClick={sync}>
              <LoadingTitle title="Sync" />
            </Button>
            <Button icon="save" onClick={saveTemplate}>
              <LoadingTitle title="Save Layout" />
            </Button>
          </div>

          <div className="d-flex">
            <FormControlLabel
              className={classes.switchBox}
              value={"Auto Save Proposal"}
              control={
                <Switch
                  checked={isAutoSaveProposal || false}
                  onChange={e => onTurnAutoSave(e.target.checked)}
                  color="primary"
                />
              }
              label={"Auto Save Proposal"}
              labelPlacement="start"
              disabled={loadAutoSave}
            />
          </div>
          <div className="d-flex">
            {showFlk && (
              <Button icon="flk" onClick={onFlk}>
                Launch FLK E-sign
              </Button>
            )}
            <Button icon="page" onClick={onPreview}>
              Preview
            </Button>
            <Button variant="btn-brand-yellow" onClick={onSend}>
              <LoadingTitle title="Send Proposal" />
            </Button>
          </div>
        </div>
      </>
    );
  }
};

export default ActionMenuCover;
