import { APPRAISAL_TYPES } from "app/constants/appraisalsConstants";

const {
  listingProposal,
  agentProfiling,
  marketUpdate,
  preAppraisal,
  informationMemorandum,
  prospecting,
  commercialListingProposal,
  businessListingProposal,
  residentialInformationMemorandum,
  tenantHandbook,
} = APPRAISAL_TYPES;

const getConfig = appraisal => {
  const type = appraisal?.meta?.appraisalType;
  if (type === listingProposal)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "your-property",
        title: appraisal.property.sectionTitle,
        hide: appraisal.property.detailsHidden,
      },
      {
        id: "market-report",
        title: appraisal.team.marketReportTitle,
        hide: appraisal.team.reportHidden,
      },
      {
        id: "suburb-profile",
        title: "Suburb Profile",
        hide: !(
          !appraisal.property.suburbHidden && appraisal.team.reportHidden
        ),
      },
      {
        id: "price-estimation",
        title: appraisal.comparables.sectionTitle,
        hide: appraisal.comparables.sectionHidden,
      },
      {
        id: "method-of-sale",
        title: appraisal.methodOfSale.title,
        hide: appraisal.methodOfSale.hidden,
      },
      {
        id: "our-services",
        title: appraisal.ourServices.title,
        hide: appraisal.ourServices.hidden,
      },
      {
        id: "marketing",
        title: appraisal.marketing.title,
        hide: appraisal.marketing.sectionHidden,
      },
      {
        id: "campaign-calendar",
        title: appraisal.calendar.sectionTitle,
        hide: appraisal.calendar.sectionHidden,
      },
      {
        id: "service-fees",
        title: appraisal.meta.serviceFeesTitle,
        hide: appraisal.meta.serviceFeesHidden,
      },
      {
        id: "our-broker",
        title: appraisal.meta.ourBrokerTitle,
        hide: appraisal.meta.ourBrokerHidden,
      },
      {
        id: "team-details",
        title: "About the Team",
        hide: appraisal.team.sectionHidden,
      },
      { id: "next-steps", title: appraisal.nextSteps.title, hide: false },
    ];
  if (type === agentProfiling)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "team-details",
        title: "About the Team",
        hide: appraisal.team.sectionHidden,
      },
      {
        id: "price-estimation",
        title: appraisal.comparables.sectionTitle,
        hide: appraisal.comparables.sectionHidden,
      },
      {
        id: "market-report",
        title: appraisal.team.marketReportTitle,
        hide: appraisal.team.reportHidden,
      },
      { id: "next-steps", title: appraisal.nextSteps.title, hide: false },
    ];
  if (type === marketUpdate)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "market-report",
        title: appraisal.team.marketReportTitle,
        hide: appraisal.team.reportHidden,
      },
      {
        id: "price-estimation",
        title: appraisal.comparables.sectionTitle,
        hide: appraisal.comparables.sectionHidden,
      },
      {
        id: "team-details",
        title: "About the Team",
        hide: appraisal.team.sectionHidden,
      },
      { id: "next-steps", title: appraisal.nextSteps.title, hide: false },
    ];
  if (type === preAppraisal)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "your-property",
        title: appraisal.property.sectionTitle,
        hide: appraisal.property.detailsHidden,
      },
      {
        id: "market-report",
        title: appraisal.team.marketReportTitle,
        hide: appraisal.team.reportHidden,
      },
      {
        id: "suburb-profile",
        title: "Suburb Profile",
        hide: !(
          !appraisal.property.suburbHidden && appraisal.team.reportHidden
        ),
      },
      {
        id: "price-estimation",
        title: appraisal.comparables.sectionTitle,
        hide: appraisal.comparables.sectionHidden,
      },
      {
        id: "method-of-sale",
        title: appraisal.methodOfSale.title,
        hide: appraisal.methodOfSale.hidden,
      },
      {
        id: "our-services",
        title: appraisal.ourServices.title,
        hide: appraisal.ourServices.hidden,
      },
      {
        id: "marketing",
        title: appraisal.marketing.title,
        hide: appraisal.marketing.sectionHidden,
      },
      {
        id: "campaign-calendar",
        title: appraisal.calendar.sectionTitle,
        hide: appraisal.calendar.sectionHidden,
      },
      {
        id: "service-fees",
        title: appraisal.meta.serviceFeesTitle,
        hide: appraisal.meta.serviceFeesHidden,
      },
      {
        id: "our-broker",
        title: appraisal.meta.ourBrokerTitle,
        hide: appraisal.meta.ourBrokerHidden,
      },
      {
        id: "team-details",
        title: "About the Team",
        hide: appraisal.team.sectionHidden,
      },
      { id: "next-steps", title: appraisal.nextSteps.title, hide: false },
    ];
  if (type === prospecting)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "your-property",
        title: appraisal.property.sectionTitle,
        hide: appraisal.property.detailsHidden,
      },
      {
        id: "choose-us",
        title: appraisal.chooseUs.title,
        hide: appraisal.chooseUs.isHiddenSection,
      },
      {
        id: "our-values",
        title: appraisal.ourValues.title,
        hide: appraisal.ourValues.isHiddenSection,
      },
      {
        id: "our-growing-network",
        title: appraisal.ourGrowingNetwork.title,
        hide: appraisal.ourGrowingNetwork.isHiddenSection,
      },
      {
        id: "business-support",
        title: appraisal.businessSupport.title,
        hide: appraisal.businessSupport.hidden,
      },
      {
        id: "recruitment",
        title: appraisal.recruitmentAgents.title,
        hide: appraisal.recruitmentAgents.isHiddenSection,
      },
      {
        id: "grow-business",
        title: appraisal.growBusiness.title,
        hide: appraisal.growBusiness.hidden,
      },
      {
        id: "service-fees",
        title: appraisal.meta.serviceFeesTitle,
        hide: appraisal.meta.serviceFeesHidden,
      },
      {
        id: "next-steps",
        title: appraisal.nextSteps.title,
        hide: appraisal.nextSteps.hidden,
      },
    ];
  if (type === informationMemorandum)
    return [
      {
        id: "commercial-coverletter",
        title: "Introduction",
      },
      {
        id: "commercial-executiveSummary",
        title: appraisal.commercial.executiveSummary?.title,
        hide: appraisal.commercial.executiveSummary
          ? appraisal.commercial.executiveSummary.hidden
          : true,
      },
      {
        id: "commercial-opportunity",
        title: appraisal.commercial.opportunity?.title,
        hide: appraisal.commercial.opportunity
          ? appraisal.commercial.opportunity.hidden
          : true,
      },
      {
        id: "commercial-propertyOverview",
        title: appraisal.commercial.propertyOverview?.title,
        hide: appraisal.commercial.propertyOverview
          ? appraisal.commercial.propertyOverview.hidden
          : true,
      },
      {
        id: "commercial-investmentHighlights",
        title: appraisal.commercial.investmentHighlights?.title,
        hide: appraisal.commercial.investmentHighlights
          ? appraisal.commercial.investmentHighlights.hidden
          : true,
      },
      {
        id: "commercial-locationOverview",
        title: appraisal.commercial.locationOverview?.title,
        hide: appraisal.commercial.locationOverview
          ? appraisal.commercial.locationOverview.hidden
          : true,
      },
      {
        id: "commercial-marketOverview",
        title: appraisal.commercial.marketOverview?.title,
        hide: appraisal.commercial.marketOverview
          ? appraisal.commercial.marketOverview.hidden
          : true,
      },
      {
        id: "commercial-tenantOverview",
        title: appraisal.commercial.tenantOverview?.title,
        hide: appraisal.commercial.tenantOverview
          ? appraisal.commercial.tenantOverview.hidden
          : true,
      },
      {
        id: "commercial-financialOverview",
        title: appraisal.commercial.financialOverview?.title,
        hide: appraisal.commercial.financialOverview
          ? appraisal.commercial.financialOverview.hidden
          : true,
      },
      {
        id: "commercial-photo-gallery",
        title: appraisal.commercial.photoGallery?.title,
        hide: appraisal.commercial.photoGallery
          ? appraisal.commercial.photoGallery.hidden
          : true,
      },
      {
        id: "commercial-saleLeaseProcess",
        title: appraisal.commercial.saleLeaseProcess?.title,
        hide: appraisal.commercial.saleLeaseProcess
          ? appraisal.commercial.saleLeaseProcess.hidden
          : true,
      },
      {
        id: "commercial-priceList",
        title: appraisal.commercial.priceList?.title,
        hide: appraisal.commercial.priceList
          ? appraisal.commercial.priceList.hidden
          : true,
      },
      {
        id: "attachments",
        title: appraisal.commercial.attachments?.title,
        hide: appraisal.commercial.attachments
          ? appraisal.commercial.attachments.hidden
          : true,
      },
      {
        id: "agent-details",
        title: appraisal.commercial.agentDetails?.title,
        hide: appraisal.commercial.agentDetails
          ? appraisal.commercial.agentDetails.hidden
          : true,
      },
    ];

  if (type === residentialInformationMemorandum) {
    return [
      {
        id: "residential-coverletter",
        title: "Introduction",
      },
      {
        id: "residential-executiveSummary",
        title: appraisal.commercial.executiveSummary?.title,
        hide: appraisal.commercial.executiveSummary
          ? appraisal.commercial.executiveSummary.hidden
          : true,
      },
      {
        id: "residential-opportunity",
        title: appraisal.commercial.opportunity?.title,
        hide: appraisal.commercial.opportunity
          ? appraisal.commercial.opportunity.hidden
          : true,
      },
      {
        id: "residential-propertyOverview",
        title: appraisal.commercial.propertyOverview?.title,
        hide: appraisal.commercial.propertyOverview
          ? appraisal.commercial.propertyOverview.hidden
          : true,
      },
      {
        id: "residential-investmentHighlights",
        title: appraisal.commercial.investmentHighlights?.title,
        hide: appraisal.commercial.investmentHighlights
          ? appraisal.commercial.investmentHighlights.hidden
          : true,
      },
      {
        id: "residential-locationOverview",
        title: appraisal.commercial.locationOverview?.title,
        hide: appraisal.commercial.locationOverview
          ? appraisal.commercial.locationOverview.hidden
          : true,
      },
      {
        id: "residential-marketOverview",
        title: appraisal.commercial.marketOverview?.title,
        hide: appraisal.commercial.marketOverview
          ? appraisal.commercial.marketOverview.hidden
          : true,
      },
      {
        id: "residential-tenantOverview",
        title: appraisal.commercial.tenantOverview?.title,
        hide: appraisal.commercial.tenantOverview
          ? appraisal.commercial.tenantOverview.hidden
          : true,
      },
      {
        id: "residential-financialOverview",
        title: appraisal.commercial.financialOverview?.title,
        hide: appraisal.commercial.financialOverview
          ? appraisal.commercial.financialOverview.hidden
          : true,
      },
      {
        id: "residential-photo-gallery",
        title: appraisal.commercial.photoGallery?.title,
        hide: appraisal.commercial.photoGallery
          ? appraisal.commercial.photoGallery.hidden
          : true,
      },
      {
        id: "residential-saleLeaseProcess",
        title: appraisal.commercial.saleLeaseProcess?.title,
        hide: appraisal.commercial.saleLeaseProcess
          ? appraisal.commercial.saleLeaseProcess.hidden
          : true,
      },
      {
        id: "residential-priceList",
        title: appraisal.commercial.priceList?.title,
        hide: appraisal.commercial.priceList
          ? appraisal.commercial.priceList.hidden
          : true,
      },
      {
        id: "attachments",
        title: appraisal.commercial.attachments?.title,
        hide: appraisal.commercial.attachments
          ? appraisal.commercial.attachments.hidden
          : true,
      },
      {
        id: "agent-details",
        title: appraisal.commercial.agentDetails?.title,
        hide: appraisal.commercial.agentDetails
          ? appraisal.commercial.agentDetails.hidden
          : true,
      },
    ];
  }

  if (type === commercialListingProposal)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "commercial-executiveSummary",
        title: appraisal.executiveSummary.title,
        hide: appraisal.executiveSummary.isHiddenSection,
      },
      {
        id: "commercial-locationOverview",
        title: "Location Overview",
        hide: appraisal.commercial.propertyOverview
          ? appraisal.commercial.propertyOverview.hidden
          : true,
      },
      {
        id: "market-report",
        title: appraisal.team.marketReportTitle,
        hide: appraisal.team.reportHidden,
      },
      {
        id: "price-estimation",
        title: appraisal.comparables.sectionTitle,
        hide: appraisal.comparables.sectionHidden,
      },
      {
        id: "target-market",
        title: appraisal.targetMarket.title,
        hide: appraisal.targetMarket.isHiddenSection,
      },
      {
        id: "method-of-sale",
        title: appraisal.methodOfSale.title,
        hide: appraisal.methodOfSale.hidden,
      },
      {
        id: "marketing",
        title: appraisal.marketing.title,
        hide: appraisal.marketing.sectionHidden,
      },
      {
        id: "campaign-calendar",
        title: appraisal.calendar.sectionTitle,
        hide: appraisal.calendar.sectionHidden,
      },
      {
        id: "service-fees",
        title: appraisal.meta.serviceFeesTitle,
        hide: appraisal.meta.serviceFeesHidden,
      },
      {
        id: "team-details",
        title: "About the Team",
        hide: appraisal.team.sectionHidden,
      },
      {
        id: "next-steps",
        title: appraisal.nextSteps.title,
        hide: false,
      },
      {
        id: "commercial-disclaimer",
        title: appraisal.disclaimer.title,
        hide: appraisal.disclaimer.isHiddenSection,
      },
    ];
  if (type === businessListingProposal)
    return [
      {
        id: "cover-letter",
        title: "Introduction",
        hide: appraisal.vendor.coverLetterHidden,
      },
      {
        id: "your-property",
        title: appraisal.property.sectionTitle,
        hide: appraisal.property.detailsHidden,
      },
      {
        id: "business-marketTrends",
        title: appraisal.marketTrends.title,
        hide: appraisal.marketTrends.isHiddenSection,
      },
      {
        id: "suburb-profile",
        title: "Suburb Profile",
        hide: !(
          !appraisal.property.suburbHidden && appraisal.team.reportHidden
        ),
      },
      {
        id: "price-estimation",
        title: appraisal.comparables.sectionTitle,
        hide: appraisal.comparables.sectionHidden,
      },
      {
        id: "method-of-sale",
        title: appraisal.methodOfSale.title,
        hide: appraisal.methodOfSale.hidden,
      },
      {
        id: "our-services",
        title: appraisal.ourServices.title,
        hide: appraisal.ourServices.hidden,
      },
      {
        id: "marketing",
        title: appraisal.marketing.title,
        hide: appraisal.marketing.sectionHidden,
      },
      {
        id: "service-fees",
        title: appraisal.meta.serviceFeesTitle,
        hide: appraisal.meta.serviceFeesHidden,
      },
      {
        id: "our-broker",
        title: appraisal.meta.ourBrokerTitle,
        hide: appraisal.meta.ourBrokerHidden,
      },
      {
        id: "team-details",
        title: "About the Team",
        hide: appraisal.team.sectionHidden,
      },
      { id: "next-steps", title: appraisal.nextSteps.title, hide: false },
    ];
  if (type === tenantHandbook)
    return [
      {
        id: "residential-office-information",
        title: appraisal.articles?.find(
          item => item.slug === "office-information",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "office-information",
        )?.active,
      },
      {
        id: "residential-tenant-responsibilities",
        title: appraisal.articles?.find(
          item => item.slug === "tenant-responsibilities",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "tenant-responsibilities",
        )?.active,
      },
      {
        id: "residential-rental-payments",
        title: appraisal.articles?.find(
          item => item.slug === "rental-payments",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "rental-payments",
        )?.active,
      },
      {
        id: "residential-during-your-tenancy",
        title: appraisal.articles?.find(
          item => item.slug === "during-your-tenancy",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "during-your-tenancy",
        )?.active,
      },
      {
        id: "residential-routine-inspections",
        title: appraisal.articles?.find(
          item => item.slug === "routine-inspections",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "routine-inspections",
        )?.active,
      },
      {
        id: "residential-property-maintenance",
        title: appraisal.articles?.find(
          item => item.slug === "property-maintenance",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "property-maintenance",
        )?.active,
      },
      {
        id: "residential-vacating-the-property",
        title: appraisal.articles?.find(
          item => item.slug === "vacating-the-property",
        )?.title,
        hide: !appraisal.articles?.find(
          item => item.slug === "vacating-the-property",
        )?.active,
      },
      {
        id: "attachments",
        title: appraisal.commercial.attachments?.title,
        hide: appraisal.commercial.attachments
          ? appraisal.commercial.attachments.hidden
          : true,
      },
    ];
  return [];
};

export default getConfig;
