import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment";

import { updateTeam } from "redux/reducers/appraisalReducer";

import ArticleModal from "../../CommonComponents/ArticleModal";
import HideContainer from "../../components/HideContainer";
import Testimonials from "../Testimonials";
import useTrackEvent from "app/hooks/useTrackEvent";
import { MoreInfoSubSection } from "../MoreInfoSubSection";

import AboutTeamSection from "./AboutTeamSection";
import Separator from "../../CommonComponents/Separator";
import AgentsSection from "./AgentsSection";

const TeamDetails = ({
  isHideContainer = true,
  isCommercial,
  awards,
  community,
  difference,
  profile,
  history,
  withoutCommercialStyle = false,
  withoutTestimonial = false,
}) => {
  const isRemoved = slug => {
    if (slug === "awards") return !awards;
    if (slug === "community") return !community;
    if (slug === "our-difference") return !difference;
    if (slug === "office-profile") return !profile;
    if (slug === "history") return !history;
  };

  const trackEvent = useTrackEvent();
  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);

  const [activeArticle, setActiveArticle] = useState({});
  const [articleModalShow, setArticleModalShow] = useState(false);
  const [sessionStart, setSessionStart] = useState({});

  const dispatch = useDispatch();

  const closeArticleModal = () => {
    const now = moment();
    const duration = moment.duration(now.diff(sessionStart));
    trackEvent(
      "Open article",
      activeArticle.title,
      parseInt(duration.as("seconds")),
    );
    setArticleModalShow(false);
  };
  const openArticleModal = currentArticle => {
    setActiveArticle(currentArticle);
    setArticleModalShow(true);
    setSessionStart(moment());
  };

  const hideDetailsHandler = val => {
    dispatch(updateTeam({ sectionHidden: val }));
  };

  const HideContainerTeam = (children) => (
    <HideContainer value={team.sectionHidden} onChange={hideDetailsHandler}>
      {children}
    </HideContainer>
  );

  const Content = (
    <React.Fragment>
      <AgentsSection withoutCommercialStyle={withoutCommercialStyle} />
      <AboutTeamSection isCommercial={isCommercial} />

      <MoreInfoSubSection
        isCommercial={isCommercial}
        openArticleModal={openArticleModal}
        isRemoved={isRemoved}
      />

      {!withoutTestimonial && <Testimonials />}
      <ArticleModal
        article={activeArticle}
        show={articleModalShow}
        onHide={closeArticleModal}
        editable={activeArticle.editable}
      />
      <Separator />
    </React.Fragment>
  );
  return isHideContainer ? HideContainerTeam(Content) : Content;
};

export default TeamDetails;
