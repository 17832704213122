import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal, ModalActions } from "app/components/Modal";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import produce from "immer";
import { updateMarketingOptions } from "redux/reducers/appraisalReducer";
import { ToastContext } from "app/components/ToastProvider";
import { reorder } from "../../../../Appraisals/CreateAppraisal/utils";

const warningMessage = {
  severity: "warn",
  summary: "Warning",
  detail: "You can add maximum 3 tabs",
  life: 4000,
};

const ActivatePacksModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const marketingOptions =
    useSelector(({ appraisal }) => appraisal.marketing.options, shallowEqual) ||
    [];
  const [tabList, setTabList] = useState([]);

  const activeTabList = tabList.filter(tab => tab.active);

  useEffect(() => {
    setTabList(marketingOptions.tabs);
  }, [marketingOptions.tabs, show]);

  const onChangeMarketingActivity = (active, index) => {
    if (active && activeTabList.length > 2) {
      return toast.current.show(warningMessage);
    }
    const updatedTabList = produce(tabList, draft => {
      draft[index].active = active;
    });
    setTabList(updatedTabList);
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    const newTabList = reorder(
      tabList,
      result.source.index,
      result.destination.index,
    );
    setTabList(newTabList);
  };

  const saveAllChanges = () => {
    const updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.tabs = tabList;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
    onHide();
  };

  const { toast } = useContext(ToastContext);

  return (
    <Modal open={show} onClose={onHide}>
      <Box display="flex" flexDirection="column" padding={5}>
        <Typography variant="h5" className="mb-5">
          A maximum of 3 packs can be selected
        </Typography>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableMarketingPack">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {tabList.map((tab, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FormControlLabel
                          key={index}
                          control={
                            <div>
                              <DragIndicatorIcon className="m-auto" />
                              <Checkbox
                                checked={tab.active}
                                onChange={event =>
                                  onChangeMarketingActivity(
                                    event.target.checked,
                                    index,
                                  )
                                }
                              />
                            </div>
                          }
                          label={tab.title}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <ModalActions
        onCancel={onHide}
        onSubmit={saveAllChanges}
        submitTitle="Save and Close"
      />
    </Modal>
  );
};

export default ActivatePacksModal;
