import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "375px",
    height: "100%",
    margin: "50px auto",
    border: "16px black solid",
    borderTopWidth: "60px",
    borderBottomWidth: "60px",
    borderRadius: "36px",
    "&:before" : {
      content: "''",
      display: "block",
      width: "60px",
      height: "5px",
      position: "absolute",
      top: "-30px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#333",
      borderRadius: "10px"
    },
    "&:after" : {
      content: "''",
      display: "block",
      width: "35px",
      height: "35px",
      position: "absolute",
      left: "50%",
      bottom: "-65px",
      transform: "translate(-50%, -50%)",
      background: "#333",
      borderRadius: "50%",
    },
  },
  content: {
    width: "100%",
    height: "100%",
    background: "white",
  },
})

const SmartphoneView = ({children}) => {
  const classes = useStyles();
  return (

    <div className={classes.root}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

export default SmartphoneView;
