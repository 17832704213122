import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { makeStyles, Drawer, ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "redux/store";
import { Link } from "react-router-dom";
import {
  fetchMessagesListByType,
  fetchReadMessage,
  fetchDeleteMessage,
} from "redux/actions";
import {
  getMessagesList,
  getMessagesTotalPages,
  getIsLoadingMessagesList,
  getTotalUnreadedMessagesCount,
} from "redux/selectors/messagesSelector";
import PaginationSelective from "app/components/PaginationSelective";
import Loader from "app/components/ui/Loader";
import GridLinksList from "app/components/GridLinksList";
import { NoData } from "app/components/NoData";
import { Message } from "../../interfaces";
import MessageBlock from "./MessageBlock";
import Tabs from "./Tabs";

const useStyles = makeStyles({
  panel: {
    //@ts-ignore
    zIndex: "95 !important",
  },
  paper: {
    marginLeft: "65px",
  },
  mainContainer: {
    width: "467px",
    "& .item-row": {
      height: "auto",
      padding: "0px !important",
      "&:hover": {
        background: "none",
      },
    },
    "& .item": {
      "&:first-child": {
        margin: 0,
      },
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "82px",
  },
  updateButton: {
    alignItems: "center",
    padding: "8px 16px",
    width: "103px",
    height: "32px",
    background: "#FDB818",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FBFBFB",
  },
  title: {
    fontSize: "24px",
    lineHeight: "32px",
    color: "#333333",
  },
  titleBlock: {
    display: "flex",
    padding: "20px",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    color: "#6B6B6B",
    marginLeft: "10px",
    cursor: "pointer",
  },
});

interface MessagesBoardProps {
  isShowPanel: boolean;
  setIsShowPanel(value: boolean): void;
}

const MessagesBoard = ({ isShowPanel, setIsShowPanel }: MessagesBoardProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState("all");
  const messages = useSelector(getMessagesList);
  const totalPages = useSelector(getMessagesTotalPages);
  const isLoading = useSelector(getIsLoadingMessagesList);
  const totalUnreadedMessages = useSelector(getTotalUnreadedMessagesCount);
  const officeSpeciality = useSelector(
    (state: RootState) => state.auth?.user?.office?.speciality,
    shallowEqual,
  );
  const userId = useSelector(
    ({ auth }: RootState) => auth.user._id,
    shallowEqual,
  );
  const userPermission = useSelector(
    ({ auth }: RootState) => auth.user.permissions,
    shallowEqual,
  );

  useEffect(() => {
    if (isShowPanel) {
      dispatch(
        fetchMessagesListByType({
          speciality: officeSpeciality,
          page,
          activeTab,
        }),
      );
    }
  }, [officeSpeciality, page, activeTab, dispatch, isShowPanel]);

  useEffect(() => {
    setPage(1);
  }, [activeTab]);

  const onHandleMessage = (message: Message) => {
    if (!message?.seen?.includes(userId)) {
      const newMessage: any = {
        ...message,
        seen: [...message?.seen, userId],
      };
      const index = messages.findIndex(
        (item: any) => item?._id === newMessage?._id,
      );

      dispatch(
        fetchReadMessage({
          index,
          newMessage,
        }),
      );
    }
  };

  const onDeleteMessage = (message: Message) => {
    if (!message?.message_unvisible_to?.includes(userId)) {
      const newMessage: any = {
        ...message,
        message_unvisible_to: [...message?.message_unvisible_to, userId],
      };
      const index = messages.findIndex(
        (item: any) => item?._id === newMessage?._id,
      );

      dispatch(
        fetchDeleteMessage({
          index,
          newMessage,
          page,
          activeTab,
        }),
      );
    }
  };

  const onHandleClose = () => {
    setPage(1);
    setIsShowPanel(false);
  };

  const messagessData = (data: Array<Message>) => {
    return data.map((message: Message) => ({
      id: message?._id,
      mainData: [
        {
          content: (
            <MessageBlock
              message={message}
              isUnread={!message.seen?.includes(userId)}
              onHandleMessage={onHandleMessage}
              onDeleteMessage={onDeleteMessage}
            />
          ),
        },
      ],
    }));
  };

  const tabsData = [
    {
      button: { title: "All", value: "all", counter: totalUnreadedMessages },
      component: GridLinksList,
      componentProps: {
        content: messagessData(messages),
        name: "All",
        hasLink: false,
      },
    },
    {
      button: { title: "Important", value: "important" },
      component: GridLinksList,
      componentProps: {
        content: messagessData(messages),
        name: "important",
        hasLink: false,
      },
    },
    {
      button: { title: "Read", value: "read" },
      component: GridLinksList,
      componentProps: {
        content: messagessData(messages),
        name: "read",
        hasLink: false,
      },
    },
  ];

  return (
    <ClickAwayListener onClickAway={onHandleClose} mouseEvent="onMouseDown">
      <Drawer
        anchor={"left"}
        open={isShowPanel}
        onClose={() => onHandleClose}
        classes={{ root: classes.panel, paper: classes.paper }}
        onBackdropClick={onHandleClose}
      >
        <div className={`messagesContainer ${classes.mainContainer}`}>
          {isLoading && <Loader />}
          <div className={`${classes.titleBlock} justify-content-between`}>
            <h5 className={classes.title}>Updates</h5>
            <div className="d-flex">
              {userPermission === 3 && (
                <h4 className="d-flex flex-center rounded-top">
                  <Link
                    to="/notifications/post"
                    onClick={() => onHandleClose()}
                  >
                    <span className={classes.updateButton}>New Update</span>
                  </Link>
                </h4>
              )}
              <div className={classes.closeIcon}>
                <CloseIcon onClick={() => onHandleClose()} />
              </div>
            </div>
          </div>
          <Tabs
            data={tabsData}
            className=""
            onTabClick={(val: string) => setActiveTab(val)}
          />
          {!messages?.length && !isLoading ? (
            <NoData>No updates</NoData>
          ) : (
            <div className={classes.paginationBlock}>
              <PaginationSelective
                page={page}
                setPage={setPage}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
      </Drawer>
    </ClickAwayListener>
  );
};

export default MessagesBoard;
