import React from "react";
import { Button, withStyles } from "@material-ui/core";

const CustomButton = withStyles({
  root: {
    color: "#000",
    fontWeight: 600,
    padding: "10px 20px",
    marginRight: "1.25rem",
  },
})(Button);

const AddMarketingButton = ({children, ...props}) => (
  <CustomButton {...props} variant="outlined" disableElevation>
    {children}
  </CustomButton>
);

export default AddMarketingButton;
