import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

import './tooltip.scss';

const DELAY = 500;

const TooltipWrapper = ({ Component, props }) => {
  const [show, setShow] = useState(false);

  let onElement = false;
  const showTooltip = debounce(() => {
    onElement && setShow(true);
  }, DELAY);

  const mouseX = useRef(0);
  const mouseY = useRef(0);

  const mouseMove = (e) => {
    mouseX.current = e.clientX;
    mouseY.current = e.clientY;
  };

  const mouseEnter = () => {
    onElement = true;
    showTooltip();
  };

  const mouseLeave = () => {
    onElement = false;
    if (show) {
      setShow(false);
    }
  };

  const tooltip = useRef(document.createElement('div'));

  useEffect(() => {
    if (show) {
      const tooltipOffsetX = props.tooltipOffsetX || 208
      const tooltipOffsetY = props.tooltipOffsetY || 12
      const availableWidth = window.innerWidth - mouseX.current;
      const shiftX = availableWidth < 250 ? mouseX.current - tooltipOffsetX : mouseX.current + 8;
      const parent = document.querySelector('#root');
      tooltip.current.className = 'custom-tooltip';
      tooltip.current.style.cssText = `top: ${mouseY.current + tooltipOffsetY}px; left: ${shiftX}px;`;
      tooltip.current.innerText = props.tooltip;
      parent.append(tooltip.current);
    } else {
      tooltip.current.remove();
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <div
      onMouseMove={mouseMove}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Component {...props} />
    </div>
  );
};

const withTooltip = Component => props => {
    if (props.tooltip) {
      return <TooltipWrapper Component={Component} props={props} />;
    }
    return <Component {...props} />;
  }
;

export default withTooltip;
