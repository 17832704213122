import axios from "axios";
import API_URL from "./api";

export default class OfficesService {
  static getOfficeOptions = (
    searchParam,
    limit = null,
  ) => {
    let search = searchParam || "";

    return axios.get(
      `${API_URL}/officeOptions?search=${search}&limit=${limit}`,
    );
  };

  static getOffices = (
    searchParam,
    pageNumber,
    filterParam = "updated_at",
    sortParam = "descending",
  ) => {
    let search = searchParam || "";

    let page = pageNumber || 1;

    let filter = filterParam || "updated_at";

    let sort = sortParam || "descending";

    return axios.get(
      `${API_URL}/offices?search=${search}&page=${page}&filter=${filter}&sort=${sort}`,
    );
  };

  static getOffice = id => {
    return axios.get(`${API_URL}/offices/${id}`);
  };

  static getNewOffices = () => {
    return axios.get(`${API_URL}/new_offices`);
  };

  static getNearOffices = (lng, lat, distance) => {
    return axios.get(`${API_URL}/offices?locationNear=${true}&lng=${lng}&lat=${lat}&locationDistance=${distance}`);
  };

  static getOfficesByState = state => {
    return axios.get(`${API_URL}/offices?state=${state}`);
  };

  static activateOffice = (body) => {
		return axios.post(`${API_URL}/vaultre_offices/activate`, body, {});
	}

  static deactivateOffice = id => {
    return axios.put(`${API_URL}/offices/${id}/deactivate`, {});
  };

  static reactivateOffice = id => {
    return axios.put(`${API_URL}/offices/${id}/reactivate`, {});
  };

  static updateOffice = (id, body) => {
    return axios.put(`${API_URL}/offices/${id}`, body, {});
  };

  static syncAgents = (token, officeID) => {
    let officeToken = {
      token: token,
      officeID: officeID,
    };
    return axios.post(
      `${API_URL}/vaultre_offices/sync/agents`,
      officeToken,
      {},
    );
  };
}
