import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useGetDashboard } from "redux/hooks";
import { fetchLeaders } from "redux/actions";
import {
  LEADERS_DASHBOARD_PAGINATION_STEP,
  LEADERS_LEADERBOARD_PAGINATION_STEP,
} from "shared/constants";

import { getLeadersExtendedArray } from "redux/selectors/dashboardSelector";

export const useGetLeaders = ({
  isLeadersPage = false,
  page = 1,
  search,
  orderField,
  orderType,
}) => {
  const dispatch = useDispatch();
  const { dashboard } = useGetDashboard();
  const leaders = useSelector(getLeadersExtendedArray);

  const { weeks, range, leadersListType, isLoadingLeaders, leadersMeta } =
    dashboard || {};
  const limit = isLeadersPage
    ? LEADERS_LEADERBOARD_PAGINATION_STEP
    : LEADERS_DASHBOARD_PAGINATION_STEP;

  useEffect(() => {
    dispatch(
      fetchLeaders({
        search,
        weeks,
        limit,
        page,
        listType: leadersListType,
        range,
        orderField,
        orderType,
        withMetric: !isLeadersPage
      }),
    );
  }, [weeks, leadersListType, page, range, limit, dispatch, search, orderField, orderType, isLeadersPage]);

  return {
    leaders,
    isLoadingLeaders,
    limit,
    leadersMeta,
    leadersListType,
  };
};
