import React, { useEffect, useState, useMemo } from "react";
import { compose } from "redux";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import pinIcon from "../../../assets/images/pin.png";
import GoogleMapStyle from "./GoogleMapStyle";
import GoogleMapContainerStyles from "./GoogleMapContainer.style";

declare global {
  interface Window {
    google: any;
  }
}

interface ContextGoogleMap {
  center: {
    lat: number;
    lng: number;
    zoom?: number;
  };
  classes: any;
  isMultiMarkers?: boolean;
  isOnlyMarkers?: boolean;
  backgroundTheme?: "dim";
  markers?: Array<{
    lat: number;
    lng: number;
    title: string;
  }>;
}

const GoogleMapContainer = (props: ContextGoogleMap) => {
  const { center, classes } = props;

  const defaultCenterMap = useMemo(
    () => ({ lat: +center.lat + 0.002, lng: center.lng }),
    [center],
  );

  const [activeMarkerHover, setActiveMarkerHover] = useState<number | null>(null);
  const [activeMarker, setActiveMarker] = useState<number | null>(null);
  const [mapRef, setMapRef] = useState<any>(null);
  const [centerMap, setCenterMap] = useState<any>(defaultCenterMap);

  useEffect(() => {
    setCenterMap(defaultCenterMap);
  }, [defaultCenterMap]);

  const centerMapZoom = center.zoom || 16;
  const icon = {
    url: pinIcon,
    scaledSize: new window.google.maps.Size(30, 30),
  };

  let customMapTypeStyle: any = [];
  if (props.backgroundTheme) {
    customMapTypeStyle = [
      ...customMapTypeStyle,
      ...(GoogleMapStyle[`${props.backgroundTheme}Background`] || []),
    ];
  }

  if (props.isOnlyMarkers) {
    customMapTypeStyle = [
      ...customMapTypeStyle,
      ...GoogleMapStyle.withoutPointsOnTheMap,
    ];
  }

  const handleOnLoad = (map: any) => {
    setMapRef(map);
  };

  const handleOnDomReady = () => {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setCenterMap({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  if (!center.lat || !center.lng) return <React.Fragment />;

  return (
    <div className={classes.mapContainer}>
      <GoogleMap
        mapContainerStyle={{ height: "400px", width: "100%" }}
        center={centerMap}
        zoom={centerMapZoom}
        options={{ gestureHandling: "cooperative", styles: customMapTypeStyle }}
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
      >
        {props.isMultiMarkers ? (
          <MarkerClusterer maxZoom={9}>
            {clusterer =>
              props.markers?.map((item, index) => (
                <Marker
                  key={`Marker-${index}`}
                  position={{ lat: item.lat, lng: item.lng }}
                  title={item.title}
                  onClick={() => setActiveMarker(index)}
                  onMouseOver={() => {
                    setActiveMarker(null)
                    setActiveMarkerHover(index)
                  }}
                  onMouseOut={() => setActiveMarkerHover(null)}
                  icon={icon}
                  clusterer={clusterer}
                >
                  {[activeMarkerHover, activeMarker].includes(index) && (
                    <InfoWindow
                      key={`InfoWindow-${index}`}
                      onDomReady={handleOnDomReady}
                    >
                      <div className={classes.container}>
                        <div className={classes.iconsLayer}>{item.title}</div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))
            }
          </MarkerClusterer>
        ) : (
          <React.Fragment />
        )}
      </GoogleMap>
      <div className={classes.mapLegend}>
        <div>
          <img src={pinIcon} alt="pinIcon" />
          <span>Surrounding Raine & Horne offices</span>
        </div>
      </div>
    </div>
  );
};

export default compose(GoogleMapContainerStyles)(GoogleMapContainer);
