import { ProposalTypes } from "app/interfaces";
import { takeLatest, select, put, all } from "redux-saga/effects";
import {
  setCalendar,
  setAvailableCalendars,
  setCountry,
  updateAppraisal,
  updateAppraisalFromTemplate,
  updateMethodOfSaleField,
  updateOurServicesField,
  updateComparables,
  updateCommercialField,
  updateProperty as updateProposalProperty,
} from "redux/reducers";
import {
  setLoadProposalStart,
  setLoadProposalFinish,
} from "redux/reducers/commonReducer";
import {
  fetchArticles,
  prepareAppraisal,
} from "redux/actions/appraisalActions";
import {
  populateCalendar,
  populateStateInfo,
  getTeamData,
  getTestimonials,
  getNextStepActionTitle,
} from "app/modules/Appraisals/CreateAppraisal/utils";
import ProspectTemplatesService from "../../../api/ProspectTemplatesService";
import OfficesService from "../../../api/OfficesService";
import SuburbsService from "../../../api/SuburbsService";
import { generateBuilderData } from "./actions";
import { getDefaultSuburbsCoordinates } from "app/modules/Appraisals/CreateAppraisal/utils";

function* generateNotCommercialBuilderObject() {
  const { office, type, profile, property, suburb } = yield select(
    state => state.createProposal,
  );

  const { methodOfSale, commercial } = yield select(state => state.appraisal);
  const { selectedCalendar } = yield select(state => state.appraisal.calendar);

  const initialAppraisal: any = {
    recipient: {},
    articles: [],
    property: {},
    team: {},
    vendor: {},
    comparables: {},
    calendar: {},
    meta: {},
    methodOfSale: {},
    ourServices: {},
    marketing: {},
    testimonials: {},
    stateInfo: {},
    nextSteps: {},
    country: "Australia",
  };

  const updateMeta: any = {
    appraisalType: type,
    classification: office?.speciality || "residential",
    team: profile?.id,
    office: office?._id,
    website: office?.website,
    officeVaultreId: office?.groupid,
    flkEnabled: office?.flkEnabled,
  };

  const updateTeam: any = getTeamData(
    profile,
    office?.name,
    office?.address,
    office?.phone,
  );

  const updateNextSteps = {
    action: {
      title: getNextStepActionTitle(type),
      type: "email",
      value: profile?.email || "demo@mailinator.com",
    },
  };

  const updateTestimonials = {
    image: profile?.testimonials?.image,
    items: getTestimonials(profile?.testimonials),
  };

  const updateVendor: any = {};

  const updateProperty: any = {};

  let updateStateInfo: any = {};

  let updateComparablesInfo: any;

  let template;

  yield put(setCountry(office?.country));

  if (type === ProposalTypes.preAppraisal) {
    template = profile?.templates?.template1;
    updateVendor.coverLetter = profile?.coverLetter;
  }

  if (type === ProposalTypes.listingProposal) {
    template = profile?.templates?.template2;
    updateVendor.coverLetter = profile?.coverLetter2;
  }

  if (type === ProposalTypes.marketUpdate) {
    template = profile?.templates?.template3;
    updateVendor.coverLetter = profile?.coverLetter3;
  }

  if (type === ProposalTypes.agentProfiling) {
    template = profile?.templates?.template4;
    const state = office?.address?.match(/VIC|NSW|ACT|QLD|NT|SA|TAS|WA/) || [];
    updateStateInfo = { ...populateStateInfo(state[0]) };

    updateVendor.coverLetter = profile?.coverLetter4;
    const addressArr = office?.address?.split(",").reverse() || [];
    updateProperty.address = {
      suburb: {
        name: addressArr[3].trim(),
        postcode: addressArr[1].trim(),
        state: {
          abbreviation: addressArr[2].trim(),
        },
      },
    };
    updateProperty.agentPriceOpinionHidden = true;

    updateComparablesInfo = {
      sectionTitle:
        profile?.departmentCategory?.split(" ")[1] === "Sales"
          ? "Recent Sales & Listings"
          : "Current Listings",
      sectionSubtitle: "Keep up-to-date with your local market",
    };

    updateMeta.classification = profile?.departmentCategory
      ?.split(" ")[0]
      .toLowerCase();

    updateMeta.category =
      profile?.departmentCategory?.split(" ")[1] === "Sales"
        ? "sales"
        : "lease";
  } else {
    updateComparablesInfo = {
      sectionTitle: "Price Estimate",
      comparableTitle: "Comparable Sales",
      sectionSubtitle: "Opinion for sale price for your property",
    };
  }

  if (type === ProposalTypes.recruitment) {
    template = profile?.templates?.template5;
    updateVendor.coverLetter = profile?.coverLetter5;
  }

  if (type === ProposalTypes.informationMemorandum) {
    template = profile?.templates?.template6;
    updateVendor.coverLetter = profile?.coverLetter6;
  }

  if (type === ProposalTypes.residentialInformationMemorandum) {
    template = profile?.templates?.template9;
    updateVendor.coverLetter = profile?.coverLetter8;
  }

  if (type === ProposalTypes.prospecting) {
    template = profile?.templates?.template10;
    updateVendor.coverLetter = profile?.coverLetter13;
    updateMeta.serviceFeesTitle = "Franchise Fee Structure";
    updateTeam.leaderNameSubtitle = "Your supporting Network Manager";
  }

  if (type === ProposalTypes.commercialListingProposal) {
    template = profile?.templates?.template13;
    updateVendor.coverLetter = profile?.coverLetter7;
    updateMeta.serviceFeesTitle = "Agency Fees";
    updateTeam.marketReportTitle = "Market Overview";
    yield put(
      updateCommercialField({
        data: {
          ...commercial?.locationOverview,
          title: "Property Overview",
        },
        name: "locationOverview",
      }),
    );
  }

  if (type === ProposalTypes.businessListingProposal) {
    updateProperty.sectionTitle = "Your Business";
    updateProperty.sectionSubtitle = "What sets your business apart?";
    if (property && property?.saleLifeId === null) {
      template = profile?.templates?.template12;
    } else template = profile?.templates?.template11;
    updateVendor.coverLetter = profile?.coverLetter9;
    updateComparablesInfo = {
      sectionTitle: "Recent Industry Sales",
      sectionSubtitle: "Opinion for sale to your business",
    };
  }

  if (type === ProposalTypes.tenantHandbook) {
    template = profile?.templates?.template15;
    updateVendor.coverLetter = profile?.coverLetter14;
  }

  // keep the old logical steps
  yield put(
    updateAppraisal({
      ...initialAppraisal,
      property: updateProperty,
      team: updateTeam,
      meta: updateMeta,
      testimonials: updateTestimonials,
      vendor: updateVendor,
      stateInfo: updateStateInfo,
      comparables: updateComparablesInfo,
      nextSteps: updateNextSteps,
      country: office?.country,
    }),
  );

  if (type === ProposalTypes.marketUpdate) {
    yield put(
      updateAppraisal({
        ...initialAppraisal,
        property: {
          address: {
            suburb: { ...suburb },
          },
          detailsHidden: true,
          agentPriceOpinionHidden: true,
        },
        comparables: {
          sectionTitle:
            profile?.departmentCategory?.split(" ")[1] === "Sales"
              ? "Recent Sales & Listings"
              : "Current Listings",
          sectionSubtitle: "Keep up-to-date with your local market",
        },
        meta: {
          classification: profile?.departmentCategory
            ?.split(" ")[0]
            .toLowerCase(),
          category:
            profile?.departmentCategory?.split(" ")[1] === "Sales"
              ? "sales"
              : "lease",
        },
        stateInfo: populateStateInfo(suburb.state.abbreviation),
      }),
    );
  } else if (type === ProposalTypes.prospecting) {
    yield all([
      put(setCalendar(populateCalendar(office, "sales", selectedCalendar))),
      put(setAvailableCalendars(office?.timeline_settings)),
      put(
        updateAppraisal({
          ...initialAppraisal,
          property: {
            address: {
              suburb: { ...suburb },
            },
            displayAddress: `${suburb?.postcode} ${suburb?.state?.abbreviation}`,
            coordinates: getDefaultSuburbsCoordinates(
              suburb?.state?.abbreviation,
            ),
            detailsHidden: false,
            agentPriceOpinionHidden: true,
            sectionTitle: "Your Region",
            sectionSubtitle: "Know your service area and market",
          },
          comparables: {
            sectionTitle:
              profile?.departmentCategory?.split(" ")[1] === "Sales"
                ? "Recent Sales & Listings"
                : "Current Listings",
            sectionSubtitle: "Keep up-to-date with your local market",
          },
          meta: {
            classification: profile?.departmentCategory
              ?.split(" ")[0]
              .toLowerCase(),
            category:
              profile?.departmentCategory?.split(" ")[1] === "Sales"
                ? "sales"
                : "lease",
            office: office?._id,
          },
          stateInfo: populateStateInfo(suburb.state.abbreviation),
        }),
      ),
    ]);
  } else if (property) {
    yield put(prepareAppraisal());
    updateProperty.suburbHidden = property?.suburbHidden;
    interface updateReduxField {
      field: string;
      value: Boolean;
    }

    const updateMethodOfSaleFieldObject: updateReduxField = {
      field: "hidden",
      value: methodOfSale?.hidden || true,
    };

    const updateOurServicesFieldObject: updateReduxField = {
      field: "hidden",
      value: true,
    };

    if (type === "listingProposal") {
      if (property.saleLifeId) {
        updateMethodOfSaleFieldObject.value = methodOfSale?.hidden || false;
        updateOurServicesFieldObject.value = true;
      } else {
        updateMethodOfSaleFieldObject.value = methodOfSale?.hidden || true;
        updateOurServicesFieldObject.value = false;
      }
    }
    if (type === ProposalTypes.commercialListingProposal) {
      updateMethodOfSaleFieldObject.value = false;
    }

    yield all([
      put(updateMethodOfSaleField(updateMethodOfSaleFieldObject)),
      put(updateOurServicesField(updateOurServicesFieldObject)),
    ]);

    if (type === "listingProposal" && !property.saleLifeId) {
      yield put(
        updateComparables({
          sectionTitle: "Estimated Rental Return",
          comparableTitle: "Comparable Rentals",
          sectionSubtitle:
            "Opinion on the weekly rental return of your property",
        }),
      );
    }

    if (type === "preAppraisal") {
      yield put(
        updateAppraisal({
          ...initialAppraisal,
          team: {
            reportHidden: true,
          },
          property: {
            suburbHidden: true,
            agentPriceOpinionHidden: true,
          },
          marketing: {
            packsHidden: true,
          },
          calendar: {
            sectionHidden: true,
          },
          meta: {
            serviceFeesHidden: true,
          },
        }),
      );
    }
  }
  if (type === ProposalTypes.prospecting) {
    try {
      const { lng, lat } = yield SuburbsService.getCoordinates(
        `${suburb?.postcode} ${suburb?.state?.abbreviation}`,
      );
      const { data } = yield OfficesService.getOfficesByState(
        suburb?.state?.abbreviation,
      );
      const offices = data || [];
      yield put(
        updateProposalProperty({
          address: { suburb: { ...suburb, ...offices } },
          coordinates: { lon: lng, lat, zoom: 14 },
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }

  if (
    type === ProposalTypes.preAppraisal &&
    property &&
    property?.saleLifeId === null
  ) {
    template = profile?.templates?.template7;
  }

  if (
    type === ProposalTypes.listingProposal &&
    property &&
    property?.saleLifeId === null
  ) {
    template = profile?.templates?.template8;
  }

  if (
    type === ProposalTypes.commercialListingProposal &&
    property &&
    property?.saleLifeId === null
  ) {
    template = profile?.templates?.template14;
  }

  yield put(fetchArticles());

  if (template) {
    yield put(setLoadProposalStart("getTemplate"));
    const templateData: Generator = yield ProspectTemplatesService.getTemplate(
      template,
    )
      .then(res => res.data)
      .catch(() => null);
    if (templateData) yield put(updateAppraisalFromTemplate(templateData));
    yield put(setLoadProposalFinish("getTemplate"));
  }
}
export default function* watchCreateProposal() {
  yield takeLatest(generateBuilderData, generateNotCommercialBuilderObject);
}
