import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import { API_URL } from "../Auth/_redux/authCrud";
import axios from "axios";
import { Box, LinearProgress, withStyles } from "@material-ui/core";
import PMFees from "../Common/PMFees";

const StickyHeader = withStyles({
  root: {
    position: 'sticky',
    top: 0,
    padding: '10px 0',
    zIndex: 999,
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },
})(Box)

const PmFees = ({id}) => {

  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [pmfees, setPmfees] = useState([]);

  useEffect(() => {
    const apiUrl = API_URL + `offices/${id}`;
    setLoading(true);
    axios.get(apiUrl)
      .then(resp => {
        setPmfees(resp.data?.pmfees || []);
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later', life: 4000 });
      })
      .finally(() => setLoading(false))

  }, [id]);

  const saveChanges = () => {
    const apiUrl = `${API_URL}offices/${id}`;
    axios.put(apiUrl, { pmfees })
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PM Fees have been successfully updated', life: 3000 });
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later', life: 4000 });
      })
  };

  return (
    <div className="card-body pt-4">
      <Toast ref={toast} />
      { loading && <LinearProgress color="primary" className="mb-5 mt-5"/>}
      <StickyHeader>
        <h2>PM Fees</h2>
        <button
          onClick={() => saveChanges()}
          className="btn btn-primary w-100px"
        >
          Save
        </button>
      </StickyHeader>

      <PMFees
        pmFeesList={pmfees}
        onChange={setPmfees}
        showFooter
      />
    </div>
  )

}

export default PmFees
