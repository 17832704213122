import { cloneDeep } from "lodash";
import imageCompression from "browser-image-compression";
import * as ImageLibraryService from "../../../api/ImageLibraryService";
import { shallowEqual, useSelector } from "react-redux";
import { ChonkyActions } from "chonky";
import { actionsList, renameFolderAction, uploadLocalFile } from "./actions";

export const getFileObject = file => ({
  id: file._id,
  name: file.filename,
  modDate: file.updated_at,
  parentId: file.folder,
  thumbnailUrl: file.url,
})

const getNewFileMap = (file, fileMap, currentFolderId) => {
  let newFileMap = { ...fileMap };
  newFileMap[file._id] = getFileObject(file);
  const parent = newFileMap[currentFolderId];
  const newChildrenIds = newFileMap[currentFolderId].childrenIds.concat(file._id);
  newFileMap[currentFolderId] = {
    ...parent,
    childrenIds: newChildrenIds,
    childrenCount: newChildrenIds.length,
  };
  return newFileMap;
}

export const deleteFiles = async (files, oldFileList) => {
  const newFileMap = cloneDeep(oldFileList);

  for ( const file of files ) {
    delete newFileMap[file.id];
    const apiAction = file.isDir ? ImageLibraryService.deleteFolder : ImageLibraryService.deleteFile;
    await apiAction(file.id)

    let parent = file.parentId ? newFileMap[file.parentId] : newFileMap["rootFolderId"];
    const newChildrenIds = parent.childrenIds.filter(id => id !== file.id);
    newFileMap[parent.id] = {
      ...parent,
      childrenIds: newChildrenIds,
      childrenCount: newChildrenIds.length,
    };
  }
  return newFileMap;
};

export const moveFiles = async (files, source, destination, oldFileList) => {
  const newFileMap = cloneDeep(oldFileList);
  let moveFilesList = files.filter(f => !f.isDir);
  let moveFoldersList = files.filter(f => f.isDir);
  const moveFileIds = new Set(files.map(f => f.id))

  let destinationFolder = destination.id === "rootFolderId" ? null : destination.id;

  // moving files on server
  if (moveFilesList.length) {
    moveFilesList = moveFilesList.map(file => ({
      imageID: file.id,
      folder: destinationFolder,
    }));
    await ImageLibraryService.moveFiles(moveFilesList)
  }
  // return oldFileList

  // moving folder on server
  if (moveFoldersList.length) {
    moveFoldersList = moveFoldersList.map(folder => ({
      id: folder.id,
      parent: destinationFolder,
    }));
    await ImageLibraryService.moveFolder(moveFoldersList)
  }

  // UPDATING LOCAL LIST
  //
  // Delete files from their source folder.
  let newSourceChildrenIds = source.childrenIds.filter( id => !moveFileIds.has(id) );
  newFileMap[source.id] = {
    ...source,
    childrenIds: newSourceChildrenIds,
    childrenCount: newSourceChildrenIds.length,
  };
  //
  // Add the files to their destination folder.
  const newDestinationChildrenIds = [
    ...destination.childrenIds,
    ...files.map(f => f.id),
  ];
  newFileMap[destination.id] = {
    ...destination,
    childrenIds: newDestinationChildrenIds,
    childrenCount: newDestinationChildrenIds.length,
  };
  //
  // Finally, update the parent folder ID on the files from source folder
  // ID to the destination folder ID.
  files.forEach(file => {
    newFileMap[file.id] = {
      ...file,
      parentId: destination.id,
    };
  });
  return newFileMap;
};

export const createFolder = async (folder, parentId, officeId, oldFileList) => {
  const newFileMap = cloneDeep(oldFileList);
  const parent = (parentId === "rootFolderId") || (parentId === "SharedFolders") ? null : parentId
  let newFolder = {
    title: folder.name,
    shared: folder.shared || parentId === "SharedFolders",
    owner: officeId,
    parent,
  };

  const { data } = await ImageLibraryService.createFolder(newFolder);
  newFileMap[data._id] = {
    id: data._id,
    name: data.title,
    isDir: true,
    modDate: data.updated_at,
    parentId: parentId,
    childrenIds: [],
    childrenCount: 0,
  };

  const newChildrenIds = newFileMap[parentId].childrenIds.concat(data._id);
  newFileMap[parentId] = {
    ...newFileMap[parentId],
    childrenIds: newChildrenIds,
    childrenCount: newChildrenIds.length,
  };
  return newFileMap
};

export const getImageData = file => {
  return new Promise(res => {
    const reader = new FileReader();
    reader.onload = async () => res(reader.result);
    reader.readAsDataURL(file);
  })
}

export const compressFile = async file => {
  const blobFile = await imageCompression(file, { maxSizeMB: 5 });
  return new File([blobFile], file.name, { lastModified: new Date() })
}

export const uploadFiles = async (files, parentId, officeId, oldFileList, onFileUploaded) => {
  let newFileMap = cloneDeep(oldFileList);

  for ( const file of Array.from(files) ) {
    const compressedFile = await compressFile(file)
    let postData = new FormData();
    postData.append("file", compressedFile);
    const folderId = parentId === "rootFolderId" ? undefined : parentId
    const { data } = await ImageLibraryService.uploadFile(postData, officeId, folderId)
    onFileUploaded(data)
    newFileMap = getNewFileMap(data, newFileMap, parentId)
  }
  return newFileMap
};

export const fetchFiles = (officeId, defaultFolder) => {
  return new Promise(async res => {
    const response = await ImageLibraryService.getFiles(officeId);
    const result = {};
    const {fileMap, rootFolderId } = response.data
    result.fileMap = fileMap
    result.rootFolderId = defaultFolder && fileMap
      ? Object.keys(fileMap).find(key => fileMap[key].name === defaultFolder) || rootFolderId
      : rootFolderId
    res(result)
  })
}

export const useFiles = (fileMap, currentFolderId) => {
  let files = [];
  if (fileMap) {
    const currentFolder = fileMap[currentFolderId];
    let childrenIds;
    if (currentFolder) {
      childrenIds = currentFolder.childrenIds;
      files = childrenIds.map(fileId => fileMap[fileId]);
    }
  }
  return files;
};

export const useFolderChain = (fileMap, currentFolderId) => {
  let folderChain = [];
  if (fileMap) {
    const currentFolder = fileMap[currentFolderId];
    if (currentFolder) {
      folderChain = [currentFolder];
      let parentId = currentFolder.parentId;
      while (parentId) {
        const parentFile = fileMap[parentId];
        if (parentFile) {
          folderChain.unshift(parentFile);
          parentId = parentFile.parentId;
        } else {
          break;
        }
      }
    }
  }
  return folderChain;
};

export const useActionList = (currentFolder, withMultipleUpload) => {
  const { permissions } = useSelector(({ auth }) => auth.user, shallowEqual);
  if (currentFolder?.deletable) {
    const standardActions = [withMultipleUpload ? ChonkyActions.UploadFiles : uploadLocalFile, ...actionsList]
    return permissions === 3 ? [...standardActions, renameFolderAction] : standardActions;
  } else {
    return permissions === 3 ? [ChonkyActions.DeleteFiles, renameFolderAction, ChonkyActions.CreateFolder] : []
  }
}

export const checkImageSize = (file, aspectRatio) => {
  return new Promise(res => {
    const i = new Image()
    i.onload = function(){
      const proportion = i.width / i.height;
      res(proportion.toFixed(2) === aspectRatio.toFixed(2))
    };
    i.src = file
  })
}
