import React from "react";
import { Dialog } from "@material-ui/core";
import { withStyles, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ModalActions } from "./ModalActions";

export { ModalActions };

const modalStyles = () => ({
  paper: {
    borderRadius: 0,
    borderColor: "#E0E3E7",
    overflow: "unset",
  },
});

const closeButtonStyles = () => ({
  root: {
    width: 40,
    height: 40,
    border: "1px solid",
    borderColor: "#E0E3E7",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    alignItems: "center",
    position: "absolute",
    top: "-20px",
    right: 14,
    zIndex: 99,
    "&:hover": {
      cursor: "pointer",
      "&>.MuiSvgIcon-root": {
        opacity: 0.5,
      },
    },
    "&>.MuiSvgIcon-root": {
      width: 23,
      height: 23,
      color: "#81858A",
    },
  },
});

const CloseButton = withStyles(closeButtonStyles)(props => (
  <Box {...props}>
    <CloseIcon fontSize="large" />
  </Box>
));

export const Modal = withStyles(modalStyles)(props => (
  <Dialog {...props}>
    <CloseButton onClick={props.onClose} className={props.buttonClassname} />
    {props.children}
  </Dialog>
));
