import React, { useState } from "react";
import { API_URL } from "../../Auth/_redux/authCrud";
import { ImageLibraryModal } from "../ImageLibrary";
import ImageIcon from "@material-ui/icons/Image";
import PropTypes from "prop-types";
import ActionsWrapper from "./ActionWrapper";

const getImage = id => {
  if (!id) return null;
  return id.toString().includes("/") ? id : `${API_URL}img/${id}`;
};

export const ImageCardSelector = React.memo(({ src, onClick }) => (
  <div className="image-input mr-5" style={{ background: "#f2f2f2" }}>
    <ImageCover src={getImage(src)} />
    <label
      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
      data-action="change"
      data-toggle="tooltip"
      title="Change image"
      data-original-title="Change image"
      onClick={onClick}
    >
      <i className="fa fa-pen icon-sm text-muted" />
    </label>
  </div>
));

const ImageCover = ({ src }) => {
  return src ? (
    <div className="h-120px">
      <img src={src} alt="Cover" style={{ maxHeight: "100%" }} />
    </div>
  ) : (
    <div
      className="d-flex flex-center flex-column h-120px w-150px"
      style={{ background: "#f2f2f2" }}
    >
      <ImageIcon style={{ fontSize: 40 }} />
      No Image provided
    </div>
  );
};

const Index = ({
  src,
  officeId,
  onImageSelected,
  aspectRatio,
  defaultFolder,
  withDelete,
}) => {
  const [imageLibraryModalShow, setImageLibraryModalShow] = useState(false);

  const showLibraryModal = () => setImageLibraryModalShow(true);
  const hideModal = () => setImageLibraryModalShow(false);
  const selectImage = ({ id }) => {
    setImageLibraryModalShow(false);
    onImageSelected(id);
  };

  return (
    <>
      <div className="image-input mr-5" style={{ background: "#f2f2f2" }}>
        <ActionsWrapper
          onEdit={showLibraryModal}
          onDelete={() => selectImage({ id: null })}
          withDelete={withDelete}
        >
          <ImageCover src={getImage(src)} />
        </ActionsWrapper>
      </div>
      <ImageLibraryModal
        aspectRatio={aspectRatio}
        officeId={officeId}
        show={imageLibraryModalShow}
        onHide={hideModal}
        onImageSelect={selectImage}
        defaultFolder={defaultFolder}
      />
    </>
  );
};

Index.propTypes = {
  src: PropTypes.string,
  officeId: PropTypes.string.isRequired,
  onImageSelected: PropTypes.func,
  onImageDeleted: PropTypes.func,
  aspectRatio: PropTypes.number,
  defaultFolder: PropTypes.string,
  withDelete: PropTypes.bool,
};

export default React.memo(Index);
