import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  officeData: {},
  isLoadProposal: 1,
  loadProposalWorker: [],
  popularArticles: [],
  isLoadingPopularArticles: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState: defaultState,
  reducers: {
    setOfficeData: (state, action) => {
      state.officeData = action.payload;
    },
    setLoadProposalStart: (state, action) => {
      if (!state.loadProposalWorker.includes(action.payload)) {
        state.isLoadProposal = state.isLoadProposal - 1;
        state.loadProposalWorker.push(action.payload);
      }
    },
    setLoadProposalFinish: (state, action) => {
      state.loadProposalWorker = state.loadProposalWorker.filter(
        i => i !== action.payload,
      );
      state.isLoadProposal = state.isLoadProposal + 1;
    },
    setIsLoadingPopularArticles: (state, action) => {
      state.isLoadingPopularArticles = action.payload;
    },
    setPopularArticles: (state, action) => {
      state.popularArticles = action.payload;
    },
  },
});

export const {
  setOfficeData,
  setLoadProposalStart,
  setLoadProposalFinish,
  setIsLoadingPopularArticles,
  setPopularArticles,
} = commonSlice.actions;

export default commonSlice.reducer;
