import React from "react";

export const ActionButtons = ({
  onSave,
  onPreview,
  onUpdate,
  onDelete,
  allowDelete,
  allowUpdate,
}) => {
  return (
    <div className="text-right">
      <button onClick={onSave} className="pull-right mr-3 btn btn-primary ">
        Save
      </button>
      <button onClick={onPreview} className="pull-right btn btn-success mr-2">
        Preview
      </button>
      {allowUpdate && (
        <button onClick={onUpdate} className="pull-right btn btn-danger mr-2">
          Update
        </button>
      )}
      {allowDelete && (
        <button onClick={onDelete} className="pull-right btn btn-danger mr-2">
          Delete
        </button>
      )}
    </div>
  );
};
