import { shallowEqual, useSelector } from "react-redux";
import { articlesByTypes as articlesConst } from "../../app/assets/constants";

const useArticles = (types) => {

  const articles = useSelector(
    ({ appraisal }) => appraisal.articles,
    shallowEqual,
  );
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );

  const selectedArticles = {};
  types.forEach(key => (selectedArticles[key] = true))

  let result = {};
  articles.forEach(article => {
    types.forEach(key => {
      if( articlesConst[appraisalType]?.[key]?.[category].includes(article.slug)){
        result[key] = [...(result[key] || []), article];
      }
    })
  });
  return result;
};
export default useArticles;
