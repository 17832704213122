import React from 'react';
import Header from 'app/modules/Dashboard/Header';
import Leaders from 'app/modules/Dashboard/Leaderboard/Corporate/Leaders';

import './styles.scss';

const Leaderboard = () => {
  return (
    <div className="leaders-page">
      <Header withCustomDates />
      <div className="leaderboard">
        <div className="leaderboard-corporate">
          <Leaders leadersPage withSearch />
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
