import React, { useEffect, useState } from "react";
import { Modal, ModalActions } from "app/components/Modal";
import { shallowEqual, useSelector } from "react-redux";
import { GetOfficeImages } from "../../../../api/ImageLibraryService";
import { getImageFiles } from "../../../Common/ModalImageLibrary/utils";
import { useToast } from "app/hooks/useToast";
import cn from "classnames";
import { LinearProgress, makeStyles } from "@material-ui/core";
import ImageCard from "./ImageCard";
import produce from "immer";
import { COMMERCIAL_INFOGRAPHIC_FOLDER_NAME } from "app/constants/commonConstants";
import FolderSelection from "./FolderSelection";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "600px",
    minHeight: "150px",
    overflow: "auto",
  },
}));

const InfographicsModal = ({
  show,
  onHide,
  onApply,
  infographics,
  infographicsFolders = [COMMERCIAL_INFOGRAPHIC_FOLDER_NAME],
  withInfographicsSelection = false,
}) => {
  const officeId = useSelector(
    state => state.appraisal.team.office,
    shallowEqual,
  );
  const { showToastMessage } = useToast();
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [
    selectedInfographicsFolders,
    setSelectedinfographicsFolders,
  ] = useState([infographicsFolders[0]] || []);
  const classes = useStyles();

  const onSelectImage = ({ id, title }) => {
    setSelectedList(currList => {
      const index = currList.findIndex(item => item.id === id);
      if (index === -1) {
        return [...currList, { id, title }];
      } else {
        return produce(currList, draft => {
          draft.splice(index, 1);
        });
      }
    });
  };

  const getImagesList = (folders, fileMap) => {
    let images = [];
    folders.forEach(folder => {
      let { imageList } = getImageFiles(folder, fileMap, true);
      images = [...images, ...(imageList || [])];
    });
    return images;
  };

  useEffect(() => {
    setLoading(true);
    if (show) {
      GetOfficeImages(officeId)
        .then(({ data }) => {
          const folders = withInfographicsSelection
            ? selectedInfographicsFolders
            : infographicsFolders;
          let folderImagesList = getImagesList(folders, data.fileMap);
          let allImagesList = getImagesList(infographicsFolders, data.fileMap);
          let alreadySelected = [];
          folderImagesList = folderImagesList?.map(item => {
            const sameElement = infographics.find(
              image => image.id === item.id,
            );
            if (sameElement) {
              return { ...item, title: sameElement.title };
            } else {
              return { ...item, title: item.name.split(".")[0] };
            }
          });

          allImagesList?.forEach(item => {
            const sameElement = infographics.find(
              image => image.id === item.id,
            );
            if (sameElement) {
              alreadySelected.push(sameElement);
            }
          });
          setSelectedList(alreadySelected);
          setImageList(folderImagesList);
        })
        .catch(err => {
          console.log(err);
          showToastMessage({
            severity: "error",
            summary: "Warning",
            detail: "Something went wrong. Try again later",
            life: 4000,
          });
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    officeId,
    infographics,
    setImageList,
    setSelectedList,
    show,
    selectedInfographicsFolders,
  ]);

  const isActive = id => {
    return selectedList.find(item => item.id === id);
  };

  return (
    <Modal open={show} onClose={onHide} title="Select infographic icons">
      {withInfographicsSelection && (
        <FolderSelection
          infographicsFolders={infographicsFolders}
          selectedInfographicsFolders={selectedInfographicsFolders}
          setSelectedinfographicsFolders={setSelectedinfographicsFolders}
        />
      )}
      <LinearProgress color="primary" className={cn({ "d-none": !loading })} />
      <div
        className={classes.imageContainer}
        style={{ opacity: loading ? 0.4 : 1 }}
      >
        {imageList.length === 0 && !loading && (
          <div className="m-auto">No images exist</div>
        )}
        {loading && <div className="m-auto">Loading...</div>}
        {!loading &&
          imageList.map(image => (
            <ImageCard
              key={image.id}
              url={image.thumbnailUrl}
              name={image.title}
              active={isActive(image.id)}
              onClick={() => onSelectImage(image)}
            />
          ))}
      </div>
      <ModalActions
        onCancel={onHide}
        onSubmit={() => onApply(selectedList)}
        submitTitle="Apply"
      />
    </Modal>
  );
};

export default InfographicsModal;
