import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const BigYellowButton = ({ children, to }) => (
  <button className="big-yellow-button">
    <Link to={to}>
      {children}
    </Link>
  </button>
);

export default BigYellowButton;
