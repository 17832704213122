import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";

import {
  getStorage,
  removeStorage,
} from "../../../_metronic/_helpers/LocalStorageHelpers";
import Button from "app/components/ui/Buttons/Button";
import { actions } from "../../modules/Auth/_redux/authRedux";

const useStyles = makeStyles(theme => ({
  topBar: {
    position: "sticky",
    display: "flex",
    justifyContent: "space-between",
    height: 45,
    fontSize: 14,
    backgroundColor: "#3c3c3d",
  },
  topBarLeft: {
    display: "flex",
    paddingLeft: 12,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    "& .header": {
      margin: "auto 12px",
      color: "#fdb818",
    },
    "& .button-back": {
      color: "#fff",
      "& .icon": {
        transform: "rotateY(180deg)",
      },
      "& svg": {
        transform: "rotate(90deg)",
      },
    },
  },
}));

const DashboardModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const ownAccountToken = getStorage("ownAccountToken");

  const returnOwnAccount = () => {
    dispatch(actions.setToken(ownAccountToken));
    dispatch(actions.changeUser());
    removeStorage("ownAccountToken");
    history.push(`/dashboard`);
  };

  return (
    <div className={classes.topBar}>
      <div className={classes.topBarLeft}>
        <Button
          icon="jumpUp"
          className="button-back"
          onClick={returnOwnAccount}
        >
          Return to your account
        </Button>
      </div>
    </div>
  );
};

export default DashboardModal;
