import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import { Article } from "app/interfaces";

import BlockContainer from "./BlockContainer";
import EditorComponent from "../../Common/EditorComponent";
import { AppraisalContext } from "../AppraisalContext";

import { updateArticleBody } from "redux/reducers";

import HideContainer from "../components/HideContainer";

interface ContextSectionData {
  isHiddenSection: boolean;
  title: string;
  subtitle?: string;
}
interface ContextArticleSection {
  id: string;
  sectionData: ContextSectionData;
  withoutSubTitle?: boolean;
  onUpdateSectionData(data: { [key: string]: any }): void;
  article?: Article;
  className?: string;
}

const ArticleSection = ({
  id,
  sectionData,
  withoutSubTitle,
  onUpdateSectionData,
  article,
  className,
}: ContextArticleSection): JSX.Element => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);

  const onUpdateSection = (key: string, value: any) => {
    dispatch(onUpdateSectionData({ [key]: value }));
  };

  const onArticleChange = (body: string) => {
    if (!article?._id) return;
    dispatch(updateArticleBody({ id: article._id, body }));
  };

  return (
    <HideContainer
      value={sectionData.isHiddenSection}
      onChange={(value: boolean) => onUpdateSection("isHiddenSection", value)}
    >
      <BlockContainer
        id={id}
        defaultTitle={sectionData.title}
        defaultSubtitle={sectionData.subtitle}
        onTitleUpdate={(value: string) => onUpdateSection("title", value)}
        onSubtitleUpdate={(value: string) => onUpdateSection("subtitle", value)}
        hide={sectionData.isHiddenSection}
        className={className}
        withoutSubTitle={withoutSubTitle}
      >
        <EditorComponent
          inline
          value={article?.body || ""}
          onBlur={onArticleChange}
          type={"coverletter"}
          disabled={preview}
          charactersLimit={1100}
        />
      </BlockContainer>
    </HideContainer>
  );
};

export default ArticleSection;
