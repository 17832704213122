import React, { useContext, useEffect, useState } from "react";
import { AppraisalContext } from "../../AppraisalContext";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";
import cn from "classnames";
import moment from "moment-timezone";
import { makeStyles, TextareaAutosize } from "@material-ui/core";
import IconButton from 'app/components/ui/Buttons/IconButton';

const useStyles = makeStyles((theme) => ({
  timeLineContent: {
    backgroundColor: "#F0F0F0",
    position: 'relative',
    borderRadius: "10px",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    fontSize: "14px",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
  },
  horizontalLine: {
    width: "10%",
    height: "1px",
    border: "2px solid rgba(60, 60, 61, 0.1)"
  },
  closeIcon: {
    position: "absolute",
    top: "7px",
    right: "7px",
    cursor: "pointer",
    transition: "opacity 0.1s ease-in-out",
    color: "rgba(60, 60, 61, 0.5)",
    width: 15,
    height: 15
  },
  trashBtn: {
    position: 'absolute',
    top: '7px',
    right: '7px',
  },
  textArea: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    textAlign: "center",
    fontSize: '16px',
    color: "#333",
  },
  dateTextArea: {
    color: theme?.palette?.primary.main,
    width: "85%"
  },
}));

const useTimeLineStyles = makeStyles((theme) => ({
  dot: {
    backgroundColor: theme?.palette?.primary.main,
    margin: 0,
    boxShadow: "none",
  },
  timeLineBlock: {
    alignItems: "center",
    display: "flex",
    flexDirection: "inherit",
  },
  timelineItem: {
    "&::before, .MuiTimelineItem-content": {
      padding: 0
    },
  },
  grabbing: {
    width: '400px',
    "& .MuiTimelineSeparator-root": {
      display: 'none'
    },
    "&:before": {
      display: 'none'
    },
    "& .MuiTimelineContent-root>span": {
      display: 'none'
    }
  }
}))

const TimeLineCover = ({ children, provided }) => {
  const classes = useTimeLineStyles();
  return (
    <TimelineItem
      className={cn(classes.timelineItem, {[classes.grabbing]: false})} // change it to isDragging to change styles on drag process
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot className={classes.dot} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent classes={{root: classes.timeLineBlock}}>
        {children}
      </TimelineContent>
    </TimelineItem>
  )
}

const converDate = (date) => {
  if(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).isValid()) {
    const utc = moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ", true);
    return moment(utc).format("dddd MMM Do");
  }
  return date;
}

const CalendarItem = props => {
  const { item, isDragging, onRemove, onUpdate, provided } = props
  const classes = useStyles();
  const { preview } = useContext(AppraisalContext);
  const [text, setText] = useState('');
  const [date, setDate] = useState();

  useEffect(() => {
    setText(item.text)
  }, [item.text, setText])

  useEffect(() => {
    const dateValue = converDate(item.date);
    setDate(dateValue);
  }, [item])

  const onDataUpdate = val => {
    onUpdate({ ...item, date: val })
  }

  const onTextBlur = () => {
    onUpdate({ ...item, text })
  }

  return (
    <TimeLineCover provided={provided} isDragging={isDragging}>
      <span className={classes.horizontalLine}/>
      <div className={classes.timeLineContent}>
        {preview ? '' :
          <IconButton
            icon="trash"
            variant="trash"
            className={classes.trashBtn}
            onClick={onRemove}
          />
        }
        <TextareaAutosize
          maxLength={30}
          rowsMax={1}
          className={cn(classes.textArea, classes.dateTextArea)}
          value={date}
          placeholder="eg. Day Month Date or Week1, Week2 etc."
          onChange={(e) => setDate(e.target.value)}
          onBlur={() => onDataUpdate(date)}
        />
        <TextareaAutosize
          maxLength={100}
          rowsMax={2}
          className={classes.textArea}
          value={text}
          onChange={e => setText(e.target.value)}
          onBlur={onTextBlur}
        />
      </div>
    </TimeLineCover>
  )
}

export default CalendarItem;
