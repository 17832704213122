import React from "react";
import { Box, withStyles } from "@material-ui/core";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";

import SearchResult from "./SearchResult";
import SelectedProps from "./SelectedProps";
import NoItems from "./NoItems";
import comparables from "app/assets/images/comparable-empty-icon.svg";
import comparablesSearch from "app/assets/images/comparable-empty-results.svg";

const HeaderText = withStyles(theme => ({
  root: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "21px",
    color: "#333",
    paddingLeft: theme?.spacing(3),
  },
}))(Box);

const useStyles = makeStyles(theme => ({
  selectedItems: {
    backgroundColor: theme.palette.background.gray3,
    height: "100%",
  },
  loadingOpacity: {
    opacity: 0.7,
  },
  itemContainer: {
    maxHeight: "calc(100vh - 410px)",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const PropertiesList = ({
  isRental,
  searchItems,
  totalItems,
  onAddItem,
  onDeleteItem,
  selectedItems,
  loadMore,
  showLoadMore,
}) => {
  const classes = useStyles();

  return (
    <div className="d-flex h-100">
      <div className="w-50 pt-3 position-relative">
        {!searchItems.length ? (
          <NoItems
            src={comparablesSearch}
            text={
              <>
                <p>No results found,</p>
                <p>please refine your search above and try again.</p>
              </>
            }
          />
        ) : (
          <>
            <HeaderText>
              {totalItems} comparable {isRental ? "leases" : "sales"} found
            </HeaderText>
            <div className={classes.itemContainer}>
              <SearchResult
                items={searchItems}
                addProperty={onAddItem}
                loadMore={loadMore}
                showLoadMoreButton={showLoadMore}
              />
            </div>
          </>
        )}
      </div>
      <div className={cn("w-50 pt-3 position-relative", classes.selectedItems)}>
        {!selectedItems.length ? (
          <NoItems
            src={comparables}
            text={
              <>
                <p>No comparable {isRental ? "leases" : "sales"} selected.</p>
                <p>
                  Search above to add your first from the result to the left.
                </p>
              </>
            }
          />
        ) : (
          <>
            <HeaderText>
              Selected comparable {isRental ? "leases" : "sales"}
            </HeaderText>
            <div className={classes.itemContainer}>
              <SelectedProps items={selectedItems} onDelete={onDeleteItem} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PropertiesList;
