import React from "react";
import { makeStyles } from "@material-ui/core";
import { Title } from "../../AppraisalDetails/BlockContainer";

const useStyles = makeStyles(() => ({
  imageComponent: {
    width: 120,
    textAlign: 'center',
    margin: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexBasis: "21%",
    alignItems: "center",
    "& img": {
      width: 'inherit',
    },
  },
  title: {
    color: '#666',
    textAlign: 'center',
    textTransform: 'capitalize',
    height: 38,
    lineHeight: '19px',
    fontSize: '15px',
  },
}));


const BuilderImageCard = ({ url, title, onUpdateTitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.imageComponent}>
      <img src={url} alt={title} />
      <Title
        value={title}
        onBlur={onUpdateTitle}
        className={classes.title}
        maxLength={50}
        rowsMax={3}
      />
    </div>
  )
}

export default BuilderImageCard;
