import React from "react";
import PropTypes from "prop-types";
import { svgIconsRegistry } from "./svgIconsRegistry";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '_metronic/_helpers';
import withTooltip from 'app/hocs/withTooltip';

const SvgIcon = ({ iconName, className }) => {
  const path = toAbsoluteUrl(svgIconsRegistry[iconName]);
  return (
    <SVG src={path} className={className} />
  );
};

SvgIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  className: '',
};

export default withTooltip(SvgIcon);
