import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { FieldBuilder } from "../FieldBuilder";
import { ImageBlock } from "../ImageBlock";

const DefaultTable = ({
  classes,
  isCommercial,
  property,
  setProperty,
  isAddressEmpty,
  isEditMode,
  type,
}) => {
  const onSetProperty = (name, value) =>
    setProperty({ ...property, [name]: value });

  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );

  return (
    <React.Fragment>
      <div className="d-flex">
        <div className={classes.imageBlock}>
          <Box width="100%">
            <ImageBlock
              classes={classes}
              image={property.image}
              setImage={value => onSetProperty("image", value)}
              id={property?.id}
              isEditMode={isEditMode}
            />
          </Box>
        </div>
        <div className={classes.addressBlockInWithImageBlock}>
          <div className={classes.addressBlock}>
            <FieldBuilder
              className={classes.inputContainer}
              label={"Address"}
              value={property.address}
              onChange={value => onSetProperty("address", value)}
              placeholder={"eg. 10 Street Name, Suburb State Postcode"}
              isRequired={isAddressEmpty && !property.address}
            />
          </div>
          <div className={classes.infoBlockInWithImageBlock}>
            {!isCommercial ? (
              <React.Fragment>
                <FieldBuilder
                  className={classes.inputContainer}
                  label={"Bed"}
                  value={property.bedrooms}
                  onChange={value => onSetProperty("bedrooms", value)}
                  placeholder={"0"}
                  type={"number"}
                  step={"1"}
                />
                <FieldBuilder
                  className={classes.inputContainer}
                  label={"Bath"}
                  value={property.bathrooms}
                  onChange={value => onSetProperty("bathrooms", value)}
                  placeholder={"0"}
                  type={"number"}
                  step={"1"}
                />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            <FieldBuilder
              className={classes.inputContainer}
              label={"Parking"}
              value={property.cars}
              onChange={value => onSetProperty("cars", value)}
              placeholder={"0"}
              type={"number"}
              step={"1"}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <FieldBuilder
            className={classes.inputContainer}
            label={"Price"}
            value={property.price}
            onChange={value => onSetProperty("price", value)}
            placeholder={"eg. $300,000"}
            isPreClick
          />
          <FieldBuilder
            className={classes.inputContainer}
            label={"Land Area"}
            value={property.landArea}
            onChange={value => onSetProperty("landArea", value)}
            placeholder={"eg. 100 sqm"}
          />
          {["On the Market", "Market Sales"].includes(type) && (
            <FieldBuilder
              className={classes.inputContainer}
              label={"Floor Area"}
              value={property.floorArea}
              onChange={value => onSetProperty("floorArea", value)}
              placeholder={"eg. 100 sqm"}
            />
          )}
        </div>
        <div style={{ display: "flex" }}>
          <FieldBuilder
            className={classes.inputContainer}
            label={`${category === "lease" ? "Lease" : "Sale"} Date`}
            value={property.saleDate}
            onChange={value => onSetProperty("saleDate", value)}
            placeholder={"eg. 31/01/2022"}
          />
          <FieldBuilder
            className={classes.inputContainer}
            label={"Year Built"}
            value={property.yearBuilt}
            onChange={value => onSetProperty("yearBuilt", value)}
            placeholder={"eg. 2022"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DefaultTable;
