import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SvgIcon from 'app/components/ui/SvgIcon';
import { DESCENDING, ASCENDING } from 'app/constants/commonConstants';
import cn from 'classnames';

import './styles.scss';

const sortData = (data, sortColumn, isDate) => {
  data.sort((a, b) => {
    const key = isDate ? 'timeStamp' : 'content';
    const first = a.mainData.find(item => item.sortLabel === sortColumn)[key];
    const second = b.mainData.find(item => item.sortLabel === sortColumn)[key];
    if (first.length >= 0) {
      return first === second ? 0 : first > second ? 1 : -1;
    } else {
      return first - second;
    }
  });
};

const GridLinksList = ({ content, columnsTitles, columnsTitlesDetached, hasLink, transparentTitles }) => {
  const [data, setData] = useState(content);
  const [sortDirection, setSortDirection] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [isDate, setIsDate] = useState(undefined);

  useEffect(() => {
    if (sortDirection !== null) {
      if (sortDirection === ASCENDING) {
        sortData(data, sortColumn, isDate);
      } else {
        data.reverse();
      }
      const [...sortedData] = data;
      setData(sortedData);
    } else {
      const [...data] = content;
      setData(data);
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection, sortColumn, content]);

  const sorting = (e) => {
    const targetColumn = e.currentTarget.dataset.label;
    const isDate = e.currentTarget.dataset.isdate;
    setSortColumn(targetColumn);
    isDate ? setIsDate(true) : setIsDate(false);

    if (targetColumn === sortColumn) {
      let sortBy;
      if (sortDirection) {
        sortBy = sortDirection === ASCENDING ? DESCENDING : ASCENDING;
      } else {
        sortBy = ASCENDING;
      }
      setSortDirection(sortBy);
    } else {
      setSortDirection(ASCENDING);
    }
  };

  const ItemWrapper = hasLink ? Link : 'div';

  return (
    <div className="grid-list">
      {columnsTitles &&
      <div className={
        cn('titles-row layout-side-padding', { 'detached': columnsTitlesDetached, 'transparent': transparentTitles })}>
        {columnsTitles.map((item, index) => (
          <div
            key={index}
            className={cn('item', { [item.className]: item.className }, { 'cursor-pointer': item.sortLabel })}
            data-label={item.sortLabel}
            data-isdate={item.isDate}
            onClick={item.sortLabel && sorting}
            style={item.style && item.style}
          >
            <div className="title">
              {item.title}
              {sortDirection && item.sortLabel === sortColumn &&
              <SvgIcon
                iconName="thinArrow"
                className={cn('sort-icon', { [DESCENDING]: sortDirection === DESCENDING })}
              />
              }
            </div>
          </div>
        ))}
      </div>
      }
      {data.map(item => (
        <div
          key={item.id}
          className="item-row layout-side-padding"
        >
          <ItemWrapper
            to={item.link}
            className="item-wrapper"
          >
            {item.mainData.map((item, index) => (
              <div key={index} className={cn('item', { [item.className]: item.className })}>
                <div className="item-inner">
                  {item.content}
                </div>
              </div>
            ))}
          </ItemWrapper>
          {item.rowTail &&
            <div className={cn('item item__tail', { [item.rowTailClassName]: item.rowTailClassName })}>
              {item.rowTail}
            </div>
          }
        </div>
      ))}
    </div>
  );
};

GridLinksList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string,
      mainData: PropTypes.array.isRequired,
      rowTail: PropTypes.element,
      rowTailClassName: PropTypes.string,
    }),
  ),
  columnsTitles: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    className: PropTypes.string,
    sortLabel: PropTypes.string,
    style: PropTypes.object,
  })),
  columnsTitlesDetached: PropTypes.bool,
  hasLink: PropTypes.bool,
};

GridLinksList.defaultProps = {
  content: {
    link: '#',
    rowTail: null,
    rowTailClassName: '',
  },
  columnsTitles: null,
  columnsTitlesDetached: false,
  hasLink: true,
};

export default GridLinksList;
