import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Packs from "./Packs";
import HideContainer from "../../components/HideContainer";
import { updateMarketing } from "redux/reducers";
import BlockContainer, { Title } from "../BlockContainer";
import MarketingTypes from "./MarketingTypes";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { makeStyles, TextareaAutosize } from "@material-ui/core";
import useTrackEvent from "../../../../hooks/useTrackEvent";
import { AppraisalContext } from "../../AppraisalContext";
import cn from "classnames";

const useStyles = makeStyles(theme => ({
  corporateProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(60, 60, 61, 0.5) !important",
    textDecoration: "underline !important",
    fontSize: "16px",
    cursor: "Pointer",
    "&:hover": {
      textDecoration: "underline !important",
      backgroundColor: "white",
      color: "rgba(60, 60, 61, 0.5)",
    },
  },
  corporateProfileIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    paddingRight: "0.3em",
    fontSize: "3em",
  },
  textAreaBase: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    "&:disabled, &:focus": {
      opacity: 1 + " !important",
    },
    "&:hover": {
      opacity: 0.6,
    },
    color: "#333",
    fontSize: "15px",
    lineHeight: "21px",
    textAlign: "center",
    marginBottom: 25,
  },
  separator: {
    width: "100%",
    height: "1px",
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    paddingTop: "48px",
  },
}));

const Marketing = ({ isCommercial }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const trackEvent = useTrackEvent();
  const marketing = useSelector(
    ({ appraisal }) => appraisal.marketing,
    shallowEqual,
  );
  const description = useSelector(
    ({ appraisal }) => appraisal.marketing.description,
    shallowEqual,
  );
  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);

  const { preview } = useContext(AppraisalContext);

  const updateProp = (prop, val) => {
    dispatch(updateMarketing({ [prop]: val }));
  };

  const updateDescription = ({ target }) => {
    updateProp("description", target.value);
  };

  return (
    <div>
      <HideContainer
        value={marketing.hidden}
        onChange={val => updateProp("hidden", val)}
      >
        <BlockContainer
          id="marketing"
          defaultTitle={marketing.title}
          defaultSubtitle={marketing.subtitle}
          withoutSubTitle={isCommercial}
          onTitleUpdate={val => updateProp("title", val)}
          onSubtitleUpdate={val => updateProp("subtitle", val)}
          separator={false}
          hide={marketing.hidden}
        >
          <MarketingTypes isCommercial={isCommercial}/>
          {team.marketingBrochureLink && (
            <a
              className={classes.corporateProfile}
              href={team.marketingBrochureLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackEvent("Marketing Section", "Marketing Brochure Link")
              }
            >
              <AssignmentIndIcon
                fontSize={"large"}
                className={classes.corporateProfileIcon}
              />
              View Marketing Brochure
            </a>
          )}
        </BlockContainer>
        <div className={classes.separator} />
      </HideContainer>
      <HideContainer
        value={marketing.packsHidden}
        onChange={val => updateProp("packsHidden", val)}
      >
        <Title
          value={marketing.packsTitle}
          onBlur={val => updateProp("packsTitle", val)}
          className={cn(
            "mt-6 mb-12",
            marketing.hidden ? "common-title" : "common-subTitle",
            isCommercial ? "common-commercial-title" : "",
          )}
          maxLength={30}
        />
        <TextareaAutosize
          value={description}
          disabled={preview}
          onChange={updateDescription}
          className={classes.textAreaBase}
        />
        {marketing.packsHidden ? "" : <Packs />}
      </HideContainer>
    </div>
  );
};

export default Marketing;
