import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  Table,
  TableBody,
  TableFooter,
  TableRow,
} from "../../../../../Common/DraggableTable";
import { makeStyles } from "@material-ui/core";
import produce from "immer";
import {
  ExtraFooterContent,
  FooterContent,
  SortableTableItem,
  TableHeaderComponent,
  CostSavingsContent,
} from "./components";

const useStyles = makeStyles(theme => ({
  priceCol: {
    width: 150,
    textAlign: "right",
    lineHeight: "30px",
    [theme.breakpoints.down("sm")]: {
      width: 135,
    },
    "&:last-child": {
      width: 170,
      [theme.breakpoints.down("sm")]: {
        width: 135,
      },
    },
  },
}));

const getTotal = items => {
  if (!items) return "";
  const parseValue = val =>
    !isNaN(parseFloat(val.amount)) ? parseFloat(val.amount) : 0;
  const total = items.reduce(
    (prevVal, currentVal) => prevVal + parseValue(currentVal),
    0,
  );
  return total > 0 ? total : "";
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const PackTable = ({
  items = [],
  onItemsUpdate,
  onDelete,
  onAddExtraClick,
  onTotalUpdate,
  total,
  isExtra = false,
  onUpdateCostSavings,
  costSavings,
}) => {
  const classes = useStyles();
  const tabCellStyle = classes.priceCol;
  const subTotal = total || getTotal(items);
  const showAmountColumns =
    items.reduce((prevVal, { amount }) => prevVal + (amount || 0), 0) !== 0;
  // const showAmountColumns = true

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    onItemsUpdate(newItems);
  };

  const addCustomItem = () => {
    const newItem = { text: "New Item", amount: 100 };
    onItemsUpdate([...items, newItem]);
  };

  const addCostSavings = () => {
    onUpdateCostSavings("Cost Savings");
  };

  const onUpdateItem = (newVal, index) => {
    let newItems = produce(items, draftItems => {
      draftItems[index] = newVal;
    });
    onItemsUpdate(newItems);
  };

  return (
    <Table>
      <TableHeaderComponent
        showAmountColumns={showAmountColumns}
        tabCellStyle={tabCellStyle}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <SortableTableItem
                  key={index}
                  item={item}
                  index={index}
                  onUpdate={newVal => onUpdateItem(newVal, index)}
                  onDelete={() => onDelete(item, index)}
                  showAmountColumns={showAmountColumns}
                />
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
      <TableFooter>
        <TableRow>
          {isExtra ? (
            <ExtraFooterContent addExtraItem={addCustomItem} />
          ) : (
            <FooterContent
              onAddExtraClick={onAddExtraClick}
              total={subTotal}
              onTotalUpdate={onTotalUpdate}
              addCustomItem={addCustomItem}
              tabCellStyle={tabCellStyle}
              addCostSavings={addCostSavings}
              isCostSavings={costSavings ? true : false}
            />
          )}
        </TableRow>
        {costSavings && (
          <CostSavingsContent
            costSavings={costSavings}
            onUpdateCostSavings={onUpdateCostSavings}
          />
        )}
      </TableFooter>
    </Table>
  );
};

export default React.memo(PackTable);
