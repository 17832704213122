import React from 'react';
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

const DeleteConformationModal = ({show, onClose, onSubmit, name, tab}) => {
  const headerText = tab ? 'Delete tab?' : 'Delete marketing item?'
  const bodyText = tab
    ? `Are you sure that you want to delete tab "${name}?"`
    : `Item "${name}" would be removed from the list of selected items`;
  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby={"delete-marketing-dialog-title" + (tab && "tab")}
      aria-describedby={"delete-marketing-dialog-description"+ (tab && "tab")}
    >
      <DialogTitle id="delete-marketing-dialog-title">{headerText}</DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Disagree
        </Button>
        <Button onClick={onSubmit} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteConformationModal.prototype = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.text,
  tab: PropTypes.bool,
}

export default DeleteConformationModal;
