import React from "react";
import BlockContainer from "./BlockContainer";
import AgentBio from "./TeamDetails/AgentBio";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { APPRAISAL_TYPES } from "app/constants/appraisalsConstants";
import { updateCommercialField, updateTeam } from "../../../../redux/reducers";
import { Box, useMediaQuery } from "@material-ui/core";
import HideContainer from "../components/HideContainer";
import Separator from "../CommonComponents/Separator";
import Testimonials from "./Testimonials";

// TODO
const NetworkManager = ({
  withSeparator = false,
  withTestimonials = false,
}) => {
  const dispatch = useDispatch();
  const team = useSelector(({ appraisal }) => appraisal.team, shallowEqual);
  const commercialAgentDetails = useSelector(
    ({ appraisal }) => appraisal.commercial.agentDetails,
    shallowEqual,
  );
  const appraisalType = useSelector(
    ({ appraisal }) => appraisal.meta.appraisalType,
    shallowEqual,
  );
  const isCommercial = appraisalType === APPRAISAL_TYPES.informationMemorandum;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const onTitleUpdate = title => {
    if (isCommercial) {
      const data = { ...commercialAgentDetails, title };
      dispatch(updateCommercialField({ data, name: "agentDetails" }));
    } else {
      dispatch(updateTeam({ leaderName: title }));
    }
  };

  const onSubtitleUpdate = subtitle => {
    dispatch(updateTeam({ leaderNameSubtitle: subtitle }));
  };

  const hideCommercialHandler = hidden => {
    const data = { ...commercialAgentDetails, hidden };
    dispatch(updateCommercialField({ data, name: "agentDetails" }));
  };

  const blockTitle = isCommercial
    ? commercialAgentDetails?.title
    : team.leaderName;

  return (
    <HideContainer
      value={commercialAgentDetails.hidden}
      onChange={hideCommercialHandler}
    >
      <BlockContainer
        defaultTitle={blockTitle}
        defaultSubtitle={team.leaderNameSubtitle}
        id="network-manager"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        separator={isCommercial}
        hide={team.sectionHidden}
        withoutSubTitle={isCommercial}
      >
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection={isCommercial && !isMobile ? "row" : "column"}
        >
          <div>
            <AgentBio
              currentRentals={team.currentRentals}
              biography={team.biography}
              profilePortrait={team.profilePortrait}
              leaderName={team.leaderName}
              profileLink={"https://raineandhorne.com.au/team"}
              profileLinkText={"View your support team"}
              recentSales={team.recentSales}
              phone={team.phone}
              email={team.email}
              officeName={team.officeName}
            />
          </div>
        </Box>
      </BlockContainer>

      {withTestimonials ? <Testimonials /> : <React.Fragment />}
      {withSeparator ? <Separator space={3} /> : <></>}
    </HideContainer>
  );
};

export default NetworkManager;
