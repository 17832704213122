import React from "react";
import { Checkbox } from "primereact/checkbox";

const FolderSelection = ({
  infographicsFolders,
  selectedInfographicsFolders,
  setSelectedinfographicsFolders,
}) => (
  <div className="form-group m-5">
    <div className="form-group">
      <label className="col-form-label">Please select a folder</label>
      <div>
        {infographicsFolders.length > 0 &&
          infographicsFolders.map((key, index) => {
            return (
              <div className="field-checkbox">
                <Checkbox
                  inputId={`infographic-${index}`}
                  value={key}
                  onChange={e => setSelectedinfographicsFolders([e.target.value])}
                  checked={selectedInfographicsFolders.includes(key)}
                ></Checkbox>
                <label
                  htmlFor={`infographic-${index}`}
                  className="p-checkbox-label"
                >
                  {key}
                </label>
                <br />
              </div>
            );
          })
        }
      </div>
    </div>
  </div>
);

export default FolderSelection;
