import React from "react";
import SVG from "react-inlinesvg";
import { Avatar, makeStyles } from "@material-ui/core";
import { getImageUrl } from "shared";
import EmailIcon from "app/assets/images/EmailIcon.svg";
import facebookSvg from "app/assets/images/facebook.svg";
import linkedinSvg from "app/assets/images/linkedin.svg";
import instagramSvg from "app/assets/images/instagram.svg";
import twitterSvg from "app/assets/images/twitter.svg";
import { useCommercial } from "app/hooks";

const SocialLink = ({ svg, link, className }) => {
  if (!link) return '';
  return (
    <a className={className} href={link} target="_blank" rel="noopener noreferrer">
      <SVG src={svg} />
    </a>
  )
}


const useStyles = makeStyles(theme => ({
  avatar: () => ({
    width: 120,
    height: 120,
    marginBottom: "10px",
  }),
  leaderInfo: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15,
      textAlign: 'left',
    }
  },
  leaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "row",
    }
  },
  leaderName: {
    fontSize: "16px",
    fontWeight: 600,
  },
  agentInfo: ({ isCommercial }) => ({
    color: isCommercial ? "#fff" : "#3C3C3D",
    fontSize: "15px",
    '&:hover': {
      color: isCommercial && '#FAD201',
    },
  }),
  socialLink: ({ isCommercial }) => ({
    margin: '0 12px',
    "& svg": {
      width: "15px",
      height: "15px",
      fill: isCommercial ? "#fff" : "#3C3C3D",
      "& path": {
        fill: isCommercial ? "#fff" : "#3C3C3D",
      }
    }
  }),
}))

const LeaderInfo = ({ profilePortrait, phone, email, leaderName, facebook, linkedIn, instagram, twitter, squeeze }) => {
  const { isCommercial } = useCommercial();

  const classes = useStyles({ squeeze, isCommercial })
  return (
    <>
      <div className={classes.leaderContainer}>
        {isCommercial ? "" : (
          <Avatar
            classes={{ root: classes.avatar }}
            src={getImageUrl(profilePortrait)}
            alt="profile portrait"
          />
        )}
        <div className={classes.leaderInfo}>
          <div className={classes.leaderName}>
            {leaderName}
          </div>
          <a className={classes.agentInfo} href={'tel:'+phone}>{phone}</a>
        </div>
      </div>
      <div className="d-flex justify-content-center my-2">
        <SocialLink
          className={classes.socialLink}
          link={email && `mailto:${email}`}
          svg={EmailIcon}
        />
        <SocialLink
          className={classes.socialLink}
          link={facebook}
          svg={facebookSvg}
        />
        <SocialLink
          className={classes.socialLink}
          link={linkedIn}
          svg={linkedinSvg}
        />
        <SocialLink
          className={classes.socialLink}
          link={instagram}
          svg={instagramSvg}
        />
        <SocialLink
          className={classes.socialLink}
          link={twitter}
          svg={twitterSvg}
        />
      </div>
    </>
  )
}

export default LeaderInfo;
