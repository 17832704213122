import React, { useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import ampersandIcon from "../../../assets/images/Ampersand.svg";
import EditorComponent from "../../Common/EditorComponent";
import { updateVendor } from "redux/reducers";
import HideContainer from "../components/HideContainer";
import { AppraisalContext } from "../AppraisalContext";
import Separator from "../CommonComponents/Separator";
import { ProposalTypes } from "app/interfaces";

const useStyles = makeStyles(theme => ({
  address: {
    fontSize: "48px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textAlign: "center",
    lineHeight: "55px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      lineHeight: "35px !important",
    },
  },
  iconAndBorder: {
    display: "flex",
    margin: "20px -15px",
    marginBottom: theme.spacing(3),
  },
  horizontalBorder: {
    position: "relative",
    margin: "15px",
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    width: "100%",
  },
  editor: {
    margin: "25px auto",
  },
  coverLetterBlock: {
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
}));

const CoverLetter = ({ withoutSeparator = false, withoutTopTitle = false }) => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const vendor = useSelector(state => state.appraisal.vendor, shallowEqual);
  const appraisalType = useSelector(
    state => state.appraisal.meta.appraisalType,
    shallowEqual,
  );
  const address = useSelector(
    state => state.appraisal.property.displayAddress,
    shallowEqual,
  );
  const suburb = useSelector(
    state => state.appraisal.property.address?.suburb,
    shallowEqual,
  );
  const propertyAddress = useSelector(
    state => state.appraisal.property.address,
    shallowEqual,
  );
  const buildingName = useSelector(
    state => state.appraisal.property.buildingName,
    shallowEqual,
  );

  const classes = useStyles();
  const addressLines = address?.split(",");

  const AppraisalAddress = () => {
    switch (appraisalType) {
      case ProposalTypes.businessListingProposal:
        return (
          <Typography component="h2" className={classes.address}>
            {propertyAddress.street}
          </Typography>
        );
      case ProposalTypes.marketUpdate:
        return suburb ? (
          <Typography component="h2" className={classes.address}>
            {`${suburb.name} ${suburb.state.abbreviation} ${suburb.postcode}`}
          </Typography>
        ) : null;
      case ProposalTypes.prospecting:
        return suburb ? (
          <Typography component="h2" className={classes.address}>
            {`${suburb.name} ${suburb.postcode}, ${suburb.state.abbreviation}`}
          </Typography>
        ) : null;
      case ProposalTypes.residentialInformationMemorandum:
      case ProposalTypes.informationMemorandum:
      case ProposalTypes.listingProposal:
      case ProposalTypes.preAppraisal:
      case ProposalTypes.commercialListingProposal:
        return (
          <>
            {buildingName && (
              <Typography component="h2" className={classes.address}>
                {buildingName},
              </Typography>
            )}
            {addressLines?.map((addressLine, index) => (
              <Typography
                key={index}
                component="h2"
                className={classes.address}
              >
                {addressLine}
                {index !== addressLines?.length - 1 && ","}
              </Typography>
            ))}
          </>
        );
      default:
        return addressLines?.map((addressLine, index) => (
          <Typography key={index} component="h2" className={classes.address}>
            {addressLine}
            {index !== addressLines?.length - 1 && ","}
          </Typography>
        ));
    }
  };

  return (
    <div id="cover-letter" className={classes.coverLetterBlock}>
      {!withoutTopTitle ? (
        <React.Fragment>
          <div className="my-9">
            <AppraisalAddress />
          </div>
          <div className={classes.iconAndBorder}>
            <div className={classes.horizontalBorder} />
            <img src={ampersandIcon} alt="ampersand" />
            <div className={classes.horizontalBorder} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <HideContainer
        className={classes.editor}
        value={vendor.coverLetterHidden}
        onChange={() =>
          dispatch(
            updateVendor({ coverLetterHidden: !vendor.coverLetterHidden }),
          )
        }
      >
        <EditorComponent
          inline
          value={vendor.coverLetter}
          onBlur={value => dispatch(updateVendor({ coverLetter: value }))}
          type={"coverletter"}
          disabled={preview}
          charactersLimit={1100}
        />
        {withoutSeparator ? "" : <Separator space={3} />}
      </HideContainer>
    </div>
  );
};

export default CoverLetter;
