import { createAction } from "@reduxjs/toolkit";
import { createProposalSlice } from "./reduxSlice";

export const {
  resetState,
  setStep,
  setOffice,
  setType,
  setProfile,
  setProperty,
  setSuburb,
} = createProposalSlice.actions;

export const generateBuilderData = createAction(
  "createProposal/generateBuilderData",
);
