import React from "react";
import HideContainer from "../HideContainer";
import BlockContainer from "../../components/BlockContainer";
import Tabs from "./Tabs";
import { makeStyles } from "@material-ui/core";
import { TabsContextProps } from "app/interfaces";
import { ReactNode } from "react";

interface ContextProps {
  id: string;
  title?: string;
  subtitle?: string;
  isHiddenSection: boolean;
  onChangeHiddenSection(val?: boolean): void;
  onTitleUpdate(val?: string): void;
  onSubtitleUpdate(val?: string): void;
  tabsContent: Array<TabsContextProps>;
  separator?: boolean;
  children?: ReactNode;
}

const useStyles = makeStyles(theme => ({
  corporateProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(60, 60, 61, 0.5) !important",
    textDecoration: "underline !important",
    fontSize: "16px",
    cursor: "Pointer",
    "&:hover": {
      textDecoration: "underline !important",
      backgroundColor: "white",
      color: "rgba(60, 60, 61, 0.5)",
    },
  },
  corporateProfileIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
    paddingRight: "0.3em",
    fontSize: "3em",
  },
  textAreaBase: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    "&:disabled, &:focus": {
      opacity: 1 + " !important",
    },
    "&:hover": {
      opacity: 0.6,
    },
    color: "#333",
    fontSize: "15px",
    lineHeight: "21px",
    textAlign: "center",
    marginBottom: 25,
  },
  separator: {
    width: "100%",
    height: "1px",
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    paddingTop: "48px",
  },
}));

const TabsBlock = ({
  id,
  title,
  subtitle,
  isHiddenSection,
  onChangeHiddenSection,
  onTitleUpdate,
  onSubtitleUpdate,
  separator = false,
  tabsContent = [],
  children = null,
}: ContextProps) => {
  const classes = useStyles();

  return (
    <div>
      <HideContainer value={isHiddenSection} onChange={onChangeHiddenSection}>
        <BlockContainer
          id={id}
          defaultTitle={title}
          defaultSubtitle={subtitle}
          onTitleUpdate={onTitleUpdate}
          onSubtitleUpdate={onSubtitleUpdate}
          separator={separator}
          hide={isHiddenSection}
        >
          {tabsContent.length ? (
            <Tabs tabsContent={tabsContent} />
          ) : (
            <></>
          )}
        </BlockContainer>
        {children && children}
        <div className={classes.separator} />
      </HideContainer>
    </div>
  );
};

export default TabsBlock;
