import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { chunk } from "lodash";
import {
  makeStyles,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Carousel from "app/components/Carousel";
import SVG from "react-inlinesvg";

import HideContainer from "../components/HideContainer";
import { updateTestimonials } from "redux/reducers/appraisalReducer";
import openBacktick from "../../../assets/images/openBacktick.svg";
import closeBacktick from "../../../assets/images/closeBacktick.svg";
import { fade } from "@material-ui/core/styles/colorManipulator";
import TeamsService from "../../../api/TeamsService";
import { useCommercial } from "app/hooks";

const useStyles = makeStyles(theme => ({
  testimonialCard: {
    borderRadius: 0,
    height: "100%",
    borderColor: theme.palette.divider,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  testimonialContent: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 100%",
    "& svg": {
      width: "15%",
      fill: fade(theme.palette.primary.main, 0.4),
    },
    "& h5": {
      width: "70%",
      color: "#333",
    },
  },
  author: {
    margin: "1em 0",
    fontSize: "1.5em",
    color: theme.palette.primary.main,
  },
  title: {
    margin: "20px 0",
  },
}));

const TestimonialItem = ({ data }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.testimonialCard}>
      <div className={classes.testimonialContent}>
        <SVG src={openBacktick} />
        <h5>{data.quote}</h5>
        <SVG src={closeBacktick} />
      </div>
      <Typography
        component="p"
        align="center"
        variant="subtitle2"
        gutterBottom
        className={classes.author}
      >
        {data.name}
      </Typography>
    </Card>
  );
};

const CarouselItem = ({ item }) => {
  return (
    <Grid container spacing={3}>
      {item.map((data, index) => (
        <Grid item sm={6} key={index} className="w-100">
          <TestimonialItem data={data} />
        </Grid>
      ))}
    </Grid>
  );
};

const Testimonials = () => {
  const { isCommercial } = useCommercial();
  const dispatch = useDispatch();

  const appraisalTestimonials = useSelector(
    ({ appraisal }) => appraisal.testimonials,
    shallowEqual,
  );
  const [chunkTestimonials, setChunkTestimonials] = useState([]);
  const teamId = useSelector(
    ({ appraisal }) => appraisal.meta.team,
    shallowEqual,
  );
  const classes = useStyles();

  useEffect(() => {
    TeamsService.getTeam(teamId).then(({ data }) => {
      if (data.testimonials) {
        onTestimonialsUpdate(data.testimonials.items, "items");
      }
    });

    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    setChunkTestimonials(
      chunk(appraisalTestimonials?.items || [], isMobile ? 1 : 2),
    );
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appraisalTestimonials]);

  const onTestimonialsUpdate = (val, field) => {
    dispatch(updateTestimonials({ [field]: val }));
  };
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  return (
    <HideContainer
      value={appraisalTestimonials.hidden}
      onChange={val => onTestimonialsUpdate(val, "hidden")}
    >
      {!!appraisalTestimonials.items.length && (
        <Typography
          component="p"
          variant="subtitle1"
          align="center"
          className={`${classes.title} common-subTitle ${
            isCommercial ? "common-commercial-title" : ""
          }`}
        >
          {appraisalTestimonials.title}
        </Typography>
      )}
      {appraisalTestimonials.hidden ? (
        ""
      ) : (
        <Carousel
          autoPlay
          infiniteLoop
          interval={5000}
          showStatus={false}
          showThumbs={false}
          centerMode={true}
          centerSlidePercentage={100}
        >
          {chunkTestimonials.map((item, index) => (
            <CarouselItem item={item} key={index} />
          ))}
        </Carousel>
      )}
    </HideContainer>
  );
};

export default Testimonials;
