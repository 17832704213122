import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Modal, ModalActions } from "app/components/Modal";
import { InputText } from "primereact/inputtext";

const UrlModal = ({ show, onHide, value, onChange }) => {
  const [ url, setUrl ] = useState('');
  useEffect(() => {
    setUrl(value);
  }, [value, setUrl])

  const saveUrl = () => onChange(url);

  return (
    <Modal open={show} onClose={onHide} maxWidth="sm" fullWidth>
      <Box m={2}>
        <Typography component="h5" variant="h5">
          Add your attachment link below
        </Typography>
        <Typography component="h6" variant="h6" className="mt-10">
          To make the document accessible to the client you will need to upload it in C+ &gt; eMarketing storage &gt; a link will generate for the document &gt; copy inside of C+ and paste below in DigikitPlus
        </Typography>
        <InputText
          type="text"
          value={url}
          placeholder="https://www.websiteurl.com"
          className="w-100"
          onChange={e => setUrl(e.target.value)}
        />
      </Box>

      <ModalActions
        onCancel={onHide}
        onSubmit={saveUrl}
        submitTitle='Save'
      />
    </Modal>
  )
}

export default UrlModal;
