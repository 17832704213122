import { ProposalTypes } from "app/interfaces";

export const articles = {
  teamDetails: {
    sales: ["awards", "our-difference", "office-profile", "corporate-profile", "community", "history"],
    lease: ["awards", "our-difference", "office-profile", "corporate-profile", "community", "history"]
  },
  nextStepsSection: {
    sales: ["preparing-your-home", "faqs", "looking-for-your-next-property", "the-sales-process"],
    lease: ["considering-another-investment-property", "making-your-property-stand-out", "faqs"]
  },
  marketing: {
    sales: ["social-media", "digital", "local", "visuals", "database", "print" ],
    lease: ["social-media", "digital", "local", "visuals", "database", "print" ],
  },
  methodsOfSale: {
    sales: ['auction', 'off-market', 'expression-of-interest', 'tender', 'private-treaty'],
    lease: ['auction', 'off-market', 'expression-of-interest', 'tender', 'private-treaty'],
  },
  ourServices: {
    sales: [],
    lease: ['maximising-your-return', 'tenancy-selection', 'ongoing-service', 'your-property-journey'],
  },
  opinion: {
    sales: ["price-estimate"],
    lease: ["estimated-rental-return"]
  },
  nextSteps: {
    sales: ["next-steps---proposal", "next-steps---prelist", "next-steps"],
    lease: ["next-steps"],
  },
  fee: {
    sales: ["service-fees", "agency-fees"],
    lease: ["fees"],
  },
  ourBroker: {
    sales: ["our-broker"],
    lease: ["our-broker"],
  },
  businessSupport: {
    sales: ["training", "property-management", "marketing--comms", "it", "our-broker"],
    lease: ["training", "property-management", "marketing--comms", "it", "our-broker"],
  },
  growBusiness: {
    sales: ["compassplus", "designplus", "canva", "amplify", "digikitplus", "processplus"],
    lease: ["compassplus", "designplus", "canva", "amplify", "digikitplus", "processplus"],
  },
  chooseUs: {
    sales: ["national--internation-reach", "history", "performance-matrix", "rewards-culture--recognition", "our-mission"],
    lease: ["national--internation-reach", "history", "performance-matrix", "rewards-culture--recognition", "our-mission"],
  },
  common: {
    sales: ["why-choose-us", "recruitment", "franchise-fee-structure", "value-of-your-business", "our-values"],
    lease: ["why-choose-us", "recruitment", "franchise-fee-structure", "value-of-your-business", "our-values"],
  },
  tenant: {
    sales: ['office-information', 'rental-payments', 'during-your-tenancy', 'routine-inspections', 'property-maintenance', 'vacating-the-property'],
    lease: ['office-information', 'rental-payments', 'during-your-tenancy', 'routine-inspections', 'property-maintenance', 'vacating-the-property'],
  },
}

export const getAllArticles = type => {
  return [].concat(
    articles.teamDetails[type],
    articles.nextStepsSection[type],
    articles.marketing[type],
    articles.opinion[type],
    articles.nextSteps[type],
    articles.fee[type],
    articles.methodsOfSale[type],
    articles.ourServices[type],
    articles.ourBroker[type],
    articles.businessSupport[type],
    articles.growBusiness[type],
    articles.chooseUs[type],
    articles.common[type],
  )
}

export const articlesByTypes = {
  [ProposalTypes.commercialListingProposal]: {
    methodsOfSale: {
      sales: ['auction', 'off-market', 'expression-of-interest', 'tender', 'private-treaty'],
      lease: ['auction', 'off-market', 'expression-of-interest', 'tender', 'private-treaty'],
    },
    teamDetails: {
      sales: ["awards", "our-difference", "corporate-profile", "community", "history"],
      lease: ["awards", "our-difference", "corporate-profile", "community", "history"]
    },
    marketing: {
      sales: ["social-media", "digital", "local", "visuals", "database", "print" ],
      lease: ["social-media", "digital", "local", "visuals", "database", "print" ],
    },
    common: {
      sales: ["executive-summary", "disclaimer", "target-market"],
      lease: ["executive-summary", "disclaimer", "target-market"],
    },
    fee: {
      sales: ["agency-fees"],
      lease: ["fees"],
    },
    opinion: {
      sales: ["price-estimate"],
      lease: ["estimated-rental-return"]
    },
  },
  [ProposalTypes.tenantHandbook]: {
    tenant: {
      sales: ['office-information', 'tenant-responsibilities', 'rental-payments', 'during-your-tenancy', 'routine-inspections', 'property-maintenance', 'vacating-the-property'],
      lease: ['office-information', 'tenant-responsibilities', 'rental-payments', 'during-your-tenancy', 'routine-inspections', 'property-maintenance', 'vacating-the-property'],
    },
  }
}

export const getAllArticlesByTypes = (proposalType, type = "sales") => {
  const articlesByProposalType = articlesByTypes[proposalType];
  let result = [];
  Object.keys(articlesByProposalType).forEach(key => {
    result = result.concat(articlesByProposalType[key][type])
  })
  return result;
}
