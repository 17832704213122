import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { getImageUrl } from "shared";
import ProfileButton from "./ProfileButton";
import { useCommercial } from "app/hooks";
import { Title } from "../BlockContainer";
import { updateTeam } from "../../../../../redux/reducers";

const useStyles = makeStyles(theme => ({
  agentPhoto: ({ isCommercial, withoutCommercialStyle }) => ({
    float: isCommercial && !withoutCommercialStyle ? "none" : "right",
    maxWidth: "300px",
    margin:
      isCommercial && !withoutCommercialStyle ? "0 auto" : "0 0 25px 25px",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      float: "none",
      maxWidth: "100%",
      width: "100%",
    },
  }),
  articleContent: {
    display: "inline",
    fontSize: "16px",
  },
  agentInfo: {
    minHeight: "325px",
  },
  profileLinks: {
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gridGap: "20px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  nameTitle: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 600,
    lineHeight: "26px",
    marginTop: "15px",
  },
  commercialInfoContainer: {
    minHeight: 100,
    "& a": {
      color: "#333",
      fontSize: 15,
      lineHeight: "21px",
    },
    "& a:hover": {
      color: "#777",
    },
  },
}));

const SocialLink = ({ type, value, title }) => {
  if (!value) return "";
  let href = value; // default is link type
  if (type === "email") href = `mailto:${value}`;
  if (type === "phone") href = `tel:${value}`;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="mb-2">
      <strong>{title}</strong>
      {value}
    </a>
  );
};

const AgentBio = ({
  profilePortrait,
  leaderName,
  biography,
  profileLink,
  profileLinkText = "See My Profile",
  recentSales,
  currentRentals,
  phone,
  email,
  officeName,
  className,
  withoutCommercialStyle = false,
  secondAgent = false,
}) => {
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { isCommercial } = useCommercial();
  const classes = useStyles({ isCommercial, withoutCommercialStyle });
  const recentSalesTitle = isCommercial
    ? "View Recent Transactions"
    : "View Recent Sales";

  const CommercialInfo = () => {
    if (!isCommercial || withoutCommercialStyle) return "";
    return (
      <div className={classes.commercialInfoContainer}>
        {/*<div className={classes.nameTitle}>{leaderName}</div>*/}
        <Title
          value={leaderName}
          className={classes.nameTitle}
          maxLength={50}
          onBlur={updateTeamLeader}
        >
          {" "}
        </Title>
        <Box display="flex" flexDirection="column" alignItems="center">
          {/*<p className="mt-3 mb-2">{officeName}</p>*/}
          <Title
            value={officeName}
            className="mt-3 mb-2 text-center"
            maxLength={50}
            onBlur={updateOffice}
          ></Title>
          <SocialLink value={phone} title="Phone: " type="phone" />
          <SocialLink value={email} title="Email: " type="email" />
        </Box>
      </div>
    );
  };

  const updateTeamLeader = val => {
    if (secondAgent) {
      dispatch(updateTeam({ secondLeaderName: val }));
    } else {
      dispatch(updateTeam({ leaderName: val }));
    }
  };

  const updateOffice = val => {
    if (!secondAgent) {
      dispatch(updateTeam({ officeName: val }));
    } else {
      dispatch(updateTeam({ secondOffice: val }));
    }
  };

  return (
    <div className={className}>
      {(profilePortrait || biography) && (
        <div className={classes.agentInfo}>
          {profilePortrait && (
            <img
              src={getImageUrl(profilePortrait)}
              className={classes.agentPhoto}
              alt={leaderName}
            />
          )}
          {biography && (!isCommercial || withoutCommercialStyle) && (
            <div className={classes.articleContent + " mce-content-body"}>
              <div
                dangerouslySetInnerHTML={{
                  __html: biography
                    // .replace(/<img[^>]*>/g, "")
                    // .replace(/<br[^>]*>/g, ""),
                }}
              />
            </div>
          )}
        </div>
      )}
      <CommercialInfo />
      <div className={classes.profileLinks}>
        <ProfileButton profileLink={profileLink} title={profileLinkText} />

        {recentSales && category === "sales" && (
          <ProfileButton profileLink={recentSales} title={recentSalesTitle} />
        )}
        {currentRentals && category === "lease" && (
          <ProfileButton
            profileLink={currentRentals}
            title="View Current Rentals"
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(AgentBio);
