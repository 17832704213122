import { Modal, Button } from 'react-bootstrap';
import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

export const CropImageModal = ({ onCrop, src, aspectRatio, show, onHide }) => {
  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      onCrop(cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='xl'
      centered
      backdrop="static"
    >
      <Modal.Header className='h-55px'>
        <CloseIcon fontSize='large' onClick={onHide} className='hover-opacity-40 cursor-pointer'/>
        <Modal.Title>
          Crop Image
        </Modal.Title>
        <Button variant="primary" disabled={!cropper} onClick={getCropData}>
          Apply
        </Button>
      </Modal.Header>
      <Modal.Body style={{ height: 'calc(100vh - 120px)' }} className='position-relative'>
        <Cropper
          style={{ height: '100%', width: "100%" }}
          preview=".img-preview"
          src={src}
          aspectRatio={aspectRatio}
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          background={false}
          onInitialized={instance => setCropper(instance)}
        />
      </Modal.Body>
    </Modal>
  );
}

CropImageModal.prototype = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  src: PropTypes.string,
  onCrop: PropTypes.func.isRequired,
}
