import React, { useEffect, useState } from 'react';
import { Button as PrButton } from 'primereact/components/button/Button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { makeStyles } from '@material-ui/styles';
import { TextareaAutosize, Tooltip } from '@material-ui/core';
import cn from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from '../../Appraisals/CreateAppraisal/utils';
import InlineEditor from '../../Appraisals/CommonComponents/InlineEditor';
import IconButton from 'app/components/ui/Buttons/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import produce from 'immer';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme?.palette?.primary.main,
    fontSize: 24,
    lineHeight: '24px !important',
    fontWeight: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    textAlign: 'center',
    outline: 'none'
  },
  tooltip: {
    fontSize: 18
  },
  textAreaBase: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    "&:disabled, &:focus": {
      opacity: 1 + ' !important',
    },
    "&:hover": {
      opacity: 0.6,
    },
  },
  container: {
    "& > div:nth-child(even)": {
      background: 'rgba(60, 60, 61, 0.1)',
    }
  }
}));


const AddItemDialog = ({ show, onHide, onAdd }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    setText('')
  }, [show])

  const addItem = () => {
    onAdd({ text })
    onHide()
  }

  const onInputChange = ({ target }) => {
    setText(target.value)
  }

  const itemDialogFooter = (
    <React.Fragment>
      <PrButton label="Cancel" icon="pi pi-times" className="p-button-text" onClick={onHide} />
      <PrButton label="Save" icon="pi pi-check" className="p-button-text" onClick={addItem} disabled={!text.trim()}/>
    </React.Fragment>
  )

  return (
    <Dialog visible={show} style={{ width: '450px' }} header="Timeline Items" modal className="p-fluid" footer={itemDialogFooter} onHide={onHide}>
      <div className="p-field">
        <label htmlFor="text">Title</label>
        <InputText id="text" value={text} onChange={onInputChange} required autoFocus />
        {!text.trim() && <small className="p-error">Text is required.</small>}
      </div>
    </Dialog>
  )
}


const CalendarItems = ({ calendar, onUpdate, onDelete }) => {
  const maxLength = 50;
  const classes = useStyles();

  const [deleteCalendarDialog, setDeleteCalendarDialog] = useState(false);
  const [title, setTitle] = useState(calendar.title);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAdd, setShowAdd] = useState(false)


  const hideDeleteCalendarDialog = () => {
    setDeleteCalendarDialog(false);
  }

  const onDeleteCalendar = () => {
    setDeleteCalendarDialog(true);
  }

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) return;

    const newItems = reorder(
      calendar.items,
      result.source.index,
      result.destination.index
    );
    onUpdate({ ...calendar, items: newItems})
  }

  const addItem = newItem => {
    onUpdate({ ...calendar, items: [...calendar.items, newItem]})
  }

  const onTitleUpdate = ({ target }) => {
    setShowTooltip(false)
    onUpdate({ ...calendar, title: target.value})
  }

  const onTextUpdate = (val, index) => {
    let updatedCalendar = produce(calendar, draft => {
      draft.items[index].text = val
    })
    onUpdate(updatedCalendar);
  }

  const deleteRowItem = index => {
    let updatedCalendar = produce(calendar, draft => {
      draft.items.splice(index, 1);
    })
    onUpdate(updatedCalendar);
  }

  const deleteCalendarDialogFooter = (
    <React.Fragment>
      <PrButton label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCalendarDialog} />
      <PrButton label="Yes" icon="pi pi-check" className="p-button-text" onClick={onDelete} />
    </React.Fragment>
  );

  return (
    <div className="border-bottom pb-5 pt-5">
      <Tooltip placement="top" title={`You can enter another ${maxLength - title?.length} characters`} open={showTooltip} classes={{tooltip: classes.tooltip}}>
        <TextareaAutosize
          maxLength={maxLength}
          placeholder={`Maximum ${maxLength} characters`}
          rowsMax={1}
          value={title}
          className={cn( classes.title, classes.textAreaBase )}
          onChange={ e => setTitle(e.target.value) }
          onBlur={ onTitleUpdate }
          onFocus={ () => setShowTooltip(true) }
        />
      </Tooltip>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={classes.container}>
              {calendar.items.map((item, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  { provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="p-4 d-flex text-hover-primary pl-2"
                    >
                      <DragIndicatorIcon className="m-auto" />
                      <InlineEditor
                        value={item.text}
                        onBlur={val => onTextUpdate(val, index)}
                        placeholder="name"
                        className="h6 m-0 ml-2"
                      />
                      <IconButton
                        icon="trash"
                        variant="trash"
                        onClick={() => deleteRowItem(index)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <PrButton label="Add Item" icon="pi pi-plus" className="btn btn-primary mt-5 mr-5" onClick={() => setShowAdd(true)} />
      <PrButton label="Delete Calendar" icon="pi pi-times" className="btn btn-primary mt-5" onClick={onDeleteCalendar} />

      <AddItemDialog show={showAdd} onHide={() => setShowAdd(false)} onAdd={addItem}/>

      <Dialog visible={deleteCalendarDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCalendarDialogFooter} onHide={hideDeleteCalendarDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
          <span>Are you sure you want to delete this calendar?</span>
        </div>
      </Dialog>
    </div>
  )
}


export default CalendarItems

