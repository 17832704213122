import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SvgIcon from "app/components/ui/SvgIcon";
import { NoData } from "app/components/NoData";
import cn from "classnames";
import Button from "app/components/ui/Buttons/Button";

const Articles = ({ list, isLoading }) => (
  <>
    <div className="footer-articles-list">
      <div className="articles-list_header">
        <h5 className="articles-list_title">Most popular articles</h5>
      </div>
      <ul className="articles-list-wrapper">
        {list.map((item, index) => (
          <li
            key={item.article_id}
            className={cn("articles-list_item", {
              "horizontal-separator": index !== list.length - 1,
            })}
          >
            <div className="articles-list_item-content">
              <span className="list-item_title">{item._id}</span>
              <span className="list-item_subtitle">
                {item.count}&nbsp;Views
              </span>
            </div>
            <Link
              to={`/offices/${item.office}/articles`}
              className="articles-list_item-link"
            >
              <SvgIcon iconName="chevronRight" />
            </Link>
          </li>
        ))}
        {!list.length && !isLoading && <NoData />}
      </ul>
    </div>
    <div className="articles-button-wrapper">
      <Button variant="outlined-blue" disabled={!list.length}>
        <Link to="/popular-articles">View all articles</Link>
      </Button>
    </div>
  </>
);

Articles.propTypes = {
  list: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

Articles.defaultProps = {
  list: [],
};

export default Articles;
