import React, { useState } from "react";
import {
  Card,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { toAbsoluteUrl } from "_metronic/_helpers";

import cn from "classnames";
import ActionsWrapper from "./ActionsWrapper";
import FlipWrapper from "./FlipWrapper";
import IconsSection from "./IconsSections";

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    height: "100%",
    position: 'relative',
  },
  cardWrapper: {
    position: "relative",
    cursor: "pointer",
    "&:hover div": {
      opacity: 0.8,
    },
  },
  btnLayer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "12px 0",
    width: "60%",
    color: "#fff",
    borderRadius: "10px",
    fontSize: "22px",
    textAlign: "center",
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  marketTypeOverlay: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: '0.65rem 1rem',
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  content: {
    padding: theme.spacing(2),
    '& p': {
      paddingBottom: theme.spacing(1),
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  image: {
    height: 0,
    paddingTop: '67.25%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
}));

const PropertyCard = props => {
  const classes = useStyles();
  const handleCardClick = () => {
    if (props.onClick) props.onClick()
  }

  return (
    <Card
      variant="outlined"
      className={classes.card}
      onClick={handleCardClick}
    >
      {props.marketType &&
        <div className={classes.marketTypeOverlay}>
          {props.marketType}
        </div>
      }
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${
            props.image
              ? CSS.escape(props.image)
              : toAbsoluteUrl("/media/logos/upload-prop-photo.png")
          })`
        }}
      />
      <div className="position-relative">
        <IconsSection
          bathrooms={props.bath}
          bedrooms={props.bed}
          cars={props.cars}
          landArea={props.landArea}
          underImageIcons={props.underImageIcons}
          bigIcons={props.bigIcons}
        />
      </div>
      {props.descriptionFields?.length &&
        <div
          className={
            cn(classes.content, { "pt-0": props.underImageIcons.underImageIcons })
          }
        >
          {props.descriptionFields.map((val, index) => val && (
            <Typography key={index} color="textSecondary">
              {val}
            </Typography>
          ))}
        </div>
      }
    </Card>
  );
}

const CardWithImage = props => {
  const [isFlipped, setIsFlipped] = useState(false);

  const actionList = [];
  const flipCard = () => setIsFlipped(true)
  if (props.onDescriptionChange) actionList.push({ title: 'Add Note', action: flipCard })
  if (props.onEdit) actionList.push({ title: 'Edit', action: props.onEdit })
  if (props.onDelete) actionList.push({ title: 'Delete', action: props.onDelete })

  return (
    <FlipWrapper
      description={props.description}
      onDescriptionChange={props.onDescriptionChange}
      isFlipped={isFlipped}
      setIsFlipped={setIsFlipped}
      showDescription={props.showDescription}
    >
      {/* showDescription - to show icon not on preview */}
      <ActionsWrapper actionList={actionList}>
        <PropertyCard {...props}/>
      </ActionsWrapper>
    </FlipWrapper>
  );
};

export default React.memo(CardWithImage);
