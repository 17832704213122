import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLeaderListType } from "redux/reducers/dashboardReducer";
import {
  getLeadersListType,
  getRange,
  getWeeks,
} from "redux/selectors/dashboardSelector";
import SwitchButton from "app/components/ui/Buttons/SwitchButton";
import { LeadersList } from "./LeadersList";
import { Button } from "primereact/button";
import AppraisalsService from "app/api/AppraisalsService";
import { AGENTS, OFFICES } from "app/constants/commonConstants";
import SearchBox from "../../../../modules/SearchBox/index";
import useDebounce from "../../../../hooks/useDebounce";
import cn from "classnames";

import "./styles.scss";

const switchButtonData = [
  { title: "Agent", listType: AGENTS },
  { title: "Office", listType: OFFICES },
];

const Leaders = ({ leadersPage = false, withSearch = false }) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const currentListType = useSelector(getLeadersListType);
  const weeks = useSelector(getWeeks);
  const range = useSelector(getRange);

  const debouncedSearchTerm = useDebounce(search, 500);

  const onSwitchButtonClick = ({ index }) => {
    dispatch(setLeaderListType(switchButtonData[index].listType));
  };

  const initialIndex = switchButtonData.findIndex(
    item => item.listType === currentListType,
  );

  const handleExport = () => {
    AppraisalsService.leaderboardExport(weeks, range.start, range.end).then(
      response => {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "leaderboard.csv";
        link.click();
      },
    );
  };

  const handleOfficeExport = () => {
    AppraisalsService.leaderboardOfficeExport(
      weeks,
      range.start,
      range.end,
    ).then(response => {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "offices_leaderboard.csv";
      link.click();
    });
  };

  return (
    <div className="leaders">
      <div
        className={cn("header-wrapper horizontal-separator", {
          "extended-list": leadersPage,
        })}
      >
        <div className="header leaderboard-padding">
          <h2 className="header-title">
            Top&nbsp;{currentListType}&nbsp;by&nbsp;total&nbsp;sent
          </h2>
          <SwitchButton
            data={switchButtonData}
            handlerClick={onSwitchButtonClick}
            initialIndex={initialIndex}
            className="leaderboard-switch-button"
          />
          <div className="export-buttons">
            {leadersPage && currentListType === AGENTS && (
              <Button
                label="Export .CSV"
                className="pull-right"
                onClick={handleExport}
              />
            )}
            {leadersPage && currentListType !== AGENTS && (
              <Button
                label="Export Office .CSV"
                className="pull-right"
                onClick={handleOfficeExport}
              />
            )}
          </div>
        </div>
        {withSearch ? (
          <SearchBox
            onChangeSearch={val => setSearch(val)}
            className="layout-side-padding proposals-list-search-box"
            textFieldClassName="textFieldClassName"
          />
        ) : (
          ""
        )}
      </div>
      <LeadersList leadersPage={leadersPage} search={debouncedSearchTerm} />
    </div>
  );
};

export default Leaders;
