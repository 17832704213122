import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import _ from "lodash";
import { loginRequest } from "../../../authConfig";
import { actions } from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import UsersService from "../../../api/UsersService";
import AuthLayout from "app/Layouts/AuthLayout";
import SelectOffice from "./SelectOffice";
import { ToastContext } from "../../../components/ToastProvider";
import LoginForm from "./components/LoginForm";
import SessionExpiredModal from "./components/SessionExpiredModal";

function Login() {
  const { instance, accounts, inProgress } = useMsal();
  const [loading, setLoading] = useState(false);
  const [officeList, setOfficeList] = useState([]);
  const authToken = useSelector(({ auth }) => auth.authToken, shallowEqual);
  const { toast } = useContext(ToastContext);

  const dispatch = useDispatch();

  const showError = err => {
    const errMessage =
      "Please check you are logging in the correct way (See note next to login area). If problems persist, please contact products@corp.rh.com.au";
    toast.current.show({
      severity: "error",
      summary: "Login Failed",
      detail: errMessage,
      life: 5000,
    });
  };

  const onGetOffices = data => {
    if (Array.isArray(data)) {
      if (data.length === 1) {
        onOfficeSelect(data[0]);
      } else {
        const officeList = _.uniqBy(data, "user.office.name");
        setOfficeList(officeList);
      }
    } else {
      onOfficeSelect(data);
    }
  };

  const initAzureUser = async account => {
    setLoading(true);
    try {
      const { accessToken } = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      const { data } = await UsersService.getMeByAzure(accessToken);
      onGetOffices(data);
    } catch (err) {
      const errMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong, please try again later";
      if (toast.current)
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errMessage,
          life: 3000,
        });
    }
    setLoading(false);
  };

  const onOfficeSelect = data => {
    dispatch(actions.initUser({ ...data, authList: officeList }));
  };

  useEffect(() => {
    if (
      !authToken &&
      inProgress === "none" &&
      accounts.length === 1 &&
      !inProgress
    ) {
      initAzureUser(accounts[0]);
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, inProgress, accounts]);

  const onUserPassLogin = async ({ username, password }) => {
    setLoading(true);
    try {
      const { data } = await login(username, password);
      dispatch(actions.setToken(data.value));
    } catch (err) {
      showError(err);
    }
    setLoading(false);
  };

  const ssoSignIn = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      await initAzureUser(loginResponse);
    } catch (err) {
      showError(err);
    }
  };

  if (officeList?.length)
    return <SelectOffice officeList={officeList} onSelect={onOfficeSelect} />;

  return (
    <>
      <AuthLayout
        title="Login"
        subTitle={
          <div>
            <p>Enter your username and password.</p>
            <p>
              For those offices not on SSO (Single Sign On), please login using
              the same login details as your CompassPlus account.
            </p>
          </div>
        }
      >
        <LoginForm
          onSSOLogin={ssoSignIn}
          onUserPassLogin={onUserPassLogin}
          loading={loading || inProgress !== "none"}
        />
      </AuthLayout>
      <SessionExpiredModal />
    </>
  );
}

export default Login;
