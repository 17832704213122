import { CreatePropertySteps, ProposalTypes } from "../../interfaces";

export interface Step {
  id: CreatePropertySteps;
  label: string
}
export function getSteps (isCorporate: boolean, type?: ProposalTypes): Array<Step> {
  const propertyStep =
    type === ProposalTypes.marketUpdate || type === ProposalTypes.prospecting
      ? {
          id: CreatePropertySteps.SelectSuburb,
          label: "Suburb",
        }
      : type === ProposalTypes.businessListingProposal
      ? {
          id: CreatePropertySteps.SelectProperty,
          label: "Select Business",
        }
      : {
          id: CreatePropertySteps.SelectProperty,
          label: "Select Property",
        };

  let steps = [
    {
      id: CreatePropertySteps.SelectType,
      label: "Select Type",
    },
    {
      id: CreatePropertySteps.SelectTeam,
      label: "Select Profile",
    },
    propertyStep,
    {
      id: CreatePropertySteps.Builder,
      label: "Proposal Builder",
    },
    {
      id: CreatePropertySteps.Sender,
      label: "Send",
    },
  ]

  if (type === ProposalTypes.agentProfiling) {
    steps.splice(2,1);
  }

  if (isCorporate) steps.unshift({
    id: CreatePropertySteps.SelectOffice,
    label: "Select Office",
  })

  return steps;
}
