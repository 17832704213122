import React, { useContext, useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {updateOwnersDetails} from "../../../../redux/actions";
import { AppraisalContext } from "../AppraisalContext";
import produce from "immer";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

const useStyles = makeStyles(() => ({
    inputDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
    }
}));


const OwnersDetails = () => {
    const dispatch = useDispatch();
    const propertyOwners = useSelector((state) => state.appraisal.owners, shallowEqual);

    const emptyItem = {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    }

    const [localOwners, setLocalOwners] = useState([]);

    useEffect(() => {
        setLocalOwners(propertyOwners.map((owner) => ({firstName: owner?.firstName, lastName: owner.lastName, email: owner.email, phone: owner.phone})))
    }, [propertyOwners])


    const handleBlur = (index, field, value) => {
        const newOwners = produce(propertyOwners, draft => {
            draft[index][field] = value
        })
        dispatch(updateOwnersDetails(newOwners))
    }
    const onDelete = (index) => {
        const newOwners = produce(propertyOwners, draft => {
            draft.splice(index, 1)
        })
        dispatch(updateOwnersDetails(newOwners))
    }

    const onAdd = () => {
        const newOwners = produce(propertyOwners, draft => {
            draft.push(emptyItem)
        })
        dispatch(updateOwnersDetails(newOwners))
    }

    const handleChange = (index,field, value) => {
       const updatedLocalState = produce(localOwners, draft => {
           draft[index][field] = value
       })
       setLocalOwners(updatedLocalState)
       // dispatch(updateOwnersDetails(updatedLocalState))
    }

    const { preview } = useContext(AppraisalContext)
    const classes = useStyles();
    if (preview) return '';
    return (
        <div className="card-body">
            {propertyOwners.map((owner, index) => {
               return (
                   <div className={classes.inputDiv} key={index}>
                    <Typography className="mb-3 my-3 mx-4 text-nowrap">{`Recipient ${index + 1}:`}</Typography>
                    <TextField
                        className="mb-3 my-3 mx-4"
                        id="owner-f-name"
                        fullWidth
                        variant="outlined"
                        label="First Name"
                        size="small"
                        value={ localOwners[index]?.firstName }
                        InputProps={{className: classes.input}}
                        onChange={e => handleChange(index, 'firstName', e.target.value)}
                        onBlur={e => handleBlur(index, 'firstName', e.target.value)}
                    />
                    <TextField
                        className="mb-3 my-3 mx-4"
                        id="owner-l-name"
                        fullWidth
                        variant="outlined"
                        label="Last Name"
                        size="small"
                        value={localOwners[index]?.lastName}
                        InputProps={{className: classes.input}}
                        onChange={e => handleChange(index,'lastName', e.target.value)}
                        onBlur={e => handleBlur(index, 'lastName', e.target.value)}
                    />
                    <TextField
                        className="mb-3 my-3 mx-4"
                        id="vendor-email"
                        fullWidth
                        variant="outlined"
                        label="Email"
                        size="small"
                        value={localOwners[index]?.email}
                        InputProps={{className: classes.input}}
                        onChange={e => handleChange(index,'email', e.target.value)}
                        onBlur={e => handleBlur(index, 'email', e.target.value)}
                    />
                    <TextField
                        className="mb-3 my-3 mx-4"
                        id="vendor-mobile"
                        fullWidth
                        label="Mobile"
                        variant="outlined"
                        size="small"
                        value={localOwners[index]?.phone}
                        InputProps={{className: classes.input}}
                        onChange={e => handleChange(index,'phone', e.target.value)}
                        onBlur={e => handleBlur(index, 'phone', e.target.value)}
                    />
                   {propertyOwners.length > 1 &&
                     <img src={toAbsoluteUrl("/media/delete.png")}  alt="delete" width={'40px'} height={'40px'} onClick={() => onDelete(index)} />
                   }
                </div>)
            })}
            {propertyOwners.length < 4 &&
            <button
                onClick={onAdd}
                className="btn btn-primary w-60px mx-4"
            >
                + Add
            </button>}
        </div>
    );
};

export default OwnersDetails;
