import axios from 'axios';
import API_URL from './api';

export default class ProspectTemplatesService {
  static getTemplates = (search = '', page = 1, filter = 'updated_at', sort = 'descending', archived = false, draft) => {
    return axios.get(
      `${API_URL}/prospect-templates?search=${search}&page=${page}&filter=${filter}&sort=${sort}&archived=${archived}&draft=${draft}`,
    );
  }

  static postTemplates = (body) => {
    return axios.post(
      `${API_URL}/prospect-templates`, body
    );
  }

  static putTemplates = (body, id) => {
    return axios.put(
      `${API_URL}/prospect-templates/${id}`, body
    );
  }

  static getTemplate = (id) => {
    return axios.get(
      `${API_URL}/prospect-templates/${id}`
    );
  }

}
