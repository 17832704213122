import React from 'react';
import { HeaderMobile } from '_metronic/layout/components/header-mobile/HeaderMobile';
import { Aside } from '_metronic/layout/components/aside/Aside';

import './styles.scss';

const Layout = ({ children }) => {
  return (
    <>
      <HeaderMobile />
      <div className="layout">
        <Aside />
        <div className="layout-content">
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
