export const svgIconsRegistry = {
  avatar: '/media/svg/icons/Manage/icon-avatar.svg',
  page: '/media/svg/icons/Manage/icon-page.svg',
  sync: '/media/svg/icons/Manage/icon-sync.svg',
  save: '/media/svg/icons/Manage/icon-save.svg',
  trash: '/media/svg/icons/Manage/icon-trash.svg',
  jumpUp: '/media/svg/icons/Manage/icon-jump-up.svg',
  smartphone: '/media/svg/icons/Manage/icon-smartphone.svg',
  laptop: '/media/svg/icons/Manage/icon-laptop.svg',
  ampersand: '/media/svg/misc/ampersand.svg',
  threeDots: '/media/svg/icons/Manage/icon-three-dots.svg',
  chevronLeft: '/media/svg/icons/Manage/icon-chevron-left.svg',
  chevronRight: '/media/svg/icons/Manage/icon-chevron-right.svg',
  chevronLargeBack: '/media/svg/icons/Manage/icon-chevron-large-back.svg',
  checkMarkGreen: '/media/svg/icons/Manage/icon-check-mark-green.svg',
  closeRedBtn: '/media/svg/icons/Manage/icon-close-red-btn.svg',
  crossWhite: '/media/svg/icons/Manage/icon-cross.svg',
  actionReport: '/media/svg/icons/Manage/icon-action-report.svg',
  questionCircle: '/media/svg/icons/Manage/icon-question-circle.svg',
  thinArrow: '/media/svg/icons/Manage/icon-arrow-down.svg',
  handshake: '/media/svg/icons/Manage/icon-handshake.svg',
  pencil: '/media/svg/icons/Manage/icon-pencil.svg',
  noChange: '/media/svg/icons/Manage/icon-no-change.svg',
  tuneIcon: '/media/svg/icons/Manage/icon-tune.svg',
  flk: '/media/flk.svg',
};
