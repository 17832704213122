import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { chunk } from "lodash";

import {
  makeStyles,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import { updateOurGrowingNetwork } from "redux/reducers";
import { getOurGrowingNetworkData } from "redux/selectors";

import OfficesService from "app/api/OfficesService";
import Carousel from "app/components/Carousel";
import HideContainer from "app/modules/Appraisals/components/HideContainer";
import BlockContainer from "app/modules/Appraisals/components/BlockContainer";

const useStyles = makeStyles(theme => ({
  testimonialCard: {
    borderRadius: 0,
    height: "100%",
    borderColor: theme.palette.divider,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#fff",
    fontWeight: 600,
  },
  imageBox: {
    position: "relative",
  },
  overText: {
    position: "absolute",
    background:
      "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0.1) 100%)",
    bottom: "0px",
    left: "0px",
    right: "0px",
    padding: "20px",
  },
}));

const useOurGrowingNetworkStyles = makeStyles(theme => ({
  carouselDots: {
    '& .carousel-slider': {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: '80px',
      },
    }
  }
}))

const TestimonialItem = ({ data }: { data: any }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.testimonialCard}>
      <div className={classes.imageBox}>
        <img src={data.image} alt={data.overlay_text} />
        <div className={classes.overText}>
          <Typography
            component="p"
            align="left"
            style={{ color: "#fff", fontSize: "16px" }}
          >
            {data.overlay_text} {data.secondary_overlay_text}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

const CarouselItem = ({ item }: { item: any }) => {
  return (
    <Grid container spacing={3}>
      {item.map((data: any, index: any) => (
        <Grid item sm={6} key={index} className="w-100">
          <TestimonialItem data={data} />
        </Grid>
      ))}
    </Grid>
  );
};

const OurGrowingNetwork = () => {
  const dispatch = useDispatch();
  const ourGrowingNetworkSection = useSelector(getOurGrowingNetworkData);

  const [content, setContent] = useState<Array<any>>([]);
  const [chunkTestimonials, setChunkTestimonials] = useState<Array<any>>([]);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const classes = useOurGrowingNetworkStyles();

  const fetchData = useCallback(() => {
    OfficesService.getNewOffices().then(resp => {
      // TODO
      const data = resp.status === 200 ? resp.data : [];
      setContent(data.filter((item: any) => item.image));
    });
  }, [setContent]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setChunkTestimonials(chunk(content || [], isMobile ? 1 : 2));
  }, [content, isMobile]);

  const onHideSection = (value: boolean) => {
    dispatch(updateOurGrowingNetwork({ isHiddenSection: value }));
  };

  const onTitleUpdate = (value: string) => {
    dispatch(updateOurGrowingNetwork({ title: value }));
  };

  const onSubTitleUpdate = (value: string) => {
    dispatch(updateOurGrowingNetwork({ subtitle: value }));
  };

  return (
    <HideContainer
      value={ourGrowingNetworkSection.isHiddenSection}
      onChange={onHideSection}
    >
      <BlockContainer
        id={"our-growing-network"}
        defaultTitle={ourGrowingNetworkSection.title}
        defaultSubtitle={ourGrowingNetworkSection.subtitle}
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubTitleUpdate}
        hide={ourGrowingNetworkSection.isHiddenSection}
      >
        <Carousel
          autoPlay
          infiniteLoop
          interval={5000}
          showStatus={false}
          showThumbs={false}
          centerMode={true}
          centerSlidePercentage={100}
          className={classes.carouselDots}
        >
          {chunkTestimonials.map((item, index) => (
            <CarouselItem item={item} key={index} />
          ))}
        </Carousel>
      </BlockContainer>
    </HideContainer>
  );
};

export default OurGrowingNetwork;
