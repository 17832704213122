import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { AppraisalContext } from "../../../../../AppraisalContext";
import { getPrice } from "../../../../../CreateAppraisal/utils";
import { TableCell, TableRow } from "../../../../../../Common/DraggableTable";
import AddMarketingButton from "./AddMarketingButton";
import InlineEditor from "../../../../../CommonComponents/InlineEditor";
import IconButton from "../../../../../../../components/ui/Buttons/IconButton";
import { useCommercial } from "app/hooks";

const useStyles = makeStyles(() => ({
  costSavingsRow: ({ isCommercial }) => ({
    "& span": {
      wordBreak: "break-all",
      color: "#FFB300",
      fontWeight: 400,
      lineHeight: 2,
      float: "right",
    },
    "& input": {
      color: `${isCommercial ? "#FFD200" : "#FFB300"}`,
      textAlign: "end",
    },
  }),
}));

export const FooterContent = ({
  addCustomItem,
  onAddExtraClick,
  tabCellStyle,
  total,
  onTotalUpdate,
  addCostSavings,
  isCostSavings,
}) => {
  const { preview } = useContext(AppraisalContext);
  const totalPrice = getPrice(total) || "";

  return (
    <>
      <TableCell className="pl-0" hidden={preview}>
        <AddMarketingButton onClick={addCustomItem}>
          Add Marketing Item
        </AddMarketingButton>
        {onAddExtraClick && (
          <AddMarketingButton onClick={onAddExtraClick}>
            Add Optional Extras
          </AddMarketingButton>
        )}
        {!isCostSavings && (
          <AddMarketingButton onClick={addCostSavings}>
            Add Cost Savings
          </AddMarketingButton>
        )}
      </TableCell>
      <TableCell
        className={tabCellStyle}
        hidden={preview ? preview && !total : false}
      >
        Total:
      </TableCell>
      <TableCell
        className={tabCellStyle}
        hidden={preview ? preview && !total : false}
      >
        <InlineEditor
          inputProps={{ style: { textAlign: "right" } }}
          value={totalPrice}
          placeholder={preview ? "" : "Total Amount"}
          onBlur={val => onTotalUpdate(val)}
          charLimit={15}
        />
      </TableCell>
      <TableCell hidden={preview} style={{ width: "60px" }} />
    </>
  );
};

export const ExtraFooterContent = ({ addExtraItem }) => {
  const { preview } = useContext(AppraisalContext);

  return (
    <TableCell className="pl-0" hidden={preview}>
      <AddMarketingButton onClick={addExtraItem}>Add Extra</AddMarketingButton>
    </TableCell>
  );
};

export const CostSavingsContent = ({ costSavings, onUpdateCostSavings }) => {
  const { preview } = useContext(AppraisalContext);
  const { isCommercial } = useCommercial();
  const classes = useStyles({ isCommercial });

  return (
    <TableRow className={classes.costSavingsRow}>
      <TableCell>
        {preview ? (
          <span>{costSavings}</span>
        ) : (
          <InlineEditor
            value={costSavings}
            placeholder="Enter your text here"
            onBlur={val => onUpdateCostSavings(val)}
            charLimit={120}
          />
        )}
      </TableCell>
      {!preview && (
        <TableCell style={{ width: "60px" }}>
          <IconButton
            icon="trash"
            variant="trash"
            onClick={() => onUpdateCostSavings("")}
          />
        </TableCell>
      )}
    </TableRow>
  );
};
