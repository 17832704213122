import axios from "axios";
import { useMemo } from "react";

export const dataURLtoFile = (dataUrl, filename) => {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type:mime});
}

export const prepareCustomFileMap = async officeId => {
  const apiUrl =
    process.env.REACT_APP_API_URL + `/files/${officeId}?fullTree=true`;
  const {
    data: { fileMap, rootFolderId },
  } = await axios.get(apiUrl);
  return { fileMap, rootFolderId };
};


export const useFiles = (fileMap, currentFolderId) => {
  return useMemo(() => {
    let files = [];
    if (fileMap) {
      const currentFolder = fileMap[currentFolderId];
      let childrenIds;
      if (currentFolder) {
        childrenIds = currentFolder.childrenIds;
        files = childrenIds.map(fileId => fileMap[fileId]);
      }
    }
    return files;
  }, [currentFolderId, fileMap]);
};

export const useFolderChain = (fileMap, currentFolderId) => {
  return useMemo(() => {
    let folderChain = [];
    if (fileMap) {
      const currentFolder = fileMap[currentFolderId];
      if (currentFolder) {
        folderChain = [currentFolder];
        let parentId = currentFolder.parentId;
        while (parentId) {
          const parentFile = fileMap[parentId];
          if (parentFile) {
            folderChain.unshift(parentFile);
            parentId = parentFile.parentId;
          } else {
            break;
          }
        }
      }
    }
    return folderChain;
  }, [currentFolderId, fileMap]);
};
