import React, { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Toast } from "primereact/toast";
import AppraisalsService from "../../../api/AppraisalsService";
import { updateProperty, updateVendor } from "../../../../redux/reducers";
import AppraisalMenus from "../AppraisalMenus";
import ProspectTemplatesService from "../../../api/ProspectTemplatesService";
import AppraisalTypeContent from "./AppraisalTypeContent";
import useTrackEvent from "../../../hooks/useTrackEvent";
import PropertiesService from "../../../api/PropertiesService";
import {
  mapPropertyData,
  populateVendorDetails,
} from "../CreateAppraisal/utils";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import TeamsService from "../../../api/TeamsService";

import { updateTeam } from "redux/reducers";
import _ from "lodash";
import OwnersDetails from "./OwnersDetails";
import VendorDetails from "./VendorDetails";
import { getProposalType } from "../../../../redux/selectors";

const useStyles = makeStyles(theme => ({
  container: {
    "& p": {
      lineHeight: "21px",
      color: "#333",
      fontSize: "15px",
    },
    "& .footer p": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
    display: "flex",
    flexDirection: "column",
    background: "rgba(0, 0, 0, 0.5) !important",
  },
}));

const compareUpdatedData = (obj1, obj2, vendor = false) => {
  const keys = Object.keys(obj1);
  let updatedKeys = [];

  for (let key of keys) {
    if (key === "coordinates") {
      if (obj1[key].lon !== obj2[key].lon || obj1[key].lat !== obj2[key].lat) {
        updatedKeys.push(_.startCase(key));
      }
    }
    if (obj1[key] !== obj2[key]) {
      if (!["address", "coordinates", "about"].includes(key)) {
        if (vendor) {
          key = "Recipient " + key;
        }
        updatedKeys.push(_.startCase(key));
      }
    }
  }
  return updatedKeys;
};

const convertToInternationalFormat = mobileNumber => {
  const cleanedNumber = mobileNumber.replace(/\D/g, "");
  const normalizedNumber = cleanedNumber.startsWith("0")
    ? cleanedNumber.slice(1)
    : cleanedNumber;
  return `+61${normalizedNumber}`;
};

const AppraisalDetails = props => {
  const trackEvent = useTrackEvent();
  const [saveLoading, setSaveLoading] = useState(false);
  const [printInProgress, setPrintInProgress] = useState(false);
  const appraisalData = useSelector(state => state.appraisal, shallowEqual);
  const appraisalType = useSelector(getProposalType);
  const meta = useSelector(({ appraisal }) => appraisal.meta, shallowEqual);
  const teamId = useSelector(
    ({ appraisal }) => appraisal.team.id,
    shallowEqual,
  );
  const [header, setHeader] = useState("");
  const [message, setMessage] = useState("");
  const [syncDialog, setSyncDialog] = useState(false);

  const classes = useStyles();

  const toast = useRef(null);
  const dispatch = useDispatch();

  const goToPreview = () => {
    saveAppraisal().then(props.showPreview);
  };

  const printAppraisal = () => {
    setPrintInProgress(true);
    trackEvent("print", "");
    AppraisalsService.printAppraisal(appraisalData.id)
      .then(resp => {
        const file = new Blob([resp.data], { type: "application/pdf" });
        const fileURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = fileURL;
        tempLink.setAttribute(
          "download",
          appraisalData.property.displayAddress,
        );
        tempLink.click();
      })
      .finally(() => {
        setPrintInProgress(false);
      });
  };

  const goToSend = () => {
    setSaveLoading(true);
    let isErrors = false;
    let errorMessage = "";
    if (
      appraisalType === "listingProposal" &&
      meta.flkEnabled &&
      appraisalData.nextSteps?.hasOwnProperty("flkHidden")
    ) {
      if (
        appraisalData.owners.length === 0 ||
        appraisalData.owners[0].firstName === undefined ||
        appraisalData.owners[0].firstName?.trim() === "" ||
        appraisalData.owners[0].lastName === undefined ||
        appraisalData.owners[0].lastName?.trim() === ""
      ) {
        isErrors = true;
        errorMessage = "Please fill all recipients details";
      }
      if (!appraisalData.nextSteps?.flkHidden) {
        appraisalData.nextSteps?.flkContacts?.forEach(contact => {
          if (contact.flkUrl === "") {
            errorMessage = "Please fill all FLK E-sign URL links";
            return (isErrors = true);
          }
        });
      }
    }

    if (isErrors) {
      setSaveLoading(false);
      return toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: errorMessage,
        life: 3000,
      });
    } else {
      saveAppraisal().then(() => props.showSend());
    }
  };

  const syncProperty = () => {
    setSaveLoading(true);
    let changedItems = [];
    PropertiesService.getProperty(
      meta.property,
      meta.classification,
      meta.category,
      meta.office,
    )
      .then(({ data }) => {
        const newPropertyData = mapPropertyData(
          data,
          meta.category,
          appraisalData.property.about.description,
          appraisalData.property.agentPriceOpinion,
        );
        changedItems = changedItems.concat(
          compareUpdatedData(newPropertyData, appraisalData.property),
        );
        dispatch(updateProperty(newPropertyData));
        return PropertiesService.getPropertyVendor(
          meta.property,
          data.saleLifeId || data.leaseLifeId || data.lifeId,
          meta.office,
          meta.category,
        );
      })
      .then(({ data }) => {
        const newVendorData = populateVendorDetails(data);
        changedItems = changedItems.concat(
          compareUpdatedData(newVendorData, appraisalData.vendor, true),
        );
        dispatch(updateVendor(newVendorData));
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Property has been successfully synchronized",
          life: 3000,
        });
      })
      .finally(() => {
        let msg = "";
        if (changedItems.length > 0) {
          setHeader("Sync Successful");
          msg = "The following items were updated:";
          msg += "<ul><li>" + changedItems.join("</li><li>") + "</li></ul>";
          setMessage(msg);
        } else {
          setMessage(
            "If an update was made, be sure you have clicked 'Update' in CompassPlus'",
          );
          setHeader("No new updates");
        }
        setSyncDialog(true);
        setSaveLoading(false);
      });
  };

  const updateCoverletter = () => {
    const ownersNames = appraisalData.owners
      .map(owner => `${owner.firstName}`)
      .join(" & ");
    const coverletter = appraisalData.vendor.coverLetter.replace(
      /<p>.*Dear .*,<\/p>/,
      `<p>Dear ${ownersNames},</p>`,
    );
    dispatch(updateVendor({ coverLetter: coverletter }));
  };

  const saveAppraisal = () => {
    setSaveLoading(true);
    if (
      appraisalType === "listingProposal" &&
      meta.flkEnabled &&
      appraisalData.owners?.length > 0
    ) {
      updateCoverletter();
    }
    return props.saveAppraisal().finally(() => setSaveLoading(false));
  };

  const goToFlk = () => {
    saveAppraisal();
    const category =
      meta.category === "sales" ? "sales" : "property_management";
    let url = `https://app.${
      process.env.FLK_ENV === "staging" ? "staging." : ""
    }flkitover.com/dashboard/agreements/${category}/draft?create=${category}&utm_source=RH`;
    url +=
      "&address=" +
      encodeURIComponent(
        appraisalData.property.displayAddress +
          " " +
          appraisalData.property.address.suburb.postcode,
      );
    url += `&sessionId=${appraisalData.id}`;
    appraisalData.owners.forEach((owner, index) => {
      url += `&${index + 1}-firstName=${owner.firstName}`;
      url += `&${index + 1}-secondName=${owner.lastName}`;
      url += `&${index + 1}-email=${owner.email}`;
      url += `&${index + 1}-phone=${convertToInternationalFormat(owner.phone)}`;
    });
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.focus();
  };

  const turnAutoSave = checked =>
    TeamsService.updateTeam(teamId, { isAutoSaveProposal: checked }).then(
      () => {
        dispatch(updateTeam({ isAutoSaveProposal: checked }));
        toast.current.show({
          severity: "info",
          summary: `Autosave ${checked ? "enabled" : "of"}`,
          life: 3000,
        });
      },
    );

  const saveTemplate = () => {
    setSaveLoading(true);
    return ProspectTemplatesService.postTemplates(appraisalData)
      .then(res => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Template has been successfully updated",
          life: 3000,
        });
        return res;
      })
      .catch(error => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            error.message || "Something went wrong, please try again later",
          life: 3000,
        });
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const footer = (
    <div>
      <Button
        label="Ok"
        icon="pi pi-check"
        onClick={() => setSyncDialog(false)}
      />
    </div>
  );

  return (
    <>
      <AppraisalMenus
        onSync={syncProperty}
        saveLoading={saveLoading}
        onPreview={goToPreview}
        onPrint={printAppraisal}
        onSave={saveAppraisal}
        turnAutoSave={turnAutoSave}
        onSend={goToSend}
        onSaveTemplate={saveTemplate}
        onFlk={goToFlk}
      >
        <div className={classes.container}>
          {appraisalType === "listingProposal" && meta.flkEnabled ? (
            <OwnersDetails />
          ) : (
            <VendorDetails />
          )}
          <AppraisalTypeContent type={appraisalType} />
        </div>
        <Toast ref={toast} />
        <Backdrop className={classes.backdrop} open={printInProgress}>
          <CircularProgress color="inherit" />
          <Typography component="h4" variant="h4" className="mt-10">
            Please wait a minute whilst we compose your printed proposal
          </Typography>
        </Backdrop>
      </AppraisalMenus>
      <Dialog
        header={header}
        visible={syncDialog}
        style={{ width: "30vw" }}
        footer={footer}
        onHide={() => setSyncDialog(false)}
      >
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Dialog>
    </>
  );
};

export default AppraisalDetails;
