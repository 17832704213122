import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import { getPropertyTypes } from "./propertyTypes";
import moment from "moment";
import SubParamsFields from "./SubParamsFields";
import ParamsFields from "./ParamsFields";

const commonTextStyle = {
  lineHeight: "17px",
  fontWeight: 600,
  fontSize: 14,
};

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.gray2,
    width: "100%",
    height: 150,
    minHeight: 150,
    display: "flex",
    justifyContent: "center",
  },
  inputs: {
    display: "flex",
    height: 70,
    alignItems: "end",
  },
  searchAddress: {
    ...commonTextStyle,
    textDecoration: "underline",
    cursor: "pointer",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& input": {
      height: 45,
      border: "1px solid",
      borderColor: theme.palette.border.gray,
      background: "#fff",
      lineHeight: "17px",
      fontSize: 13,
      padding: "13px 13px",
      outline: "none",
    },
    "& label": {
      ...commonTextStyle,
    },
    "&>svg": {
      position: "absolute",
      right: 13,
      bottom: 22.5,
      transform: "translateY(50%)",
    },
  },
  searchButton: {
    width: 100,
    height: 45,
    background: theme.palette.background.gray1,
    color: "#fff",
    "&:hover": {
      opacity: 0.9,
    },
  },
}));

const SearchToolbar = ({ onSearch, marketType, classification }) => {
  const classes = useStyles();
  const propertyTypeOptions = getPropertyTypes(marketType, classification);
  const [paramsFields, setParamsFields] = useState({
    radius: 0.5
  });
  const [propertyType, setPropertyType] = useState(
    propertyTypeOptions[0].value,
  );
  const [searchByAddress, setSearchByAddress] = useState(false);
  const [address, setAddress] = useState("");


  useEffect(() => {
    if (searchByAddress && marketType === "rh") setSearchByAddress(false);
    if (propertyTypeOptions) setPropertyType(propertyTypeOptions[0].value);
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketType]);

  const handleSearch = () => {
    const result = searchByAddress
      ? { address }
      : {
          radius: paramsFields.radius,
          p_type: propertyType === "All" ? "" : propertyType,
          min_beds: paramsFields.bedrooms,
          max_beds: paramsFields.bedrooms,
          min_baths: paramsFields.bathrooms,
          max_baths: paramsFields.bathrooms,
          min_car_spaces: paramsFields.parking,
          max_car_spaces: paramsFields.parking,
          min_price: paramsFields.priceMin,
          max_price: paramsFields.priceMax,
          min_land: paramsFields.landMin,
          max_land: paramsFields.landMax,
          min_land_area: paramsFields.landAreaMin,
          max_land_area: paramsFields.landAreaMax,
          dateRange:
            moment(paramsFields.dateMin || "19800101").format("YYYYMMDD") +
            "-" +
            moment(paramsFields.dateMax || undefined).format("YYYYMMDD"),
        };
    onSearch(searchByAddress, result);
  };

  const changeSearchType = () => setSearchByAddress(current => !current);

  const searchByAddressFields = (
    <div className={cn(classes.inputContainer, "mr-9")}>
      <label>Address</label>
      <input
        value={address}
        onChange={event => setAddress(event.target.value)}
        type="text"
        placeholder="eg. 123 Elm Street"
        style={{ width: 669 }}
      />
    </div>
  );

  return (
    <div className={classes.container}>
      <div className="d-flex flex-column justify-content-center">
        <div className={classes.inputs}>
          {searchByAddress ? searchByAddressFields : 
            <ParamsFields 
              propertyType={propertyType}
              setPropertyType={setPropertyType}
              propertyTypeOptions={propertyTypeOptions}
              marketType={marketType}
              classification={classification}
              paramsFields={paramsFields}
              setParamsFields={setParamsFields}
            />}
          <button className={classes.searchButton} onClick={handleSearch}>
            Search
          </button>
        </div>
        <div className="d-flex mt-3">
          <SubParamsFields 
            searchByAddress={searchByAddress}
            classification={classification}
            paramsFields={paramsFields}
            setParamsFields={setParamsFields}
          />
          {marketType !== "rh" && (
            <div className={classes.searchAddress} onClick={changeSearchType}>
              {searchByAddress ? "Search by radius" : "Search for address"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchToolbar;
