import React from 'react';
import IconButton from 'app/components/ui/Buttons/IconButton';
import cn from 'classnames';

import './styles.scss';

const simplePagination = (page, setPage, totalPages) => {
  const clickHandler = (value) => {
    if (value !== page) {
      setPage(value);
    }
  };

  let counter = totalPages;
  const range = [];
  while (counter) {
    range.push(counter);
    counter--;
  }

  return range.reverse().map(value => (
    <div
      key={value}
      className={cn('item page', { active: value === page })}
      onClick={() => clickHandler(value)}
    >
      {value}
    </div>
  ));
};

const complexPagination = (page, setPage, totalPages) => {
  const clickHandler = (value) => {
    if (value !== page) {
      setPage(value);
    }
  };

  if (page < 5) {
    const range = [1, 2, 3, 4, 5];
    return (
      <>
        {range.map(value => (
          <div
            key={value}
            className={cn('item page', { active: value === page })}
            onClick={() => clickHandler(value)}
          >
            {value}
          </div>
        ))}
        <div className="item">...</div>
        <div
          className="item page"
          onClick={() => clickHandler(totalPages)}>
          {totalPages}
        </div>
      </>
    );
  }
  if (page >= 5 && page <= (totalPages - 4)) {
    return (
      <>
        <div
          className="item page"
          onClick={() => clickHandler(1)}>
          1
        </div>
        <div className="item">...</div>
        <div
          className="item page"
          onClick={() => clickHandler(page - 1)}
        >
          {page - 1}
        </div>
        <div
          className="item page active"
        >
          {page}
        </div>
        <div
          className="item page"
          onClick={() => clickHandler(page + 1)}
        >
          {page + 1}
        </div>
        <div className="item">...</div>
        <div
          className="item page"
          onClick={() => clickHandler(totalPages)}
        >
          {totalPages}
        </div>
      </>
    );
  }
  if (page > totalPages - 4) {
    const range = [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    return (
      <>
        <div
          className="item page"
          onClick={() => clickHandler(1)}>
          1
        </div>
        <div className="item">...</div>
        {range.map(value => (
          <div
            key={value}
            className={cn('item page', { active: value === page })}
            onClick={() => clickHandler(value)}
          >
            {value}
          </div>
        ))}
      </>
    );
  }
};

const PaginationSelective = ({ page, setPage, totalPages }) => {
  let content;
  if (totalPages < 8) {
    content = simplePagination(page, setPage, totalPages);
  } else {
    content = complexPagination(page, setPage, totalPages);
  }

  return (
    <>
      {totalPages > 0 &&
      <div className="pagination-selective">
        <IconButton
          icon="chevronLeft"
          variant="round-btn-arrow-left"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        />
        <div className="block-center">
          {content}
        </div>
        <IconButton
          icon="chevronRight"
          variant="round-btn-arrow-right"
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        />
      </div>
      }
    </>
  );
};

export default PaginationSelective;
