import React from "react";
import SmartphoneView from "./smartphone";
import TabletView from "./tablet";

const MobileView = ({ children, mobile, tablet }) => {
  if (mobile) return <SmartphoneView>{children}</SmartphoneView>
  if (tablet) return <TabletView>{children}</TabletView>
  return <>{children}</>
}

export default  MobileView;
