import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

import { MetricsTableRow } from "./MetricsTableRow";
import _ from "lodash";

const useMetricsTableStyles = makeStyles({
  table: {
    width: "100%",
    backgroundColor: "#F7F7F7",
  },
  metric: {
    color: "#333",
    fontWeight: 300,
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "32px !important",
    textTransform: "capitalize",
    padding: "10px 12px",
  },
  metricRow: {
    borderTop: "1px solid #e5e5e5",
  },
});

export const MetricsTable = ({ isRental, metrics }) => {
  const classes = useMetricsTableStyles();

  return (
    <Grid item sm={12} md={6} className="w-100">
      <table className={classes.table}>
        <thead>
          <tr className={classes.metricRow}>
            <th className={classes.metric}>Metric</th>
            <th className={classes.metric}>Value</th>
          </tr>
        </thead>
        <tbody>
          { _.uniqBy(metrics, 'metricTypeId').map(metric => (
            <MetricsTableRow
              metric={metric}
              key={metric.metricType}
              isRental={isRental}
            />
          ))}
        </tbody>
      </table>
    </Grid>
  );
};
