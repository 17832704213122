import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button as PrButton } from 'primereact/components/button/Button';
import { Toast } from 'primereact/toast';
import OfficesService from 'app/api/OfficesService';
import { Box, LinearProgress, withStyles } from '@material-ui/core';
import CalendarUnit from './CalendarItems';
import produce from 'immer';

const StickyHeader = withStyles({
  root: {
    position: 'sticky',
    top: 0,
    padding: '10px 0',
    zIndex: 999,
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },
})(Box)

const baseItems = [
  {text: 'Approve Marketing Material'},
  {text: 'Local Paper Ad'},
  {text: 'Open for Inspection TBA'},
  {text: 'Open House buyer call backs'},
  {text: 'Weekly Report Update'},
  {text: 'Auction Invitations sent'},
  {text: 'Auction buyers contacted'},
  {text: 'Auction Date'},
]

const TimelineSettings = () => {

  const { officeID } = useParams();
  const [loading, setLoading] = useState(false);
  const [calendars, setCalendars] = useState([]);

  const toast = useRef(null);

  useEffect(() => {
    setLoading(true)

    OfficesService.getOffice(officeID).then(resp => {
      const _office = resp.data;
      if (_office && _office.timeline_settings && _office.timeline_settings.length !== 0) {
        setCalendars(_office.timeline_settings)
      } else {
        addCalendarHandler();
      }
      setLoading(false)
    });
  // TODO: FIX
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeID]);

  const saveChanges = () => {
    let _settings = { timeline_settings: calendars }
    OfficesService.updateOffice(officeID, _settings)
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Settings have been successfully updated', life: 3000 });
      })
      .catch(() => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later', life: 4000 });
      })
  };

  const addCalendarHandler = () => {
    let updatedCalendars = produce(calendars, draft => {
      draft.push({title: 'Campaign calendar name', items: baseItems});
    })
    setCalendars(updatedCalendars)
  }

  const onCalendarUpdate = (val, index) => {
    const result = Array.from(calendars)
    result[index] = val;
    setCalendars(result);
  }

  const onCalendarDelete = index => {
    const result = Array.from(calendars)
    result.splice(index, 1);
    setCalendars(result);
  }

  return (
    <div className="card-body pt-4">
      <StickyHeader>
        <h2>Campaign Calendar Items</h2>
        <button
          onClick={saveChanges}
          className="btn btn-primary w-100px"
        >
          Save
        </button>
      </StickyHeader>
      {
        calendars.map((item, index) => (
          <CalendarUnit
            calendar={item}
            onUpdate={val => onCalendarUpdate(val, index)}
            onDelete={() => onCalendarDelete(index)}
            key={index}
          />
        ))
      }
      <PrButton label="Add Calendar" icon="pi pi-plus" className="btn btn-primary mt-5" onClick={addCalendarHandler} />
      <Toast ref={toast} />
      { loading && <LinearProgress color="primary" className="mb-5 mt-5"/>}
    </div>
  )

}

export default TimelineSettings
