import { createAction } from "@reduxjs/toolkit";

export const fetchMessagesList = createAction<any>("messages/messagesList");
export const fetchMessagesListByType = createAction<any>(
  "messages/messagesListByType",
);
export const fetchReadMessage = createAction<any>("messages/readMessage");
export const fetchDeleteMessage = createAction<any>("messages/deleteMessage");
export const fetchReadAllMessages = createAction<any>(
  "messages/readAllMessages",
);
