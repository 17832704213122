import React, { useState, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { makeStyles, Grid, useMediaQuery } from "@material-ui/core";

import BlockContainer from "../AppraisalDetails/BlockContainer";
import HideContainer from "../components/HideContainer";
import {
  updateOurServicesField,
  updateOurServicesServiceProp,
  updateArticleBody,
} from "../../../../redux/reducers/appraisalReducer";
import { AppraisalContext } from "../AppraisalContext";
import { Tabs, HideTab } from "../../Common/CustomizedTabs";
import EditorComponent from "../../Common/EditorComponent";
import useTrackEvent from "../../../hooks/useTrackEvent";
import useArticles from "../../../hooks/useArticles";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.2,
  },
  tabContent: {
    marginTop: 10,
    marginBottom: 10,
    "& .mce-edit-focus": {
      maxHeight: "100% !important",
    },
    "& img": {
      width: "100%",
      maxWidth: "100%",
    },
  },
}));

const TabContent = ({ description, onChange, className }) => {
  const { preview } = useContext(AppraisalContext);
  return (
    <Grid container spacing={3} className={className}>
      <Grid item xs={12} sm={12}>
        <EditorComponent
          type="methodOfSale"
          inline
          value={description}
          onBlur={onChange}
          disabled={preview}
          charactersLimit={1000}
        />
      </Grid>
    </Grid>
  );
};

const OurServices = () => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const ourServices = useSelector(
    ({ appraisal }) => appraisal.ourServices,
    shallowEqual,
  );
  const ourServicesArticles = useArticles({ ourServices: true }).ourServices;
  const [activeTab, setActiveTab] = useState("maximising-your-return");

  const { preview } = useContext(AppraisalContext);

  const hideDetailsHandler = value => {
    dispatch(updateOurServicesField({ value, field: "hidden" }));
  };

  const onTitleUpdate = value => {
    dispatch(updateOurServicesField({ value, field: "title" }));
  };

  const onSubtitleUpdate = value => {
    dispatch(updateOurServicesField({ value, field: "subtitle" }));
  };

  const changeMethodHide = (value, field) => {
    dispatch(updateOurServicesServiceProp({ value, field, prop: "hidden" }));
  };

  const changeActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const onUpdateArticleBody = (id, body) => {
    dispatch(updateArticleBody({ id, body }));
  };

  const classes = useStyles({ preview });

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <HideContainer value={ourServices.hidden} onChange={hideDetailsHandler}>
      <BlockContainer
        defaultTitle={ourServices.title}
        defaultSubtitle={ourServices.subtitle}
        id="our-services"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        hide={ourServices.hidden}
      >
        <div className={ourServices.hidden ? classes.disabled : ""}>
          <div>
            <Tabs
              value={activeTab}
              onChange={changeActiveTab}
              indicatorColor="primary"
              textColor="primary"
              variant={isMobile ? "scrollable" : "fullWidth"}
              scrollButtons={isMobile ? "on" : "auto"}
              centered
            >
              <HideTab
                label={
                  ourServicesArticles?.find(
                    a => a?.slug === "maximising-your-return",
                  )?.title || "Maximising Your Return"
                }
                hide={ourServices.services.maximisingYourReturn.hidden}
                onHideChange={val =>
                  changeMethodHide(val, "maximisingYourReturn")
                }
                onClick={() =>
                  trackEvent("Our Services tab click", "Maximising Your Return")
                }
                value="maximising-your-return"
              />
              <HideTab
                label={
                  ourServicesArticles?.find(
                    a => a?.slug === "tenancy-selection",
                  )?.title || "Tenancy Selection"
                }
                hide={ourServices.services.tenancySelection.hidden}
                onHideChange={val => changeMethodHide(val, "tenancySelection")}
                onClick={() =>
                  trackEvent("Our Services tab click", "Tenancy Selection")
                }
                value="tenancy-selection"
              />
              <HideTab
                label={
                  ourServicesArticles?.find(a => a?.slug === "ongoing-service")
                    ?.title || "Ongoing service"
                }
                hide={ourServices.services.ongoingService.hidden}
                onHideChange={val => changeMethodHide(val, "ongoingService")}
                onClick={() =>
                  trackEvent("Our Services tab click", "Ongoing service")
                }
                value="ongoing-service"
              />
              <HideTab
                label={
                  ourServicesArticles?.find(
                    a => a?.slug === "your-property-journey",
                  )?.title || "Your property journey"
                }
                hide={ourServices.services.yourPropertyJourney.hidden}
                onHideChange={val =>
                  changeMethodHide(val, "yourPropertyJourney")
                }
                onClick={() =>
                  trackEvent("Our Services tab click", "Your property journey")
                }
                value="your-property-journey"
              />
            </Tabs>
            <TabContent
              className={classes.tabContent}
              description={
                ourServicesArticles?.find(a => a.slug === activeTab)?.body
              }
              onChange={val =>
                onUpdateArticleBody(
                  ourServicesArticles?.find(a => a.slug === activeTab)?._id,
                  val,
                )
              }
            />
          </div>
        </div>
      </BlockContainer>
    </HideContainer>
  );
};

export default OurServices;
