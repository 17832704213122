import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppraisalsService from 'app/api/AppraisalsService';

const ActionButtons = ({ status, rowId, archiveHandler }) => {
  const archiveItem = async id => {
    await AppraisalsService.archiveAppraisal(id);
    archiveHandler && archiveHandler(id);
  };

  const unarchiveItem = async id => {
    await AppraisalsService.unarchiveAppraisal(id);
    archiveHandler && archiveHandler(id);
  }

  return (
    <ul className="navi navi-hover">
      {status === 0 ?
        <li className="navi-item">
          <Link to={`/proposals/${rowId}/metrics`} className="navi-link">
            <span className="navi-text">View Report</span>
          </Link>
        </li>
        : null}
      {status !== 3 ? (
        <>
          <li className="navi-item">
            <Link to={`/proposal-view/${rowId}?disableMetrics=true`} className="navi-link" target="_blank">
              <span className="navi-text">Preview</span>
            </Link>
          </li>
          <li className="navi-item" onClick={() => archiveItem(rowId)}>
            <div className="navi-link cursor-pointer">
              <span className="navi-text">Archive</span>
            </div>
          </li>
          <li className="navi-item">
            <Link to={`/proposals/${rowId}/edit`} className="navi-link">
              <span className="navi-text">Edit</span>
            </Link>
          </li>
        </>
      ) : (
        <li className="navi-item" onClick={() => unarchiveItem(rowId)}>
          <div className="navi-link cursor-pointer">
            <span className="navi-text">Unarchive</span>
          </div>
        </li>
      )}
    </ul>
  );
};

ActionButtons.propTypes = {
  status: PropTypes.number,
  rowId: PropTypes.string.isRequired,
  archiveHandler: PropTypes.func,
};

ActionButtons.defaultProps = {
  status: 0,
  archiveHandler: null,
};

export default ActionButtons;
