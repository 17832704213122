import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { updateNextSteps } from "../../../../../redux/reducers";
import { updateVendorWithCoverLetter } from "../../../../../redux/actions";

const useStyles = makeStyles(() => ({
  inputDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const FlkForm = () => {
  const dispatch = useDispatch();
  const propertyOwners = useSelector(
    state => state.appraisal.owners,
    shallowEqual,
  );
  const contacts = useSelector(
    state => state.appraisal.nextSteps?.flkContacts,
    shallowEqual,
  );
  const [localOwners, setLocalOwners] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!contacts || contacts.length === 0) {
      const newContacts = propertyOwners.map(owner => ({
        firstName: owner.firstName,
        lastName: owner.lastName,
        flkUrl: "",
      }));
      dispatch(updateNextSteps({ flkContacts: newContacts }));
    }
    setLocalOwners(
      contacts.map(() => ({ firstName: "", lastName: "", flkUrl: "" })),
    );
  }, [propertyOwners, contacts]);

  //   const handleChange = (index, field, value) => {
  //     const updatedLocalState = produce(localOwners, draft => {
  //       draft[index][field] = value;
  //     });
  //     setLocalOwners(updatedLocalState);
  //   };

  const handleBlur = (index, field, value) => {
    const newContacts = produce(contacts, draft => {
      draft[index][field] = value;
    });

    const nameDisplay = newContacts
      .reduce(
        (acc, curr) => acc + ` ${curr.firstName} ${curr.lastName},`.trim(),
        "",
      )
      .replace(/,$/g, "");

    dispatch(
      updateVendorWithCoverLetter({
        name: nameDisplay,
      }),
    );

    dispatch(updateNextSteps({ flkContacts: newContacts }));
  };

  return (
    <div className="card-body">
      {contacts.map((owner, index) => {
        return (
          <div className={classes.inputDiv} key={index}>
            <Typography className="mb-3 my-3 mx-4 text-nowrap">
              Recipient {index + 1}:
            </Typography>
            <TextField
              className="mb-3 my-3 mx-4"
              id="owner-f-name"
              fullWidth
              variant="outlined"
              label="First Name"
              size="small"
              value={contacts[index]?.firstName}
              InputProps={{ className: classes.input }}
              onChange={e => handleBlur(index, "firstName", e.target.value)}
              onBlur={e => handleBlur(index, "firstName", e.target.value)}
            />
            <TextField
              className="mb-3 my-3 mx-4"
              id="owner-l-name"
              fullWidth
              variant="outlined"
              label="Last Name"
              size="small"
              value={contacts[index]?.lastName}
              InputProps={{ className: classes.input }}
              onChange={e => handleBlur(index, "lastName", e.target.value)}
              onBlur={e => handleBlur(index, "lastName", e.target.value)}
            />
            <TextField
              className="mb-3 my-3 mx-4"
              id="vendor-email"
              fullWidth
              variant="outlined"
              label="FLK E-sign URL link"
              size="small"
              value={contacts[index]?.flkUrl}
              InputProps={{ className: classes.input }}
              onChange={e => handleBlur(index, "flkUrl", e.target.value)}
              onBlur={e => handleBlur(index, "flkUrl", e.target.value)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FlkForm;
