import React, { useEffect, useRef } from "react";
import MetricsService from "../../api/MetricsService";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment, { Moment } from "moment";
import { useIdleTimer } from "react-idle-timer";
import {getMetricData, getRecipientData} from "../../../redux/selectors";
import {useLocation, useParams} from "react-router-dom";
import AppraisalsService from "../../api/AppraisalsService";

interface ContextProps {
  sessionId: string | undefined;
  disableMetrics: boolean;
}

const defaultValue = {
  sessionId: undefined,
  disableMetrics: false,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const MetricContext = React.createContext<ContextProps>(defaultValue);
interface PreviewProps {
  proposalId: string;
}

const PreviewMetricHOC = ({ children = <></> }) => {
  const { id, officeId } = useSelector(getMetricData);
  const contacts = useSelector(getRecipientData);
  let recipient = {
    firstName: undefined,
    lastName: undefined,
  };
  let { proposalId } = useParams<PreviewProps>();
  const flkLink = proposalId.split('-')[1];
  if (flkLink) {
    recipient = contacts.find((c: { flkUrl: string; }) => c.flkUrl === atob(flkLink));
  }
  let sessionStart = useRef<Moment>();
  let sessionId = useRef<string>();
  let intervalID = useRef<number>();
  let query = useQuery();
  const disableByQuery = query.get("disableMetrics") === "true";
  const disableByLS = localStorage.getItem("preview") === "1";
  const disableMetrics = disableByQuery || disableByLS || !id;

  const handleOnIdle = (event: any) => {
    if (disableMetrics) return;
    console.log("user is idle", event);
    clearInterval(intervalID.current);
  };

  const handleOnActive = (event: any) => {
    if (disableMetrics) return;
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event: any) => {
    if (disableMetrics) return;
    console.log("user did something", event);
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 2,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    const postMetricAndSetInterval = () => {

      // Post the session metric immediately
      postMetric();

      // Set up an interval to post metrics every 5 seconds
      intervalID.current = window.setInterval(postMetric, 5000);
    };

    // Start the session
    if (!disableMetrics && officeId) {
      sessionStart.current = moment();
      sessionId.current = uuidv4();

      AppraisalsService.smsToAgent(id).then(() =>
        console.log("Sms has sent to agent"),
      );
      // Call the function to post the metric and start the interval
      postMetricAndSetInterval();
    }

    // Cleanup function to clear the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalID.current);
  }, [id, officeId, disableMetrics]);

// Function to post the session metric
  const postMetric = () => {
    const now = moment();
    const duration = moment.duration(now.diff(sessionStart.current)).as("seconds");
    MetricsService.postMetric({
      appraisal: id,
      office: officeId,
      sessionId: sessionId.current,
      type: "session",
      recipient: recipient?.firstName + " " + recipient?.lastName,
      duration,
    }).then();
  };


  return (
    <MetricContext.Provider
      value={{ sessionId: sessionId.current, disableMetrics }}
    >
      {children}
    </MetricContext.Provider>
  );
};

export default PreviewMetricHOC;
