import { withStyles } from "@material-ui/core";

const PropertiesManualListStyles = withStyles((theme: any) => ({
  container: {
    width: "700px",
    height: "auto",
    maxWidth: "none",
  },
  confirmBlock: {
    maxWidth: "700px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".3s ease",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
    "&>img": {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
  overlayImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    display: "block",
    height: "auto",
    position: "relative",
    overflow: "hidden",
  },
  image: {
    objectFit: "cover",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  propertiesContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  imageBlock: {
    width: "35%",
    margin: "20px 20px 0 0",
  },
  addressBlockInWithImageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "65%",
    "& input": {
      width: "100%",
    },
  },
  addressInputs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "120px",
    "& input": {
      height: "45px",
    },
  },
  infoBlockInWithImageBlock: {
    display: "flex",
    justifyContent: "space-between",
    "& .inputContainer": {
      width: "30%",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    paddingTop: "20px",
    paddingRight: "10px",
    width: "100%",
    "& input": {
      width: "100%",
      height: "35px",
      border: "1px solid",
      borderColor: theme.palette.border.gray,
      background: "#fff",
      lineHeight: "17px",
      fontSize: 13,
      padding: "13px 13px",
      outline: "none",
    },
    "& label": {
      lineHeight: "17px",
      fontWeight: "bold",
      fontSize: 14,
    },
    "&>svg": {
      position: "absolute",
      right: 13,
      bottom: 22.5,
      transform: "translateY(50%)",
    },
  },
  title: {
    color: "#333",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "21px",
  },
  addressBlock: {
    maxHeight: "70px",
  },
}));

export default PropertiesManualListStyles;

