import React from "react";
import { makeStyles, Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import MarketReport from "../MarketReport";

import {
  updateTargetMarket,
  updateExecutiveSummary,
  updateDisclaimer,
} from "redux/reducers";
import {
  getTargetMarketData,
  getExecutiveSummaryData,
  getDisclaimerData,
} from "redux/selectors";

import useArticles from "app/hooks/useArticlesByTypes";

import CoverletterImage from "../CoverletterImage";
import ArticleSection from "../ArticleSection";
import Coverletter from "../Coverletter";
import { GenericVideoModule } from "../GenericVideo";
import SalesProcess from "../SalesProcess";
import Marketing from "../Marketing";
import ServicesFees from "../ServicesFees";

import TeamDetails from "../TeamDetails";
import NextSteps from "../NextSteps";

import CampaignCalendar from "../CampaignCalendar";

// import SaleLeaseProcess from "../../Commercial/SaleLeaseProcess";
import Comparable from "../../Comparables/Comparable";

import Separator from "../../CommonComponents/Separator";
import LocationOverView from "../../Commercial/LocationOverview";
import { useCommercial } from "app/hooks";

const useStyles = makeStyles(() => ({
  smallFontSize: {
    "& .mce-content-body p": {
      fontSize: 12,
      lineHeight: "18px",
      textAlign: "justify",
    },
  },
  customCommercialTable: {
    "& .mce-content-body table": {
      border: "none",
      "& td": {
        border: "none",
      },
      "& tr": {
        border: "none",
        borderBottom: "1px solid #d9c672",
      },
    },
    "& a": {
      color: "#ffd200",
    },
  },
  customStyleArticle: {
    "& ol": {
      paddingLeft: "15px",
    },
  },
}));

const CommercialListingProposal = () => {
  const classes = useStyles();
  const { isCommercial } = useCommercial();

  const targetMarketSection = useSelector(getTargetMarketData);
  const executiveSummarySection = useSelector(getExecutiveSummaryData);
  const disclaimerSection = useSelector(getDisclaimerData);

  const articles = useArticles(["common"]);
  return (
    <div className={classes.customCommercialTable}>
      <div id="commercial-coverletter">
        <CoverletterImage isCommercial />
        <Container
          maxWidth="md"
          component="div"
          className="px-10"
          id="appraisal-details"
        >
          <Coverletter />
          <ArticleSection
            id="commercial-executiveSummary"
            sectionData={executiveSummarySection}
            withoutSubTitle
            onUpdateSectionData={updateExecutiveSummary}
            article={articles?.common?.find(
              item => item.slug === "executive-summary",
            )}
          />
          <GenericVideoModule />
          <Separator />
        </Container>
        <LocationOverView />
        <Container maxWidth="md" component="div" className="px-10 pb-1">
          <MarketReport withoutSubTitle withoutSuburb />
          <Comparable withoutSubTitle />
          <ArticleSection
            id="target-market"
            sectionData={targetMarketSection}
            withoutSubTitle
            onUpdateSectionData={updateTargetMarket}
            article={articles?.common?.find(
              item => item.slug === "target-market",
            )}
          />
          <SalesProcess withoutSubTitle />
          <Marketing isCommercial={isCommercial} />
          <CampaignCalendar withoutSubTitle />
          {/* <SaleLeaseProcess /> */}
          <ServicesFees withoutSubTitle />
          <TeamDetails
            isHideContainer={true}
            isCommercial={isCommercial}
            awards
            difference
            profile
            community
            history
            withoutCommercialStyle={true}
          />
          <NextSteps withoutSubTitle withoutSubSection />
          <ArticleSection
            id="commercial-disclaimer"
            className={classes.customStyleArticle}
            sectionData={disclaimerSection}
            withoutSubTitle
            onUpdateSectionData={updateDisclaimer}
            article={articles?.common?.find(item => item.slug === "disclaimer")}
          />
        </Container>
      </div>
    </div>
  );
};
export default CommercialListingProposal;
