import React, { useEffect, useState } from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import { Chart } from "chart.js";

import { MetricsTable } from "./MetricsTable";
import { SuburbMedianPrice } from "./SuburbMedianPrice";
import { useRef } from "react";

const useStyles = makeStyles(theme => ({
  chart: {
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    maxHeight: "350px",
  },
}));

const SuburbProfile = ({ statistics, isRental, type, isCommercial }) => {
  const classes = useStyles();
  const chartRef = useRef();

  const [year, setYear] = useState("");
  const [price, setPrice] = useState("");
  const [chart, setChart] = useState();

  const statisticChart = statistics?.charts[type];
  const metrics = statistics?.metrics?.filter(
    item => item.propertyType === type,
  );
  const statisticChartData = statisticChart?.data;
  const statisticChartLabels = statisticChart?.labels;

  // TODO: Refactoring
  // Here is the problem: refactoring
  useEffect(() => {
    if (chart) {
      chart.destroy();
    }

    if (!chartRef.current) {
      return;
    }

    const ctx = chartRef.current.getContext("2d");
    if (statisticChart && statisticChartData?.length > 0) {
      setChart(
        new Chart(ctx, {
          type: "line",
          data: {
            labels: statisticChartLabels,
            datasets: [
              {
                pointRadius: 6,
                pointHoverRadius: 6,
                backgroundColor: "rgba(0,0,0,0.03)",
                pointBackgroundColor: "white",
                pointHoverBackgroundColor: isCommercial ? "#F7CE46" : "#FAB633",
                pointBorderColor: "#45484D",
                borderColor: "#45484D",
                data: statisticChartData,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            hover: {
              mode: "nearest",
              intersect: false,
              axis: "x",
            },
            scales: {
              y: {
                display: false,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                mode: "nearest",
                intersect: false,
                axis: "x",
                enabled: false,
                external: ({ tooltip }) => {
                  if (tooltip.dataPoints !== undefined) {
                    setYear(tooltip.dataPoints[0].label);
                    setPrice(tooltip.dataPoints[0].formattedValue);
                  }
                },
              },
            },
          },
        }),
      );
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticChartData, statisticChartLabels, isCommercial]);

  // TODO: Refactoring
  useEffect(() => {
    if ((!year || !price) && chart?.data) {
      setYear(chart.data.labels[chart.data.labels.length - 1]);
      setPrice(
        chart.data.datasets[0].data[chart.data.datasets[0].data.length - 1],
      );
    }
  }, [chart, price, year]);

  return (
    <Grid container spacing={3}>
      {metrics?.length > 0 && (
        <MetricsTable isRental={isRental} metrics={metrics} />
      )}
      { statisticChart && statisticChartData?.length > 0 && (
        <Grid item sm={12} md={6} className="w-100">
          <Box className={classes.chart}>
            <canvas ref={chartRef} />
          </Box>
          {type && metrics && (
            <SuburbMedianPrice
              year={year}
              disclaimer={statisticChart?.disclaimer}
              price={price}
              isRental={isRental}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SuburbProfile;
