import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import BlockContainer from "../AppraisalDetails/BlockContainer";
import HideContainer from "../components/HideContainer";
import { updateMeta } from "../../../../redux/reducers/appraisalReducer";
import useArticles from "../../../hooks/useArticles";


const OutBroker = () => {

  const dispatch = useDispatch();
  const ourBrokerHidden = useSelector(({ appraisal }) => appraisal.meta.ourBrokerHidden, shallowEqual);
  const ourBrokerTitle = useSelector(({ appraisal }) => appraisal.meta.ourBrokerTitle, shallowEqual);
  const ourBrokerSubtitle = useSelector(({ appraisal }) => appraisal.meta.ourBrokerSubtitle, shallowEqual);

  const ourBrokerArticle = useArticles({ ourBroker: true }).ourBroker?.[0]

  const hideDetailsHandler = val => {
    dispatch(updateMeta({ ourBrokerHidden: val} ));
  }

  const onTitleUpdate = (title) => {
    dispatch(updateMeta({ourBrokerTitle: title}))
  }

  const onSubtitleUpdate = (subtitle) => {
    dispatch(updateMeta({ourBrokerSubtitle: subtitle}))
  }

  if (!ourBrokerArticle) return "";
  return (
    <HideContainer value={ourBrokerHidden} onChange={hideDetailsHandler}>
      <BlockContainer
        defaultTitle={ourBrokerTitle}
        defaultSubtitle={ourBrokerSubtitle}
        id="our-broker"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        hide={ourBrokerHidden}
      >
        <div className="mb-10">
          <div className='mce-content-body'
            dangerouslySetInnerHTML={{
              __html:
              ourBrokerArticle.body
            }}
          />
        </div>
      </BlockContainer>
    </HideContainer>
  );
};

export default OutBroker;
