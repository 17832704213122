import React, { useState, useContext, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  Grid,
  useMediaQuery,
  TextareaAutosize,
} from "@material-ui/core";

import BlockContainer from "../AppraisalDetails/BlockContainer";
import HideContainer from "../components/HideContainer";
import {
  updateMethodOfSaleMethodProp,
  updateMethodOfSaleField,
  updateArticleBody,
  updateMethodOfSale,
} from "redux/reducers/appraisalReducer";
import { AppraisalContext } from "../AppraisalContext";
import { Tabs, HideTab } from "../../Common/CustomizedTabs";
import EditorComponent from "../../Common/EditorComponent";
import useTrackEvent from "../../../hooks/useTrackEvent";
import useArticles from "../../../hooks/useArticles";
import MobileTabs from "../../Common/MobileTabs";
import { replaceKeysOfObject } from "../../Common/Helpers";

import photoForArticles from "../../../assets/images/marketing/visuals.jpeg";

import { getImageUrl } from "shared";
import { useCommercial } from "app/hooks";
import { useNz } from "../../../hooks/useNz";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: "auto",
    width: 400,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.2,
  },
  select: ({ isCommercial }) => ({
    padding: "0 !important",
    fontSize: "2em",
    fontWeight: "500",
    lineHeight: "2.5em !important",
    background: "#fff",
    border: `2px solid ${isCommercial ? "#ffd200" : "#FFB300"}`,
    borderRadius: "0",
    color: isCommercial ? "#ffd200" : "#FFB300",
  }),
  icon: ({ preview, isCommercial }) => ({
    color: isCommercial ? "#ffd200" : "#FFB300",
    display: preview ? "none" : "block",
    marginRight: "10px",
    fontSize: 25,
  }),
  tabContent: {
    marginTop: 10,
    marginBottom: 10,
    "& .mce-edit-focus": {
      maxHeight: "100% !important",
    },
    "& img": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  methodTextRecommendation: {
    border: "none",
    resize: "none",
    background: "transparent",
    width: "100%",
    "&:disabled, &:focus": {
      opacity: 1 + " !important",
    },
    "&:hover": {
      opacity: 0.6,
    },
    color: "#333",
    fontSize: "15px",
    lineHeight: "21px",
    textAlign: "center",
    fontWeight: 800,
    marginTop: "2em",
  },
}));

const TabContent = ({ description, onChange, img, alt, className }) => {
  const { preview } = useContext(AppraisalContext);
  return (
    <Grid container spacing={3} className={className}>
      <Grid item xs={12} sm={7}>
        <EditorComponent
          type="methodOfSale"
          inline
          value={description}
          onBlur={onChange}
          disabled={preview}
          charactersLimit={1000}
          hardLimit
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <img src={img} alt={alt} />
      </Grid>
    </Grid>
  );
};

const methodsOfSaleSlugs = {
  "Private Treaty": "private-treaty",
  Auction: "auction",
  "Off Market": "off-market",
  "Expression Of Interest": "expression-of-interest",
  Tender: "tender",
};

const SalesProcess = ({ withoutSubTitle, noMethodSaleRecommendation }) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const methodOfSale = useSelector(
    ({ appraisal }) => appraisal.methodOfSale,
    shallowEqual,
  );
  const articles = useSelector(
    ({ appraisal }) => appraisal.articles,
    shallowEqual,
  );
  const defaultMethodOfSalesArticles = useArticles({ methodsOfSale: true })
    .methodsOfSale;
  const [methodOfSalesArticles, setMethodOfSalesArticles] = useState([]);
  const [activeTab, setActiveTab] = useState(
    defaultMethodOfSalesArticles?.find(article => article.title === methodOfSale?.selectedMethod)?.slug,
  );

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const { preview } = useContext(AppraisalContext);
  const { isCommercial } = useCommercial();
  const { isNz } = useNz();

  useEffect(() => {
    setMethodOfSalesArticles(defaultMethodOfSalesArticles);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles]);

  useEffect(() => {
    if (isMobile) {
      const chosenMethod = defaultMethodOfSalesArticles?.find(
        method =>
          method.title === methodOfSale?.selectedMethod,
      );
      let filteredArrayOfMethods = defaultMethodOfSalesArticles?.filter(
        method =>
          method.title !== methodOfSale?.selectedMethod,
      );
      filteredArrayOfMethods?.unshift({ ...chosenMethod });
      setMethodOfSalesArticles(filteredArrayOfMethods);
      setActiveTab(methodsOfSaleSlugs[methodOfSale?.selectedMethod]);
    } else if (preview && defaultMethodOfSalesArticles && methodOfSale) {
      setActiveTab(defaultMethodOfSalesArticles.find(article => article.title === methodOfSale?.selectedMethod)?.slug);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview, methodOfSale.selectedMethod, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      if (!methodOfSale?.methods.privateTreaty.hidden) {
        setActiveTab(methodsOfSaleSlugs["Private Treaty"]);
        return;
      }
      if (
        methodOfSale?.methods.privateTreaty.hidden &&
        !methodOfSale?.methods.auction.hidden
      ) {
        setActiveTab(methodsOfSaleSlugs["Auction"]);
        return;
      }
      if (
        methodOfSale?.methods.auction.hidden &&
        !methodOfSale?.methods.offMarketSale.hidden
      ) {
        setActiveTab(methodsOfSaleSlugs["Off Market"]);
        return;
      }
      if (
        methodOfSale?.methods.offMarketSale.hidden &&
        !methodOfSale?.methods.eoi?.hidden
      ) {
        setActiveTab(methodsOfSaleSlugs["Expression Of Interest"]);
        return;
      }
      if (methodOfSale?.methods.tender.hidden) {
        setActiveTab(methodsOfSaleSlugs["Tender"]);
      }
    }
  }, [methodOfSale, isMobile]);

  const updateSaleMethod = event => {
    dispatch(
      updateMethodOfSaleField({
        value: event.target.value,
        field: "selectedMethod",
      }),
    );
  };

  const hideDetailsHandler = value => {
    dispatch(updateMethodOfSaleField({ value, field: "hidden" }));
  };

  const onTitleUpdate = value => {
    dispatch(updateMethodOfSaleField({ value, field: "title" }));
  };

  const onSubtitleUpdate = value => {
    dispatch(updateMethodOfSaleField({ value, field: "subtitle" }));
  };

  const changeMethodHide = (value, field) => {
    dispatch(updateMethodOfSaleMethodProp({ value, field, prop: "hidden" }));
  };

  const changeActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };

  const onUpdateArticleBody = (id, body) => {
    dispatch(updateArticleBody({ id, body }));
  };

  const updateRecommendationText = ({ target }) => {
    const value = target?.value || "";
    dispatch(
      updateMethodOfSaleField({ value, field: "methodRecommendationText" }),
    );
  };

  const classes = useStyles({ preview, isCommercial });

  const changeMobileTab = article => {
    setActiveTab(currentValue =>
      currentValue === article.slug ? null : article.slug,
    );
    trackEvent("Method of Sale tab click", article.title);
  };

  const ActiveTab = ({ article }) =>
    !article ? (
      ""
    ) : (
      <TabContent
        className={classes.tabContent}
        description={article.body}
        onChange={val => onUpdateArticleBody(article._id, val)}
        img={getImageUrl(article.tabImage)}
        alt={article.title}
      />
    );

  const hiddensTabs = replaceKeysOfObject(methodOfSale.methods, {
    offMarketSale: "off-market",
    eoi: "expression-of-interest",
    privateTreaty: "private-treaty",
  });

  const renderImage = url => {
    const image = url && getImageUrl(url);
    return image || photoForArticles;
  };
  return (
    <HideContainer value={methodOfSale.hidden} onChange={hideDetailsHandler}>
      <BlockContainer
        defaultTitle={methodOfSale.title}
        defaultSubtitle={methodOfSale.subtitle}
        withoutSubTitle={withoutSubTitle}
        id="method-of-sale"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        hide={methodOfSale.hidden}
      >
        <div className={methodOfSale.hidden ? classes.disabled : ""}>
          {isMobile ? (
            <MobileTabs
              active={activeTab}
              onChange={changeMobileTab}
              articles={methodOfSalesArticles || []}
              body={data => <ActiveTab article={data} />}
              hiddens={hiddensTabs}
            />
          ) : (
            <div>
              <Tabs
                value={activeTab}
                onChange={changeActiveTab}
                indicatorColor="primary"
                textColor="primary"
                variant={isMobile ? "scrollable" : "fullWidth"}
                scrollButtons={isMobile ? "on" : "auto"}
                centered
              >
                <HideTab
                  label={
                    methodOfSalesArticles?.find(
                      a => a.slug === "private-treaty",
                    )?.title || "Private Treaty"
                  }
                  hide={methodOfSale.methods.privateTreaty.hidden}
                  onHideChange={val => changeMethodHide(val, "privateTreaty")}
                  onClick={() =>
                    trackEvent("Method of Sale tab click", "Private Treaty")
                  }
                  value={methodsOfSaleSlugs["Private Treaty"]}
                />
                <HideTab
                  label={
                    methodOfSalesArticles?.find(
                      a => a.slug === "auction",
                    )?.title || "Auction"
                  }
                  hide={methodOfSale.methods.auction.hidden}
                  onHideChange={val => changeMethodHide(val, "auction")}
                  onClick={() =>
                    trackEvent("Method of Sale tab click", "Auction")
                  }
                  value={methodsOfSaleSlugs["Auction"]}
                />
                <HideTab
                  label={
                    methodOfSalesArticles?.find(a => a.slug === "off-market")
                      ?.title || "Off Market"
                  }
                  hide={methodOfSale.methods.offMarketSale.hidden}
                  onHideChange={val => changeMethodHide(val, "offMarketSale")}
                  onClick={() =>
                    trackEvent("Method of Sale tab click", "Off Market")
                  }
                  value={methodsOfSaleSlugs["Off Market"]}
                />
                <HideTab
                  label={
                    methodOfSalesArticles?.find(
                      a => a.slug === "expression-of-interest",
                    )?.title || "EOI"
                  }
                  hide={methodOfSale.methods.eoi.hidden}
                  onHideChange={val => changeMethodHide(val, "eoi")}
                  onClick={() => trackEvent("Method of Sale tab click", "EOI")}
                  value={methodsOfSaleSlugs["Expression Of Interest"]}
                />
                <HideTab
                  label={
                    methodOfSalesArticles?.find(a => a.slug === "tender")
                      ?.title || "Tender"
                  }
                  hide={methodOfSale.methods.tender.hidden}
                  onHideChange={val => changeMethodHide(val, "tender")}
                  onClick={() =>
                    trackEvent("Method of Sale tab click", "Tender")
                  }
                  value={methodsOfSaleSlugs["Tender"]}
                />
              </Tabs>
              <TabContent
                className={classes.tabContent}
                description={
                  methodOfSalesArticles?.find(a => a.slug === activeTab)?.body
                }
                onChange={val =>
                  onUpdateArticleBody(
                    methodOfSalesArticles?.find(a => a.slug === activeTab)?._id,
                    val,
                  )
                }
                img={renderImage(
                  methodOfSalesArticles?.find(a => a.slug === activeTab)
                    ?.tabImage,
                )}
                alt={activeTab}
              />
            </div>
          )}
          <HideContainer
            title="Hide Sub Section"
            value={methodOfSale.subSectionHidden}
            onChange={val =>
              dispatch(updateMethodOfSale({ subSectionHidden: val }))
            }
            removed={noMethodSaleRecommendation}
          >
            <div>
              <TextareaAutosize
                defaultValue={methodOfSale.methodRecommendationText}
                disabled={preview}
                onBlur={updateRecommendationText}
                className={classes.methodTextRecommendation}
              />
              <FormControl align="center" className={classes.formControl}>
                <Select
                  disabled={preview}
                  disableUnderline={true}
                  value={methodOfSale.selectedMethod}
                  onChange={updateSaleMethod}
                  classes={{
                    icon: classes.icon,
                    select: classes.select,
                  }}
                >
                  <MenuItem
                    disabled={methodOfSale.methods.privateTreaty.hidden}
                    value={
                      methodOfSalesArticles?.find(
                        a => a.slug === "private-treaty",
                      )?.title || "Private Treaty"
                    }
                  >
                    {methodOfSalesArticles &&
                      (methodOfSalesArticles?.find(
                        a => a.slug === "private-treaty",
                      )?.title ||
                        "Private Treaty")}
                  </MenuItem>
                  <MenuItem
                    disabled={methodOfSale.methods.auction.hidden}
                    value={ methodOfSalesArticles?.find(
                      a => a.slug === "auction",
                    )?.title || "Auction"}
                  >
                    { methodOfSalesArticles?.find(
                      a => a.slug === "auction",
                    )?.title || "Auction"}
                  </MenuItem>
                  <MenuItem
                    disabled={methodOfSale.methods.offMarketSale.hidden}
                    value={isNz ? "By Negotiation" : "Off Market"}
                  >
                    {isNz ? "By Negotiation" : "Off Market"}
                  </MenuItem>
                  <MenuItem
                    disabled={methodOfSale.methods.eoi.hidden}
                    value={
                      methodOfSalesArticles?.find(
                        a => a.slug === "expression-of-interest",
                      )?.title || "Expression Of Interest"
                    }
                  >
                    {methodOfSalesArticles &&
                      (methodOfSalesArticles?.find(
                        a => a.slug === "expression-of-interest",
                      )?.title ||
                        "Expression Of Interest")}
                  </MenuItem>
                  <MenuItem
                    disabled={methodOfSale.methods.tender.hidden}
                    value={
                      methodOfSalesArticles?.find(a => a.slug === "tender")
                        ?.title || "Tender"
                    }
                  >
                    {methodOfSalesArticles &&
                      (methodOfSalesArticles?.find(a => a.slug === "tender")
                        ?.title || "Tender")
                    }
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </HideContainer>
        </div>
      </BlockContainer>
    </HideContainer>
  );
};

export default SalesProcess;
