import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  isLoadingProposalsList: true,
  isLoadingProposalsListWidgets: true,
  isLoadingSentProposalsByLimit: true,
  isLoadingShortProposalList: false,
  proposalsList: {},
  sentProposalsByLimit: {},
  proposalsByType: [],
  proposalsMetrics: {
    currCount: 0,
    prevCount: 0,
  },
  recentProposals: {},
  articles: [],
  averageOpens: null,
  readingTime: {
    avg: null,
    prevAvg: null,
  },
  clicksPercent: {
    currClicks: "",
    prevClicks: "",
  },
  unopenedProposals: {
    total: 0,
    prevTotal: 0,
  },
  sentProposals: {
    total: 0,
    prevTotal: 0,
  },
  shortProposalList: {},
};

export const proposalsSlice = createSlice({
  name: "proposals",
  initialState: defaultState,
  reducers: {
    setIsLoadingProposalsList: (state, action) => {
      state.isLoadingProposalsList = action.payload;
    },
    setIsLoadingProposalsListWidgets: (state, action) => {
      state.isLoadingProposalsListWidgets = action.payload;
    },
    setProposalsList: (state, action) => {
      state.proposalsList = action.payload;
    },
    setIsLoadingProposalsByLimit: (state, action) => {
      state.isLoadingSentProposalsByLimit = action.payload;
    },
    setIsLoadingShortProposalList: (state, action) => {
      state.isLoadingShortProposalList = action.payload;
    },
    setSentProposalsByLimit: (state, action) => {
      state.sentProposalsByLimit = action.payload;
      state.isLoadingSentProposalsByLimit = false;
    },
    setProposalsByType: (state, action) => {
      state.proposalsByType = action.payload;
    },
    setProposalsMetrics: (state, action) => {
      state.proposalsMetrics = action.payload;
    },
    setRecentProposals: (state, action) => {
      state.recentProposals = { ...state.recentProposals, ...(action?.payload || {})};
    },
    setUnopenedProposals: (state, action) => {
      state.unopenedProposals = action.payload;
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setAverageOpens: (state, action) => {
      state.averageOpens = action.payload;
    },
    setReadingTime: (state, action) => {
      state.readingTime = action.payload;
    },
    setClicksPercent: (state, action) => {
      state.clicksPercent = action.payload;
    },
    setProposalsStatistic: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
    setShortProposals: (state, action) => {
      state.shortProposalList = action.payload;
      state.isLoadingShortProposalList = false
    },
  },
});

export const {
  setIsLoadingProposalsList,
  setIsLoadingProposalsListWidgets,
  setProposalsList,
  setIsLoadingProposalsByLimit,
  setSentProposalsByLimit,
  setProposalsByType,
  setProposalsMetrics,
  setRecentProposals,
  setUnopenedProposals,
  setArticles,
  setAverageOpens,
  setReadingTime,
  setClicksPercent,
  setProposalsStatistic,
  setShortProposals,
  setIsLoadingShortProposalList,
} = proposalsSlice.actions;

export default proposalsSlice.reducer;
