import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "app/components/ui/Buttons/Button";
import ArrowIcon from "../../assets/images/arrow.svg";
import { Modal } from "../../components/Modal/index";
import MessageContent from "./MessageContent";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "467px",
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
  titleBlock: {
    padding: "32px 32px 0 32px",
  },
  icon: {
    marginBottom: "17px",
  },
  title: {
    marginRight: "20px",
    fontSize: "32px",
    lineHeight: "36px",
    padding: "17px 0",
  },
  button: {
    padding: "12px 16px",
    height: "40px",
    fontWeight: "bold",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "16px",
  },
  cancelButton: {
    width: "73px",
    background: "#F4F4F4",
    marginRight: "8px",
  },
  submitButton: {
    width: "139px",
    color: "#FBFBFB",
    background: "#FDB818",
  },
  messageInfo: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "20px 32px 20px 32px",
  },
  messageContent: {
    maxHeight: "300px",
    overflowY: "auto",
  },
  closeButton: {
    top: "32px",
    right: "32px",
    border: "none",
  },
});

interface ReviewMessageProps {
  data: {
    seen: Array<string>;
    content: string;
    showcaseNotification: boolean;
    sender: {
      email: string;
      name: string;
      phone: string;
      office: string;
    };
    to: string;
    message_unvisible_to: [];
  } | null;
  onCancel(): void;
  onConfirm(value: any): void;
}

const ReviewMessage = ({ data, onCancel, onConfirm }: ReviewMessageProps) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={true}
        onClose={onCancel}
        buttonClassname={classes.closeButton}
      >
        <div className={classes.container}>
          <div>
            <div className={classes.titleBlock}>
              <div className={classes.icon}>
                <img src={ArrowIcon} alt="arrow"></img>
              </div>
              <span className={classes.title}>Review Update</span>
            </div>
            <hr />
          </div>
          <div className={classes.messageInfo}>
            <MessageContent
              data={data}
              isUnread
              onHandleMessage={() => null}
              bodyClass={classes.messageContent}
            />
            <div className="d-flex justify-content-end">
              <Button
                className={`${classes.cancelButton} ${classes.button}`}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <div>
                <Button
                  disabled={!data?.to}
                  className={`${classes.submitButton} ${classes.button}`}
                  onClick={() => onConfirm(data)}
                >
                  Confirm & publish
                </Button>
                {!data?.to && <div>* Please select an audience</div>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReviewMessage;
