/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  custom: {
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  disabled: {
    opacity: .5,
    cursor: 'default !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
}));

export const DropdownCustomToggler = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const clickHandler = (e) => {
    e.preventDefault();
    if (!props.disabled) {
      props.onClick(e);
    }
  };

  return (
    <a
      ref={ref}
      className={cn(`btn btn-sm btn-icon ${classes.custom}`, { [classes.disabled]: props.disabled })}
      onClick={clickHandler}
    >
      {props.children}
    </a>
  );
});
