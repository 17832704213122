import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import { fetchLeaders } from "redux/actions";
import {
  getWeeks,
  getLeaders,
  getIsLoadingLeaders,
} from "redux/selectors/dashboardSelector";
import { AGENTS } from "app/constants/commonConstants";
import { NoData } from "app/components/NoData";
import Loader from "app/components/ui/Loader";

import LeaderChart from "./LeaderChart";
import List from "../List";

const LeadersList = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getIsLoadingLeaders);
  const weeks = useSelector(getWeeks);
  const leaders = useSelector(getLeaders);

  useEffect(() => {
    dispatch(fetchLeaders({ weeks, listType: AGENTS }));
  }, [weeks, dispatch]);

  return (
    <div className="leaders">
      <h2 className="header-title horizontal-separator leaderboard-padding">
        See how the team's going
      </h2>
      <div className="leaders-list-wrapper">
        {isLoading ? (
          <Loader />
        ) : !leaders.length ? (
          <Box mt={1}>
            <NoData />
          </Box>
        ) : (
          <List listType={AGENTS} leaders={leaders} renderItem={LeaderChart} />
        )}
      </div>
    </div>
  );
};

export default LeadersList;
