import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Container } from "@material-ui/core";

import EditorComponent from "../../../Common/EditorComponent";
import MapWithProperty from "../../../Common/GoogleMaps";

import { updateCommercialField } from "redux/reducers";
import HideContainer from "../../components/HideContainer";
import Separator from "../../CommonComponents/Separator";
import BlockContainer from "../../AppraisalDetails/BlockContainer";
import Infographics from "../Infographics";

const LocationOverView = ({ id = null, infographicsFolders, withInfographicsSelection }) => {
  const dispatch = useDispatch();
  const { lat, lon } = useSelector(({ appraisal }) => appraisal.property.coordinates, shallowEqual);
  const locationOverview = useSelector(({ appraisal }) => appraisal.commercial.locationOverview, shallowEqual);

  const onChangeSection = (field, data) => {
    const newData = { ...locationOverview, [field]: data };
    dispatch(updateCommercialField({ data: newData, name: "locationOverview" }))
  }

  const onDescriptionUpdate = val => onChangeSection('body', val);
  const onTitleUpdate = val => onChangeSection('title', val);
  const onHiddenUpdate = val => onChangeSection('hidden', val);
  const onInfographicsUpdate = val => onChangeSection('infographics', val);

  return (
    <>
      <HideContainer
        value={locationOverview.hidden}
        onChange={onHiddenUpdate}
      >
        <BlockContainer
          id={id || "commercial-locationOverview"}
          defaultTitle={locationOverview.title}
          onTitleUpdate={onTitleUpdate}
          hide={locationOverview.hidden}
          withoutSubTitle
          separator={false}
        >
          <MapWithProperty
            lat={lat}
            lng={lon}
          />
          <div className="min-h-15px" />
          <Container maxWidth="md" component="div" className="px-10 pb-1 mt-7">
            <EditorComponent
              inline
              type="coverletter"
              value={locationOverview.body}
              onBlur={onDescriptionUpdate}
              placeholder="Enter any description here..."
              charactersLimit={1200}
            />
            <Infographics
              infographics={locationOverview.infographics}
              onChange={onInfographicsUpdate}
              infographicsFolders={infographicsFolders}
              withInfographicsSelection={withInfographicsSelection}
            />
            <Separator />
          </Container>
        </BlockContainer>
      </HideContainer>
    </>
  );
};

export default LocationOverView;
