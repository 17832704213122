import React from "react";
import { Box, Typography } from "@material-ui/core";

import MetricDown from "app/assets/images/dashboardMetrics/down.svg";
import MetricUp from "app/assets/images/dashboardMetrics/up.svg";
import MetricStatic from "app/assets/images/dashboardMetrics/static.svg";

export const WidgetMetric = ({ isUp, message, isChanged }) => {
  return (
    <Box display="flex" alignItems="center" py={1}>
      <Box mr={1}>
        <img
          src={isChanged ? (isUp ? MetricUp : MetricDown) : MetricStatic}
          alt="Metric"
        />
      </Box>
      <Typography>{isChanged ? message : "No change"}</Typography>
    </Box>
  );
};
