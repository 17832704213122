import { all, put, takeLatest, call, select } from "redux-saga/effects";
import {
  setIsLoadingProposalsList,
  setIsLoadingProposalsListWidgets,
  setProposalsList,
  setSentProposalsByLimit,
  setIsLoadingProposalsByLimit,
  setIsLoadingShortProposalList,
  setProposalsByType,
  setRecentProposals,
  setProposalsStatistic,
  setUnopenedProposals,
  setArticles,
  setReadingTime,
  setAverageOpens,
  setClicksPercent,
  setProposalsMetrics,
  setShortProposals,
} from "../reducers/proposalsReducer";
import { setIsLoadingLeaderboardChart } from "../reducers/dashboardReducer";
import {
  fetchProposalsList,
  fetchSentProposalsByLimit,
  fetchProposalsByType,
  fetchProposalsListWidgets,
  fetchShortProposals,
  fetchAppraisalsList,
} from "../actions";
import { getRange, getWeeks } from "../selectors/dashboardSelector";
import AppraisalsService from "app/api/AppraisalsService";
import MetricsService from "app/api/MetricsService";
import { UNOPENED, RECENT, MAX_LIMIT } from "app/constants/proposalsConstants";

export default function* watchProposals() {
  yield all([
    yield takeLatest(fetchProposalsList, fetchProposalsListSaga),
    yield takeLatest(
      fetchSentProposalsByLimit.type,
      fetchSentProposalsByLimitSaga,
    ),
    yield takeLatest(fetchProposalsByType.type, fetchProposalsByTypeSaga),
    yield takeLatest(fetchProposalsListWidgets, fetchProposalsListWidgetsSaga),
    yield takeLatest(fetchShortProposals.type, fetchShortProposalsSaga),
    yield takeLatest(fetchAppraisalsList.type, fetchAppraisalsListSaga),
  ]);
}

function* fetchProposalsListSaga({ payload }) {
  yield put(setIsLoadingProposalsList(true));
  const {
    search,
    page,
    archived,
    draft,
    query,
    weeks,
    proposalType,
    proposalOffice,
    proposalTeamMember,
    proposaldateRange,
  } = payload;

  let response;
  if (query === UNOPENED) {
    const PAGE = 1;
    response = yield call(
      AppraisalsService.getUnopenedAppraisals,
      ...[MAX_LIMIT, PAGE, weeks || MAX_LIMIT],
    );
  } else {
    response = yield call(
      AppraisalsService.getAppraisals,
      ...[
        search,
        page,
        undefined,
        undefined,
        archived,
        draft,
        50,
        weeks,
        proposalType,
        proposalOffice,
        proposalTeamMember,
        proposaldateRange,
      ],
    );
  }
  yield put(setProposalsList(response.data));
  yield put(setIsLoadingProposalsList(false));
}

export function* fetchAverageOpensSaga() {
  const {
    data: { avg },
  } = yield call(MetricsService.getAverageOpens);
  yield put(setAverageOpens(avg));
}

export function* fetchReadingTimeSaga() {
  const weeks = yield select(getWeeks);
  const {
    data: { avg, prevAvg },
  } = yield call(MetricsService.getAverageTime, weeks);
  yield put(setReadingTime({ avg, prevAvg }));
}

export function* fetchClicksPercent() {
  const {
    data: { percent, prevPercent },
  } = yield call(MetricsService.getActionsClicks);
  yield put(setClicksPercent({ currClicks: percent, prevClicks: prevPercent }));
}

function* fetchProposalsListWidgetsSaga() {
  yield put(setIsLoadingProposalsListWidgets(true));
  yield call(fetchAverageOpensSaga);
  yield call(fetchReadingTimeSaga);
  yield call(fetchClicksPercent);
  yield put(setIsLoadingProposalsListWidgets(false));
}

export function* fetchRecentProposalsSaga() {
  const weeks = yield select(getWeeks);
  const PAGE = 1;
  const { data } = yield call(
    AppraisalsService.getRecentAppraisals,
    ...[MAX_LIMIT, PAGE, weeks, true],
  );
  yield put(setRecentProposals(data));
}

export function* fetchUnopenedProposalsSaga() {
  const weeks = yield select(getWeeks);
  const PAGE = 1;
  const { data } = yield call(
    AppraisalsService.getUnopenedAppraisals,
    ...[MAX_LIMIT, PAGE, weeks],
  );
  yield put(setUnopenedProposals(data));
}

function* fetchSentProposalsByLimitSaga({ payload }) {
  yield put(setIsLoadingProposalsByLimit(true));
  const { type, limit, page, weeks } = payload;
  let service;
  switch (type) {
    case UNOPENED:
      service = AppraisalsService.getUnopenedAppraisals;
      break;
    case RECENT:
      service = AppraisalsService.getRecentAppraisals;
      break;
    default:
      break;
  }

  try {
    const response = yield call(service, ...[limit, page, weeks]);
    yield put(setSentProposalsByLimit({ data: response.data }));
  } catch (e) {
    yield put(setSentProposalsByLimit({ error: e.message }));
  }
}

function* fetchProposalsByTypeSaga({ payload }) {
  yield put(setIsLoadingLeaderboardChart(true));
  try {
    const response = yield call(AppraisalsService.senttypes, payload.weeks);
    const { currCount, prevCount, appraisals } = response.data || {};

    yield put(setProposalsByType(appraisals));
    yield put(setProposalsMetrics({ currCount, prevCount }));
  } catch (e) {
    yield put(setProposalsByType({ error: e.message }));
  }
  yield put(setIsLoadingLeaderboardChart(false));
}

export function* fetchArticlesSaga() {
  const {
    data: { articles },
  } = yield call(MetricsService.getArticles);
  yield put(setArticles(articles));
}

export function* fetchProposalStatistic({
  status = null,
  filter = "created_at",
  key = "sentProposals",
}) {
  const params = {};
  const weeks = yield select(getWeeks);
  const range = yield select(getRange);
  params.start = range?.start;
  params.end = range?.end;

  params.weeks = weeks;
  params.filter = filter;
  if (status) {
    params.status = status;
  }
  const { data } = yield call(AppraisalsService.getProposalStatistic, params);
  yield put(setProposalsStatistic({ key, data }));
}

export function* fetchShortProposalsSaga({ payload }) {
  const {
    status = null,
    filter = "created_at",
    limit = null,
    page = 1,
    excludeTimeInMS = null,
  } = payload;
  yield put(setIsLoadingShortProposalList(true));
  try {
    const weeks = yield select(getWeeks);

    const params = { weeks, filter, page };
    if (status) {
      params.status = status;
    }
    if (limit) {
      params.limit = limit;
    }
    if (excludeTimeInMS) {
      params.excludeTimeInMS = excludeTimeInMS;
    }
    const { data } = yield call(AppraisalsService.getShortProposals, params);
    yield put(setShortProposals(data.shortProposals));
  } catch {
    yield put(setIsLoadingShortProposalList(false));
  }
}

export function* fetchAppraisalsListSaga({ payload }) {
  const {
    search,
    page,
    archived,
    draft,
    query,
    weeks,
    range,
    proposalType,
    proposalOffice,
    proposalTeamMember,
    proposaldateRange,
  } = payload;

  yield put(setIsLoadingProposalsList(true));
  try {
    const params = {
      weeks,
      page,
      search,
      proposalType,
      proposalOffice,
      proposalTeamMember,
      proposaldateRange,
      archived,
      draft,
      query,
    };

    if (draft || archived) {
      params.filterСriterion = "created_at";
    } else params.filterСriterion = "sent_at";

    params.status = query;
    params.start = range.start;
    params.end = range.end;

    const { data } = yield call(AppraisalsService.getProposalList, params);
    yield put(setProposalsList(data));
  } catch (e) {
    console.log(e);
  }
  yield put(setIsLoadingProposalsList(false));
}
