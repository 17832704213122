import React from "react";
import {
  useParams,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import OfficeDetails from "./OfficeDetails";
import UserAccess from "./UserAccess";
import OfficeArticles from "./OfficeArticles";
import OfficeTeams from "./OfficeTeams";
import CreateTeam from "./CreateTeam";
import EditTeam from "./EditOfficeTeam";
import MarketingSettings from "./MarketingSettings";
import TimelineSettings from "./TimelineSettings";
import EditArticle from "./EditArticle";
import OfficeImages from "./OfficeImages";
import PmFees from "./PmFees";
import ProposalTypes from "./ProposalTypes";

const Office = () => {
  const { officeID } = useParams();
  let { path } = useRouteMatch();

  return (
    <div className="card card-custom card-stretch d-flex flex-column">
      <OfficeDetails id={officeID} />
      <Switch>
        <Redirect from={`${path}`} exact={true} to={`${path}/teams`} />
        <Route
          path={`${path}/users`}
          component={() => <UserAccess id={officeID} />}
        />
        <Route
          path={`${path}/articles`}
          exact={true}
          component={() => <OfficeArticles id={officeID} />}
        />
        <Route exact path={`${path}/teams`}>
          <OfficeTeams id={officeID} />
        </Route>
        <Route
          path={`${path}/teams/new`}
          exact={true}
          component={() => <CreateTeam id={officeID} />}
        />
        <Route
          path={`${path}/teams/:teamId`}
          exact={true}
          component={EditTeam}
        />
        <Route
          path={`${path}/articles/:articleId`}
          exact={true}
          component={EditArticle}
        />
        <Route
          path={`${path}/images`}
          exact={true}
          component={() => <OfficeImages officeId={officeID} />}
        />
        <Route
          path={`${path}/marketing-settings`}
          exact={true}
          component={() => <MarketingSettings id={officeID} />}
        />
        <Route
          path={`${path}/timeline-settings`}
          exact={true}
          component={TimelineSettings}
        />
        <Route
          path={`${path}/pm-fees`}
          exact={true}
          component={() => <PmFees id={officeID} />}
        />
        <Route
          path={`${path}/proposal-types`}
          exact={true}
          component={() => <ProposalTypes id={officeID} />}
        />
      </Switch>
    </div>
  );
};

export default Office;
