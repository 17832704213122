import React, { useContext } from "react";
import { makeStyles, Tab as MuiTab, Tabs as MuiTabs, Tooltip, Typography } from "@material-ui/core";
import { AppraisalContext } from "../Appraisals/AppraisalContext";
import HideIcon from "@material-ui/icons/VisibilityOffOutlined";
import ShowIcon from "@material-ui/icons/VisibilityOutlined";

const useStyles = makeStyles(theme => ({
  tabs: () => ({
    minHeight: "40px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 -20px",
    },
  }),
  tab: ({ disabled }) => ({
    borderBottom: "2px solid rgba(60, 60, 61, 0.1)",
    fontSize: "15px",
    lineHeight: "21px",
    color: "#333",
    opacity: disabled ? "0.5" : "1",
    textTransform: 'none',
    minWidth: 140,
    padding: '6px 10px'
  }),
  selectedTab: {
    color: "#000 !important",
  }
}));

export const Tabs = props => {
  const classes = useStyles({ scrollButtons: props.scrollButtons === "on" });
  return (
    <MuiTabs classes={{ root: classes.tabs }} {...props} />
  );
};

export const Tab = props => {
  const classes = useStyles({ disabled: props.disabled });
  return <MuiTab
    disableRipple
    classes={{ root: classes.tab, selected: classes.selectedTab }}
    {...props}
  />;
};

const LabelTooltip = ({ handleIconClick, hide }) => {
  const Icon = hide ? HideIcon : ShowIcon;
  const { preview } = useContext(AppraisalContext);

  if (preview) return '';
  return (
    <Tooltip arrow title={
      <Typography color="inherit" component='h3'>{hide ? 'Show' : 'Hide'}</Typography>
    }>
      <Icon onClick={handleIconClick} className="header-close-icon h-15px ml-3" />
    </Tooltip>
  )
}

export const HideTab = ({ label, hide, onHideChange, ...other }) => {
  const { preview } = useContext(AppraisalContext);

  const handleIconClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onHideChange(!hide)
  }

  const Label = () => {
    return (
      <div className="d-flex align-items-center" style={{ opacity: (hide ? "0.5" : "1") }}>
        <span>{label}</span>
        <LabelTooltip hide={hide} handleIconClick={handleIconClick} />
      </div>
    );
  }

  return (
    <Tab
      className={preview && hide ? 'd-none' : 'mce-content-body'}
      label={<Label />}
      {...other}
    />
  );
}
