import { useSelector } from "react-redux";

export const useGetAuth = () => {
  const auth = useSelector(({ auth }) => auth);

  const { authToken, expirationTime } = auth || {};

  return {
    auth,
    authToken,
    expirationTime,
  };
};
