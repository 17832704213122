import React, { useRef } from "react";
import { Toast } from "primereact/toast";

const initState = {
  toast: null,
}

export const ToastContext = React.createContext(initState);

const ToastContextProvider = ({ children,  }) => {
  const ref = useRef(null);
  return(
    <ToastContext.Provider value={{ toast: ref }}>
      <Toast ref={ref} />
      {children}
    </ToastContext.Provider>
  )
}

export default ToastContextProvider;
