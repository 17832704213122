/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { Brand } from "../brand/Brand";
import { NavLink } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DashboardIcon from "../../../../app/assets/images/Dashboard.svg";
import ProposalIcon from "../../../../app/assets/images/Proposal.svg";
import ProposalCreate from "../../../../app/assets/images/ProposalCreate.svg";
import SettingsIcon from "../../../../app/assets/images/Settings.svg";
import HelpIcon from "../../../../app/assets/images/Help.svg";
import { UserProfileDropdown } from "../extras/dropdowns/UserProfileDropdown";
import { makeStyles, Tooltip } from "@material-ui/core";
import { resetState } from "../../../../app/modules/CreateProposal/redux/actions";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "16px",
  },
}));

const asideStyles = makeStyles(() => ({
  templateItem: {
    "& .svg-icon": {
      "& svg": {
        height: "2.3rem !important",
        width: "2.3rem !important",
      },
    },
  },
}));

const NavItem = ({ title, onClick, svgSrc, to, exact, btnClassName }) => {
  const classes = useStyles();
  return (
    <li
      className="nav-item mb-6"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title={title}
    >
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={title}
        placement="right"
      >
        <NavLink
          to={to}
          className={`menu-link nav-link btn btn-icon btn-lg ${btnClassName}`}
          role="tab"
          onClick={onClick}
          exact={exact}
        >
          <span className="svg-icon svg-icon-xl">
            <SVG src={svgSrc} width="100%" height="100%" />
          </span>
        </NavLink>
      </Tooltip>
    </li>
  );
};

export function Aside() {
  const classes = asideStyles();
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();

  const resetCreateProposal = () => {
    dispatch(resetState());
  };

  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: false,
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle",
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display",
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display",
      ),
      extrasQuickActionsDisplay: false,
      extrasQuickPanelDisplay: false,
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display",
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display",
      ),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              <NavItem
                title="Dashboard"
                to="/dashboard"
                svgSrc={DashboardIcon}
                btnClassName={"btn-default"}
              />
              <NavItem
                onClick={resetCreateProposal}
                title="Create Proposal"
                to="/create-proposal"
                svgSrc={ProposalCreate}
                btnClassName={"btn-default"}
              />
              <NavItem
                title="Proposals"
                to="/proposals"
                svgSrc={ProposalIcon}
                exact
                btnClassName={"btn-default"}
              />
              <NavItem
                title="Settings"
                to="/offices"
                svgSrc={SettingsIcon}
                btnClassName={"btn-default"}
              />
              {user.permissions > 2 && (
                <NavItem
                  title="Templates"
                  to="/templates"
                  svgSrc={toAbsoluteUrl(
                    "/media/svg/icons/Design/Templates.svg",
                  )}
                  btnClassName={`btn-hover-transparent-white btn-clean ${classes.templateItem}`}
                />
              )}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}
          <div className="aside-primary d-flex align-items-center flex-column">
            <div className="mb-7">
              <UserNotificationsDropdown />
            </div>
            <ul className="list-unstyled flex-column" role="tablist">
              <NavItem
                title="Help"
                to="/help"
                svgSrc={HelpIcon}
                btnClassName={"btn-default"}
              />
            </ul>
            <div className="mb-7">
              <UserProfileDropdown />
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}
      </div>
      {/* end::Aside */}
    </>
  );
}
