/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch, Route } from "react-router-dom";

import { useGetAuth } from "redux/hooks";

import BasePage from "./BasePage";
import AuthRouter from "app/modules/Auth/AuthRouter";
import { Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import ProposalView from "./pages/ProposalView";

export function Routes() {
  const { authToken } = useGetAuth();
  const isAuthorized = !!authToken;

  return (
    <Switch>
      <Route
        exact
        path="/proposal-view/:proposalId"
        component={ProposalView}
      />
      <Route path="/preview" component={ProposalView} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/">{isAuthorized ? <BasePage /> : <AuthRouter />}</Route>
    </Switch>
  );
}
