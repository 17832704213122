import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateChooseUs } from "redux/reducers";
import { getChooseUsData, getArticlesData } from "redux/selectors";
import { Typography, makeStyles } from "@material-ui/core";

import { Article } from "app/interfaces";
import PopupBlock from "app/modules/Appraisals/components/PopupBlock";
import HideContainer from "app/modules/Appraisals/components/HideContainer";
import BlockContainer from "app/modules/Appraisals/components/BlockContainer";
import useArticles from "app/hooks/useArticles";
import awardIcon from "app/assets/images/award.svg";
import communityIcon from "app/assets/images/community.svg";
import historyIcon from "app/assets/images/history.png";
import ampersandIcon from "app/assets/images/logo-ampersand.svg";
import missionIcon from "app/assets/images/mission.png";
import { updateArticleBody } from "../../../../redux/reducers/appraisalReducer";
import EditorComponent from "../../Common/EditorComponent";
import { AppraisalContext } from "../AppraisalContext";

const useStyles = makeStyles({
  chooseUsSubtitle: {
    fontWeight: 800,
    marginTop: "50px",
    fontSize: "15px",
    textAlign: "center",
  },
});

const ChooseUs = (): JSX.Element => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const articles = useSelector(getArticlesData);
  const chooseUsSection = useSelector(getChooseUsData);
  const classes = useStyles();

  const articleList: { chooseUs?: Array<Article> | [] } = useArticles({
    chooseUs: true,
  });

  const iconMap: { [key: string]: string } = {
    "national--internation-reach": communityIcon,
    "history": historyIcon,
    "performance-matrix": ampersandIcon,
    "rewards-culture--recognition": awardIcon,
    "our-mission": missionIcon,
  };

  const labelMap: { [key: string]: string } = {
    "national--internation-reach": "Our Reach",
    "history": "Our Story",
    "performance-matrix": "Our Performance",
    "rewards-culture--recognition": "Our Recognition",
    "our-mission": "Our Mission",
  };

  const articlesData = articleList?.chooseUs?.map(item => ({

    ...item,
    iconSrc: iconMap[item.slug],
    label: labelMap[item.slug],
  }));

  const mainArticl = articles.find(
    (item: Article) => item.slug === "why-choose-us",
  );

  const onUpdateArticleBody = (id: string, body: string) => {
    dispatch(updateArticleBody({ id, body }));
  };

  const onHideSection = (value: boolean) => {
    dispatch(updateChooseUs({ isHiddenSection: value }));
  };

  const onTitleUpdate = (value: string) => {
    dispatch(updateChooseUs({ title: value }));
  };

  const onSubTitleUpdate = (value: string) => {
    dispatch(updateChooseUs({ subtitle: value }));
  };

  return (
    <HideContainer
      value={chooseUsSection.isHiddenSection}
      onChange={onHideSection}
    >
      <BlockContainer
        id={"choose-us"}
        defaultTitle={chooseUsSection.title}
        defaultSubtitle={chooseUsSection.subtitle}
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubTitleUpdate}
        hide={chooseUsSection.isHiddenSection}
      >
        <EditorComponent
          inline
          value={mainArticl?.body}
          onBlur={(newBody: string) =>
            onUpdateArticleBody(mainArticl?._id, newBody)
          }
          type={"coverletter"}
          disabled={preview}
          charactersLimit={1100}
        />
        <Typography
          component="p"
          variant="subtitle1"
          align="center"
          className={classes.chooseUsSubtitle}
        >
          Click on the articles below for more information
        </Typography>
        <PopupBlock articleList={articlesData} />
      </BlockContainer>
    </HideContainer>
  );
};

export default ChooseUs;
