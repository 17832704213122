import React from "react";

const FormInput = ({ title, value, placeholder, type, onChange, disabled }) => {
  const InputIcon = ({ type }) => {
    if (type !== "phone" && type !== "email") return "";
    return (
      <div className="input-group-prepend">
        <span className="input-group-text">
          {type === "phone" && <i className="fa fa-phone" />}
          {type === "email" && <i className="fa fa-at" />}
        </span>
      </div>
    );
  };

  return (
    <div className="form-group row">
      <label className="col-lg-2 col-form-label">{title}</label>
      <div className="col-lg-10">
        <div className="input-group input-group-lg input-group-solid">
          <InputIcon type={type} />
          <input
            type="text"
            placeholder={placeholder}
            className={`form-control form-control-lg form-control-solid`}
            value={value}
            onChange={e => onChange(e.target.value)}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default FormInput;
