import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import ServicesFees from "../ServicesFees";
import BusinessSupport from "../BusinessSupport";
import GrowBusiness from "../GrowBusiness";
import AttractAgents from "../AttractAgents";
import ChooseUs from "../ChooseUs";
import NextSteps from "../NextSteps/NextStepsProspecting";
import NetworkManager from "../NetworkManager";
import OurGrowingNetwork from "../OurGrowingNetwork";
import YourProperty from "../YourProperty";
import { GenericVideoModule } from "../GenericVideo";
import { AgentVideoModule } from "../AgentVideo";
import ArticleSection from "../ArticleSection";
import { updateOurValues } from "redux/reducers";
import { getOurValuesData } from "redux/selectors";

import useArticles from "app/hooks/useArticles";

const Prospecting = () => {
  const articles = useArticles({ common: true });
  const ourValuesSection = useSelector(getOurValuesData);
  const ourValuesArticle = useCallback(
    () => articles?.common?.find(item => item.slug === "our-values"),
    [articles],
  );

  return (
    <>
      <CoverletterImage />
      <Container
        maxWidth="md"
        component="div"
        className="px-10"
        id="appraisal-details"
      >
        <Coverletter withoutTopTitle />
      </Container>
      <YourProperty withoutVideo />
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <GenericVideoModule isVideoFromSetting withSeparator />
        <ChooseUs />
        <ArticleSection
          id="our-values"
          sectionData={ourValuesSection}
          onUpdateSectionData={updateOurValues}
          article={ourValuesArticle()}
        />
        <OurGrowingNetwork />
        <BusinessSupport />
        <AttractAgents />
        <GrowBusiness />
        <ServicesFees
          article={articles?.common?.find(
            item => item.slug === "franchise-fee-structure",
          )}
        />
        <NetworkManager withSeparator withTestimonials />
        <AgentVideoModule isVideoFromSetting withSeparator/>
        <NextSteps withoutDescription/>
      </Container>
    </>
  );
};

export default Prospecting;
