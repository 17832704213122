import { Select as MUISelect, withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    background: '#fff',
    border: "1px solid",
    borderColor: theme.palette.border.gray,
    lineHeight: '17px',
    fontSize: 13,
    padding: '13px 18px',
  },
})

export default withStyles(styles)(MUISelect);
