import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

import PropertyCard from 'app/components/PropertyCard';
import { getPrice, getLandSize } from "../../CreateAppraisal/utils";
import HoverProposalIcon from "../../../Common/HoverProposalIcon";

const SelectedProps = ({ items, onDelete }) => {
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );
  return (
    <Grid container spacing={3}>
      {items.map((prop, index) => (
        <Grid item xs={4} md={4} key={index}>
          <HoverProposalIcon
            onClick={() => onDelete(prop, index)}
            type="remove"
          >
            <PropertyCard
              bed={prop.bedrooms}
              bath={prop.bathrooms}
              cars={prop.cars}
              landArea={getLandSize(prop.landArea)}
              image={prop.image}
              underImageIcons
              descriptionFields={[
                prop.address,
                prop.buildingArea &&
                  "Building Area: " + prop.buildingArea + " sqm",
                prop.manually && prop.isCommercial
                  ? `${category === "lease" ? "" : "Sale "}Price: ${getPrice(
                      prop.price,
                    )}`
                  : getPrice(prop.price),
                prop.ratePerSqm && "Rate per sqm : " + prop.ratePerSqm,
                prop.daysOnMarket &&
                  "Days on Market (DOM): " + prop.daysOnMarket,
                getLandSize(prop.floorArea) &&
                  "Floor Area: " + getLandSize(prop.floorArea),
                prop.yearBuilt && "Year Built: " + prop.yearBuilt,
                prop.saleDate &&
                  `${category === "lease" ? "Lease" : "Sale"} date: ` +
                    prop.saleDate,
              ]}
            />
          </HoverProposalIcon>
        </Grid>
      ))}
    </Grid>
  );
};

SelectedProps.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};

export default SelectedProps;
