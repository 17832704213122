import { ObjectId } from "mongodb";

export enum ProposalTypes {
  preAppraisal = "preAppraisal",
  listingProposal = "listingProposal",
  marketUpdate = "marketUpdate",
  agentProfiling = "agentProfiling",
  recruitment = "recruitment",
  appraisal = "Appraisal",
  prospecting = "prospecting",
  informationMemorandum = "informationMemorandum",
  commercialListingProposal = "commercialListingProposal",
  businessListingProposal = "businessListingProposal",
  residentialInformationMemorandum = "residentialInformationMemorandum",
  tenantHandbook = "tenantHandbook",
}

export enum CreatePropertySteps {
  SelectOffice,
  SelectType,
  SelectTeam,
  SelectProperty,
  SelectSuburb,
  Builder,
  Sender,
}

export interface State {
  id: number;
  name: string;
  abbreviation: string;
}

export interface Suburb {
  id: number;
  name: string;
  postcode: string;
  state: State;
}

export interface TeamLeader {
  _id: ObjectId;
  email: string;
  firstname: string;
  lastname: string;
}

export interface Testimonial {
  _id: ObjectId;
  name: string;
  quote: string;
}

export interface Testimonials {
  image: ObjectId;
  items: Array<Testimonial>;
}

export interface Team {
  _id: ObjectId;
  id: ObjectId;
  about: string;
  biography: string;
  coverLetter: string;
  coverLetter2: string;
  coverLetter3: string;
  coverLetter4: string;
  coverLetter5: string;
  coverLetter6: string;
  coverLetter7: string;
  coverLetter8: string;
  coverLetter9: string;
  coverLetter13: string;
  coverLetter14: string;
  created_at: string;
  currentRentals: string;
  departmentCategory: string;
  email: string;
  facebook: string;
  image: ObjectId;
  instagram: string;
  leader: Array<TeamLeader>;
  linkedIn: string;
  members: Array<any>;
  name: string;
  office: ObjectId;
  phone: string;
  profileLink: string;
  profilePortrait: ObjectId;
  propertyForRent: string;
  propertyForSale: string;
  recentSales: string;
  report: string;
  reportImage: ObjectId;
  templates: {
    template1: ObjectId;
    template2: ObjectId;
    template3: ObjectId;
    template4: ObjectId;
    template5: ObjectId;
    template6: ObjectId;
    template7: ObjectId;
    template8: ObjectId;
    template10: ObjectId;
    template11: ObjectId;
    template12: ObjectId;
    template13: ObjectId;
    template14: ObjectId;
  };
  testimonials: Testimonials;
  twitter: string;
  updated_at: string;
  videos: Array<string>;
}

export interface Office {
  _id: ObjectId;
  id: ObjectId;
  active: boolean;
  address: string;
  fax: string;
  groupid: number;
  marketing_lease_settings: any;
  marketing_settings: any;
  name: string;
  new_articles: boolean;
  phone: string;
  pmfees: Array<any>;
  speciality: string;
  source_type: string;
  timeline_settings: Array<any>;
  token: string;
  created_at: string;
  updated_at: string;
  website: string;
  proposalTypes: Array<any>;
  address_state: string;
}

export interface Address {
  country: {
    gstRate: number;
    id: number;
    isocode: string;
    name: string;
  };
  level: string;
  state: State;
  street: string;
  streetNumber: string | number;
  suburb: Suburb;
  unitNumber: string | number;
}

export interface contactStaff {
  adminAccess: boolean;
  email: string;
  firstName: string;
  id: number;
  lastLogin: string;
  lastName: string;
  paAccess: any;
  permissions: {
    accessPropertyManagement: boolean;
    accessSales: boolean;
    canLogin: boolean;
    deleteContacts: boolean;
    globalActionListsReadWrite: boolean;
    globalContactsReadWrite: boolean;
    globalNotesRead: boolean;
    globalNotesReadWrite: boolean;
    globalPropertiesRead: boolean;
    globalPropertiesReadWrite: boolean;
    globalTasksReadWrite: boolean;
    sendSMS: boolean;
    settings: boolean;
  };
  phoneNumbers: [
    {
      number: string;
      typeCode: string;
      type: string;
      comment: any;
    },
  ];
  photo: {
    original: string;
    thumb_360: string;
  };
  position: string;
  profile: any;
  role: string;
  staffTypeId: number;
  username: string;
  websiteUrl: string;
}

export interface Property {
  address: Address;
  addressVisibility: string;
  agentPriceOpinion: string;
  agentPriceOpinionHidden: boolean;
  auctionDetails: {
    auctioneer: string;
    dateTime: string;
    venue: string;
  };
  authorityEnd: any;
  authorityStart: any;
  authorityType: {
    id: number;
    name: string;
  };
  bath: number;
  bed: number;
  branch: any;
  brochureDescription: string;
  building: any;
  carports: number;
  certificateOfTitle: string;
  class: {
    id: number;
    internalName: string;
    name: string;
  };
  contactStaff: contactStaff;
  corelogicId: number;
  description: string;
  displayAddress: string;
  displayPrice: string;
  eTableUrl: string;
  energyRating: any;
  ensuites: number;
  epcGraphUrl: string;
  externalLinks: Array<any>;
  floorArea: { units: string; value: number };
  folioNumber: string;
  frontage: number;
  garages: number;
  geolocation: { accuracy: string; latitude: number; longitude: number };
  heading: string;
  id: number;
  improvementValue: any;
  inserted: string;
  keyID: any;
  keyOut: boolean;
  landArea: { units: string; value: number };
  landValue: any;
  leaseLifeId: any;
  legalDescription: string;
  lotNumber: string | number;
  methodOfSale: { name: string; id: number };
  mobileMarketingDescription: string;
  modified: string;
  openSpaces: number;
  photos: Array<any>;
  priceOnApplication: boolean;
  priceQualifier: any;
  rateableValue: any;
  rates: {
    water: { period: string; value: number };
    strata: { period: string; value: number };
    council: { period: string; value: number };
  };
  receptionRooms: number;
  referenceID: string;
  royalMailId: any;
  rpdp: string;
  suburbHidden: boolean;
  saleLifeId: number;
  searchPrice: number;
  sellingFeeFixed: any;
  sellingFeePercent: any;
  setSaleDateDetails: { dateTime: string };
  soiUrl: any;
  status: string;
  tenancyDetails: string;
  tenancyStart: any;
  tenancyStop: any;
  tenantEmail: string;
  tenantName: string;
  tenantPhone: string;
  tenanted: boolean;
  tenderDetails: { dateTime: any };
  tenureOrTitleType: any;
  toilets: number;
  type: {
    name: string;
    id: number;
    propertyClass: {
      name: string;
      id: number;
      internalName: string;
    };
  };
  volumeNumber: string;
  vpa: number;
  yearBuilt: number;
}

export interface TabsContextProps {
  key: string;
  label: string;
  isHiddenTab: boolean;
  onHideChange(value: Boolean): void;
  media: any;
  body: string;
  changeBody(value: string): void;
  openAdditionalModal?: () => void;
  children?: HTMLBodyElement;
}

export interface Article {
  active: boolean;
  body: string;
  category: string;
  created_at: string;
  editable?: boolean;
  icon: string;
  image: string;
  tabImage?: string;
  office: string;
  position: string;
  slug: string;
  title: string;
  label: string;
  updated_at: string;
  iconSrc?: string;
  __v?: number;
  _id?: string;
}

export interface Message {
  _id: ObjectId;
  seen: Array<string>;
  content: string;
  created_at: string;
  showcaseNotification: boolean;
  sender: {
    email: ObjectId;
    name: string;
    phone: string;
    id: ObjectId;
    office: string;
  };
  to: string;
  message_unvisible_to: Array<ObjectId>;
}
