import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";
import DataGrid from '../../Common/CustomizedDataGrid'
import useDebounce from "../../../hooks/useDebounce";
import SearchBox from "../../../modules/SearchBox";
import SuburbsService from "../../../api/SuburbsService";
import { Suburb } from "../../../interfaces";
import { setSuburb } from "../redux/actions";
import { RootState } from "redux/store";

const useStyles = makeStyles(() => ({
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  content: {
    position: "relative",
    minHeight: '300px',
  },
}));

const SelectSuburb = () => {

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [suburbs, setSuburbs] = useState([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const officeId = useSelector((state: RootState) => state.createProposal.office?.id, shallowEqual);
  const typeProposal = useSelector((state: RootState) => state.createProposal.type, shallowEqual);

  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    if (search.length < 4) return;
    setLoading(true);
    SuburbsService.getSuburbs(officeId, search, page)
      .then(({ data }) => {
        setSuburbs(data.items.map((suburb: Suburb) => ({
          id: suburb.id,
          name: suburb.name,
          postcode: suburb.postcode,
          state: suburb.state
        })))
        setTotal(data.totalItems)
      })
      .finally(() => setLoading(false))
  }, [officeId, page, debouncedSearchTerm, search]);

  const getStateAbbreviation = (params: any) => {
    return params.value.abbreviation
  }

  const columns = [
    { field: "name", headerName: "Suburb Name", width: 500 },
    { field: "postcode", headerName: "Postcode", width: 200 },
    { field: "state", valueGetter: getStateAbbreviation, headerName: "State", width: 200 }
  ];

  const onSuburbSelected = async (suburb: Suburb) => {
    dispatch(setSuburb(suburb))
  }

  const classes = useStyles();

  return (
    <div className={classes.content}>
      <SearchBox
        value={search}
        onChangeSearch={setSearch}
        className="my-3 mx-5"
        placeholder={`Enter your ${typeProposal === "prospecting" ? "Prospecting" : "Market Update"} suburb name here`}
      />
      {loading && (
        <div className={classes.loading}>
          <h3 className="mb-10">Fetching Suburbs from CompassPlus...</h3>
          <CircularProgress />
        </div>
      )}
      {!loading && suburbs.length > 0 && (
        <DataGrid
          columns={columns}
          rows={suburbs}
          loading={loading}
          onRowSelected={(val: { data: Suburb }) => onSuburbSelected(val.data)}
          pageSize={50}
          rowCount={total}
          rowsPerPageOptions={[50]}
          pagination
          paginationMode="server"
          onPageChange={(val: { page: number }) => setPage(val.page)}
        />
      )}
    </div>
  );

};

export default SelectSuburb;
