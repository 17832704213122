import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { AppraisalContext } from "app/modules/Appraisals/AppraisalContext";
import VisibilityOut from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOut from "@material-ui/icons/VisibilityOffOutlined";
import cn from "classnames";

const useStyles = makeStyles(theme => ({
  articleButtonContainer: {
    position: "relative",
    padding: "35px",
    paddingTop: "25px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    alignSelf: "start",
  },
  articleStatusButton: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  articleButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textTransform: "none",
    fontSize: "15px",
    lineHeight: "19px",
    textAlign: "center",
    transition: "color 0.2s ease",
    cursor: "pointer",
    "& img": {
      width: 90,
      height: 90,
    },
    "& span": {
      width: 108,
      color: "inherit",
      marginTop: 15,
    },
    "&:hover": {
      width: 108,
      color: "#FFB300",
    },
    "&:active": {
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.2,
  },
}));

interface ArticleButtonContextProps {
  label: string;
  active: boolean;
  imgSrc: string | undefined;
  imgAlt: string;
  onClick: () => void;
  onChangeStatus: (value: boolean) => void;
}

const ArticleButton = ({
  label,
  onClick,
  imgSrc,
  imgAlt,
  active,
  onChangeStatus,
}: ArticleButtonContextProps) => {
  const { preview } = useContext(AppraisalContext);
  const classes = useStyles({ preview });
  if ((preview && !active) || active === undefined) return <React.Fragment />;

  return (
    <div className={classes.articleButtonContainer}>
      {!preview && (
        <Tooltip
          arrow
          title={
            <Typography color="inherit" component="h3">
              {active ? "Hide" : "Show"}
            </Typography>
          }
        >
          <IconButton
            disableRipple
            aria-label="change status"
            className={classes.articleStatusButton}
            onClick={() => onChangeStatus(!active)}
          >
            {active ? <VisibilityOut /> : <VisibilityOffOut />}
          </IconButton>
        </Tooltip>
      )}

      <div
        className={cn(classes.articleButton, {
          [classes.disabled]: !active && !!onChangeStatus,
        })}
        onClick={onClick}
      >
        <img src={imgSrc} alt={imgAlt} />
        <span>{label}</span>
      </div>
    </div>
  );
};

export default React.memo(ArticleButton);
