import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { actions } from "../../../../../app/modules/Auth";
import { useHistory } from "react-router-dom";
import DropDownMenu from "../../../../../app/components/DropDownMenu";
import _ from 'lodash';
export function UserProfileDropdown() {
  const { user, authList } = useSelector(({ auth }) => auth);
  const [activeOffice, setActiveOffice] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const index = authList.findIndex(
      item => item.office._id === user.office._id,
    );
    setActiveOffice(index);
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const useStyles = makeStyles(() => ({
    avatar: {
      width: "calc(1.5em + 1.65rem + 4px)",
      height: "calc(1.5em + 1.65rem + 4px)",
      border: "3px solid #FFB300",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(actions.logout());
  };

  const changeOffice = ({ index }) => {
    const data = authList[index];
    setActiveOffice(index);
    dispatch(actions.switchOffice(data));
    history.go(0);
  };

  let officeList = authList.map((item, index) => ({
    label: item.office.name,
    value: index,
  }));

  officeList = _.uniqBy(officeList, 'label');

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <Avatar
          className={classes.avatar}
          src={user.agent?.imageurl || user.agent?.photo.thumb_360}
          alt={""}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div
          className="d-flex align-items-center p-8"
          style={{ backgroundColor: "#FFB300", fontColor: "white" }}
        >
          <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
            {user.firstname} {user.lastname}
          </div>
        </div>
        <div className="separator separator-solid" />
        <div className="navi navi-spacer-x-0">
          <div className="navi-footer px-8 py-5 align-items-baseline d-flex flex-column ">
            {authList.length > 1 ? (
              <>
                <div>Switch Office accounts</div>
                <DropDownMenu
                  data={officeList}
                  initialIndex={activeOffice}
                  itemHandler={changeOffice}
                  className="select-menu my-3 w-100"
                  customHeight={120}
                />
              </>
            ) : (
              ""
            )}
            <div onClick={signOut} className="btn btn-primary font-weight-bold">
              Sign Out
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
