import RaineAndHorneCommercial from "../../assets/images/rhcommercial-logo.png";
import RaineAndHorneImg from "../../assets/images/RaineAndHorne.png";
import { Typography } from "@material-ui/core";
import React from "react";

interface PropsInterface {
  isCommercial: boolean
  officeName: string
  officePhone: string
}

const ArchivedStatusMessage = ({ isCommercial, officeName, officePhone }: PropsInterface) => (
  <div className={"d-flex justify-content-center text-center align-items-center h-100 flex-column"}>
    <img src={isCommercial ? RaineAndHorneCommercial : RaineAndHorneImg} alt="logo" className="w-300px"/>
    <Typography component="h1" variant="h4" className={'mt-5'}>
      This {isCommercial ? "Information Memorandum" : "proposal"} has expired and been deactivated
    </Typography>
    <Typography component="h1" variant="h4" className={'mt-3'}>
      {`For an up-to-date ${isCommercial ? "Information Memorandum" : "proposal"}, please contact:`}
    </Typography>
    <Typography component="h1" variant="h4" className={'mt-3'}>
      {`${officeName}`}
    </Typography>
    <Typography component="h1" variant="h4" className={'mt-3'}>
      {`${officePhone}`}
    </Typography>
  </div>
)

export default ArchivedStatusMessage;
