import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  Grid,
  Card,
  CardMedia,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: "100%",
  },
  selectedProperty: {
    backgroundColor: "white",
  },
}));

export const ImagesModal = ({ onHide, show, images, loading, onSelect }) => {
  const classes = useStyles();

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Comparables
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="d-flex flex-column align-items-center">
            <h3 className="mb-10">Loading Images...</h3>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={3}>
            {images.map((image, index) => (
              <Grid key={index} item xs={4}>
                <Card
                  className={`${classes.card}`}
                  onClick={() => onSelect(image.id, image.src)}
                >
                  <CardMedia
                    component="img"
                    className={classes.media}
                    image={image.src}
                    title={image.id}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
