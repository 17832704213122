import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useDispatch } from "react-redux";
import { updateRecipient } from "redux/reducers/appraisalReducer";
import EditorComponent from "../../../Common/EditorComponent";

const EmailTemplate = ({ appraisalId, recipient, buttonText, isCommercial }) => {

  const dispatch = useDispatch();
  const emailHeader = toAbsoluteUrl(isCommercial
    ? '/media/emailTemplate/email-header-commercial.jpeg'
    : '/media/emailTemplate/email-header.jpg'
  )

  return(
    <div>
      <div className="form-email-preview form-component">
        <table className="gmail-fix" bgcolor="#eeeff2" width="100%" style={{minWidth: '320px'}}>
          <tbody>
            <tr>
              <td>
                <table className="flexible" width="600" align="center" style={{margin: '0 auto'}}>
                  <tbody>
                    <tr>
                      <td align="center" style={{paddingTop: '40px'}}>
                        <div>
                          <img src={emailHeader} width="100%" style={{verticalAlign: 'bottom'}} alt="top-gradient"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="plr-10" bgcolor="#fefefe" style={{padding: '47px 30px 14px 30px', boxShadow: '5px 9px 20px #d2d2d2'}}>
                        <table width="540">
                          <tbody>
                            <tr>
                              <td align="center" style={{padding: '0 0 20px', color: '#717993'}}>
                                <EditorComponent
                                  inline
                                  value={ recipient.email.message }
                                  onBlur={value => dispatch(updateRecipient({email: {...recipient.email, message: value}}))}
                                  type={'coverletter'}
                                  disabled={false}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table width="250" align="center" style={{margin: '0 auto'}}>
                                  <tbody>
                                    <tr>
                                      <td className="active-t" align="center" style={{paddingBottom: '40px'}}>
                                        <a style={{padding: '14px 10px 18px', textDecoration: 'none', display: 'block'}} href={`${window.location.origin}/proposal-view/${appraisalId}`}>
                                          <div style={{padding: '8px', fontSize: '20px', color: 'white', background: isCommercial ? "#3c3c3d" : "#fdb300"}}>
                                            {buttonText}
                                          </div>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <div style={{ background: isCommercial ? "#ffcc2f" : "", minHeight: 11 }}>
                          {isCommercial ? "" : (
                            <img
                              src={toAbsoluteUrl(
                                "/media/emailTemplate/bottom-gradient.jpg",
                              )}
                              width="100%"
                              style={{ verticalAlign: "top" }}
                              alt="bottom-gradient"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="plr-10" align="center" style={{ padding: '31px 10px 30px 20px', color:'#6e727a'}}>
                        &copy; Copyright {new Date().getFullYear()} Raine&Horne All rights reserved.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  )
}

export default EmailTemplate;
