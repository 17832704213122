import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProposalsByType } from "redux/selectors/proposalsSelector";
import { Chart } from "chart.js";
import { fetchProposalsByTypeData } from "redux/actions";
import { setProposalsByType } from "redux/reducers/proposalsReducer";
import { getRange, getWeeks } from "redux/selectors/dashboardSelector";
import Loader from "app/components/ui/Loader";
import { getIsLoadingLeaderboardChart } from "redux/selectors/dashboardSelector";

import "./styles.scss";

const LeaderboardChart = () => {
  const canvas = useRef();
  const tooltip = useRef();

  const dispatch = useDispatch();

  const weeks = useSelector(getWeeks);
  const range = useSelector(getRange);
  const data = useSelector(getProposalsByType);
  const isLoading = useSelector(getIsLoadingLeaderboardChart);

  useEffect(() => {
    dispatch(fetchProposalsByTypeData({ weeks, range }));
    return () => dispatch(setProposalsByType([]));
  }, [dispatch, weeks, range]);

  const chartData = data.map(item => item.count);
  const chartColors = data.map(item => item.color);

  const hideTooltip = () => {
    tooltip.current.style.opacity = "0";
  };

  useEffect(() => {
    let chart;
    if (data.length) {
      const ctx = canvas.current.getContext("2d");
      chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: chartData,
              backgroundColor: chartColors,
            },
          ],
        },
        options: {
          cutout: 28,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: ({ tooltip: context }) => {
                if (context.opacity) {
                  tooltip.current.textContent =
                    context.dataPoints[0].formattedValue;
                  tooltip.current.style.cssText = `
                top: ${context.caretY}px;
                left: ${context.caretX}px;
                background: ${context.dataPoints[0].element.options.backgroundColor};
                opacity: 1;`;
                }
              },
            },
          },
        },
      });
    }
    return () => chart && chart.destroy();
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="leaderboard-chart-wrapper">
      <div className="leaderboard-chart" onMouseLeave={hideTooltip}>
        <canvas ref={canvas} />
        <div ref={tooltip} className="chart-tooltip" />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="leaderboard-chart-labels">
          {data.map(item => (
            <li key={item.title} className="item">
              <div className="d-flex">
                <div
                  className="color"
                  style={{ backgroundColor: item.color }}
                />
                <span className="title">{item.title}</span>
              </div>
              <span className="quantity">{item.count}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LeaderboardChart;
