import axios from 'axios';
import API_URL from './api';
import { sortBy, uniqWith, isEqual } from "lodash";

export default class PropertiesService {
  static getPropertiesVaultreAll = (
    classificationType,
    statusPoperty,
    teamLeaderId,
    officeId,
    category,
    searchParam,
    pageNumber,
    filterParam,
    sortParam,
    searchType,
    mixedClassificationType,
  ) => {
    if (category) {
      return PropertiesService.getPropertiesVaultre(
        classificationType,
        statusPoperty,
        teamLeaderId,
        officeId,
        category,
        searchParam,
        pageNumber,
        filterParam,
        sortParam,
        searchType,
      ).then(response => {
        let rawProperties = response?.data?.items;
        rawProperties = sortBy(rawProperties, o => new Date(o.modified));
        rawProperties.reverse();
        return rawProperties;
      });
    }

    let additionalPromises = [];
    if (mixedClassificationType) {
      additionalPromises.push(
        PropertiesService.getPropertiesVaultre(
          "commercial",
          statusPoperty,
          teamLeaderId,
          officeId,
          "lease",
          searchParam,
          pageNumber,
          filterParam,
          sortParam,
          searchType,
        ),
      );
      additionalPromises.push(
        PropertiesService.getPropertiesVaultre(
          "commercial",
          statusPoperty,
          teamLeaderId,
          officeId,
          "sales",
          searchParam,
          pageNumber,
          filterParam,
          sortParam,
          searchType,
        ),
      );
    }
    if (classificationType === 'commercial') {
      additionalPromises.push(
        PropertiesService.getPropertiesVaultre(
          "business",
          statusPoperty,
          teamLeaderId,
          officeId,
          "sales",
          searchParam,
          pageNumber,
          filterParam,
          sortParam,
          searchType,
        ),
      );
    }

    return new Promise((resolve, reject) => {
      let rawProperties = [];
      Promise.all([
        PropertiesService.getPropertiesVaultre(
          classificationType,
          statusPoperty,
          teamLeaderId,
          officeId,
          "lease",
          searchParam,
          pageNumber,
          filterParam,
          sortParam,
          searchType,
        ),
        PropertiesService.getPropertiesVaultre(
          classificationType,
          statusPoperty,
          teamLeaderId,
          officeId,
          "sales",
          searchParam,
          pageNumber,
          filterParam,
          sortParam,
          searchType,
        ),
        ...additionalPromises,
      ])
        .then(responses => {
          responses.forEach(response => {
            rawProperties = rawProperties.concat(response?.data?.items);
          });
          // if(mixedClassificationType) {
          //   rawProperties = uniqBy(rawProperties, 'id');
          // }

          rawProperties = uniqWith(rawProperties, isEqual);
          rawProperties = sortBy(rawProperties, o => new Date(o.modified));
          rawProperties.reverse();
          resolve(rawProperties);
        })
        .catch(reject);
    });
  };

  static getPropertiesVaultre = (
    classificationType,
    statusPoperty,
    teamLeaderId,
    officeId,
    categoryType,
    searchParam,
    pageNumber,
    filterParam,
    sortParam,
    searchType,
  ) => {
    let querystring = "";

    let classification = classificationType || "residential";

    if (statusPoperty && statusPoperty !== "") {
      querystring += `&status=${statusPoperty}`;
    }

    if (officeId && officeId !== "") {
      querystring += `&office=${officeId}`;
    }

    if (teamLeaderId && teamLeaderId !== "") {
      querystring += `&teamleaderid=${teamLeaderId}`;
    }

    let search = searchParam || "";

    let page = pageNumber || 1;

    let filter = filterParam || "updated_at";

    let sort = sortParam || "descending";

    let type = searchType || "all";

    let category = categoryType === "sales" ? "sale" : "lease";

    return axios.get(
      `${API_URL}/properties/vaultre?classification=${classification}&type=${type}&category=${category}&search=${search}&page=${page}&filter=${filter}&sort=${sort}${querystring}`,
    );
  };

  static getProperty = (id, classification, category, office) => {
    return axios.get(
      `${API_URL}/properties/vaultre/${id}?classification=${classification}&category=${category}&office=${office}`,
    );
  };

  static getPropertyVendor = (id, lifeId, office, category) => {
    return axios.get(
      `${API_URL}/properties/vaultre/${id}/vendors?lifeId=${lifeId}&office=${office}&category=${category}`,
    );
  };
}
