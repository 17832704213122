import React from "react";
import { DatePicker } from "@material-ui/pickers";
import { Moment } from "moment";
import { makeStyles, MenuItem } from "@material-ui/core";
import SVG from "react-inlinesvg";
import calendarSvg from "app/assets/images/calendar.svg";
import cn from "classnames";
import Select from "./Select";

const useStyles = makeStyles((theme: any) => ({
  container: {
    background: theme.palette.background.gray2,
    width: "100%",
    height: 150,
    minHeight: 150,
    display: "flex",
    justifyContent: "center",
  },
  inputs: {
    display: "flex",
    height: 70,
    alignItems: "end",
  },
  dropdowns: {},
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& input": {
      height: 45,
      border: "1px solid",
      borderColor: theme.palette.border.gray,
      background: "#fff",
      lineHeight: "17px",
      fontSize: 13,
      padding: "13px 13px",
      outline: "none",
    },
    "& label": {
      lineHeight: "17px",
      fontWeight: 600,
      fontSize: 14,
    },
    "&>svg": {
      position: "absolute",
      right: 13,
      bottom: 22.5,
      transform: "translateY(50%)",
    },
  },
  searchButton: {
    width: 100,
    height: 45,
    background: theme.palette.background.gray1,
    color: "#fff",
    "&:hover": {
      opacity: 0.9,
    },
  },
  datePicker: {
    background: "#fff",
    width: 125,
    "& .MuiInput-underline:before": {
      content: "none",
    },
    "& input": {
      height: 17,
      padding: "13px 13px",
    },
  },
}));

interface ContextProps {
  propertyType: string;
  propertyTypeOptions: Array<{ [key: string]: string }>;
  marketType: string;
  classification: "residential" | "commercial" | undefined;
  paramsFields: { [key: string]: any };
  setParamsFields(val: React.SetStateAction<any>): void;
  setPropertyType(val: React.SetStateAction<any>): void;
}

interface EventProps {
  target: { value: string | number };
}

const ParamsFields = ({
  propertyType,
  setPropertyType,
  propertyTypeOptions,
  marketType,
  classification,
  paramsFields,
  setParamsFields,
}: ContextProps) => {
  const classes = useStyles();
  const setState = (key: string, newValue: string | number | Moment | null) => {
    return setParamsFields({ ...paramsFields, [key]: newValue });
  };

  const SearchByDate = () => (
    <React.Fragment>
      <div className={cn(classes.inputContainer, "mr-3")}>
        <label>Start date</label>
        <DatePicker
          clearable
          value={paramsFields.dateMin}
          onChange={(date: Moment | null) => setState("dateMin", date)}
          className={classes.datePicker}
          emptyLabel="Select date"
          format="DD/MM/YY"
        />
        <SVG src={calendarSvg} />
      </div>
      <div className={cn(classes.inputContainer, "mr-3")}>
        <label>End date</label>
        <DatePicker
          clearable
          value={paramsFields.dateMax}
          onChange={(date: Moment | null) => setState("dateMax", date)}
          className={classes.datePicker}
          emptyLabel="Select date"
          format="DD/MM/YY"
        />
        <SVG src={calendarSvg} />
      </div>
    </React.Fragment>
  );

  const SearchByLand = (label: string, keyMin: string, keyMax: string) => (
    <div className={cn(classes.inputContainer, "mr-9")}>
      <label>{label}</label>
      <div className="d-flex">
        <input
          value={paramsFields?.[keyMin] || ""}
          onChange={(event: EventProps) => setState(keyMin, event.target.value)}
          className="w-90px"
          type="text"
          placeholder="Min"
        />
        <input
          value={paramsFields?.[keyMax] || ""}
          onChange={(event: EventProps) => setState(keyMax, event.target.value)}
          className="w-90px border-left-0"
          type="text"
          placeholder="Max"
        />
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <div className={cn(classes.inputContainer, "mr-3")}>
        <label>Radius (km)</label>
        <input
          value={paramsFields.radius || ""}
          // defaultValue={"0.5"}
          onChange={(event: EventProps) =>
            setState("radius", event.target.value)
          }
          className="w-100px"
          type="text"
          placeholder="Radius"
        />
      </div>
      <div className={cn(classes.inputContainer, "mr-3")}>
        <label>Property type</label>
        <Select
          disableUnderline
          className="w-180px"
          value={propertyType}
          onChange={event => setPropertyType(event.target.value)}
        >
          {propertyTypeOptions?.map(option => (
            <MenuItem
              aria-label={option.display}
              value={option.value}
              key={option.value || option.display}
            >
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={cn(classes.inputContainer, "mr-3")}>
        <label>Price</label>
        <div className="d-flex">
          <input
            value={paramsFields.priceMin || ""}
            onChange={(event: EventProps) =>
              setState("priceMin", event.target.value)
            }
            className="w-90px"
            type="text"
            placeholder="Min"
          />
          <input
            value={paramsFields.priceMax || ""}
            onChange={(event: EventProps) =>
              setState("priceMax", event.target.value)
            }
            className="w-90px border-left-0"
            type="text"
            placeholder="Max"
          />
        </div>
      </div>
      {marketType !== "rh" && SearchByDate()}
      {classification === "residential"
        ? SearchByLand("Land", "landMin", "landMax")
        : SearchByLand("Land Area", "landAreaMin", "landAreaMax")}
    </React.Fragment>
  );
};

export default ParamsFields;
