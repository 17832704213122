import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchPopularArticles } from "redux/actions";
import cn from "classnames";
import SvgIcon from "app/components/ui/SvgIcon";
import { NoData } from "app/components/NoData";
import Loader from "app/components/ui/Loader";
import PaginationSelective from "app/components/PaginationSelective";
import {
  getIsLoadingPopularArticles,
  getPopularArticlesPages,
  getPopularArticles,
} from "redux/selectors/commonSelector";

import "./styles.scss";

export const PopularArticles = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingPopularArticles);
  const totalPages = useSelector(getPopularArticlesPages);
  const articles = useSelector(getPopularArticles);

  useEffect(() => {
    dispatch(fetchPopularArticles({ page, limit: 50 }));
  }, [dispatch, page]);

  if (isLoading) return <Loader />;

  return (
    <div className="popular-articles-page">
      <div className="footer-articles-list">
        <div className="articles-list-page-header horizontal-separator">
          <h5 className="title">Most popular articles</h5>
        </div>
        <ul className="articles-list-wrapper">
          {articles.map((item, index) => (
            <li
              key={item.article_id}
              className={cn("articles-list_item", {
                "horizontal-separator": index !== articles.length - 1,
              })}
            >
              <div className="articles-list_item-content">
                <span className="list-item_title">{item._id}</span>
                <span className="list-item_subtitle">
                  {item.count}&nbsp;Views
                </span>
              </div>
              <Link
                to={`/offices/${item.office}/articles`}
                className="articles-list_item-link"
              >
                <SvgIcon iconName="chevronRight" />
              </Link>
            </li>
          ))}
          {!articles.length && !isLoading && <NoData />}
        </ul>
        <div className="pagination">
          <PaginationSelective
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};
