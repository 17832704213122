import React, { lazy, Suspense, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { ContentRoute } from "_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import Layout from "./Layouts/Layout";
import SplashScreen from "app/components/SplashScreen";
import { Offices } from "./pages/Offices";
import Office from "./modules/Offices/Office";
import ProposalsList from "./pages/ProposalsList";
import CreateProposal from "./pages/CreateProposal";
import { Help } from "./pages/Help";
import { Dashboard } from "./pages/Dashboard";
import { Templates } from "./pages/Templates/Templates";
import CreateTemplate from "./modules/Templates/CreateTemplate";
import CreateOffice from "./modules/Offices/CreateOfiice";
import ProposalMetrics from "./pages/ProposalMetrics";
import Leaderboard from "./pages/Leaderboard";
import moment from "moment";
import { actions } from "app/modules/Auth";
import Loader from "./components/ui/Loader";
import CreateProposalByCompassId from "./pages/CreateProposalByCompassId";
import CreateMessage from "../app/modules/Messages/CreateMessage";

import { useGetUser, useGetAuth } from "redux/hooks";
import ProposalBuilder from "./pages/ProposalBuilder";
import { PopularArticles } from "_metronic/_partials/widgets/PopularArticles";

const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage"),
);

export default function BasePage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { expirationTime } = useGetAuth();
  const checkExpirationTime = moment.isMoment(expirationTime)
    ? expirationTime.format("YYYY-MM-DDTHH:mm:ssZ")
    : expirationTime;
  const { user } = useGetUser();

  const { _id: userId } = user;

  if (!userId) {
    dispatch(actions.fetchUser());
  }

  let interval = useRef();

  const timeChecker = () => {
    if (moment() > moment(checkExpirationTime) && userId) {
      clearInterval(interval.current);
      dispatch(actions.logout());
      return history.push({
        pathname: "/auth/login",
        search: "?expired=true",
        state: { expired: true },
      });
    }
  };

  useEffect(
    () => {
      interval.current = setInterval(() => {
        timeChecker();
      }, 5000);
      return () => {
        clearTimeout(interval.current);
      };
    },
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkExpirationTime],
  );

  return (
    <Suspense fallback={<Loader />}>
      <Layout>
        {!userId ? (
          <SplashScreen />
        ) : (
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Redirect from="/auth/login" to="/dashboard" />
            <ContentRoute exact path="/offices/new" component={CreateOffice} />
            <ContentRoute path="/offices/:officeID" component={Office} />
            <ContentRoute path="/dashboard" component={Dashboard} />
            <ContentRoute
              path="/popular-articles"
              component={PopularArticles}
            />
            <ContentRoute path="/builder" component={BuilderPage} />
            <ContentRoute
              exact
              path="/notifications/post"
              component={CreateMessage}
            />
            <ContentRoute path="/offices" component={Offices} />
            <ContentRoute
              exact
              path="/proposals/:appraisalId/edit"
              component={ProposalBuilder}
            />
            <ContentRoute
              exact
              path="/proposals/:appraisalId/metrics"
              component={ProposalMetrics}
            />
            <ContentRoute exact path="/proposals" component={ProposalsList} />
            <ContentRoute
              exact
              path="/create-proposal"
              component={CreateProposal}
            />
            <ContentRoute
              exact
              path="/create-proposal/:compassId"
              component={CreateProposalByCompassId}
            />
            <ContentRoute path="/help" component={Help} />
            <ContentRoute path="/leaderboard" component={Leaderboard} />
            <ContentRoute path="/templates" component={Templates} />
            <ContentRoute
              path="/admin/manager/templates/:templateId"
              component={CreateTemplate}
            />
            <Route path="/user-profile">
              <UserProfilePage />
            </Route>
          </Switch>
        )}
      </Layout>
    </Suspense>
  );
}
