import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getIsLoadingFooterWidgets,
  getFooterWidgetsData,
  getWeeks,
  getPrevAverageReadingTime,
} from "redux/selectors";
import { fetchFooterWidgetsData } from "redux/actions";
import Widget from "app/components/Widgets/Widget";
import Articles from "./Articles";
import Loader from "app/components/ui/Loader";
import SvgIcon from "app/components/ui/SvgIcon";

import { averageOpensTooltip } from "shared/constants";
import { convertSecToFormattedStr, getWidgetMetrics } from "shared/utils";

import "./styles.scss";
import { useGetUser } from "../../../../redux/hooks";

const Footer = () => {
  const { isCorporateUser } = useGetUser();
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingFooterWidgets);
  const weeks = useSelector(getWeeks);
  const {
    articles,
    readingTime,
    averageOpens,
    usersSessions,
    officesSessions,
  } = useSelector(getFooterWidgetsData);
  const prevReadingTime = useSelector(getPrevAverageReadingTime);

  const formattedTime = convertSecToFormattedStr(readingTime);
  const averageTimeMetrics = getWidgetMetrics({
    weeks,
    prev: prevReadingTime,
    cur: readingTime,
    showDiff: false,
  });

  const averageOpensNum = Number(averageOpens.split(" ")[0]);

  const clicksMetrics = getWidgetMetrics({
    weeks,
    prev: averageOpensNum,
    cur: averageOpensNum,
    showDiff: false,
  });

  useEffect(() => {
    dispatch(fetchFooterWidgetsData());
  }, [dispatch, weeks]);

  return (
    <div className="footer layout-side-padding">
      {isLoading && <Loader />}
      <div className="footer-left">
        <Widget
          label={formattedTime}
          title="Average reading time"
          variant="size-30 grey spacing-25"
          className="footer-widget horizontal-separator"
          metrics={averageTimeMetrics}
        />
        <Widget
          label={averageOpens}
          title="Average proposal clicks"
          variant="size-30 grey"
          className="footer-widget horizontal-separator"
          icon={
            <SvgIcon iconName="questionCircle" tooltip={averageOpensTooltip} />
          }
          metrics={clicksMetrics}
        />
        {isCorporateUser && (
          <>
            <Widget
              label={usersSessions}
              title="Daily Active Users"
              variant="size-30 grey"
              className="footer-widget horizontal-separator"
            />
            <Widget
              label={officesSessions}
              title="Daily Active Offices"
              variant="size-30 grey"
              className="footer-widget"
            />
          </>
        )}
      </div>
      <div className="footer-right">
        <Articles list={articles} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Footer;
