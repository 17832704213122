import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { makeStyles, Select, MenuItem } from '@material-ui/core'
import BlockContainer from '../../AppraisalDetails/BlockContainer';
import { updateCalendar } from "../../../../../redux/reducers/appraisalReducer"
import HideContainer from "../../components/HideContainer";
import Calendar from "./Calendar";

const useStyles = makeStyles(() => ({
  boldText: {
    margin: '40px 0',
    fontSize: '15px',
    textAlign: "center",
  },
}));

const AvailableCalendars = ({ availableCalendars, onSelect, selected }) => {
  if (!availableCalendars?.length) return '';
  return (
    <div className='d-flex justify-content-center mb-5'>
      <Select value={selected} onChange={onSelect}>
        {
          availableCalendars.map((calendar, index) => (
            <MenuItem key={index} value={calendar}>
              {calendar.title}
            </MenuItem>
          ))
        }
      </Select>
    </div>
  )
}

const CampaignCalendar = ({ withoutSubTitle }) => {
  const classes = useStyles();

  const calendar = useSelector(({ appraisal }) => appraisal.calendar, shallowEqual);
  const calendarItems = useSelector(({ appraisal }) => appraisal.calendar.items, shallowEqual);
  const availableCalendars = useSelector(({availableCalendars}) => availableCalendars, shallowEqual);
  const [selectedCalendar, setSelectedCalendar] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (availableCalendars.length) {
      setSelectedCalendar(availableCalendars.find((el) => el.title === calendar.selectedCalendar))
    }
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCalendars]);

  const updateCalendarItems = items => {
    dispatch(updateCalendar({ items }))
  }

  const hideSection = val => {
    dispatch(updateCalendar({ sectionHidden: val }))
  }

  const onTitleUpdate = (title) => {
    dispatch(updateCalendar({sectionTitle: title}))
  }

  const onSubtitleUpdate = (subtitle) => {
    dispatch(updateCalendar({sectionSubtitle: subtitle}))
  }

  const handleSelectCalendar = event => {
    dispatch(updateCalendar({selectedCalendar: event.target.value.title}))
    setSelectedCalendar(event.target.value);
    const items = event.target.value.items || [];
    const newCalendarItems = items.map(el => ({
      date: new Date(),
      text: el.text
    }));
    updateCalendarItems(newCalendarItems)
  }

  return (
    <HideContainer value={ calendar.sectionHidden } onChange={ hideSection }>
      <BlockContainer
        defaultTitle={calendar.sectionTitle}
        defaultSubtitle={calendar.sectionSubtitle}
        withoutSubTitle={withoutSubTitle}
        id="campaign-calendar"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubtitleUpdate}
        hide={calendar.sectionHidden}
      >
        <div className={classes.boldText}>
          {calendar.text}
        </div>
        {availableCalendars?.length > 1 &&
          <AvailableCalendars
            availableCalendars={availableCalendars}
            onSelect={handleSelectCalendar}
            selected={selectedCalendar}
          />
        }
        <Calendar
          calendarItems={calendarItems}
          onUpdate={updateCalendarItems}
        />
      </BlockContainer>
    </HideContainer>
  );
}


export default CampaignCalendar;
