import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { updateArticleStatus } from "redux/reducers";
import { Article } from "app/interfaces";
import useTrackEvent from "app/hooks/useTrackEvent";

import { Box } from "@material-ui/core";
import ArticleModal from "../../CommonComponents/ArticleModal";
import ArticleButton from "./ArticleButton";
import { AppraisalContext } from "../../AppraisalContext";

interface PopupBlockContextProps {
  articleList: Array<Article> | undefined;
}

const PopupBlock = ({
  articleList = [],
}: PopupBlockContextProps): JSX.Element => {
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();

  const [activeArticle, setActiveArticle] = useState<Article | undefined>(
    undefined,
  );
  const [articleModalShow, setArticleModalShow] = useState(false);
  const [sessionStart, setSessionStart] = useState({});
  const { preview } = useContext(AppraisalContext);

  const onUpdateArticleStatus = (id: string | undefined, status: boolean) => {
    return id ? dispatch(updateArticleStatus({ id, status })) : null;
  };

  const openArticleModal = (currentArticle: Article) => {
    setActiveArticle(currentArticle);
    setArticleModalShow(true);
    setSessionStart(moment());
  };

  const closeArticleModal = () => {
    const now = moment();
    const duration = moment.duration(now.diff(sessionStart)).as("seconds");
    trackEvent("Open article", activeArticle?.title, Math.round(duration));
    setArticleModalShow(false);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {articleList.map(articleElem => (
          <ArticleButton
            key={articleElem._id}
            label={articleElem.label || ""}
            onClick={() => openArticleModal(articleElem)}
            active={articleElem.active}
            onChangeStatus={(val: any) =>
              onUpdateArticleStatus(articleElem._id, val)
            }
            imgSrc={articleElem.iconSrc}
            imgAlt={articleElem.title}
          />
        ))}
      </Box>
      <ArticleModal
        article={activeArticle}
        show={articleModalShow}
        onHide={closeArticleModal}
        editable={activeArticle?.editable && !preview}
      />
    </React.Fragment>
  );
};

export default PopupBlock;
