import { ProposalTypes } from "../../../interfaces";
import PreList from "../../../assets/images/PreList.svg";
import ListingProposal from "../../../assets/images/ListingProposal.svg";
import MarketUpdate from "../../../assets/images/MarketUpdate.svg";
import AgentProfiling from "../../../assets/images/AgentProfiling.svg";
import ResidentialIM from "../../../assets/images/IM.svg";
import CommercialIM from "../../../assets/images/CommercialIM.svg";
import CommercialListing from "../../../assets/images/CommercialListing.svg";
import BusinessSales from "../../../assets/images/BusinessSales.svg";
import Prospecting from "../../../assets/images/Prospecting.svg";

// interface TypeInterface {
//   type: ProposalTypes;
//   label: string;
//   imgLink: string;
//   sublabel: string;
// }

const prospectType = {
  type: ProposalTypes.prospecting,
  label: "Prospecting",
  imgLink: Prospecting,
  sublabel:
    "Utilise this proposal for prospecting new staff into your business.",
};

const residentialTypes = [
  {
    type: ProposalTypes.preAppraisal,
    label: "Pre List",
    imgLink: PreList,
    sublabel: "Best used for initial contact when pitching for new listings.",
  },
  {
    type: ProposalTypes.listingProposal,
    label: "Listing Proposal",
    imgLink: ListingProposal,
    sublabel:
      "Your final proposal designed to close the deal and win the listing.",
  },
  {
    type: ProposalTypes.marketUpdate,
    label: "Market Update",
    imgLink: MarketUpdate,
    sublabel:
      "Keep your clients up to date with what is happening in their local market.",
  },
  {
    type: ProposalTypes.agentProfiling,
    label: "Agent Profiling",
    imgLink: AgentProfiling,
    sublabel:
      "The promotional template to sell you, the Sales or Leasing professional.",
  },
  {
    type: ProposalTypes.residentialInformationMemorandum,
    label: "Information Memorandum",
    imgLink: ResidentialIM,
    sublabel:
      "Showcase the best aspects of your listing through this comprehensive digital report.",
  },
  {
    type: ProposalTypes.tenantHandbook,
    label: "Tenant Information Guide",
    imgLink: ResidentialIM,
    sublabel:
      "Educate your tenants on your office's processes and procedures, to ensure a smooth tenancy.",
  },
];

const commercialTypes = [
  {
    type: ProposalTypes.informationMemorandum,
    label: "Commercial IM",
    imgLink: CommercialIM,
    sublabel:
      "Showcase the best aspects of your listing through this comprehensive digital report.",
  },
  {
    type: ProposalTypes.commercialListingProposal,
    label: "Commercial Listing Submission",
    imgLink: CommercialListing,
    sublabel:
      "Your final proposal designed to close the deal and win the listing.",
  },
];

const businessTypes = [
  {
    type: ProposalTypes.businessListingProposal,
    label: "Business Listing Proposal",
    imgLink: BusinessSales,
    sublabel:
      "Your final proposal designed to close the deal and win the listing.",
  },
];

export const allProspectTypes = [
  ...residentialTypes,
  ...commercialTypes,
  ...businessTypes,
  prospectType,
];

const getTypesList = (types: any[] | []) => {
  // @ts-ignore
  return allProspectTypes.filter(item => types.includes(item.type));
};

export default getTypesList;
