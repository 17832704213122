import React from "react";
import { useSelector } from "react-redux";
import {
  getVisited,
  getVisitedBy,
  getVisitedChanged,
} from "../../../../redux/selectors";

const VisitedDetails = () => {
  const visited = useSelector(getVisited);
  const visitedBy = useSelector(getVisitedBy);
  const updated = useSelector(getVisitedChanged);

  return (
    <div className="unit">
      <h3 className="unit_header horizontal-separator">
        Inspection Confirmation
      </h3>
      {updated.date ? (
        <div className="visit-details">
          <div className="unit_item-content">
            Ticked {`${visited ? "Yes" : "No"}`} on {`${updated.date}`} at{" "}
            {`${updated.time}`}
          </div>
          <div>by {`${visitedBy}`}</div>
        </div>
      ) : (
        <div className="visit-details">
          Ticked {`${visited ? "Yes" : "No"}`}
        </div>
      )}
    </div>
  );
};
export default VisitedDetails;
