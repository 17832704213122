import React, { useContext } from "react";

import BlockContainer from "./BlockContainer";
import HideContainer from "../components/HideContainer";
import EditorComponent from "../../Common/EditorComponent";
import { Box } from "@material-ui/core";
import { AppraisalContext } from "../AppraisalContext";
import PropTypes from "prop-types";
import Infographics from "../Commercial/Infographics";

const TenantArticleSection = ({
                                  id,
                                  data = {},
                                  onChange,
                                  onTitleChange,
                                  onHide,
                                  charactersLimit = 1100,
                                  className,
                                  withoutSeparator,
                                  withoutContainer,
                                  withInfographics = false,
                                  infographicsFolders,
                                  withInfographicsSelection = false,

                              }) => {
    const { preview } = useContext(AppraisalContext);

    if (!data && preview) return ''; // don't show section if article was not found (not active)

    const onHideChange = val => onChange({ ...data, hidden: val });
    const onTitleUpdate = val => onChange({ ...data, title: val });
    const onArticleUpdate = val => onChange({ ...data, body: val });
    const onInfographicsUpdate = val => onChange({ ...data, infographics: val })
    const Container = ({ children, ...props }) => withoutContainer
        ? children
        : <BlockContainer {...props}>{children}</BlockContainer>;

    return (
        <HideContainer value={!data.active || data.hidden} onChange={onHide || onHideChange} className={className}>
            <Container
                id={id}
                defaultTitle={data.title}
                onTitleUpdate={onTitleChange || onTitleUpdate}
                hide={!data.active || data.hidden}
                withoutSubTitle
                separator={!withoutSeparator}
            >
                <Box mt={5}>
                    <EditorComponent
                        inline
                        value={data.body}
                        onBlur={onArticleUpdate}
                        disabled={preview}
                        charactersLimit={charactersLimit}
                    />
                    { withInfographics && (
                        <Infographics
                            infographics={data.infographics}
                            onChange={onInfographicsUpdate}
                            infographicsFolders={infographicsFolders}
                            withInfographicsSelection={withInfographicsSelection}
                        />
                    )}
                </Box>
            </Container>
        </HideContainer>
    );
};

TenantArticleSection.prototype = {
    id: PropTypes.string,
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    charactersLimit: PropTypes.number,
    className: PropTypes.string,
    withoutSeparator: PropTypes.bool,
    withoutContainer: PropTypes.bool,
    infographicsFolders: PropTypes.array,
}

export default TenantArticleSection;
