import { all, put, takeLatest, call, select } from "redux-saga/effects";
import {
  fetchOfficeData,
  fetchPopularArticles,
} from "../actions/commonActions";
import {
  setOfficeData,
  setIsLoadingPopularArticles,
  setPopularArticles,
} from "../reducers/commonReducer";
import { getOfficeId } from "../selectors/commonSelector";
import OfficesService from "app/api/OfficesService";
import MetricsService from "app/api/MetricsService";

function* fetchOfficeDataSaga() {
  const officeId = yield select(getOfficeId);
  const { data } = yield call(OfficesService.getOffice, officeId);
  yield put(setOfficeData(data));
}

function* fetchPopularArticlesSaga({ payload }) {
  const { page, limit } = payload;

  yield put(setIsLoadingPopularArticles(true));
  try {
    const { data } = yield call(MetricsService.getArticles, limit, page);
    yield put(setPopularArticles(data));
  } catch (e) {
    console.log(e);
  }
  yield put(setIsLoadingPopularArticles(false));
}

export default function* watchCommon() {
  yield all([
    yield takeLatest(fetchOfficeData.type, fetchOfficeDataSaga),
    yield takeLatest(fetchPopularArticles.type, fetchPopularArticlesSaga),
  ]);
}
