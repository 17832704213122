import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';

const SwitchButton = ({ data, handlerClick, initialIndex, className }) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const onClick = (event, index) => {
    setActiveIndex(index);
    handlerClick({ event, index });
  };

  return (
    <div className="switch-button">
      {data.map((button, index) => (
        <button
          key={button.title}
          className={cn('button', { active: activeIndex === index }, { [className]: className })}
          onClick={(e) => onClick(e, index)}
        >
          {button.title}
        </button>
      ))}
    </div>
  );
};

SwitchButton.propTypes = {
  data: PropTypes.array.isRequired,
  handlerClick: PropTypes.func.isRequired,
  initialIndex: PropTypes.number,
  className: PropTypes.string,
};

SwitchButton.defaultProps = {
  initialIndex: 0,
  className: '',
};

export default SwitchButton;
