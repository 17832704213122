import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LinearProgress } from "@material-ui/core";
import { Checkbox } from "../../../../_metronic/_partials/controls";
import EditorComponent from "../../Common/EditorComponent";
import TemplatePreview from "../TemplatePreview";
import TemplatesService from "../../../api/TemplatesService";
import { getImage } from "../../Appraisals/CreateAppraisal/utils";
import { UploadImage } from "../../../api/ImageLibraryService";
import Header from "./Header";
import DeleteModal from "./DeleteModal";
import ImageInput from "./ImageInput";

const FormWrapper = ({ label, children }) => (
  <>
    <div className="form-group row">
      <label className="col-3 col-form-label">{label}</label>
      <div className="col-9">{children}</div>
    </div>
  </>
);

const saveImage = async (file, name) => {
  if (!file) return {};
  const postData = new FormData();
  postData.append("file", file);
  const {
    data: { _id },
  } = await UploadImage(undefined, undefined, postData);
  return { [name]: _id };
};

const getSlug = title =>
  title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

const Schema = Yup.object().shape({
  title: Yup.string().required("title is required"),
  editable: Yup.boolean().required("editable is required"),
  category: Yup.string().required("category is required"),
  lTDOnly: Yup.boolean(),
  body: Yup.string().required("body is required"),
  content: Yup.string(),
  icon: Yup.string(),
  image: Yup.string(),
  tabImage: Yup.string(),
});

const categorySelectables = [
  {
    display: "AU - Property Management - Residential",
    value: "residential-property-management",
  },
  {
    display: "AU - Property Management - Commercial",
    value: "commercial-property-management",
  },
  {
    display: "AU - Sales - Residential",
    value: "residential-sales",
  },
  {
    display: "AU - Sales - Commercial",
    value: "commercial-sales",
  },
  {
    display: "AU - Sales - Business",
    value: "business-sales",
  },
  {
    display: "AU - IM - Commercial",
    value: "commercial-information-memorandum",
  },
  {
    display: "AU - IM - Residential",
    value: "residential-information-memorandum",
  },
  {
    display: "AU - Corporate - Prospecting",
    value: "corporate-prospecting",
  },
  {
    display: "AU - Common",
    value: "common",
  },
  {
    display: "AU - Tenant Information Guide",
    value: "residential-tenant-handbook",
  },
  {
    display: "NZ - Property Management - Residential",
    value: "residential-property-management-nz",
  },
  {
    display: "NZ - Property Management - Commercial",
    value: "commercial-property-management-nz",
  },
  {
    display: "NZ - Sales - Residential",
    value: "residential-sales-nz",
  },
  {
    display: "NZ - Sales - Commercial",
    value: "commercial-sales-nz",
  },
  {
    display: "NZ - Sales - Business",
    value: "business-sales-nz",
  },
  {
    display: "NZ - IM - Commercial",
    value: "commercial-information-memorandum-nz",
  },
  {
    display: "NZ - IM - Residential",
    value: "residential-information-memorandum-nz",
  },
  {
    display: "NZ - Tenant Information Guide",
    value: "residential-tenant-handbook-nz",
  },
];

const CreateTemplate = props => {
  const history = useHistory();
  const [templateView, setTemplateView] = useState(false);
  const [initial, setInitial] = useState({ title: "", lTDOnly: false });
  const [isEdit, setIsEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [image, setImage] = useState();
  const [tabImage, setTabImage] = useState();
  const [loading, setLoading] = useState(false);

  const templateId = props.match?.params["templateId"];

  useEffect(() => {
    setIsEdit(false);
    if (props.location?.pathname?.includes("/admin/manager/templates")) {
      TemplatesService.get(templateId).then(response => {
        setInitial(response.data);
        setIsEdit(true);
      });
    }
  }, [props, templateId]);

  // Methods
  const addTemplate = values =>
    TemplatesService.add({
      ...values,
      slug: getSlug(values.title),
      position: "position-0",
    });

  const editTemplate = values =>
    TemplatesService.edit({ ...values }, templateId);

  const getInputClasses = fieldName => {
    if (formik.touched[fieldName]) {
      return formik.errors[fieldName] ? "is-invalid" : "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues: initial,
    validationSchema: Schema,
    onSubmit: async (values, { setStatus }) => {
      const saveChanges = isEdit ? editTemplate : addTemplate;
      setLoading(true);
      try {
        const newImage = await saveImage(image, "image");
        const newTabImage = await saveImage(tabImage, "tabImage");
        await saveChanges({ ...values, ...newImage, ...newTabImage });
        history.push("/templates");
      } catch (e) {
        setStatus("something went wrong");
      }
      setLoading(false);
    },
    enableReinitialize: true,
  });

  const onClickPreview = () => setTemplateView(true);

  const closePreview = () => setTemplateView(false);

  const onClickDelete = () => {
    TemplatesService.delete(templateId).then(() => history.push("/templates"));
  };

  const ErrorMessage = ({ field }) => {
    return formik.touched[field] && formik.errors[field] ? (
      <div className="" style={{ color: "red" }}>
        {formik.errors[field]}
      </div>
    ) : (
      ""
    );
  };

  const changeImage = (imgData, field) => {
    const reader = new FileReader();
    reader.readAsDataURL(imgData);
    reader.onloadend = () => formik.setFieldValue(field, reader.result);
  };

  const onImageChange = imgData => {
    changeImage(imgData, "image");
    setImage(imgData);
    formik.setFieldTouched("image");
  };

  const onTabImageChange = imgData => {
    changeImage(imgData, "tabImage");
    setTabImage(imgData);
    formik.setFieldTouched("tabImage");
  };

  const getImgSrc = field => {
    return formik.touched[field]
      ? formik.values[field]
      : getImage(formik.values[field]);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        onConfirm={onClickDelete}
      />
      {!templateView ? (
        <form
          className="card card-custom card-stretch"
          onSubmit={formik.handleSubmit}
        >
          <Header
            disableSave={formik.isSubmitting && !image}
            isEdit={isEdit}
            onDelete={() => setShowDelete(true)}
            onPreview={onClickPreview}
          />
          {loading && <LinearProgress color="primary" />}
          <div className="form" style={{ opacity: loading ? 0.4 : 1 }}>
            {/* begin::Body */}
            <fieldset>
              <div className="card-body">
                <FormWrapper label="Title">
                  <input
                    type="text"
                    placeholder="title"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "title",
                    )}`}
                    name="title"
                    {...formik.getFieldProps("title")}
                  />
                  <ErrorMessage field="title" />
                </FormWrapper>

                <FormWrapper label="Editable">
                  <select
                    className="form-control form-control-solid"
                    name="editable"
                    id="editable"
                    onChange={() => {
                      formik.setFieldTouched("editable", true, true);
                    }}
                    {...formik.getFieldProps("editable")}
                  >
                    <option value="">select editable</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  <ErrorMessage field="editable" />
                </FormWrapper>

                <FormWrapper label="Category">
                  <select
                    className="form-control form-control-solid"
                    name="category"
                    {...formik.getFieldProps("category")}
                  >
                    <option value="">select category</option>
                    {categorySelectables.map(item => (
                      <option value={item.value} key={item.value}>
                        {item.display}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage field="category" />
                </FormWrapper>

                <FormWrapper label="LTD office only">
                  <Checkbox
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "lTDOnly",
                    )}`}
                    isSelected={formik.values.lTDOnly}
                    name="lTDOnly"
                    onChange={event => {
                      formik.setFieldValue("lTDOnly", event.target.checked);
                      formik.setFieldTouched("lTDOnly", true, true);
                    }}
                  />
                  <ErrorMessage field="lTDOnly" />
                </FormWrapper>

                <FormWrapper label="Images">
                  <div className="d-flex">
                    <div className="d-flex flex-column">
                      <h4 className="card-label text-dark">Header</h4>
                      <ImageInput
                        url={getImgSrc("image")}
                        onChange={onImageChange}
                        aspectRatio={4.08}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="card-label text-dark">Tab</h4>
                      <ImageInput
                        url={getImgSrc("tabImage")}
                        onChange={onTabImageChange}
                        aspectRatio={1.33}
                      />
                    </div>
                  </div>
                </FormWrapper>

                <FormWrapper label="Content">
                  <EditorComponent
                    value={formik.values.body}
                    charactersLimit={1100}
                    onBlur={body => {
                      formik.setFieldValue("body", body);
                      formik.setFieldTouched("body", true, true);
                    }}
                  />
                  <ErrorMessage field="body" />
                </FormWrapper>
              </div>
            </fieldset>
          </div>
          {/* end::Form */}
        </form>
      ) : (
        <TemplatePreview
          templateData={formik.values}
          closePreview={closePreview}
        />
      )}
    </div>
  );
};

export default CreateTemplate;
