import React, {useState, useEffect, useRef} from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button, makeStyles,
  Card, CardActionArea,
  CardMedia, CardContent, Typography,
  Grid
} from "@material-ui/core";
import { Button as PrButton } from "primereact/components/button/Button";
import { Toast } from 'primereact/toast';
import produce from "immer";
import { getYoutubeParameterByName } from "../modules/Appraisals/CreateAppraisal/utils";
import FormInput from "../modules/Offices/EditOfficeTeam/FormInput";
import VideosService from "../api/VideosService";


const useStyles = makeStyles({
  root: {
    height: '100%',
    position: 'relative'
  },
  actionRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  contentRoot: {
    width: '100%'
  },
  media: {
    width: '100%',
    height: 235,
  },
  video: {
    paddingTop: '56.25%',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000
  }
});

const Video = ({id, link, title, openVideo, confirmDeleteItem}) => {

  const classes = useStyles();
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  return (
    <Card className={classes.root}>
      {
        user.permissions === 3 &&
        <PrButton icon="pi pi-trash" className={`p-button-rounded p-button-warning ${classes.deleteIcon}`} onClick={() => confirmDeleteItem(id)} />
      }
      <CardActionArea className={classes.actionRoot} onClick={() => openVideo(link)}>
        <CardMedia
          className={classes.media}
          image={`http://i3.ytimg.com/vi/${link}/hqdefault.jpg`}
          title={title}
        />
        <CardContent className={classes.contentRoot}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export const Help = () => {

  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const [videos, setVideos] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [videoObj, setVideoObj] = useState({link: '', title: ''});
  const [currentVideo, setCurrentVideo] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletableVideo, setDeletableVideo] = useState();

  const toast = useRef(null);

  const classes = useStyles();

  useEffect (() => {
    VideosService.getVideos()
      .then((resp) => {
        setVideos(resp.data)
      })
  }, []);

  const onSubmit = () => {
    let newVideo = produce(videoObj, draft => {
      draft.link = getYoutubeParameterByName('v', videoObj.link);
    });
    VideosService.postVideo(newVideo)
      .then(resp => {
        let newVideos = produce(videos, draft => {
          draft.push(resp.data)
        });
        setVideos(newVideos);
        setShowAddDialog(false);
      }).catch(err => {
        const resp = err.response.data
        toast.current.show({ severity: 'error', summary: 'Error', detail: `${resp.message ? resp.message : resp.error.message}`, life: 3000 });
      })
  }

  const openVideo = (link) => {
    setCurrentVideo(link)
    setShowVideoDialog(true)
  }

  const onDelete = () => {
    VideosService.deleteVideo(deletableVideo)
    .then(resp => {
      setVideos(videos.filter(v => v._id !== deletableVideo));
      setDeletableVideo("");
      setShowDeleteDialog(false);
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Video successfully deleted', life: 3000 });
    })
  }

  const confirmDeleteItem = (id) => {
    setDeletableVideo(id);
    setShowDeleteDialog(true);
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="card card-custom card-stretch">
        <div className="card-header flex-nowrap px-5">
          <div className="card-title flex-column-auto">
            <h3 className="card-label font-weight-bolder text-dark">
              Help Centre
            </h3>
          </div>
          {
            user.permissions === 3 &&
            <div className="ml-5 my-3">
              <button
                onClick={() => setShowAddDialog(true)}
                className="pull-right btn btn-primary w-120px h-100"
              >
                Add video
              </button>
            </div>
          }
        </div>
        <div className="card-body p-5">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={3}
          >
            {
              videos.map(video => (
                <Grid item xs={12} sm={6} md={3} key={video._id}>
                  <Video
                    id={video._id}
                    link={video.link}
                    title={video.title}
                    openVideo={openVideo}
                    confirmDeleteItem={confirmDeleteItem}
                  />
                </Grid>
              ))
            }
          </Grid>
        </div>
      </div>
      <Dialog open={showAddDialog} onClose={() => setShowAddDialog(false)} fullWidth>
        <DialogTitle>Add Video</DialogTitle>
        <DialogContent>
          <FormInput
            title="Video link"
            placeholder="Video link"
            value={videoObj.link}
            onChange={val => setVideoObj({ ...videoObj, ...{ link: val } })}
          />
          <FormInput
            title="Video Title"
            placeholder="Video Title"
            value={videoObj.title}
            onChange={val => setVideoObj({ ...videoObj, ...{ title: val } })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} className="btn btn-primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showVideoDialog} onClose={() => setShowVideoDialog(false)} maxWidth="md" fullWidth>
        <DialogContent>
          <div className={classes.video}>
            <iframe
              title='youtube'
              src={`https://www.youtube.com/embed/${currentVideo}?color=white&modestbranding=1&showinfo=0&rel=0`}
              allowFullScreen="allowfullscreen"
              frameBorder="0">
            </iframe>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        maxWidth="md"
      >
        <DialogTitle>Delete video?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure that you want to delete this video?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={onDelete} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
