import React, { useState, useMemo } from "react";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";

import { TabsContextProps } from "app/interfaces";
import { HideTab, Tabs as CustomTabs } from "../../../Common/CustomizedTabs";
import EditorComponent from "../../../Common/TypeScript/EditorComponent";
import useTrackEvent from "../../../../hooks/useTrackEvent";

const useStyles = makeStyles(theme => ({
  tabContent: {
    marginTop: 10,
    marginBottom: 10,
    "& .mce-edit-focus": {
      maxHeight: "100% !important",
    },
    "& img": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

interface ContextProps {
  description: string;
  className?: string;
  onChange?(value: string): void;
  media: any;
  openAdditionalModal?: () => void;
}

const TabContent = ({
  description,
  onChange,
  media,
  className,
  openAdditionalModal,
}: ContextProps) => (
  <Grid container spacing={3} className={className}>
    <Grid item xs={12} sm={7}>
      <EditorComponent
        type="methodOfSale"
        inline
        value={description}
        onBlur={onChange}
        charactersLimit={1000}
        hardLimit
        disabled={!onChange}
        openAdditionalModal={openAdditionalModal}
      /> 
    </Grid>
    <Grid item xs={12} sm={5}>
      {media}
    </Grid>
  </Grid>
);

const Tabs = ({ tabsContent }: { tabsContent: Array<TabsContextProps> }) => {
  const [activeTabKey , setActiveTabKey] = useState(tabsContent[0].key);
  const activeTab = useMemo(() => tabsContent.find(item => item.key === activeTabKey), [tabsContent, activeTabKey])

  const trackEvent = useTrackEvent();
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const onHideTab = (isHideTab: boolean, tabContent: TabsContextProps, tabIndex: number) => {
    if(activeTabKey === tabContent.key && isHideTab){
      const nextActiveTab = tabsContent.find((item, index ) => (index > tabIndex) && !item.isHiddenTab)
      if(nextActiveTab) {
        setActiveTabKey(nextActiveTab.key)
      }
    }
    return tabContent.onHideChange(isHideTab);
  }

  return (
    <div>
      <CustomTabs
        value={activeTabKey}
        onChange={(e: any, newTabKey: string) => setActiveTabKey(newTabKey)}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? "scrollable" : "fullWidth"}
        scrollButtons={isMobile ? "on" : "auto"}
        centered={!isMobile}
      >
        {tabsContent.map((tabContent, tabIndex) => (
          <HideTab
            key={tabContent.key}
            label={tabContent.label}
            hide={tabContent.isHiddenTab}
            onHideChange={(isHideTab: boolean) => onHideTab(isHideTab, tabContent, tabIndex) }
            value={tabContent.key}
            onClick={() => trackEvent("Marketing tab click", tabContent.label)}
          />
        ))}
      </CustomTabs>
      <TabContent
        description={activeTab?.body || ""}
        onChange={activeTab?.changeBody}
        media={activeTab?.media}
        className={classes.tabContent}
        openAdditionalModal={activeTab?.openAdditionalModal}
      />
    </div>
  );
};

export default Tabs;
