import { Link } from "react-router-dom";
import React from "react";

const Header = ({ isEdit, onPreview, onDelete, disableSave }) => (
  <div className="card-header py-3">
    <div className="card-title align-items-start flex-column">
      <h3 className="card-label font-weight-bolder text-dark">
        { isEdit ? "Edit Template" : "New Template" }
      </h3>
      <span className="text-muted font-weight-bold font-size-sm mt-1">
        { isEdit ? "Update Template Details" : "Create New Template" }
      </span>
    </div>
    <div className="card-toolbar">
      { !isEdit ? '' :
        <button
          type="button"
          className="btn btn-danger mr-2"
          onClick={onDelete}
        >
          Delete
        </button>
      }
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={onPreview}
      >
        Preview
      </button>
      <button
        type="submit"
        className="btn btn-success mr-2"
        disabled={disableSave}
      >
        Save
      </button>
      <div>
        <Link to="/templates" className="btn btn-secondary">
          Cancel
        </Link>
      </div>
    </div>
  </div>
)

export default Header;
