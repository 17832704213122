/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import {
  Badge,
  Tooltip,
  withStyles,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownItemToggler } from "../../../../_partials/dropdowns";
import NotificationsIcon from "../../../../../app/assets/images/Notifications.svg";
import { getTotalUnreadedMessagesCount } from "redux/selectors/messagesSelector";
import MessagesBoard from "app/modules/Messages";
import { fetchMessagesList } from "redux/actions";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "16px",
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    color: "white",
  },
}))(Badge);

export function UserNotificationsDropdown() {
  const dispatch = useDispatch();
  const [isShowPanel, setIsShowPanel] = useState(false);
  const classes = useStyles();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);
  const totalUnreadedMessages = useSelector(getTotalUnreadedMessagesCount);
  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);

  useEffect(() => {
    dispatch(
      fetchMessagesList({ speciality: currentUser?.office?.speciality }),
    );
  }, [currentUser, dispatch]);

  return (
    <>
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title="User Notifications"
              placement="right"
            >
              <Button
                onClick={() => setIsShowPanel(!isShowPanel)}
                className="menu-link nav-link btn btn-icon btn-lg btn-default"
                role="tab"
              >
                <StyledBadge
                  badgeContent={totalUnreadedMessages}
                  color="primary"
                >
                  <span
                    className={`svg-icon svg-icon-xl ${
                      !totalUnreadedMessages ? "" : "svg-icon-primary"
                    }`}
                  >
                    <SVG src={NotificationsIcon} />
                  </span>
                </StyledBadge>
              </Button>
            </Tooltip>
          </Dropdown.Toggle>
          <MessagesBoard
            isShowPanel={isShowPanel}
            setIsShowPanel={setIsShowPanel}
          />
        </Dropdown>
      )}
    </>
  );
}
