import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux/store";
import { updateMeta, updateTeamVideo } from "redux/reducers";
import Separator from "../CommonComponents/Separator";
import HideContainer from "../components/HideContainer";
import { UpdateVideoLinkButton, YoutubeVideo } from "../CommonComponents";


export const AgentVideoModule = ({
  isVideoFromSetting = false,
  withSeparator = false,
}) => {
  const dispatch = useDispatch();

  const teamData = useSelector(
    (state: RootState) => state.appraisal.team,
    shallowEqual,
  );
  const genericVideoHidden = useSelector(
    (state: RootState) => state.appraisal.meta.agentVideoHidden,
  );

  const videoUrlFromState = teamData.videos[1];

  const onVideoHiddenChange = (val: boolean) => {
    dispatch(updateMeta({ agentVideoHidden: val }));
  };

  const onUpdateVideoUrl = (newUrl: string) => {
    dispatch(updateTeamVideo({ videoIndex: 1, newUrl }));
  };

  if (isVideoFromSetting && !videoUrlFromState) {
    return <React.Fragment />;
  }

  return (
    <HideContainer
      title="Hide video"
      value={genericVideoHidden}
      onChange={onVideoHiddenChange}
    >
      <UpdateVideoLinkButton
        currentVideoLink={videoUrlFromState}
        onUpdate={onUpdateVideoUrl}
        className="m-6"
      />
      <YoutubeVideo video={videoUrlFromState} />
      {withSeparator ? <Separator space={3} /> : <React.Fragment />}
    </HideContainer>
  );
};
