import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

import { WidgetMetric } from "shared/components";

import "./styles.scss";

const WidgetLink = ({ to, label, title, variant, metrics }) => {
  const linkRef = useRef();

  const onMouseHandler = isTruth => {
    if (isTruth) {
      linkRef.current.classList.add(variant);
    } else {
      linkRef.current.classList.remove(variant);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Link
        ref={linkRef}
        to={to}
        className="widget-link"
        onMouseEnter={() => onMouseHandler(true)}
        onMouseLeave={() => onMouseHandler(false)}
      >
        <div className={`widget_label ${variant}`}>{label}</div>
        <span className={`widget_title hover-active`}>{title}</span>
      </Link>
      {metrics && <WidgetMetric {...metrics} />}
    </Box>
  );
};

WidgetLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["yellow", "red"]).isRequired,
};

export default WidgetLink;
