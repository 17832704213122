import React, { useEffect, useState } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  drawerItem: ({ squeeze }) =>  ({
    [theme.breakpoints.down('sm')]: {
      lineHeight: "20px"
    },
    fontSize: squeeze ? 16 : 18,
    lineHeight: squeeze ? "22px" : "25px",
    marginBottom: squeeze ? 9 : 10,
    cursor: "pointer",
    borderBottom: "3px solid transparent",
    transition: 'border-color 0.3s ease',
    "&:last-child": {
      marginBottom: "0 !important",
    }
  }),
  activeItem: {
    borderColor: `${theme.palette.primary.main} !important`
  }
}))

const ListElement = ({ id, title, hide, onSelect, squeeze }) => {

  const [isActive, setActive] = useState(false);
  const [elem, setElem] = useState(document.querySelector(`#preview-container #${id}`))

  const scrollHandler = () => {
    const element = document.querySelector(`#preview-container #${id}`);
    if (element) {
      const { y: topPos, height } = element.getBoundingClientRect();
      const bottomPos = topPos + height ;
      const active = topPos - 66 <= 0 && bottomPos - 66 > 0; // 66 - height of header
      setActive(active);
    }
  }

  // TODO: FIX
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const element = document.querySelector(`#preview-container #${id}`)
    if (element) {
      setElem(element);
      const root = document.querySelector(`#preview-container`);
      root.addEventListener('scroll', scrollHandler)
    }
    return () => {
      const root = document.querySelector(`#preview-container`);
      root.removeEventListener("scroll", scrollHandler);
    }
  });

  const classes = useStyles({squeeze});


  const scrollToId = () => {
    if (onSelect) onSelect();
    setElem(document.querySelector(`#preview-container #${id}`));
    setTimeout(
      () => elem.scrollIntoView({ behavior: 'smooth', block: 'start' }),
      0
    )
  }
  if (hide || !elem) return '';

  return (
    <div className={cn(classes.drawerItem, {[classes.activeItem]: isActive })} onClick={scrollToId}>
      {title}
    </div>
  )
}

export default ListElement;

