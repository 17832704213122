import { all, spawn } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import common from './reducers/commonReducer';
import appraisal from './reducers/appraisalReducer';
import availableCalendars from './reducers/availableCalendarsReducer';
import proposals from './reducers/proposalsReducer';
import messages from "./reducers/messagesReducer";
import dashboard from "./reducers/dashboardReducer";
import metrics from "./reducers/metricsReducer";
import createProposal from "../app/modules/CreateProposal/redux/reduxSlice";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import watchCommon from "./saga/commonSaga";
import watchAppraisals from "./saga/appraisalSaga";
import watchProposals from "./saga/proposalsSaga";
import watchDashboard from "./saga/dashboardSaga";
import watchMetrics from "./saga/metricsSaga";
import watchCreateProposal from "../app/modules/CreateProposal/redux/saga";
import watchMessages from "./saga/messagesSaga";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  common,
  appraisal,
  availableCalendars,
  proposals,
  dashboard,
  metrics,
  createProposal,
  messages,
});

export function* rootSaga() {
  yield spawn(auth.saga);
  yield all([
    watchCommon(),
    watchAppraisals(),
    watchProposals(),
    watchDashboard(),
    watchMetrics(),
    watchCreateProposal(),
    watchMessages(),
  ]);
}
