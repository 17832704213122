import React, { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import produce from "immer";

import { updateCommercialField } from "redux/reducers";
import AttachmentIcon from "./AttachmentIcon";
import { AppraisalContext } from "../../AppraisalContext";
import { getIcon } from "./utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BlockContainer from "../../AppraisalDetails/BlockContainer";
import HideContainer from "../../components/HideContainer";
import UrlModal from "./UrlModal";
import IconModal from "./IconModal";

const Attachments = () => {
  const { preview } = useContext(AppraisalContext);
  const attachments = useSelector(({ appraisal }) => appraisal.commercial.attachments, shallowEqual,) || {};
  const dispatch = useDispatch();
  const [editUrlIndex, setEditUrlIndex] = useState(undefined);
  const [editIconIndex, setEditIconIndex] = useState(undefined);

  const onChange = data => dispatch(updateCommercialField({ data, name: "attachments" }));
  const onHideChange = val => onChange({ ...attachments, hidden: val });
  const onTitleUpdate = val => onChange({ ...attachments, title: val });
  const onAttachmentChanged = (newValue, index) => {
    let updatedAttachments = produce(attachments, draft => {
      draft.items[index] = { ...draft.items[index], ...newValue };
    });
    onChange(updatedAttachments);
  };

  const updateUrl = url => {
    let updatedAttachments = produce(attachments, draft => {
      draft.items[editUrlIndex].url = url;
    });
    onChange(updatedAttachments);
    setEditUrlIndex(undefined);
  };

  const updateIcon = type => {
    let updatedAttachments = produce(attachments, draft => {
      draft.items[editIconIndex].type = type;
    });
    onChange(updatedAttachments);
    setEditIconIndex(undefined);
  };

  const onAttachmentClick = (index) => {
    if (preview) {
      let url = attachments.items[index].url;
      if (!url) return;
      if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      setEditIconIndex(index);
    }
  };

  return (
    <HideContainer value={attachments.hidden} onChange={onHideChange}>
      <BlockContainer
        id="attachments"
        defaultTitle={attachments.title}
        onTitleUpdate={onTitleUpdate}
        hide={attachments.hidden}
        withoutSubTitle
      >
        <Grid container spacing={3} justify="space-around">
          {attachments.items?.map((item, index) =>
            preview && !item.active ? (
              ""
            ) : (
              <Grid item xs={6} sm={4} key={`${index}-${item.type}`}>
                <AttachmentIcon
                  icon={getIcon(item.type)}
                  title={item.title}
                  active={item.active}
                  onChange={newVal => onAttachmentChanged(newVal, index)}
                  onClick={() => onAttachmentClick(index)}
                  onURLClick={() => setEditUrlIndex(index)}
                />
              </Grid>
            ),
          )}
        </Grid>
        <UrlModal
          value={attachments?.items[editUrlIndex]?.url}
          onChange={updateUrl}
          onHide={() => setEditUrlIndex(undefined)}
          show={editUrlIndex !== undefined}
        />
        <IconModal
          show={editIconIndex !== undefined}
          onHide={() => setEditIconIndex(undefined)}
          onApply={(val) => updateIcon(val)}
          type={attachments?.items[editIconIndex]?.type}
        />
      </BlockContainer>
    </HideContainer>
  );
};

export default Attachments;
