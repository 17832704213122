import { Box, DialogActions, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const commonBtnStyles = {
  height: '100%',
  padding: "0 40px",
  display: 'flex',
  alignItems: 'center',
  "&:hover": {
    cursor: 'pointer',
    "&>span": {
      opacity: 0.5
    }
  }
}

const actionStyles = theme => ({
  cancel: {
    color: '#5A5A5A',
    textDecoration: 'underline',
    ...commonBtnStyles
  },
  submit: {
    backgroundColor: theme.palette.primary.main,
    color: '#333',
    ...commonBtnStyles
  },
  container: {
    height: 50,
    borderTop: "1px solid",
    borderColor: "#E0E3E7",
    lineHeight: '19px',
    fontWeight: 600,
    fontSize: 15,
    padding: 0,
    justifyContent: 'space-between'
  }
})

const ModalActions = withStyles(actionStyles)( props => {
  const {
    classes,
    cancelTitle = 'Cancel',
    submitTitle = 'Save',
    onCancel, onSubmit

  } = props;
  return (
    <DialogActions classes={{ root: classes.container }}>
      {!onCancel ? <div/> : (
        <Box className={classes.cancel} onClick={onCancel}>
          <span>{cancelTitle}</span>
        </Box>
      )}
      <Box className={classes.submit} onClick={onSubmit}>
        <span>{submitTitle}</span>
      </Box>
    </DialogActions>
  )
})

ModalActions.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  cancelTitle: PropTypes.string,
  submitTitle: PropTypes.string,
}

export { ModalActions };
