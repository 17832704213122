import React, { useState } from "react";
import nanoid from "nanoid";
import { compose } from "redux";
import { Box, withStyles } from "@material-ui/core";
import cn from "classnames";
import SelectedProps from "../PropertieList/SelectedProps";
import NoItems from "../PropertieList/NoItems";
import comparables from "app/assets/images/comparable-empty-icon.svg";
import PropertiesManualListStyles from "./PropertiesManualList.style";
import DefaultTable from "../components/PropertyTables/DefaultTable";
import ManualCommercialTable from "../components/PropertyTables/ManualCommercialTable";
import ManualBusinessListingTable from "../components/PropertyTables/ManualBusinessListingTable";
import { useBusinessListing } from "app/hooks";

const HeaderText = withStyles(theme => ({
  root: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "21px",
    color: "#333",
    paddingLeft: theme?.spacing(3),
  },
}))(Box);

const PropertiesManualList = ({
  isRental,
  onAddItem,
  onDeleteItem,
  selectedItems,
  isCommercial,
  classes,
  tabType,
}) => {
  const { isBusinessListing } = useBusinessListing();
  const [isAddressEmpty, setIsAddressEmpty] = useState(false);
  const [property, setProperty] = useState({});
  const addItem = () => {
    setIsAddressEmpty(!property.address ? true : false);
    const result = {
      id: nanoid(),
      manually: true,
      isCommercial: true,
      ...property,
    };
    if (property.address) {
      onAddItem(result);
      setProperty({});
    }
  };

  return (
    <div className="d-flex h-100">
      <div className="w-50 pt-3 position-relative">
        <div className={classes.propertiesContainer}>
          <div className={classes.title}>{`Create ${
            isBusinessListing ? "Business" : "Property"
          }`}</div>
          {isBusinessListing ? (
            <ManualBusinessListingTable
              classes={classes}
              property={property}
              setProperty={setProperty}
              isAddressEmpty={isAddressEmpty}
            />
          ) : isCommercial ? (
            <ManualCommercialTable
              classes={classes}
              property={property}
              setProperty={setProperty}
              isAddressEmpty={isAddressEmpty}
              type={
                tabType === "off"
                  ? "On the Market"
                  : tabType === "on"
                  ? "Market Sales"
                  : null
              }
            />
          ) : (
            <DefaultTable
              classes={classes}
              isCommercial={isCommercial}
              property={property}
              setProperty={setProperty}
              isAddressEmpty={isAddressEmpty}
              type={
                tabType === "off"
                  ? "On the Market"
                  : tabType === "on"
                  ? "Market Sales"
                  : null
              }
            />
          )}
          <div className={classes.addButtonBlock}>
            <div onClick={() => addItem()} className={classes.addButton}>
              <span>Add</span>
            </div>
          </div>
        </div>
      </div>
      <div className={cn("w-50 pt-3 position-relative", classes.selectedItems)}>
        <HeaderText style={{ paddingTop: "10px" }}>
          {`Selected ${isBusinessListing ? "Businesses" : "Properties"}`}
        </HeaderText>
        {!selectedItems.length ? (
          <NoItems
            src={comparables}
            text={
              <>
                <p>No comparable {isRental ? "leases" : "sales"} selected.</p>
                <p>Add your first from the left.</p>
              </>
            }
          />
        ) : (
          <>
            <div className={classes.itemContainer}>
              <SelectedProps items={selectedItems} onDelete={onDeleteItem} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(PropertiesManualListStyles)(PropertiesManualList);
