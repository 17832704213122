import React, { memo } from "react";
export const FieldBuilder = memo(
  ({
    className,
    label,
    placeholder,
    value = "",
    onChange = () => null,
    type = "text",
    step,
    isRequired,
    isPreClick,
  }) => {
    const [inputValue, setInputValue] = React.useState(value || "");
    React.useEffect(() => {
      setInputValue(value || "");
    }, [value]);
    return (
      <React.Fragment>
        <div className={className}>
          <label>{label}</label>
          <div className="d-flex">
            <input
              value={inputValue}
              onChange={event => setInputValue(event.target.value)}
              onBlur={() => onChange(inputValue)}
              type={type}
              placeholder={placeholder}
              step={step}
              onClick={() => isPreClick && !inputValue && setInputValue("$")}
            />
          </div>
        </div>
        {isRequired ? (
          <small className="p-error">Address is required.</small>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  },
);
