import React, {
  useEffect,
  useState,
} from "react";

import { ChonkyActions, FullFileBrowser } from "chonky";
import { makeStyles, CircularProgress } from "@material-ui/core";
import CropImageModal from "./components/CropImageModal";
import FolderModal from './components/FolderModal'

import { useFileActionHandler } from './useFileActionHandler';
import * as ImageLibraryService from "../../../api/ImageLibraryService";
import * as utils from "./utils";
import { dataURLtoFile } from "../../Images/utils";
export { default as ImageLibraryModal } from "./components/Modal"

const useStyles = makeStyles(() => ({
  browserWrapper: ({ loading }) => ({
    height: '100%',
    position: "relative",
    "& .chonky-chonkyRoot": {
      opacity: loading ? 0.4 : 1,
      border: "none"
    }
  }),
  loading: ({ loading }) => ({
    display: loading ? "initial" : "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }),
}));

export let selectImage = () => {}

export const ImageLibrary = ({
    officeId,
    defaultFolder,
    onChangeSelection,
    aspectRatio,
    onImageSelected,
    withMultipleUpload
  }) => {

  const uploadImageRef = React.useRef(null);

  const [fileMap, setFileMap] = useState([])
  const [currentFolderId, setCurrentFolderId] = useState('rootFolderId');
  const [cropModalData, setCropModalData] = useState(false);
  const [folderModalData, setFolderModalData] = useState(null)
  const [loading, setLoading] = useState(false);

  // FILE LIST INITIALIZATION
  useEffect(() => {
    setLoading(true);
    utils.fetchFiles(officeId, defaultFolder)
      .then(({fileMap, rootFolderId}) => {
        setFileMap(fileMap);
        setCurrentFolderId(rootFolderId);
      })
      .finally(() => setLoading(false))
      // TODO: FIX
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  let files = utils.useFiles(fileMap, currentFolderId);
  files = files?.filter((file) => !file?.name?.includes("_cropped"));

  const folderChain = utils.useFolderChain(fileMap, currentFolderId);
  const fileActions = utils.useActionList(fileMap?.[currentFolderId], withMultipleUpload);

  selectImage = async image => {
    if (!!aspectRatio && !( await utils.checkImageSize(image.thumbnailUrl, aspectRatio) )) {
      setCropModalData(image);
    } else {
      onImageSelected(image);
    }
  }

  const onOpen = async ({ folderId, image, isDir }) => {
    if (isDir) {
      setCurrentFolderId(folderId);
    } else {
      await selectImage(image)
    }
  }

  const onDelete = newFileList => setFileMap(newFileList);
  const onMove = newFileList => setFileMap(newFileList);

  const onUploadFiles = () => uploadImageRef.current.click();
  const onUploadFilesSelected = async event => {
    if (withMultipleUpload) return await uploadFiles(event.target.files);
    let file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    if(!aspectRatio) return await uploadFiles([file]);

    const compressedFile = await utils.compressFile(file);
    const imageData = await utils.getImageData(compressedFile);
    if (await utils.checkImageSize(imageData, aspectRatio)) return await uploadFiles([file]);
    setCropModalData({ img: imageData, name: compressedFile.name });
  };

  const uploadFiles = async files => {
    setLoading(true);
    const onFileUploaded = aspectRatio ? afterCrop : ()=>{};
    const newFileList = await utils.uploadFiles(files, currentFolderId, officeId, fileMap, onFileUploaded );
    setLoading(false);
    setFileMap(newFileList);
  }

  const onCropImage = ({ thumbnailUrl, name }) => setCropModalData({ img: thumbnailUrl, name });
  const onCropImageModalClose = () => setCropModalData(undefined);
  const onCropImageDone = async (file, name) => {
    setCropModalData(undefined);
    const dotIndex = name.lastIndexOf(".");
    const newName = name.slice(0, dotIndex) + '_cropped' + name.slice(dotIndex);
    await uploadFiles([ dataURLtoFile(file, newName) ])
  }
  const afterCrop = file => onImageSelected(utils.getFileObject(file));

  const onFolderClose = () => setFolderModalData(null);
  const onFolderDone = data => {
    setFolderModalData(null);
    data.id ? onRenameDone(data) : onCreateFolderDone(data);
  }

  const onRename = folder => setFolderModalData(folder);
  const onRenameDone = async folder => {
    setLoading(true);
    const { data: { title, _id } } = await ImageLibraryService.UpdateFolder({ title: folder.name, id: folder.id})
    setLoading(false);
    setFileMap( currentFileMap => ({ ...currentFileMap, [_id]: { ...currentFileMap[_id], name: title }}))
  }

  const onCreateFolder = () => setFolderModalData({ shared: currentFolderId === 'SharedFolders' });
  const onCreateFolderDone = async newFolder => {
    setLoading(true);
    const newFileList = await utils.createFolder(newFolder, currentFolderId, officeId, fileMap)
    setLoading(false);
    setFileMap(newFileList)
  }

  const handleFileAction = useFileActionHandler({
    fileList: fileMap,
    onOpen,
    onDelete,
    onCreateFolder,
    onMove,
    onUploadFiles,
    onCropImage,
    onRename,
    onChangeSelection
  });

  const classes = useStyles({ loading });

  return (
    <div className={classes.browserWrapper}>
      <div className={classes.loading}>
        <CircularProgress style={{ height: 70, width: 70 }}/>
      </div>
      <input
        type="file"
        accept="image/*"
        id="image-input"
        ref={uploadImageRef}
        style={{ display: "none" }}
        onChange={onUploadFilesSelected}
        multiple={withMultipleUpload}
      />
      <FullFileBrowser
        files={files}
        folderChain={folderChain}
        fileActions={fileActions}
        onFileAction={handleFileAction}
        disableDefaultFileActions={[
          ChonkyActions.ToggleHiddenFiles.id,
          ChonkyActions.SortFilesBySize.id,
          ChonkyActions.OpenSelection.id,
          ChonkyActions.SelectAllFiles.id,
          ChonkyActions.ClearSelection.id,
        ]}
      />
      <CropImageModal
        data={cropModalData}
        show={!!cropModalData}
        onClose={onCropImageModalClose}
        onCrop={onCropImageDone}
        aspectRatio={aspectRatio}
      />
      <FolderModal
        show={!!folderModalData}
        data={folderModalData}
        onDone={onFolderDone}
        onClose={onFolderClose}
      />
    </div>
  );
};


export default React.memo(ImageLibrary);
