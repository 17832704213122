import React, { useState } from "react";
import TabsList from "app/modules/ProposalsList/TabsList";
import ProposalsWidget from "app/modules/ProposalsList/Widget";
import BigYellowButton from "app/components/ui/Buttons/BigYellowButton";

import "./styles.scss";
import DateSelector from "../../components/DateSelector";

const ProposalsList = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="proposals-list-page-header horizontal-separator">
        <h1 className="title">Proposals</h1>
        <DateSelector withCustomDates />
        <div className="header-button-wrapper">
          <BigYellowButton to="/create-proposal">
            Create new proposal
          </BigYellowButton>
        </div>
      </div>
      <ProposalsWidget activeTab={activeTab} />
      <TabsList onTabClick={setActiveTab} />
    </>
  );
};

export default ProposalsList;
