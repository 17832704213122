import React from 'react';
import { useDispatch } from 'react-redux';
import Tabs from 'app/components/Tabs';
import ListItems from './ListItems';
import { fetchShortProposals } from 'redux/actions';

import {
  UNOPENED,
  RECENT,
} from 'app/constants/proposalsConstants';

import './styles.scss';

const ProposalsSection = () => {
  const dispatch = useDispatch();

  const data = [
    {
      button: { title: 'Unopened - Past 48hrs', variant: UNOPENED },
      component: ListItems,
      componentProps: {
        appraisalStatus: UNOPENED,
        viewAllLink: '/proposals?type=unopened',
        serviceAction: (limit, page) => dispatch(
          fetchShortProposals({ status: UNOPENED, filter: "sent_at", limit, page, excludeTimeInMS: 172800000 }),
        ),
      },
    },
    {
      button: { title: 'Recently sent', variant: RECENT },
      component: ListItems,
      componentProps: {
        appraisalStatus: RECENT,
        viewAllLink: '/proposals',
        serviceAction: (limit, page) => dispatch(
          fetchShortProposals({ filter: "sent_at", limit, page }),
        ),
      },
    },
  ];

  return (
    <div className="proposals-tabs">
      <Tabs data={data} />
    </div>
  );
};

export default ProposalsSection;
