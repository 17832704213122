export const getIsLoadingMessagesList = ({ messages }) =>
  messages.isLoadingMessagesList;
export const getMessagesList = ({ messages }) =>
  messages.messagesList.messages || [];
export const getMessagesTotalPages = ({ messages }) =>
  messages.messagesList.pages;
export const getMessagesTotal = ({ messages }) => messages.messagesList.total;
export const getTotalUnreadedMessagesCount = ({ messages }) =>
  messages.messagesList.totalUnreaded || 0;
export const getUnreadedMessages = ({ messages }) =>
  messages.messagesList.unreadedMessages || [];
