import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';

import GridLinksList from "app/components/GridLinksList";
import { formatDateISO8601ToDdMmYy } from "shared/utils";
import PaginationSelective from "app/components/PaginationSelective";
import Loader from "app/components/ui/Loader";

import { API_URL } from '../Auth/_redux/authCrud';
import useDebounce from '../../hooks/useDebounce';
import SearchBox from '../../modules/SearchBox';
import {shallowEqual, useSelector} from "react-redux";


const OfficeTeams = ({ id }) => {
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const history = useHistory();
  const totalPages = useRef(null);

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const debouncedSearchTerm = useDebounce(search, 500);

  const getTeams = () => {
    const url = `${API_URL}teams?office=${id}&search=${search}&page=${page}&limit=50`;
    setLoading(true);
    axios
      .get(url)
      .then(({ data }) => {
        const teams = data.teams.map(team => ({
          id: team._id,
          name: team.name,
          updated: formatDateISO8601ToDdMmYy(team.updated_at),
          timeStamp: Date.parse(team.updated_at),
        }));
        totalPages.current = data.pages;
        setTeams(teams.reverse());
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getTeams();
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, debouncedSearchTerm, page]);

  const columnsTitles = [
    { title: 'Profile name', sortLabel: 'name' },
    { title: 'Updated', sortLabel: 'updated', isDate: true },
  ];

  const listData = teams.map(team => ({
    id: team.id,
    link: `/offices/${id}/teams/${team.id}`,
    mainData: [
      { content: team.name, sortLabel: 'name' },
      { content: team.updated, sortLabel: 'updated', timeStamp: team.timeStamp },
    ],
  }));

  return (
    <>
      <div className="card-header flex-nowrap px-5">
        <div className="card-title flex-column-auto">
          <h3 className="card-label font-weight-bolder text-dark">
            Profile Centre
          </h3>
        </div>

        <div className="ml-5 my-3">
          <button
            onClick={() => {history.push(`/offices/${id}/teams/new`)}}
            className="pull-right btn btn-primary w-130px h-100"
          >
            {user.permissions < 2 ? 'Create My Profile' : 'New Profile'}
          </button>
        </div>
      </div>
      <div className="m-5">
        <SearchBox onChangeSearch={val => setSearch(val)} />
      </div>
      <div className="card-body p-0 p-relative">
        {loading && <Loader />}
        <GridLinksList
          content={listData}
          columnsTitles={columnsTitles}
        />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '82px' }}>
          <PaginationSelective
            page={page}
            setPage={setPage}
            totalPages={totalPages.current}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(OfficeTeams);
