import { DatePicker } from "@material-ui/pickers";
import React from "react";

const CustomRange = ({ dates, onChange }) => {
    const onStartChange = val => {
      onChange({ ...dates, start: val.format('YYYY-MM-DD') })
    }
    const onEndChange = val => {
      onChange({ ...dates, end: val.format('YYYY-MM-DD') })
    }

    return (
      <div className="select-wrapper ml-5">
          <span className="select-title">Start date</span>
          <DatePicker
            value={dates.start}
            maxDate={dates.end}
            className="w-100px mr-3"
            onChange={onStartChange}
            animateYearScrolling
          />
          <span className="select-title">End date</span>
          <DatePicker
            minDate={dates.start}
            value={dates.end}
            className="w-100px"
            onChange={onEndChange}
            animateYearScrolling
          />
      </div>
    );
}
export default CustomRange;
