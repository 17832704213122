import React from "react";
import { makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import PropTypes from "prop-types";
import cn from "classnames";

const useStyles = makeStyles( theme => ({
  button: ({
      color = '#fff',
      background = theme.palette.background.gray1,
    }) => ({
    backgroundColor: background,
    color: color,
    padding: '13px 25px',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '19px',
    "&:hover": {
      opacity: 0.9
    },
    "&:active": {
      color: fade(color, 0.7),
    }
  })
}))

const Button = ({ className, onClick, children, color, background, style }) => {
  const classes = useStyles({ color, background });
  return (
    <button
      className={cn(classes.button, className)}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Button;
