import React from "react";
import SVG from "react-inlinesvg";
import { makeStyles } from "@material-ui/core";

import bedImage from "app/assets/images/bed.svg";
import bathImage from "app/assets/images/bath.svg";
import sedanImage from "app/assets/images/sedan.svg";
import measuring from "app/assets/images/measuring.svg";


const useStyles = makeStyles( theme => ({
  iconsLayer: ({ underImageIcons, bigIcons }) => ({
    position: underImageIcons ? "relative" : "absolute",
    width: "100%",
    left: 0,
    top: underImageIcons ? 0 : "-50px",
    background: underImageIcons? 'transparent' : "rgba(0,0,0, 0.4)",
    color: underImageIcons ? theme.palette.text.secondary : "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexWrap: "wrap",
    "& svg": {
      height: bigIcons ? "30px" : "20px",
      width: bigIcons ? "30px" : "20px",
      fill: underImageIcons ? theme.palette.text.secondary : "#fff",
      [theme.breakpoints.down('sm')]: {
        height: '30px',
        width: '30px',
      }
    },
    "& span": {
      lineHeight: bigIcons ? "30px" : "20px",
      fontSize: bigIcons ? "17px" : "12px",
      marginLeft: "7px",
      marginRight: "15px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "17px",
        lineHeight: "30px"
      }
    },
    "&>div": {
      padding: "10px 0",
    }
  }),
}))

const ValueWithIcon = ({ value, imgSrc, land }) => {
  if (!value || value === '' || value === 0) return "";
  return (
    <div className="d-flex">
      <SVG src={imgSrc} />
      <span className={land && "mr-0"}>{value}</span>
    </div>
  );
};

const IconsSection = ({ bedrooms, bathrooms, cars, landArea, underImageIcons, bigIcons }) => {
  const classes = useStyles({ underImageIcons, bigIcons });
  return (
    <div className={classes.iconsLayer}>
      <div className="d-flex">
        <ValueWithIcon value={bedrooms} imgSrc={bedImage} />
        <ValueWithIcon value={bathrooms} imgSrc={bathImage} />
        <ValueWithIcon value={cars} imgSrc={sedanImage} />
      </div>
      {landArea !== '' && <ValueWithIcon land value={landArea} imgSrc={measuring} />}
    </div>
  )
}

export default React.memo(IconsSection);
