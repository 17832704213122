import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  withStyles,
  makeStyles,
  DialogTitle,
} from "@material-ui/core";
import { Toast } from "primereact/toast";
import produce from "immer";
import cn from "classnames";

import { Modal, ModalActions } from "../../../components/Modal";
import { updateComparables } from "../../../../redux/reducers/appraisalReducer";
import PropertiesManualList from "./PropertiesManualList";
import { useBusinessListing } from "app/hooks";
import {useNz} from "../../../hooks/useNz";

const useStyles = makeStyles(theme => ({
  tabContainer: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.background.gray2,
      color: "#333",
      fontWeight: 600,
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& button": {
      textTransform: "none",
      fontSize: 12,
    },
  },
  body: {
    height: "calc(100vh - 100px)",
    minHeight: 170,
  },
  loadingBar: {
    position: "absolute",
    top: 198,
    width: "100%",
  },
  loading: {
    opacity: 0.5,
  },
}));

const CustomizedDialogTitle = withStyles({
  root: {
    padding: 0,
    position: "relative",
  },
})(DialogTitle);

const marketTypes = {
  rh: "rh",
  rhleased: "rhleased",
  off: "off",
  on: "on",
};

const initState = {
  selected: [],
};

export const ComparableManuallyModal = ({
  show,
  onHide,
  activeTabIndex,
  isCommercial,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { isBusinessListing } = useBusinessListing();

  const [marketType, setMarketType] = useState(marketTypes.rh);

  const [rhList, setRhList] = useState(initState);
  const [onList, setOnList] = useState(initState);
  const [offList, setOffList] = useState(initState);
  const [rhleasedList, setRhleasedList] = useState(initState);
  const { isNz } = useNz();

  const comparables = useSelector(
    ({ appraisal }) => appraisal.comparables,
    shallowEqual,
  );
  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );

  const tabs = {
    [marketTypes.rh]: [rhList, setRhList],
    [marketTypes.on]: [onList, setOnList],
    [marketTypes.off]: [offList, setOffList],
    [marketTypes.rhleased]: [rhleasedList, setRhleasedList],
  };
  const [activeTabValues, setActiveTabValues] = tabs[marketType];

  const isRental = category !== "sales";

  useEffect(() => {
    setRhList({ ...initState, selected: comparables.raineandhorne || [] });
    setOnList({ ...initState, selected: comparables.on || [] });
    setOffList({ ...initState, selected: comparables.off || [] });
    setRhleasedList({ ...initState, selected: comparables.rhleased || [] });
    const openTab = Object.values(marketTypes)[activeTabIndex];
    setMarketType(openTab);
  }, [comparables, show, activeTabIndex]);

  const handleTabChange = (event, newValue) => setMarketType(newValue);

  const saveAllChanges = () => {
    dispatch(
      updateComparables({
        raineandhorne: rhList.selected,
        rhleased: rhleasedList.selected,
        on: onList.selected,
        off: offList.selected,
      }),
    );
    onHide();
  };

  const onAddItem = (newItem, index) => {
    if (activeTabValues.selected.length === 9) {
      return toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail:
          "The maximum number of 9 comparable properties has already been selected",
        life: 4000,
      });
    }
    setActiveTabValues(prevState => {
      const selected = [...prevState.selected, newItem];
      return { ...prevState, selected };
    });
  };

  const onDeleteItem = (item, index) => {
    setActiveTabValues(prevState => {
      const selected = produce(prevState.selected, draft => {
        draft.splice(index, 1);
      });
      return { ...prevState, selected };
    });
  };

  return (
    <Modal
      open={show}
      onClose={onHide}
      maxWidth="xl"
      fullWidth
      style={{ zIndex: "100" }}
    >
      <CustomizedDialogTitle>
        <Tabs
          centered
          value={marketType}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{ root: classes.tabContainer }}
        >
          <Tab
            disableRipple
            value={marketTypes.rh}
            label={category === "sales" ? "R&H Sales" : "R&H For Lease"}
          />
          {category === "sales" && !isBusinessListing && (
            <Tab
              disableRipple
              value={marketTypes.off}
              label="RP Data Market Sales"
            />
          )}
          {category === "lease" && !isBusinessListing && (
            <Tab
              disableRipple
              value={marketTypes.rhleased}
              label="R&H Leased"
            />
          )}
          {isBusinessListing || (category === "lease" && isNz) ? (
            ""
          ) : (
            <Tab
              disableRipple
              value={marketTypes.on}
              label="RP Data On The Market"
            />
          )}
        </Tabs>
      </CustomizedDialogTitle>

      <div className={cn(classes.body)}>
        <PropertiesManualList
          selectedItems={activeTabValues.selected}
          onAddItem={onAddItem}
          onDeleteItem={onDeleteItem}
          isRental={isRental}
          isCommercial={isCommercial}
          tabType={marketType}
        />
      </div>
      <ModalActions
        onCancel={onHide}
        onSubmit={saveAllChanges}
        submitTitle="Save and Close"
      />
      <Toast ref={toast} />
    </Modal>
  );
};
