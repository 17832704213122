import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { confirmDialog } from "primereact/confirmdialog";

import { isCorporate as isCorporateUser } from "shared/utils";
import { useToast, getSuccessToastMessage } from "app/hooks";

import FormInput from "../EditOfficeTeam/FormInput";
import EditorComponent from "../../Common/EditorComponent";
import ImageEdit from "../../Common/ImageEdit";
import { ActionButtons } from "./ActionButtons";
import ArticleModal from "../../Appraisals/CommonComponents/ArticleModal";
import ArticlesService from "../../../api/ArticlesService";
import { articles as articlesConst } from "../../../assets/constants";

const successUpdateMessage = getSuccessToastMessage(
  `Article have been successfully updated`,
);
const successDeleteMessage = getSuccessToastMessage(
  `Article have been successfully deleted`,
);

const EditArticle = () => {
  const history = useHistory();
  const { articleId, officeID } = useParams();

  const { showToastMessage } = useToast();

  const [article, setArticle] = useState({ title: "" });
  const [articleModalShow, setArticleModalShow] = useState(false);

  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);
  const isCorporate = isCorporateUser(currentUser.permissions);

  const marketingAndMethodsOfSaleArticles = new Set([
    ...articlesConst.marketing.sales,
    ...articlesConst.marketing.lease,
    ...articlesConst.methodsOfSale.sales,
    ...articlesConst.methodsOfSale.lease,
  ]);

  useEffect(() => {
    ArticlesService.get(articleId)
      .then( res => {
        const article = res.data || {};
        setArticle(article);
      })
  }, [articleId]);

  const saveChanges = async () => {
    await ArticlesService.update(articleId, article);

    showToastMessage(successUpdateMessage);
  };

  const deleteArticle = async () => {
    await ArticlesService.delete(articleId);

    showToastMessage(successDeleteMessage);

    history.push(`/offices/${officeID}/articles`);
  };

  const onArticleUpdate = async () => {
    const res = await ArticlesService.refresh(articleId);

    setArticle(res.data);

    showToastMessage(successUpdateMessage);
  };

  const reject = () => {
    showToastMessage({
      severity: "info",
      summary: "Rejected",
      detail: "Cancelled",
      life: 3000,
    });
  };

  const confirmUpdate = () => {
    confirmDialog({
      message: "A new version of this article is available, sync it across?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: onArticleUpdate,
      reject: reject,
    });
  };

  const confirmDelete = () => {
    confirmDialog({
      message:
        "Are you sure you want to delete this article?\n" +
        "\n" +
        "NOTE: This action will delete the article from this office only",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: deleteArticle,
      reject: reject,
    });
  };

  const onImageSelected = id =>
    setArticle(currentArticle => ({ ...currentArticle, image: id }));

  const onTabImageSelected = id =>
    setArticle(currentArticle => ({ ...currentArticle, tabImage: id }));

  const updateActive = e => {
    const value = JSON.parse(e.target.value);
    setArticle({ ...article, ...{ active: value } });
  };

  return (
    <>
      <div className="card-header py-3">
        <div className="card-title">
          <h3 className="card-label font-weight-bolder text-dark">
            Article Details - {article?.title || ""}
          </h3>
        </div>
        <div className="card-toolbar">
          <ActionButtons
            onSave={saveChanges}
            onPreview={() => setArticleModalShow(true)}
            onUpdate={confirmUpdate}
            onDelete={confirmDelete}
            allowDelete={isCorporate}
            allowUpdate={article?.updates}
          />
        </div>
      </div>
      <div className="card-body pt-4">
        <FormInput
          title="Title"
          placeholder="Title"
          value={article?.title}
          disabled={!isCorporate}
          onChange={val => setArticle({ ...article, ...{ title: val } })}
        />
        <div className="form-group row">
          <label className="col-lg-2 col-form-label">Active</label>
          <div className="col-lg-10">
            <div className="input-group input-group-lg input-group-solid">
              <select
                className="form-control form-control-solid"
                name="active"
                id="active"
                onChange={updateActive}
                value={article?.active}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
          </div>
        </div>

        <FormInput
          title="Editable"
          placeholder="Editable"
          value={article?.editable ? "Yes" : "No"}
          disabled={true}
          onChange={val => setArticle({ ...article, ...{ editable: val } })}
        />
        <FormInput
          title="Category"
          placeholder="Category"
          value={article?.category}
          disabled={true}
          onChange={val => setArticle({ ...article, ...{ category: val } })}
        />

        <div className="d-flex flex-row my-10">
          <div className="d-flex flex-column">
            <h4 className="card-label text-dark">Header Image</h4>
            <ImageEdit
              src={article?.image}
              officeId={officeID}
              onImageSelected={onImageSelected}
              // 1020px x 250px
              aspectRatio={4.08}
            />
          </div>
          <div className="d-flex flex-column">
            <h4 className="card-label text-dark">Tab Image</h4>
            <ImageEdit
              src={article?.tabImage}
              officeId={officeID}
              onImageSelected={onTabImageSelected}
              // 400px x 300px
              aspectRatio={1.33}
            />
          </div>
        </div>

        <h4 className="card-label text-dark">Content</h4>
        <EditorComponent
          type="settings"
          charactersLimit={
            marketingAndMethodsOfSaleArticles.has(article?.slug) ? 1000 : 1100
          }
          value={article?.body}
          onBlur={val => setArticle({ ...article, ...{ body: val } })}
          hardLimit={marketingAndMethodsOfSaleArticles.has(article?.slug)}
        />
      </div>
      <div className="card-footer">
        <ActionButtons
          onSave={saveChanges}
          onPreview={() => setArticleModalShow(true)}
          onUpdate={confirmUpdate}
          onDelete={confirmDelete}
          allowDelete={isCorporate}
          allowUpdate={article?.updates}
        />
      </div>
      <ArticleModal
        article={article}
        show={articleModalShow}
        onHide={() => setArticleModalShow(false)}
        editable={false}
      />
    </>
  );
};

export default EditArticle;
