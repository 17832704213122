import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Loader from "app/components/ui/Loader";
import GridLinksList from "app/components/GridLinksList";
import MenuAction from "./MenuAction";
import { actions } from '../../../modules/Auth/_redux/authRedux';
import UsersService from "../../../api/UsersService";
import { setStorage } from '../../../../_metronic/_helpers/LocalStorageHelpers';

const columnsTitles = [
  { title: "Agent", sortLabel: "agent" },
  { title: "Active", className: "width-120", sortLabel: "active" },
  { title: "Permission level", className: "width-160 item__centered" },
  { title: "", className: "width-50 item__centered" },
];

const UserList = ({ users, loading, updateValue }) => {
  const currentUser = useSelector(({ auth }) => auth.user, shallowEqual);
  const authToken = useSelector(({ auth }) => auth.authToken, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLevelChange = event => {
    const { name, value } = event.target;
    updateValue(name, value, "permissions", "level");
  };

  const listData = users.map(user => ({
    id: user.id,
    mainData: [
      { content: user.fullName, sortLabel: "agent" },
      {
        sortLabel: "active",
        content: user.isActive ? "Yes" : "No",
        className: user.isActive
          ? "green-color width-100"
          : "alert-color width-100",
      },
      {
        content: (
          <Select
            name={user.id}
            value={user.level}
            onChange={handleLevelChange}
            disabled={currentUser.permissions < 2 || loading}
          >
            <MenuItem value={0}>Basic</MenuItem>
            <MenuItem value={1}>Personal Assistant</MenuItem>
            <MenuItem value={2}>Admin</MenuItem>
            <MenuItem value={3} disabled={currentUser.permissions < 3}>
              Corporate
            </MenuItem>
          </Select>
        ),
        className: "width-160 item__centered",
      },
    ],
    rowTail: (
      <MenuAction
        actions={
          currentUser.permissions === 3 ?
          [
            {
              title: user.isActive ? "Deactivate" : "Activate",
              onClick: () =>
                updateValue(user.id, !user.isActive, "active", "isActive"),
            },
            { title: "Login As User",
              onClick: async () => {
                setStorage("ownAccountToken", authToken);
                UsersService.getUserToken(user.id).then((response) => {
                  dispatch(actions.setToken(response.data.value));
                }).then(() => {
                  dispatch(actions.changeUser());
                  history.push(`/dashboard`);
                });
              }
            }
          ] : [
            {
              title: user.isActive ? "Deactivate" : "Activate",
              onClick: () =>
                updateValue(user.id, !user.isActive, "active", "isActive"),
            },
          ]
        }
      />
    ),
    rowTailClassName: "width-50 item__centered",
  }));

  return (
    <>
      {loading && <Loader />}
      <GridLinksList
        content={listData}
        columnsTitles={columnsTitles}
        hasLink={false}
        transparentTitles
      />
    </>
  );
};

export default React.memo(UserList);
