import { createSlice } from "@reduxjs/toolkit";
import { AGENTS } from "app/constants/commonConstants";

const defaultState = {
  isLoadingHeader: true,
  isLoadingLeaders: true,
  isLoadingLeaderboardChart: true,
  isLoadingProposalsWidget: true,
  isLoadingFooterWidgets: true,
  weeks: 4,
  range: {
    start: null,
    end: null,
  },
  leaders: [],
  leadersMeta: {},
  leadersExtendedData: [],
  recentProposalsChartData: null,
  proposalsByTypeData: null,
  proposalsWidgetData: {
    sent: 0,
    prevSent: 0,
    recent: "",
    unopened: "",
    prevUnopened: 0,
    clicks: "",
    prevClicks: "",
  },
  footerWidgetsData: {
    articles: [],
    readingTime: "",
    averageOpens: "",
    usersSessions: "",
    officesSessions: "",
  },
  leadersListType: AGENTS,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: defaultState,
  reducers: {
    setIsLoadingHeader: (state, action) => {
      state.isLoadingHeader = action.payload;
    },
    setIsLoadingLeaders: (state, action) => {
      state.isLoadingLeaders = action.payload;
    },
    setIsLoadingLeaderboardChart: (state, action) => {
      state.isLoadingLeaderboardChart = action.payload;
    },
    setProposalsByType: (state, action) => {
      state.proposalsByType = action.payload;
    },
    setIsLoadingProposalsWidget: (state, action) => {
      state.isLoadingProposalsWidget = action.payload;
    },
    setIsLoadingFooterWidgets: (state, action) => {
      state.isLoadingFooterWidgets = action.payload;
    },
    setWeeks: (state, action) => {
      state.weeks = action.payload;
    },
    setRange: (state, action) => {
      state = { ...state, range: action.payload };
      return state;
    },
    setLeaders: (state, action) => {
      state.leaders = action.payload.appraisals[0].data;
      state.leadersMeta = action.payload.appraisals[0].metadata[0];
      state.leadersExtendedData =
        action.payload.leaders || action.payload.offices;
      state.isLoadingLeaders = false;
    },
    setRecentProposalsChartData: (state, action) => {
      state.recentProposalsChartData = action.payload;
    },
    setProposalsWidgetData: (state, action) => {
      state.proposalsWidgetData = action.payload;
      state.isLoadingProposalsWidget = false;
    },
    setFooterWidgetsData: (state, action) => {
      state.footerWidgetsData = action.payload;
      state.isLoadingFooterWidgets = false;
    },
    setLeaderListType: (state, action) => {
      state.leadersListType = action.payload;
    },
    setUsersSessions: (state, action) => {
      state.usersSessions = action.payload;
    },
    setOfficesSessions: (state, action) => {
      state.officesSessions = action.payload;
    },
  },
});

export const {
  setIsLoadingHeader,
  setIsLoadingLeaders,
  setIsLoadingLeaderboardChart,
  setIsLoadingProposalsWidget,
  setIsLoadingFooterWidgets,
  setWeeks,
  setRange,
  setLeaders,
  setRecentProposalsChartData,
  setProposalsWidgetData,
  setFooterWidgetsData,
  setLeaderListType,
  setUsersSessions,
  setOfficesSessions,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
