import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { HideTab, Tabs } from "app/modules/Common/CustomizedTabs";
import { updateMarketingOptions } from "../../../../../../redux/reducers";
import PackContent from "./PackContent";
import { useMediaQuery } from "@material-ui/core";
import { Title } from "../../BlockContainer";
import ActivatePacksModal from "./ActivatePacksModal";
import DarkButton from "app/components/ui/DarkButton";
import { AppraisalContext } from "../../../AppraisalContext";
import MobileTabs from "../MobileTabs";
import { useCommercial } from "app/hooks";

const Packs = () => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const { isCommercial } = useCommercial();
  const marketingOptions =
    useSelector(({ appraisal }) => appraisal.marketing.options, shallowEqual) ||
    [];
  const [activeTabIndex, setActiveTab] = useState(0);
  const [showPacksModal, setShowPacksModal] = useState(false);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));
  const activeTabList = marketingOptions?.tabs?.filter(tab => tab.active);
  const activeTab = marketingOptions.tabs?.find(
    item => item.title === activeTabList[activeTabIndex]?.title,
  );
  const packIndex = marketingOptions.tabs?.findIndex(
    item => item.title === activeTabList[activeTabIndex]?.title,
  );

  useEffect(() => {
    const index = activeTabList?.findIndex(item => !item.hidden);
    setActiveTab(index === -1 ? 0 : index);
    // TODO: FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabList?.length]);

  const onHideChange = (index, value) => {
    const hideIndex = marketingOptions.tabs?.findIndex(
      item => item.title === activeTabList[index]?.title,
    );
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.tabs[hideIndex].hidden = value;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const onItemsUpdate = newItems => {
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.tabs[packIndex].items = newItems;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const onUpdateCostSavings = newCostSavings => {
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.tabs[packIndex].costSavings = newCostSavings;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const onExtraItemsUpdate = newItems => {
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.extra.items = newItems;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const onAddExtraClick = () => {
    const newItem = { text: "New Item", amount: 100 };
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.extra.items = [...draft.extra.items, newItem];
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const updateExtraTitle = newVal => {
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.extra.title = newVal;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const onTotalUpdate = newVal => {
    let updatedMarketingOptions = produce(marketingOptions, draft => {
      draft.tabs[packIndex].total = newVal;
    });
    dispatch(updateMarketingOptions(updatedMarketingOptions));
  };

  const openPacksModal = () => {
    setShowPacksModal(prev => !prev);
  };

  const onMobileTabChange = val => {
    const index = activeTabList?.findIndex(
      item => item.title === val.title,
    );
    setActiveTab(current => (current === index ? null : index));
  };

  return (
    <>
      {preview ? (
        ""
      ) : (
        <div className="mb-5">
          <ActivatePacksModal
            show={showPacksModal}
            onHide={() => setShowPacksModal(false)}
          />
          <DarkButton onClick={openPacksModal} className="d-flex m-auto">
            Add/Remove/Reorder Tabs
          </DarkButton>
        </div>
      )}
      {isMobile ? (
        <MobileTabs
          marketingPacks={activeTabList}
          onChangeTab={onMobileTabChange}
          active={activeTab}
          onItemsUpdate={onItemsUpdate}
          onUpdateCostSavings={onUpdateCostSavings}
          onAddExtraClick={onAddExtraClick}
          onTotalUpdate={onTotalUpdate}
          total={activeTab?.total}
        />
      ) : (
        <div>
          <Tabs
            value={activeTabIndex}
            onChange={(e, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons={isMobile ? "on" : "auto"}
            centered
          >
            {activeTabList.map((tab, index) => (
              <HideTab
                key={index}
                label={tab.title}
                hide={tab.hidden}
                onHideChange={() => onHideChange(index, !tab.hidden)}
                value={index}
              />
            ))}
          </Tabs>
          {activeTab && (
            <PackContent
              items={activeTab.items}
              total={activeTab.total}
              onItemsUpdate={onItemsUpdate}
              onAddExtraClick={onAddExtraClick}
              onTotalUpdate={onTotalUpdate}
              onUpdateCostSavings={onUpdateCostSavings}
              costSavings={activeTab?.costSavings}
            />
          )}
        </div>
      )}
      {!marketingOptions?.extra?.items?.length ? (
        ""
      ) : (
        <>
          <Title
            value={marketingOptions?.extra.title || "Optional Extras"}
            onBlur={updateExtraTitle}
            className={`common-subTitle mt-7 ${isCommercial ? "common-commercial-title" : ""}`}
            maxLength={30}
          />
          <PackContent
            title={marketingOptions.extra.title || "Optional Extras"}
            items={marketingOptions.extra.items}
            onItemsUpdate={onExtraItemsUpdate}
            showAddExtraItemButton
            isExtra
          />
        </>
      )}
    </>
  );
};

export default Packs;
