import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Button as PrButton } from "primereact/components/button/Button";

const FolderModal = ({ show, onClose, data, onDone }) => {
  const [text, setText] = useState("");

  const name = data?.name;

  useEffect(() => {
    if (name) setText(name);
  }, [name, show]);

  const isEdit = !!data?.id;
  const onInputChange = ({ target }) => setText(target.value);

  const onSubmit = () => onDone({ ...data, name: text });

  return (
    <Dialog
      onHide={onClose}
      visible={show}
      style={{ width: "450px" }}
      header={isEdit ? "Rename Folder" : "New Folder"}
      modal
      className="p-fluid"
      footer={
        <>
          <PrButton
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onClose}
          />
          <PrButton
            label="Save"
            icon="pi pi-check"
            className="p-button-text"
            onClick={onSubmit}
            disabled={!text?.trim()}
          />
        </>
      }
    >
      <div className="p-field">
        <label htmlFor="text">Name</label>
        <InputText
          id="text"
          value={text}
          onChange={onInputChange}
          required
          autoFocus
        />
        {!text?.trim() && <small className="p-error">Text is required.</small>}
      </div>
    </Dialog>
  );
};

export default FolderModal;
