import React, { useContext, useState } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";

import { Modal, ModalActions, ModalContent } from "shared/components";
import { AppraisalContext } from "../AppraisalContext";
import DarkButton from "../../../components/ui/DarkButton";

export const UpdateVideoLinkButton = ({ currentVideoLink, onUpdate, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newVideoUrl, setNewVideoUrl] = useState(currentVideoLink);
  const { preview } = useContext(AppraisalContext);

  const onCloseDialog = () => {
    setNewVideoUrl(currentVideoLink);
    setIsModalOpen(false);
  };

  const onUpdateVideoUrl = () => {
    onUpdate(newVideoUrl);
    setIsModalOpen(false);
  };

  return (
    <Box display="flex" justifyContent="center" className={className}>
      {preview ? (
        ""
      ) : (
        <DarkButton onClick={() => setIsModalOpen(true)}>
          Update video link
        </DarkButton>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={onCloseDialog}
        title="Update video link"
      >
        <ModalContent>
          <Box mb={3}>
            <Typography>
              If you wish to update the default video, update the video links in
              your user settings.
            </Typography>
          </Box>
          <TextField
            variant="outlined"
            fullWidth
            label="Video link"
            value={newVideoUrl}
            onChange={e => setNewVideoUrl(e.target.value)}
          />
        </ModalContent>
        <ModalActions>
          <Button color="primary" onClick={onUpdateVideoUrl}>
            Update
          </Button>
          <Button color="secondary" onClick={onCloseDialog}>
            Cancel
          </Button>
        </ModalActions>
      </Modal>
    </Box>
  );
};
