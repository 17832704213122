import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { getRange, getWeeks } from "redux/selectors/dashboardSelector";
import { setWeeks, setRange } from "redux/reducers/dashboardReducer";
import DropDownMenu from "app/components/DropDownMenu";

import CustomRange from "./CustomRange";
import moment from "moment-timezone";

const dropDownMenuData = [
  { label: "7 Days", value: 1 },
  { label: "30 Days", value: 4 },
  { label: "3 Months", value: 12 },
  { label: "4 Months", value: 16 },
  { label: "6 Months", value: 24 },
  { label: "12 Months", value: 48 },
];

const customItem = { label: "Custom Range", value: 0 };

const DateSelector = ({ withCustomDates }) => {
  const dispatch = useDispatch();

  const weeks = useSelector(getWeeks);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const customRangeDates = useSelector(getRange);

  useEffect(() => {
    return () => {
      dispatch(
        setRange({
          start: null,
          end: null,
        }),
      );
    };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItemHandler = ({ index }) => {
    const newValue = dropDownMenuData[index]?.value;
    if (newValue) {
      dispatch(setWeeks(newValue));
      dispatch(
        setRange({
          start: null,
          end: null,
        }),
      );
      setShowCustomRange(false);
    } else {
      dispatch(
        setRange({
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        }),
      );
      setShowCustomRange(true);
    }
  };

  const updateRange = val => {
    dispatch(setRange(val));
  };

  const dataList = withCustomDates
    ? [...dropDownMenuData, customItem]
    : dropDownMenuData;

  return (
    <div className="d-flex">
      <div className="select-wrapper">
        <span className="select-title">Showing last</span>
        <DropDownMenu
          data={dataList}
          initialIndex={dataList.findIndex(item => item.value === weeks)}
          itemHandler={menuItemHandler}
          className={cn("select-menu", { "w-130px": withCustomDates })}
        />
      </div>
      {showCustomRange && (
        <CustomRange dates={customRangeDates} onChange={updateRange} />
      )}
    </div>
  );
};

export default DateSelector;
