import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import produce from "immer";

import PMFeeItem from "./PMFeeItem";
import { reorder } from "../../Appraisals/CreateAppraisal/utils";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "../DraggableTable";
import { AppraisalContext } from "../../Appraisals/AppraisalContext";
import TableFooter from "./TableFooter";

const TableHeaderComponent = ({ hide = false }) => {
  const { preview } = useContext(AppraisalContext);
  if (hide) return '';

  return (
    <TableHead>
      <TableRow>
        {!preview && <TableCell style={{ width: "70px" }}/>} {/* space for DND icon */}
        <TableCell>
          Title
        </TableCell>
        <TableCell>
          Cost
        </TableCell>
        {!preview && <TableCell style={{ width: "70px" }} />} {/* space for action icon */}
      </TableRow>
    </TableHead>
  );
}

const PmFees = ({ pmFeesList, onChange, hideHeader = false, showFooter = false }) => {
  const { preview } = useContext(AppraisalContext)
  const removeItem = index => {
    let newList = produce(pmFeesList, draft => {
      draft.splice(index, 1);
    })
    onChange(newList)
  }

  const updateItem = (newVal, index) => {
    const newList = produce(pmFeesList, draft => {
      draft[index] = newVal
    })
    onChange(newList)
  }

  const onDragEnd = result => {
    if (!result.destination) return;  // dropped outside the list

    const newItems = reorder(
      pmFeesList,
      result.source.index,
      result.destination.index
    );
    onChange(newItems)
  }

  const onAddNew = newItem => {
    onChange([...pmFeesList, newItem])
  }

  return (
    <Table>
      <TableHeaderComponent hide={hideHeader}/>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              { pmFeesList.map( (item, index) => (
                <Draggable
                  isDragDisabled={preview}
                  key={item._id || item.localId}
                  draggableId={item._id || item.localId}
                  index={index}
                >
                  { provided => (
                    <PMFeeItem
                      provided={provided}
                      value={item}
                      onRemove={() => removeItem(index)}
                      onChange={val => updateItem(val, index)}
                    />
                  )}
                </Draggable>
              )) }
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
      {showFooter && <TableFooter onAddNew={onAddNew} />}
    </Table>
  )
}

export default PmFees;
