import { useContext } from "react";
import { AppraisalContext } from "../modules/Appraisals/AppraisalContext";
import MetricsService from "../api/MetricsService";
import { useSelector } from "react-redux";
import { getMetricData } from "../../redux/selectors";
import { MetricContext } from "../pages/ProposalView/PreviewMetricHOC";

const useTrackEvent = () => {
  const { id, officeId, draft } = useSelector(getMetricData);
  const { sessionId, disableMetrics } = useContext(MetricContext);
  const { preview } = useContext(AppraisalContext);
  return (type, data, duration = 0) => {
    if (preview && (!disableMetrics || (draft && type === "print"))) {
      MetricsService.postMetric({
        appraisal: id,
        office: officeId,
        sessionId: sessionId,
        type: type,
        data: data,
        duration: duration,
      }).then();
      console.log("track event", type);
    }
  };
};
export default useTrackEvent;
