import React from "react";
import { useSelector } from "react-redux";
import { Container } from "@material-ui/core";

import CoverletterImage from "../CoverletterImage";
import Coverletter from "../Coverletter";
import YourProperty from "../YourProperty";
import Comparable from "../../Comparables/Comparable";
import Marketing from "../Marketing";
import ServicesFees from "../ServicesFees";
import OurBroker from "../OurBroker";
import TeamDetails from "../TeamDetails";
import NextSteps from "../NextSteps";
import Footer from "../Footer";
import ArticleSection from "../ArticleSection";
import { updateMarketTrends } from "redux/reducers";
import { getMarketTrendsData } from "redux/selectors";
import useArticles from "app/hooks/useArticles";

const BusinessListingProposal = () => {
  const articles = useArticles({ common: true, nextSteps: true }) || [];
  const marketTrendsSection = useSelector(getMarketTrendsData);

  return (
    <>
      <CoverletterImage />
      <Container
        maxWidth="md"
        component="div"
        className="px-10"
        id="appraisal-details"
      >
        <Coverletter />
      </Container>
      <YourProperty />
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <ArticleSection
          id="business-marketTrends"
          sectionData={marketTrendsSection}
          onUpdateSectionData={updateMarketTrends}
          article={articles?.common?.find(
            item => item.slug === "value-of-your-business",
          )}
        />
        <Comparable />
        <Marketing />
        <ServicesFees />
        <OurBroker />
        <TeamDetails awards difference profile community history />
        <NextSteps
          salesProcess
          faq
          lookingFor
          preparingYourHome
          article={articles?.nextSteps?.find(item => item?.slug === "next-steps")}
        />
      </Container>
      <Container maxWidth="md" component="div" className="px-10 pb-1">
        <Footer withoutBottom />
      </Container>
    </>
  );
};

export default BusinessListingProposal;
