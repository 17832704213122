
export const getImageFiles = (folderName, fileMap, shared) => {
  const filesArray = Object.values(fileMap);
  const { childrenIds, id: folderId } = filesArray.find(
    item => (item.name === folderName) && (item.shared === shared)
  ) || {};
  if (!childrenIds) return { imageList:[], folderId };
  const imageList = filesArray.filter( item => childrenIds.includes(item.id) && !item.isDir );
  return { imageList, folderId };
}

export const checkImageSize = (file, aspectRatio) => {
  return new Promise(res => {
    const i = new Image()
    i.onload = function(){
      const proportion = i.width / i.height;
      res(proportion === aspectRatio)
    };
    i.src = file
  })
}

export const getImageData = file => {
  return new Promise(res => {
    const reader = new FileReader();
    reader.onload = async () => {
      res(reader.result)
    };
    reader.readAsDataURL(file);
  })
}
