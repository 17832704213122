/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import MomentUtils from "@date-io/moment";
import { Routes } from "./Routes";
import { I18nProvider } from "../_metronic/i18n";
import MaterialThemeProvider from "styles/MaterialThemeProvider";
import SplashScreen from "app/components/SplashScreen";
import { msalConfig } from "./authConfig";
import { Chart, registerables } from "chart.js";
import { LoadScript } from "@react-google-maps/api";
import ToastContextProvider from "./components/ToastProvider";

Chart.register(...registerables);

const msalInstance = new PublicClientApplication(msalConfig);
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export default function App({ store, persistor, basename }) {
  return (
    <ToastContextProvider>
      {/* Provide Redux store */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<SplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <LoadScript googleMapsApiKey={googleMapsApiKey}>
                    <MsalProvider instance={msalInstance}>
                      <Routes />
                    </MsalProvider>
                  </LoadScript>
                </MuiPickersUtilsProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ToastContextProvider>
  );
}
