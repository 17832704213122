import axios from 'axios';
import API_URL from './api';

export default class TemplatesService {

  static getTemplates = (params) => {
    return axios.get(`${API_URL}/templates`, { params })
  }

  static add = data => {
    const apiUrl = `${API_URL}/templates`;
    return axios.post(apiUrl, data)
  }

  static get = id => {
    const apiUrl = `${API_URL}/templates/${id}`;
    return axios.get(apiUrl)
  }

  static edit = (data, id) => {
    const apiUrl = `${API_URL}/templates/${id}`;
    return axios.put(apiUrl, data)
  }

  static delete = id => {
    const apiUrl = `${API_URL}/templates/${id}`;
    return axios.delete(apiUrl)
  }
}
