import { withStyles } from "@material-ui/core";

const PropertiesManualListStyles = withStyles((theme: any) => ({
  selectedItems: {
    backgroundColor: theme.palette.background.gray3,
    height: "100%",
  },
  loadingOpacity: {
    opacity: 0.7,
  },
  itemContainer: {
    maxHeight: "calc(100vh - 410px)",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  propertiesContainer: {
    display: "flex",
    height: "100%",
    padding: "0 20px 10px 40px",
    flexDirection: "column",
  },
  addressBlockInWithImageBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "60%",
    "& input": {
      width: "100%",
    },
  },
  addButton: {
    marginTop: "23px",
    color: "#fff",
    display: "flex",
    padding: "0 40px",
    alignItems: "center",
    backgroundColor: "#454748",
    width: "100px",
    height: "35px",
    "& span": {
      fontSize: "15px",
      fontWeight: "600",
      lineHeight: "19px",
      margin: "0 auto",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  addressInputs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "120px",
    "& input": {
      height: "45px",
    },
  },
  infoBlockInWithImageBlock: {
    display: "flex",
    justifyContent: "space-between",
    "& .inputContainer": {
      width: "30%",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    paddingTop: "20px",
    paddingRight: "10px",
    width: "100%",
    "& input": {
      width: "100%",
      height: "35px",
      border: "1px solid",
      borderColor: theme.palette.border.gray,
      background: "#fff",
      lineHeight: "17px",
      fontSize: 13,
      padding: "13px 13px",
      outline: "none",
    },
    "& label": {
      lineHeight: "17px",
      fontWeight: 600,
      fontSize: 14,
    },
    "&>svg": {
      position: "absolute",
      right: 13,
      bottom: 22.5,
      transform: "translateY(50%)",
    },
  },
  imageBlock: {
    width: "40%",
    margin: "20px 20px 0 0",
  },
  addButtonBlock: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
  },
  title: {
    color: "#333",
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "21px",
    paddingTop: "10px",
  },
  imageContainer: {
    display: "block",
    height: "auto",
    position: "relative",
    overflow: "hidden",
  },
  image: {
    objectFit: "cover",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".3s ease",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
    "&>img": {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
  addressBlock: {
    maxHeight: "70px",
  },
}));

export default PropertiesManualListStyles;
