import cn from "classnames";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Title } from "../../AppraisalDetails/BlockContainer";


const useStyles = makeStyles(() => ({
  imageComponent: {
    width: 100,
    textAlign: 'center',
    margin: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    "& img": {
      width: 'inherit',
    },
    "& span": {
      color: '#666'
    },
    "& :hover": {
      cursor: 'pointer',
      opacity: 0.9
    },
    "& :active": {
      opacity: 0.9
    },
    "& textarea": {
      textTransform: 'capitalize'
    }
  },
  active: {
    "& textarea": {
      backgroundColor: 'rgba(255, 193, 7, 0.6)',
    },
  },
  title: {
    color: '#666',
    textAlign: "center"
  }
}));


const ImageCard = ({ url, name, active, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className={cn(classes.imageComponent, { [classes.active]: active })}
      onClick={onClick}
    >
      <img src={url} alt={name} />
      <Title
        disabled
        value={name}
        className={classes.title}
        rowsMax={2}
      />
    </div>
  )
}

export default ImageCard;
