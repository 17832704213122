import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOfficeData } from 'redux/actions/commonActions';
import { getOfficeName } from 'redux/selectors/commonSelector';
import Proposals from './Proposals';
import LeadersList from './LeadersList';

import './styles.scss';

const Office = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOfficeData());
  }, [dispatch]);

  const officeName = useSelector(getOfficeName);

  return (
    <div className="leaderboard-office">
      <div className="leaderboard-padding">
        <div className="office-name">
          {officeName ?
            <h1>{officeName}</h1> :
            <h1>Loading...</h1>
          }
        </div>
        <Proposals />
      </div>
      <LeadersList />
    </div>
  );
};

export default Office;
