import React, { useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import SearchBox from "../../modules/SearchBox";
import TemplateList from "../../modules/Templates/TemplateList";
import CreateTemplate from "../../modules/Templates/CreateTemplate";
import { Route, Switch } from "react-router-dom";
import useDebounce from "app/hooks/useDebounce";
import BigYellowButton from "app/components/ui/Buttons/BigYellowButton";

import "./styles.scss";

export const Templates = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const subheader = useSubheader();
  subheader.setTitle("Templates");

  const debouncedSearchTerm = useDebounce(search, 500);

  const handleSearch = val => {
    setPage(1);
    setSearch(val);
  };

  return (
    <Switch>
      <Route exact path="/templates">
        <div className="card card-custom card-stretch">
          <div className="templates-list-page-header horizontal-separator">
            <h1 className="title">Templates</h1>
            <div className="header-button-wrapper">
              <BigYellowButton to="templates/new">
                Create new template
              </BigYellowButton>
            </div>
          </div>
          <div>
            <SearchBox
              onChangeSearch={val => handleSearch(val)}
              className="layout-side-padding templates-list-search-box"
              styles={{ margin: "40px 0px" }}
            />
          </div>
          <div className="card-body p-0">
            <TemplateList
              search={debouncedSearchTerm}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </Route>
      <Route exact path="/templates/new">
        <CreateTemplate />
      </Route>
    </Switch>
  );
};
