import React, {useEffect, useState} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TeamMembers = ({ allMembers, teamData, setTeamData }) => {

  const [selectedMembers, setSelectedMembers] = useState([])

  useEffect(()=>{
    setSelectedMembers(teamData.members)
  }, [teamData])

  if (!allMembers) return "";

  const memberTemplate = (member) => {
    return (
      <React.Fragment>
        <span>{member.firstname + " " + member.lastname + " (" + member.agentid + ")"}</span>
      </React.Fragment>
    )
  }

  return (
    <DataTable value={allMembers} selection={selectedMembers} onSelectionChange={e => setTeamData({ ...teamData, ...{ members: e.value }})} dataKey="_id">
      <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
      <Column header='Users' body={memberTemplate}></Column>
    </DataTable>
  );
};

export default TeamMembers;
