import React from "react";
import { Modal } from 'react-bootstrap';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Dialog, makeStyles, useMediaQuery } from "@material-ui/core";
import produce from "immer";
import SVG from "react-inlinesvg";
import CloseIcon from '@material-ui/icons/Close';
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import EditorComponent from "../../Common/EditorComponent";
import { setArticles } from "../../../../redux/reducers/appraisalReducer"
import TestimonialList from "../AppraisalDetails/TestimonialList";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    border: 'none',
    padding: '40px',
    paddingBottom: '20px',
    paddingTop: '25px'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    textTransform: 'uppercase',
    letterSpacing: '2px',
    lineHeight: '1.4',
    fontWeight: '400',
    fontSize: '14px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative'
  },
  headerVerticalBar: {
    backgroundColor: '#EBE9E9',
    margin: '0px 20px',
    height: '50px',
    width: '1px',
  },
  headerLogo: {
    width: '38px'
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    marginBottom: '50px',
    display: 'flex',
    '& img': {
      width: '100%'
    },
    '&:after': {
      content: '""',
      top: '100%',
      position: 'absolute',
      height: '4px',
      width: '100%',
      backgroundColor: '#F7F7F7',
      background: 'linear-gradient(to right, #EB9F47, #F6CB50)',
    }
  },
  modalBody: {
    padding: '40px',
    paddingTop: 0,
    [theme.breakpoints.down("xs")]: {
      '& iframe': {
        height: "100%",
        maxWidth: "100%",
      },
      '& img': {
        height: '100%',
        maxWidth: '100%',
      }
    },
  },
  bodyParagraph: {
    color: '#666363',
    fontWeight: '300',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '22px',
    '& ul': {
      paddingLeft: '15px',
      '& li': {
        listStyle: 'disc',
      },
    },
  }
}));

const ArticleModal = (props) => {

  const {article, editable} = props;

  const classes = useStyles({image: article?.image});

  const dispatch = useDispatch();
  const articles = useSelector(state => state.appraisal.articles, shallowEqual);

  const onArticleChange = (body) => {
    const articleIndex = articles.findIndex(art => art._id === article._id);
    let newArticles = produce(articles, draft => {
      draft[articleIndex] = { ...draft[articleIndex], body }
    })
    dispatch(setArticles(newArticles))
  }

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Dialog open={props.show} onClose={props.onHide} fullWidth={true} maxWidth="lg" fullScreen={isMobile}>
      <Modal.Header className={classes.headerWrapper}>
        <div className={classes.header}>
          <div className={classes.left}>
            <SVG src={toAbsoluteUrl("/media/logos/logo-ampersand-yellow.svg")} className={classes.headerLogo}/>
            <div className={classes.headerVerticalBar}>
            </div>
            <div>
              {article?.title}
            </div>
          </div>
          <div className={classes.right}>
            <CloseIcon fontSize={'large'} className={classes.closeIcon} onClick={props.onHide}/>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        <div className={classes.imageContainer}>
          {article?.image && (
            <img
              src={`${process.env.REACT_APP_API_URL}/img/${article?.image}`}
              alt={article?.title || ""}
            />
          )}
        </div>
        <div>
          {article && article.items ?
            <TestimonialList items={article.items} />
            :
            (editable ? <EditorComponent
                value={ article?.body }
                type='article'
                onBlur={onArticleChange}>
              </EditorComponent>
              : <div className={classes.bodyParagraph}
                     dangerouslySetInnerHTML={{
                       __html: article?.body}}>
              </div>
          )
          }

        </div>
      </Modal.Body>
    </Dialog>
  );
};



export default ArticleModal;
