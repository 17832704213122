import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonsSeparator from "./ButtonsSeparator";
import cn from "classnames";
import hidePasswordIcon from "../../../../assets/images/hidePassword.svg";
import showPasswordIcon from "../../../../assets/images/showPassword.svg";

const requiredField = Yup.string()
  .min(3, "Minimum 3 symbols")
  .max(50, "Maximum 50 symbols")
  .required("Required field");

const schema = Yup.object().shape({
  username: requiredField,
  password: requiredField,
});

const useStyles = makeStyles(() => ({
  passwordIcon: {
    background: "#F3F6F9",
    padding: "0 10px",
  },
}));

const InputComponent = ({
  name,
  placeholder,
  errors,
  register,
  isSubmitted,
  icon,
  type,
  handleShowPasswod,
}) => {
  const classes = useStyles();
  const statusIcon = isSubmitted && errors[name] ? "is-invalid" : "";
  return (
    <div className="form-group fv-plugins-icon-container">
      <div className="d-flex">
        <input
          placeholder={placeholder}
          type={type}
          className={`form-control form-control-solid h-auto py-5 px-6
         ${statusIcon}`}
          name={name}
          {...register(name)}
          style={{ borderRadius: 0 }}
        />
        {icon && (
          <img
            src={icon}
            alt={"passwordIcon"}
            onClick={handleShowPasswod}
            className={classes.passwordIcon}
          />
        )}
      </div>
      {errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{errors[name].message}</div>
        </div>
      )}
    </div>
  );
};

const Button = ({ type = "button", title, loading, onClick }) => (
  <button
    onClick={onClick}
    type={type}
    className={`btn btn-primary font-weight-bold px-9 py-4 w-100`}
  >
    <span>{title}</span>
    {loading && <span className="ml-3 spinner spinner-white" />}
  </button>
);

const LoginForm = ({ onUserPassLogin, onSSOLogin, loading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onSubmit",
  });

  const handleShowPasswod = () => setShowPassword(prev => !prev);

  const Input = props => (
    <InputComponent
      {...props}
      errors={errors}
      register={register}
      isSubmitted={isSubmitted}
    />
  );

  return (
    <form
      onSubmit={handleSubmit(onUserPassLogin)}
      className={cn("form fv-plugins-bootstrap fv-plugins-framework", {
        "opacity-80 pe-none": loading,
      })}
    >
      <Input name="username" placeholder="Login" type="text" />
      <Input
        name="password"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        handleShowPasswod={handleShowPasswod}
        icon={showPassword ? hidePasswordIcon : showPasswordIcon}
      />

      <div className="form-group d-flex flex-column align-items-center">
        <Button type="submit" title="Sign In" loading={loading} />
        <ButtonsSeparator />
        <Button
          title="Sign In with SSO"
          onClick={onSSOLogin}
          loading={loading}
        />
        <div className="text-center mb-10 mb-lg-20">
          <p className="text-muted font-weight-bold font-size-lg my-3">
            Be sure you are logged into your R&H Corporate supplied Microsoft
            email, in order to login here.
          </p>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
