import React, { useContext, useEffect, useRef, useState } from "react";
import { Input, makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { AppraisalContext } from "../AppraisalContext";
import { fade } from "@material-ui/core/styles/colorManipulator";

const hoverEffectStyle = (theme, withHoverEffect) =>
  !withHoverEffect
    ? {}
    : {
        transition: "all 0.2s ease",
        "&:hover": {
          backgroundColor: fade(theme.palette.primary.main, 0.1),
        },
        "&:focus": {
          color: theme.palette.primary.main,
          backgroundColor: "transparent",
        },
      };

const useStyles = makeStyles(theme => ({
  input: ({ preview, withHoverEffect }) => ({
    width: "100%",
    fontSize: "inherit",
    "&:before": {
      borderColor: "transparent",
    },
    "&:focus:not(.Mui-focused):before": {
      borderColor: preview ? "transparent !important" : "#666 !important",
    },
    "&:active:not(.Mui-focused):before": {
      borderColor: preview ? "transparent !important" : "#666 !important",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: preview ? "transparent !important" : "#666 !important",
    },
    "&:after": {
      borderColor: preview ? "transparent" : "#FFB300",
    },
    ...hoverEffectStyle(theme, withHoverEffect),
  }),
}));

const InlineEditor = ({
  value,
  onBlur,
  placeholder,
  withHoverEffect = false,
  charLimit,
  ...other
}) => {
  const inputRef = useRef();
  const [val, setVal] = useState(value);
  const [isMoneyType, setMoneyType] = useState(false);

  const { preview } = useContext(AppraisalContext);
  const classes = useStyles({ preview, withHoverEffect });

  const onChangeValue = ({ target: { value } }) => {
    charLimit ? setVal(value.substring(0, charLimit)) : setVal(value);
  };

  useEffect(() => {
    setMoneyType(value && !isNaN(Number(value)));
    setVal(value);
  }, [value]);

  const onInputBlur = () => {
    if (value !== val) onBlur(val);
  };

  return (
    <Input
      {...other}
      inputRef={inputRef}
      classes={{ root: classes.input }}
      readOnly={preview}
      placeholder={placeholder}
      value={val}
      onChange={onChangeValue}
      onBlur={onInputBlur}
      inputComponent={isMoneyType ? NumberFormatCustom : "input"}
    />
  );
};
export default InlineEditor;

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      fixedDecimalScale
      decimalScale={2}
      prefix="$"
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
};
