import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getLandSize, getPrice } from "../../CreateAppraisal/utils";
import HoverProposalIcon from "../../../Common/HoverProposalIcon";
import PropertyCard from 'app/components/PropertyCard';

const useStyles = makeStyles(() => ({
  gridItem: {
    "&>div:hover": {
      borderColor: "#FFB300",
        boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
        cursor: "pointer",
    }
  },
  loadMoreButton: {
    color: "#fff",
    fontWeight: 600,
    padding: "10px 20px",
  }
}))


const SearchResult = ({ items, addProperty, loadMore, showLoadMoreButton }) => {
  const classes = useStyles();

  const category = useSelector(
    ({ appraisal }) => appraisal.meta.category,
    shallowEqual,
  );

  return (
    <>
      <Grid container spacing={3} >
        {items.map((prop, index) => (
          <Grid item xs={4} md={4} key={index} className={classes.gridItem}>
            <HoverProposalIcon onClick={() => addProperty(prop, index)} type='add'>
              <PropertyCard
                bath={prop.bathrooms}
                bed={prop.bedrooms}
                cars={prop.cars}
                landArea={getLandSize(prop.landArea)}
                image={prop.image}
                underImageIcons
                descriptionFields={[
                  prop.address,
                  getPrice(prop.price),
                  prop.daysOnMarket && 'Days on Market (DOM): ' + prop.daysOnMarket,
                  getLandSize(prop.floorArea) && 'Floor Area: ' + getLandSize(prop.floorArea),
                  prop.yearBuilt && 'Year Built: ' + prop.yearBuilt,
                  prop.saleDate && (`${category === "lease" ? "Lease" : "Sale"} date: `+ prop.saleDate)
                ]}
              />
            </HoverProposalIcon>
          </Grid>
        ))}
      </Grid>
      { showLoadMoreButton &&
        <Button
          onClick={loadMore}
          variant="contained"
          color="primary"
          disableElevation
          className={`mt-5 ${classes.loadMoreButton}`}
        >
          Load more
        </Button>
      }
    </>
  )
}

export default SearchResult;
