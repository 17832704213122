import React, { useContext } from "react";
import { Box, makeStyles } from "@material-ui/core";
import SVG from "react-inlinesvg";
import StatusIcon from "./StatusIcon";
import { Title } from "../../AppraisalDetails/BlockContainer";
import { AppraisalContext } from "../../AppraisalContext";
import DarkButton from "app/components/ui/DarkButton";

const useStyles = makeStyles(() => ({
  title: {
    color: "#333",
    textAlign: "center",
    fontSize: "15px",
  },
}));

const AttachmentIcon = props => {
  const { icon, onChange, title, active, onClick, onURLClick } = props;
  const classes = useStyles();
  const { preview } = useContext(AppraisalContext);

  const onUpdateTitle = title => {
    onChange({ ...props, title });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        style={{ opacity: active ? 1 : 0.2 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <SVG
          src={icon}
          width="90px"
          onClick={() => onClick(false)}
          className="cursor-pointer"
        />
        <Title
          value={title}
          onBlur={onUpdateTitle}
          className={classes.title}
          maxLength={25}
          rowsMax={2}
        />
        {!preview && (
          <DarkButton onClick={onURLClick} className="d-block m-auto">
            Add Link
          </DarkButton>
        )}
      </Box>
      <StatusIcon
        active={active}
        onChange={val => onChange({ ...props, active: val })}
      />
    </Box>
  );
};

export default AttachmentIcon;
