import React, { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AppraisalContext } from "../../AppraisalContext";
import DarkButton from "app/components/ui/DarkButton";
import InfographicsModal from "./InfographicsModal";
import BuilderImageCard from "./BuilderImageCard";
import { reorder } from "../../CreateAppraisal/utils";
import { getImageUrl } from "shared";
import produce from "immer";
import nanoid from "nanoid";

const Infographics = ({ infographics = [], onChange, className, infographicsFolders, withInfographicsSelection }) => {
  const { preview } = useContext(AppraisalContext);
  const [showModal, setModalShowing] = useState(false);

  const openModal = () => setModalShowing(true);
  const closeModal = () => setModalShowing(false);
  const onUpdateInfographicList = newList => {
    onChange(newList);
    closeModal();
  };

  const onUpdateImageTitle = (newTitle, index) => {
    const newInfographics = produce(infographics, draftInfographics => {
      draftInfographics[index].title = newTitle;
    });
    onChange(newInfographics);
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    const newList = reorder(
      infographics,
      result.source.index,
      result.destination.index,
    );

    onChange(newList);
  };

  const id = nanoid();

  if (preview && !infographics.length) return "";
  return (
    <>
      <div className={className}>
        {!!infographics.length && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId={`${id} droppableInfographics`}
              direction="horizontal"
            >
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="d-flex justify-content-center flex-wrap mb-5"
                >
                  {infographics.map((item, index) => (
                    <Draggable
                      key={`${index} ${id}`}
                      draggableId={`${index} ${id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="p-4 d-flex text-hover-primary pl-2"
                        >
                          <BuilderImageCard
                            key={item.id}
                            title={item.title}
                            url={getImageUrl(item.id)}
                            onUpdateTitle={val =>
                              onUpdateImageTitle(val, index)
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!preview && (
          <DarkButton onClick={openModal} className="d-block m-auto">
            Add Infographics
          </DarkButton>
        )}
      </div>
      <InfographicsModal
        show={showModal}
        onHide={closeModal}
        onApply={onUpdateInfographicList}
        infographics={infographics}
        infographicsFolders={infographicsFolders}
        withInfographicsSelection={withInfographicsSelection}
      />
    </>
  );
};

export default Infographics;
