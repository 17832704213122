import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLeaderName,
  getLeaderImageId,
  getOfficeName,
  getProposalLastUpdated,
} from 'redux/selectors/metricsSelector';
import SvgIcon from 'app/components/ui/SvgIcon';

import { getImageUrl } from 'shared';

import "./styles.scss";

const ProposalDetails = () => {
  const leaderName = useSelector(getLeaderName);
  const leaderImageId = useSelector(getLeaderImageId);
  const officeName = useSelector(getOfficeName);
  const lastUpdated = useSelector(getProposalLastUpdated);

  return (
    <div className="unit">
      <h3 className="unit_header horizontal-separator">Proposal Details</h3>
      <div className="unit_content-wrapper">
        <div className="unit_item">
          <div className="details-image details-image__photo">
            {leaderImageId ?
              <img
                src={getImageUrl(leaderImageId)}
                alt={leaderName}
              /> : <SvgIcon iconName="avatar" />
            }
          </div>
          <div className="unit_item-content">
            <span className="title">{leaderName}</span>
            <span className="subtitle">{officeName}</span>
          </div>
        </div>
        <div className="unit_item">
          <div className="details-image details-image__icon">
            <SvgIcon iconName="pencil" />
          </div>
          <div className="unit_item-content">
            <span className="title">Last Updated</span>
            <span className="subtitle">{`${lastUpdated.date} at ${lastUpdated.time}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalDetails;
