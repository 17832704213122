import React, { useContext } from 'react';
import produce from 'immer';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { TableRow, TableCell } from '../DraggableTable';
import { AppraisalContext } from '../../Appraisals/AppraisalContext';
import InlineEditor from '../../Appraisals/CommonComponents/InlineEditor';
import IconButton from 'app/components/ui/Buttons/IconButton';

const PmFeeItem = ({ value, onChange, onRemove, provided, ...other }) => {
  const { preview } = useContext(AppraisalContext);

  const onRawValueChange = (field, newValue) => {
    let updatedItem = produce(value, draft => {
      draft[field] = newValue;
    });
    onChange(updatedItem);
  };

  return (
    <TableRow
      ref={provided?.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
      {...other}
    >
      { preview ? '' :
        <TableCell style={{ width: "70px" }}>
          <DragIndicatorIcon className="m-auto"/>
        </TableCell>
      }
      <TableCell>
        <InlineEditor
          value={value.title}
          placeholder="Title"
          onBlur={val => onRawValueChange('title', val)}
        />
      </TableCell>
      <TableCell>
        <InlineEditor
          value={value.cost}
          placeholder="Cost"
          onBlur={val => onRawValueChange('cost', val)}
        />
      </TableCell>
      {preview ? '' :
        <TableCell style={{ width: '70px' }}>
          <IconButton
            icon="trash"
            variant="trash"
            onClick={onRemove}
          />
        </TableCell>
      }
    </TableRow>
  )
}

export default PmFeeItem;
