import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from './ActionButtons';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '_metronic/_partials/dropdowns';

const ProposalActionMenu = ({ status, rowId, archiveHandler, disabled }) => (
  <Dropdown className="dropdown-inline">
    <Dropdown.Toggle
      disabled={disabled}
      as={DropdownCustomToggler}
    >
      <i className="ki ki-bold-more-hor" />
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-left">
      <ActionButtons
        status={status}
        rowId={rowId}
        archiveHandler={archiveHandler}
      />
    </Dropdown.Menu>
  </Dropdown>);

ProposalActionMenu.propTypes = {
  status: PropTypes.number,
  rowId: PropTypes.string.isRequired,
  archiveHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

ProposalActionMenu.defaultProps = {
  status: 0,
  archiveHandler: null,
  disabled: false,
};

export default ProposalActionMenu;
