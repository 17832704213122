import React, { useEffect, useState } from "react";
import DataGrid from "../../Common/CustomizedDataGrid";
import SearchBox from "../../../modules/SearchBox";
import useDebounce from "../../../hooks/useDebounce";
import { Office } from "../../../interfaces";
import { useDispatch } from "react-redux";
import { setOffice } from "../redux/actions";
import { useGetUser } from "../../../../redux/hooks";
import { Box } from "@material-ui/core";
import OfficesService from "../../../api/OfficesService";

interface officeListInterface {
  offices: Array<Office>
  total: Number
}

const columns = [
  { field: "name", headerName: "Office Name", width: 500 },
  { field: "groupid", headerName: "Group ID", width: 200 },
];

const SelectOffice = () => {
  const [offices, setOffices] = useState<Array<Office> | []>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalState, setTotalState] = useState<Number>(0);

  const dispatch = useDispatch();
  const { isCorporateUser } = useGetUser();
  const { officeId } = useGetUser();
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    const fetchOfficesList = async () => {
      const { data } = await OfficesService.getOffices(debouncedSearchTerm, page);
      const { offices, total }: officeListInterface = data;
      offices.forEach(office => office.id = office._id); // preparing for dataGrid
      setTotalState(total);
      setOffices(offices);
    }

    const fetchUserOffice = async () => {
      const { data } = await OfficesService.getOffice(officeId);
      dispatch(setOffice(data));
    }

    setLoading(true);
    if (isCorporateUser) {
      fetchOfficesList()
        .finally(() => setLoading(false))
    } else {
      fetchUserOffice()
        .finally(() => setLoading(false))
    }
  }, [officeId, page, debouncedSearchTerm, isCorporateUser, dispatch]);


  const onSelect = (office: Office) => {
    dispatch(setOffice(office))
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" height="100%">
      <SearchBox
        value={search}
        onChangeSearch={(val: React.SetStateAction<string>) => setSearch(val)}
        className="my-3 mx-5"
      />
      <DataGrid
        columns={columns}
        rows={offices}
        loading={loading}
        onRowSelected={(val: any) => onSelect(val.data)}
        pageSize={50}
        rowCount={totalState}
        rowsPerPageOptions={[50]}
        pagination
        paginationMode="server"
        onPageChange={(val: any) => setPage(val.page)}
      />
    </Box>
  );
};

export default SelectOffice;
