import React from 'react';
import { toAbsoluteUrl } from '_metronic/_helpers';

import './styles.scss';

const SplashScreen = () => {
  const imgSrc = toAbsoluteUrl('/media/logos/RaineAndHorne_long_min.png');
  return (
    <div className="splash-screen">
      <img className='splash-image' src={imgSrc} alt="Raine & Horne" />
      <svg className="splash-spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    </div>
  );
};

export default SplashScreen;
