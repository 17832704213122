import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CreatePropertySteps,
  Office,
  Property,
  ProposalTypes,
  Suburb,
  Team,
} from "../../../interfaces";

export interface CreateProposalState {
  step?: CreatePropertySteps;
  office?: Office;
  type?: ProposalTypes;
  profile?: Team;
  property?: Property;
  suburb?: Suburb;
}

const initialState: CreateProposalState = {
  step: CreatePropertySteps.SelectOffice,
  office: undefined,
  type: undefined,
  profile: undefined,
  property: undefined,
  suburb: undefined,
};

export const createProposalSlice = createSlice({
  name: "createProposal",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setStep: (state, action: PayloadAction<CreatePropertySteps>) => {
      state.step = action.payload;
    },
    setOffice: (state, action: PayloadAction<Office>) => {
      state.office = action.payload;
      state.step = CreatePropertySteps.SelectType;
    },
    setType: (state, action: PayloadAction<ProposalTypes>) => {
      state.type = action.payload;
      state.step = CreatePropertySteps.SelectTeam;
    },
    setProfile: (state, action: PayloadAction<Team>) => {
      state.profile = action.payload;
      let nextStep = CreatePropertySteps.SelectProperty;
      if (
        state.type === ProposalTypes.marketUpdate ||
        state.type === ProposalTypes.prospecting
      ) {
        state.property = undefined;
        nextStep = CreatePropertySteps.SelectSuburb;
      }
      if (state.type === ProposalTypes.agentProfiling) {
        state.property = undefined;
        nextStep = CreatePropertySteps.Builder;
      }
      state.step = nextStep;
    },
    setProperty: (state, action: PayloadAction<Property>) => {
      state.property = action.payload;
      state.step = CreatePropertySteps.Builder;
    },
    setSuburb: (state, action: PayloadAction<Suburb>) => {
      state.suburb = action.payload;
      state.step = CreatePropertySteps.Builder;
    },
  },
});

export default createProposalSlice.reducer;
