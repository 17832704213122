import { getYoutubeParameterByName } from "../CreateAppraisal/utils";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  video: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  },
}));

export const YoutubeVideo = ({ video }) => {
  const classes = useStyles();
  return (
    <div className={classes.video}>
      <iframe
        title="Youtube Video"
        src={`https://www.youtube.com/embed/${getYoutubeParameterByName(
          "v",
          video,
        )}?color=white&modestbranding=1&showinfo=0&rel=0`}
        allowFullScreen="allowfullscreen"
        frameBorder="0"
      />
    </div>
  );
};

export default React.memo(YoutubeVideo);
