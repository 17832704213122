import {
  PROPOSALS_BY_TYPE,
  PROPOSALS_BY_TYPE_EXTENDED,
} from "app/constants/proposalsConstants";
import { AGENTS } from "app/constants/commonConstants";

export const getIsLoadingHeaders = ({ dashboard }) => dashboard.isLoadingHeader;
export const getIsLoadingLeaders = ({ dashboard }) =>
  dashboard.isLoadingLeaders;
export const getIsLoadingLeaderboardChart = ({ dashboard }) =>
  dashboard.isLoadingLeaderboardChart;
export const getWeeks = ({ dashboard }) => dashboard.weeks || 0;
export const getRange = ({ dashboard }) => dashboard.range;
export const getLeadersMetaData = ({ dashboard }) => dashboard.leadersMeta;
export const getIsLoadingProposalsWidget = ({ dashboard }) =>
  dashboard.isLoadingProposalsWidget;
export const getRecentProposalsChartData = ({ dashboard }) =>
  dashboard.recentProposalsChartData;
export const getProposalsWidgetData = ({ dashboard }) =>
  dashboard.proposalsWidgetData;
export const getIsLoadingFooterWidgets = ({ dashboard }) =>
  dashboard.isLoadingFooterWidgets;
export const getFooterWidgetsData = ({ dashboard }) =>
  dashboard.footerWidgetsData;
export const getLeadersListType = ({ dashboard }) => dashboard.leadersListType;
export const getLeadersArray = ({ dashboard }) => dashboard.leaders;
export const getLeadersExtendedArray = ({ dashboard }) =>
  dashboard.leadersExtendedData;
export const getUsersSessions = ({ dashboard }) => dashboard.usersSessions;
export const getOfficesSessions = ({ dashboard }) => dashboard.officesSessions;

const composeExtendedLeadersData = (leaders, listType) => {
  return leaders.map(leader => {
    return {
      _id: listType === AGENTS ? leader.agent : null,
      image: listType === AGENTS ? leader.image : null,
      office: leader.office,
      sentProposals: PROPOSALS_BY_TYPE_EXTENDED.map(
        proposalType => leader[proposalType.alias],
      ),
    };
  });
};

export const getLeaders = (state, leadersPage) => {
  if (leadersPage) {
    const leaders = getLeadersExtendedArray(state);
    const listType = getLeadersListType(state);
    return composeExtendedLeadersData(leaders, listType);
  } else {
    const leaders = getLeadersArray(state);
    return leaders.map(item => {
      let sentProposals;
      if (item.types) {
        const reducedProposals = item.types.reduce(
          (acc, item) => {
            if (!acc[item.type] && PROPOSALS_BY_TYPE[item.type]) {
              acc[item.type] = 0;
            }
            if (!PROPOSALS_BY_TYPE[item.type]) {
              return {
                ...acc,
                otherTypes: (acc.otherTypes += item.count),
              };
            }
            return {
              ...acc,
              [item.type]: acc[item.type] + item.count,
            };
          },
          { otherTypes: 0 },
        );

        sentProposals = Object.entries(reducedProposals).map(item => ({
          type: item[0],
          count: item[1],
        }));
      }
      return {
        ...item,
        office: item.office ? item.office.replace("Raine & Horne ", "") : "",
        sentProposals,
      };
    });
  }
};
