import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_URL } from "../Auth/_redux/authCrud";
import axios from "axios";
import { Box, LinearProgress, withStyles } from "@material-ui/core";
import { allProspectTypes } from "../CreateProposal/Steps/TypesList";
import { Checkbox } from "primereact/checkbox";

const StickyHeader = withStyles({
  root: {
    position: "sticky",
    top: 0,
    padding: "10px 0",
    zIndex: 999,
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
  },
})(Box);

const ProposalTypes = ({ id }) => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [proposalTypes, setProposalTypes] = useState([]);

  useEffect(() => {
    const apiUrl = API_URL + `offices/${id}`;
    setLoading(true);
    axios
      .get(apiUrl)
      .then(resp => {
        setProposalTypes(resp.data?.proposalTypes || []);
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong. Please try again later",
          life: 4000,
        });
      })
      .finally(() => setLoading(false));
  }, [id]);

  console.log(proposalTypes);

  const saveChanges = () => {
    const apiUrl = `${API_URL}offices/${id}`;
    axios
      .put(apiUrl, { proposalTypes })
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Proposal Types have been successfully updated",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong. Please try again later",
          life: 4000,
        });
      });
  };

  const updateTypeOptions = ({ value, checked }) => {
    let selectedTypes = [...proposalTypes];

    if (checked) {
      selectedTypes.push(value);
    } else {
      selectedTypes.splice(selectedTypes.indexOf(value), 1);
    }
    console.log("selectedTypes:", selectedTypes);
    setProposalTypes(selectedTypes);
  };

  return (
    <div className="card-body pt-4">
      <Toast ref={toast} />
      {loading && <LinearProgress color="primary" className="mb-5 mt-5" />}
      <StickyHeader>
        <h2>Proposal Types</h2>
        <button
          onClick={() => saveChanges()}
          className="btn btn-primary w-100px"
        >
          Save
        </button>
      </StickyHeader>

      {allProspectTypes.map((item, index) => {
        return (
          <div className="field-checkbox">
            <Checkbox
              inputId={`type-${index}`}
              value={item.type}
              onChange={val => updateTypeOptions(val)}
              checked={proposalTypes.includes(item.type)}
            ></Checkbox>
            <label htmlFor={`type-${index}`} className="p-checkbox-label">
              {item.label}
            </label>
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default ProposalTypes;
