import React, { useContext, CSSProperties } from "react";
import SwitchButton from "../../Common/SwitchButton";
import { AppraisalContext } from "../AppraisalContext"

interface ContextProps {
  children: React.ReactNode
  value: boolean
  onChange(val?: boolean): void | boolean
  className?: string
  title?: string
  removed?: boolean
  disabled?: boolean
}

const HideContainer = ({ value, onChange, className, children, title, removed, disabled = false }: ContextProps): JSX.Element  => {
  const { preview } = useContext(AppraisalContext);
  const disabledStyle: CSSProperties = { opacity:  0.5, pointerEvents: preview ? 'none' : 'auto' };

  if ((value && preview ) || removed) return <React.Fragment></React.Fragment>;
  const Switcher = (
    <div className="d-flex justify-content-center switch-box">
      <SwitchButton
        value={value}
        onClick={onChange}
        label={ title || "Hide Section" }
        disabled={disabled}
      />
    </div>
  )
  return (
    <React.Fragment>
      {!preview && Switcher}
      <div className={className || ''} style={ value ? disabledStyle : {} }>
        { children }
      </div>
    </React.Fragment>
  )
};

export default HideContainer;
