import React, { useState, useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateCommercialField, updateProperty } from "redux/reducers";
import BlockContainer from "../../AppraisalDetails/BlockContainer";
import HideContainer from "../../components/HideContainer";
import Carousel from "app/components/Carousel";
import { makeStyles } from "@material-ui/core";
import PhotosModal from "./PhotosModal";
import { AppraisalContext } from "../../AppraisalContext";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

const useStyles = makeStyles(theme => ({
  carouselContainer: {
    position: "relative",
  },
  carousel: {
    "& .slider": {
      [theme.breakpoints.up("sm")]: {
        height: 500,
      },
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "95%",
    width: "100%",
    opacity: 0,
    transition: ".3s ease",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
    "&>img": {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
}));

const PhotoGallery = ({ id = null }) => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const classes = useStyles();
  const [isShowModal, setIsShowModal] = useState(false);

  const { hidden, title } =
    useSelector(
      ({ appraisal }) => appraisal.commercial.photoGallery,
      shallowEqual,
    ) || {};
  const photos =
    useSelector(({ appraisal }) => appraisal.property.photos, shallowEqual) ||
    [];
  const selectedPhotos = photos?.filter(photo => photo.hidden !== true);
  const carouselList = selectedPhotos
    ?.map(photo => photo.thumbnails?.thumb_1024)
    .filter(photo => !!photo);

  const onHiddenChange = hidden =>
    dispatch(updateCommercialField({ data: { hidden }, name: "photoGallery" }));

  const onTitleUpdate = title =>
    dispatch(updateCommercialField({ data: { title }, name: "photoGallery" }));

  const onUpdatePhotosList = selectedList => {
    const newList = photos.map(photo => ({
      ...photo,
      hidden: selectedList.some(item => item?.id === photo?.id) ? false : true,
    }));
    dispatch(updateProperty({ photos: newList }));
    setIsShowModal(false);
  };

  const openModal = () => {
    if (!preview) setIsShowModal(true);
  };
  const closeModal = () => setIsShowModal(false);

  if (!carouselList.length) return "";
  return (
    <HideContainer value={hidden} onChange={onHiddenChange} className="my-10">
      <BlockContainer
        id={id || "commercial-photo-gallery"}
        defaultTitle={title}
        onTitleUpdate={onTitleUpdate}
        hide={hidden}
        withoutSubTitle
      >
        <div className={classes.carouselContainer}>
          <Carousel
            autoPlay
            infiniteLoop
            inteinterval={5000}
            showStatus={false}
            showThumbs={false}
            className={classes.carousel}
            showIndicators={false}
            dynamicHeight={true}
            onClickItem={openModal}
          >
            {carouselList?.map((src, index) => (
              <img src={src} alt="" key={index} />
            ))}
          </Carousel>
          {preview ? (
            ""
          ) : (
            <div className={classes.overlay} onClick={openModal}>
              <img src={toAbsoluteUrl("/media/choice.png")} alt="upload" />
            </div>
          )}
        </div>
        <PhotosModal
          photos={photos}
          show={isShowModal}
          onHide={closeModal}
          onApply={onUpdatePhotosList}
        />
      </BlockContainer>
    </HideContainer>
  );
};

export default PhotoGallery;
