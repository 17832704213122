import React, { useContext } from "react";
import { useSelector, useDispatch} from "react-redux"
import produce from "immer";
import { updateRecruitmentAgents } from "redux/reducers";
import { getRecruitmentAgentsData, getArticlesData } from "redux/selectors";
import { Article } from "app/interfaces"
import { setArticles } from "../../../../redux/reducers/appraisalReducer"
import EditorComponent from "../../Common/EditorComponent";
import { AppraisalContext } from "../AppraisalContext";
import BlockContainer from "../AppraisalDetails/BlockContainer";
import HideContainer from "../components/HideContainer";

const AttractAgents = (): JSX.Element => {
  const dispatch = useDispatch();
  const { preview } = useContext(AppraisalContext);
  const articles = useSelector(getArticlesData);
  const recruitmentAgentsSection = useSelector(getRecruitmentAgentsData);
  const mainArticle = articles.find((item: Article) => item.slug === "recruitment")

  const onArticleChange = (body: string) => {
    const articleIndex = articles.findIndex((art: Article)  => art._id === mainArticle._id);
    let newArticles = produce(articles, (draft: any) => {
      draft[articleIndex] = { ...draft[articleIndex], body }
    })
    dispatch(setArticles(newArticles))
  } 


  const onHideSection = (value: boolean) => {
    dispatch(updateRecruitmentAgents({isHiddenSection: value}))
  }

  const onTitleUpdate = (value: string) => {
    dispatch(updateRecruitmentAgents({title: value}))
  }

  const onSubTitleUpdate = (value: string) => {
    dispatch(updateRecruitmentAgents({subtitle: value}))
  }

  return (
    <HideContainer value={recruitmentAgentsSection.isHiddenSection} onChange={onHideSection}>
      <BlockContainer
        defaultTitle={recruitmentAgentsSection.title}
        defaultSubtitle={recruitmentAgentsSection.subtitle}
        id="recruitment"
        onTitleUpdate={onTitleUpdate}
        onSubtitleUpdate={onSubTitleUpdate}
        hide={recruitmentAgentsSection.isHiddenSection} 
        withoutSubTitle={false}
      >
        <EditorComponent
          inline
          value={mainArticle?.body}
          onBlur={onArticleChange}
          type={"coverletter"}
          disabled={preview}
          charactersLimit={1100}
        />
      </BlockContainer>
    </HideContainer>
  );
};

export default AttractAgents;
