import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import { v4 as uuidv4 } from "uuid";


const AddDialog = ({ show, onClose, onAdd }) => {
  const [title, setTitle] = useState('');
  const [cost, setCost] = useState('');

  useEffect(() => {
    setTitle('');
    setCost('100');
  }, [ show ])

  const setCostValue = ({ target }) => setCost(target.value);
  const setTitleValue = ({ target }) => setTitle(target.value);

  const handleAddClick = () => {
    const localId = uuidv4();
    onAdd({ title, cost, localId })
  }

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>Add Fee Item</DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <div className="mb-3 d-flex flex-column">
          <label htmlFor="title">Title</label>
          <InputText id="title" value={title} onChange={setTitleValue} required autoFocus className="w-100" />
          {!title? <small className="p-error h-15px">Title is required.</small> : <div className="h-15px"/>}
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="cost">Cost</label>
          <InputText
            id="cost"
            maxLength={300}
            value={cost}
            onChange={setCostValue}
            required
            rows={3} cols={20}
            className="w-100"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddClick} className="btn btn-primary" disabled={!title}>
          Add
        </Button>
        <Button onClick={onClose} className="btn">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddDialog;
