import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";

const Modal = () => {
  const [expirationDialog, setExpirationDialog] = useState(false)
  const location = useLocation();

  useEffect(() => {
    setExpirationDialog(Boolean(location.state?.expired));
  }, [location])

  const footer = (
    <div>
      <Button label="Ok" icon="pi pi-check" onClick={() => setExpirationDialog(false)} />
    </div>
  );

  return (
    <Dialog
      header={'Session Expired'}
      visible={expirationDialog}
      style={{ width: '30vw' }}
      footer={footer}
      onHide={() => setExpirationDialog(false)}
    >
      <div style={{ textAlign: 'center' }}>
        <h3>Your session has expired</h3>
        <p>Please start a new session.</p>
      </div>
    </Dialog>
  )
}

export default Modal;
