import {
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import React from "react";

const SwitchButton = props => {
  return (
    <FormControlLabel
      className={props.className}
      value={props.label}
      control={
        <Switch
          checked={props.value || false}
          onChange={e => props.onClick(e.target.checked)}
          color="primary"
          disabled={props.disabled || false}
        />
      }
      label={props.label}
      labelPlacement="start"
    />
  );
};

export default SwitchButton;
