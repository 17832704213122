import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import moment from "moment-timezone";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import binIcon from "../../assets/images/bin.svg";
import { Message } from "../../interfaces";
import MessageContent from "./MessageContent";

const useStyles = makeStyles(() =>
  createStyles({
    messageInfo: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "20px",
    },
    unreadMessage: {
      background: "#FFF9EA",
      "&:hover": {
        background: "#F7F0E1",
      },
    },
    bin: {
      cursor: "pointer",
      "&:hover": {
        filter:
          "invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg) brightness(94%) contrast(115%)",
      },
    },
    content: {
      cursor: "pointer",
    },
  }),
);

interface MessagesInfoProps {
  message: Message;
  isUnread: boolean;
  onHandleMessage(value: Message): void;
  onDeleteMessage(value: Message): void;
}

const MessagesBlock = ({
  message,
  isUnread,
  onHandleMessage,
  onDeleteMessage,
}: MessagesInfoProps) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.messageInfo} ${
        isUnread ? classes.unreadMessage : ""
      }`}
    >
      <MessageContent
        data={message}
        isUnread={isUnread}
        onHandleMessage={onHandleMessage}
        bodyClass={classes.content}
      />
      <div className="d-flex justify-content-between">
        <div>
          <AccessTimeIcon style={{ margin: "0 10px 0 0" }} />
          <span>{moment(message.created_at).format("DD MMM")}</span>
        </div>
        <div onClick={() => onDeleteMessage(message)} className={classes.bin}>
          <img src={binIcon} alt="bin"></img>
        </div>
      </div>
    </div>
  );
};

export default MessagesBlock;
