import React from "react";

import { Avatar } from "shared";
import { AGENTS } from "app/constants/commonConstants";
import { PROPOSALS_BY_TYPE_EXTENDED } from "app/constants/proposalsConstants";

import "./styles.scss";

// TODO: Refactoring
export const List = ({
  listType,
  leaders,
  leadersPage,
  renderItem,
  onSetupOrder,
  orderField,
  orderType,
}) => {
  const RenderItem = renderItem;

  const arrow = field => {
    if (field === orderField) {
      if (orderType === "DESC") {
        return "↑";
      }
      if (orderType === "ASC") {
        return "↓";
      }
    }
  };

  return (
    <div className="leaders-list-inner">
      <>
        <ul className={`leaders-list ${leadersPage && "leaders-list-table"}`}>
          <li
            className={`${
              !leadersPage ? "hidden" : "leaders-list-inner-column"
            } item-inner horizontal-separator leaderboard-padding`}
          >
            <div className="leaders-list-title"></div>
            {leadersPage &&
              PROPOSALS_BY_TYPE_EXTENDED.map((proposalType, index) => (
                <div
                  key={`${index}_header`}
                  className={`leaderboard-${proposalType.alias} leaders-list-header_item`}
                  onClick={() => onSetupOrder(proposalType.alias)}
                >
                  {proposalType.title}
                  {arrow(proposalType.alias)}
                </div>
              ))}
          </li>
          {leaders.map((leader, index) => (
            <li
              key={`${index}_list`}
              className={`item-inner horizontal-separator leaderboard-padding ${leadersPage &&
                "leaders-list-inner-column"}`}
            >
              <div className="d-flex leader-list-title">
                {listType === AGENTS && (
                  <div className="item-image-container">
                    <div className="item-image-wrapper">
                      <Avatar id={leader.image} width={36} />
                    </div>
                  </div>
                )}
                <div className="item-title">
                  <span className="name">
                    {listType === AGENTS
                      ? leader.agent || leader._id
                      : leader.office}
                  </span>
                  {listType === AGENTS && (
                    <span className="office">{leader.office}</span>
                  )}
                </div>
              </div>
              {RenderItem && <RenderItem leader={leader} />}
            </li>
          ))}
        </ul>
      </>
    </div>
  );
};

export default List;
