import React, { useContext, useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { updateVendorWithCoverLetter } from "../../../../redux/actions";
import { AppraisalContext } from "../AppraisalContext";

const useStyles = makeStyles(theme => ({
  inputDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center'
  }
}));


const VendorDetails = () => {
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.appraisal.vendor, shallowEqual);
  const [vendorName, setVendorName] =  useState(vendor.name)
  const [vendorEmail, setVendorEmail] =  useState(vendor.email)
  const [vendorPhone, setVendorPhone] =  useState(vendor.phone)


  useEffect(() => {
    setVendorName(vendor.name);
    setVendorEmail(vendor.email);
    setVendorPhone(vendor.phone);
  }, [vendor]);

  const handleBlur = () => {
    dispatch(updateVendorWithCoverLetter({
      name: vendorName,
      email: vendorEmail,
      phone: vendorPhone
    }))
  }

  const { preview } = useContext(AppraisalContext)
  const classes = useStyles();
  if (preview) return '';
  return (
    <div className="card-body">
      { vendor &&
      <div className={classes.inputDiv} >
        <Typography>Recipient(s):</Typography>
        <TextField
          className="mb-3 my-3 mx-4"
          id="vendor-name"
          fullWidth
          variant="outlined"
          label="Name(s)"
          size="small"
          value={vendorName}
          InputProps={{className: classes.input}}
          onChange={e => setVendorName(e.target.value)}
          onBlur={handleBlur}
        />
        <TextField
          className="mb-3 my-3 mx-4"
          id="vendor-email"
          fullWidth
          variant="outlined"
          label="Email(s)"
          size="small"
          value={vendorEmail}
          InputProps={{className: classes.input}}
          onChange={e => setVendorEmail(e.target.value)}
          onBlur={handleBlur}
        />
        <TextField
          className="mb-3 my-3 mx-4"
          id="vendor-mobile"
          fullWidth
          label="Mobile(s)"
          variant="outlined"
          size="small"
          value={vendorPhone}
          InputProps={{className: classes.input}}
          onChange={e => setVendorPhone(e.target.value)}
          onBlur={handleBlur}
        />
      </div>
      }
    </div>
  );
};

export default VendorDetails;
