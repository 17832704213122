import axios from 'axios';
import API_URL from './api';

export default class SendService {

  static sendAppraisal = (id, body) => {
    return axios.post(
      `${API_URL}/appraisals/${id}/send`,
      body
    );
  }

  static getHistory = (id) => {
    return axios.get(
      `${API_URL}/send/${id}/history`
    );
  }
}
