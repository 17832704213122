import { withStyles } from "@material-ui/core";

const GoogleMapContainerStyles = withStyles((theme: any) => ({
  mapContainer: {
    "& .gm-style-iw-d": {
      display: "inline",
    },
    "& .gm-style-iw": {
      padding: "0 !important",
    },
    "& .gm-style-iw button": {
      display: "none !important",
    },
    "& button.gm-fullscreen-control": {
      display: "none !important", // removing full screen
    },
    "& .gm-style-mtc": {
      display: "none !important", // removing select type of map
    },
    "& .gm-svpc": {
      display: "none !important", // removing street view
    },
  },
  container: {
    padding: "10px 3px",
    textAlign: "center",
    "& img": {
      height: "160px",
    },
  },
  iconsLayer: {
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& svg": {
      height: "20px",
      width: "20px",
      fill: theme.palette.text.secondary,
    },
    "& span": {
      lineHeight: "20px",
      fontSize: "12px",
      marginLeft: "7px",
      marginRight: "15px",
    },
  },
  mapLegend: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "30px",
      height: "30px",
    }
  }
}));

export default GoogleMapContainerStyles;
