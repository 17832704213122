import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {setAppraisal, updateNextSteps} from "../../../redux/reducers";
import { makeStyles } from "@material-ui/core";
import AppraisalContextProvider from "../../modules/Appraisals/AppraisalContext";
import AppraisalDetails from "../../modules/Appraisals/AppraisalDetails";
import PreviewMetricHOC from "./PreviewMetricHOC";
import { getPreviewArchiveData } from "../../../redux/selectors";
import ArchivedStatusMessage from "./ArchivedStatusMessage";
import AppraisalsService from "../../api/AppraisalsService";
import { getErrorToastMessage, useToast } from "../../hooks";
import coverletter from "../../modules/Appraisals/AppraisalDetails/Coverletter";

const useStyles = makeStyles({
  previewContainer: {
    display: "flex",
    position: "relative",
    overflowY: "scroll",
    overflowX: "hidden",
    backgroundColor: "#fff",
  },
});

interface PreviewProps {
  proposalId: string;
}

const Preview = () => {
  let { proposalId } = useParams<PreviewProps>();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const proposalData = localStorage.getItem("proposal_preview");
  const { isArchived, officeName, officePhone, isCommercial } = useSelector(getPreviewArchiveData);
  const { showToastMessage } = useToast();
  const [appraisalId, flkLink] = proposalId.split('-')

  useEffect(() => {
    const initPreview = () => {
      if (!proposalData) return history.push("/dashboard")
      try {
        dispatch(setAppraisal(JSON.parse(proposalData)));
      } catch {
        history.push("/dashboard")
      }
    }

    const initProposalView = async () => {
      dispatch(setAppraisal({})) // reset appraisal data
      AppraisalsService.getAppraisal(appraisalId)
        .then(({ data }) => {
          if (data.meta.appraisalType === 'listingProposal' && data.nextSteps?.hasOwnProperty('flkHidden') && data.meta.flkEnabled) {
            let coverletter = data.vendor.coverLetter;
            if (!data.nextSteps?.flkHidden) {
              const link = flkLink ? atob(flkLink) : data.nextSteps?.flkContacts[0]?.flkUrl;
              const owner = data.nextSteps.flkContacts.find((c: { flkUrl: string; }) => c.flkUrl === link);
              coverletter = coverletter.replace(/<p>.*Dear .*,<\/p>/, `<p>Dear ${owner.firstName} ${owner.lastName },</p>`);
              dispatch(setAppraisal({ ...data, id: appraisalId, vendor: { ...data.vendor, coverLetter: coverletter }}));
              dispatch(updateNextSteps({ action: {value: link, title: data.nextSteps?.action.title, type: 'link'} }));
            } else {
              const ownersNames = data.owners.map((owner: { firstName: any; }) => `${owner.firstName}`).join(' & ');
              coverletter = coverletter.replace(/<p>.*Dear .*,<\/p>/, `<p>Dear ${ ownersNames },</p>`);
              dispatch(setAppraisal({ ...data, id: appraisalId, vendor: { ...data.vendor, coverLetter: coverletter }}));
            }
          } else {
            dispatch(setAppraisal({ ...data, id: appraisalId}));
          }

        })
        .catch((error) => showToastMessage(
          getErrorToastMessage("Sorry we didn't find your proposal" + error)
        ))
    }
    if (appraisalId) initProposalView().then();
    else initPreview();
  }, [appraisalId, proposalData, dispatch, history, flkLink]);

  if (proposalId && isArchived) {
    return (
      <ArchivedStatusMessage
        isCommercial={isCommercial}
        officeName={officeName}
        officePhone={officePhone}
      />
    );
  }

  return (
    <div id="preview-container" className={classes.previewContainer}>
      <PreviewMetricHOC>
        <AppraisalContextProvider preview>
          <AppraisalDetails flkLink={flkLink}/>
        </AppraisalContextProvider>
      </PreviewMetricHOC>
    </div>
  );
};

export default Preview;
